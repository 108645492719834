import {HWMap} from "../common/common.vo";
import {CompanyAccountVO, DefaultCompanyAccountVO} from "../company/company.vo";
import {EduMainVO} from "../education/education.vo";
import {CodeVO, DefaultCodeVO} from "../common/code.vo";
import {ProReqVO} from "../pro/pro.vo";

export interface ReviewVO extends HWMap {

    ridx: number

    bidx: number
    emIdx: number
    targetId: string

    title: string
    keywordCodeList: string
    codeVOList: [CodeVO]
    feedback: number
    rating: number
    createDate: string

    writer: string

    // 고수의 Pick
    pin: boolean
}

export function DefaultReviewVO() {
    const defaultVO: ReviewVO = {
        bidx: 0,
        codeVOList: [DefaultCodeVO()],
        createDate: "",
        emIdx: 0,
        feedback: 0,
        keywordCodeList: "",
        pin: false,
        rating: 0,
        ridx: 0,
        targetId: "",
        title: "",
        writer: ""

    }
    return defaultVO
}

import React, {useEffect, useRef, useState} from "react";
import {HWMap} from "../../../../core/common/common.vo";
import {useNavigate} from "react-router-dom";
import {
	AnsPollVO,
	EduAnswerVO,
	EduContentVO,
	EduFileVO,
	EduMainVO,
	QstPollOptionVO,
	QstPollVO
} from "../../../../core/education/education.vo";
import WebUtils from "../../../../common/utils/web.utils";
import DateUtils from "../../../../common/utils/date.utils";
import {M_EduContentIndex, M_EduTitleDom} from "../M_EduMainDetail";
import educationService from "../../../../core/education/education.service";
import EducationService from "../../../../core/education/education.service";
import StringUtils from "../../../../common/utils/string.utils";
import APIs from "../../../../common/apis/APIs";
import SwalUtils from "../../../../common/utils/swal.utils";
import PointService from "../../../../core/point/point.service";
import ToastUtils from "../../../../common/utils/toast.utils";
import FileUploader from "../../../pc/common/FileUploader";

const M_QstPoll: React.FC<{ paramMap: HWMap }> = ({paramMap}) => {

	const navigate = useNavigate()

	const [requestStep, setRequestStep] = useState()
	const [eduMainVO, setEduMainVO] = useState<EduMainVO | null>(null)
	const [contentList, setContentList] = useState<EduContentVO[] | null>(null)
	const [qstPollList, setQstPollList] = useState<QstPollVO[] | null>(null)
	const [qstPollOption, setQstPollOption] = useState<QstPollOptionVO | null>(null)
	const [eduFileList, setEduFileList] = useState<EduFileVO[] | null>(null)
	const [eduAnswerVO, setEduAnswerVO] = useState<EduAnswerVO | null>(null)

	const [eduContentVO, setContentVO] = useState<EduContentVO | null>(null)
	const [ansPollList, setAnsPollList] = useState<AnsPollVO[] | null>(null)
	const $ansPollList = useRef<AnsPollVO[] | null>(null)

	const $uploadRef = useRef()

	const [isLoading, setIsLoading] = useState(false);

	/**
	 * 받아온 데이터 정의
	 */
	useEffect(() => {
		WebUtils.log(paramMap)
		if (paramMap.eduMainVO) {
			setRequestStep(paramMap.requestStep)
			setEduMainVO(paramMap.eduMainVO)
			setContentList(paramMap.contentList)
			setContentVO(paramMap.eduContentVO);
			
			setAnsPollList(paramMap.answerList[paramMap.requestStep]?.ansPollList)
			setEduAnswerVO(paramMap.answerList[paramMap.requestStep]);

			(async () => {
				const resultDetail = await educationService.getContentDetail({
					ecIdx: paramMap.eduContentVO.ecIdx
				})
				
				/*resultDetail.qstPollList.map((qstPoll:QstPollVO) => {
					if (qstPoll.pollType === "file") {
						qstPoll.$uploader = useRef(null)
					}
					return qstPoll;
				})*/
				await setQstPollList(resultDetail.qstPollList)
				await setQstPollOption(resultDetail.qstPollOption)
				await setEduFileList(resultDetail.eduFileList)
				
				WebUtils.goTop()
			})()
		}
	}, [paramMap]);
	
	
	
	
	
	/**
	 * EduAnswerVO 데이터 확인 시 자동저장 실행
	 */
	const $intervalSave = useRef<NodeJS.Timeout | null>(null)
	useEffect(() => {
	
		if ( eduMainVO && eduAnswerVO && !eduAnswerVO.isComplete && !eduMainVO.isEnd ) {
			const interval = 1000 * 60;
			$intervalSave.current = setInterval(() => autoIntervalSave(), interval)
		}
		
		return () => {
			if ( $intervalSave.current ) {
				//!eduAnswerVO?.isComplete && btnClickSave(false)
				clearInterval($intervalSave.current)
			}
		}
		
	}, [eduAnswerVO])
	
	
	
	
	/**
	 * 정해진 시간뒤 자동 저장
	 * @param _seconds
	 */
	const autoIntervalSave = async () => {
		
		if (eduAnswerVO && eduMainVO) {
			if (!eduAnswerVO.isComplete && !eduMainVO.isEnd ) {
				await btnClickSave(false)
			}
		}
	}

	
	
	
	const inputAnswers = async (qp_index: number, _answer: number, _shortTxt: string, _files: string = "") => {
		const tmpList = [...ansPollList!]
		if (qstPollList && qstPollList[qp_index].pollType === "single") {
			tmpList[qp_index] = {
				...tmpList[qp_index],
				isAnswered: true,
				selTaIdxs: _answer.toString(),
				shortTxt: "",
				selFidxs: "",
			};
		} else if (qstPollList && qstPollList[qp_index].pollType === "short") {
			tmpList[qp_index] = {
				...tmpList[qp_index],
				isAnswered: true,
				selTaIdxs: "",
				shortTxt: _shortTxt,
				selFidxs: "",
			};
		} else if (qstPollList && qstPollList[qp_index].pollType === "file") {
			tmpList[qp_index] = {
				...tmpList[qp_index],
				isAnswered: true,
				selTaIdxs: "",
				shortTxt: "",
				selFidxs: _files
			}
		}
		await setAnsPollList(tmpList)
	}
	useEffect(() => {
		$ansPollList.current = ansPollList
	}, [ansPollList])
	
	


	/**
	 * 답변 데이터가 모두 있는지 확인
	 * 제출 가능 결과 반환
	 */
	const isEnd = async () => {

		let result: boolean = true
		if (eduContentVO?.ecIdx && ansPollList) {
			ansPollList.map((item, index) => {
				if (!item.selTaIdxs && !item.shortTxt && !item.selFidxs) {
					result = false
					return result;
				}
			})
		}
		return result;
	}
	
	
	
	
	
	/**
	 * 저장처리
	 * false : 임시저장,
	 * true : 제출
	 * @param _isSubmission
	 */
	const btnClickSave = async (_isSubmission: boolean) => {
		if (isLoading) return
		setIsLoading(true)
		//const fileList = ($uploadRef.current as any)?.getFileList();
		//($uploadRef.current as any).setFidxs("dnsajkdnjsakn");

		try {
			if (eduContentVO?.ecIdx && ansPollList) {

				if (_isSubmission && !await isEnd()) {
					SwalUtils.text({
						title : `코스학습`,
						message : `누락된 설문항목이 있습니다.`,
						isCancel : true,
						confirmOptions : {
							text : "확인"
						}
					})
					//alert("답변누락");
				} else {
					EducationService.setAnsRecord(eduContentVO!.ecIdx, `SAVE`, "poll")
					const result = await EducationService.setAnsPollList(eduContentVO!.ecIdx, $ansPollList.current!, _isSubmission)
					if ( result.status === "SUCCESS" ) {

						if ( _isSubmission ) {
							EducationService.setAnsRecord(eduContentVO!.ecIdx, `END`, "poll")
							const statusResult = await EducationService.setAnswerStatus({
								eaIdx: eduAnswerVO?.eaIdx,
								isResult: true,
								isComplete: true
							})
							if ( statusResult.status === "SUCCESS" ) {

								ToastUtils.show(`${eduContentVO?.qstPollOptionVO.pollTitle} 완료되었습니다.`)
								//alert("제출 완료");
								//paramMap._goStep(requestStep! + 1)

								// 포인트적립 : 학습 컨텐츠 완료
								await PointService.savePoint("COURSE_VIEW", eduContentVO!.ecIdx)

								paramMap._retrievePage( statusResult.allComplete )
							}
						} else {
							//alert("임시저장 완료");
							ToastUtils.show(`입력하신 내용이 임시저장되었습니다.`)
						}
					}
				}
			}
		} catch (error) {
			WebUtils.log(error)
		} finally {
			setTimeout(() => {
				setIsLoading(false)
			}, 3000)
		}
	}


	return (
		<>
			<section className="detail-section type-survey">
				{/*<!-- title -->*/}
				<div className="detail-title">
					<div className="inner">
						<M_EduTitleDom paramMap={{
							mainTitle: eduMainVO?.eduTitle,
							contentTitle: qstPollOption?.pollTitle,
							startDate: DateUtils.displayEduDate(eduMainVO?.scheduleMode, eduMainVO?.startDate),
							endDate: DateUtils.displayEduDate(eduMainVO?.scheduleMode, eduMainVO?.endDate),
							introduce: eduMainVO?.introduce
						}}/>
						<div className="survey-content">
							{
								eduFileList &&
								(
									<ul className="attach-list">
										{
											eduFileList.map((file, f_index) => (
												<li key={f_index}>
													<a href={`${APIs.EDU_FILE_DOWN}${file.fidx}`}><span className="path">{file.filename}</span></a>
												</li>
											))
										}
									</ul>
								)
								
							}
							<div className="text-area" dangerouslySetInnerHTML={{__html: StringUtils.nl2br(qstPollOption?.pollDesc) || ""}}></div>
						</div>
						<h2 className="tit">{qstPollOption?.pollTitle}</h2>
						
						<M_EduContentIndex paramMap={paramMap} ></M_EduContentIndex>
						
					</div>
				</div>
				<div className="detail-content">
					<div className="inner">
						<div className="content-title">
							<h3 className="tit">설문</h3>
						</div>
						{
							qstPollList?.map((qstPoll, qp_index) => (
								<div className="form-item" key={qp_index}>
									
									{qstPoll.pollType === "single" &&
										(
											<>
												<div className="form-item">
													<div className="form-title">
														<strong className="tit">Q{qp_index + 1}. {qstPoll.pollTitle}</strong>
														<p className="desc">{qstPoll.pollDesc}</p>
													</div>
													<div className="rdo-bind">
														{
															qstPoll.answerList.map((qstPollAns, qpa_index) => (
																<div className="radio" key={qpa_index}>
																	<input type="radio" className="inp-radio"
																	       name={`qstPoll-${qp_index}`}
																	       id={`qstPollAns-${qstPollAns.qpaIdx}`}
																	       value={`qstPollAns-${qstPollAns.qpaIdx}`}
																	       checked={!!(ansPollList && ansPollList[qp_index]?.selTaIdxs === qstPollAns.qpaIdx.toString())}
																	       onChange={(e) => inputAnswers(qp_index, qstPollAns.qpaIdx, "")}/>
																	<label htmlFor={`qstPollAns-${qstPollAns.qpaIdx}`} className="lab-radio">{qstPollAns.answer}</label>
																</div>
															))
														}
													</div>
												</div>
											</>
										)
									}
									
									
									{qstPoll.pollType === "short" &&
										(
											<>
												<div className="form-item">
													<div className="form-title">
														<strong className="tit">Q{qp_index + 1}. {qstPoll.pollTitle}</strong>
													</div>
													<div className="inp-bind">
													<textarea cols={30} rows={5} className="inp-text" placeholder="답변을 이곳에 작성해주세요"
													          name={`qstPoll-${qp_index}`}
													          id={`qstPoll-${qp_index}`}
													          onChange={(e) => inputAnswers(qp_index, 0, e.target.value, "")}
													          value={ansPollList ? (ansPollList[qp_index]?.shortTxt ?? "") : ""}
													></textarea>
													</div>
												</div>
											</>
										)
									}
									
									
									{qstPoll.pollType === "file" &&
										(
											<>
												<div className="form-title">
													<strong className="tit">Q{qp_index + 1}. {qstPoll.pollTitle}</strong>
													<p className="desc">{qstPoll.pollDesc}</p>
												</div>
												
												<FileUploader ref={$uploadRef} key={eduAnswerVO?.eaIdx} params={{
													domId: `qstPoll-${qp_index}`,
													domText: `파일크기 : 100MB이하 / 1개 파일만 등록 가능합니다. 여러 개일 경우 ZIP파일로 압축하여 업로드해주세요`,
													target: `account`,
													fidxs: ansPollList && ansPollList[qp_index].selFidxs,
													maxFileSize: 100,
													maxFileCount: 1,
													updateHandler: async (_target: string, _files: string) => {
														console.log(_target + " ::::: " + _files)
														await inputAnswers(qp_index, 0, "", _files)
													}
												}}/>
												{/*<div className="guide-text">
												파일크기 : 100MB이하 / 1개 파일 / 확장자는 제약이 없습니다.
											</div>*/}
											</>
										)
										
									}
								
								</div>
							))
						}
					
					</div>
				</div>
			</section>
			
			{/*<!-- Floating Area -->*/}
			<div className="floating-area">
				{/*<!-- Floating Button -->*/}
				<div className="floating-button">
					<div className="inner">
						<div className="button-area">
							{
								paramMap.isPossible &&
								<>
									{
										// https://github.com/innople-ebot/elandedu/issues/197
										!eduAnswerVO?.isComplete && <button type="button" className="btn gray lg" onClick={() => btnClickSave(false)}>임시저장</button>
									}
									{
										!eduAnswerVO?.isComplete ?
											<button type="button" className="btn red lg" onClick={() => btnClickSave(true)}>제출하기</button>
											: <button type="button" className="btn lg full" onClick={() => btnClickSave(true)}>수정하기</button>
									}
								</>
							}
							
							
							{/*
							<button className="btn lg gray">임시저장</button>
							<button className="btn lg">제출하기</button>
							<button class="btn lg full">제출하기</button>
							*/}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default M_QstPoll
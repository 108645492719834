import React, {useEffect, useState} from "react";
import moment from "moment/moment";
import Swal from "sweetalert2";
import {BoardFileVO, BoardVO} from "../../../../../../core/board/board.vo";
import {useNavigate} from "react-router-dom";
import M_ProEduTalkModal from "../../../../../mobile/common/M_ProEduTalkModal";
import ProService from "../../../../../../core/pro/pro.service";
import APIs from "../../../../../../common/apis/APIs";
import MypageService from "../../../../../../core/mypage/mypage.service";
import ToastUtils from "../../../../../../common/utils/toast.utils";
import useHeader from "../../../../../../common/hook/useHeader";
import {HeaderBtn} from "../../../../../../core/common/header";
import useFooter from "../../../../../../common/hook/useFooter";
import SwalUtils from "../../../../../../common/utils/swal.utils";


interface M_ProEduTalkBoard {
    boardVO: BoardVO,
    isCancelable: boolean,
    isEditable: boolean,
    isReceive: boolean,
    updateEvent: () => void,
    updateModeEvent?: () => void
}

const ProEduTalkBoard = (props: M_ProEduTalkBoard) => {
    useHeader({
        type: `content`,
        title: ``,
        buttons: [
            HeaderBtn.Back
        ]
    });
    useFooter({isHidden:true})

    const {isCancelable, isEditable, updateEvent} = props

    const navigate = useNavigate();
    const [boardVO, setBoardVO] = useState(props.boardVO)

    useEffect(() => {
        setBoardVO(props.boardVO)

    }, [props]);

    const cancelInquiry = (e: any) => {
        e.preventDefault();
        Swal.fire({
            title: "답변 거절하기",
            html: `
                    고수님께 적절한 질문이 아니였나요?<br>
                    답변하시기 어려운 이유를 알려주세요
                `,
            input: "text",
            inputPlaceholder: "내용을 입력해주세요",
            inputAttributes: {
                maxlength: "50",
                autocapitalize: "off",
                autocorrect: "off"
            },
            showCancelButton: true,
            confirmButtonText: "답변 거절",
            cancelButtonText: "취소",
        }).then(async (result) => {
            if (result.isConfirmed) {
                let params = {bidx: boardVO.idx, msg: result.value}
                const successHandler = () => {
                    ToastUtils.show("답변이 거절되었습니다.")
                    updateEvent()
                }

                await ProService.inquiryCancel(params, successHandler)

            }
        })
    }

    const deleteInquiry = async (e: any) => {
        e.preventDefault()
        Swal.fire({
            title: "삭제하기",
            html: `
                <br/>
                삭제 클릭 시 동작을 되돌릴 수 없습니다.<br/>
                정말 에듀톡을 삭제하시겠습니까?<br/>
            `,
            showCancelButton: true,
            confirmButtonText: "삭제",
            cancelButtonText: "취소",
        }).then(async (result) => {
            if (result.isConfirmed) {
                let params = {bidx: boardVO.idx}
                let successHander = () => {
                    ToastUtils.show("삭제되었습니다.")
                    navigate("/pro/talk")
                }

                await MypageService.boardOneDelete(params, successHander);
            }
        })

    }

    const downloadFile = async (e: any, fileVO: BoardFileVO) => {
        e.preventDefault()
        window.location.href = `${APIs.BOARD_FILES_DOWN}${fileVO.fidx}`
    }

    const getBadge = (code:string) => {
        const filter = getLabel(code)
        return (<span className={`badge type1 sm ${filter.color}`}>{filter.name}</span>)
    }

    const getLabel = (code:string) => {
        const tabMenuList = [
            {name:'전체', code: "", color: "", btn:""},
            {name: '미해결', code: "041901", color: "red"},
            {name:'진행중', code: "041902", color: "orange"},
            {name:'일지작성', code: "041903", color: "purple"},
            {name:'완료', code: "041904", color: "green"},
            {name:'취소', code: "041905", color: "gray"}
        ]
        const targetCode = !props.isReceive && code === "041903" ? "041904" : code
        const filter = tabMenuList.filter(item => item.code === targetCode);
        return filter.length > 0 ? filter[0] : tabMenuList[0]
    }



    return (
        <section className="detail-section border-10">
            <div className="inner">
                <div className="detail-title border-2">
                    <div className="cate">
                        <a href="#" onClick={e => e.preventDefault()}>
                            {getBadge(boardVO.statusCode ?? "")}
                        </a>
                    </div>
                    <h2 className="tit">{boardVO.title}</h2>
                    <div className="info">
                    <span
                        className="date">{moment(boardVO.createDate).format('YYYY-MM-DD')}</span>
                    </div>
                    <div className="writer">
                        <div className="text-wrap">
                            <div className="name">
                                <b>{boardVO.writer}</b>
                            </div>
                            <span className="job">{boardVO.coName}</span>
                        </div>
                    </div>
                    <div className="util">
                        {
                            isEditable && (
                                <>
                                    <a href="#" className="btn-modify" onClick={e => {
                                        e.preventDefault()
                                        props.updateModeEvent && props.updateModeEvent()
                                    }}>
                                        <span className="blind">수정하기</span>
                                    </a>
                                    <a href="#" className="btn-delete" onClick={deleteInquiry}>
                                        <span className="blind">삭제하기</span>
                                    </a>
                                </>
                            )
                        }
                    </div>
                </div>
                <div className="detail-content">
                    <ul className="attach-list">
                        {
                            boardVO.attachList?.map((value, index) => {
                                return (
                                    <li key={index}>
                                        <a href="#" onClick={e => downloadFile(e, value)}>
                                            <span className="path">{value.filename}</span>
                                        </a>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <div className="text-area"
                         dangerouslySetInnerHTML={{__html: boardVO.contentHtml ?? ""}}/>
                    <div className="guide-link">
                        {
                            isCancelable && (
                                <a href="#" onClick={cancelInquiry}>답변할 수 없는 질문인가요?</a>
                            )
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProEduTalkBoard


class ArrayUtils {

	static shuffle(array: any[]) {
		return array.sort(() => Math.random() - 0.5);
	}


	static shuffleArray(array: any[]): any[] {
		const newArray = [...array]; // 기존 배열을 변경하지 않기 위해 복사본 생성
		for (let i = newArray.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1)); // 0에서 i 사이의 무작위 인덱스 선택
			[newArray[i], newArray[j]] = [newArray[j], newArray[i]]; // 무작위로 선택한 요소와 현재 요소를 교환
		}
		return newArray;
	}


	static objSorter<T>(originObj: Record<string, T>, sortKey: T[], ...keyPath: string[]): Record<string, T> {
		let sortedObj: Record<string, T> = {};
		let exceptSortObj: Record<string, T> = {};
		let copiedOriginObj: Record<string, T> = JSON.parse(JSON.stringify(originObj));

		const getValue = (e: any, path: string[]) => {
			let result: any = e;
			for (let i in path) {
				result = result[path[i]];
			}
			return result;
		};

		if (
			!Object.values(copiedOriginObj)
			.map((e) => getValue(e, keyPath))
			.sort((a, b) => a - b)
			.every((val, i) => val === sortKey.sort((a, b) => +a - +b)[i])
		) {
			Object.keys(copiedOriginObj)
			.filter((e) => sortKey.indexOf(getValue(copiedOriginObj[e], keyPath)) < 0)
			.forEach((e) => {
				exceptSortObj[e] = copiedOriginObj[e];
				delete copiedOriginObj[e];
			});
		}

		Object.values(copiedOriginObj).forEach((e, i) => {
			sortedObj[
				Object.keys(copiedOriginObj)[
					Object.values(copiedOriginObj).map((e) => {
						return sortKey.indexOf(getValue(e, keyPath));
					})[i]
					]
				] = Object.values(copiedOriginObj).filter((e) => getValue(e, keyPath) === sortKey[i])[0];
		});

		return Object.assign(sortedObj, exceptSortObj);
	}


}


export default ArrayUtils
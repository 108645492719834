import React, {useEffect, useState} from "react";
import EducationService from "../../../../core/education/education.service";
import APIs from "../../../../common/apis/APIs";
import CommonJS from "../../../../common/common";
import {Link} from "react-router-dom";

const M_DashboardOldCourseInfo : React.FC = () => {

    const [searchYear, setSearchYear] = useState(new Date().getFullYear());
    const [terminationCount, setTerminationCount] = useState(0);
    const [boardHisCount, setBoardHisCount] = useState(0);

    useEffect(() => {
        (async () => {
            const result = await EducationService.getMyCourseOldInfo4DashBoard({
                page: 0,
                size: 1,
                sort: "createDate,DESC",
                searchYear: searchYear,
                boardKeys: APIs.KNOWLEDGE_BOARDKEY
            });
            if(result.status == "SUCCESS" && result.data) {
                setBoardHisCount(result.data.boardHistory);
                setTerminationCount(result.data.termination);
            }
        })()

        CommonJS.select();
    }, [searchYear]);


    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 2020 + 1 }, (_, i) => 2020 + i);
    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const year = parseInt(event.target.value)
        setSearchYear(year);
    };

    return (
        <>
            <div className="box-item box2">
                <div className="box-title">
                    <h3 className="tit">수강이력</h3>
                    <select name="year" className="sel" onChange={handleSelectChange} style={{backgroundColor:"white"}}>
                        {years.reverse().map(year => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                </div>
                <div className="box-content">
                    <ul className="stats-list">
                        <li>
                            <strong>코스학습</strong>
                            <ul className="count-list">
                                <li>
                                    <Link to={`/education/termination`}>
                                        <span className="num">{terminationCount}</span>
                                        <span className="txt">종료</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <strong>학습이력</strong>
                            <ul className="count-list">
                                <li>
                                    <span className="num">{boardHisCount}</span>
                                    <span className="txt">지식콘텐츠</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default M_DashboardOldCourseInfo;
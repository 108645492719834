import React, {useEffect, useState} from "react";
import PointService from "../../../../core/point/point.service";
import {PointAccountVO} from "../../../../core/point/point.vo";
import {HWMap} from "../../../../core/common/common.vo";
import {PageUtils} from "../../../../common/utils/page.utils";
import Pagination from "../../common/Pagination";
import DateUtils from "../../../../common/utils/date.utils";
import NumberFormatter from "../../common/NumberFormatter";

const PointList: React.FC = () => {

    const [pointList, setPointList] = React.useState<PointAccountVO[]>([]);
    const [count, setCount] = React.useState(0);
    const [params, setParams] = useState<HWMap>(new PageUtils())
    const [activeSort, setActiveSort] = React.useState("");

    const updatePages = (newPages: number) => {
        setParams((preParams) => ({
            ...preParams,
            page: newPages
        }));
    }

    useEffect(() => {
        (async () => {
            let cpParams = {...params};
            cpParams.cateCode = activeSort;

            const result = await PointService.getAccountPointHistory(cpParams)
            console.log("::::result ",result)

            if(result.status == "SUCCESS" && result.data) {
                setPointList(result.data.content);
                setCount(result.data.totalElements);
            }
        })()
    }, [params]);

    useEffect(() => {
        setParams(new PageUtils())
    }, [activeSort]);

    return (
        <section className="list-section">
            <div className="inner">
                <div className="board-util">
                    <div className="util-group">
                        <div className="board-total">
                            총 <span>{count}</span>건
                        </div>
                        <div className="board-sort">
                            <div className="sort-list">
                                <a href="javascript:void(0);"
                                   className={activeSort=='' ? 'active' : ''}
                                   onClick={() => setActiveSort('')}>전체</a>
                                <a href="javascript:void(0);"
                                   className={activeSort=='300101' ? 'active' : ''}
                                   onClick={() => setActiveSort('300101')}>코스학습</a>
                                <a href="javascript:void(0);"
                                   className={activeSort=='300102' ? 'active' : ''}
                                   onClick={() => setActiveSort('300102')}>지식학습</a>
                                <a href="javascript:void(0);"
                                   className={activeSort=='300103' ? 'active' : ''}
                                   onClick={() => setActiveSort('300103')}>사내고수</a>
                                <a href="javascript:void(0);"
                                   className={activeSort=='300104' ? 'active' : ''}
                                   onClick={() => setActiveSort('300104')}>기타활동</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="save-list">

                    <table className="table-list">
                        <caption>학습포인트 내역</caption>
                        <colgroup>
                            <col style={{width: "20%"}}/>
                            <col style={{width: "20%"}}/>
                            <col style={{width: "20%"}}/>
                            <col style={{width: "20%"}}/>
                            <col/>
                        </colgroup>
                        <thead>
                        <tr>
                            <th>적립일시</th>
                            <th>구분</th>
                            <th>구분상세</th>
                            <th>내역</th>
                            <th>포인트</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            pointList.length > 0 ?
                                pointList.map((point: PointAccountVO, index) => (
                                    <tr key={index}>
                                        <td>{DateUtils.formatDateTime(point.createDate)}</td>
                                        <td>{point.cateCodeVO?.codeName}</td>
                                        <td>{point.title}</td>
                                        <td><p className="txt">{point.detailDesc}</p></td>
                                        <td>
                                            {
                                                point.actionCode === "S"
                                                    ? <span className="plus">+<NumberFormatter number={point.point}/></span>
                                                    : <span className="minus"><NumberFormatter number={point.point}/></span>
                                            }
                                        </td>
                                    </tr>
                                ))
                                : <tr className="comment-empty">
                                    <td colSpan={5}>아직 적립된 포인트가 없습니다.</td>
                                  </tr>

                        }
                        </tbody>
                    </table>


                    {/* 페이지네이션 */}
                    {pointList.length > 0 && <Pagination page={params.page} totalCount={count} updatePages={updatePages}/>}

                </div>
            </div>
        </section>
    )
}


export default PointList;
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {BoardVO} from "../../../../core/board/board.vo";
import M_BoardCardTypeRow from "./M_BoardCardTypeRow";
import {BoardInfoVO} from "../../../../core/board/BoardInfo.vo";
import useHeader from "../../../../common/hook/useHeader";
import {HeaderBtn} from "../../../../core/common/header";
import useFooter from "../../../../common/hook/useFooter";
import StringUtils from "../../../../common/utils/string.utils";


interface BoardCardTypeProps {
    boardInfo: BoardInfoVO,
    boardList : BoardVO[],
    noticeList: BoardVO[]
}

const M_BoardCardType = (props:BoardCardTypeProps) => {
    const navigation = useNavigate()
    const{boardKey} = useParams()
    const [query, setQuery] = useSearchParams();
    const [boardInfo, setBoardInfo] = useState<BoardInfoVO>(props.boardInfo)
    const [boardList, setBoardList] = useState<BoardVO[]>(props.boardList)
    const [noticeList, setNoticeList] = useState<BoardVO[]>(props.noticeList)

    useHeader({
        type:"content",
        title:boardInfo.boardName,
        buttons:[HeaderBtn.Back]
    })
    useFooter({isHidden:true})

    useEffect(() => {
        setBoardList(props.boardList)
        setNoticeList(props.noticeList)
        setBoardInfo(props.boardInfo)

    }, [props]);

    const goDetail = (idx: number) => {
        navigation('/mypage/board/'+ boardKey +'/' + idx)
    }


    if (boardList.length > 0 || noticeList.length > 0){
        return (
            <ul className="card-list type5">
                {
                    noticeList.map(value => <M_BoardCardTypeRow key={value.idx}
                                                                board={value}
                                                                clickEvent={goDetail}
                                                                isNotice={true}
                                                                isComment={boardInfo.isComment}/>)
                }
                {
                    boardList.map(value => <M_BoardCardTypeRow key={value.idx}
                                                               board={value}
                                                               clickEvent={goDetail}
                                                               isNotice={false}
                                                               isComment={boardInfo.isComment}/>)
                }
            </ul>
        )
    } else {
        return (
            <div className="content-empty">
                <i className="ico-content"></i>
                <p className="txt">
                    {
                        StringUtils.isEmpty(query.get("searchText") ?? "") ? (
                            <>아직 게시글이 없습니다.<br/>첫번째 게시글을 작성해주세요</>
                        ) : (
                            <>검색결과가 없습니다.<br/>검색어를 다시 한 번 확인해주세요</>
                        )
                    }
                </p>
            </div>
        )
    }
}

export default M_BoardCardType
import React, {useEffect, useRef, useState} from "react";
import {HWMap} from "../../../core/common/common.vo";
import BoardService from "../../../core/board/board.service";
import {BoardVO} from "../../../core/board/board.vo";
import APIs from "../../../common/apis/APIs";
import ElandUtils from "../../../common/utils/eland.utils";
import useHeader from "../../../common/hook/useHeader";
import {HeaderBtn} from "../../../core/common/header";
import useFooter from "../../../common/hook/useFooter";
import M_WebzineItem from "../board/item/M_WebzineItem";
import M_InfinityScroll from "../common/M_InfinityScroll";
import type { Swiper as SwiperType } from 'swiper';
import SwiperInit from 'swiper';
import {EduMainVO} from "../../../core/education/education.vo";

interface StorageboxProps {
    type:string
}

const M_Storagebox: React.FC<StorageboxProps> = ({ type }) => {

	useHeader({
		type: 'content',
		title: '보관함',
		buttons: [HeaderBtn.Back, HeaderBtn.Menu],
	});
	useFooter({
		isHidden: false
	});

	const [sortBoardType, setSortBoardType] = useState<string>(type);
    const [totalCount, setTotalCount] = useState(0);
    const [boardList, setBoardList] = useState<BoardVO[]>([]);
    const [params, setParams] = useState<HWMap>({
        page: 0,
        size: 12,
        sort: "createDate,DESC",
    })
    const scrollRef = useRef<{ reload: () => void, saveScroll:(index:number|null)=>void }>(null);

    const boardService = new BoardService();

	const handleTabClick = (tabType: string, event: React.MouseEvent<HTMLAnchorElement>) => {
		setSortBoardType(tabType);

		// 모든 .swiper-slide 요소에서 active 클래스를 제거
		const slides = document.querySelectorAll('.swiper-slide');
		slides.forEach(slide => {
			slide.classList.remove('active');
		});

		// 클릭한 요소의 부모 li에 active 클래스 추가
		const target = event.currentTarget.closest('.swiper-slide');
		if (target) {
			target.classList.add('active');
		}
	};

	const swiperRef = useRef<SwiperType | null>(null);

	const initSwiper = () => {
        swiperRef.current?.destroy(true, true);

        if (boardList.length > 0){
            swiperRef.current = new SwiperInit(`.tab_swiper`, {
                slidesPerView: "auto",
                preventClicks: true,
                preventClicksPropagation: false,
                observer: true,
                observeParents: true
            });
        }
	}

    const fetchList = async (makeParamFunc:(params:HWMap, size:number) => HWMap) => {
        let fetchParams = makeParamFunc({...params}, params.size)
        console.log("⭐fetchParams", fetchParams);

        if (sortBoardType === 'recent') {
            fetchParams.boardKeys = APIs.KNOWLEDGE_BOARDKEY
            const result = await boardService.getRecentViewList(fetchParams);
            if (result.status === 'SUCCESS' && result.data) {
                const updatedBoardList = await ElandUtils.convertJobSkillNames4BoardList(result.data.content);
                setTotalCount(result.data.totalElements);

                return updatedBoardList
            }
        } else if (sortBoardType === 'wish') {
            fetchParams.WISH_LIKE = 'wish'
            fetchParams.boardKey = APIs.KNOWLEDGE_BOARDKEY
            const result = await boardService.getSearchBoards(fetchParams);
            if (result.status === 'SUCCESS' && result.data) {
                const updatedBoardList = await ElandUtils.convertJobSkillNames4BoardList(result.data.list);
                setTotalCount(result.data.totalCount);
                return updatedBoardList
            }
        }

        return []
    }

    const makeParamFunc = (params: HWMap, size: number): HWMap => {
        return {
            ...params,
            size,
        };
    };

	useEffect(() => {
        if (sortBoardType != '') {
            setParams({
                page: 0,
                size: 12,
                sort: "createDate,DESC",
            });

            // fetchList 함수 호출
            (async () => {
                const result = await fetchList(makeParamFunc);
                console.log('Fetched data:', result);
            })();
        }

		initSwiper()
	}, [sortBoardType]);

    useEffect(() => {

    }, []);

    return (
        <main id={"main"} className="content tab-layout">
            <div className="tab-area">
                <div className="inner">
                    <div className="tab-slide">
						<ul className="tab-list swiper-wrapper">
							<li className={`tab-item swiper-slide ${sortBoardType == 'recent' && 'active'}`}>
								<a href="javascript:void(0);"
								   onClick={(event) => handleTabClick('recent', event)}>최근 본 지식</a>
							</li>
							<li className={`tab-item swiper-slide ${sortBoardType == 'wish' && 'active'}`}>
								<a href="javascript:void(0);"
								   onClick={(event) => handleTabClick('wish', event)}>관심 지식</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<section className="list-section">
				<div className="inner">

                    {totalCount > 0 && (
                        <div className="board-util">
                            <div className="util-group">
                                <div className="board-total">
                                    총 <span>{totalCount}</span> 개의 게시물이 있습니다.
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="board-list">
                        {
                            boardList.length > 0 ?
                                <ul className="webzine-list type1">
                                {
                                        boardList.map((item) => (
                                            <M_WebzineItem key={item.idx} board={item}/>
                                        ))
                                    }
                                </ul> :
                                <div className="content-empty">
                                    <i className="ico-content"></i>
                                    <p className="txt">{ sortBoardType == 'recent' ? "최근 본 지식이 없습니다" : "관심 지식이 없습니다" }<br />이랜드에듀에서 다양한 교육을 만나보세요</p>
                                </div>
                        }
                    </div>
                    {
                    boardList &&
                    <M_InfinityScroll<BoardVO>
                        queryKey={"storage" + sortBoardType + "List"}
                            update={fetchList}
                            defaultSize={params.size}
                            totalCount={totalCount}
                            getList={val=>{setBoardList(val)}}
                            scrollRef={scrollRef}
                        />
                    }
                </div>
            </section>
        </main>
    )
}

export default M_Storagebox
import React from "react";
import {Link} from "react-router-dom";

interface JobSkillResultRankItem {
    score: number;
    rank: number;
    totalCount: number;
    skillName: string;
    linkValid: boolean;
}

const JobSkillResultRankItem = (props:JobSkillResultRankItem) => {
    const { rank , totalCount,skillName, linkValid, score} = props;

    const isPreventEmoji = (totalCount <= 10);
    // const isPreventScore = (score >= 80);
    const percentage = ((rank - 1) / totalCount) * 100;
    let rankClass = '';
    let emoji = '';
    let talk = '';

    if (percentage <= 10) {
        rankClass = 'rank1';
        emoji = 'kissing_with_heart';
        talk = '멋져요!';
    } else if (percentage <= 30) {
        rankClass = 'rank2';
        emoji = 'kissing_closed_eyes';
        talk = '최고를 향해!';
    } else if (percentage <= 50) {
        rankClass = 'rank3';
        emoji = 'smiling';
        talk = '함께 성장해봐요~';
    } else {
        rankClass = 'rank4';
        emoji = isPreventEmoji ? 'smiling' :'downcast';
        talk = '노력해볼까요?';
    }

    function getRandomStr(items: string[]): string {
        if (items.length === 0) {
            return '';
        }
        const randomIndex = Math.floor(Math.random() * items.length);
        return items[randomIndex];
    }


    // if (percentage <= 10) {
    //     rankClass = 'rank1';
    //     emoji = 'kissing_with_heart';
    //     talk = getRandomStr(['멋져요!','매우 훌륭해요!','당신은 NO.1',`상위 ${percentage.toFixed(1)}%`]);
    // } else if (percentage <= 30) {
    //     rankClass = 'rank2';
    //     emoji = 'kissing_closed_eyes';
    //     talk = getRandomStr(['최고를 향해!', '당신은 상위권!', '평균보다 훨씬 높아요!', `상위 ${percentage.toFixed(1)}%`]);
    // } else if (percentage <= 50) {
    //     rankClass = 'rank2';
    //     emoji = 'smiling';
    //     talk = getRandomStr(['함께 성장해봐요~','성장할 수 있어요!', '평균이상 멋져요!',`상위 ${percentage.toFixed(1)}%`]);
    // } else if (percentage <= 70){
    //     rankClass = 'rank3';
    //     emoji = 'rolling_eyes';
    //     talk = getRandomStr(['노력해볼까요?','괜찮아요!','나쁘지 않아요!', '성장할 일만 남았어요!']);
    // } else if (percentage <= 90){
    //     rankClass = 'rank3';
    //     emoji = 'downcast';
    //     talk = getRandomStr(['조금 아쉬워요','같이 학습해봐요','함께 도전해봐요!']);
    // } else {
    //     rankClass = 'rank4';
    //     emoji = 'anxious';
    //     talk = getRandomStr(['좀더 분발이 필요해요!','노력이 필요해요!', '지금은 학습 해야할때!']);
    // }


    return (
        <li className={rankClass}>
            <Link to={"/mypage/jobskill"} onClick={e => {
                if(!linkValid){
                    e.preventDefault()
                }
            }}>
                <i className="ico-emoji" style={{
                    backgroundImage:`url("/elandedu/assets/images/imoji/${emoji}.png")`,
                }}></i>
                <strong className="duty">{skillName}</strong>
                <span className="per">상위 {percentage.toFixed(1)}%</span>
                <span className="talk">{talk}</span>
            </Link>
        </li>
    )
}

export default JobSkillResultRankItem
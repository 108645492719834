import React, {useEffect, useState} from "react";
import {Chart, ChartEvent, ScriptableScalePointLabelContext} from "chart.js/auto";
import {AnyObject} from "chart.js/dist/types/basic";
import {PollResultVO} from "../../../../../core/jobskill/jobskill.vo";
import {CodeVO} from "../../../../../core/common/code.vo";

interface JobSkillReportRadarChartProps {
    resultReport: PollResultVO[],
    skillCodeList: CodeVO[]
}

const M_JobSkillResultRadarChart = (props: JobSkillReportRadarChartProps) => {
    const [skillCodeList, setSkillCodeList] = useState<CodeVO[]>(props.skillCodeList)
    const [resultReport, setResultReport] = useState<PollResultVO[]>(props.resultReport)
    const [tooltip, setTooltip] = useState({visible: false, x: 0, y: 0, label: ''});

    useEffect(() => {
        setResultReport(props.resultReport)
        setSkillCodeList(props.skillCodeList)

    }, [props]);

    useEffect(() => {
        setTestResultChart()

    }, [resultReport]);


    const setTestResultChart = async () => {
        const myChart2 = document.getElementById('myChart2') as HTMLCanvasElement

        if (Chart.getChart(myChart2)) {
            Chart.getChart(myChart2)?.destroy();
        }

        const labels = resultReport.map(value => {
            const find = skillCodeList.find(code => code.code === value.skillCode);
            return find ? find.codeName : ""
        })

        const avgScores = resultReport.map(value => value.skillAvg / 10)
        const myScores = resultReport.map(value => value.score / 10)

        const myChart = new Chart(myChart2, {
            type: 'radar',
            data: {
                labels: labels,
                datasets: [
                    {
                        label: "나의 점수",
                        data: myScores,
                        backgroundColor: ["rgba(255, 204, 18, 0.3)"],
                        borderColor: [
                            "rgba(255, 204, 18, 1)"
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: "평균 점수",
                        data: avgScores,
                        backgroundColor: [
                            "rgba(180, 197, 255, 0.3)"
                        ],
                        borderColor: [
                            "rgba(180, 197, 255, 1)"
                        ],
                        borderWidth: 1,
                    },
                ],
            },
            options: {
                elements: {
                    point: {
                        radius: 0,
                    },
                },
                scales: {
                    r: {
                        beginAtZero: true,
                        grid: {
                            color: ["rgba(30, 32, 44, 1)", "rgba(240, 242, 246, 1)"]
                        },
                        pointLabels: {
                            color: (context: ScriptableScalePointLabelContext, options: AnyObject) => {
                                const index = context.index;

                                if (resultReport[index].merit === "STRENGTH") {
                                    return "rgba(24, 71, 237, 1)"
                                } else if (resultReport[index].merit === "WEAKNESS") {
                                    return "rgba(227, 0, 25, 1)"
                                } else {
                                    return "rgba(30, 32, 44, 1)"
                                }
                            },
                        },
                        ticks: {
                            display: false,
                            stepSize: 5,
                        }
                    },
                },
                plugins: {
                    legend: {
                        labels: {
                            usePointStyle: true,
                            pointStyle: 'circle',
                            font: {
                                size: 10
                            }
                        },
                        position: 'bottom',
                    },
                    tooltip: {
                        enabled: false
                    },

                }
            }
        });
    }

    const extractedIndex = (event:ChartEvent, chart:any) => {
        const {x, y} = event;
        let index = -1;

        for (let i = 0; i < chart.scales.r._pointLabelItems.length; i++) {
            const {bottom, top, left, right} = chart.scales.r._pointLabelItems[i];

            // @ts-ignore
            if (x >= left && x <= right && y >= top && y <= bottom) {
                index = i;
                break;
            }
        }
        return index;
    }

    const getLabelText = (index:number) => {
        if (index < 0 && index > resultReport.length){
            return ""
        }

        const score = (resultReport[index].score / 20).toFixed(1);
        return score + " / 5"
    }


    return (
        <div className="chart chart-wrap">
            <div style={{position: 'relative'}}>
                {tooltip.visible && (
                    <div
                        style={{
                            position: 'absolute',
                            top: tooltip.y,
                            left: tooltip.x,
                            backgroundColor: 'rgba(0, 0, 0, 0.75)',
                            color: 'white',
                            padding: '8px',
                            borderRadius: '3px',
                            pointerEvents: 'none',
                            transform: 'translate(-50%, -100%)',
                            whiteSpace: "nowrap"
                        }}
                    >
                        {tooltip.label}
                    </div>
                )}
            </div>
            <canvas id="myChart2"/>
        </div>
    )


}

export default M_JobSkillResultRadarChart
import React from 'react';
import {BannerVO} from "../../../core/main/main.banner.vo";

interface MainBannerItemProps {
    item: BannerVO
}

const MainBannerItem = (props: MainBannerItemProps) => {
    const { item} = props;

    return (
        <li className="visual-item swiper-slide">
            <a href={item.link} target={item.linkType}>
                <img src={item.bannerSrc} alt={item.title} style={{ width: '100%' }} className="img-visual" />
            </a>
        </li>
    )
}

export default MainBannerItem
import React, {useEffect} from "react";
import CommonJS from "../../../../common/common";

const PointPolicyBtn: React.FC = () => {
    useEffect(() => {
        CommonJS.dropdownV2()

    }, []);

    return (
        <div className="point-guide dropdown">
            <button type="button" className="btn-dropdown">포인트제도 안내</button>
            <div className="dropdown-content">
                <div>
                    <strong>■ 포인트제도 안내</strong>
                    <p>
                        포인트는 [학습 포인트]와 [이벤트 포인트]로 구분됩니다.<br/>
                        <br/>
                        [학습 포인트] <br/>
                        콘텐츠 시청, 평점/댓글 작성, 도와줘요 게시판 작성, 코스학습 수료 등 기본적인 학습 활동에 의해 적립됩니다. <br/>
                        월 5,000포인트까지만 적립됩니다.
                        <br/><br/>
                        [이벤트 포인트] <br/>
                        학습 후 적용 스토리 작성, 베스트 후기 선정, 사내고수 활동, 기타 이벤트 참여 등을 통해 적립됩니다. <br/>
                        적립되는 포인트 금액에 제한은 없습니다.
                        <br/><br/>
                        포인트는 매 분기마다 법인별 맞춤 포인트(복지포인트, 이페이, 애슐리캐시 등)로 전환됩니다. <br/>
                        보다 자세한 안내 사항은 ‘마이페이지 - 자주 묻는 질문’을 확인해주세요.
                    </p>
                </div>
                <div>
                    <strong>■ 포인트 적립 기준</strong>
                    <table className="table-list">
                        <caption>포인트 적립 기준</caption>
                        <colgroup>
                            <col/>
                            <col style={{width: '50%'}}/>
                            <col/>
                            <col/>
                            <col/>
                        </colgroup>
                        <thead>
                        <tr>
                            <th>구분</th>
                            <th>포인트명</th>
                            <th>학습포인트</th>
                            <th>이벤트포인트</th>
                            <th>적립기준</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td rowSpan={3}>코스학습</td>
                            <td>코스학습 커리큘럼 과목 수료</td>
                            <td>500</td>
                            <td></td>
                            <td>수료 시</td>
                        </tr>
                        <tr>
                            <td>코스학습 댓글 작성</td>
                            <td>100</td>
                            <td></td>
                            <td>작성 시 (동일 콘텐츠 1회)</td>
                        </tr>
                        <tr>
                            <td>코스학습 지식콘텐츠 시청</td>
                            <td>50</td>
                            <td></td>
                            <td>작성 시 (동일 콘텐츠 1회)</td>
                        </tr>
                        <tr>
                            <td rowSpan={4}>지식학습</td>
                            <td>지식콘텐츠 시청</td>
                            <td>50</td>
                            <td></td>
                            <td>시청 시 (동일 콘텐츠 1회)</td>
                        </tr>
                        <tr>
                            <td>지식콘텐츠 댓글 작성</td>
                            <td>100</td>
                            <td></td>
                            <td>작성 시 (동일정콘텐츠 1회)</td>
                        </tr>
                        <tr>
                            <td>[적용스토리] 작성</td>
                            <td></td>
                            <td>500</td>
                            <td>작성 시</td>
                        </tr>
                        <tr>
                            <td>[적용스토리] 베스트 후기 선정</td>
                            <td></td>
                            <td>2,000</td>
                            <td>관리자 선정</td>
                        </tr>
                        <tr>
                            <td rowSpan={4}>사내고수</td>
                            <td>[사내고수의 지식]에 콘텐츠 등록</td>
                            <td></td>
                            <td>30,000</td>
                            <td>지식 등록 시 (사내고수에게 지급)</td>
                        </tr>
                        <tr>
                            <td>[사내고수의 자료실]에 자료 등록</td>
                            <td></td>
                            <td>10,000</td>
                            <td>자료 등록 시 (사내고수에게 지급)</td>
                        </tr>
                        <tr>
                            <td>[에듀톡] 후 일지 작성</td>
                            <td></td>
                            <td>5,000</td>
                            <td>작성 시 (사내고수에게 지급)</td>
                        </tr>
                        <tr>
                            <td>사내고수와 [에듀톡] 후기 작성</td>
                            <td>100</td>
                            <td></td>
                            <td>작성 시 (문의자에게 지급)</td>
                        </tr>
                        <tr>
                            <td rowSpan={5}>기타활동</td>
                            <td>출석체크</td>
                            <td>50</td>
                            <td></td>
                            <td>로그인 시 일 1회</td>
                        </tr>
                        <tr>
                            <td>[도와줘요 게시판] 게시글 작성</td>
                            <td>100</td>
                            <td></td>
                            <td>작성 시</td>
                        </tr>
                        <tr>
                            <td>[도와줘요 게시판] 답글 작성</td>
                            <td>100</td>
                            <td></td>
                            <td>작성 시</td>
                        </tr>
                        <tr>
                            <td>직무역량진단 완료</td>
                            <td></td>
                            <td>1,000</td>
                            <td>반기별 진단 완료 시</td>
                        </tr>
                        <tr>
                            <td>이벤트 참여</td>
                            <td></td>
                            <td>이벤트 기준</td>
                            <td>관리자 선정</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default PointPolicyBtn;
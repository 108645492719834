import React, {useEffect, useState} from "react";
import MypageNav from "../../common/MypageNav";
import {CodeVO} from "../../../../../core/common/code.vo";
import {JobSkillReport} from "../../../../../core/jobskill/jobskill.vo";
import {
    getModifierAndSmallTalk,
    getPostposition,
    getTransitionStr
} from "../../../../../core/jobskill/jobskill.text";
import JobSkillResultRadarChart from "./JobSkillResultRadarChart";
import MemberUtils from "../../../../../common/utils/member.utils";
import JobSkillResultDoughnutChart from "./JobSkillResultDoughnutChart";
import JobSkillResultRankItem from "./JobSkillResultRankItem";
import JobSkillResultProRecommend from "./JobSkillResultProRecommend";
import JobSkillContentRecommend from "./JobSkillContentRecommend";
import ElandUtils from "../../../../../common/utils/eland.utils";
import JobSkillResultMentoring from "./JobSkillResultMentoring";

interface JobSkillResultProps {
    resultReport:JobSkillReport
}

const JobSkillResult = (props: JobSkillResultProps) => {
    const [skillCodeList, setSkillCodeList] = useState<CodeVO[]>([])
    const [resultReport, setResultReport] = useState<JobSkillReport>(props.resultReport)

    useEffect(() => {
        const getSkillCodeList = async () => {
            setSkillCodeList(await ElandUtils.getJobSkillCodeList(true));
        }
        getSkillCodeList()

    }, []);

    useEffect(() => {
        setResultReport(props.resultReport)

    }, [props.resultReport]);

    const getWeakListStr = () =>{
        return resultReport.list
        .filter(value => value.merit === "WEAKNESS")
        .map(value => value.skillCode)
        .join(",")
    }

    const getWeaknessAndStrengthList = (merit: string) => {
        return resultReport.list.filter(value => value.merit === merit)
        .map(value => {
            const find = skillCodeList.find(code => code.code === value.skillCode);
            return find ? find.codeName : ""
        })
    }

    const getNormalStrengthStr = () => {
        const strengthResult = resultReport.list
        .filter(value => value.merit === "STRENGTH")

        const strength1 = strengthResult
        .map(item => skillCodeList.find(code=>code.code === item.skillCode)?.codeName ?? "")
        .join(", ")

        const strength2= strengthResult
            .map(item => {
                const codeName = skillCodeList.find(code=>code.code === item.skillCode)?.codeName ?? "";
                const postPosition = getPostposition(codeName)
                return `${codeName}${postPosition} 상위 ${(((item.rank - 1) /item.totalCount)* 100).toFixed(0)}%`
            })
            .join(", ")

        return (
            <>
            {MemberUtils.getSessionVO()?.userName} 님은 {strength1} 영역이 상대적으로 강한 고리에요! <br/>
            {strength2}에요!<br/>
            계속 나의 강점으로 키워내 선한 영향력을 끼치는 사내고수로 활동해보세요!
            </>
        )
    }

    const getNormalWeaknessStr = () => {
        const weaknessResult = resultReport.list
        .filter(value => value.merit === "WEAKNESS")

        const weak1 = weaknessResult
        .map(item => skillCodeList.find(code=>code.code === item.skillCode)?.codeName ?? "")
        .join(", ")

        return (
            <>
                상대적으로 약한 고리인 {weak1} 개선이 필요해요.<br/>
                아래 AI 멘토링과 추천 콘텐츠를 통해 역량을 개선해봐요!
            </>
        )
    }

    const getStrengthStr = () => {
        return (
            <>
                {MemberUtils.getSessionVO()?.userName} 님은 모든 평가를 높게 주셨어요.<br/>
                누구에게나 인정받을 수 있는 나만의 강점은 무엇일지 점검하고<br/>
                작은 지식부터 나눔을 시작해보세요!
            </>
        )
    }

    const getWeaknessStr = () => {
        return (
            <>
                {MemberUtils.getSessionVO()?.userName} 님은 모든 평가를 낮게 주셨어요.<br/>
                <br/>
                가장 시급하게 개선이 필요한 역량이 무엇일지 고민해보고<br/>
                매주 정해진 시간에 꾸준히 학습해보는게 좋을 것 같아요!
            </>
        )
    }

    const strengthAndWeaknessSection = (
        title: string,
        type: string,
        badgeClass: string,
        getDescriptionStr: () => JSX.Element
    ) => {
        return (
            <div>
                <strong>{title}</strong>
                <div className="cate">
                    {
                        getWeaknessAndStrengthList(type).map(skillName => (
                            <span className={`badge type1 ${badgeClass}`}
                                  key={skillName}>{skillName}</span>
                        ))
                    }
                </div>
                <p>{getDescriptionStr()}</p>
            </div>
        );
    };

    const getSmallTalkArea = () => {
        let smallTalk = getModifierAndSmallTalk(resultReport.currentAvg);

        return (
            <div className="text-wrap">
                <strong>당신은 <span>{smallTalk.modifier}{resultReport.jobCode.codeName}</span></strong>
                <p className="desc">
                    {smallTalk.smalltalk}<br/>
                    <br/>
                    {
                        resultReport.lastAvg > 0 && (
                            <>
                                {getTransitionStr(resultReport.lastAvg, resultReport.currentAvg)}<br/>
                                <br/>
                            </>
                        )
                    }

                    매 주 이랜드에듀 지식콘텐츠를 시청하고<br/>
                    사내고수와 도와줘요게시판을 적극적으로 활용해보세요 😎
                </p>
                <p className="guide-text">
                    역량 점수 기준<br/>
                    90점 이상: 해당 직무에서 ACE라고 볼 수 있어요!<br/>
                    70점 이상 ~90점 미만: 조금만 더 노력하면 ACE가 될 수 있어요!<br/>
                    70점 미만: 많은 노력이 필요합니다. 에듀와 다양한 학습을 통해 역량을 향상 시켜 보아요!
                </p>
            </div>

        )
    }


    return (
        <main className="content job-competency nav-layout">
            <div className="common-title">
                <div className="inner">
                    <h2 className="tit">직무역량진단 결과지</h2>
                    <p className="desc" style={{color: "var(--gray-70)"}}>
                    나의 직무역량에 대한 종합 점수, 각 역량에 대한 상대평가 점수를 확인해보세요!<br/>
                        개별 결과지는 절대 공개되지 않으며, 스스로 학습하기 위한 참고지표로 활용하시면 됩니다.
                    </p>
                </div>
            </div>
            <section className="result-section compare">
                <div className="inner">
                    <h3 className="tit">
                        [{resultReport.parentCode.codeName}] 직무에서
                        총 <span>{resultReport.totalCount}명</span>이 테스트를 진행했어요!
                    </h3>
                    <p className="guide-text">TIP. 현 시점에서 나와 같은 직무의 역량진단을 한 사람의 숫자입니다.</p>
                </div>
            </section>
            <section className="result-section composite">
                <div className="inner">
                    <div className="section-title">
                        <h3 className="tit"><i className="title-emoji"
                                               style={{backgroundImage: `url("/elandedu/assets/images/imoji/1st_place_medal.png")`}}/> 종합
                            평가</h3>
                        <p className="desc">TIP. 종합 평가는 나의 직무에서 필요한 역량을 모두 가지고 있을 때를 100점으로 보았을 때, 스스로 평가한 현재 나의 점수 입니다.</p>
                    </div>
                    <div className="section-content">
                        {
                        resultReport.currentAvg > 0 && (
                                getSmallTalkArea()
                            )
                        }
                        <JobSkillResultDoughnutChart currentAvg={resultReport.currentAvg} lastAvg={resultReport.lastAvg}/>
                    </div>
                </div>
            </section>
            <section className="result-section detail">
                <div className="inner">
                    <div className="section-title">
                        <h3 className="tit"><i className="title-emoji"
                                               style={{backgroundImage: `url("/elandedu/assets/images/imoji/writing_hand.png")`}}/> 역량별
                            세부 평가</h3>
                        <p className="desc">
                            TIP 1. 각 직무에서 필요한 역량을 다르기 때문에 개인별로 뜨는 세부 역량이 다릅니다.<br/>
                            TIP 2. 나의 직무에서 필요한 역량 중, 스스로 평가한 내용을 바탕으로 상위 2개는 강한 고리로, 하위 2개는 약한 고리로
                            표현됩니다.<br/>
                            TIP 3. 역량별 백분위 결과는, 각 역량이 필요한 직무의 직원들과의 상대적인 수치이므로, 실시간으로 변경됩니다.
                        </p>
                    </div>
                    <div className="section-content">
                        <div className="detail-result">
                            <JobSkillResultRadarChart resultReport={resultReport.list}
                                                      skillCodeList={skillCodeList}/>
                            <div className="text-wrap">
                                {
                                resultReport.currentAvg > 30 &&
                                        strengthAndWeaknessSection("강한고리",
                                            "STRENGTH",
                                            "blue",
                                            () => resultReport.currentAvg >= 95 ? getStrengthStr() : getNormalStrengthStr()
                                        )

                                }
                                {
                                    resultReport.currentAvg < 95 &&
                                        strengthAndWeaknessSection("약한고리",
                                            "WEAKNESS",
                                            "red",
                                            () => resultReport.currentAvg < 30 ? getWeaknessStr() : getNormalWeaknessStr()
                                        )
                                }
                            </div>
                        </div>
                        <ul className="rank-list">
                            {
                                resultReport.list.map(val => {
                                    const codeName = skillCodeList.find(code=>code.code === val.skillCode)?.codeName ?? "";
                                    return <JobSkillResultRankItem key={val.skillCode}
                                                                   rank={val.rank}
                                                                   totalCount={val.totalCount}
                                                                   skillName={codeName}
                                                                   linkValid={false}
                                                                   score={val.score}/>
                                })
                            }

                        </ul>
                    </div>
                </div>
            </section>
            <JobSkillResultMentoring jobName={resultReport.jobCode.codeName}
                                     strengths={getWeaknessAndStrengthList("STRENGTH").join(", ")}
                                     weaknesses={getWeaknessAndStrengthList("WEAKNESS").join(", ")}/>
            {
                resultReport.currentAvg > 0 && (
                    <>
                        <JobSkillContentRecommend weakList={getWeakListStr()}/>
                        <JobSkillResultProRecommend weakList={getWeakListStr()}/>
                    </>
                )
            }
            <MypageNav currentMenu={"jobskill"}/>
        </main>
    )
}

export default JobSkillResult

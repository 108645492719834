import React from "react";
import {EduMainVO} from "../../../core/education/education.vo";
import StringUtils from "../../../common/utils/string.utils";
import {Link} from "react-router-dom";
import moment from "moment";
import {HWMap} from "../../../core/common/common.vo";
import educationService from "../../../core/education/education.service";

interface MainEduStatusListItemProps {
    type:string
    item?:EduMainVO
}

const MainEduStatusListItem = (props: MainEduStatusListItemProps) => {
    const {type, item} = props;
    
    
    let scheduleTxt:HWMap = {}
    if ( item ) {
        scheduleTxt = educationService.getScheduleText( item );
    }
    
    // 뱃지 생성
    const createBadge = () => {
        switch (type) {
            case "major":
                return <span className="badge type1 red">필수교육</span>;
            case "target":
                return <span className="badge type1 orange">지정교육</span>;
            case "free":
                return <span className="badge type1 green">자율참여</span>;
            default:
                return null;
        }
    };

    // 상태값 생성
    const createState = () => {
        if (item && item.answerCount == item.contentCount) {
            return <span className="state complete">완료</span>
        } else {
            return <span className="state">미완료</span>
        }
    };

    return (
        <li>
            { createBadge() }
            <div>
                <Link to={"/education/" + (item?.emIdx ?? "") + "/0/" + StringUtils.titleUrlFilter(item?.eduTitle ?? "")}>
                    <strong className="tit"
                            style={{
                                maxWidth: '400px'
                            }}
                    >{item && item.eduTitle}</strong>
                    <span className="date">{scheduleTxt.startDateTxt} ~ {scheduleTxt.endDateTxt}</span>
                </Link>
            </div>
            { createState() }
        </li>
    )
}

export default MainEduStatusListItem;
import APIs from "../apis/APIs";
import {BoardFileVO, BoardVO} from "../../core/board/board.vo";
import ToastUtils from "./toast.utils";
import {CardFileVO} from "../../core/education/education.vo";

class FileUtils {
    
    
    static getRealfilename = ( _url:string ) => {
        return _url.substring( _url.lastIndexOf("/") + 1, _url.length )
    }
    
    
    static getExtention = async ( _fileName:string ) => {
        return _fileName.substring( _fileName.lastIndexOf(".") + 1, _fileName.length )
    }
    
    
    static getRealfilePath = ( _url:string ) => {
        return _url.substring( 0, _url.lastIndexOf("/") + 1 )
    }
    
    
    
    static doDownload = async (_url:string, _filename:string) => {
        
        try {
            const response = await fetch(_url, {
                method: 'GET',
                headers: {
                    // 필요한 경우 인증 헤더 추가
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });
            
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            
            // 콘텐츠 디스포지션에서 파일 이름을 추출하거나 수동으로 설정
            const contentDisposition = response.headers.get('Content-Disposition');
            let fileName = _filename;
            
            if (contentDisposition) {
                const match = contentDisposition.match(/filename="(.+)"/);
                if (match && match[1]) {
                    fileName = match[1];
                }
            }
            
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);
            
        } catch (error) {
            console.error('Failed to download file:', error);
        }
    };
    
    
    
    
    
    static validFileAndAlert = (fileInputEle: HTMLInputElement | null, max:number, mbSize:number, successFunc: (file:File | null) => void) => {
        if (!fileInputEle || !fileInputEle.files || fileInputEle.files.length === 0) {
            successFunc(null)
            return
        }

        if (!this.isValidNum(fileInputEle,max)) {
            ToastUtils.show("파일은 최대 " + max + "개만 입력이 가능합니다.")
            return
        }

        const file = fileInputEle.files[0];

        if (!this.isValidSize(fileInputEle, mbSize)) { // 100MB 초과
            ToastUtils.show("파일 용량이 " + mbSize + "MB를 초과했습니다.")
            return
        }

        successFunc(file)
        return
    };



    static isValidNum = (fileInputEle: HTMLInputElement | null, max: number, min:number = 0) => {
        return (fileInputEle && fileInputEle.files && fileInputEle.files.length >= min && fileInputEle.files.length <= max)
    }

    static isValidSize = (fileInputEle: HTMLInputElement | null, mbSize: number) => {
        const file =fileInputEle && fileInputEle.files && fileInputEle.files[0];
        if (file == null){
            return false
        }

        if (file.size > mbSize * 1024 * 1024) {
            return false
        }

        return true
    }

    static onErrorImg = (e:any)=>{
        if (e.target.src !== process.env.PUBLIC_URL + '/elandedu/assets/images/common/img_profile.png'){
            e.target.src = process.env.PUBLIC_URL + '/elandedu/assets/images/common/img_profile.png'
        }
    }

    static onErrorBoardImg = (e:any)=>{
        if (e.target.src !== APIs.BOARD_ERROR_IMG){
            e.target.src = APIs.BOARD_ERROR_IMG
        }
    }

    static getFileUrl = (fileUrl:string)=>{
        if (fileUrl && fileUrl.includes("http")) {
            return fileUrl
        }
        return APIs.RESOURCRE_URL + fileUrl
    }


    /**
     * 직무 자료실 랜덤 이미지
     */
    static getRandomJobDataImg = (board: BoardVO)=>{
        const imagePaths = [
            process.env.PUBLIC_URL + '/elandedu/assets/images/content/Basic_Green_W.png',
            process.env.PUBLIC_URL + '/elandedu/assets/images/content/Basic_Blue_W.png',
            process.env.PUBLIC_URL + '/elandedu/assets/images/content/Basic_Orange_M.png',
            process.env.PUBLIC_URL + '/elandedu/assets/images/content/Basic_Purple_W.png',
            process.env.PUBLIC_URL + '/elandedu/assets/images/content/Basic_Red_M.png'
        ]

        // https://github.com/innople-ebot/elandedu/issues/275#issuecomment-2244351931 에 의해 변경
        const randomIndex = (board.idx % imagePaths.length)
        return imagePaths[randomIndex];
    }


    static getImageSrc = (thumbFile: BoardFileVO | CardFileVO | null) => {
        if (!thumbFile) {
            return APIs.BOARD_ERROR_IMG;
        }
        return thumbFile.isS3
            ? thumbFile.s3RealLink
            : `${APIs.API_URL}/${thumbFile.webPath}/${thumbFile.realfilename}`;
    };

    static getCardImageSrc = (thumbFile: CardFileVO | null) => {
        if (!thumbFile) {
            return APIs.BOARD_ERROR_IMG;
        }

        return thumbFile.isS3
            ? thumbFile.s3RealLink
            : `${APIs.API_URL}/${thumbFile.webPath}/${thumbFile.realfilename}`;
    };


    // api 통하여 보여줌
    static getFileByFidx = (thumbFile: BoardFileVO | null) => {
        if (!thumbFile) {
            return APIs.BOARD_ERROR_IMG;
        }
        return `${APIs.BOARD_FILES_SHOW}${thumbFile.fidx}`;
    };

    // api 통하여 보여줌
    static showEduCard = (cardFile: CardFileVO | null) => {
        if (!cardFile) {
            return APIs.BOARD_ERROR_IMG;
        }
        return `${APIs.FILES_EDU_CARD_SHOW}${cardFile.fidx}`;
    };



}

export default FileUtils
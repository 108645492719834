import {SessionVO} from "../../core/common/session.vo";
import {CompanyAccountVO} from "../../core/company/company.vo";
import PointService from "../../core/point/point.service";
import AlarmService from "../../core/alarm/alarm.service";

class MemberUtils {

	static AccountRate = [
		{rateCode: "200201", value : "NORMAL", codeName:""},
		{rateCode: "200202", "value" : "SEMI", codeName:"예비고수"},
		{rateCode: "200203", "value" : "PRO1", codeName:"사내고수"},
		{rateCode: "200204", "value" : "PRO2", codeName:"사내고수"},
		{rateCode: "200205", "value" : "PRO3", codeName:"사내고수"},
		{rateCode: "200206", "value" : "PRO4", codeName:"사내고수"},
		{rateCode: "200207", "value" : "PRO5", codeName:"사내고수"}
	]

	static getProStr(rateCode: string){
		let find = this.AccountRate.find(val=> val.rateCode === rateCode);
		return find ? find.codeName : ""
	}

	static isProRateCode(rateCode: string): boolean {
		switch (rateCode){
			case null :
				return false
			case "" :
				return false
			case "200201" :
				return false
			case "200202" :
				return false
			default:
				return true
		}
	}

	static isSemiProRateCode(rateCode: string): boolean{
		return rateCode === "200202"
	}

	static isPro() : boolean {
		let session = this.getSessionVO();
		if (!session){
			return false
		}
		return this.isProRateCode(session.rateCode)
	}

	static isSemiPro() {
		let session = this.getSessionVO();
		if (!session){
			return false
		}
		return this.isSemiProRateCode(session.rateCode)
	}

	static getSessionVO() {
		const sessionStr = localStorage.getItem('sessionVO');
		if (!sessionStr){
			return null
		}

		const sessionVO: SessionVO = JSON.parse(sessionStr)
		return sessionVO;
	}

	static getAccessToken() {
		const accessToken = localStorage.getItem('token');
		if (!accessToken){
			return ""
		}
		return accessToken;
	}

	static setSessionVO(account:CompanyAccountVO | SessionVO) {
		localStorage.setItem("sessionVO", JSON.stringify(account))
	}

	static getCoInfoVO() {
		const sessionVO = this.getSessionVO();
		return sessionVO && sessionVO.coInfoVO
	}

	static getLoginId():string {
		const sessionStr = localStorage.getItem('sessionVO');
		if (!sessionStr){
			return ''
		}

		const session = JSON.parse(sessionStr)
		return session.coEmailId;
	}

	static getProfileImgSrc():string {
		const session = this.getSessionVO()
		if (session && session.profileSrc){
			return session.profileSrc;
		}

		return ""
	}

	/**
	 * 로그인 상태 확인하는 메서드
	 * @returns {boolean} 로그인 상태라면 true, 아니라면 false
	 */
	static isLogin(): boolean {
		return MemberUtils.getLoginId() != ""
	}

	static isCompleteJobSkill(){
		const item = localStorage.getItem("isCompleteJobSkill");
		return item === 'true'

	}

	static setIsCompleteJobSkill(isComplete:boolean){
		localStorage.setItem("isCompleteJobSkill", isComplete.toString());

	}

	static async getNotifCount(){
		const result = await AlarmService.getNotifCount();

		if(result.status == "SUCCESS" && result.data) {
			return result.data

		} else {
			console.log("notif 호출 살패", result.msg);
		}

		return 0;
	}

	static async getMyPoint(){
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = currentDate.getMonth();
		const quarter = Math.floor(month / 3) + 1
		const months = [
			"1,2,3", // 1분기
			"4,5,6", // 2분기
			"7,8,9", // 3분기
			"10,11,12"  // 4분기
		];

		const result = await PointService.getAccountPointDetail({searchYear: year, searchMonth: months[quarter - 1]});

		if(result.status == "SUCCESS" && result.data) {
			let totalPoints = 0;

			for (const item of result.data.pointType) {
				if (item.pointType === 'EVENT' || item.pointType === 'EDU') {
					totalPoints += item.totalPoints;
				} else if (item.pointType === 'CALCULATE') {
					// 포인트가 -9999와 같은 형태로 마이너스가 붙어서 내려와서 += 로 더함
					totalPoints += item.totalPoints;
				}
			}
			return totalPoints;
		} else {
			console.log("point 호출 살패", result.msg);
		}

		return 0;
	}

	static clearLocalStorageExcept =(keysToKeep: string[]): void => {
		const savedValues: { [key: string]: string | null } = keysToKeep.reduce((acc, key) => {
			acc[key] = localStorage.getItem(key);
			return acc;
		}, {} as { [key: string]: string | null });

		localStorage.clear();

		keysToKeep.forEach(key => {
			if (savedValues[key] !== null) {
				localStorage.setItem(key, savedValues[key]!);
			}
		});
	}


}
export default MemberUtils;
import {CompanyAccountVO, DefaultCompanyAccountVO} from "../company/company.vo";

export interface ProReqVO {

    proidx: number
    companyAccountVO?: CompanyAccountVO
    profileImgSrc: string
    jobCode: string
    skillCode: string
    workingYear: number

    strengthKeyword: string
    officialKeyword: string
    proLevel: number
    proInfo: string
    // adminMemo: string
    chatTopic: string
    permitProDate: Date
    recentActivityDate: Date

    followerCount: number
    chatCount: number
    uploadCount: number
    reviewCode: string | null

    //임시 변수
    jobCodeName: string
    skillCodeName: string[]


}

export function DefaultProReqVO() {
    const defaultVO: ProReqVO = {
        chatCount: 0,
        chatTopic: "",
        companyAccountVO: DefaultCompanyAccountVO(),
        followerCount: 0,
        jobCode: "",
        jobCodeName: "",
        officialKeyword: "",
        permitProDate: new Date(),
        proInfo: "",
        proLevel: 0,
        profileImgSrc: "",
        proidx: 0,
        recentActivityDate: new Date(),
        skillCode: "",
        skillCodeName: [],
        strengthKeyword: "",
        uploadCount: 0,
        workingYear: 0,
        reviewCode: "",
    }
    return defaultVO
}

import React, {useEffect, useState} from "react";
import CodeUtils from "../../../common/utils/code.utils";
import {CodeVO} from "../../../core/common/code.vo";
import CommonJS from "../../../common/common";
import ElandUtils from "../../../common/utils/eland.utils";
import {CodeCountDto} from "../../../core/board/board.vo";


interface JobSkillFilterSelectButtonProps {
    isSkill: boolean;
    updateJobCode:(newJobCode: string) => void;
    updateSkillCode:(newSkillCode: string) => void;
    deactivate?: string;
    initCode?: string;
    placeholder?: string;
    isFilter: boolean;
    countList?: CodeCountDto[]
}

const JobSkillFilterSelectButton = (props: JobSkillFilterSelectButtonProps) => {
    const {deactivate = '', isSkill, updateJobCode, updateSkillCode, isFilter} = props;
    const [deactivateCode, setDeactivateCode] = useState<string>(deactivate)
    const [jobCodeList, setJobCodeList] = useState<CodeVO[]>([]);
    const [skillCodeList, setSkillCodeList] = useState<CodeVO[]>([]);
    const [initCode, setInitCode] = useState<string>("")
    const [countList, setCountList] = useState<CodeCountDto[]>([])

    useEffect(() => {
        if (!isSkill){
            getJobCodeList()
        } else {
            getSkillCodeList()
        }

        // innderHtml 기능을 사용할 경우 지식콘텐츠에서 랜더링이 안되는 이벤트 발생
        // 따라서 isFilter 변수를 받아 지식콘텐츠에서 해당 기능 비활성화
        CommonJS.selectV2(!isFilter)
    }, []);

    useEffect(() => {
        setDeactivateCode(deactivate)
    }, [deactivate]);

    const getJobCodeList = async () => {
        let jobSkillCodeList = await ElandUtils.getJobSkillCodeList(false)
        setJobCodeList(jobSkillCodeList)
    }

    const getSkillCodeList = async () => {
        let jobSkillCodeList = await ElandUtils.getJobSkillCodeList(true)
        setSkillCodeList(jobSkillCodeList)
    }


    useEffect(() => {
        setInitCode(props.initCode ?? "")

    }, [props.initCode])


    useEffect(() => {
        if (props.countList && props.countList.length > 0){
            setCountList(props.countList)

        }

    }, [props.countList])

    const getCodeCount = (code: string) => {
        if (countList.length <= 0){
            return ''
        }

        let codeVO = countList.find((vo)=>vo.code === code)
        return codeVO ? `(${codeVO.count})` : '(0)'
    }

    const getTotalCount = () => {
        return countList.find(vo=> vo.code === 'totalCount')?.count
    }

    if (isSkill){
        return (

            <div className="select">
                <button type="button" className="btn-select">
                    <span style={((!initCode || initCode === '') && props.placeholder) ?
                        {color:"var(--gray-40)"} : {} }>
                        {
                        !initCode || initCode === ''
                            ? (props.placeholder ?? '직무 역량 전체')
                            : (skillCodeList.find((codeVO) => codeVO.code === initCode))?.codeName
                        }
                    </span>
                </button>
                <ul className="select-option" style={{zIndex:20}}>
                    <li className={!initCode ? "selected" : ""}>
                        {
                            !props.placeholder && (
                                <a href="/public" onClick={(e) => {
                                    e.preventDefault()
                                    updateSkillCode("")
                                }}>
                                    <span>직무 역량 전체</span>
                                    <span>{countList && countList.length > 0 ? `(${getTotalCount()})` : ''}</span>
                                </a>
                            )
                        }
                    </li>
                    {
                        skillCodeList.map((code, index) => {
                            if (deactivateCode != code.code) {
                                return (
                                    <li key={index}
                                        className={initCode == code.code ? "selected" : ""}>
                                    <a href="/public" onClick={(e) => {
                                            e.preventDefault()
                                            updateSkillCode(code.code)
                                        }}>
                                            <span>{code.codeName}</span>
                                            <span>{getCodeCount(code.code)}</span>
                                        </a>
                                    </li>
                                )
                            }
                        })
                    }
                </ul>
            </div>
        )
    } else {
        return (
            <div className="select">
                <button type="button" className="btn-select">
                    <span style={props.placeholder ? {color: "var(--gray-40)"} : {}}>
                        {
                            !initCode || initCode === ''
                                ? (props.placeholder ?? '직무 구분 전체')
                                : (jobCodeList.find((codeVO) => codeVO.code === initCode))?.codeName
                        }
                    </span>
                </button>
                <ul className="select-option" style={{zIndex:20}}>
                    {
                        !props.placeholder && (
                            <li className={!initCode || initCode === '' ? "selected" : ""}>
                                <a href="/public" onClick={(e) => {
                                    e.preventDefault()
                                    updateJobCode("")
                                }}>
                                    <span>직무 구분 전체</span>
                                    <span>{countList && countList.length > 0 ? `(${getTotalCount()})` : ''}</span>
                                </a>
                            </li>
                        )
                    }
                    {
                        jobCodeList.map((code, index) => {
                            return (
                                <li key={index} className={initCode == code.code ? "selected" : ""}>
                                    <a href="/public" onClick={(e) => {
                                        e.preventDefault()
                                        updateJobCode(code.code)
                                    }}>
                                        <span>{code.codeName}</span>
                                        <span>{getCodeCount(code.code)}</span>
                                    </a>
                                </li>
                            )
                        })

                    }

                </ul>
            </div>
        )
    }

}

export default JobSkillFilterSelectButton;
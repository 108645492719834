import React, {useEffect, useState} from "react";
import CommonJS from "../../../common/common";


interface SelectOption {
    value: string | number;
    name: string;
}

interface SelectProps {
    options: SelectOption[];
    updateOption:(option: string | number) => void;
}

const CommonSelect: React.FC<SelectProps> = ({ options, updateOption }) => {
    const [selectedValue, setSelectedValue] = useState<string | number>(options[0].value);

    const handleSelect = (value: string | number) => {
        setSelectedValue(value);
    };

    useEffect(() => {
        CommonJS.select()
    }, []);

    return (
        <div className="select">
            <button type="button"
                    className="btn-select">{options.find(option => option.value === selectedValue)?.name}</button>
            <ul className="select-option">
                {options.map(option => (
                    <li key={option.value} className={option.value === selectedValue ? 'selected' : ''}>
                        <a href="#" onClick={(e) => {
                            e.preventDefault()
                            updateOption(option.value)
                        }}>
                            <span>{option.name}</span>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CommonSelect;
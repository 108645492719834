import React, {useEffect, useRef, useState} from "react";
import CommonJS from "../../../../../common/common";
import {CodeVO} from "../../../../../core/common/code.vo";
import {useLocation, useNavigate} from "react-router-dom";
import M_SummernoteIframe, {SummernoteIframeHandle} from "../../../../mobile/common/M_SummernoteIframe";
import MemberUtils from "../../../../../common/utils/member.utils";
import MypageService from "../../../../../core/mypage/mypage.service";
import PointService from "../../../../../core/point/point.service";
import ToastUtils from "../../../../../common/utils/toast.utils";
import ElandUtils from "../../../../../common/utils/eland.utils";
import StringUtils from "../../../../../common/utils/string.utils";
import M_BottomButton from "../../../common/M_BottomButton";
import FileUploader from "../../../../pc/common/FileUploader";
import useHeader from "../../../../../common/hook/useHeader";
import {HeaderBtn} from "../../../../../core/common/header";
import useFooter from "../../../../../common/hook/useFooter";

const M_ProHelpForm = () => {
    useHeader({buttons: [HeaderBtn.Back], title: "게시글 작성", type: 'content'})
    useFooter({isHidden: true})

    const navigate = useNavigate()
    const location = useLocation()
    const [boardVO, setBoardVO] = useState((location.state && location.state.board) ? {...location.state.board} : null)
    const [fidxs, setFidxs] = useState<string>((location.state && location.state.fidxs) ? location.state.fidxs : "")
    const [jobCodeList, setJobCodeList] = useState<CodeVO[]>([])

    const boardKey = "pro_help"
    const [title, setTitle] = useState<string>("")
    const [content, setContent] = useState<string>("")
    const [jobCode, setJobCode] = useState<string>('')
    const [cateCode, setCateCode] = useState<string>('')

    const iframeRef = useRef<SummernoteIframeHandle>(null);


    useEffect(() => {
        getJobCodeList().then(() => {
                readyToUpdate()
                CommonJS.inputFile('.file-upload-gray')
                CommonJS.select()
            }
        )

    }, []);

    const getJobCodeList = async () => {
        let jobSkillCodeList = await ElandUtils.getJobSkillCodeList(false)
        setJobCodeList(jobSkillCodeList)
    }

    const readyToUpdate = () => {
        if (boardVO) {
            setTitle(boardVO.title)
            setContent(boardVO.contentHtml)
            setCateCode(boardVO.cateCode ?? '')
            setJobCode(boardVO.jobCode)
            setFidxs(fidxs)

        }
        CommonJS.select()
    }


    const handleUploadAttachFile = (idxs:string) => {
        setFidxs(idxs)
    }

    const checkInputFill = () => {
        return !StringUtils.isEmpty(title) &&
            !StringUtils.isEmpty(content) &&
            !StringUtils.isEmpty(jobCode) &&
            !StringUtils.isEmpty(cateCode)
    }

    const saveHelpBoard = async () => {
        let content = iframeRef.current?.getHtmlContent() ?? ""
        let params = {
            boardKey: boardKey ?? "",
            title: title,
            content: content,
            coCode: MemberUtils.getSessionVO()?.coCode ?? "",
            jobCode: jobCode,
            cateCode: cateCode,
            fidxs: fidxs
        }

        let successHandler = async (result:any) => {
            await PointService.savePoint("HELP_POST", result.data ?? "0");
            showComplete()
        }

        await MypageService.boardOneSave(params, successHandler)

    }

    const updateHelpBoard = async () => {
        const bidx = boardVO.idx.toString()
        let params = {
            boardKey: boardKey ?? "",
            title: title,
            content:iframeRef.current?.getHtmlContent() ?? "",
            jobCode: jobCode,
            cateCode: cateCode,
            fidxs: fidxs
        }

        await MypageService.boardOneUpdate(bidx, params, showComplete)

    }


    const showComplete = () => {
        ToastUtils.show("입력하신 내용이 저장되었습니다.")
        navigate('/pro/help/', {replace: true});
    }

    const updateContent = (content:string) => {
        setContent(content)
    }

    const titleOnChangeEvent = (event:React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        if (input.length <= 50) {
            setTitle(input);
        }
    }


    return (
        <main className="content register">
            <section className="form-section">
                <div className="inner">
                    <div className="form-list">
                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">직무</strong>
                            </div>
                            <div className="sel-bind">
                                <select name="sort" className="sel"
                                        value={jobCode}
                                        style={{color:jobCode === '' ? "var(--gray-40)" : ""}}
                                        onChange={e => {
                                            setJobCode(e.target.value)
                                        }}>
                                    <option value="" hidden disabled>직무를 선택해주세요</option>
                                    {
                                        jobCodeList.map((code, index) => {
                                            return (
                                                <option key={code.code}
                                                        value={code.code}>
                                                    {code.codeName}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">분류</strong>
                            </div>
                            <div className="sel-bind">
                                <select name="sort" className="sel"
                                        value={cateCode}
                                        style={{color:cateCode === '' ? "var(--gray-40)" : ""}}
                                        onChange={e => setCateCode(e.target.value)}>
                                    <option value="" hidden disabled>게시글의 분류를 선택해주세요</option>
                                    <option value={"040401"}>도움요청</option>
                                    <option value={'040402'}>지식나눔</option>
                                    <option value={"040403"}>자유주제</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">제목</strong>
                            </div>
                            <div className="inp-bind">
                                <input type="text" name="" id=""
                                       className="inp-text"
                                       onChange={titleOnChangeEvent}
                                       value={title}
                                       placeholder="제목을 입력해주세요"/>
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">내용</strong>
                            </div>
                            <div className="inp-bind">
                                <M_SummernoteIframe ref={iframeRef} mode={"edit"}
                                                    htmlCode={boardVO?.contentHtml ?? ""}
                                                    updateEvent={updateContent}/>
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">첨부파일</strong>
                            </div>
                            <FileUploader params={{
                                domId: `boardVO-attach`,
                                domText: `첨부파일을 업로드 해주세요`,
                                target: `board`,
                                fidxs: fidxs,
                                maxFileSize: 1024 * 1024 * 100,
                                maxFileCount: 1,
                                updateHandler: (_target: string, _files: string) => {
                                    console.log(_target + " ::::: " + _files)
                                    handleUploadAttachFile(_files);
                                }
                            }}/>
                            <div className="guide-text">
                                파일크기 : 100MB이하 / 1개 파일만 등록 가능합니다. 여러개일 경우 ZIP파일로 압축하여 업로드해주세요
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <M_BottomButton name={"등록하기"}
                            onClick={boardVO ? updateHelpBoard : saveHelpBoard}
                            disabled={!checkInputFill()}/>
        </main>
    )
}

export default M_ProHelpForm


import MemberUtils from "../../../common/utils/member.utils";
import {Navigate, useNavigate} from "react-router-dom";
import React, {ReactNode, useEffect, useState} from "react";
import SwalUtils from "../../../common/utils/swal.utils";
import Landing from "../etc/Landing";


/**
 * M_AuthGuard 컴포넌트
 * 특정 컴포넌트를 렌더링하기 전에 사용자가 로그인했는지 검증합니다.
 * 사용자가 로그인하지 않은 경우 로그인 페이지로 리다이렉트하거나 이전 페이지로 이동합니다.
 *
 * 사용법:
 * <M_AuthGuard>
 *   <ProtectedComponent />
 * </M_AuthGuard>
 *
 * @param {children: ReactNode} props - 자식 컴포넌트를 포함하는 props
 * @returns 로그인된 경우 자식 컴포넌트, 그렇지 않으면 로그인 페이지로 리다이렉트하거나 이전 페이지로 이동
 */
const AuthGuard: React.FC<{ children: ReactNode }> = ({ children }) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (MemberUtils.getSessionVO() == null) {
            SwalUtils.text({
                title : "로그인",
                message : "<p style='line-height: 150%'>로그인 사용자만 접근 가능합니다.<br> 로그인 페이지로 넘어가시겠습니까?</p>",
                isIcon : false,
                confirmOptions : {
                    text : "확인",
                    eventHandler : () => {navigate("/login/")}
                },
                cancelOptions : {
                    text : "취소",
                    eventHandler : () => {navigate(-1)}
                },
                isCancel: true
            })
        }

    }, [navigate]);

    if (MemberUtils.getSessionVO() == null) {
        return <Landing />; // 세션이 없으면 아무것도 렌더링하지 않음
    }

    return <>{children}</>;
};

export default AuthGuard;
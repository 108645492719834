import { configureStore } from '@reduxjs/toolkit'
import {sessionSlice} from "./slices/sessionSlice";
import headerReducer from "./slices/headerSlice";
import footerSlice from "./slices/footerSlice";


// 스토어 설정
export const store = configureStore({
	reducer: {
		session :sessionSlice.reducer,
		header: headerReducer,
		footer: footerSlice
	},

})




// RootState와 AppDispatch 타입 추론
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
import React from 'react';
import M_MainBanner from "./M_MainBanner";
import M_MainEduStatus from "./M_MainEduStatus";
import M_MainProRecommendEmpty from "./M_MainProRecommendEmpty";
import MemberUtils from "../../../common/utils/member.utils";
import M_MainJobSkillResult from "./M_MainJobSkillResult";
import M_MainContentRecommend from "./M_MainContentRecommend";
import M_MainProRecommend from "./M_MainProRecommend";
import M_MainEditorContents from "./M_MainEditorContents";
import M_MainProNewContents from "./M_MainProNewContents";
import M_MainNewContents from "./M_MainNewContents";
import M_MainUseStory from "./M_MainUseStory";
import M_MainBoard from "./M_MainBoard";
import useHeader from "../../../common/hook/useHeader";
import {HeaderBtn} from "../../../core/common/header";
import useFooter from "../../../common/hook/useFooter";
import M_MainFooter from "./M_MainFooter";
import M_MainSystemItem from "./M_MainSystemItem";

const M_Main: React.FC = () => {

    useHeader({
        type: 'main',
        buttons: [HeaderBtn.Search, HeaderBtn.Notify, HeaderBtn.Menu]
    });

    useFooter({
        isHidden: false
    })

    return (
        <>
            <main id="main" className="content main">
                {/* 배너 */}
                <M_MainBanner/>

                {/* 진행중인 필수교육 */}
                <M_MainEduStatus/>

                {MemberUtils.isCompleteJobSkill() && (
                    <>
                        {/* 000 님의 역량 향상을 위한 */}
                        <M_MainContentRecommend/>

                        {/* 추천 사내고수 */}
                        <M_MainProRecommend/>
                    </>
                )}

                {/* 직무역량진단 */}
                {
                    MemberUtils.isLogin() && (
                        MemberUtils.isCompleteJobSkill() ? <M_MainJobSkillResult/> :
                            <M_MainProRecommendEmpty/>
                    )
                }

                {/*인재원 플레이리스트*/}
                <M_MainEditorContents type="recommend"/>

                {/* 법인 플레이리스트 */}
                {
                    MemberUtils.isLogin() && <M_MainEditorContents type="company"/>
                }

                {/* 사내고수 업로드 신규 콘텐츠 */}
                <M_MainProNewContents/>

                {/*추천 Pick*/}
                <M_MainEditorContents type="pick"/>

                {/* 신규콘텐츠 */}
                <M_MainNewContents/>

                {/*적용스토리*/}
                {
                    MemberUtils.isLogin() ? <M_MainUseStory/> : <></>
                }

                {/*게시판*/}
                {
                    MemberUtils.isLogin() && <M_MainBoard/>
                }

                {
                    !MemberUtils.isLogin() && (
                        <div className="system-section">
                            <div className="inner">
                                <ul className="system-list">
                                    <M_MainSystemItem type={"point"}/>
                                    <M_MainSystemItem type={"master"}/>
                                </ul>
                            </div>
                        </div>
                    )
                }

            </main>
            <M_MainFooter/>
        </>
    )
};

export default M_Main;

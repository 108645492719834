import React, {useEffect, useRef, useState} from "react";
import MemberUtils from "../../../../../common/utils/member.utils";
import memberUtils from "../../../../../common/utils/member.utils";
import {BoardVO} from "../../../../../core/board/board.vo";
import M_ProEduTalkRow from "./M_ProEduTalkRow";
import {useSearchParams} from "react-router-dom";
import ProService from "../../../../../core/pro/pro.service";
import useFooter from "../../../../../common/hook/useFooter";
import Swiper from "../../../../../assets/js/swiper.min";
import useHeader from "../../../../../common/hook/useHeader";
import {HeaderBtn} from "../../../../../core/common/header";
import M_InfinityScroll from "../../../common/M_InfinityScroll";
import axiosCaller from "../../../../../common/utils/axios.caller";
import APIs from "../../../../../common/apis/APIs";
import {HWMap} from "../../../../../core/common/common.vo";
import WebUtils from "../../../../../common/utils/web.utils";

const M_ProEduTalk = () => {
    useHeader({
        type: `sub`,
        title: `나의 에듀톡`,
        buttons: [
            HeaderBtn.Menu
        ]
    });
    useFooter({isHidden: false})
    let [query, setQuery] = useSearchParams();
    const scrollRef = useRef<{ reload: () => void, saveScroll:(index:number)=>void}>(null);

    const [pageParams, setPageParams] = useState({
        page : parseInt(query.get('page') ?? "0"),
        size : parseInt(query.get('size') ?? "10"),
        totalCount: parseInt(query.get('totalCount') ?? "0"),
        }
    )
    const [totalCount, setTotalCount] = useState<number>(0)
    const [statusCode, setStatusCode] = useState<string>(query.get('statusCode') ?? "")
    const [isReceive, setIsReceive] = useState<boolean>(
        (!query.get('isReceive') ? MemberUtils.isPro() : query.get('isReceive')==="true" && MemberUtils.isPro())
    )
    const [boardList, setBoardList] = useState<BoardVO[]>([])
    const [tabMenuList, setTabMenuList] =
        useState<{ name: string, code: string, count: number }[]>([
            {name: '전체', code: "", count: 0},
            {name: '미해결', code: "041901", count: 0},
            {name: '진행중', code: "041902", count: 0},
            {name: '일지작성', code: "041903", count: 0},
            {name: '완료', code: "041904", count: 0},
            {name: '취소', code: "041905", count: 0}
        ])

    const [isNewArr, setIsNewArr]
        = useState<{receiver:boolean, writer:boolean}>({receiver: false, writer: false})

    useEffect(() => {
        getIsNewBadge()
        initSwiper()

    }, []);

    useEffect(() => {
        updateParams()

    }, [statusCode, isReceive]);

    useEffect(()=>{
        WebUtils.findCenter(true, statusCode)

    },[statusCode])


    const initSwiper = () => {
        new Swiper(`.tab_swiper`, {
            slidesPerView: "auto",
            preventClicks: true,
            preventClicksPropagation: false,
            observer: true,
            observeParents: true
        });

        new Swiper(`.filter_swiper`, {
            slidesPerView: "auto",
            preventClicks: true,
            preventClicksPropagation: false,
            observer: true,
            observeParents: true
        });
    }

    const updateParams = () => {
        query.set("statusCode",statusCode)
        query.set("isReceive", isReceive.toString())

        setQuery(query)
    }

    const getIsNewBadge = async () => {
        let successHandler = (result: any) =>{
            setIsNewArr({receiver: result.data.receiver, writer: result.data.writer})
        }
        await ProService.inquiryNewGet(successHandler)
    }

    const fetchList = async (makeParamFunc:(params:HWMap, size:number) => HWMap) => {
        let paramStatus = statusCode
        if (statusCode === '041904' && !isReceive){
            paramStatus = '041903,041904'
        }

        let params = makeParamFunc({
            ...pageParams,
            statusCode: paramStatus,
            isReceive: isReceive
        }, pageParams.size)

        let result = await axiosCaller.get(APIs.PRO_INQUIRY, params);
        if (result.status === "SUCCESS"){
            setTotalCount(result.data.count)
            result.data.statusCount && updateStatusCount(result.data.statusCount)
            return result.data.list
        }

        return []
    }

    const updateStatusCount = (arr: { code: string, count: number }[]) => {
        let totalCount = 0
        for (let i = 0; i < arr.length; i++) {
            totalCount += arr[i].count
        }

        let completeCount = 0
        if (!isReceive){
            completeCount = arr.filter(val => val.code ==="041903" ||val.code ==="041904")
                .map(value => value.count)
                .reduce((sum, currValue)=>{return sum + currValue}, 0)

        }

        const newTabMenuList = [...tabMenuList];
        newTabMenuList.map(value => {
            if (value.code === '') {
                value.count = totalCount
                return
            }
            if(value.code === '041904' && !isReceive) {
                value.count = completeCount
                return
            }

            value.count = arr.find(value2 => value2.code === value.code)?.count ?? 0
        })

        setTabMenuList(newTabMenuList)

    }

    const updateReceiveTab = (is: boolean) => {
        scrollRef.current && scrollRef.current.reload()
        setIsReceive(is)
    }

    const updateTab = (statusCode: string) => {
        scrollRef.current && scrollRef.current.reload()
        setStatusCode(statusCode);
    }

    const getTab = () => {
        return (

            <ul className={memberUtils.isPro() ? "tab-list swiper-wrapper" : "tab-list single swiper-wrapper"}
                style={{marginBottom: '8px'}}>
                {
                    memberUtils.isPro() && (
                        <li className={`tab-item swiper-slide ${isReceive ? "active" : ""}`}>
                            <a href="#" onClick={e => {
                                e.preventDefault()
                                updateReceiveTab(true)
                            }}>요청받은 내역 {isNewArr.receiver && <i className="ico-new"/>}</a>
                        </li>
                    )
                }
                <li className={`tab-item swiper-slide ${!isReceive ? "active" : ""}`}>
                    <a href="#" onClick={e => {
                        e.preventDefault()
                        updateReceiveTab(false)
                    }}>요청한 내역{isNewArr.writer && <i className="ico-new"/>}</a>
                </li>
            </ul>
        )
    }

    return (
        <main id="main" className="content edutalk">

        <div className="tab-area">
                <div className="inner">
                    <div className="tab-slide tab_swiper">
                        {
                            getTab()
                        }
                    </div>
                </div>
            </div>
            <div className="filter-area border-10">
                <div className="inner">
                    <div className="filter-slide filter_swiper">
                        <ul className="filter-list swiper-wrapper">
                            {
                                tabMenuList.map((value, index) => {
                                    if (value.code === "041903" && !isReceive) {
                                        return
                                    }
                                    let status = statusCode
                                    if (!isReceive && (statusCode === "041903" || statusCode === "041903,041904")) {
                                        status = "041904"
                                    }

                                    return (
                                        (
                                            <li key={value.code}
                                                id={`filter-${value.code}`}
                                                className={status === value.code ? "filter-item swiper-slide active" : "filter-item swiper-slide"}>
                                                <a href="#" onClick={e => {
                                                    e.preventDefault()
                                                    updateTab(value.code)
                                                }}>{value.name} ({value.count})</a>
                                            </li>
                                        )
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <section className="list-section">
                <div className="inner">
                    <div className="board-list">
                        {
                            boardList.length > 0 ?
                                (<>
                                        <ul className="webzine-list type4">
                                            {
                                                boardList.map((value, index) => {
                                                    return (
                                                        <M_ProEduTalkRow boardVO={value}
                                                                         isReceive={isReceive}
                                                                         onclickEvent={()=>{
                                                                             scrollRef.current && scrollRef.current.saveScroll(index)
                                                                         }}
                                                                         key={value.idx} />
                                                    )
                                                })
                                            }
                                        </ul>
                                    </>
                                )
                                :
                                (
                                    <div className="content-empty">
                                        <i className="ico-content"></i>
                                        <p className="txt">
                                            {
                                                isReceive ? (
                                                    <>
                                                        내역이 없습니다.<br/>
                                                        에듀톡 문의가 들어오면 알림드릴게요!
                                                    </>
                                                ) : (
                                                    <>
                                                        내역이 없습니다.<br/>
                                                        에듀톡 문의를 통해<br/>
                                                        사내고수의 지식을 공유받아보세요!
                                                    </>
                                                )
                                            }
                                        </p>
                                    </div>
                                )
                        }
                    </div>
                </div>
                <M_InfinityScroll<BoardVO>
                    queryKey={"ProEduTalkList"}
                    update={fetchList}
                    defaultSize={pageParams.size}
                    totalCount={totalCount}
                    getList={val=>{setBoardList(val)}}
                    scrollRef={scrollRef} />
            </section>
        </main>
    )
}

export default M_ProEduTalk
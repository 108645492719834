import React, {useEffect, useState} from "react";
import MypageNav from "../common/MypageNav";
import BoardService from "../../../../core/board/board.service";
import {BoardVO, DefaultBoardInfoVO} from "../../../../core/board/board.vo";
import QuestionRow from "./QuestionRow";
import {BoardInfoVO} from "../../../../core/board/BoardInfo.vo";
import StringUtils from "../../../../common/utils/string.utils";

const FrequentlyQuestion = () => {

    const [noticeMap, setNoticeMap] = useState<BoardVO[]>([])
    const [boardMap, setBoardMap] = useState<BoardVO[]>([])
    const [boardInfo, setBoardInfo] = useState<BoardInfoVO>(DefaultBoardInfoVO())

    useEffect(() => {
        getQuestion()

    }, []);

    const getQuestion = async () => {
        const bs = new BoardService()
        const boardList = await bs.getBoardList({boardKey: "frequently"});

        if (boardList.status === "ok"){
            setNoticeMap(boardList.noticeMap.list)
            setBoardMap(boardList.boardMap.list)
            setBoardInfo(boardList.boardInfoVO)
        } else {
            console.log("오류발생")
            console.log(boardList.msg)
        }

    }

    return (
        <main className="content faq nav-layout">
            <div className="common-title">
                <div className="inner">
                    <h2 className="tit">자주 묻는 질문</h2>
                    <p className="desc" dangerouslySetInnerHTML={{__html: StringUtils.nl2br(boardInfo?.description ?? "")}}></p>
                </div>
            </div>
            <section className="list-section">
                <div className="inner">
                    <div className="faq-list">
                        {
                            noticeMap.map((value, index) => {
                                return (<QuestionRow key={value.idx} {...value}/>)
                            })
                        }
                        {
                            boardMap.map((value, index) => {
                                return (<QuestionRow key={value.idx} {...value}/>)
                            })
                        }
                    </div>
                </div>
            </section>
            <MypageNav currentMenu={"frequently"}/>
        </main>
    )
}

export default FrequentlyQuestion
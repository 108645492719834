import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import StringUtils from "../../../../common/utils/string.utils";
import BoardService from "../../../../core/board/board.service";
import {BoardVO} from "../../../../core/board/board.vo";
import DateUtils from "../../../../common/utils/date.utils";
import {SessionVO} from "../../../../core/common/session.vo";
import MemberUtils from "../../../../common/utils/member.utils";
import M_SummernoteIframe from "../../../mobile/common/M_SummernoteIframe";
import {CompanyAccountVO} from "../../../../core/company/company.vo";
import {ProReqVO} from "../../../../core/pro/pro.vo";
import axiosCaller from "../../../../common/utils/axios.caller";
import APIs from "../../../../common/apis/APIs";
import SwalUtils from "../../../../common/utils/swal.utils";
import ToastUtils from "../../../../common/utils/toast.utils";
import PointService from "../../../../core/point/point.service";
import FileUtils from "../../../../common/utils/file.utils";
import ElandUtils from "../../../../common/utils/eland.utils";
import M_UseStoryCard from "./M_UseStoryCard";
import useFooter from "../../../../common/hook/useFooter";

const m_UseStoryDetail: React.FC = () => {

    useFooter({
        isHidden: false
    })

    const boardService = new BoardService();
    const navigate = useNavigate();
    let {boardKey, bidx, boardTitle} = useParams();

    const [boardVO, setBoardVO] = useState<BoardVO>();
    const [sessionVO, setSessionVO] = useState<SessionVO | null>(MemberUtils.getSessionVO());
    const [writerInfo, setWriterInfo] = useState<CompanyAccountVO>();
    const [cardContent, setCardContent] = useState<BoardVO | ProReqVO>();
    const [renderKey, setRenderKey] = useState<number>(0);

    const deleteOne = (bidx : number) => {

        SwalUtils.text({
            title : `삭제하기`,
            message : `삭제 클릭 시 동작을 되돌릴 수 없습니다.<br />정말 적용스토리를 삭제하시겠습니까?`,
            isCancel : false,
            confirmOptions : {
                text : "확인",
                eventHandler : async() => {
                    // 삭제하시겠습니까?
                    const result = await boardService.deleteBoardOne({bidx: bidx});

                    if(result.status == "SUCCESS") {
                        // 포인트 취소
                        await PointService.cancelPoint("STORY_CREATE", bidx)
                        ToastUtils.show("삭제되었습니다.", 2 )
                        window.location.href = `/board/story/${boardKey}`;
                    } else {
                        ToastUtils.show("잠시 후 다시 시도해주세요.", 2 )
                        console.error(result.msg);
                    }
                }
            },
            cancelOptions: {
                text: "취소", eventHandler: () => {
                    console.log("취소 버튼")
                }
            }
        })


    }

    useEffect(() => {
        (async () => {
            if(!bidx) return;

            const result = await boardService.getBoardOne({bidx: bidx});

            if(result.status == "SUCCESS" && result.data) {
                const board = result.data.boardVO;

                if(!board.isUse) {
                    alert("삭제된 게시물 입니다.")
                    navigate(`/board/story/${boardKey}`);
                }

                setBoardVO(board);
                setWriterInfo(result.data.writerInfo);
                setRenderKey(renderKey + 1)
            }

        })()
    }, [bidx]);

    useEffect(() => {

        if(boardVO && boardVO.opt5) {
            // 지식콘텐츠 후기
            if(boardVO.cateCode == "040501") {
                (async () => {
                    const result = await boardService.getBoardOne({bidx: boardVO.opt5, isSaveHis: false});
                    if(result.status == "SUCCESS" && result.data) {
                        let updatedBoardVO = { ...result.data.boardVO, writerInfo: result.data.writerInfo, coInfoVO: result.data.writerInfo.coInfoVO };
                        setCardContent(updatedBoardVO);
                    }
                })()
            }
            // 사내고수 도움받은 후기
            if (boardVO.cateCode == "040502") {
                (async () => {
                    const result = await axiosCaller.get(APIs.PRO_INFO + boardVO.opt5)
                    if (result.status === 'SUCCESS') {
                        let newProReq = result.data.pro;
                        newProReq.jobCodeName = await ElandUtils.convertJobSkillCodesToNames(newProReq.jobCode)
                        setCardContent(newProReq);
                    }
                })()
            }

        }

    }, [boardVO]);

    return (
        <>{
            boardVO &&
            <main id="use-story-detail" className="content detail">
                <section className="detail-section">
                    <div className="inner">

                        <div className="detail-title">
                            <div className="cate">
                                <a href="#">{boardVO.cateVO?.codeName}</a>
                            </div>
                            <h2 className="tit">{boardVO.title}</h2>
                            <div className="info">
                                <span className="date">{DateUtils.formatDate(boardVO.createDate)}</span>

                            </div>
                            <div className="writer">
                                <div className="image-wrap">
                                    <img src={FileUtils.getFileUrl(writerInfo?.profileSrc ?? "")} alt="프로필 이미지"
                                         onError={FileUtils.onErrorImg}
                                         className="img-profile"/>
                                </div>
                                <div className="text-wrap">
                                    <div className="name">
                                        <b>{boardVO.writer}</b>
                                    </div>
                                    <div className="job">{writerInfo?.coInfoVO?.coName}</div>
                                </div>
                            </div>
                            {
                                sessionVO && sessionVO.coEmailId == boardVO.writerKey &&
                                <div className="util">
                                    <Link to={`/board/story/use_story/${bidx}/${StringUtils.titleUrlFilter(boardTitle ?? "")}/form`} className="btn-modify">
                                        <span className="blind">수정하기</span>
                                    </Link>
                                    <a href="javascript:;" onClick={() => deleteOne(boardVO?.idx)} className="btn-delete">
                                        <span className="blind">삭제하기</span>
                                    </a>
                                </div>
                            }
                        </div>

                        <div className="detail-content">

                            {   // 활동 후기가 아니라면 표출
                                boardVO.cateCode != "040503" && cardContent &&
                                <M_UseStoryCard cardContent={cardContent} />
                            }

                            {/* 본문 영역 */}
                            <div className="text-area" key={renderKey}>
                                <M_SummernoteIframe mode={"view"} htmlCode={boardVO.contentHtml}/>
                            </div>

                        </div>
                    </div>
                </section>
            </main>
        }</>
    );
}

export default m_UseStoryDetail
import React, {useEffect, useRef, useState} from "react";
import StringUtils from "../../../common/utils/string.utils";
import CodeUtils, {CoCode} from "../../../common/utils/code.utils";
import {CodeCountDto} from "../../../core/board/board.vo";
import {CodeVO} from "../../../core/common/code.vo";
import ElandUtils from "../../../common/utils/eland.utils";

interface M_SearchInputAndFilterProps {
    updateSearchText: (searchText: string) => void
    updateCode: (coCode: string, jobCode: string, skillCode:string) => void
    placeholderText: string,
    initText: string,
    jobCode: string,
    skillCode: string,
    coCode: string
    codeCountMap? :{ coCount:CodeCountDto[], jobCount:CodeCountDto[], skillCount:CodeCountDto[] }
    isCancelButton?: boolean
}

const M_SearchInputAndFilter = (props: M_SearchInputAndFilterProps) => {
    const {updateSearchText, placeholderText, isCancelButton = false} = props;

    const [isShow, setIsShow] = useState<boolean>(false)
    const [isFocusInput, setIsFocusInput] = useState<boolean>(false)
    const [searchText, setSearchText] = useState<string>("")

    const [codeCountMap, setCodeCountMap] = useState<{
        coCount:CodeCountDto[],
        jobCount:CodeCountDto[],
        skillCount:CodeCountDto[]
    }>({coCount: [], jobCount: [], skillCount:[]})


    const [coCodeList, setCoCodeList] = useState<CoCode[]>([])
    const [jobCodeList, setJobCodeList] = useState<CodeVO[]>([]);
    const [skillCodeList, setSkillCodeList] = useState<CodeVO[]>([]);

    const [selectCoCode, setSelectCoCode] = useState<string>("")
    const [selectJobCode, setSelectJobCode] = useState<string>("")
    const [selectSkillCode, setSelectSkillCode] = useState<string>("")

    useEffect(() => {
        initCode(false)

    }, [props.jobCode, props.coCode, props.skillCode]);

    useEffect(() => {
        props.codeCountMap && setCodeCountMap(props.codeCountMap)

    }, [props.codeCountMap]);

    useEffect(() => {
        setSearchText(props.initText)

    }, [props.initText]);

    useEffect(() => {
        getCodeList()
        getJobCodeList()
        getSkillCodeList()

    }, []);

    useEffect(() => {
        toggleLayer(isShow)

    }, [isShow]);

    const initCode = (isClear:boolean) => {
        setSelectCoCode(!isClear ? props.coCode : "")
        setSelectJobCode(!isClear ? props.jobCode : "")
        setSelectSkillCode(!isClear ? props.skillCode : "")
    }


    const getCodeList = async () => {
        let coCodeList = await CodeUtils.getCoCodeList()
        setCoCodeList(coCodeList)
    }

    const getJobCodeList = async () => {
        let jobSkillCodeList = await ElandUtils.getJobSkillCodeList(false)
        setJobCodeList(jobSkillCodeList)
    }

    const getSkillCodeList = async () => {
        let jobSkillCodeList = await ElandUtils.getJobSkillCodeList(true)
        setSkillCodeList(jobSkillCodeList)
    }


    const isActive = () => {
        let job = !StringUtils.isEmpty(selectJobCode);
        let skill = !StringUtils.isEmpty(selectSkillCode);
        let co = !StringUtils.isEmpty(selectCoCode);

        return job || skill || co
    }


    const showAndClose = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setIsShow(prev => !prev)
        initCode(false)
    }

    const updateCode = (e: React.MouseEvent<HTMLButtonElement>) =>{
        e.preventDefault()
        props.updateCode(selectCoCode, selectJobCode, selectSkillCode)
        setIsShow(prev => !prev)
    }

    const isCodeDisable = (code: string, codeType:string) => {
        switch (codeType){
            case "co":
                return !getCodeExist(code, codeCountMap.coCount)
            case "job":
                return !getCodeExist(code, codeCountMap.jobCount)
            case "skill":
                return !getCodeExist(code, codeCountMap.jobCount)
        }

        return false

    }

    const getCodeExist = (code:string, codeList:CodeCountDto[]) => {
        if (codeList && codeList.length <= 0){
            return true
        }

        let codeVO = codeList.find((vo)=>vo.code === code)
        return codeVO && codeVO.count !== 0
    }

    const toggleLayer = (isVisible:boolean) => {
        const body = document.body;
        if (isVisible) {
            body.style.overflow = 'hidden'
            body.style.position = 'fixed'
            body.style.width = '100%'
        } else {
            body.style.overflow = ''
            body.style.position = ''
            body.style.width = ''
        }
    }

    const handleCancelClick = (e:any) => {
        e.preventDefault()
        setSearchText("")
        updateSearchText("")
    }

    const handleDeleteClick = (e:any) => {
        e.preventDefault()
        setSearchText("")
    }


    return (
        <>
            <div className="search-tab">
                <div className="search-form">
                    <input type="text" className="inp-search"
                           placeholder={placeholderText}
                           value={searchText}
                           onChange={val => setSearchText(val.target.value)}
                           onKeyDown={(e) => {
                               if (e.key === "Enter" && !e.nativeEvent.isComposing) {
                                   updateSearchText(searchText)
                               }
                           }}
                           onFocus={e => {
                               setIsFocusInput(true)
                           }}
                           onBlur={e => {
                               if (e.relatedTarget && e.relatedTarget.className === "btn-delete") {
                                   e.preventDefault()
                                   e.target.focus()
                                   return;
                               }
                               setIsFocusInput(false)
                           }}
                    />
                    <button type="button" className="btn-search"
                            onClick={(e) => {
                                e.preventDefault()
                                updateSearchText(searchText)
                            }}
                            onTouchEnd={e => {
                                e.preventDefault()
                                updateSearchText(searchText)
                            }}
                    >
                        <span className="blind">검색</span>
                    </button>
                    <button type="button" className="btn-delete"
                            onClick={handleDeleteClick}
                            onTouchEnd={handleDeleteClick}
                            style={{display: isFocusInput ? "inline-block" : "none"}}
                    >
                        <span className="blind">지우기</span>
                    </button>
                </div>

                {
                    isCancelButton ? (
                        <button
                            type="button"
                            className="btn-cancel"
                            onClick={handleCancelClick}
                            onTouchEnd={handleCancelClick}
                            onBlur={() => setIsFocusInput(false)}
                        >
                            취소
                        </button>
                    ) : (
                        <button type="button"
                                className={isActive() ? "btn-filter active" : "btn-filter"}
                                onClick={showAndClose}>
                            <span className="blind">검색 필터</span>
                        </button>
                    )
                }
            </div>
            <div className="layer active type-full search-filter"
                 style={{display: isShow ? "block" : "none"}}>
                <div className="layer-header">
                    <div className="inner">
                    <h3 className="tit">필터</h3>
                        <button type="button"
                                className="btn-back"
                                onClick={showAndClose}>
                            <span className="blind">이전 화면으로</span>
                        </button>
                    </div>
                </div>
                <div className="layer-content">
                    <div className="inner">
                        <div className="scroll-content">
                            <ul className="filter-list">
                                <li>
                                    <strong>법인 구분</strong>
                                    <ul className="depth2-list">
                                        {
                                            coCodeList.map(value => {
                                                return (
                                                    <li key={value.coCode}>
                                                        <button type="button"
                                                                className={value.coCode === selectCoCode ? "selected" : ""}
                                                                onClick={e => {
                                                                    e.preventDefault()
                                                                    let code = selectCoCode !== value.coCode ? value.coCode : ""
                                                                    setSelectCoCode(code)
                                                                }}
                                                                disabled={isCodeDisable(value.coCode, 'co')}
                                                        >
                                                            {value.coName}
                                                        </button>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </li>
                                <li>
                                    <strong>직무 구분</strong>
                                    <ul className="depth2-list">
                                        {
                                            jobCodeList.map(value => {
                                                return (
                                                    <li key={value.code}>
                                                        <button type="button"
                                                                className={value.code === selectJobCode ? "selected" : ""}
                                                                onClick={e => {
                                                                    e.preventDefault()
                                                                    let code = selectJobCode !== value.code ? value.code : ""
                                                                    setSelectJobCode(code)
                                                                }}
                                                                disabled={isCodeDisable(value.code, 'job')}
                                                        >
                                                            {value.codeName}
                                                        </button>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </li>
                                <li>
                                    <strong>직무 역량</strong>
                                    <ul className="depth2-list">
                                        {
                                            skillCodeList.map(value => {
                                                return (
                                                    <li key={value.code}>
                                                        <button type="button"
                                                                className={value.code === selectSkillCode ? "selected" : ""}
                                                                onClick={e => {
                                                                    e.preventDefault()
                                                                    let code = selectSkillCode !== value.code ? value.code : ""
                                                                    setSelectSkillCode(code)

                                                                }}
                                                                disabled={isCodeDisable(value.code, 'skill')}
                                                        >
                                                            {value.codeName}
                                                        </button>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="layer-button">
                    <div className="inner">
                        <div className="button-area">
                            <button className="btn lg gray"
                                    style={{position:"static"}}
                                    onClick={e => {
                                e.preventDefault()
                                initCode(true)
                            }}>초기화
                            </button>
                            <button className="btn lg full"
                                    style={{position:"static"}}
                                    onClick={updateCode}>검색</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default M_SearchInputAndFilter
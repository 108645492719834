import React, {useEffect, useRef, useState} from "react";
import {BoardVO} from "../../../../core/board/board.vo";
import M_CardItem from "../../board/item/M_CardItem";
import ElandUtils from "../../../../common/utils/eland.utils";
import type { Swiper as SwiperType } from 'swiper';
import SwiperInit from 'swiper';

interface ProContentSlideProps {
    isContent: boolean;
    count: number;
    contentList: BoardVO[]
}

const M_ProContentSlide = (props: ProContentSlideProps) => {
    const {isContent, count, contentList} = props

    const [proContentList, setProContentList] = useState(contentList)
    const swiperRef = useRef<SwiperType | null>(null);

    useEffect(() => {
        const renderList = async () => {
            let updatedBoardList = await ElandUtils.updateBoardListWithSkillNames(props.contentList);
            updatedBoardList = await ElandUtils.updateBoardListWithJobNames(updatedBoardList);

            setProContentList(updatedBoardList)
        }

        renderList()
    }, [props]);

    const installSwiper = () => {
        swiperRef.current?.destroy(true,true)

        let container = isContent ?
            ".list-section .board-slide.master-contents" :
            ".list-section .board-slide.master-resources"

        if (proContentList.length >0){
            swiperRef.current = new SwiperInit(container, {
                slidesPerView: "auto",
                spaceBetween: 24,
            });
        }
    }


    useEffect(() => {
        installSwiper()

    }, [proContentList]);

    const getContentCardList = () => {
        let isExist = proContentList.length > 0;
        return isExist ?
            (
                <ul className="card-list type1 swiper-wrapper">
                    {
                        proContentList.map((item) => (
                            <M_CardItem key={item.idx} board={item} isRateShow={true}/>
                        ))
                    }
                </ul>
            )
            :
            (
                <div className="content-empty">
                    아직 업로드된 콘텐츠가 없습니다.
                </div>
            )
    }

    const getProDataCardList = () => {
        let isExist = proContentList.length > 0;
        return isExist ?
            (
                <ul className="card-list type1 swiper-wrapper">
                    {
                        proContentList.map((item) => (
                            <M_CardItem key={item.idx} board={item} isRateShow={true}/>
                        ))
                    }
                </ul>
            )
            :
            (
                <div className="content-empty">
                    아직 업로드된 콘텐츠가 없습니다.
                </div>
            )
    }


    return (
        <section className="list-section border-10">
            <div className="inner">
                <div className="section-title">
                    <h3 className="tit">{isContent ? '고수콘텐츠' : '고수자료실'}</h3>
                    <span className="num"><b>{count}</b>건</span>
                </div>

                <div className={isContent ? "board-list board-slide master-contents" : "board-list board-slide master-resources"}>
                    {
                        isContent ? getContentCardList() : getProDataCardList()
                    }
                </div>
            </div>
        </section>

)


}

export default M_ProContentSlide
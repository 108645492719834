import React, {useEffect, useRef, useState} from "react";
import SummernoteIframe, {SummernoteIframeHandle} from "../common/SummernoteIframe";
import {BoardFileVO, BoardVO, DefaultBoardVO} from "../../../core/board/board.vo";
import JobSkillFilterSelectButton from "../common/JobSkillFilterSelectButton";
import {SessionVO} from "../../../core/common/session.vo";
import MemberUtils from "../../../common/utils/member.utils";
import FileUploader from "../../pc/common/FileUploader";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import APIs from "../../../common/apis/APIs";
import JobSkillMultiSelect from "../../pc/common/SkillMultiSelect";
import BoardService from "../../../core/board/board.service";
import BoardCard from "./BoardCard";
import ToastUtils from "../../../common/utils/toast.utils";
import SwalUtils from "../../../common/utils/swal.utils";
import PointService from "../../../core/point/point.service";
import Snb from "./Snb";
import FileUtils from "../../../common/utils/file.utils";

const BoardForm: React.FC = () => {
    const navigate = useNavigate();
    const boardService = new BoardService();
    let {boardKey, bidx} = useParams();

    const [boardVO, setBoardVO] = React.useState<BoardVO>(DefaultBoardVO);
    const [tagList, setTagList] = React.useState<string[]>([]);
    const [tag, setTag] = React.useState<string>("");
    const [jobCode, setJobCode] = React.useState("");
    const [skillCode, setSkillCode] = React.useState("");
    const [sessionVO, setSessionVO] = useState<SessionVO | null>(MemberUtils.getSessionVO());
    const [boardFileList, setBoardFileList] = React.useState<BoardFileVO[]>([]);
    const [contentsIdxs, setContentsIdxs] = React.useState("");
    const [attachIdxs, setAttachIdxs] = React.useState("");

    const [updateKey, setUpdateKey] = useState(0);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const iframeRef = useRef<SummernoteIframeHandle>(null);
    const $uploadRef = useRef(null)
    const $thumbRef = useRef(null)
    const $attachRef = useRef(null)

    // 파일 업로드 검사를 위함
    const [isUploading, setIsUploading] = useState(false);
    const [retryCounter, setRetryCounter] = useState(0);
    const retryTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);


    const handleUploadFile = (idx: string) => {
        setBoardVO({...boardVO, thumbIdx: parseInt(idx, 10)});
    }

    const handleUploadAttachFile = (idxs: string) => {
        setAttachIdxs(idxs)
    }

    const handleUploadContents = (idxs: string) => {
        setBoardFileList([])
        setContentsIdxs(idxs)
    }


    const checkWriter = (boardVO: BoardVO) => {
        if(boardVO.writerKey != MemberUtils.getLoginId()) {
            SwalUtils.text({
                title : `안내`,
                message : `권한이 없습니다.`,
                isCancel : false,
                confirmOptions : {
                    text : "확인",
                    eventHandler : async() => {
                        navigate(-1)

                    }
                }
            })
        } else {
            return true;
        }
    }


    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        if(bidx != "0") {
            SwalUtils.text({
                title : `안내`,
                message : `콘텐츠 유형은 수정이 불가능합니다.`,
                isCancel : false,
                confirmOptions : {
                    text : "확인",
                }
            })
        } else {
            SwalUtils.text({
                title : `안내`,
                message : `콘텐츠 유형을 변경할 경우 작성한 내용이 모두 사라집니다.<br/> 정말 변경하시겠습니까?`,
                isCancel : false,
                confirmOptions : {
                    text : "확인",
                    eventHandler : async() => {
                        setContentsIdxs("")
                        setBoardFileList([])
                        setAttachIdxs("")
                        setTag("")
                        setTagList([])
                        setUpdateKey(prevKey => prevKey + 1);
                        setBoardVO({...DefaultBoardVO(), boardType: event.target.value});
                    }
                },
                cancelOptions: {
                    text: "취소", eventHandler: () => {
                        console.log("취소 버튼")
                    }
                }
            })
        }

    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (name === "title") {
            // https://github.com/innople-ebot/elandedu/issues/513
            if (value.length <= 50) {  // 제목 50글자 제한
                setBoardVO(prevState => ({
                    ...prevState,
                    title: value
                }));
            }
        } else if (name === "tag") {
            setTag(value.replace(/[#\s]/g, ''));  // 태그는 별도의 길이 제한 없이 state 업데이트
        }
    };

    const activeEnter = (e: any) => {
        if (e.keyCode === 13) {
            pushTag()
        }
    }

    const pushTag = () => {
        if(tag == "" || tagList.length >= 10) return;

        const copyTagList = [...tagList, tag];
        setTagList(copyTagList)
        setTag("")
    }

    const deleteTagItem = (_tag : string) => {
        const updatedTags = tagList.filter(tag => tag !== _tag);
        setTagList(updatedTags); // 상태 업데이트
    }

    const validateForm = (isTemp: boolean): boolean => {

        if (boardVO.boardType == "mov" || boardVO.boardType == "card") {
            if(contentsIdxs == "") {
                ToastUtils.show("지식콘텐츠를 등록해주세요.", 2 )
                return false;
            }

            if(!boardVO || !boardVO.thumbIdx || boardVO.thumbIdx <= 0) {
                ToastUtils.show("썸네일을 선택해주세요.", 2 )
                return false;
            }
        }

        if(skillCode == "" || jobCode == "") {
            ToastUtils.show("직무와 역량을 선택해주세요.", 2 )
            return false;
        }

        if (boardVO.title.trim() === "") {
            ToastUtils.show("제목을 작성해주세요.", 2 )
            return false;
        }

        const htmlContent = iframeRef.current?.getHtmlContent();
        if (!htmlContent || htmlContent.trim() === "" || htmlContent.trim() === "<br>") {
            ToastUtils.show("내용을 작성해주세요.", 2 )
            return false;
        }

        if (tagList.length <= 0) {
            ToastUtils.show("검색태그를 등록해주세요.", 2 )
            return false;
        }


        return true;
    };


    // 저장
    const saveBoard = async (isTemp: boolean) => {
        if(!sessionVO){
            ToastUtils.show("로그인이 필요합니다!", 2)
            return
        }


        if(!(MemberUtils.isPro() || MemberUtils.isSemiPro())) {
            SwalUtils.text({
                title : `지식등록 불가`,
                message : `사내고수가 아닙니다!`,
                isCancel : false,
                confirmOptions : {
                    text : "확인",
                    // eventHandler : () => navigate(-1)
                }
            })

            return
        }


        if (isUploading || ($uploadRef.current && ($uploadRef.current as any).getUploadingStatus())){
            SwalUtils.text({
                title : `안내`,
                message : `영상 콘텐츠가 업로드 되고 있어요!<br/>영상이 업로드된 이후에 등록하기 버튼을 눌러주세요`,
                isCancel : false,
                confirmOptions : {
                    text : "확인",
                }
            })

            return
        }

        // 검증 및 저장
        if (validateForm(isTemp)) {
            const tags = tagList.join(",");
            const htmlContent = iframeRef.current?.getHtmlContent();

            const sendData = {
                ...boardVO,
                idx: boardVO.isTemp && !isTemp ? 0 : boardVO.idx,
                boardKey: boardVO.boardType == "mov" || boardVO.boardType == "card" || boardVO.boardType == "pdf" ? "pro_knowledge" : "job_data",
                tags: tags,
                jobCode: jobCode,
                skillCode: skillCode,
                contentHtml: htmlContent || null,
                writer: sessionVO.userName,
                writerKey: sessionVO.coEmailId,
                contentsIdxs: contentsIdxs,
                isTemp: isTemp,
                attachIdx: attachIdxs,
                coCode: MemberUtils.getSessionVO()?.coCode // 작성자 법인 
            }


            // saveBoardForm
            const result = await boardService.saveBoardForm4Knowledge(sendData);
            if(result.status == "SUCCESS" && result.data) {
                //alert("저장되었습니다.")
                if(isTemp) {
                    ToastUtils.show("임시저장이 완료되었습니다.", 2 )
                    setBoardVO(result.data)
                } else {

                    // 포인트 적립 (지식 / 자료실 구분)
                    if(boardVO.boardType == "html") {
                        await PointService.savePoint("PRO_REG_REPOSITORY", result.data.idx);
                    } else {
                        await PointService.savePoint("PRO_REG_CONTENT", result.data.idx);
                    }

                    ToastUtils.show("저장이 완료되었습니다.", 2 )
                    navigate(`/board/list/${boardVO.boardType == "html" ? "job_data" : "pro_knowledge"}`)
                }

            } else {
                ToastUtils.show("저장에 실패했습니다. 다시 시도해주세요.", 2 )
                console.log(":::" + result.status, result.msg);
            }
        }


    }

    // 파일 업로드 상태에 따라 재시도
    const checkFileStatus = async () => {
        try {
            const result = await boardService.getBoardFileList({ fidxs: contentsIdxs });

            if (result.status === "SUCCESS" && result.data) {
                const uploadingFileExists = result.data.some(
                    (item: BoardFileVO) =>
                        item.fileStatus === "S3_UPLOADING" ||
                        item.fileStatus === "RES_UPLOADING" ||
                        item.fileStatus === "BAK_UPLOADING" ||
                        item.fileStatus === "RES_MERGING"
                );

                if (uploadingFileExists) {
                    setIsUploading(true);

                    // 일정 시간 후 재시도
                    retryTimeoutRef.current = setTimeout(() => {
                        setRetryCounter((prev) => prev + 1);
                    }, 3000); // 3초 후 재조회

                } else {
                    setIsUploading(false);
                    if (retryTimeoutRef.current) {
                        clearTimeout(retryTimeoutRef.current);
                    }
                }

                // board file setting
                if(boardVO.boardType == "card") {
                    if(result.data[0]?.fileext == "pdf") {
                        setBoardVO({...boardVO, boardType: "pdf"})
                    }
                }

                result.data?.map((item: BoardFileVO) => {item.fileMode = "contents";})
                setBoardFileList(result.data);

            }
        } catch (error) {
            console.error("Failed to fetch file status", error);
            setIsUploading(false)
        }
    };

    const filterFileIdxs = (fileList: BoardFileVO[]) => {

        let attachIdxArr: number[] = [];
        let contentsArr: number[] = [];

        fileList?.map((item: BoardFileVO) => {
            if(!item.fileMode || item.fileMode === "attach") {
                attachIdxArr.push(item.fidx)
            }

            if(item.fileMode === "contents" || item.fileMode === 'mov') {
                contentsArr.push(item.fidx);
            }
        })

        if(contentsArr.join(",") != "") {
            setContentsIdxs(contentsArr.join(","))
            $uploadRef.current && ($uploadRef.current as any).setFidxs(contentsArr.join(","))
        }
        if(attachIdxArr.join(",") != "") {
            setAttachIdxs(attachIdxArr.join(","))
            $attachRef.current && ($attachRef.current as any).setFidxs(attachIdxArr.join(","))
        }
    }

    useEffect(() => {
        if (contentsIdxs !== "" && contentsIdxs !== "0") {
            checkFileStatus();
        }

        return () => {
            if (retryTimeoutRef.current) {
                clearTimeout(retryTimeoutRef.current); // 컴포넌트 언마운트 시 타이머 정리
            }
        };
    }, [contentsIdxs, retryCounter]);

    useEffect(() => {

        (async () => {
            if(bidx && bidx != "0") {
                const result = await boardService.getBoardOne({bidx: bidx, isSaveHis: false});
                if (result.status == "SUCCESS" && result.data) {
                    // 작성자 체크
                    if(checkWriter(result.data.boardVO)) {
                        setBoardVO(result.data.boardVO);
                        setTagList(result.data.boardVO.tags?.split(","))
                        setJobCode(result.data.boardVO.jobCode);
                        setSkillCode(result.data.boardVO.skillCode);
                        filterFileIdxs(result.data.boardVO.attachList)
                        $thumbRef.current && ($thumbRef.current as any).setFidxs(`${result.data.boardVO.thumbIdx}`)
                    }
                }
            } else {
                const result = await boardService.getRecentTempBoardOne({boardKey: "pro_knowledge,job_data"});
                if(result.status == "SUCCESS" && result.data != null) {
                    const board = result.data.boardVO;
                    const fileList = result.data.fileList;
                    if(board) {
                        setBoardVO(board);
                        $thumbRef.current && ($thumbRef.current as any).setFidxs(`${board.thumbIdx}`)
                        filterFileIdxs(fileList)
                        setTagList(board.tags?.split(","))
                        setJobCode(board.jobCode);
                        setSkillCode(board.skillCode);
                    } else {
                        const boardVO = DefaultBoardVO();
                        setContentsIdxs("")
                        setBoardFileList([])
                        setAttachIdxs("")
                        setTag("")
                        setTagList([])
                        setUpdateKey(prevKey => prevKey + 1);
                        const type = searchParams.get('type')
                        setBoardVO({...boardVO, boardType: type || "mov"})
                    }
                }
            }
        })()

        // CommonJS.select();
    }, [bidx]);


    return (
        <main className="content register nav-layout" key={updateKey}>
            <div className="common-title">
                <div className="inner">
                    <h2 className="tit">지식등록</h2>
                    <p className="desc">사내고수에게만 노출되는 지식등록 게시판입니다<br/>지식등록메뉴는 PC에서만 지원합니다.</p>
                </div>
            </div>
            <section className="form-section">
                <div className="inner">
                    <div className="form-list">
                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">콘텐츠 유형</strong>
                            </div>
                            <div className="rdo-bind">
                                <div className="radio">
                                    <input type="radio" name="radios" id="radioId" className="inp-radio"
                                           value="mov" checked={boardVO.boardType === 'mov'}
                                           onChange={handleRadioChange}/>
                                    <label htmlFor="radioId" className="lab-radio">영상</label>
                                </div>
                                <div className="radio">
                                    <input type="radio" name="radios" id="radioId2" className="inp-radio"
                                           value="card" checked={boardVO.boardType === 'card' || boardVO.boardType == "pdf"}
                                           onChange={handleRadioChange}/>
                                    <label htmlFor="radioId2" className="lab-radio">카드뉴스</label>
                                </div>
                                <div className="radio">
                                    <input type="radio" name="radios" id="radioId3" className="inp-radio"
                                           value="html" checked={boardVO.boardType === 'html'}
                                           onChange={handleRadioChange}/>
                                    <label htmlFor="radioId3" className="lab-radio">파일</label>
                                </div>
                            </div>
                            <div className="guide-text">
                                영상 및 카드뉴스는 [사내고수의 지식], 파일은 [사내고수의 자료실] 에 업로드 됩니다.
                            </div>
                        </div>

                        {/* 카드뉴스, 영상만 등록 */}
                        {
                            boardVO.boardType != 'html' &&
                            <div className="form-item">
                                <div className="form-title">
                                    <strong className="tit">지식콘텐츠*</strong>
                                </div>


                                {/* 카드뉴스에서도 IMG / PDF로 나눔 */}
                                {
                                    (boardVO.boardType == "card" || boardVO.boardType == "pdf") &&
                                    <div className="rdo-bind" style={{marginBottom: '10px'}}>
                                        <div className="radio">
                                            <input type="radio" name="radioId-card" id="radioId-card" className="inp-radio"
                                                   value="card" checked={boardVO.boardType === 'card'}
                                                   onChange={handleRadioChange}/>
                                            <label htmlFor="radioId-card" className="lab-radio">이미지</label>
                                        </div>
                                        <div className="radio">
                                            <input type="radio" name="radioId-pdf" id="radioId-pdf" className="inp-radio"
                                                   value="pdf"
                                                   checked={boardVO.boardType === 'pdf'}
                                                   onChange={handleRadioChange}/>
                                            <label htmlFor="radioId-pdf" className="lab-radio">PDF</label>
                                        </div>
                                    </div>
                                }



                                {/* 파일 업로드 체크!! 업로드 중일 때 분기 화면 */}
                                {
                                    (contentsIdxs != "") && (
                                        isUploading ? (
                                            <div className="mt-2 mb-2">파일 업로드 중입니다...</div>
                                        ) : (
                                            <div className="media-preview mb-2">
                                                {
                                                    (boardVO.boardType === "mov") && (boardFileList.length > 0) && (
                                                        <video src={FileUtils.getImageSrc(boardFileList[0])} controls/>
                                                    )
                                                }

                                                {
                                                    (boardVO.boardType !== "mov") && (
                                                        <div className="detail-content">
                                                            <BoardCard fileList={boardFileList} boardVO={boardVO}/>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    )
                                }


                                {
                                    boardVO.boardType &&
                                    <FileUploader ref={$uploadRef} params={{
                                        domId: `boardVO-knowledge`,
                                        target: `board`,
                                        fidxs: contentsIdxs,
                                        maxFileSize: boardVO.boardType == 'mov' ? (2560) : 100,
                                        allowList:(() => {
                                            switch (boardVO.boardType) {
                                                case 'mov':
                                                    return 'mp4,wmv,avi';
                                                case 'pdf':
                                                    return 'pdf';
                                                case 'card':
                                                    return 'jpg,jpeg,png';
                                                default:
                                                    return '';
                                            }
                                        })(),
                                        maxFileCount:(() => {
                                            switch (boardVO.boardType) {
                                                case 'mov':
                                                    return 1;
                                                case 'pdf':
                                                    return 1;
                                                case 'card':
                                                    return 0;
                                                default:
                                                    return 0;
                                            }
                                        })(),
                                        updateHandler: (_target: string, _files: string) => {
                                            handleUploadContents(_files);
                                        }
                                    }}/>
                                }
                                <div className="guide-text">
                                    {boardVO.boardType == "mov" && "파일 포맷 : MP4, WMV, AVI / 파일크기 : 2GB이하 / 권장해상도 : 1280*720"}
                                    {boardVO.boardType == "card" && "파일 포맷 : JPG, JPEG, PNG / 파일크기 : 100MB이하 / 권장비율 : 와이드(16:9)"}
                                    {boardVO.boardType == "pdf" && "파일 포맷 : PDF / 파일크기 : 100MB이하 / 권장비율 : 와이드(16:9)"}
                                </div>
                            </div>
                        }

                        {/* 썸네일은 카드뉴스, 영상만 등록 */}
                        {
                            boardVO.boardType != "html" &&
                            <div className="form-item">
                                <div className="form-title">
                                    <strong className="tit">콘텐츠썸네일*</strong>
                                </div>
                                <div className="media-preview">
                                    <img src={APIs.BOARD_FILES_SHOW + boardVO.thumbIdx} alt="" className="img-thumb"/>
                                </div>
                                <FileUploader ref={$thumbRef} params={{
                                    domId: `boardVO-thumbnail`,
                                    domText: `권장해상도를 확인하시고 썸네일을 등록해주세요.`,
                                    target: `board`,
                                    fidxs: boardVO.thumbIdx,
                                    maxFileSize: 10,
                                    maxFileCount: 1,
                                    allowList: 'jpg,jpeg,png,gif',
                                    updateHandler: (_target: string, _files: string) => {
                                        console.log(_target + " ::::: " + _files)
                                        handleUploadFile(_files);
                                    }
                                }}
                                />
                                <div className="guide-text">
                                    파일 포맷 : JPG, JPEG, GIF, PNG / 파일크기 : 10MB이하 / 권장해상도 800*600 (4:3 비율)
                                </div>
                            </div>
                        }

                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">첨부파일</strong>
                            </div>
                            <FileUploader ref={$attachRef} params={{
                                domId: `boardVO-attach`,
                                domText: `첨부파일은 1개만 업로드 가능합니다. 파일이 여러개일 경우 zip파일로 압축하여 등록해주세요.`,
                                target: `board`,
                                fidxs: attachIdxs,
                                maxFileSize: 100,
                                maxFileCount: 1,
                                updateHandler: (_target: string, _files: string) => {
                                    console.log(_target + " ::::: " + _files)
                                    handleUploadAttachFile(_files);
                                }
                            }}/>
                            <div className="guide-text">
                                파일크기 : 100MB이하, 1개의 파일 / 확장자는 제약이 없습니다.
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">직무*</strong>
                            </div>
                            <div className="sel-bind">
                                <JobSkillFilterSelectButton isSkill={false}
                                                            updateJobCode={setJobCode}
                                                            updateSkillCode={() => {}}
                                                            initCode={boardVO.jobCode}
                                                            isFilter={false}/>
                                <JobSkillMultiSelect initCode={boardVO.skillCode}
                                                     updateSkillCode={setSkillCode}/>
                            </div>
                        </div>

                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">제목*</strong>
                            </div>
                            <div className="inp-bind fill">
                                <input type="text" name="title" id="" className="inp-text"
                                       value={boardVO.title}
                                       onChange={handleInputChange}
                                       placeholder="콘텐츠 제목을 50글자 이내로 입력해주세요"/>
                            </div>
                            <div className="guide-text">
                                콘텐츠 제목은 직원들이 관심을 가질 수 있도록 적어주세요
                            </div>
                        </div>

                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">강의 설명*</strong>
                            </div>
                            <div className="inp-bind">
                                <SummernoteIframe mode={"edit"} ref={iframeRef} htmlCode={boardVO.contentHtml || boardVO.contentText}/>
                            </div>
                        </div>


                        {/* 스크립트는 영상만 작성 */}
                        {
                            boardVO.boardType == "mov" &&
                            <div className="form-item">
                                <div className="form-title">
                                    <strong className="tit">스크립트</strong>
                                </div>
                                <div className="inp-bind">
                                <textarea name="" id="" cols={30} rows={10} className="inp-text"
                                          value={boardVO.scriptHtml}
                                          onChange={e => {
                                              setBoardVO(prev=>{
                                                  return {...prev, scriptHtml : e.target.value}
                                              })
                                          }}
                                          placeholder="스크립트를 입력해주세요"></textarea>
                                </div>
                            </div>
                        }

                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">검색태그*</strong>
                            </div>
                            <div className="inp-btn-bind">
                                <input type="text" name="tag" id="" className="inp-text" value={tag}
                                       onChange={handleInputChange} placeholder="검색 태그를 입력해주세요"
                                       onKeyDown={activeEnter}/>
                                <button type="button" onClick={pushTag} className="btn blue">등록</button>
                            </div>
                            <div className="guide-text">
                                지식콘텐츠를 잘 표현하는 태그를 입력해주세요. 최대 10개까지 등록이 가능합니다. 검색에 반영되며 키워드알림을 설정한 사용자에게 주 1회 알림이 발송돼요.
                            </div>


                            {
                                tagList.length > 0 &&
                                <div className="tag-list">
                                    {
                                        tagList.map((item, index) => (
                                            <span key={index} className="tag-item">
                                                {item}
                                                <button type="button" className="btn-remove" onClick={() => {
                                                    deleteTagItem(item)
                                                }}></button>
                                            </span>
                                        ))
                                    }
                                </div>
                            }


                            {
                                tagList.length >= 10 && tag != "" &&
                                <div className="error-text">
                                    기존에 등록한 키워드를 삭제 후 등록해주세요
                                </div>
                            }

                        </div>

                    </div>
                    <div className="button-area">
                        <button type="button"
                                className="btn gray lg"
                                disabled={ bidx != "0" && true}
                                onClick={()=>saveBoard(true)}>
                            임시저장
                        </button>
                        <button type="button"
                                className="btn red lg"
                                onClick={()=>saveBoard(false)}>
                            등록하기
                        </button>
                    </div>
                </div>
            </section>


            {/* 사이드 메뉴 바 */}
            <Snb/>
        </main>
    )
}


export default BoardForm;

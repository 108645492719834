import axiosCaller from "../../common/utils/axios.caller";
import APIs from "../../common/apis/APIs";
import {HWMap} from "../common/common.vo";

class BoardService {

    /**
     * boardList 조회
     * @param _params
     */
    getBoardList = (_params : HWMap) => {
        return axiosCaller.get(APIs.BOARDS + "/getBoardList.v2", _params);
    }

    /**
     * 적용스토리 조회
     * @param _params
     */
    getUseStoryBoardList = (_params : HWMap) => {
        return axiosCaller.get(APIs.BOARDS + "/getUseStoryBoardList", _params);
    }

    getBoardOneOrigin = async (_params : HWMap) => {
        return axiosCaller.get(APIs.BOARDS + "/getBoardOne/" + _params.bidx, _params);
    }

    /**
     * board 상세 조회
     * @param _params
     */
    getBoardOne = async (_params : HWMap) => {
        return axiosCaller.get(APIs.BOARDS + "/getBoardOneV2/" + _params.bidx, _params);
    }

    isWishBoard = async (_params : HWMap) => {
        return axiosCaller.get(APIs.BOARD_WISH + "/isWishBoard/" + _params.bidx, _params);
    }

    /**
     * 책갈피 삭제
     * @param _params
     */
    deleteWishBoard = async (_params : HWMap) => {
        return axiosCaller.delete(APIs.BOARD_WISH + "/isWishBoard/" + _params.bidx, _params);
    }

    /**
     * 책갈피 추가
     * @param _params
     */
    putWishBoard = async (_params : HWMap) => {
        return axiosCaller.put(APIs.BOARD_WISH + "/isWishBoard/" + _params.bidx, _params);
    }

    /**
     * 연관 지식 조회
     * @param _params
     */
    getRelatedContents = async (_params : HWMap) => {
        return axiosCaller.get(APIs.BOARDS + "/relatedContents", _params);
    }

    /**
     * 최근 본 지식컨텐츠 조회
     * @param _params
     */
    getRecentViewList = async (_params : HWMap) => {
        return axiosCaller.get(APIs.BOARDS + "/getRecentViewList", _params);
    }

    // 일반적인 게시물 저장중 (적용스토리_)
    saveBoardOne = async (_params : HWMap) => {
        return axiosCaller.post(APIs.BOARDS + "/setBoardOneV2", _params);
    }

    /**
     * 임시 게시물 최근 한개 조회
     * @param _params
     */
    getRecentTempBoardOne = async (_params : HWMap) => {
        return axiosCaller.get(APIs.BOARDS + "/getRecentTempBoardOne", _params);
    }

    /**
     * 파일 리스트 조회
     * @param _params
     */
    getBoardFileList = async (_params : HWMap) => {
        return axiosCaller.get(APIs.BOARD_FILES + "/getBoardFileList", _params);
    }

    /**
     * board 통합 검색 및 조회 api
     * 이랜드 태그 기준 포함(추천/신규/인기)
     * @param _params
     */
    getSearchBoards = async (_params : HWMap) => {
        return axiosCaller.get(APIs.BOARDS + "/searchBoards", _params);
    }

    getBoardFilterCount = async (_params : HWMap) => {
        return axiosCaller.get(APIs.CODE_COUNT, _params);
    }


    // 지식콘텐츠 등록 폼에 적용
    saveBoardForm4Knowledge = async (_params : HWMap) => {
        return axiosCaller.post(APIs.BOARDS + "/saveBoardForm4Knowledge", _params);
    }

    /**
     * 게시물 삭제
     * @param _params
     */
    deleteBoardOne = async (_params : HWMap) => {
        return axiosCaller.delete(APIs.BOARDS + "/deleteOne/" + _params.bidx, _params);
    }

    /**
     * 게시판 정보 조회
     * @param _params
     */
    static getBoardInfoOne = async (_params : HWMap) => {
        return axiosCaller.get(APIs.BOARDS + "/getBoardInfo", _params);
    }

}


export default BoardService
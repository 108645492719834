import React, {useEffect, useRef, useState} from "react";
import M_JobSkillResultRadarChart from "../../mypage/jobskill/result/M_JobSkillResultRadarChart";
import {CodeVO, DefaultCodeVO} from "../../../../core/common/code.vo";
import {JobSkillReport} from "../../../../core/jobskill/jobskill.vo";
import JobSkillService from "../../../../core/jobskill/jobskill.service";
import MemberUtils from "../../../../common/utils/member.utils";
import ElandUtils from "../../../../common/utils/eland.utils";
import M_JobSkillResultRankItem from "../../mypage/jobskill/result/M_JobSkillResultRankItem";
import type { Swiper as SwiperType } from 'swiper';
import SwiperInit from 'swiper';

const M_DashboardJobSkillResult = () => {
    const swiperVal = useRef<SwiperType | null>(null);
    const [skillCodeList, setSkillCodeList] = useState<CodeVO[]>([])
    const [resultReport, setResultReport] = useState<JobSkillReport>({
        currentAvg: 0,
        jobCode: DefaultCodeVO(),
        parentCode: DefaultCodeVO(),
        lastAvg: 0,
        list: [],
        totalCount: 0,
    })

    useEffect(() => {
        const getSkillCodeList = async () => {
            setSkillCodeList(await ElandUtils.getJobSkillCodeList(true));
        }

        const successHandler = (result: any) => {
            setResultReport(result.data)
        }

        if (MemberUtils.isCompleteJobSkill()) {
            getSkillCodeList()
            JobSkillService.jobSkillResultGet(successHandler)
        }

    }, []);

    const initSwiper = () => {
        swiperVal.current?.destroy(true, true)

        if (resultReport.list.length >0){
            swiperVal.current = new SwiperInit(".box-content .rank-slide", {
                slidesPerView: "auto",
                spaceBetween: 16,
            })
        }
    }

    useEffect(() => {
        initSwiper()

    }, [resultReport]);

    const goJobSkill = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        window.location.href = "/mypage/jobskill"
    }



    return (
        <div className="box-item box6">
            <div className="box-title">
                <h3 className="tit">직무역량진단</h3>
                <a href="#" className="link-more" onClick={goJobSkill}>더보기</a>
            </div>
            {
                MemberUtils.isCompleteJobSkill() ? (
                        <div className="box-content">
                            <p className="job">
                                {
                                    MemberUtils.isCompleteJobSkill() && (
                                        <>
                                            나의 직무
                                            : <span>{resultReport.parentCode.codeName} - {resultReport.jobCode.codeName}</span>
                                        </>
                                    )
                                }
                            </p>
                            <div className="results-wrap">
                                <M_JobSkillResultRadarChart resultReport={resultReport.list}
                                                            skillCodeList={skillCodeList}/>
                                <div className="rank-slide">
                                    <ul className="rank-list swiper-wrapper">
                                        {
                                            resultReport.list
                                            .filter((value, index) => index < 4)
                                            .map(val => {
                                                const codeName = skillCodeList.find(code => code.code === val.skillCode)?.codeName ?? "";
                                                return <M_JobSkillResultRankItem key={val.skillCode}
                                                                                 rank={val.rank}
                                                                                 totalCount={val.totalCount}
                                                                                 skillName={codeName}
                                                                                 linkValid={true}
                                                                                 score={val.score}/>
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="content-empty">
                            <div>
                                <p>
                                    직무역량진단을 통해<br/>
                                    나에게 필요한 역량을 확인해보세요.<br/>
                                    진단 완료 시 1,000P가 지급됩니다!
                                </p>
                                <button type="button" className="btn red sm" onClick={goJobSkill}>
                                    진단하러가기
                                </button>
                            </div>
                        </div>
                )
            }
        </div>
    )
}

export default M_DashboardJobSkillResult
import React from "react";

const ProEduTalkCancel = (props:{cancelMsg:string}) => {

    return (
        <section className="reply-section cancel">
            <div className="inner">
                <div className="cancel-text">
                    고수가 취소한 문의입니다<br/>
                    취소 사유 : “{props.cancelMsg}”
                </div>
            </div>
        </section>
    )
}

export default ProEduTalkCancel
import React from "react";
import ProEduTalkCommentInput from "../common/ProEduTalkCommentInput";
import {CommentVO} from "../../../../../../core/board/board.vo";

interface ProCommentInputProps{
    comment: CommentVO | null,
    statusCode: string,
    updateEvent : () => void
}


const ProEduTalkReceiverInput = (props:ProCommentInputProps) => {
    const {comment, updateEvent} = props

    return (
        <section className="comment-section">
            <div className="inner">
                <div className="comment type2">
                    <div className="comment-title">
                        <h3 className="tit">답변</h3>
                    </div>
                    <ProEduTalkCommentInput comment={comment}
                                            updateEvent={updateEvent}
                                            enableTemp={props.statusCode === "041901"} isAddition={false}/>
                </div>
            </div>
        </section>
    )
}

export default ProEduTalkReceiverInput
import axiosCaller from "../../common/utils/axios.caller";
import APIs from "../../common/apis/APIs";
import {useNavigate} from "react-router-dom";
import SwalUtils from "../../common/utils/swal.utils";
import ToastUtils from "../../common/utils/toast.utils";

class ProService {

    static failHandler = (result:any) =>{
        if (result.status){
            if (result.status === "FAIL"){
                let text: string = result.msg
                ToastUtils.show(result.msg)

            } else if (result.status === "ERROR"){
                ToastUtils.show(result.msg)
                console.log(result.data)

            } else if (result.status === "UNAUTHORIZATION"){
                ToastUtils.show(result.msg)

                // 로그인 페이지 이동
                const navigate = useNavigate()
                navigate("/login")

            }

        } else {
            ToastUtils.show("네트워크 오류가 발생했습니다. 잠시 후 시도해주세요.")
        }

    }

    static proRecommendListGet = async (params:{size:number} ,success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.get(APIs.PRO_RECOMMEND, params);

            if (result.status === 'SUCCESS') {
                success(result);

            } else {
                ProService.failHandler(result);

            }
        } catch (error) {
            ProService.failHandler(error);
        }
    };

    static proRecommendOneGet = async (params:{weakList:string},success:(result:any)=>void, fail?:(result:any)=> void) => {
        try {
            const result = await axiosCaller.get(APIs.PRO_RECOMMEND + "/one", params);

            if (result.status === 'SUCCESS') {
                success(result);

            } else {
                if (fail){
                    fail(result)
                }else {
                    ProService.failHandler(result);
                }


            }
        } catch (error) {
            ProService.failHandler(error);
        }
    }

    static proListSearch = async (params:any, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.get(APIs.PRO_SEARCH, params)

            if (result.status === 'SUCCESS') {
                success(result)
            }else {
                ProService.failHandler(result)
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }

    static proInfoGet = async (proidx: string, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.get(APIs.PRO_INFO + proidx)
            if (result.status === 'SUCCESS') {
                success(result)

            } else {
                ProService.failHandler(result)
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }

    static proContentAndJobGet = async (proidx: string, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.get(APIs.PRO_INFO + proidx + "/content")
            if (result.status === 'SUCCESS') {
                success(result)

            } else {
                ProService.failHandler(result)
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }

    static newProContentGet = async (success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.get(APIs.PRO_INFO + "content/new")
            if (result.status === 'SUCCESS') {
                success(result)

            } else {
                ProService.failHandler(result)
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }

    static proFollowUpdate = async (params: {proidx: number, isSave: boolean}, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.put(APIs.PRO_FOLLOW, params);
            if (result.status === 'SUCCESS') {
                success(result)

            } else {
                ProService.failHandler(result)
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }

    static proRequestStatusGet = async (success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.get(APIs.PRO_REQUEST)
            if (result.status === 'SUCCESS') {
                success(result)

            } else {
                ProService.failHandler(result)
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }

    static proRequestSubmit = async (formData:FormData, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.post(APIs.PRO_REQUEST, formData, {'Content-Type': 'application/x-www-form-urlencoded'})
            if (result.status === 'SUCCESS') {
                success(result)

            } else {
                ProService.failHandler(result)
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }

    static proRequestQuestionGet = async (success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.get(APIs.PRO_QUESTION)
            if (result.status === 'SUCCESS') {
                success(result)

            } else {
                ProService.failHandler(result)
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }


    static inquiryNewGet = async (success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.get(APIs.PRO_INQUIRY + "/new");
            if (result.status === 'SUCCESS') {
                success(result)
            } else {
                // ProService.failHandler(result)
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }

    static inquiryListGet = async (params:any, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.get(APIs.PRO_INQUIRY, params);
            if (result.status === 'SUCCESS') {
                success(result)
            } else {
                ProService.failHandler(result)
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }

    static inquiryListForDashboardGet = async (success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.get(APIs.PRO_INQUIRY + "/dashboard");
            if (result.status === 'SUCCESS') {
                success(result)
            } else {
                ProService.failHandler(result)
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }

    static inquiryOneGet = async (bidx:string, success:(result:any)=>void, fail?:(result:any)=>void) => {
        try {
            const result = await axiosCaller.get(APIs.PRO_INQUIRY + "/" + bidx);
            if (result.status === 'SUCCESS') {
                success(result)
            } else {

                if (fail){
                    fail(result)

                } else {
                    ProService.failHandler(result)

                }

            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }

    static inquirySave = async (formData: FormData, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.post(APIs.PRO_INQUIRY, formData, {'Content-Type': 'application/x-www-form-urlencoded'})
            if (result.status === 'SUCCESS') {
                success(result)
            } else {
                ProService.failHandler(result)
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }

    static inquiryUpdate = async (bidx: number, formData: FormData, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.post(APIs.PRO_INQUIRY + "/" + bidx, formData, {'Content-Type': 'application/x-www-form-urlencoded'})
            if (result.status === 'SUCCESS') {
                success(result)
            } else {
                ProService.failHandler(result)
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }

    static inquiryCancel = async (params: {bidx:number, msg:string}, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.put(APIs.PRO_INQUIRY_CANCEL, params);
            if (result.status === 'SUCCESS') {
                success(result)
            } else {
                ProService.failHandler(result)
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }

    static inquiryReviewSave = async (params:any, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.post(APIs.PRO_INQUIRY_REVIEW, params);
            if (result.status === 'SUCCESS') {
                success(result)
            } else {
                ProService.failHandler(result)
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }

    static getInquiryReceiver = async (params:any, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.get(APIs.PRO_INQUIRY_RECEIVER, params);
            if (result.status === 'SUCCESS') {
                success(result)
            } else {
                ProService.failHandler(result)
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }

    static helpListSearch = async (params:any, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.get(APIs.PRO_HELP_SEARCH, params)
            if (result.status === 'SUCCESS') {
                success(result)
            } else {
                ProService.failHandler(result)
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }



    static helpOneGet = async (bidx: string, success:(result:any)=>void, fail:()=>void) => {
        try {
            let result = await axiosCaller.get(APIs.PRO_HELP_BOARD + '/' + bidx)
            if (result.status === 'SUCCESS') {
                success(result)
            } else {
                ProService.failHandler(result)
                fail()
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }



    static helpCommentListGet = async (bidx: string, success:(result:any)=>void) => {
        try {
            let result = await axiosCaller.get(APIs.PRO_HELP_BOARD + '/' + bidx + '/comment')
            if (result.status === 'SUCCESS') {
                success(result)
            } else {
                ProService.failHandler(result)
                fail()
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }

    static helpCommentSave = async (formData: FormData, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.post(APIs.PRO_HELP_BOARD_COMMENT, formData, {'Content-Type': 'application/x-www-form-urlencoded'})
            if (result.status === 'SUCCESS') {
                success(result)
            } else {
                ProService.failHandler(result)
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }

    static helpCommentUpdate = async (cidx: number, formData: FormData, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.post(APIs.PRO_HELP_BOARD_COMMENT + '/' + cidx, formData, {'Content-Type': 'application/x-www-form-urlencoded'})
            if (result.status === 'SUCCESS') {
                success(result)
            } else {
                ProService.failHandler(result)
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }

    static helpCommentDelete = async (params:{cidx:number}, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.delete(APIs.PRO_HELP_BOARD_COMMENT, params);
            if (result.status === 'SUCCESS') {
                success(result)
            } else {
                ProService.failHandler(result)
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }

    static inquiryCommentSave = async (formData: FormData, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.post(APIs.PRO_INQUIRY_COMMENT, formData, {'Content-Type': 'application/x-www-form-urlencoded'})
            if (result.status === 'SUCCESS') {
                success(result)
            } else {
                ProService.failHandler(result)
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }

    static inquiryCommentUpdate = async (cidx:number, formData: FormData, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.post(APIs.PRO_INQUIRY_COMMENT + '/' + cidx, formData, {'Content-Type': 'application/x-www-form-urlencoded'})
            if (result.status === 'SUCCESS') {
                success(result)
            } else {
                ProService.failHandler(result)
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }

    static inquiryCommentDelete = async (params:{bidx:number, cidx:number}, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.delete(APIs.PRO_INQUIRY_COMMENT, params);
            if (result.status === 'SUCCESS') {
                success(result)
            } else {
                ProService.failHandler(result)
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }

    static inquiryJournal = async (params:{journal: string, bidx: number}, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.post(APIs.PRO_INQUIRY_JOURNAL, params);
            if (result.status === 'SUCCESS') {
                success(result)
            } else {
                ProService.failHandler(result)
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }

    static selfProInfoGet = async (success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.get(APIs.PRO_INFO + "self")
            if (result.status === 'SUCCESS') {
                success(result)

            } else {
                ProService.failHandler(result)
            }

        } catch (error) {
            ProService.failHandler(error)
        }
    }



}

export default ProService
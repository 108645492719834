import React from "react";
import {Link} from "react-router-dom";

const M_MainProRecommendEmpty = () => {
    return (
        <div className="banner-section">
            <div className="inner">
                <div className="banner">
                    <span className="desc">진단 완료시 <b>1,000P</b> 지급!</span>
                    <strong className="tit">직무역량진단을 통해<br/>나에게 필요한 역량을 찾아보세요</strong>
                    <Link to="/mypage/jobskill" className="btn sm">지금 바로 하러가기</Link>
                </div>
            </div>
        </div>
    )
}

export default M_MainProRecommendEmpty;
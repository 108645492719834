import {BoardVO} from "../../../../core/board/board.vo";
import React, {useEffect, useState} from "react";
import {HWMap} from "../../../../core/common/common.vo";
import WebUtils from "../../../../common/utils/web.utils";
import M_WebzineItem from "../item/M_WebzineItem";

const M_Gallery: React.FC<{paramMap:HWMap}> = ({paramMap = undefined}) => {
    const [boardList, setBoardlist] = useState<BoardVO[]>([])

    /**
     * 받아온 데이터 정의
     */
    useEffect(() => {
        WebUtils.log(paramMap)
        if (paramMap?.boardList !== undefined) {
            setBoardlist( paramMap.boardList )
        }
    }, [paramMap]);

    return (

        <ul className="webzine-list type1">
            {boardList.map((item, index) => (
                <M_WebzineItem key={item.idx} board={item} />
            ))}
        </ul>
    );
}

export default M_Gallery;
import {ifError} from "assert";

export interface ModifierAndSmallTalk{
    modifier: string;
    smalltalk: string;
}

export const getPostposition = (word:string) => {
    const lastChar = word[word.length - 1];
    const uniCode = lastChar ? lastChar.charCodeAt(0) : 44032;
    const consonantBase = 44032;
    const consonantOffset = 28;

    const consonantIndex = (uniCode - consonantBase) % consonantOffset;
    return consonantIndex === 0 ? '는' : '은';
};

export const getModifierAndSmallTalk = (score:number) => {

    const over90Arr = [
        {
            modifier: "ACE ",
            smalltalk: "매우 우수한 역량을 가졌어요!"
        },
        {
            modifier: "인정받는 ",
            smalltalk: "나만의 노하우도 좋지만 기본을 놓치지 않는게 중요해요!"
        },
        {
            modifier: "이미 완성형 ",
            smalltalk: "하지만 멈춰 있지 않게 최근 지식들을 학습해봐요!"
        }
    ]

    const over70Arr = [
        {
            modifier: "가능성 높은 ",
            smalltalk: "부족한 부분을 개선하면 ACE가 될 수 있어요!"
        },
        {
            modifier: "적당한 실력을 갖춘 ",
            smalltalk: "뛰어난 ACE가 되기 위해 더 학습해 보세요!"
        },
        {
            modifier: "필요한 역량을 갖춘 ",
            smalltalk: "완성형이 되기 위해 필요한 것을 확인해볼께요!"
        }
    ]

    const etcArr = [
        {
            modifier: "노력을 통해 성장가능성이 큰 ",
            smalltalk: "역량 개선을 위해 학습하고 적용해봐요!"
        },
        {
            modifier: "학습을 통해 발전할 수 있는 ",
            smalltalk: "지식을 보고 적용 후 피드백 해보세요!"
        },
        {
            modifier: "아직은 도움이 필요한 ",
            smalltalk: "추천 사내고수를 통해 도움을 받으세요!"
        }
    ]

    let targetArr = []

    if (score >= 90) {
        targetArr = over90Arr
    } else if (score > 70) {
        targetArr = over70Arr
    } else {
        targetArr = etcArr
    }
    const randomIndex = Math.floor(Math.random() * targetArr.length);
    return targetArr[randomIndex]
}

export const getTransitionStr = (pastScore:number, currentScore:number) => {

    const upArr = [
        "지난 진단대비 종합 점수가 00점 상승 했어요. 꾸준히 노력하는 모습 멋져요!",
        "지난 진단대비 종합 점수가 00점 상승 했어요. 성장 노하우가 궁금해요! 학습후기에 남겨주세요!",
    ]

    const downArr = [
        "지난 진단대비 종합 점수가 00점 하락 했어요. 상위 유저들은 평균 21개의 콘텐츠를 학습하고 있어요!",
        "지난 진단대비 종합 점수가 00점 하락 했어요. 자신감이 필요해요! 성과를 만들어보아요!"
    ]

    const equalArr = [
        "지난 진단대비 종합 점수가 동일해요."
    ]

    if (pastScore == 0){
        return ""
    }

    let targetArr = []
    let scoreDiff = currentScore - pastScore;

    if (scoreDiff > 0) {
        targetArr = upArr
    } else if (scoreDiff == 0) {
        targetArr = equalArr
    } else {
        targetArr = downArr
    }

    const randomIndex = Math.floor(Math.random() * targetArr.length);
    const str = targetArr[randomIndex];
    return str.replace("00", Math.abs(scoreDiff).toString())
}


import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

const SearchInput : React.FC = () => {

    const [searchText, setSearchText] = useState('');
    const navigator = useNavigate();

    const doSearch = () => {
        if (searchText.trim()) {
            setSearchText('');
            navigator(`/search/${searchText}`);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            doSearch();
        }
    };

    return (
        <div className="search">
            <input
                type="text"
                className="inp-search"
                placeholder="검색어를 입력하세요."
                value={searchText}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
            />
            <button type="button" className="btn-search" onClick={doSearch}>
                <span className="blind">검색</span>
            </button>
        </div>
    )
}

export default SearchInput;
import React, {useEffect, useState} from "react";
import {BoardVO} from "../../../../core/board/board.vo";
import APIs from "../../../../common/apis/APIs";
import StringUtils from "../../../../common/utils/string.utils";
import {Link, useLocation} from "react-router-dom";
import ToastUtils from "../../../../common/utils/toast.utils";
import BoardService from "../../../../core/board/board.service";
import FileUtils from "../../../../common/utils/file.utils";

interface CardItemProps {
    board: BoardVO;
    onRefresh?: () => void; // Optional
}

const CardItem: React.FC<CardItemProps> = ({board, onRefresh}) => {

    const boardService = new BoardService();
    const location = useLocation()
    const [isWish, setIsWish] = useState(board.isWish);
    const [randomJobImg, setRandomJobImg] = useState<string | null>(null);

    useEffect(() => {
        if (board.boardKey === 'job_data') {
            setRandomJobImg(FileUtils.getRandomJobDataImg(board));
        }
    }, [board.boardKey]);

    /**
     * 관심 지식
     */
    const setBookMark = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

        event.stopPropagation(); // 이벤트 전파 방지
        event.preventDefault(); // 기본 동작 방지

        let result;

        if (isWish) {
            result = await boardService.deleteWishBoard({ bidx: board.idx });
        } else {
            result = await boardService.putWishBoard({ bidx: board.idx });
        }

        if (result.status === "SUCCESS" && result.data) {
            setIsWish(result.data.isWished);

            if (result.data.isWished) {
                ToastUtils.show("관심 지식에 저장되었습니다.", 2);
            } else {
                ToastUtils.show("관심 지식에서 해제되었습니다.", 2);
            }

            // 새로고침 함수있다면 호출
            if (onRefresh) {
                onRefresh();
            }
        }
    };

    return (
        <li className={`slide-item swiper-slide ${board.isView && 'viewed'}`}>
            <Link to={`/board/${board.boardKey + '/' + board.idx + '/'}` + StringUtils.titleUrlFilter(board.title)}
                  state={{from:location.pathname}}>
                <div className="image-wrap">

                    {
                        board.boardKey == 'job_data'
                            ? <img src={randomJobImg || APIs.BOARD_ERROR_IMG} alt="" className="img-thumb" />
                            : <img
                                src={ FileUtils.getImageSrc(board.thumbFile) }
                                onError={ FileUtils.onErrorBoardImg }
                                alt="" className="img-thumb"/>
                    }

                    {
                        board.boardKey != 'job_data' &&
                        <button type="button"
                                onClick={setBookMark}
                                className={`btn-bookmark ${isWish ? 'active' : ''}`}>
                            <span className="blind">북마크</span>
                        </button>
                    }

                    {
                        (board.boardType === 'mov' || board.boardType === 'card' || board.boardType === 'pdf') && (
                            <span className="data">
                                {
                                    board.boardType === 'mov' ? StringUtils.formatTime(board.duration) : "카드뉴스"
                                }
                            </span>
                        )
                    }

                </div>
                <div className="text-wrap">
                <div className="cate-wrap" style={{height:'24px'}}>
                        <span className="cate">{board.jobName}</span>

                        {/* 추천 / 신규 / 인기 뱃지 */}
                        <Badge type={board.opt1 ? board.opt1 : ''} />

                    </div>
                    <strong className="tit">{board.title}</strong>
                    <div className="rating-wrap">
                        <span className="star">{board.rateAvg.toFixed(1)} ({board.reviewCount})</span>
                        <div className="hits">{board.views.toLocaleString()}</div>
                    </div>
                    {
                        board.writerInfo &&
                        <div className="writer">
                            <img src={FileUtils.getFileUrl(board?.writerInfo?.profileSrc ?? "")}
                                 onError={FileUtils.onErrorImg}
                                 alt="" className="img-profile"/>
                            {
                               board.writerInfo.isAdmin
                                ? board.writer
                                : `${board.writerInfo.coInfoVO?.coName} ${board.writerInfo.userName} 고수`
                            }
                        </div>
                    }
                </div>
            </Link>
        </li>
    );
}

export default CardItem;


function Badge({type}: { type: string }) {
    switch (type) {
        case 'commend':
            return <span className="badge type1 green">추천</span>;
        case 'popular':
            return <span className="badge type1 red">인기</span>;
        case 'new':
            return <span className="badge type1 orange">신규</span>;
        default:
            return <></>;
    }
}
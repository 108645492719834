import React, {useEffect, useRef} from "react";
import {BoardVO} from "../../../../core/board/board.vo";
import M_CardItem from "./M_CardItem";
import M_NullItem from "./M_NullItem";
import type { Swiper as SwiperType } from 'swiper';
import SwiperInit from 'swiper';

const M_RelatedItem: React.FC<{boardList: BoardVO[]}> = ({boardList}) => {
    const swiperRef = useRef<SwiperType | null>(null);

    useEffect(() => {
        initSwiper();

    }, [boardList]);

    const initSwiper = () => {
        swiperRef.current?.destroy(true, true);

        if (boardList.length > 0) {
            swiperRef.current = new SwiperInit(".board-slide", {
                slidesPerView: "auto",
                spaceBetween: 24,
            });
        }

    };


    return (
        <section className="relation-section">
            <div className="inner">
                <div className="section-title">
                    <h3 className="tit">연관 지식</h3>
                </div>
                <div className="board-list board-slide">
                    {
                        boardList.length > 0 ?
                        <ul className="card-list type1 swiper-wrapper">
                            {
                                boardList.map((item, index) => (
                                    <M_CardItem key={index} board={item} isRateShow={true}/>
                                ))
                            }
                        </ul>
                        : <M_NullItem text={"연관지식을 모두 시청하셨습니다 😀"}/>
                    }
                </div>
            </div>
        </section>
    );
}

export default M_RelatedItem;
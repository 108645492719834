class CommonJS {

    static all() {
        // this.select();
        // this.inputFile();
        this.gnb();
        this.typeChange();
        this.accordion();
        this.dropdown();
        this.mediaFile();
        this.tooltip();
        this.lineModal();
        this.tabmenu();
        this.floatingNav();
        this.radioBg();
        this.mediaModal();
    }

    static gnb() {
        try {
            var gnb = $(".header .gnb");
            var depOneItem = gnb.find(".gnb-list > li");
            var deptwoWrap = gnb.find(".depth2-wrap");
            var bg = $(".gnb-bg");

            depOneItem
            .on("mouseover focusin", function () {
                gnb.find(".depth1-list > li").removeClass("active");
                gnb.find(".depth2-wrap").hide();
                $(this).addClass("active");
                if ($(this).find(deptwoWrap).length > 0) {
                    $(this).find(deptwoWrap).show();
                    bg.stop().show();
                }
            })
            .on("mouseleave focusout", function () {
                gnb.find(".depth1-list > li").removeClass("active");
                gnb.find(".depth2-wrap").hide();
                bg.stop().hide();
            });
        } catch (e) {
        }
    }

    static typeChange() {
        try {
            var btnChange = $(".btn-change");
            var inpPassword = $(".inp-password");

            btnChange.on("click", function () {
                if ($(this).parent().is(".show")) {
                    inpPassword
                    .attr("type", "password")
                    .parent()
                    .removeClass("show");
                } else {
                    inpPassword.attr("type", "text").parent().addClass("show");
                }
            });
        } catch (e) {
        }
    }

    static select() {
        try {
            let selectWrap = document.querySelectorAll('.select');

            selectWrap.forEach((parentWrap) => {
                let selectBtn = parentWrap.querySelector(".btn-select");
                let selectOpt = parentWrap.querySelector(".select-option");

                // 이벤트 핸들러
                const handleClick = () => {
                    if(!parentWrap.classList.contains('active')){
                        selectWrap.forEach((wrap)=>{
                            wrap.classList.remove('active')
                        })
                    }
                    parentWrap.classList.add('active')

                    if(selectOpt){
                        parentWrap.querySelectorAll(".select-option a").forEach((a)=>{
                            a.addEventListener('click', (e)=>{
                                let option = a.innerHTML;
                                let siblings = (t) => [...t.parentElement.children].filter(e => e != t)
                                siblings(a.parentElement).forEach(arr=>arr.classList.remove('selected'))
                                parentWrap.classList.remove('active')
                                a.parentElement.classList.add('selected')
                                siblings(selectOpt)[0].innerHTML = option
                            })
                        })
                    }
                }

                // selectBtn.removeEventListener('click', handleClick);
                selectBtn.addEventListener('click', handleClick);
            })

            // 이벤트 핸들러
            const handlerMouseup = (e) => {
                const isOutside = Array.from(selectWrap).every((wrap) => {
                    return !wrap.contains(e.target);
                });

                if (isOutside) {
                    selectWrap.forEach((wrap) => {
                        wrap.classList.remove('active');
                    });
                }
            }

            // document.removeEventListener('mouseup', handlerMouseup);
            // 외부 클릭 이벤트
            document.addEventListener('mouseup', handlerMouseup);
        } catch (e) {
            console.log(e)
        }
    }


    static select4Review() {
        try {
            let selectWrap = document.querySelectorAll('.select');

            selectWrap.forEach((parentWrap) => {
                let selectOpt = parentWrap.querySelector(".select-option");

                // 이벤트 핸들러
                const handleClick = () => {
                    if(parentWrap.classList.contains('active')){
                        selectWrap.forEach((wrap)=>{
                            wrap.classList.remove('active')
                        })
                    } else {
                        parentWrap.classList.add('active')
                    }

                    if(selectOpt){
                        parentWrap.querySelectorAll(".select-option a").forEach((a)=>{
                            a.addEventListener('click', (e)=>{
                                let option = a.innerHTML;
                                let siblings = (t) => [...t.parentElement.children].filter(e => e != t)
                                siblings(a.parentElement).forEach(arr=>arr.classList.remove('selected'))
                                parentWrap.classList.remove('active')
                                a.parentElement.classList.add('selected')
                                siblings(selectOpt)[0].innerHTML = option
                            })
                        })
                    }
                }

                const selectBtn = $(".btn-select");

                selectBtn.off("click");
                selectBtn.on("click", handleClick);
            })

            // 이벤트 핸들러
            const handlerMouseup = (e) => {
                const isOutside = Array.from(selectWrap).every((wrap) => {
                    return !wrap.contains(e.target);
                });

                if (isOutside) {
                    selectWrap.forEach((wrap) => {
                        wrap.classList.remove('active');
                    });
                }
            }

            // document.removeEventListener('mouseup', handlerMouseup);
            // 외부 클릭 이벤트
            document.addEventListener('mouseup', handlerMouseup);
        } catch (e) {
            console.log(e)
        }
    }


    static selectV2(innerHtmlChange = true) {
        try {
            let selectWrap = document.querySelectorAll('.select');

            selectWrap.forEach((parentWrap) => {
                let selectBtn = parentWrap.querySelector(".btn-select");
                let selectOpt = parentWrap.querySelector(".select-option");

                // 이벤트 핸들러
                const handleClick = () => {
                    if(!parentWrap.classList.contains('active')){
                        selectWrap.forEach((wrap)=>{
                            wrap.classList.remove('active')
                        })
                    }
                    parentWrap.classList.add('active')

                    if(selectOpt){
                        parentWrap.querySelectorAll(".select-option a").forEach((a)=>{
                            a.addEventListener('click', (e)=>{
                                let option = a.innerHTML;
                                let siblings = (t) => [...t.parentElement.children].filter(e => e != t)
                                siblings(a.parentElement).forEach(arr=>arr.classList.remove('selected'))
                                parentWrap.classList.remove('active')
                                a.parentElement.classList.add('selected')
                                if (innerHtmlChange){
                                    siblings(selectOpt)[0].innerHTML = option
                                }

                            })
                        })
                    }
                }

                // selectBtn.removeEventListener('click', handleClick);
                selectBtn.addEventListener('click', handleClick);
            })

            // 이벤트 핸들러
            const handlerMouseup = (e) => {
                const isOutside = Array.from(selectWrap).every((wrap) => {
                    return !wrap.contains(e.target);
                });

                if (isOutside) {
                    selectWrap.forEach((wrap) => {
                        wrap.classList.remove('active');
                    });
                }
            }

            // document.removeEventListener('mouseup', handlerMouseup);
            // 외부 클릭 이벤트
            document.addEventListener('mouseup', handlerMouseup);
        } catch (e) {
            console.log(e)
        }
    }

    static accordion() {
        try {
            var accordion = $(".accordion");
            var accordionTit = accordion.find(".tit-accordion");
            var accordionCont = accordion.find(".accordion-content");

            accordionTit.off("click");

            accordionTit.on("click", function () {
                if ($(this).parent().hasClass("active")) {
                    $(this)
                    .parent()
                    .toggleClass("active")
                    .find(accordionCont)
                    .slideToggle("fast");
                } else {
                    $(this)
                    .parent()
                    .toggleClass("active")
                    .find(accordionCont)
                    .slideToggle("fast");
                }
            });
        } catch (e) {
        }
    }

    static dropdown() {
        try {
            var dropdown = $(".dropdown");
            var dropdownTit = dropdown.find(".btn-dropdown");
            var dropdownCont = dropdown.find(".dropdown-content");

            dropdownTit.on("click", function () {
                if ($(this).parent().hasClass("active")) {
                    $(this)
                    .parent()
                    .toggleClass("active");
                } else {
                    $(this)
                    .parent()
                    .toggleClass("active");
                }
            });
        } catch (e) {
        }
    }

    static dropdownV2() {
        try {
            var dropdown = $(".dropdown");
            var dropdownTit = dropdown.find(".btn-dropdown");

            // 기존 클릭 이벤트 해제
            dropdownTit.off("click");

            dropdownTit.on("click", function (event) {
                var $dropdown = $(this).parent();
                var isActive = $dropdown.hasClass("active");

                // 모든 드롭다운 닫기
                dropdown.removeClass("active");

                // 클릭한 드롭다운 토글
                if (!isActive) {
                    $dropdown.addClass("active");

                    // 외부 클릭 감지를 위한 이벤트 바인딩
                    $(document).on("click.dropdown", function (e) {
                        // 클릭한 곳이 활성 드롭다운이 아닌 경우
                        if (!$(e.target).closest('.dropdown.active').length) {
                            dropdown.removeClass("active");
                            $(document).off("click.dropdown");
                        }
                    });
                }

                // 이벤트 전파 중단
                event.stopPropagation();
            });

            // 드롭다운 내부 클릭 시 전파 중단 (옵션)
            dropdown.on("click", function (event) {
                event.stopPropagation();
            });

        } catch (e) {
            console.error(e);
        }
    }

    /**
     * 인풋 파일 연동
     * @param className 타겟 클래스
     * @param isPath 경로로 표시할지 결정, true일 경우 경로까지, false일 경우 파일명만 표시
     */
    static inputFile(className = '.file-upload', isPath= true) {
        try {
            var uploadeWrap = $(className);
            var uploadBtn = uploadeWrap.find(".btn-upload");
            var uploadPath = uploadeWrap.find(".inp-upload");
            var fileInp = uploadeWrap.find(".inp-file");

            uploadBtn.off()
            uploadPath.off()
            fileInp.off()

            uploadBtn.attr('onclick', '').unbind('click')
            uploadBtn.on("click", function () {
                $(this).siblings(fileInp).click();

                fileInp.on("change", function () {
                    var filePath = $(this).val();
                    if (!isPath){
                        filePath = filePath.split('\\').pop().split('/').pop();
                    }
                    $(this).siblings(uploadPath).val(filePath);
                });
            });
        } catch (e) {
        }
    }

    static mediaFile() {
        try {
            var uploadeWrap = $(".media-upload, .profile-upload");
            var uploadBtn = uploadeWrap.find(".btn-upload");

            uploadBtn.on("click", function () {
                if ($(this).siblings(".inp-file")) {
                    $(this).siblings(".inp-file").click();
                }
            });
        } catch (e) {
        }
    }

    static tooltip() {
        try {
            var tooltipWrap = $(".tooltip");
            var tooltipBtn = tooltipWrap.find(".btn-tooltip");
            var tooltipClose = tooltipWrap.find(".btn-close");

            tooltipBtn.off("click");
            tooltipClose.off("click");

            tooltipBtn.on("click", function () {
                $(this).parent().toggleClass("active");
            });
            tooltipClose.on("click", function () {
                tooltipWrap.removeClass("active");
            });
        } catch (e) {
        }
    }

    static tooltipV2() {
        try {
            var tooltipWrap = $(".tooltip");
            var tooltipBtn = tooltipWrap.find(".btn-tooltip");
            var tooltipClose = tooltipWrap.find(".btn-close");

            // 이전에 바인딩된 클릭 핸들러 해제
            tooltipBtn.off("click");
            tooltipClose.off("click");
            $(document).off("click.tooltip");

            tooltipBtn.on("click", function (event) {
                var $tooltip = $(this).parent();
                var isActive = $tooltip.hasClass("active");

                // 모든 툴팁 닫기
                tooltipWrap.removeClass("active");

                // 클릭한 툴팁 토글
                if (!isActive) {
                    $tooltip.addClass("active");

                    // 이벤트 전파 중단하여 즉시 닫히는 것 방지
                    event.stopPropagation();

                    // 툴팁이 활성화된 경우에만 이벤트 리스너 추가
                    $(document).on("click.tooltip", function (e) {
                        // 클릭한 곳이 활성 툴팁이 아닌 경우
                        if (!$(e.target).closest('.tooltip').length) {
                            tooltipWrap.removeClass("active");
                            $(document).off("click.tooltip");
                        }
                    });
                } else {
                    // 툴팁이 비활성화된 경우 클릭 핸들러 해제
                    $(document).off("click.tooltip");
                }
            });

            tooltipClose.on("click", function (event) {
                $(this).parent().removeClass("active");
                $(document).off("click.tooltip");
                event.stopPropagation(); // 닫기 버튼 클릭 시 이벤트 전파 중단
            });

            // 툴팁 내부 클릭 시 이벤트 전파 중단
            tooltipWrap.on("click", function (event) {
                event.stopPropagation();
            });

        } catch (e) {
            console.error(e);
        }
    }

    static lineModal() {
        try {
            var modal = $(".line-modal");
            var modalClose = modal.find(".btn-close");

            modalClose.on("click", function () {
                $(this).parent().removeClass("active");
            });
        } catch (e) {
        }
    }

    static tabmenu() {
        try {
            var tabs = $(".tabs");

            tabs.each(function () {
                var tabMenu = $(this).find(".tab-item");
                var tabCont = $(this).find(".tab-panel");

                tabMenu.on("click", function () {
                    var idx = tabMenu.index(this);
                    tabCont.hide().eq(idx).show();
                    tabMenu.removeClass("active").eq(idx).addClass("active");
                });
                tabMenu.eq(0).click();
            });
        } catch (e) {
        }
    }

    static floatingNav() {
        try {
            var floatNav = $(".floating-nav");
            var navTop = floatNav.offset().top;

            $(window).scroll(function () {
                if ($(this).scrollTop() >= navTop) {
                    floatNav.addClass("fixed");
                } else {
                    floatNav.removeClass("fixed");
                }
            });
        } catch (e) {
        }
    }

    static radioBg() {
        try {
            var radioWrap = $(".course.detail .radio");
            var radioInp = radioWrap.find(".inp-radio");

            radioInp.on("click", function () {
                if ($(this).parent().has("active")) {
                    radioWrap.removeClass("active");
                    $(this).parent().addClass("active");
                } else {
                    $(this).parent().removeClass("active");
                }
            });
        } catch (e) {
        }
    }


    static hpPlayer() {
        try {
            var hpPlayer = $(".hp-player");
            var controlBar = hpPlayer.find(".hp-control-bar");
            var progressBar = hpPlayer.find(".hp-progress-bar");
            var progressInp = progressBar.find(".inp-range");

            var dropdownWrap = $(".hp-dropdown");
            var dropdownBtn = dropdownWrap.find(".btn-dropdown");

            hpPlayer
                .on("mouseover hover", function () {
                    controlBar.stop().fadeIn(100);
                })
                .on("mouseleave", function () {
                    setTimeout(function () {
                        controlBar.stop().fadeOut();
                    }, 4000);
                });

            dropdownBtn.click(function () {
                if (!$(this).parent(dropdownWrap).hasClass("active")) {
                    dropdownWrap.removeClass("active");
                    $(this).parent(dropdownWrap).toggleClass("active");
                } else {
                    $(this).parent(dropdownWrap).toggleClass("active");
                }

                $(document).mouseup(function (e) {
                    if (dropdownWrap.has(e.target).length === 0) {
                        dropdownWrap.removeClass("active");
                    }
                });
            });

            progressInp.bind('change mousemove', function() {
                var val = $(this).val();
                var maxVal = parseInt($(this).attr('max')); // max 속성 값을 정수로 파싱

                // 버퍼값 계산
                var bufferPercent = val / maxVal * 100;

                // linear-gradient 스타일 적용
                $(this).css(
                    'background',
                    'linear-gradient(to right, #e30019 0%, #e30019 ' + bufferPercent + '%, #888 ' + bufferPercent + '%, #888 ' + bufferPercent + '%, #444 ' + bufferPercent + '%, #444 100%)'
                );
            });

        } catch (e) {}
    }


    static hpPlayerProgress () {
        var hpPlayer = $(".hp-player");
        var controlBar = hpPlayer.find(".hp-control-bar");
        var progressBar = hpPlayer.find(".hp-progress-bar");
        var progressInp = progressBar.find(".inp-range");

        var val = progressInp.val();
        var maxVal = parseInt(progressInp.attr('max')); // max 속성 값을 정수로 파싱

        // 버퍼값 계산
        var bufferPercent = val / maxVal * 100;

        // linear-gradient 스타일 적용
        progressInp.css(
            'background',
            'linear-gradient(to right, #e30019 0%, #e30019 ' + bufferPercent + '%, #888 ' + bufferPercent + '%, #888 ' + bufferPercent + '%, #444 ' + bufferPercent + '%, #444 100%)'
        );
    }


    static mediaModal() {
        try {
            var modalOpen = $(".media-content .btn-media");
            var modal = $(".media-modal");
            var modalClose = modal.find(".btn-close");

            modal.hide();
            modalOpen.on("click", function () {
                modal.fadeIn();
            });
            modalClose.on("click", function () {
                modal.fadeOut();
            });

        } catch (e) {}
    }


}

export default CommonJS
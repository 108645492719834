import React, {useEffect, useImperativeHandle, useRef, useState} from "react";
import ReactPlayer from "react-player";
import screenfull from "screenfull";
import dateUtils from "../../../common/utils/date.utils";
import ToastUtils from "../../../common/utils/toast.utils";
import {OnProgressProps} from "react-player/types/base";
import CommonJS from "../../../common/common";
import {VideoVO} from "../../../core/education/education.vo";
import FileUtils from "../../../common/utils/file.utils";
import FileService from "../../../core/common/file.service";
import swalUtils from "../../../common/utils/swal.utils";
import DeviceUtils from "../../../common/utils/device.utils";
import CommonMobileJS from "../../../common/common_m";



interface HwsPlayerProps {
	url: string
	playing?: boolean
	ready?: boolean
	pip?: boolean
	controls?: boolean
	width?: string | number
	height?: string | number
	light?: false
	volume?: number
	muted?: false
	played?: number
	loaded?: number
	duration?: number
	playbackRate?: number
	loop?: boolean,
	
	thumbnail?: string | boolean,
	isSkip?: boolean,
	isSpeed?: boolean,
	videoList?: Array<VideoVO> | null,
	
	fnSeek?: Function | null,
	fnProgress?: Function | null,
	fnEnded?: Function | null,
}

const M_HwsPlayer = React.forwardRef( (_props:HwsPlayerProps, ref ) => {

	const [created, setCreated] = useState<boolean>(false)
	const [mounted, setMounted] = useState<boolean>(false)
	
	const [playerProps, setPlayerProps] = useState<HwsPlayerProps>({
		url: `http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4`,
		pip: false,
		playing: false,
		ready: false,
		controls: false,
		width: _props.width, // 초기 너비 설정
		height: _props.height, // 초기 높이 설정
		light: false,
		volume: 0.8,
		muted: false,
		played: 0,
		loaded: 0,
		duration: 0,
		playbackRate: 1.0,
		loop: false,
		
		thumbnail: false,
		isSkip: true,
		isSpeed: true,
		
		videoList: null,
		fnSeek: null,
		fnProgress: null,
		fnEnded: null,
	});
	
	const $playerRef = useRef<ReactPlayer>(null)
	const $seekingRef = useRef<boolean>(false);
	const continueSeconds = useRef<number>(0);
	
	/**
	 * 초기 effect
	 */
	useEffect(() => {

		if ( _props ) {
			
			setPlayerProps(prevState => ({
				...prevState,
				..._props
			}));
			
			setCreated(true)
		}
	}, [])
	
	
	/**
	 * created
	 * 추가데이터 조회
	 */
	useEffect(() => {
		
		if ( created ) {
			
			(async () => {
				const realfilename = FileUtils.getRealfilename(playerProps.url);
				const videoList = await FileService.getVideoList(realfilename);
				
				if ( videoList && videoList.length > 0 ) {
					
					const lowVideo = videoList.length > 0 ? videoList[videoList.length - 1] : null;
					const lowFilename = lowVideo.realfilename;
					setCurrentResolution(lowVideo.resHeight)
					const lowUrl = FileUtils.getRealfilePath( playerProps.url ) + lowFilename;
					setPlayerProps(prevState => ({
						...prevState,
						url: lowUrl,
						videoList: videoList
					}));
				}
				
				setMounted(true)
			})()
		}
		
	}, [created])
	
	
	/**
	 * mounted
	 * 라이브러리 실행
	 */
	useEffect(() => {
		
		if ( mounted ) {
			CommonMobileJS.hpPlayer()
		}
		
	}, [mounted])


	/**
	 * 현재 선택된 해상도 값
	 */
	const [currentResolution, setCurrentResolution] = useState<number>()
	const doChangeResolution = async (_index:number) => {
		
		if ( playerProps.videoList ) {
			await setCurrentResolution( playerProps.videoList[_index].resHeight )
			const lowFilename = playerProps.videoList[_index].realfilename;
			const lowUrl = FileUtils.getRealfilePath( playerProps.url ) + lowFilename;
			
			const seconds = $playerRef.current?.getCurrentTime()
			await setPlayerProps(prevState => ({
				...prevState,
				url: lowUrl
			}));
			
			onSeek(seconds)
		}
	}

	
	const onReady = async () => {
		
		// 준비상태 X + 이어보기 시간 O 있을 경우
		if (!playerProps.ready && continueSeconds.current > 0) {
			console.log("🎧 이어보기 준비 : " + continueSeconds.current);
			await onSeek(continueSeconds.current);
		}
		await setPlayerProps(prev => ({...prev, ready: true}))
	};
	const onPlay = async () => setPlayerProps(prev => ({...prev, playing: true}));
	const onPause = async () => setPlayerProps(prev => ({ ...prev, playing: false }));
	// @ts-ignore
	const onMuted = async () => setPlayerProps(prev => ({...prev, muted: !playerProps.muted}));
	const onProgress = async (state: OnProgressProps) => {
		await setPlayerProps(prev => ({ ...prev, played: state.played, loaded: state.loaded }));
		CommonJS.hpPlayerProgress()
		
		const playseconds = Math.round(state.playedSeconds)
		if ( playerProps.fnProgress != null && typeof playerProps.fnProgress === 'function' && playseconds > 0 ) {
			await playerProps.fnProgress(playseconds)
		}
	};
	const onSeek = async (seconds: any) => {
		//console.log(`seekTo :::::: ${seconds}`)
		if ($playerRef.current) {
			$playerRef.current.seekTo(seconds, 'seconds');
		}
		
		if ( playerProps.fnSeek != null && typeof playerProps.fnSeek === 'function') {
			await playerProps.fnSeek(seconds)
		}
	};
	const onEnded = async () => {
		await setPlayerProps(prev => ({ ...prev, playing: false }))
		if ( playerProps.fnEnded != null && typeof playerProps.fnEnded === 'function') {
			await playerProps.fnEnded()
		}
	};
    const onFullscreen = async () => {
        if (screenfull.isEnabled && $playerRef.current) {
            await screenfull.toggle(document.querySelector('.hp-player')!);
        } else {
            // Safari / iOS Mobile
            let videoElement = $playerRef.current && $playerRef.current.getInternalPlayer() as any;
            if (videoElement?.webkitEnterFullScreen) {
                await videoElement.webkitEnterFullScreen();

            } else {
                console.warn("Fullscreen is not supported in this environment.");
            }
        }
    }
	
	
	
	
	/**
	 * 재생속도 변경 이벤트
	 * @param _speed
	 */
	const onPlaybackRateChange = async (_speed:number) => {
		
		/**
		 * 학습가능상태 확인
		 */
		if ( !playerProps.isSpeed ) {
			ToastUtils.show("빨리듣기가 불가능한 컨텐츠 입니다.")
			$playerRef.current!.getInternalPlayer().playbackRate = 1
		} else {
			$playerRef.current!.getInternalPlayer().playbackRate = _speed
		}
		
		setPlayerProps(prevState => ({
			...prevState,
			playbackRate: $playerRef.current!.getInternalPlayer().playbackRate
		}))
	}
	
	/**
	 * progressBar 마우스 다운
	 * @param e
	 */
	const onSeekMouseDown = (e:any) => {
		//console.log(`onSeekMouseDown :::::: ${e.target.value}`)
		$seekingRef.current = true;
	}
	
	/**
	 * progressBar 변경
	 * @param e
	 */
	const onSeekChange = (e:any) => {
		//console.log(`onSeekChange :::::: ${e.target.value}`)
		setPlayerProps(prev => ({ ...prev, played: e.target.value }));
		onSeek(parseFloat(e.target.value))
		// $playerRef.current?.setState({ played: parseFloat(e.target.value) })
	}
	
	/**
	 * progressBar 마우스 업
	 * @param e
	 */
	const onSeekMouseUp = (e:any) => {
		//console.log(`onSeekMouseUp :::::: ${e.target.value}`)
		$seekingRef.current = false;
	}

	/**
	 * pip toggle
	 */
	const onPipToggle = () => {
		const videoElement = $playerRef.current?.getInternalPlayer() as HTMLVideoElement | null;

		if (!videoElement) {
			console.error('비디오 요소를 찾을 수 없습니다.');
			return;
		}

		if (document.pictureInPictureEnabled) {
			// PiP 모드 지원 시 웹에서 직접 PiP 모드로 전환
			if (!document.pictureInPictureElement) {
				videoElement.requestPictureInPicture().catch(error => {
					console.error('PiP 모드 요청 실패:', error);
				});
			} else {
				document.exitPictureInPicture().catch(error => {
					console.error('PiP 모드 종료 실패:', error);
				});
			}
		} else {
			// PiP 모드 미지원 시 안드로이드 네이티브 코드 호출
			DeviceUtils.onPlayInPip(playerProps.url, $playerRef.current?.getCurrentTime() || 0, playerProps.playing || false);
		}
	}


	// 안드로이드 네이티브에서 호출되는 함수
	(window as any).setPlayerState = (state: 'play' | 'pause', currentTime: number) => {
		const videoElement = $playerRef.current?.getInternalPlayer() as HTMLVideoElement | null;
		if (!videoElement) return;

		// 재생 시간을 설정
		videoElement.currentTime = currentTime / 1000;

		// 전달된 상태에 따라 재생 또는 일시정지
		if (state === 'play') {
			videoElement.play();
			setPlayerProps((prev) => ({ ...prev, playing: true }));
		} else {
			videoElement.pause();
			setPlayerProps((prev) => ({ ...prev, playing: false }));
		}
	};


	/**
	 * done : 배속 선택 팝업창
	 */
	const showSelectPlayrate = async () => {
		
		swalUtils.select({
			title: "속도설정",
			isIcon: false,
			message:  "",
			options: new Map([
				[1, '1x'],
				[1.25, '1.25x'],
				[1.5, '1.5x'],
				[2, '2x']
			]),
			confirmOptions : {
				text: "확인",
				eventHandler: (_text) => {
					if ( _text ) {
						onPlaybackRateChange(Number(_text))
					}
				}
			},
			cancelOptions: {
				text: "취소",
			},
			isCancel: true,
		});
	}
	
	
	/**
	 * done : 해상도 선택 팝업
	 */
	const showSelectResolution = () => {
		let resOptions = playerProps.videoList?.reduce((acc:{[key:number] : string}, item) => {
			const height = item.resHeight;
			acc[height] = `${height}p`;
			return acc;
		}, {});
		
		swalUtils.select({
			title: "속도설정",
			isIcon: false,
			message:  "",
			options: resOptions,
			confirmOptions : {
				text: "확인",
				eventHandler: async (_text) => {
					if ( _text ) {
						const index = playerProps.videoList?.findIndex(_video => _video.resHeight.toString() == _text ) ?? 0
						await doChangeResolution(index)
					}
				}
			},
			cancelOptions: {
				text: "취소",
			},
			isCancel: true,
		});
	}

	
	
	
	/**
	 * 외부 접근 핸들러 정의
	 */
	useImperativeHandle(ref, () => ({
		
		getReady : () => mounted,
		
		/**
		 * Props 반환
		 */
		getProps :() => {
			return playerProps
		},
		
		/**
		 * Props 적용
		 * @param props
		 */
		setProps :(props:HwsPlayerProps) => {
			setPlayerProps(props)
		},
		
		
		seekTo: async (_seconds:number) => {
			continueSeconds.current = _seconds;
			console.log("🎧 이어보기 : " + _seconds);
			await onSeek(_seconds);
		},
		
		
		getInternalPlayer: () => (
			$playerRef.current?.getInternalPlayer()
		)
		
	}))
	
	
	return (
		<div className="hp-player" style={{ width: playerProps.width, height: playerProps.height }}>
			<div className="hp-video" style={{backgroundColor: `black`}}>
				
				<ReactPlayer className={`playerRef`} ref={$playerRef} url={playerProps.url}
				             width={playerProps.width}
				             height={playerProps.height}
							//controls={true}
                             playsinline={true}
					         light={playerProps.thumbnail ? <img src={`${playerProps.thumbnail}`} alt='Thumbnail' style={{zIndex: 50}} onClick={onPlay} /> : false}
					         playIcon={<button style={{zIndex: 55, width: `80px`, height: `80px`, position: `absolute`, top: `50%`, left: `50%`, transform: `translate(-50%, -50%)`} } onClick={onPlay}><img src={`/assets/img/play_icon.png`} style={{width: "80px"}} /></button>}
					         playing={playerProps.playing}
					         muted={playerProps.muted}
				             //light={playerProps.thumbnail}
					         onReady={onReady}
					         onPlay={onPlay}
					         onPause={onPause}
					         onProgress={onProgress}
					//onSeek={onSeek}
					         onPlaybackRateChange={() => {}}
					         onEnded={onEnded}
					         pip={playerProps.pip}
				/>
			
			</div>
			<div className="hp-control-bar">
				<div className="hp-progress-bar">
					<input type="range" className="inp-range"
					       min={0}
					       max={$playerRef.current?.getDuration()}
					       step={0.0001}
					       value={$playerRef.current?.getCurrentTime()}
					       onMouseDown={onSeekMouseDown}
					       onChange={onSeekChange}
					       onMouseUp={onSeekMouseUp}
					       readOnly={playerProps.isSkip}
					/>
				</div>
				<div className="hp-control-button">
					<div className="hp-control-top">
						{ playerProps.isSpeed && <button type="button" id="speedOption" className="btn-speed" onClick={showSelectPlayrate}><span className="blind">재생속도</span></button>}
						{ playerProps.videoList && <button type="button" id="qualityOption" className="btn-quality" onClick={showSelectResolution}><span className="blind">화질</span></button>}
					</div>
					<div className="hp-control-middle">
						{ playerProps?.playing || <button type="button" className="btn-play" onClick={onPlay}><span className="blind">재생</span></button>}
						{ playerProps?.playing && <button type="button" className="btn-pause" onClick={onPause}><span className="blind">일시정지</span></button>}
						{/*
						<button type="button" class="btn-pause"><span class="blind">일시정지</span></button>
						<button type="button" class="btn-stop"><span class="blind">정지</span></button>
						*/}
					</div>
					<div className="hp-control-bottom">
						<div className="hp-timeclip">
							<span>{ $playerRef.current && dateUtils.formatTime( $playerRef.current.getCurrentTime() ) }</span>
							/
							<span>{ $playerRef.current && dateUtils.formatTime( $playerRef.current.getDuration()) }</span>
						</div>
						<div className="hp-screen-button">
							{ playerProps.muted || <button type="button" className="btn-volume" onClick={onMuted}><span className="blind">음소거 해제</span></button> }
							{ playerProps.muted && <button type="button" className="btn-mute" onClick={onMuted}><span className="blind">음소거</span></button> }
							{/*
							<button type="button" className="btn-volume"><span className="blind">음소거</span></button>
							<button type="button" class="btn-mute"><span class="blind">볼륨</span></button>
							*/}
							<button type="button" className="btn-pip" onClick={onPipToggle}><span className="blind">작은화면</span></button>
							<button type="button" className="btn-fullscreen" onClick={() => onFullscreen()}><span className="blind">전체화면</span></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
})

M_HwsPlayer.displayName = `m_hwsPlayer`
export default M_HwsPlayer
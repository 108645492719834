import React, {useEffect, useRef, useState} from "react";
import JobSkillFilterSelectButton from "../../../pc/common/JobSkillFilterSelectButton";
import DateUtils from "../../../../common/utils/date.utils";
import ProRequestSubmitKeywordInput from "./ProRequestSubmitKeywordInput";
import {useLocation, useNavigate} from "react-router-dom";
import CommonJS from "../../../../common/common";
import FileUtils from "../../../../common/utils/file.utils";
import Swal from "sweetalert2";
import ProService from "../../../../core/pro/pro.service";
import ToastUtils from "../../../../common/utils/toast.utils";
import SwalUtils from "../../../../common/utils/swal.utils";

interface ProRequestSubmitVal {
    activityCode: string,
    workingYear: number,
    strengthKeyword: string,
}

interface ProSubmitAsk {
    askTitle: string,
    askContent: string,
    placeholder: string
}

const ProRequestSubmit = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const [activeList, setActiveList] = useState<string[]>([])
    const [askList, setAskList] = useState<ProSubmitAsk[]>([])

    const [submitVal, setSubmitVal] = useState<ProRequestSubmitVal>({
        activityCode: location.state?.activityCode ?? "",
        workingYear: 0,
        strengthKeyword: "",
    });

    const [jobCode, setJobCode] = useState<string>('')
    const [skillCode1, setSkillCode1] = useState<string>('')
    const [skillCode2, setSkillCode2] = useState<string>('')

    const profileInput = useRef<HTMLInputElement>(null)
    const fileInput = useRef<HTMLInputElement>(null)


    useEffect(() => {
        CommonJS.inputFile('.profile-upload-div', false)
        CommonJS.inputFile('.file-upload-div', false)
        getInfo()
        getAskList()
    }, []);

    const rateByYear = () => {
        const currentYear = new Date().getFullYear()
        const diffYear = currentYear - submitVal.workingYear + 1
        if (diffYear >= 9) {
            return '시니어 (9년차 이상)'
        } else if (diffYear >= 5) {
            return '미들(5-8년차)'
        } else {
            return '주니어 (1-4년차)'
        }
    }

    const changeKeywordStr = (newVal: string) => {
        setSubmitVal({
            ...submitVal,
            strengthKeyword: newVal
        })
    }

    const getInfo = async () => {
        const successHandler = (result:any) => {
            setActiveList(result.data.activeList)
            const skillList = result.data.strengthList
            if (skillList[0]){
                setSkillCode1(skillList[0])
            }
            if (skillList[1]){
                setSkillCode2(skillList[1])
            }
        }

        await ProService.proRequestStatusGet(successHandler)
    }

    const getAskList = async () => {
        const successHandler = (result:any) => {
            setAskList(result.data.json)
        }
        await ProService.proRequestQuestionGet(successHandler)
    }

    const setAsk = (content: string, index: number) => {
        const newAsk = {...askList[index]}
        newAsk.askContent = content
        setAskList(prev => {
            const newAskList = [...prev]
            newAskList[index] = newAsk

            return newAskList
        })
    }

    const saveSubmit = async () => {
        if (!FileUtils.isValidSize(profileInput.current, 20) && !FileUtils.isValidNum(profileInput.current, 1, 0)) {
            ToastUtils.show("이미지 형식의 프로필 사진 파일을 1장, 20MB 이하로 등록해주세요")
            return
        }

        if (!FileUtils.isValidSize(fileInput.current, 100) && !FileUtils.isValidNum(fileInput.current, 1, 0)) {
            ToastUtils.show("파일을 1개, 100MB 이하로 등록해주세요")
            return
        }

        if (!validCheck()) {
            SwalUtils.text({
                title : `안내`,
                message : `<span style="line-height: 150%">입력하지 않은 항목이 존재합니다.<br/>확인해 주세요.</span>`,
                isCancel : false,
                confirmOptions : {
                    text : "확인",
                    eventHandler : async() => {}
                },
            })
            // alert("입력하지 않은 항목이 존재합니다.")
            return
        }
        const formData = new FormData();

        // 프로필 사진, 파일
        const file = fileInput.current && fileInput.current.files && fileInput.current.files[0]
        const profile = profileInput.current && profileInput.current.files && profileInput.current.files[0]
        profile && formData.append("profileImg", profile)
        file && formData.append("file", file)

        //활동내역, 활동직무, 활동역량, 시작년도, 강점키워드
        formData.append("activityCode", submitVal.activityCode)
        formData.append("workingYear", submitVal.workingYear.toString())
        formData.append("strengthKeyword", submitVal.strengthKeyword)
        formData.append("jobCode", jobCode)
        formData.append("skillCode", [skillCode1, skillCode2].join(','))
        formData.append("requestAnswer", JSON.stringify(askList))

        await ProService.proRequestSubmit(formData, showComplete)
    }

    const validCheck = () => {
        const hasNonEmptyStrings =
            jobCode.trim() !== "" &&
            skillCode1.trim() !== "" &&
            skillCode2.trim() !== "" &&
            submitVal.activityCode.trim() !== "" &&
            submitVal.strengthKeyword.trim() !== "";

        // 숫자 필드가 유효한 범위인지 확인
        const hasValidStartYear = submitVal.workingYear >= 1980;

        // askList 의 content 내용이 없는 것 확인
        const hasAskContents = askList.filter(ask=>ask.askContent.length <= 0).length <= 0

        // 모든 조건이 참이면 true 반환
        return hasNonEmptyStrings && hasValidStartYear && hasAskContents;
    }

    const showComplete = () => {
        Swal.fire({
            title: "사내고수 신청",
            html: `
                <i class="ico-award"></i>
                제출이 완료되었습니다!<br>
                결과는 메일로 알려드릴게요<br><br>
            `,
            showCancelButton: false,
            confirmButtonText: "확인",
            cancelButtonText: "취소",
        }).then(() => {
            window.location.href = '/pro/request'
        })
    }

    return (
        <main className="content register">
            <div className="common-title">
                <div className="inner">
                    <h2 className="tit">사내고수 신청하기</h2>
                </div>
            </div>
            <section className="form-section">
                <div className="inner">
                    <div className="form-list">
                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">활동 내역 *</strong>
                            </div>
                            <div className="sel-bind">
                                <div className="select">
                                    <button type="button"
                                            className="btn-select">{submitVal.activityCode != '' ? submitVal.activityCode : '활동내역을 선택해주세요'}</button>
                                    <ul className="select-option">
                                        {
                                            activeList.map((active, index) => {
                                                return (
                                                    <li className={active == submitVal.activityCode ? "selected" : ""}
                                                        key={index}>
                                                        <a href="#" onClick={e => {
                                                            e.preventDefault()
                                                            setSubmitVal((prev) => {
                                                                return {
                                                                    ...prev,
                                                                    activityCode: active
                                                                }
                                                            })
                                                        }}>{active}</a>
                                                    </li>
                                                )
                                            })
                                        }

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">프로필 사진</strong>
                            </div>
                            <div className="inp-btn-bind type-file profile-upload-div">
                                <input type="file" name="" id="" className="inp-file"
                                       accept="image/*" ref={profileInput}/>
                                <input type="text" name="" id="" className="inp-text inp-upload"
                                       placeholder="프로필에 노출할 사진을 업로드 해 주세요" readOnly={true}/>
                                <button type="button" className="btn gray btn-upload"
                                        style={{background:"rgba(69, 74, 95, 1)", color:"#FFF"}}>첨부파일 찾기
                                </button>
                            </div>
                            <div className="guide-text">
                                파일 포맷 : JPG, JPEG, PNG / 파일크기 : 20MB이하 | 가로-세로 1:1비율 권장
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">활동직무 *</strong>
                            </div>
                            <div className="sel-bind">
                                <JobSkillFilterSelectButton isSkill={false}
                                                            updateJobCode={setJobCode}
                                                            updateSkillCode={() => {
                                                            }}
                                                            placeholder={"직무를 선택해주세요"}
                                                            isFilter={false}/>
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">강점 역량 *</strong>
                            </div>
                            <div className="sel-bind">
                                <JobSkillFilterSelectButton isSkill={true}
                                                            updateJobCode={() => {
                                                            }}
                                                            updateSkillCode={setSkillCode1}
                                                            deactivate={skillCode2}
                                                            initCode={skillCode1}
                                                            placeholder={"역량1을 선택해주세요"}
                                                            isFilter={false}/>

                                <JobSkillFilterSelectButton isSkill={true}
                                                            updateJobCode={() => {
                                                            }}
                                                            updateSkillCode={setSkillCode2}
                                                            deactivate={skillCode1}
                                                            initCode={skillCode2}
                                                            placeholder={"역량2를 선택해주세요"}
                                                            isFilter={false}/>
                            </div>
                            <div className="guide-text">
                                * 직무역량진단결과로 나온 나의 강점역량 2개를 입력해 주세요. (하위 2개 역량을 제외하고 활동역량은 추후 조정가능합니다.)
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">직무 시작 연도 *</strong>
                            </div>
                            <div className="sel-bind">
                                <div className="select">
                                    <button type="button" className="btn-select">
                                        <span style={{color:"var(--gray-40)"}}>활동직무를 시작한 연도를 선택해주세요</span>
                                    </button>
                                    <ul className="select-option">
                                        {
                                            DateUtils.getStartYear().map((year) => {
                                                return (
                                                    <li key={year}>
                                                        <a href="#" onClick={(e) => {
                                                            e.preventDefault()
                                                            setSubmitVal((prev) => {
                                                                return {...prev, workingYear: year}
                                                            })
                                                        }}>{year}</a>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <div
                                    className="year-info">{submitVal.workingYear != 0 && rateByYear()}</div>
                            </div>
                        </div>
                        <ProRequestSubmitKeywordInput changeKeywordEvent={changeKeywordStr}/>
                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">필수 질문 *</strong>
                            </div>
                            {
                                askList && askList.map((ask, index) => {
                                    return (
                                        <div className="inp-bind" key={index}>
                                            <strong
                                                className="sub-tit">{index + 1}. {ask.askTitle}</strong>
                                            <textarea name="" id="" cols={30} rows={4}
                                                      className="inp-text"
                                                      placeholder={ask.placeholder}
                                                      onBlur={e => {
                                                          setAsk(e.target.value, index)
                                                      }
                                                      }></textarea>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">파일 업로드</strong>
                            </div>
                            <div className="inp-btn-bind type-file file-upload-div">
                                <input type="file" name="" id="" className="inp-file"
                                       ref={fileInput}/>
                                <input type="text" name="" id="" className="inp-text inp-upload"
                                       placeholder="검토에 사용될 지식을 업로드해 주세요" readOnly={true}/>
                                <button type="button" className="btn gray btn-upload"
                                        style={{background:"rgba(69, 74, 95, 1)", color:"#FFF"}}>첨부파일 찾기
                                </button>
                            </div>
                            <div className="guide-text">
                                파일크기 : 100MB이하, 1개의 파일 / 확장자는 제약이 없습니다.
                            </div>
                        </div>
                    </div>
                    <div className="button-area">
                        <button type="button" className="btn red lg" onClick={saveSubmit}>신청하기
                        </button>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default ProRequestSubmit
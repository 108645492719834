import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import BoardMov from "../BoardMov";
import BoardCard from "../BoardCard";
import APIs from "../../../../common/apis/APIs";
import {BoardFileVO, BoardVO} from "../../../../core/board/board.vo";
import BoardService from "../../../../core/board/board.service";
import {BoardInfoVO} from "../../../../core/board/BoardInfo.vo";
import CommonJS from "../../../../common/common";
import SummernoteIframe from "../../../pc/common/SummernoteIframe";
import ToastUtils from "../../../../common/utils/toast.utils";
import StringUtils from "../../../../common/utils/string.utils";

interface MovCardDetailProps {
    boardInfoVO: BoardInfoVO,
    boardVO: BoardVO,
    fileList: BoardFileVO[],
    tags: string[],
}

const MovCardDetail : React.FC<MovCardDetailProps> = ({boardInfoVO, boardVO, fileList: initialFileList, tags}) => {
    const boardService = new BoardService();
    let {boardKey, bidx} = useParams();

    const [isWish, setIsWish] = useState(false);

    // 탭 순서) 강의 설명 : 0, AI 요약 beta : 1, 스크립트 : 2
    const [currentTab, setCurrentTab] = useState(0);

    const [fileList, setFileList] = useState(initialFileList);

    useEffect(() => {
        if(boardVO) {
            setIsWish(boardVO.isWish)
        }

        CommonJS.tabmenu()
    }, [boardVO]);



    useEffect(() => {
        setFileList(initialFileList)
    }, [initialFileList]);

    /**
     * 관심 지식
     */
    const setBookMark = () => {

        (async () => {
            let result;

            if(isWish) {
                result = await boardService.deleteWishBoard({bidx: bidx});
            } else {
                result = await boardService.putWishBoard({bidx: bidx});
            }

            if(result.status == "SUCCESS" && result.data) {
                setIsWish(result.data.isWished);
                if(isWish) {
                    ToastUtils.show("관심 지식에서 해제되었습니다.", 2 )
                } else {
                    ToastUtils.show("관심 지식에 저장되었습니다.", 2 )
                }
            }

        }) ()
    }

    return (
        <section className="detail-section">
            <div className="inner">
                <div className="detail-title">
                    {/* top 가이드 메뉴 */}
                    <span className="breadcrumb">
                            <Link to={`/board/list/${boardKey}`}>{boardInfoVO?.boardName}</Link>
                            <a href="#" style={{cursor: "default"}} onClick={(e) => {
                                e.preventDefault()
                            }}>{boardVO.jobName}</a>
                        </span>

                    <h2 className="tit">{boardVO.title}</h2>
                    <button type="button" className={`btn-bookmark ${isWish ? 'active' : ''}`} onClick={setBookMark}>
                        <span className="blind">북마크</span>
                    </button>
                </div>

                <div className="detail-content">

                    {/* detail top */}
                    {boardVO.boardType === "mov" && <BoardMov fileList={fileList} boardVO={boardVO}/>}
                    {boardVO.boardType === "card" && <BoardCard fileList={fileList} boardVO={boardVO}/>}
                    {boardVO.boardType === "pdf" && <BoardCard fileList={fileList} boardVO={boardVO}/>}

                    <div className="detail-bottom">
                        <div className="tabs">
                            <ul className="tab-menu">
                                <li className={`tab-item explain ${currentTab == 0 ? 'active' : ''}`} style={{ cursor : 'pointer' }} onClick={() => setCurrentTab(0)}>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                    }}>강의 설명</a>
                                </li>
                                {
                                    !StringUtils.isEmpty(boardVO.aiHtml) && (
                                        <li className={`tab-item ai ${currentTab == 1 ? 'active' : ''}`} style={{ cursor : 'pointer' }} onClick={() => setCurrentTab(1)}>
                                            <a href="#" onClick={e => e.preventDefault()}>
                                                AI 요약 beta
                                            </a>
                                        </li>
                                    )
                                }

                                {
                                    !StringUtils.isEmpty(boardVO.scriptHtml) && (
                                        <li className={`tab-item script ${currentTab == 2 ? 'active' : ''}`} style={{ cursor : 'pointer' }} onClick={() => setCurrentTab(2)}>
                                            <a href="#" onClick={e => e.preventDefault()}>
                                                스크립트
                                            </a>
                                        </li>
                                    )
                                }

                            </ul>
                            {
                                currentTab == 0 &&
                                <div className="tab-panel tab-explain">
                                    <ul className="attach-list">
                                        {
                                            boardVO.attachList?.map((file, index) => (
                                                file.fileMode === 'attach' && file.fidx != boardVO.thumbIdx &&
                                                <li key={index} className="attach">
                                                    <b className="tit">첨부파일 </b>
                                                    <a href={`${APIs.BOARD_FILES_DOWN}${file.fidx}`}>
                                                        <span className="path">{file.filename}</span>
                                                    </a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    <div className="text-area" style={{
                                        marginTop: '0px'
                                    }}>
                                        <SummernoteIframe mode={"view"} htmlCode={boardVO.contentHtml}/>
                                    </div>
                                </div>
                            }
                            {
                                currentTab == 1 &&
                                <div className="tab-panel tab-ai">
                                    <div className="text-area">
                                        <SummernoteIframe mode={"view"} htmlCode={boardVO.aiHtml}/>
                                    </div>
                                </div>
                            }
                            {
                                currentTab == 2 &&
                                <div className="tab-panel tab-script">
                                    <div className="text-area">
                                        <SummernoteIframe mode={"view"} htmlCode={boardVO.scriptHtml}/>
                                    </div>
                                </div>
                            }
                        </div>

                        {
                        tags &&
                            <div className="tag-list">
                                {tags?.map((tag, index) => (
                                    <React.Fragment key={index}>
                                        <Link to={`/search/${tag.replace(/[#!@$%^&*()+={}[\]|\\:;"'<>,?\/]/g, '')}`}>#{tag}</Link>
                                        {index < tags.length - 1 ? ', ' : ''}
                                    </React.Fragment>
                                ))}
                            </div>
                        }

                    </div>
                </div>
            </div>
        </section>
    )
}

export default MovCardDetail;
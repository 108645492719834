import React, {useEffect, useState} from "react";
import {BoardVO} from "../../../../../core/board/board.vo";
import moment from "moment";
import ElandUtils from "../../../../../common/utils/eland.utils";
import MemberUtils from "../../../../../common/utils/member.utils";

interface ProHelpListProps {
    boardVO: BoardVO,
    clickEvent: (idx:number)=>void,
    isBest: boolean
}

const M_ProHelpListRow = (props:ProHelpListProps) => {
    const {clickEvent, isBest} = props
    const [boardVO, setBoardVO] = useState<BoardVO>(props.boardVO)

    useEffect(() => {
        const renderCode = async () =>{
            const board = {...boardVO}
            board.jobName = board.jobCode && await ElandUtils.getJobSkillCodeName(board.jobCode)
            setBoardVO(board)
        }
        renderCode()

    }, []);

    useEffect(() => {
        if (boardVO.writerRate) {
            console.log("boardVO.writerRate : ", boardVO.writerRate)
        }
    }, [boardVO]);

    const getCateBadge = (cateCode:string) => {
        switch (cateCode) {
            case "040401":
                return (<span className="badge type1 sm orange">도움요청 </span>)
            case "040402":
                return (<span className="badge type1 sm green">지식나눔 </span>)
            case "040403":
                return (<span className="badge type1 sm blue">자유주제 </span>)
            default:
                break;
        }
    }

    return (
        <li>
            <a href="#" onClick={(e) => {
                e.preventDefault()
                clickEvent(boardVO.idx)}
            }>
                <div className="cate">
                    {
                        isBest ? (
                            <span className="badge type1 sm red">BEST</span>
                        ) : (
                            boardVO.cateCode && getCateBadge(boardVO.cateCode)
                        )
                    }
                    <span>{boardVO.jobName}</span>
                </div>
                <div style={{display:"flex", alignItems:"center"}}>
                    <strong className="tit">{boardVO.title}
                    {
                        boardVO.commentCount > 0 &&
                        (<span className="comment" style={{color:"red" ,marginLeft: '4px'}}>({boardVO.commentCount})</span>)
                    }
                    </strong>
                </div>
                <div className="info-wrap">
                    <div>
                        <div className="writer">
                            {
                                (MemberUtils.isSemiProRateCode(boardVO.writerRate ?? "") ||
                                MemberUtils.isProRateCode(boardVO.writerRate ?? "")) && (
                                    <span className="badge type1 sm gray" style={{marginRight:'6px'}}>고수</span>
                                )
                            }
                            {boardVO.writer}
                        </div>
                    </div>
                    <div>
                        {
                            boardVO.isFile && (<span className="attach"></span>)
                        }
                        <span className="hits">{boardVO.views}</span>
                        <span className="like">{boardVO.likeCount}</span>
                        <span className="date">
                            {moment(boardVO.createDate).format("YYYY-MM-DD")}
                        </span>
                    </div>
                </div>
            </a>
        </li>
    )
}

export default M_ProHelpListRow


import React, {useEffect, useState, useCallback} from "react";
import {ReviewListItemVO} from "../../../core/review/review.vo";
import {CodeVO} from "../../../core/common/code.vo";
import CommonJS from "../../../common/common";
import ReviewService from "../../../core/review/review.service";
import {HWMap} from "../../../core/common/common.vo";
import {PageUtils} from "../../../common/utils/page.utils";
import StarRating from "./StarRating";
import {ReviewType} from "./ReviewType";
import MemberUtils from "../../../common/utils/member.utils";
import StringUtils from "../../../common/utils/string.utils";

interface AudienceReviewItemProps {
    itemVO: ReviewListItemVO
    type: ReviewType
    updateHandler: () => void;
}

// M_AudienceReview 아이템
const AudienceReviewItem = (props: AudienceReviewItemProps)=>{
    const { itemVO, type, updateHandler } = props;

    const [params, setParams] = useState<HWMap>(new PageUtils())
    const [isUpdate, setIsUpdate] = useState<Boolean>(false);

    // 한줄평 텍스트
    const [reviewText, setReviewText] = useState("");

    // 수정화면으로 변경
    const changeToUpdate = async () => {
        setIsUpdate(true);
    }

    // 수정
    const doUpdate = async () => {
        let btnSelectClass = document.getElementsByClassName("updateStar");
        let starClass = btnSelectClass[0].getElementsByClassName("star");
        let rating = starClass[0].getElementsByClassName("check").length;

        let _params = params
        _params.type = type;
        _params.bidx = itemVO.review?.bidx;
        _params.emIdx = itemVO.review?.emIdx;
        _params.targetId = itemVO.review?.targetId;
        _params.writer = MemberUtils.getSessionVO()?.coEmailId;
        _params.rating = rating;
        _params.title = reviewText;

        console.log("params : ", params);

        const result = await ReviewService.setReview(_params);
        if (result.status === 'SUCCESS') {
            setIsUpdate(false);
            updateHandler();
        }
    }

    // 삭제
    const doDelete = async () => {
        if (itemVO.review) {
            console.log("doDelete itemVO.review : ", itemVO.review)
            const result = await ReviewService.deleteReview(itemVO.review);
            if (result.status === 'SUCCESS') {
                updateHandler();
            }
        }
    }

    // 취소
    const doCancel = async () => {
        setIsUpdate(false);
    }

    // 수정화면으로 진입하면, 별점에 이벤트 등록
    useEffect(() => {
        CommonJS.select();
    }, [isUpdate]);

    // itemVO가 변경되면,
    useEffect(() => {
        if (itemVO.review) {
            setReviewText(itemVO.review.title);
        }
    }, [itemVO]);

    // 본인 확인
    function isAuthorize() {
        return MemberUtils.getSessionVO()?.coEmailId == itemVO.review?.writer
    }

    // 코드 목록에서 배지를 렌더링하는 함수
    function renderBadgesFromCodeVOList(codeVOList?:[CodeVO]) {
        if (!codeVOList) {
            return null;
        }

        return codeVOList.map((codeVO, index) => getBadgeTag(codeVO, index));
    }

    // 키워드 가져오기
    function getBadgeTag(codeVO:CodeVO, index:number) {
        // 뒤에서 두 번째 문자 추출
        const secondToLastChar = codeVO.code.charAt(codeVO.code.length - 2);

        // 숫자로 변환
        const number = parseInt(secondToLastChar, 10);
        let className = "badge type1 "

        if (number % 10 === 0 || number % 9 === 0) {
            className += "red"
        } else if (number % 8 === 0 || number % 7 === 0) {
            className += "blue"
        } else if (number % 6 === 0 || number % 5 === 0) {
            className += "orange"
        } else if (number % 4 === 0 || number % 3 === 0) {
            className += "green"
        } else {
            className += "purple"
        }

        return <span key={index} className={className}>{codeVO.codeName}</span>;
    }

    return (
        <li>
            {
                isUpdate ? (
                        <div className="comment-modify" style={{ padding: 0, marginTop: 0, borderTop: 0 }}>
                            <div className="comment-util">
                                <span className="name">{itemVO.companyAccount?.userName}</span>
                                <div className="button-area">
                                    <button type="button" className="btn gray" onClick={doCancel}>취소</button>
                                    <button type="button" className="btn blue" onClick={doUpdate}>수정</button>
                                </div>
                            </div>
                            <div className="comment-input">
                                <div className="select">
                                    <button type="button" className="btn-select updateStar">
                                        <div className="star">
                                            <StarRating totalStars={5} rating={ itemVO.review && itemVO.review.rating || 0 }/>
                                        </div>
                                    </button>
                                    <ul className="select-option">
                                        <li className="selected">
                                            <a href="#" onClick={e=>{e.preventDefault()}}>
                                                <div className="star">
                                                    <StarRating totalStars={5} rating={5}/>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" onClick={e=>{e.preventDefault()}}>
                                                <div className="star">
                                                    <StarRating totalStars={5} rating={4}/>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" onClick={e=>{e.preventDefault()}}>
                                                <div className="star">
                                                    <StarRating totalStars={5} rating={3}/>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" onClick={e=>{e.preventDefault()}}>
                                                <div className="star">
                                                    <StarRating totalStars={5} rating={2}/>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" onClick={e=>{e.preventDefault()}}>
                                                <div className="star">
                                                    <StarRating totalStars={5} rating={1}/>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <textarea name="" id="" style={{ width: '100%', height: '90px' }} className="inp-text" value={reviewText} onChange={(e) => setReviewText(e.target.value)} ></textarea>
                            </div>
                            <span className="date">{itemVO.review && ReviewService.changeDateFormat(itemVO.review.createDate)}</span>
                        </div>
                    ) :
                    (
                        <div className="comment-item">
                        <span className="name">{itemVO.companyAccount && itemVO.companyAccount.userName}</span>
                        <div className="star-rating">
                            <div className="star">
                                {
                                    itemVO.review ? (
                                        <StarRating totalStars={5} rating={ itemVO.review && itemVO.review.rating}/>
                                    ) : <></>
                                }
                            </div>
                            <b className="score">{itemVO.review && itemVO.review.rating}</b>
                        </div>
                        <p className="text-area" dangerouslySetInnerHTML={{__html: StringUtils.nl2br(itemVO.review?.title ?? "")}}></p>
                        <span className="date">{itemVO.review && ReviewService.changeDateFormat(itemVO.review.createDate)}</span>
                        <div className="util">
                            { isAuthorize() ? (
                                <>
                                    <a className="btn-modify" onClick={changeToUpdate}><span className="blind">수정하기</span></a>
                                    {/*<a className="btn-delete" onClick={doDelete}><span className="blind">삭제하기</span></a>*/}
                                </>
                            ) : (
                              <> </>
                            )}
                        </div>
                    </div>
                    )
            }

        </li>
    )
}

export default AudienceReviewItem
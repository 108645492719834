import React, {useEffect, useState} from "react";
import PointService from "../../../../core/point/point.service";
import {Link} from "react-router-dom";
import PointChart from "../../mypage/point/PointChart";
import NumberFormatter from "../../common/NumberFormatter";

const DashboardPoint: React.FC = () => {
    const [points, setPoints] = useState({
        coursePoint: 0,
        knowledgePoint: 0,
        proPoint: 0,
        etcPoint: 0,
        eduTotalPoint: 0,
        eventTotalPoint: 0,
        calculatePoint: 0,
    });

    const [calculatedPoints, setCalculatedPoints] = useState(0);

    useEffect(() => {

        (async() => {

            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth();
            const quarter = Math.floor(month / 3) + 1
            const months = [
                "1,2,3", // 1분기
                "4,5,6", // 2분기
                "7,8,9", // 3분기
                "10,11,12"  // 4분기
            ];

            const result = await PointService.getPointDetail4DashBoard({searchYear: year, searchMonth: months[quarter - 1]});

            if(result.status == "SUCCESS" && result.data) {
                const { category, pointType, calculatePoint } = result.data;

                const updatedPoints = { ...points, calculatePoint };

                category?.forEach((item: any) => {
                    switch (item.cateCode) {
                        case '300101':
                            updatedPoints.coursePoint = item.totalPoints;
                            break;
                        case '300102':
                            updatedPoints.knowledgePoint = item.totalPoints;
                            break;
                        case '300103':
                            updatedPoints.proPoint = item.totalPoints;
                            break;
                        case '300104':
                            updatedPoints.etcPoint = item.totalPoints;
                            break;
                    }
                });

                pointType?.forEach((item: any) => {
                    switch (item.pointType) {
                        case 'EVENT':
                            updatedPoints.eventTotalPoint = item.totalPoints;
                            break;
                        case 'EDU':
                            updatedPoints.eduTotalPoint = item.totalPoints;
                            break;
                        case 'CALCULATE':
                            updatedPoints.calculatePoint = item.totalPoints;
                            break;
                    }
                });

                setCalculatedPoints(calculatePoint);
                setPoints(updatedPoints);

            } else {
                console.log("point 호출 살패", result.msg);
            }
        })()
    }, []);

    const changeSign = (num:number) => {
        return num === 0 ? 0 : -num;
    }


    return (
        <div className="box-item box5">
            <div className="box-title">
                <h3 className="tit">내 포인트</h3>
                <Link to={"/mypage/point"} className="link-more">바로가기</Link>
            </div>
            <div className="box-content">
                <div className="point">
                    <span>현재</span>
                    <span><strong className="num"><NumberFormatter number={points.eduTotalPoint + points.eventTotalPoint + points.calculatePoint}/></strong> P</span>
                </div>

                {/* 포인트 차트 */}
                {
                    points &&
                    <PointChart
                        course={points.coursePoint}
                        knowledge={points.knowledgePoint}
                        pro={points.proPoint}
                        etc={points.etcPoint}
                    />
                }

                <ul className="unit-list">
                    <li>
                        <span className="txt red">코스학습</span>
                        <span className="num"><NumberFormatter number={points.coursePoint}/> P</span>
                    </li>
                    <li>
                        <span className="txt blue">지식학습</span>
                        <span className="num"><NumberFormatter number={points.knowledgePoint}/> P</span>
                    </li>
                    <li>
                        <span className="txt green">사내고수</span>
                        <span className="num"><NumberFormatter number={points.proPoint}/> P</span>
                    </li>
                    <li>
                        <span className="txt yellow">기타활동</span>
                        <span className="num"><NumberFormatter number={points.etcPoint}/> P</span>
                    </li>
                </ul>
                <p className="info-text">
                    지난 분기 전환 포인트
                    <span><NumberFormatter number={changeSign(calculatedPoints)}/> P</span>
                </p>
            </div>
        </div>
    )
}

export default DashboardPoint;
import React, {useEffect, useState} from "react";
import MypageService from "../../../../core/mypage/mypage.service";
import {BoardVO, DefaultBoardVO} from "../../../../core/board/board.vo";

const PrivacyBoard = () => {

    const [board, setBoard] = useState<BoardVO>(DefaultBoardVO())
    // useEffect(() => {
    //     const successHandler = (result:any) =>{
    //         result.data.list.length > 0 && setBoard(result.data.list[0])
    //     }
    //
    //     MypageService.privacyGet(successHandler)
    // }, []);

    let defaultHtml = `
        <strong class="tit-policy">[이랜드에듀 홈페이지 개인정보 처리방침]</strong>
        <div class="article-wrap">
            <p>
                이랜드에듀 (이하 ‘회사’라 함)는 '회사'의 서비스를 이용하시는 모든 이용자 및 정보주체(이하 '고객'이라 함)의 개인정보를 매우 소중히 여기며, 고객님의 개인정보가 보호받을 수 있도록 [개인정보처리방침]을 제정하고 이를 준수하고 있습니다. 또한 회사는「개인정보보호법」과 동 법률의 시행령에 따른 개인정보보호지침을 준수하고 있습니다.<br>
                회사는 개인정보처리방침을 통하여 고객이 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다. 회사의 개인정보처리방침은 관련 법률이나 회사의 내부 방침에 따라 변경될 수 있으므로, 본 홈페이지(www.elandedu.co.kr) 이 용 시에 수시로 확인하여 주시기 바랍니다.<br>
            </p>
            <br>
            <b>'회사'의 개인정보처리방침은 다음과 같은 내용을 담고 있습니다.</b>
            <br>
            <p>
                제 1조 (개인정보의 처리목적)<br>
                제 2조 (처리하는 개인정보의 항목 및 수집방법)<br>
                제 3조 (개인정보의 처리 및 보유기간)<br>
                제 4조 (개인정보의 제 3자 제공)<br>
                제 5조 (개인정보의 처리위탁)<br>
                제 6조 (개인정보의 파기 절차 및 방법)<br>
                제 7조 (개인정보의 안전성 확보조치)<br>
                제 8조 (고객 및 법정대리인의 권리ㆍ의무 및 그 행사 방법)<br>
                제 9조 (개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항)<br>
                제 10조 (개인정보 보호 책임자)<br>
                제 11조 (가명정보의 처리)<br>
                제 12조 (권익침해 구제방법)<br>
                제 13조 (고지의무)<br>
                제 14조 (개인정보 처리방침 변경)<br>
            </p>
        </div>
        <div class="article-wrap">
            <strong class="tit-subject">제 1조 (개인정보의 처리목적)</strong>
            <p>
                회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.<br>
                1. 마케팅 및 광고에 활용 (고객의 별도 동의가 있는 경우만 해당)<br>
                마케팅 등 광고성 정보 전달, 사은행사, 판촉행사, 광고물 발송, 회사가 제공하는 서비스 및 이벤트 등 정보제공, 회사가 진행하는 행사 및 제휴행사의 안내, 서비스 홍보, 텔레마케팅, DM, EM 등의 정보제공 및 활용<br>
                2. 법령에서 허용하는 범위 내에서의 추가적인 이용ㆍ제공<br>
                회사는 「개인정보 보호법」 제15조제3항 또는 제17조제4항에 따라, 당초 수집 목적과 합리적으로 관련된 범위에서 정보주체의 동의 없이 개인정보를 이용 또는 제공할 수 있습니다. 다만, 개인정보를 동의 없이 추가적으로 이용 또는 제공하는 경우에는 다음 각 호의 내용을 고려합니다. (1) 당초 수집 목적과의 관련성이 있는지 여부 (2) 개인정보 수집정황 또는 처리관행에 비추어 볼 때, 개인정보의 추가적인 이용 또는 제공에 대한 예측 가능성이 있는지 여부 (3) 정보주체의 이익이 부당하게 침해되는지 여부 (4) 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부<br>
            </p>
        </div>
        <div class="article-wrap">
            <strong class="tit-subject">제 2조 (처리하는 개인정보 항목 및 수집 방법)</strong>
            <p>
                ① 본 사이트에서는 회원가입을 위해 고객 정보를 수집하지 않습니다.<br>
                다만, 문의 사항이나, 특별한 프로모션을 진행하는 경우, 다음의 개인정보 항목을 수집합니다.
            </p>
            <div class="table-wrap">
                <table>
                    <caption>처리하는 개인정보 항목 및 수집 방법</caption>
                    <colgroup>
                        <col style="width: 33.333%">
                        <col style="width: 33.333%">
                        <col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>처리목적</th>
                            <th>필수항목</th>
                            <th>선택항목</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>문의 사항 접수 및 답변</td>
                            <td>이메일 주소, 문의 내용<br>(문의 유형, 제목, 내용)</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>마케팅 및 프로모션</td>
                            <td>성명, 휴대폰 번호</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br>
            <p>
                ② 회사는 고객의 기본적 인권을 현저하게 침해할 우려가 있는 민감정보(사상, 신념, 노동조합·정당의 가입·탈퇴, 정치적 견해, 건강, 범죄기록, 건강상태 및 성생활 등)는 수집하지 않습니다.
            </p>
        </div>
        <div class="article-wrap">
            <strong class="tit-subject">제 3조 (개인정보의 처리 및 보유기간)</strong>
            <p>
                ① 회사는 법령에 따른 개인정보 보유·이용기간 또는 고객으로부터 개인정보 수집 시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.<br>
                ② 특별한 프로모션 등을 진행하기 위해 수집한 개인정보는 프로모션 진행 목적 달성 후, 안전하게 파기합니다.
            </p>
        </div>
        <div class="article-wrap">
            <strong class="tit-subject">제 4조 (개인정보의 제3자 제공)</strong>
            <p>
                회사는 고객의 개인정보를 제 1조(개인정보의 처리목적)에서 명시한 범위 내에서만 처리하며, 원칙적으로 제3자 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.<br>
                1. 고객님이 사전에 제 3자 제공에 동의한 경우<br>
                ※ 단, 동의를 거부하시는 경우에도 서비스는 이용하실 수 있습니다.<br>
                2. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우<br>
                ※ 그러나 예외사항에서도 관계법령에 의하거나 수사기관의 요청에 의해 정보를 제공하는 경우, 본래의 수집목적 및 이용목적에 반하여 무분별하게 정보가 제공되지 않도록 최대한 노력하겠습니다.<br>
                3. 통계작성, 과학적 연구, 공익적기록 보존 등을 위하여 특정 개인을 알아볼 수 없는 형태로 처리하여 제공하는 경우
            </p>
        </div>
        <div class="article-wrap">
            <strong class="tit-subject">제5조 (개인정보 처리위탁)</strong>
            <p>
                ① 회사는 원활한 서비스 제공 및 서비스 품질의 향상을 위해서 고객의 개인정보를 필요한 업무 범위 내에서 다음과 같이 타 업체에 수집, 보관, 처리 등을 위탁하여 처리하고 있습니다.
            </p>
            <div class="table-wrap">
                <table>
                    <caption>개인정보 처리위탁</caption>
                    <thead>
                        <tr>
                            <th>위탁 받는 자 (수탁자)</th>
                            <th>위탁 업무 내용</th>
                            <th>보유 및 이용기간</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>(주) 이랜드이노플</td>
                            <td>홈페이지 구축 및 유지</td>
                            <td>위탁 목적 달성 시까지</td>
                        </tr>
                        <tr>
                            <td>(주) 휴먼웨어즈</td>
                            <td>홈페이지 구축 및 유지</td>
                            <td>위탁 목적 달성 시까지</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br>
            <p>
                ② 회사는 위탁계약 체결 시 개인정보 보호법 제26조에 따라 위탁업무 수행목적 외 개인정보의 처리금지, 기술적ㆍ관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리ㆍ감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.<br>
                ③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 ‘개인정보처리방침’을 통하여 공개하도록 하겠습니다.
            </p>
        </div>
        <div class="article-wrap">
            <strong class="tit-subject">제6조 (개인정보의 파기 절차 및 방법)</strong>
            <p>
                ① 회사는 개인정보 보유기간의 경과, 처리목적의 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.<br>
                ② 고객으로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고, 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.<br>
                <br>
                1. 파기절차<br>
                회사는 파기 사유가 발생한 개인정보를 선정하고 회사의 개인정보보호 책임자의 승인을 받아 개인정보를 파기합니다. 다만, 파기와 관련하여 사전에 승인받은 내부지침이 있는 경우, 해당 지침에 따릅니다.<br>
                2. 파기방법<br>
                회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
            </p>
        </div>
        <div class="article-wrap">
            <strong class="tit-subject">제7조 (개인정보의 안전성 확보조치)</strong>
            <p>
                회사는 고객의 개인정보를 처리하는 경우, 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적·관리적 대책을 강구합니다.<br>
                ① 개인정보 암호화(기술적 조치)<br>
                고객의 중요 개인정보는 암호화하여 저장·관리하고, 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 합니다.<br>
                ② 해킹 등에 대비한 대책(기술적 조치)<br>
                회사는 해킹이나 악성코드 등에 의해 고객의 개인정보가 유출되는 것을 방지하기 위해 외부로부터의 침입을 차단하는 장치를 이용하고, 각 서버마다 침입 탐지시스템을 설치하여 24시간 침입을 감시하고 있습니다. 또한, 최신 백신프로그램을 이용하여 고객의 개인정보나 자료가 유출되거나 손상되지 않도록 방지하고 기타 시스템적으로 보안성을 확보하기 위해 취약점 진단 및 조치를 실시하여 가능한 모든 기술적 보호대책을 갖추려고 노력하고 있습니다.<br>
                ③ 개인정보 취급자의 최소화 및 교육실시(관리적 조치)<br>
                개인정보를 처리하는 임직원을 최소화하며, 개인정보 유출에 대한 위험을 줄입니다. 또한 내부관리계획을 수립하여 개인정보를 보관하는 데이터베이스 시스템과 개인정보를 처리하는 시스템에 대한 비밀번호의 생성과 변경, 그리고 접근할 수 있는 권한에 대한 체계적인 관리와 감사를 실시합니다. 또한 개인정보를 처리하는 모든 임직원들을 대상으로 개인정보보호 의무와 보안에 대한 정기적인 교육과 캠페인을 실시하고 있습니다.<br>
                ④ 물리적 조치<br>
                고객의 개인정보가 보관되는 전산실, 자료보관실 등은 시건 장치하여 접근을 통제합니다.
            </p>
        </div>
        <div class="article-wrap">
            <strong class="tit-subject">제8조 (고객 및 법정대리인의 권리·의무 및 그 행사 방법)</strong>
            <p>
                ① 고객은 회사에 대해 언제든지 개인정보 열람·정정·수정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.<br>
                ② 제1항에 따른 권리 행사는 본 사이트에 입력하신 경우에는 해당 화면에서 자신이 직접 행사하실 수 있습니다.<br>
                ③ 제1항에 따른 권리 행사는 제10조(개인정보 보호책임자)에 기재된 담당부서로 서면, 전화, 전자우편, 인터넷 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체없이 조치하겠습니다.<br>
                ④ 제1항에 따른 권리 행사는 고객의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 [개인정보 처리방법에 관한 고시(제2020-7호)]별지 제11호 서식에 따른 위임장을 제출하여야 합니다.<br>
                ⑤ 고객의 개인정보 ‘열람’ 요구는 개인정보 보호법 제35조 제4항에 따라 제한되거나 거절될 수 있으며, ‘처리정지’ 요구는 개인정보 보호법 제37조 제2항의 단서 조항에 의하여 제한될 수 있습니다.<br>
                ⑥ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 정정ㆍ삭제를 요구할 수 없습니다.<br>
                ⑦ 회사는 고객의 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
            </p>
        </div>
        <div class="article-wrap">
            <strong class="tit-subject">제9조 (개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항)</strong>
            <p>
                ① 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다. 쿠키는 회사의 사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저로 전송하는 소량의 정보이며 이용자의 PC컴퓨터내의 하드디스크에 저장되기도 합니다. 그러나PC의 다른 부분에는 전혀 영향을 주지 않습니다.<br>
                ② 쿠키 사용 목적 : 고객이 회사 관련 사이트에 접속을 하면 회사의 서버가 이용자의 컴퓨터 브라우저에서 쿠키 내용을 읽음으로써 추가 입력 없이 서비스를 제공받으실 수 있도록 하기 위해 사용됩니다. 또한 방문 및 이용형태, 인기 검색어, 보안접속 여부 등 이용자의 취향과 관심분야를 파악하여 이용자에게 최적화된 정보를 제공하기 위해 사용됩니다.<br>
                ③ 쿠키에 대한 선택권 : 웹 브라우저 옵션에서 모든 쿠키를 다 받아들이거나, 쿠키가 설치될 때 통지를 보내도록 하거나, 아니면 모든 쿠키를 거부할 수 있는 선택권을 가지실 수 있습니다.<br>
                ④ 쿠키의 설정방법 (인터넷 익스플로러의 경우) : 웹 브라우저 상단의 도구&gt;인터넷 옵션&gt;개인정보 메뉴를 클릭하여 [개인정보보호 수준]을 설정하시면 됩니다.<br>
                ⑤ 모바일 서비스에서의 쿠키 설치/운영에 관한 사항 회사는 PC환경과 동일ㆍ유사한 인터넷 환경을 모바일 서비스에서도 제공하기 위해 모바일 기기(예: 스마트폰, 태블릿PC)에서도 ‘쿠키(cookie)’를 사용할 수 있습니다. 모바일 기기에서도 웹 브라우저 설정 기능에서 쿠키 허용 여부를 선택할 수 있습니다. 모바일 기기의 운영체제 및 웹 브라우저 종류에 따라 다소 상이할 수 있지만, 대부분의 경우 모바일 웹 브라우저의 환경 설정을 통해 쿠키허용 여부를 결정하거나, 기존의 쿠키 일체를 삭제할 수 있습니다.<br>
                ⑥ 쿠키의 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 있을 수 있습니다.
            </p>
        </div>
        <div class="article-wrap">
            <strong class="tit-subject">제10조 (개인정보 보호책임자)</strong>
            <p>
                ① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 고객의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
            </p>
            <div class="table-wrap">
                <table>
                    <caption>개인정보 보호책임자</caption>
                    <thead>
                        <tr>
                            <th>구분</th>
                            <th>책임자</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>성명</td>
                            <td>최보배</td>
                        </tr>
                        <tr>
                            <td>직책</td>
                            <td>개인정보 보호 책임자</td>
                        </tr>
                        <tr>
                            <td>전자우편</td>
                            <td>eland_hrd@eland.co.kr</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br>
            <p>
                ② 고객은 회사의 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제, 열람청구 등에 관한 사항을 개인정보 보호책임자에게 문의하실 수 있습니다. 회사는 고객의 문의에 대해 지체 없이 답변 및 처리해 드릴 것입니다.
            </p>
        </div>
        <div class="article-wrap">
            <strong class="tit-subject">제11조 (가명정보의 처리)</strong>
            <p>
                회사는 통계작성, 과학적 연구, 공익적 기록보존 등을 위하여 수집한 개인정보를 특정 개인을 알아볼 수 없도록 가명 처리하여 다음과 같이 처리하고 있습니다.
            </p>
            <div class="table-wrap">
                <table>
                    <caption>가명정보의 처리</caption>
                    <thead>
                        <tr>
                            <th>구분</th>
                            <th>처리목적</th>
                            <th>처리항목</th>
                            <th>보유 및 이용기간</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>상품 및 서비스 개발과 마케팅 연구</td>
                            <td>서비스 이용 분석을 통한 상품, 서비스 및 이벤트 개발</td>
                            <td>웹사이트 방문이력, 검색이력</td>
                            <td>처리목적 달성 시까지</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br>
            <p>
                가명정보란 추가적인 정보의 사용ㆍ결합 없이는 특정 개인을 알아볼 수 없는 정보로서 회사는 정보주체의 개인정보를 보호하기 위하여 가명정보를 사용하며 가명정보 사용 시, 「개인정보 보호법」 제28조의2(가명정보의 처리 등), 제28조의3(가명정보의 결합 제한), 제28조의4(가명정보에 대한 안전조치의무 등), 제28조의5(가명정보 처리시 금지의무 등) 등 관련 법령을 준수합니다.
            </p>
        </div>
        <div class="article-wrap">
            <strong class="tit-subject">제12조 (권익침해 구제방법)</strong>
            <p>
                고객은 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.<br>
                <br>
                &lt;아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.&gt;
            </p>
            <div class="table-wrap">
                <table>
                    <caption>권익침해 구제방법</caption>
                    <thead>
                        <tr>
                            <th>처리목적</th>
                            <th>필수항목</th>
                            <th>선택항목</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>개인정보분쟁조정위원회</td>
                            <td>1833-6972</td>
                            <td><a href="http://www.kopico.go.kr" target="_blank">www.kopico.go.kr</a></td>
                        </tr>
                        <tr>
                            <td>개인정보침해신고센터</td>
                            <td>(국번 없이)118</td>
                            <td><a href="https://privacy.kisa.or.kr/" target="_blank">https://privacy.kisa.or.kr</a></td>
                        </tr>
                        <tr>
                            <td>대검찰청 사이버수사과</td>
                            <td>(국번 없이)1301</td>
                            <td><a href="http://www.spo.go.kr">www.spo.go.kr</a></td>
                        </tr>
                        <tr>
                            <td>경찰청 사이버수사국</td>
                            <td>(국번 없이)182</td>
                            <td><a href="https://ecrm.police.go.kr">https://ecrm.police.go.kr</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="article-wrap">
            <strong class="tit-subject">제13조 (고지의무)</strong>
            <p>
                이 개인정보 처리방침은 2024년 06월 30일에 제정되었으며 정부의 정책, 정보보호 유관법령 또는 보안기술의 변경 및 서비스의 변경사항에 따라 내용의 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 홈페이지의 ‘공지사항’을 통해 고지할 것입니다.<br>
                다만, 개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자의 권리와 관련된 중요한 변경이 있을 경우에는 개정 최소 30일 전부터 홈페이지 or APP 또는 이메일을 통해 고지하겠습니다.<br>
                개인정보 처리방침 공고일자 : 2024-06-30<br>
                개인정보 처리방침 시행일자 : 2024-06-30
            </p>
        </div>
        <div class="article-wrap">
            <strong class="tit-subject">제14조 (개인정보 처리방침 변경)</strong>
            <p>
                이 개인정보 처리방침은 2024년 06월 30일부터 적용됩니다.
            </p>
        </div>   
    `


    return (
        <main className="content privacy">
            <div className="common-title">
                <div className="inner">
                    <h2 className="tit">개인정보 처리방침</h2>
                </div>
            </div>
            <section className="policy-section">
                <div className="inner" dangerouslySetInnerHTML={{__html:board.contentHtml ?? defaultHtml}}>
                </div>
            </section>
        </main>
    )
}

export default PrivacyBoard
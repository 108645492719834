import React, {useEffect, useState} from "react";
import {BoardVO, CommentFileVO, CommentVO} from "../../../../../../core/board/board.vo";
import M_ProEduTalkCommentRow from "../common/M_ProEduTalkCommentRow";
import M_ProEduTalkCommentInput from "../common/M_ProEduTalkCommentInput";
import moment from "moment/moment";
import CommonJS from "../../../../../../common/common";
import {useParams} from "react-router-dom";
import M_ProEduTalkReview from "../common/M_ProEduTalkReview";
import MemberUtils from "../../../../../../common/utils/member.utils";
import FileUtils from "../../../../../../common/utils/file.utils";
import APIs from "../../../../../../common/apis/APIs";
import StringUtils from "../../../../../../common/utils/string.utils";
import M_BottomButton from "../../../../common/M_BottomButton";
import {DefaultReviewVO, ReviewVO} from "../../../../../../core/review/ReviewVO";
import {HWMap} from "../../../../../../core/common/common.vo";
import {PageUtils} from "../../../../../../common/utils/page.utils";
import {M_ReviewType} from "../../../../review/M_ReviewType";
import ReviewService from "../../../../../../core/review/review.service";
import ToastUtils from "../../../../../../common/utils/toast.utils";

interface ProEduTalkWriterReplyProps {
    statusCode: string,
    boardVO: BoardVO,
    reply: CommentVO,
    commentList: CommentVO[],
    updateEvent: () => void,
    receiverKey: string,
    changeFeedback: () => void
}

const M_ProEduTalkWriterReply = (props: ProEduTalkWriterReplyProps) => {
    const {bidx} = useParams()
    const {receiverKey, reply, commentList,updateEvent} = props
    const [reviewVO, setReviewVO] = useState<ReviewVO>(DefaultReviewVO())
    const [statusCode, setStatusCode] = useState<string>(props.statusCode)

    // 버튼 클릭시 모드
    const [isAddMode, setIsAddMode] = useState<boolean>(false)

    useEffect(() => {
        setTimeout(()=>CommonJS.accordion())
        getReviewList()
    }, []);


    useEffect(() => {
        console.log("boardVO : ", props.boardVO)
        setStatusCode(props.statusCode)
    }, [props]);

    const getReviewList = async () => {
        const targetId = receiverKey
        if (bidx && targetId) {
            let params: HWMap = {...new PageUtils()}
            params.bidx = bidx
            params.targetId = targetId;
            params.type = M_ReviewType.EDU_TALK;

            const result = await ReviewService.getReviewList(params);
            if (result.status === 'SUCCESS') {
                if (result.list.length > 0){
                    setReviewVO(result.list[0].review)
                }
            }
        }
    }

    const isOnly = () => {
        return commentList.filter(value =>
            value.writerKey === MemberUtils.getLoginId() || value.writerKey === receiverKey).length <= 0
    }
    const isAllReply = () => {
        return commentList.filter(value =>
            value.writerKey === MemberUtils.getLoginId() || value.writerKey === receiverKey).length >= 2
    }

    const isCompleteStatus = () => ["041904", "041903"].includes(statusCode);

    // 7일이 지났는지 확인하는 함수
    const isDateExceeded = (createDateStr: Date) => {
        const currentDate = new Date(); // 현재 날짜

        // currentDate와 createDate의 시각 정보를 제외한 일자 비교
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth(); // 0부터 시작
        const currentDay = currentDate.getDate();

        // 문자열을 Date 객체로 변환
        const createDate = new Date(createDateStr);

        // 유효하지 않은 날짜일 경우 false 반환
        if (isNaN(createDate.getTime())) {
            console.error("Invalid date format:", createDateStr);
            return false;
        }

        const createYear = createDate.getFullYear();
        const createMonth = createDate.getMonth();
        const createDay = createDate.getDate();

        // 두 날짜를 일자만 기준으로 다시 생성
        const normalizedCurrentDate = new Date(currentYear, currentMonth, currentDay);
        const normalizedCreateDate = new Date(createYear, createMonth, createDay);

        // 두 날짜의 차이를 밀리초로 계산한 후 일(day)로 변환
        const diffTime = normalizedCurrentDate.getTime() - normalizedCreateDate.getTime();
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); // 밀리초를 일(day)로 변환

        return diffDays > 7; // 7일이 지났다면 true 반환
    };

    const isComplete = isCompleteStatus();

    const onChangeMode = (e:any, isAdd: boolean) => {
        e.preventDefault()
        setIsAddMode(isAdd)
    }

    const getReviewArea = () => {
        return isComplete && <M_ProEduTalkReview bidx={bidx ?? "0"} targetId={receiverKey} isReceiver={false}/>
    }

    const getAccordionClass = () => {
        if (isComplete){
            CommonJS.accordion()
            return "accordion border-10 active"
        }

        return "accordion-none active"
    }

    const downloadFile = async (e: any, fileVO: CommentFileVO) => {
        e.preventDefault()
        window.location.href = `${APIs.BOARD_COMMENTS_FILES_DOWN}${fileVO.cfidx}`
    }


    return (
        <>
            <section className="reply-section">
                <div className="inner">
                    <div className={getAccordionClass()}>
                        <h3 className="tit-accordion">답변</h3>
                        <div className="accordion-content">
                            <div className="reply">
                                <div className="reply-util">
                                    <div className="writer">
                                        <div className="image-wrap">
                                            <img
                                                src={FileUtils.getFileUrl(reply?.profileSrc ?? "")}
                                                onError={FileUtils.onErrorImg}
                                                alt="프로필 이미지" className="img-profile"/>
                                        </div>
                                        <div className="text-wrap">
                                            <div className="name">
                                                <b>{reply.writer}</b>
                                                <span>{MemberUtils.getProStr(reply.writerRate ?? "")}</span>
                                            </div>
                                            <span className="job">{reply.coName}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="reply-content">
                                    <ul className="attach-list">
                                        {
                                            reply.commentFileList && reply.commentFileList?.map((value, index) => {
                                                return (
                                                    <li key={index}>
                                                        <a href="#"
                                                           onClick={e => downloadFile(e, value)}><span
                                                            className="path">{value.filename}</span></a>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    <div className="text-area"
                                         dangerouslySetInnerHTML={{__html: StringUtils.nl2br(reply.contentText ?? "")}}></div>
                                    <span
                                        className="date">{moment(reply.updateDate).format("YYYY.MM.DD")}</span>
                                </div>
                                {
                                    (!isComplete && !isAddMode && isOnly()) &&
                                    (
                                        <>
                                            <div className="button-area">
                                                <a href="#"
                                                   className={isAddMode ? "btn-add active" : "btn-add"}
                                                   onClick={e => onChangeMode(e, true)}>
                                                    추가로 질문할래요
                                                </a>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                            <div className="comment type2">
                                {
                                    (isAddMode && isOnly() && !isComplete) &&
                                    <M_ProEduTalkCommentInput comment={null}
                                                              updateEvent={updateEvent}
                                                              enableTemp={false}
                                                              isAddition={true}/>
                                }
                                <ul className="comment-list">
                                    {
                                        commentList.map((value, index) => {
                                            return <M_ProEduTalkCommentRow key={index}
                                                                           comment={value}
                                                                           updateEvent={updateEvent}
                                                                           isEditable={!isAllReply() && !isComplete}/>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {getReviewArea()}
            </section>
            {
                (!isComplete || isDateExceeded(props.boardVO.createDate)) && reviewVO.ridx == 0  && (
                    <M_BottomButton name={"문의가 해결되었어요"} onClick={() => {
                        props.changeFeedback()
                    }}/>
                )
            }
        </>
    )
}

export default M_ProEduTalkWriterReply
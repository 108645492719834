import axiosCaller from "../../common/utils/axios.caller";
import APIs from "../../common/apis/APIs";
import {useNavigate} from "react-router-dom";
import SwalUtils from "../../common/utils/swal.utils";
import ToastUtils from "../../common/utils/toast.utils";

class JobSkillService {

    static failHandler = (result:any) =>{
        if (result.status){
            if (result.status === "FAIL"){
                ToastUtils.show(result.msg)

            } else if (result.status === "ERROR"){
                ToastUtils.show(result.msg)
                console.log(result.data)

            } else if (result.status === "UNAUTHORIZATION"){
                ToastUtils.show(result.msg)

                // 로그인 페이지 이동
                const navigate = useNavigate()
                navigate("/login")

            }

        } else {
            ToastUtils.show("네트워크 오류가 발생했습니다. 잠시 후 시도해주세요.")

        }

    }

    static jobSkillPollGet = async (jobCode: string, success:(result:any)=>void, failure?:((result:any)=>void)) => {
        try {
            const result = await axiosCaller.get(APIs.JOB_SKILL + "/" + jobCode);

            if (result.status === 'SUCCESS') {
                success(result)

            } else {
                if (failure){
                    failure(result)

                } else {
                    JobSkillService.failHandler(result)

                }
            }
        } catch (error) {
            JobSkillService.failHandler(error)
        }
    };

    static jobSkillSubmit = async (params:any, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.post(APIs.JOB_SKILL_SUBMIT, params);

            if (result.status === 'SUCCESS') {
                success(result);

            } else {
                JobSkillService.failHandler(result);

            }
        } catch (error) {
            JobSkillService.failHandler(error);
        }
    };


    static jobSkillResultGet = async (success:(result:any)=>void, failure?:(result:any)=>void) => {
        try {
            const result = await axiosCaller.get(APIs.JOB_SKILL_RESULT);

            if (result.status === 'SUCCESS') {
                success(result);

            } else {
                if (failure){
                    failure(result);

                } else {
                    JobSkillService.failHandler(result);

                }
            }
        } catch (error) {
            JobSkillService.failHandler(error);
        }
    };


    static jobSkillAIMentoringGet = async (params:{jobName:string, strengths:string, weaknesses:string},
                                           success:(result:any)=>void,
                                           failure?:(result:any)=>void,
                                           errors?:(error:any)=>void) => {
        try {
            const result = await axiosCaller.getHide(APIs.JOB_SKILL_RESULT + '/mentoring', params);

            if (result.status === 'SUCCESS') {
                success(result);

            } else {
                if (failure){
                    failure(result);
                } else {
                    JobSkillService.failHandler(result);
                }
            }
        } catch (error) {
            if (errors){
              errors(error)
            } else {
                JobSkillService.failHandler(error);
            }

        }
    };


    static jobSkillRecommendContentGet = async (params:{boardKey:string, size:number}, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.get(APIs.JOB_SKILL_RECOMMEND, params);

            if (result.status === 'SUCCESS') {
                success(result);

            } else {
                JobSkillService.failHandler(result);

            }
        } catch (error) {
            JobSkillService.failHandler(error);
        }
    };



}

export default JobSkillService
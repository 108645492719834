import React, {useEffect, useState, useCallback} from "react";
import Pagination from "../common/Pagination";
import {HWMap} from "../../../core/common/common.vo";
import {PageUtils} from "../../../common/utils/page.utils";
import {ReviewListItemVO} from "../../../core/review/review.vo";
import ReviewService from "../../../core/review/review.service";
import AudienceReviewItem from "./AudienceReviewItem";
import CommonJS from "../../../common/common";
import StarRating from "./StarRating";
import {ReviewType} from "./ReviewType";
import PointService from "../../../core/point/point.service";
import MemberUtils from "../../../common/utils/member.utils";

interface AudienceReviewProps {
    bidx: number
    emIdx: number
    type: ReviewType
}

/*
    일반 한줄평
    http://khane7.test-site.kr/elandedu/html/2_classroom/2-3_%EC%BD%94%EC%8A%A4%ED%95%99%EC%8A%B5_%EC%83%81%EC%84%B8_%EC%98%81%EC%83%81.html
*/
const AudienceReview = (props: AudienceReviewProps) => {
    const {emIdx, bidx, type} = props;
    const [params, setParams] = useState<HWMap>(new PageUtils())
    const [totalCount, setTotalCount] = useState<number>(0)
    const [rating, setRating] = useState<number>(0)
    const [reviewList, setReviewList] = useState<ReviewListItemVO[]>([])

    // 한줄평 텍스트
    const [reviewText, setReviewText] = useState("");

    // 한줄평 작성 가능 여부
    const [isDisabled, setIsDisabled] = useState(false);

    // 리뷰 불러오기
    const getReviewList = async () => {
        if (type && (emIdx > 0 || bidx > 0)) {
            console.log("getReviewList 요청 완료")
            const result = await ReviewService.getReviewList(params);
            console.log("getReviewList 반환 완료 : ", result)
            if (result.status === 'SUCCESS') {
                setTotalCount(result.count);
                setRating(Math.round(result.rating * 10) / 10)
                setReviewList(result.list);
                setIsDisabled(result.isExistMyReview);
            }
        }
    }

    // 페이지 누를 때 업데이트하는 함수
    const updatePages = (page: number) => {
        setParams((params) => ({
            ...params,
            page: page
        }));
    };


    // 한줄평 등록
    const register = async () => {
        let btnSelectClass = document.getElementsByClassName("btn-select");
        let starClass = btnSelectClass[0].getElementsByClassName("star");
        let rating = starClass[0].getElementsByClassName("check").length;

        let _params = params
        switch (type) {
            case ReviewType.BOARD:
                _params.bidx = bidx;
                _params.type = ReviewType.BOARD;
                break;
            case ReviewType.EDUCATION:
                _params.emIdx = emIdx;
                _params.type = ReviewType.EDUCATION;
                break;
        }
        _params.writer = MemberUtils.getSessionVO()?.coEmailId;
        _params.rating = rating;
        _params.title = reviewText;

        const result = await ReviewService.setReview(_params);
        if (result.status === 'SUCCESS') {
            // 포인트 적립
            switch (type) {
                case ReviewType.BOARD:
                    await PointService.savePoint("COMMENT_POST", bidx)
                    break;
                case ReviewType.EDUCATION:
                    await PointService.savePoint("COURSE_COMMENT", emIdx)
                    break;
            }

            updatePages(0)
            setReviewText("");
            getReviewList();
        }
    }

    const updateParams = () => {
        let _params = params
        switch (type) {
            case ReviewType.BOARD:
                _params.bidx = bidx;
                _params.type = ReviewType.BOARD;
                break;
            case ReviewType.EDUCATION:
                _params.emIdx = emIdx;
                _params.type = ReviewType.EDUCATION;
                break;
        }
        setParams(_params)
    }
    // 초기화
    useEffect(() => {
        updateParams()
        CommonJS.select4Review()
    }, []);

    useEffect(() => {
        if (type && (emIdx > 0 || bidx > 0)) {
            updateParams()
            getReviewList()
        }
        console.log("파람 확인 전용 : ", emIdx)
        console.log("파람 확인 전용 : ", type)
    }, [params, emIdx, bidx]);

    return (
        <section className="comment-section">
            <div className="inner">
                <div className="comment type1">
                    <div className="comment-title">
                        <h3 className="tit">한줄평</h3>
                        <span className="num">총 {totalCount}개</span>
                    </div>
                    <div className="comment-rating">
                        <div className="star-rating">
                            <span className="txt">평점</span>
                            <div className="star">
                                <StarRating totalStars={5} rating={rating} />
                            </div>
                            <b className="score">{rating}</b>
                        </div>
                        <div className="guide-text">한줄평 작성시 <span>100P</span> 가 지급됩니다</div>
                    </div>
                    <div className="comment-input">
                        <div className="select">
                            <button type="button" className="btn-select" disabled={isDisabled}>
                                <div className="star">
                                    <StarRating totalStars={5} rating={isDisabled ? 0 : 5} />
                                </div>
                            </button>
                            <ul className="select-option">
                                <li className="selected">
                                    <a href="" onClick={event => event.preventDefault()}>
                                        <div className="star">
                                            <StarRating totalStars={5} rating={5} />
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="" onClick={event => event.preventDefault()}>
                                        <div className="star">
                                            <StarRating totalStars={5} rating={4} />
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="" onClick={event => event.preventDefault()}>
                                        <div className="star">
                                            <StarRating totalStars={5} rating={3} />
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="" onClick={event => event.preventDefault()}>
                                        <div className="star">
                                            <StarRating totalStars={5} rating={2} />
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="" onClick={event => event.preventDefault()}>
                                        <div className="star">
                                            <StarRating totalStars={5} rating={1} />
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <textarea
                               className="inp-text inp-upload"
                               rows={1}
                               style={{resize:'none'}}
                               placeholder="한줄평을 입력해주세요"
                               value={reviewText}
                               onChange={(e) => setReviewText(e.target.value)}
                               onKeyDown={e =>{
                                   if (e.key === "Enter" && !e.nativeEvent.isComposing) {
                                       if (e.shiftKey) {

                                       } else {
                                           e.preventDefault()
                                           register()
                                       }
                                   }
                               }}
                               disabled={isDisabled} />
                        <button type="button" className="btn red" onClick={register} disabled={isDisabled}>등록</button>
                    </div>

                    {/* 리스트 없을 때 */}
                    {reviewList.length === 0 ? (
                        <div className="comment-empty">
                            <p>
                                아직 작성된 한줄평이 없습니다.<br/>
                                한줄평 작성하고 100포인트 받아가세요~
                            </p>
                        </div>
                    ) : (
                        <>
                            <ul className="comment-list">
                                {reviewList.map((review, index) => (
                                    <AudienceReviewItem key={index} itemVO={review} type={type} updateHandler={ getReviewList }></AudienceReviewItem>
                                ))}
                            </ul>

                            <Pagination page={params.page} totalCount={totalCount} updatePages={updatePages}/>
                        </>
                    )}


                </div>
            </div>
        </section>
    );
}

export default AudienceReview;
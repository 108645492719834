import React, {useEffect, useState} from "react";
import {BoardVO} from "../../../../core/board/board.vo";
import BoardService from "../../../../core/board/board.service";
import APIs from "../../../../common/apis/APIs";
import {useNavigate} from "react-router-dom";
import FileUtils from "../../../../common/utils/file.utils";

const RecentBoard: React.FC = () => {

    const boardService = new BoardService();
    const [board, setBoard] = useState<BoardVO>();
    const navigate = useNavigate();
    const [randomJobImg, setRandomJobImg] = useState<string>("");

    useEffect(() => {
        if (board && board.boardKey === 'job_data') {
            setRandomJobImg(FileUtils.getRandomJobDataImg(board));
        }
    }, [board?.boardKey]);

    useEffect(() => {
        (async () => {
            const result = await boardService.getRecentViewList({page: 0, size: 1, sort: "createDate,DESC", boardKeys: APIs.KNOWLEDGE_BOARDKEY});
            if(result.status == "SUCCESS" && result.data && result.data.content) {
                setBoard(result.data.content[0])
            }

        })()
    }, []);

    return (
        <div className="box-item box3">
            <div className="box-title">
                <h3 className="tit">최근 본 지식</h3>
                <a href="#" className="link-more" onClick={()=>navigate('/education/storagebox')}>전체보기</a>
            </div>

            {
                board
                    ?
                    <div className="box-content">
                        <img
                            src={ board.boardKey == 'job_data' ? randomJobImg : FileUtils.getImageSrc(board.thumbFile) }
                            alt=""
                            onError={FileUtils.onErrorBoardImg}
                            style={{width:"250px", height:"180px", objectFit:'cover'}}
                            className="img-thumb"/>
                        <strong>{board.title}</strong>
                    </div>
                    :
                    <div className="box-content">
                        <div className="content-empty">
                            <p>최근 본 지식이 없습니다</p>
                        </div>
                    </div>
            }

        </div>
    )
}

export default RecentBoard;
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {BoardVO} from "../../../../core/board/board.vo";
import BoardCardTypeRow from "./BoardCardTypeRow";
import {BoardInfoVO} from "../../../../core/board/BoardInfo.vo";


interface BoardCardTypeProps {
    boardInfo: BoardInfoVO,
    boardList : BoardVO[],
    noticeList: BoardVO[]
}

const BoardCardType = (props:BoardCardTypeProps) => {
    const navigation = useNavigate()
    const{boardKey} = useParams()
    const [boardInfo, setBoardInfo] = useState<BoardInfoVO>(props.boardInfo)
    const [boardList, setBoardList] = useState<BoardVO[]>(props.boardList)
    const [noticeList, setNoticeList] = useState<BoardVO[]>(props.noticeList)

    useEffect(() => {
        setBoardList(props.boardList)
        setNoticeList(props.noticeList)
        setBoardInfo(props.boardInfo)

    }, [props]);

    const goDetail = (idx: number) => {
        navigation('/mypage/board/'+ boardKey +'/' + idx)
    }


    if (boardList.length > 0 || noticeList.length > 0){
        return (
            <ul className="card-list type5">
                {
                    noticeList.map(value => <BoardCardTypeRow key={value.idx}
                                                              board={value}
                                                              clickEvent={goDetail}
                                                              isNotice={true}/>)
                }
                {
                    boardList.map(value => <BoardCardTypeRow key={value.idx}
                                                             board={value}
                                                             clickEvent={goDetail}
                                                             isNotice={false}/>)
                }
            </ul>
        )
    } else {
        return (
            <div className="content-empty" style={{textAlign:"center"}}>
                <i className="ico-content"></i>
                <p className="txt">검색 결과가 없습니다.<br/>검색어를 다시 한 번 확인해주세요</p>
            </div>
        )
    }
}

export default BoardCardType
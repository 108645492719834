import APIs from "../apis/APIs";
import {CodeVO} from "../../core/common/code.vo";
import AxiosCaller from "./axios.caller";
import axiosCaller from "./axios.caller";
import {HWMap} from "../../core/common/common.vo";
declare var $ :any

class CodeUtils {

	code2Select (_tagId :string, _code :string, _isFirst :any ) {

		$.ajax({
			url : APIs.COMMON_CODE + "/getCodeTree",
			data : {
				code : _code
			},
			async : false,
			success: function (result :any) {
				if ( result.status === "ok" ) {

					console.log( result )
					$(_tagId + ' option').remove();

					var options = (_isFirst !== false) ? "<option value='" + _isFirst.value + "'>" + _isFirst.text + "</option>" : "";
					$.each( result.codeList, function (k :number, v :any) {
						options += "<option value='" + v.code + "'>" + v.codeName + "</option>";
					});
					$("select" + _tagId).append( options );
				}
			}
		})
	}


	codeNode2Select (_tagId :string, _code :string, _isFirst :any, _valField = null ) {

		$.ajax({
			url : APIs.COMMON_CODE + "/getCodeTree",
			data : {
				code : _code
			},
			async : false,
			success: function (result:any) {

				console.log( result )
				if ( result.status === "ok" ) {
					$(_tagId + ' option').remove();
					var options = ( (_isFirst !== false) ? "<option value='" + _isFirst.value + "'>" + _isFirst.text + "</option>" : "" ) + this.createOptions(0,  result.codeList, _valField )
					$("select" + _tagId).append( options );
				}
			}
		})
	}



	createOptions ( _indent :number, _codeList : any[], _valField = null ) {

		let optionsList = "";
		$.each( _codeList, (k: number, v: any) => {

			var icon = "";
			for (var i = 0; i < _indent; i++) {
				icon += "&nbsp; &nbsp; &nbsp; &nbsp;"
				if ( i + 1 < _indent ) {
					// if ( _codeList.length > k + 1 ) {
					icon += "│";
					// }
				} else {
					if ( _codeList.length > k + 1 || v.rows.length > 0 ) {
						icon += "┞";   // ├┣┠┝┞ 79517
					} else {
						icon += "┗";
					}
				}
			}

			if ( _valField == null ) {
				optionsList += "<option value='" + v.code + "'>" + ( icon ) + " " + v.codeName + "</option>";
			} else {
				optionsList += "<option value='" + v[_valField] + "'>" + ( icon ) + " " + v.codeName + "</option>";
			}

			if ( v.rows.length > 0 ) {
				optionsList += this.createOptions( _indent+1, v.rows, _valField );
			}
		});
		return optionsList;
	}

	static async getCoCodeList() {
		if (sessionStorage.getItem('coCodeList') == null) {
			const result = await axiosCaller.get(APIs.CO_INFO_LIST)
			if (result.status === 'SUCCESS') {
				sessionStorage.setItem('coCodeList', JSON.stringify(result.data));
			}
		}

		let codeList: CoCode[] = JSON.parse(sessionStorage.getItem('coCodeList') ?? '[]');
		return codeList;
	}

	static async getCoCodeNames(coCodes: string) {
		if(coCodes == null) return "-";

		let codeList = await this.getCoCodeList();
		const codes = coCodes.split(',');
		if (codeList.length === codes.length){
			return "전체"
		}

		const codeNames = codes.map(code => {
			const found = codeList.find(item => item.coCode === code);
			return found ? found.coName : undefined;
		}).filter(name => name);

		return codeNames.join(', ');
	}


	// 진행 중인 요청을 저장할 정적 변수
	private static reviewCodeTreePromise: Promise<HWMap> | null = null;

	static async getReviewCodeList() {
		if (sessionStorage.getItem('reviewCodeList') == null) {
			const result = await this.getAxiosReviewCode()
			if (result.status === 'ok') {
				sessionStorage.setItem('reviewCodeList', JSON.stringify(result.codeList));
			}
		}
		let codeList: CodeVO[] = JSON.parse(sessionStorage.getItem('reviewCodeList') ?? '[]');
		return codeList
	}

	private static async getAxiosReviewCode(){
		if (!this.reviewCodeTreePromise){
			this.reviewCodeTreePromise = new Promise<HWMap>(resolve => {
				resolve(AxiosCaller.get(APIs.COMMON_CODE + "/getCodeTree", {code: '3501'}))
			}).finally(()=>{
				this.reviewCodeTreePromise = null
			})
		}

		return this.reviewCodeTreePromise
	}

	// 현재 코드부터 n depth 아래 계층 코드 리스트 출력
	static extractCodeListByLevel(codeVO: CodeVO, depth:number){
		let findingCodeLevel = codeVO.codeLevel + depth;
		return this.getChildrenList(codeVO, findingCodeLevel)
	}

	// codeLevel 맞는 code 가져오기
	static getChildrenList(codeVO:CodeVO, findingCodeLevel:number){
		let list:CodeVO[] = [];

		if (codeVO.codeLevel === findingCodeLevel){
			list.push(codeVO)
			return list
		}

		if (codeVO.children.length <= 0){
			return list
		} else {
			for (let i = 0; i < codeVO.children.length; i++) {
				let childList = this.getChildrenList(codeVO.children[i], findingCodeLevel)
				list.push(...childList)
			}

			return list
		}

	}

	// 코드목록에서 코드 찾아오기
	static findCode(list: CodeVO[], code: string): CodeVO | null {
		for (const item of list) {
			if (item.code === code) {
				return item;

			} else if (item.children) {
				const found = this.findCode(item.children, code);
				if (found) {
					return found;

				}
			}
		}
		return null;
	}

}

export interface CoCode {
	coCode: string,
	coName: string
}



export default CodeUtils;
import React, {useEffect, useState} from 'react';
import {BoardVO, CommentVO} from "../../../../../../core/board/board.vo";
import M_ProEduTalkCancel from "../common/M_ProEduTalkCancel";
import M_ProEduTalkNone from "../common/M_ProEduTalkNone";
import M_ProEduTalkBoard from "../common/M_ProEduTalkBoard";
import M_ProEduTalkWriterReply from "./M_ProEduTalkWriterReply";
import M_ProEduTalkWriterReview from "./M_ProEduTalkWriterReview";
import M_ProEduTalkModal from "../../../../common/M_ProEduTalkModal";
import ToastUtils from "../../../../../../common/utils/toast.utils";

interface ProEduTalkWriterProps{
    boardVO: BoardVO,
    commentList: CommentVO[]
    updateEvent: ()=>void
}

const M_ProEduTalkWriter = (props:ProEduTalkWriterProps) => {
    const {updateEvent} = props
    const [boardVO, setBoardVO] = useState<BoardVO>(props.boardVO)
    const [commentList, setCommentList] = useState<CommentVO[]>(props.commentList)
    const [isFeedback, setIsFeedback] = useState<boolean>(false)
    const [isUpdateMode, setIsUpdateMode] = useState<boolean>(false)


    useEffect(() => {
        setBoardVO(props.boardVO)
        setCommentList(props.commentList)
    }, [props]);

    useEffect(() => {
        setTimeout(()=>{window.scrollTo(0,0)})
    }, [isFeedback]);

    const checkStatus = () => {
        switch (boardVO.statusCode) {
            case "041902":
                return "ing"
            case "041903":
                return "journal"
            case "041904":
                return "completed"
            case "041905":
                return "cancel"
            default:
                return "unresolved"
        }
    }

    const isCancel = () => boardVO.statusCode === '041905'
    const isNew = () => (checkStatus()==="unresolved") && (commentList.length <= 0)
    const isEditable = () => !isCancel() && isNew()

    const completeFeedback = () =>{
        setIsFeedback(false)
        updateEvent()
    }

    const updateModeShow = () => {
        setIsUpdateMode(true)
    }

    const getReplySection = () => {
        if(isCancel()){
            return <M_ProEduTalkCancel cancelMsg={boardVO.opt4 ?? ""}/>
        }
        if(isNew()) {
            return <M_ProEduTalkNone/>
        }

        const reply = commentList[0]
        const addCommentList = commentList.filter((value, index) => index != 0)
        return <M_ProEduTalkWriterReply boardVO={boardVO}
                                        reply={reply}
                                        commentList={addCommentList}
                                        receiverKey={boardVO.receiverKey}
                                        statusCode={boardVO.statusCode ?? "041901"}
                                        updateEvent={updateEvent}
                                        changeFeedback={()=>{
                                            setIsFeedback(true)
                                        }}/>
    }

    return (
        <>
            {
                isFeedback ? (
                    <M_ProEduTalkWriterReview
                        bidx={boardVO.idx}
                        targetId={boardVO.receiverKey}
                        updateHandler={completeFeedback}
                    />
                ) : isUpdateMode ? (
                    <M_ProEduTalkModal
                        receiver={boardVO.receiver ?? ''}
                        receiverKey={boardVO.receiverKey ?? ''}
                        boardVO={boardVO}
                        fidxs={boardVO.attachList?.map(val => val.fidx).join(",")}
                        updateEvent={() => setIsUpdateMode(false)}
                    />
                ) : (
                    <main className="content edutalk detail">
                        <M_ProEduTalkBoard
                            boardVO={boardVO}
                            isCancelable={false}
                            isEditable={isEditable()}
                            isReceive={false}
                            updateEvent={updateEvent}
                            updateModeEvent={updateModeShow}
                        />
                        {getReplySection()}
                    </main>
                )
            }

        </>
    )
}

export default M_ProEduTalkWriter


import React from "react";
import {BoardVO} from "../../../../core/board/board.vo";
import moment from "moment/moment";

interface BoardBasicTypeRowProps {
    board: BoardVO,
    isCategory: boolean,
    isNotice: boolean,
    isComment: boolean,
    clickEvent: (idx:number)=>void,
}

const M_BoardBasicTypeRow = (props:BoardBasicTypeRowProps) => {
    const {board, isCategory,isNotice, clickEvent} = props

    return (
        <li>
            <a href="#" onClick={e=>{
                e.preventDefault()
                clickEvent(board.idx)
            }}>
                <div className="cate">
                    {
                        isCategory && (<span>{board.cateName}</span>)
                    }
                </div>
                <strong className="tit">
                    {isNotice && (<span className="badge type1 sm red"
                                        style={{marginRight:'4px'}}>공지</span>)}
                    {board.title}
                    {
                        board.isFile && (
                            <span className="attach"></span>
                        )
                    }
                </strong>
                <div className="info-wrap">
                    <div>
                        <span className="writer">{board.writer}</span>
                    </div>
                    <div>
                        <span className="hits">{board.views}</span>
                        {
                            props.isComment && (
                                <span className="comment">{board.commentCount}</span>
                            )
                        }
                        <span className="date">{moment(board.createDate).format("YYYY-MM-DD")}</span>
                    </div>
                </div>
            </a>
        </li>
    )
}

export default M_BoardBasicTypeRow
import React from 'react';
import MemberUtils from "../../../common/utils/member.utils";
import M_MainBoardContentsItem from "./M_MainBoardContentsItem";

const M_MainBoard = () => {

    // https://github.com/innople-ebot/elandedu/issues/541
    const getBoardKeyArr = () => {
        // if (!MemberUtils.isLogin()){
        //     return ["pro_help","announce"]
        // }

        // const fashion = ["EWF001", "EWJ001",]
        // const coCode = MemberUtils.getSessionVO()?.coCode ?? "www";
        // for (let i = 0; i <fashion.length; i++) {
        //     if (fashion[i] === coCode){
        //         return ["company","company_announce"]
        //     }
        // }

        return ["pro_help","announce"]

        // return ["company","organization"]
    }

    return (
        <section className="news-section">
            <div className="inner">
                {
                    getBoardKeyArr().map(value => {
                        return <M_MainBoardContentsItem key={value} boardKey={value}/>
                    })
                }
            </div>
        </section>
)
}

export default M_MainBoard;
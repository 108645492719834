import React, {useEffect} from "react";
import {ProReqVO} from "../../../../core/pro/pro.vo";
import ProRecommendCard from "./ProRecommendCard";
import Swiper from "../../../../assets/js/swiper.min";

interface ProRecommendSlideProps {
    recommendList: ProReqVO[]
}

const ProRecommendSlide = (props:ProRecommendSlideProps) => {

    const {recommendList} = props
    const installSwiper = () => {
        new Swiper(".recommend-section .slide-wrap", {
            loop: true,
            slidesPerView: "auto",
            spaceBetween: 30,
            centeredSlides: true,
            navigation: {
                nextEl: ".btn-next",
                prevEl: ".btn-prev",
            },
        });
    }

    useEffect(() => {
        setTimeout(installSwiper, 100)

    }, [recommendList]);

    useEffect(() => {
    }, [])


    return (
        <section className="recommend-section">
            <div className="inner">
                <div className="section-title">
                    <h3 className="tit">추천 고수</h3>
                    <p className="desc">직무역량진단 결과에 따라 사내 강점을 가진 고수를 추천해 드릴게요</p>
                </div>
                <div className="board-list">
                    <div className="slide-wrap">
                        <ul className="slide-list swiper-wrapper card-list type3">
                            {
                                recommendList.map((value, index) => {
                                    return (
                                        <ProRecommendCard key={index} {...value}></ProRecommendCard>
                                    )
                                })
                            }

                        </ul>
                        <div className="button-area">
                            <div className="btn-prev" tabIndex={0} role="button" aria-label="Previous slide"></div>
                            <div className="btn-next" tabIndex={0} role="button" aria-label="Next slide"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProRecommendSlide
import React, {useEffect, useRef, useState} from 'react';
// @ts-ignore
import WebViewer from '@pdftron/pdfjs-express-viewer';
import APIs from "../../../common/apis/APIs";

interface PdfViewerProps {
    fileUrl: string;
    iframeHeight: number;
}

const PdfViewer: React.FC<PdfViewerProps> = ({fileUrl, iframeHeight}) => {
    const [height, setHeight] = useState<number>(iframeHeight)

    const viewer = useRef<WebViewer>(null);
    const webViewerInstance = useRef<WebViewer>(null);

    useEffect(() => {
        setHeight(iframeHeight)

    }, [iframeHeight]);

    useEffect(() => {
        //console.log("🐬",APIs.PDF_KEY)
        WebViewer(
            {
                path: process.env.PUBLIC_URL + '/elandedu/assets/libs/webviewer/',
                initialDoc: fileUrl,
                licenseKey: APIs.PDF_KEY,
                disabledElements: [
                    'eraserToolButton',
                    'freeHandToolGroupButton',
                    'textToolGroupButton',
                    'shapeToolGroupButton',
                    'signatureToolButton',
                    'freeTextToolButton',
                    'stickyToolButton',
                    'miscToolGroupButton',
                    'outlinesPanelButton',
                    'toolsHeader',
                    'notesPanelButton',
                    'printButton',
                    'toggleNotesButton',
                    'toolbarGroup-FillAndSign',
                    'toolbarGroup-Insert',
                    'toolbarGroup-Shapes',
                    'toolbarGroup-Annotate',
                    'toolbarGroup-View',
                    'selectToolButton',
                    'downloadButton',
                    'highlightToolButton',
                    'freeHandToolButton',
                    'freeHandHighlightToolButton'
                ]
            },
            viewer.current,
        ).then((instance: any) => {
            webViewerInstance.current = instance;

            instance.UI.disableElements(['menuButton'])
            instance.UI.disableElements(['moreButton'])
            instance.UI.disableElements(['fullscreenButton'])

            instance.UI.setHeaderItems((header: any) => {
                header.getHeader('default').push({
                    img: "icon-header-full-screen",
                    index: -1,
                    type: "actionButton",
                    element: 'fullScreenButton',
                    onClick: () => {
                        instance.UI.toggleFullScreen()
                    }
                });
            });

        });
    }, []);

    useEffect(() => {
        if (webViewerInstance.current) {
            webViewerInstance.current.UI.loadDocument(fileUrl);
        }
    }, [fileUrl]);

    return (
        <div className="pdfApp">
            <div className="webviewer" style={{ height : height }} ref={viewer}></div>
        </div>
    );

};

export default PdfViewer;

import React, {useState} from "react";
import M_JobSkillInputAnswer from "./M_JobSkillInputAnswer";
import {PollAskVO} from "../../../../../core/jobskill/jobskill.vo";

interface JobSkillInputAskProps {
    pollAsk: PollAskVO,
    selectAnswerEvent : (ask:PollAskVO) => void
}

const M_JobSkillInputAsk = (props: JobSkillInputAskProps) => {
    const {pollAsk,selectAnswerEvent} = props
    const selectEvent = (paaidx: number) => {
        const newAsk = {...pollAsk}
        newAsk.selectAnswer = paaidx

        selectAnswerEvent(newAsk)
    }

    return (
        <div className="form-item">
            <div className="form-title">
                <strong className="tit">{pollAsk.askTitle}</strong>
            </div>
            <div className="rdo-bind">
                {
                    pollAsk.answerList.map(answer => {
                        return (
                            <M_JobSkillInputAnswer key={answer.paaidx}
                                                   askAns={answer}
                                                   selectEvent={selectEvent}
                                                   selectAnswer={pollAsk.selectAnswer}/>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default M_JobSkillInputAsk
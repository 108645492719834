import React, {useEffect, useState} from "react";
import BoardService from "../../../core/board/board.service";
import {useNavigate, useParams} from "react-router-dom";
import {BoardFileVO, BoardVO, DefaultBoardVO} from "../../../core/board/board.vo";
import APIs from "../../../common/apis/APIs";
import M_BasicDetail from "./detail/M_BasicDetail";
import {BoardInfoVO} from "../../../core/board/BoardInfo.vo";
import {SessionVO} from "../../../core/common/session.vo";
import MemberUtils from "../../../common/utils/member.utils";
import {CompanyAccountVO} from "../../../core/company/company.vo";
import {M_ReviewType} from "../review/M_ReviewType";
import M_AudienceReview from "../review/M_AudienceReview";
import PointService from "../../../core/point/point.service";
import ElandUtils from "../../../common/utils/eland.utils";
import useHeader from "../../../common/hook/useHeader";
import {HeaderBtn} from "../../../core/common/header";
import useFooter from "../../../common/hook/useFooter";
import M_MovCardDetail from "./detail/M_MovCardDetail";
import FileUtils from "../../../common/utils/file.utils";
import M_RelatedItem from "./item/M_RelatedItem";
import CommonMobileJS from "../../../common/common_m";
import M_ProEduTalkModal from "../common/M_ProEduTalkModal";

const M_BoardDetail: React.FC = () => {

    const boardService = new BoardService();
    let {boardKey, bidx} = useParams();

    const [sessionVO, setSessionVO] = useState<SessionVO | null>(MemberUtils.getSessionVO());
    const [boardVO, setBoardVO] = useState<BoardVO>(DefaultBoardVO);
    const [boardInfoVO, setBoardInfoVO] = useState<BoardInfoVO>();
    const [tags, setTags] = useState<string[]>([]);
    const [relatedList, setRelatedList] = useState<BoardVO[]>([]);
    const [fileList, setFileList] = useState<BoardFileVO[]>([]);
    const [mode, setMode] = useState("cardMov");
    const [writerInfo, setWriterInfo] = useState<CompanyAccountVO>();
    const [isShowEdutalk, setIsShowEdutalk] = useState<boolean>(false)

    const navigate = useNavigate();


    useHeader({
        type: "content",
        title: boardInfoVO?.boardName,
        buttons: [HeaderBtn.Back]
    })

    useFooter({
        isHidden: true
    })


    useEffect(() => {
        if (!bidx) return;

        (async () => {
            const result = await boardService.getBoardOne({bidx: bidx});

            if (result.status == "SUCCESS" && result.data) {
                const board = result.data.boardVO;

                // 삭제되었다면 에러화면으로 이동
                if (!board.isUse) {
                    navigate('/notFound', {state: {alertMessage: '삭제되었습니다.'}});
                }

                board.isWish = result.data.isBookMark;
                board.writerInfo = result.data.writerInfo;

                // skillCode, JobCode name 받아오기
                const updatedBoard = await ElandUtils.convertJobSkillNames4Board(board);

                setBoardVO(updatedBoard);
                setFileList(board.attachList);
                setBoardInfoVO(result.data.boardInfoVO);
                setTags(board.tags?.split(","));

                const writerInfo = result.data.writerInfo;
                setWriterInfo(writerInfo);


                // 포인트 적립
                await PointService.savePoint("CONTENT_VIEW", bidx)
            }

            CommonMobileJS.floatingArea()
        })()
    }, [bidx]);

    useEffect(() => {
        if (boardVO != null && boardVO.idx > 0) {
            console.log("⭐boardVO", boardVO);
            getRelatedContents();
        }

    }, [boardVO]);

    useEffect(() => {
        if (boardInfoVO?.boardMode == "classic" || boardInfoVO?.boardMode == "basic") {
            setMode("basic")
        } else {
            setMode("cardMov")
        }
    }, [boardInfoVO]);


    const getRelatedContents = () => {

        const params = {
            boardKey: APIs.KNOWLEDGE_BOARDKEY,
            coCode: boardVO.coCode,
            tags: boardVO.tags,
            skillCode: boardVO.skillCode
        };

        (async () => {
            const result = await boardService.getRelatedContents(params);

            if (result.status == "SUCCESS" && result.data) {
                // todo 태그 가져와야 함
                const updatedBoardList = await ElandUtils.convertJobSkillNames4BoardList(result.data);
                setRelatedList(updatedBoardList);
            }

        })()
    }

    return (
        <>
        {
           !isShowEdutalk ? (
               <main className="content detail knowledge">
                   {/* 일반 글 상세 화면 */}
                   {mode === 'basic' && <M_BasicDetail boardVO={boardVO} sessionVO={sessionVO} tags={tags}/>}


                   {/* 카드뉴스 / 영상 상세 화면 */}
                   {
                       boardInfoVO && mode === 'cardMov' &&
                       <M_MovCardDetail
                           boardInfoVO={boardInfoVO}
                           boardVO={boardVO}
                           fileList={fileList}
                           tags={tags}
                       />
                   }


                   {/* 한줄평 */}
                   {
                       bidx && boardVO.idx > 0 &&
                       <M_AudienceReview emIdx={0}
                                         bidx={parseInt(bidx, 10)}
                                         type={M_ReviewType.BOARD}
                                         isBorder={boardVO.boardKey !== "job_data"}/>
                   }


                   {/* 지식 제작자 */}
                   {mode == 'cardMov' && writerInfo &&
                       <section className="writer-section border-10">
                           <div className="inner">
                               <div className="section-title">
                                   <h3 className="tit">지식 제작자</h3>
                               </div>
                               <div className="knowledge-writer">
                                   <div className="profile-wrap">
                                       <img src={FileUtils.getFileUrl(writerInfo?.profileSrc ?? "")}
                                            onError={FileUtils.onErrorImg}
                                            alt="프로필 이미지"
                                            className="img-profile"/>
                                       {
                                           // 고수나 예비고수
                                           MemberUtils.isProRateCode(writerInfo.rateCode) || MemberUtils.isSemiProRateCode(writerInfo.rateCode)
                                               ? <>
                                                   <div className="text-wrap">
                                                       <strong
                                                           className="name">{MemberUtils.isProRateCode(writerInfo.rateCode) ? '사내고수' : '예비고수'} {writerInfo.userName}</strong>
                                                       <span className="job">{writerInfo.teamName}</span>
                                                   </div>
                                               </>
                                               : <>
                                                   <div className="text-wrap">
                                                       <strong
                                                           className="name">{boardVO.writer}</strong>
                                                       <span className="job">이랜드 에듀는 이랜드 임직원분들의 성장을 돕기 위해 시작한 교육 플랫폼입니다.</span>
                                                       {/*<span className="job">{writerInfo.teamName}</span>*/}
                                                   </div>
                                               </>
                                       }
                                   </div>

                                   {
                                       // 사내고수이고 작성자가 아니면
                                       (MemberUtils.isProRateCode(writerInfo.rateCode) && writerInfo.coEmailId !== MemberUtils.getLoginId()) && (
                                           <button type="button" className="btn red full"
                                                   onClick={e=>{setIsShowEdutalk(true)}}
                                                   style={{height:"32px", fontSize:"12px"}}>에듀톡 문의하기</button>
                                       )
                                   }
                               </div>
                           </div>
                       </section>
                   }

                   {/* 연관 지식 */}
                   {mode == 'cardMov' && <M_RelatedItem boardList={relatedList}/>}
               </main>
           ) : (
               <M_ProEduTalkModal receiver={writerInfo?.userName ?? ''}
                                  receiverKey={writerInfo?.coEmailId ?? ""}
                                  boardVO={null}
                                  updateEvent={() => {
                                      setIsShowEdutalk(false)
                                  }}/>
           )
        }
        </>

    );
}

export default M_BoardDetail;
import React, {useEffect, useState} from "react";
import Swiper from "../../../../assets/js/swiper.min";
import webUtils from "../../../../common/utils/web.utils";
import {
	CardFileVO,
	EduAnswerVO,
	EduContentVO,
	EduMainVO,
	QstCardVO
} from "../../../../core/education/education.vo";
import EducationService from "../../../../core/education/education.service";
import educationService from "../../../../core/education/education.service";
import {HWMap} from "../../../../core/common/common.vo";
import DateUtils from "../../../../common/utils/date.utils";
import StringUtils from "../../../../common/utils/string.utils";
import ToastUtils from "../../../../common/utils/toast.utils";
import PointService from "../../../../core/point/point.service";
import WebUtils from "../../../../common/utils/web.utils";
import FileUtils from "../../../../common/utils/file.utils";
import {M_EduContentIndex, M_EduTitleDom} from "../M_EduMainDetail";
import M_PdfViewer from "../../common/M_PdfViewer";
import {BoardFileVO} from "../../../../core/board/board.vo";


const M_QstCard:React.FC<{paramMap:HWMap}> = ({paramMap}) => {
	
	const [requestStep, setRequestStep] = useState()
	const [eduMainVO, setEduMainVO] = useState<EduMainVO|null>(null)
	const [contentList, setContentList] = useState<EduContentVO[]|null>(null)
	const [eduContentVO, setContentVO] = useState<EduContentVO|null>(null)
	const [qstCardVO, setQstCardVO] = useState<QstCardVO|null>(null)
	const [cardFileList, setCardFileList] = useState<CardFileVO[]|null>(null)
	const [eduAnswerVO, setEduAnswerVO] = useState<EduAnswerVO|null>(null)
	
	const [readSlides, setReadSlides] = useState<CardFileVO[]>([])
	const [ebook, setEbook] = React.useState<CardFileVO>();
	

	/**
	 * 받아온 데이터 정의
	 */
	useEffect(() => {
		setReadSlides([])
		WebUtils.log(paramMap)
		if (paramMap.eduMainVO) {
			setRequestStep(paramMap.requestStep)
			setEduMainVO(paramMap.eduMainVO)
			setContentList(paramMap.contentList)
			setContentVO(paramMap.eduContentVO)
			setQstCardVO(paramMap.eduContentVO.qstCardVO)
			setEduAnswerVO( paramMap.answerList[paramMap.requestStep] )
		}
	}, [paramMap]);
	
	
	
	useEffect(() => {
		
		(async () => {
			if ( eduContentVO?.ecIdx ) {
				const resultList = await EducationService.getCardFiles(eduContentVO.ecIdx);
				const sortedFileList = resultList.sort((a: BoardFileVO, b: BoardFileVO) => a.ordering - b.ordering);
				await setCardFileList(sortedFileList)
			}
		})()
		
	}, [eduContentVO])
	
	
	
	
	/**
	 * 카드 파일 렌더링 후
	 * 이미지나 PDF 실행
	 */
	useEffect(() => {
		WebUtils.log(cardFileList)

		if ( qstCardVO?.cardType === 'image' ) {
			
			if ( cardFileList && cardFileList.length > 0 ) {
				initSwiper();
			}
			
			//initSwiper()
		}
		if ( qstCardVO?.cardType === 'pdf' ) {
			
			if ( cardFileList && cardFileList.length > 0 ) {
				cardFileList.forEach((file:CardFileVO) => {
					if ( file.fileext.toLowerCase() === "pdf" ) {
						setEbook(file)
					}
				})
				
				try {
					
					/**
					 * 학습가능상태 확인
					 */
					if ( paramMap.isPossible ) {
						
						/**
						 * PDF 일 경우 5초 뒤에 완료처리
						 */
						if ( !eduAnswerVO?.isComplete ) {
							setTimeout(async () => {
								EducationService.setAnsRecord(eduContentVO!.ecIdx, `END`, "card")
								const statusResult = await educationService.setAnswerStatus({
									eaIdx : eduAnswerVO!.eaIdx,
									isResult : false,
									isComplete : true,
								})
								if ( statusResult.status === "SUCCESS" ) {
									ToastUtils.show(`${eduContentVO?.qstCardVO.cardName} 완료되었습니다.`)
									
									// 포인트적립 : 학습 컨텐츠 완료
									await PointService.savePoint("COURSE_VIEW", eduContentVO!.ecIdx)
									
									paramMap._retrievePage(statusResult.allComplete)
								}
							}, 5000)
						}
					}
					
				} catch (e) {
					webUtils.log(e)
				}
			}
			
		}
		
		WebUtils.goTop()
		
	}, [cardFileList])
	
	
	
	
	
	const initSwiper = () => {
		new Swiper(".detail .media-content .slide-wrap", {
			navigation: {
				nextEl: ".btn-next",
				prevEl: ".btn-prev",
			},
			pagination: {
				el: ".slide-page",
				type: "fraction",
			},
			loop: true,
			on: {
				slideChange: function() {
					const self:HWMap = this
					const activeIndex = self.realIndex + 1;
					const totalSlides = self.slides.length - 2;
					console.log(`현재 이미지: ${activeIndex}/${totalSlides}`);
				},
				transitionEnd: function () {
					const self:HWMap = this
					let currentIndex = self.realIndex; // 현재 슬라이드의 인덱스
					console.log('Current slide index:', currentIndex);
					
					if ( cardFileList ) {
						const currentSlide = cardFileList[currentIndex];
						setReadSlides(prevState => {
							// 중복을 방지하기 위해 현재 슬라이드가 배열에 포함되어 있는지 확인
							if (!prevState.some(slide => slide.fidx === currentSlide.fidx)) {
								return [...prevState, currentSlide];
							}
							return prevState;
						});
					}
				}
			},
			observer: true, // 슬라이드가 변경될 때 DOM 변화를 감지하여 해결합니다.
			observeParents: true
		});
	}
	
	
	/**
	 * readSlides 저장때마다 슬라이드 이미지 수와 동일한지 확인
	 * 동일하면 해당 교육컨텐츠 완려처리
	 */
	useEffect(() => {
		
		/**
		 * 학습가능상태 확인
		 */
		if ( paramMap.isPossible ) {
			
			if ( readSlides && cardFileList && readSlides.length >= cardFileList.length ) {
				const isReadAll = cardFileList.every(card => readSlides.some(slide => slide.fidx === card.fidx))
				if ( isReadAll && !eduAnswerVO?.isComplete ) {
					//paramMap._retrievePage();
					(async () => {
						EducationService.setAnsRecord(eduContentVO!.ecIdx, `END`, "card")
						const result = await educationService.setAnswerStatus({
							eaIdx : eduAnswerVO!.eaIdx,
							isResult : false,
							isComplete : true,
						})
						if ( result.status === "SUCCESS" ) {
							ToastUtils.show(`${eduContentVO?.qstCardVO.cardName} 완료되었습니다.`)
							
							// 포인트적립 : 학습 컨텐츠 완료
							await PointService.savePoint("COURSE_VIEW", eduContentVO!.ecIdx)
							
							paramMap._retrievePage(result.allComplete)
						}
					})()
				}
			}
		}
	
	}, [readSlides])
	
	
	
	return (
		<>
			<section className="detail-section type-media border-10">
				{/*title*/}
				<div className="detail-title">
					<div className="inner">
						
						<M_EduTitleDom paramMap={{
							mainTitle: eduMainVO?.eduTitle,
							contentTitle: qstCardVO?.cardName,
							startDate : DateUtils.displayEduDate(eduMainVO?.scheduleMode, eduMainVO?.startDate),
							endDate : DateUtils.displayEduDate(eduMainVO?.scheduleMode, eduMainVO?.endDate),
							introduce : eduMainVO?.introduce
						}} />
						
						<div className="media-content">
							
							{ qstCardVO?.cardType === `image` &&
								<div id={`IMAGEObject`} className="slide-wrap">
									<ul className="slide-list swiper-wrapper">
										{
											cardFileList?.map((value, index) => (
												<li className="slide-item swiper-slide" key={index}>
													<a href="#" onClick={e=> e.preventDefault()}>
														<img src={FileUtils.getCardImageSrc(value)} alt={value.filename} className="img-cardnews" style={{
															objectFit: "contain",
															width: "100%"
														}}/>
													</a>
												</li>
											))
										}
									</ul>
									<div className="slide-page"></div>
									<div className="btn-prev"></div>
									<div className="btn-next"></div>
								</div>
							}
							{ qstCardVO?.cardType === `pdf` && ebook &&
								<M_PdfViewer fileUrl={FileUtils.showEduCard(ebook) || ""}/>
							}
						</div>
						<h2 className="tit">{qstCardVO?.cardName}</h2>
						
						<M_EduContentIndex paramMap={paramMap} ></M_EduContentIndex>
						
					</div>
				</div>
				<div className="detail-content">
					<div className="inner">
						<div className="tabs">
							<ul className="tab-menu">
								<li className="tab-item explain active">
									<a href="javascript:void(0);">강의 설명</a>
								</li>
								{/*<li class="tab-item ai">
									<a href="javascript:void(0);">AI 요약 beta</a>
								</li>
								<li class="tab-item script">
									<a href="javascript:void(0);">스크립트</a>
								</li>*/}
							</ul>
							<div className="tab-panel tab-explain">
								<div className="text-area" dangerouslySetInnerHTML={{__html:StringUtils.nl2br(qstCardVO?.cardDesc) || ""}}></div>
							</div>
							{/*<div class="tab-panel tab-ai">
								<ul class="ai-list">
									<li>
										<span class="timeline">00:00:00~00:01:14</span>
										<strong class="tit">고객 조사에서 고객 요청 리스트의 중요성과 혁신적인 제목의 필요성</strong>
										<div class="summary">
											<p>- 고객 조사 중 고객 요청 리스트를 제대로 관리하지 않아 문제가 지속될 수 있음.</p>
											<p>- EBG의 핵심 도구인 E-CAL은 이러한 문제를 해결하는데 도움이 될 수 있음.</p>
											<p>- 고객 요청 리스트를 지속해서 쌓지 않으면 *자기 중심적인 결정*으로 이어질 수 있음.</p>
											<p>- 고객의 니즈를 파악하여 프로젝트를 개발하고, *고객 중심적인 혁신*을 이끌어내는 것이 중요함.</p>
										</div>
									</li>

									<li>
										<span class="timeline">00:00:00~00:01:14</span>
										<strong class="tit">고객 조사에서 고객 요청 리스트의 중요성과 혁신적인 제목의 필요성</strong>
										<div class="summary">
											<p>- 고객 조사 중 고객 요청 리스트를 제대로 관리하지 않아 문제가 지속될 수 있음.</p>
											<p>- EBG의 핵심 도구인 E-CAL은 이러한 문제를 해결하는데 도움이 될 수 있음.</p>
											<p>- 고객 요청 리스트를 지속해서 쌓지 않으면 *자기 중심적인 결정*으로 이어질 수 있음.</p>
											<p>- 고객의 니즈를 파악하여 프로젝트를 개발하고, *고객 중심적인 혁신*을 이끌어내는 것이 중요함.</p>
										</div>
									</li>
								</ul>
							</div>
							<div class="tab-panel tab-script">
								<div class="text-area">
									고객 조사에 대한 전문가들의 의견<br />
									고객 조사에 대한 전문가들의 의견 여러분이 고객 조사를 할 때마다 반복해서 나오는 문제가 있는데 몇 년째 해결 못하고 있지는 않나요? <br />
									그 이유는 바로 고객 조사를 하긴 하지만 아무도 그 결과를 쌓고 철저하게 관리하지 않기 때문입니다. <br />
									이때 EBG의 핵심 도구인 E-CAL이 도움이 될 수 있습니다. <br />
									고객 요청 리스트 E-CAL은 Eland Customer Asking List의 약자입니다. 한마디로 고객 요청 리스트입니다. <br />
									고객의 니즈는 계속 변화하기 때문에 고객 요청 리스트를 지속해서 쌓지 않으면 결국 본인 위주로 다시 돌아가게 되는 오류를 범합니다. <br />
									그러다 보면 고객과 상관없이 내가 해결하고 싶은 문제 혹은 해결할 수 있는 문제만 건드리게 되죠. <br />
									고객에게 물어보지 않고 내 생각대로 프로젝트 제목으로 정해버리면 결국 고객을 움직이는 혁신으로 이어질 수가 없습니다. 혁신 제목이라는 것 자체가 고객의 니즈로부터 출발하는 것이기 때문입니다.<br />
								</div>
							</div>*/}
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
export default M_QstCard
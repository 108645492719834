import React, {useEffect, useState} from "react";
import Pagination from "../../../../pc/common/Pagination";
import SearchInput from "../../../../pc/common/SearchInput";
import {CodeVO} from "../../../../../core/common/code.vo";
import {PageParams, PageUtils} from "../../../../../common/utils/page.utils";
import ProHelpListRow from "./ProHelpListRow";
import {useNavigate, useSearchParams} from "react-router-dom";
import {BoardVO} from "../../../../../core/board/board.vo";
import ProService from "../../../../../core/pro/pro.service";
import ElandUtils from "../../../../../common/utils/eland.utils";

interface ProHelpListFilterParam {
    jobCode: string,
    cateCode: string
}

const ProHelpList = () => {
    const [query, setQuery] = useSearchParams();
    const navigation = useNavigate()

    const [bestBoardList, setBestBoardList] = useState<BoardVO[]>([])
    const [boardList, setBoardList] = useState<BoardVO[]>([])

    const [menuList, setMenuList] = useState<CodeVO[]>([])
    const [pageParams, setPageParams] = useState<PageParams>(
        new PageUtils(
            parseInt(query.get('page') ?? "0"),
            parseInt(query.get('size') ?? "10"),
            query.get('sort') ?? "",
            parseInt(query.get('totalCount') ?? "0"),
            query.get("searchText") ?? "",
        )
    );
    const [totalCount, setTotalCount] = useState<number>(0);
    const [filterParams, setFilterParams] = useState<ProHelpListFilterParam>(
        {jobCode: query.get("jobCode") ?? "", cateCode: query.get("cateCode") ?? ""}
    );

    useEffect(() => {
        const getMenuList = async () => {
            let jobcodeList = await ElandUtils.getJobSkillCodeList(false);
            setMenuList(jobcodeList)
        }

        getMenuList()
    }, []);


    useEffect(() => {
        const getBoardList = async () => {
            const successHandler = async (result:any) => {
                setBestBoardList(result.data.best.list)
                setTotalCount(result.data.board.count)
                setBoardList(result.data.board.list)
            }

            await ProService.helpListSearch({...pageParams, ...filterParams}, successHandler)
        }

        const updateParams = () => {
            query.set("page", pageParams.page.toString())
            query.set("size", pageParams.size.toString())
            query.set("sort", pageParams.sort.toString())
            query.set("searchText", pageParams.searchText)
            query.set("jobCode", filterParams.jobCode)
            query.set("cateCode", filterParams.cateCode)

            setQuery(query)
        }
        getBoardList()
        updateParams()

    }, [pageParams, filterParams]);


    const updateSearchText = (searchText: string) => {
        setPageParams((preParams) => ({
            ...preParams,
            searchText: searchText
        }));
    }

    const updatePages = (newPages: number) => {
        setPageParams((preParams) => ({
            ...preParams,
            page: newPages
        }));
    }

    const updateMenu = (newJobCode: string) => {
        setFilterParams((preParams) => ({
            ...preParams,
            jobCode: newJobCode
        }));

        setPageParams(prev => ({
            ...prev,
            page: 0,
            searchText: ''
        }))
    }

    const updateCategory = (newCate: string) => {
        updatePages(0)
        setFilterParams((preParams) => ({
            ...preParams,
            cateCode: newCate
        }));
    }

    const clickMenu = (e: any, code: string) => {
        e.preventDefault()
        updateMenu(code)
    }

    const clickCategory = (e: any, cate: string) => {
        e.preventDefault()
        updateCategory(cate)
    }

    const goForm = () => {
        navigation('/pro/help/form')
    }

    const goDetail = (idx: number) => {
        navigation('/pro/help/' + idx)
    }

    const isBoardListEmpty = () => {
        return bestBoardList.length <= 0 && boardList.length <= 0
    }


    return (
        <>
            <div className="board-util">
                <div className="util-group">
                    <div className="board-tabs">
                        <ul className="tab-list">
                            <li className={filterParams.jobCode === '' ? 'tab-item active' : 'tab-item'}>
                                <a href="#" onClick={(e) => clickMenu(e, '')}>전체</a>
                            </li>
                            {
                                menuList.map((value, index) => {
                                    return (
                                        <li key={index}
                                            className={filterParams.jobCode === value.code ? 'tab-item active' : 'tab-item'}>
                                            <a href="#"
                                               onClick={(e) => clickMenu(e, value.code)}>{value.codeName}</a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className="util-group">
                    <div className="board-total">
                        총 <span>{totalCount}</span> 개의 게시물이 있습니다.
                    </div>
                    <div className="board-search">
                        <SearchInput updateSearchText={updateSearchText}
                                     placeholderText={'제목 또는 작성자를 입력하세요'}
                                     initText={pageParams.searchText}></SearchInput>
                        <button type="button" className="btn red" onClick={goForm}>작성하기</button>
                    </div>
                </div>
                <div className="util-group">
                    <div className="board-sort">
                        <div className="sort-list">
                            <a href="#"
                               className={filterParams.cateCode === '' ? 'active' : ''}
                               onClick={(e) => clickCategory(e, '')}>전체</a>
                            <a href="#"
                               className={filterParams.cateCode === '040401' ? 'active' : ''}
                               onClick={(e) => clickCategory(e, '040401')}>도움요청</a>
                            <a href="#"
                               className={filterParams.cateCode === '040402' ? 'active' : ''}
                               onClick={(e) => clickCategory(e, '040402')}>지식나눔</a>
                            <a href="#"
                               className={filterParams.cateCode === '040403' ? 'active' : ''}
                               onClick={(e) => clickCategory(e, '040403')}>자유주제</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="board-list">
                <table className="table-list type1">
                    <colgroup>
                        <col/>
                        <col/>
                        <col/>
                        <col style={{width: "50%"}}/>
                        <col/>
                        <col/>
                        <col/>
                        <col/>
                    </colgroup>
                    <thead>
                    <tr>
                        <th>순번</th>
                        <th>직무</th>
                        <th>분류</th>
                        <th>제목</th>
                        <th style={{textAlign: "end"}}>작성자</th>
                        <th>작성일</th>
                        <th>조회수</th>
                        <th>추천수</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        !isBoardListEmpty() ? (
                            <>
                                {
                                    bestBoardList.map((value, index) => {
                                        return (
                                            <ProHelpListRow key={value.idx}
                                                            boardVO={value}
                                                            clickEvent={goDetail}
                                                            isBest={true}/>
                                        )
                                    })
                                }
                                {
                                    boardList.map((value, index) => {
                                        return (
                                            <ProHelpListRow key={value.idx} boardVO={value}
                                                            clickEvent={goDetail}
                                                            isBest={false}
                                                            index={totalCount - ((pageParams.page * pageParams.size) + index)}/>
                                        )
                                    })
                                }
                            </>
                        ) : (
                            <tr>
                                <td colSpan={8}>
                                    <div className="content-empty">
                                        <i className="ico-content"></i>
                                        <p className="txt">검색 결과가 없습니다.<br/>검색어를 다시 한 번 확인해주세요</p>
                                    </div>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
                {
                    !isBoardListEmpty() && (
                        <Pagination page={pageParams.page}
                                    totalCount={totalCount}
                                    updatePages={updatePages}
                                    size={pageParams.size}></Pagination>
                    )
                }

            </div>
        </>

    )
}

export default ProHelpList
import React from "react";

const Landing : React.FC = () => {
    return (
        <main className="content landing">
            <div className="inner">
                <div className="common-title blind">
                    <div className="inner">
                        <h2 className="tit">Landing</h2>
                        <p className="desc">HELLO, WORLD! ELANDEDU</p>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Landing;
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {BoardFileVO, BoardVO, DefaultBoardVO} from "../../../../../core/board/board.vo";
import moment from "moment";
import M_ProHelpDetailComment from "./M_ProHelpDetailComment";
import ProService from "../../../../../core/pro/pro.service";
import M_SummernoteIframe from "../../../../mobile/common/M_SummernoteIframe";
import APIs from "../../../../../common/apis/APIs";
import FileUtils from "../../../../../common/utils/file.utils";
import MypageService from "../../../../../core/mypage/mypage.service";
import ElandUtils from "../../../../../common/utils/eland.utils";
import PointService from "../../../../../core/point/point.service";
import ToastUtils from "../../../../../common/utils/toast.utils";
import useFooter from "../../../../../common/hook/useFooter";
import MemberUtils from "../../../../../common/utils/member.utils";
import useHeader from "../../../../../common/hook/useHeader";
import {HeaderBtn} from "../../../../../core/common/header";
import SwalUtils from "../../../../../common/utils/swal.utils";

const M_ProHelpDetail = ()=>{
    useHeader({
        type: 'content',
        title: "",
        buttons: [HeaderBtn.Back]
    });
    useFooter({isHidden: true})
    const navigate = useNavigate()
    const {bidx} = useParams()
    const [boardVO, setBoardVO] = useState<BoardVO>(DefaultBoardVO)
    const [isMe, setIsMe] = useState<boolean>(false)
    const [isRecommend, setIsRecommend] = useState<boolean>(false)


    useEffect(() => {
        getDetail()
    }, []);

    const getDetail = async () => {
        const successHandler = async (result:any) => {
            setIsMe(result.data.isMe)
            setIsRecommend(result.data.isRecommend)
            setBoardVO(await renderJobCateName(result.data.board))
        }
        const loadFailHandler = () => {
            navigate(-1)
        }

        bidx && await ProService.helpOneGet(bidx, successHandler, loadFailHandler);
    }


    const renderJobCateName = async (board:BoardVO) => {
        const newBoard = {...board}
        newBoard.jobName = newBoard.jobCode && await ElandUtils.getJobSkillCodeName(board.jobCode)
        switch (newBoard.cateCode) {
            case "040401":
                newBoard.cateName = "도움요청"
                break
            case "040402":
                newBoard.cateName = "지식나눔"
                break
            case "040403":
                newBoard.cateName = "자유주제"
                break
            default:
                break;
        }
        return newBoard
    }



    const getCateBadge = (cateCode:string) => {
        switch (cateCode) {
            case "040401":
                return (<span className="badge type1 sm orange">도움요청 </span>)
            case "040402":
                return (<span className="badge type1 sm green">지식나눔 </span>)
            case "040403":
                return (<span className="badge type1 sm blue">자유주제 </span>)
            default:
                break;
        }
    }

    const deleteBoard = async (e:any) => {
        e.preventDefault()
        if (isMe){
            SwalUtils.text({
                title : `삭제`,
                message : `정말로 삭제하시겠습니까?`,
                isCancel : true,
                confirmOptions : {
                    text : "삭제",
                    eventHandler : async() => {
                        let params = {
                            bidx: boardVO.idx,
                        }

                        const successHandler = async () => {
                            await PointService.cancelPoint("HELP_POST", boardVO.idx ?? "0");
                            ToastUtils.show("삭제되었습니다.")
                            navigate("/pro/help")
                        }

                        await MypageService.boardOneDelete(params, successHandler)
                    }
                },
                cancelOptions: {
                    text: "취소", eventHandler: () => {
                    }
                }
            })
        }
    }

    const updateBoard = (e:any) =>  {
        e.preventDefault()

        if (isMe){
            navigate('/pro/help/form', {
                state : {
                    board: boardVO,
                    fidxs: boardVO.attachList?.map(value => value.fidx).join(",") ?? "",
                }
            })
        }
    }

    const doRecommend = async (e:any, isSave:boolean) => {
        e.preventDefault()

        let params = {
            bidx: boardVO.idx,
            isSave: isSave,
        }

        const successHandler = () => {
            setIsRecommend(isSave)
            const newBoard = {...boardVO}
            newBoard.likeCount = isSave ? ++newBoard.likeCount : --newBoard.likeCount
            setBoardVO(newBoard)
        }

        await MypageService.boardOneRecommend(params, successHandler)
    }

    const downloadFile = async (e: any, fileVO: BoardFileVO) => {
        e.preventDefault()
        window.location.href = `${APIs.BOARD_FILES_DOWN}${fileVO.fidx}`
    }



    return (
        <main className="content detail help" >
            <section className="detail-section border-10">
                <div className="inner">
                    <div className="detail-title border-2">
                        <div className="cate-badge">
                            {getCateBadge(boardVO.cateCode ?? '')}
                        </div>
                        <div className="cate">
                            <a href="#">{boardVO.jobName}</a>
                        </div>
                        <h2 className="tit">{boardVO.title}</h2>
                        <div className="info">
                            <span
                                className="date">{moment(boardVO.createDate).format('YYYY-MM-DD')}</span>
                            <div className="rating-wrap">
                                <span className="hits">{boardVO.views}</span>
                            </div>
                        </div>
                        <div className="writer">
                            <div className="image-wrap">
                                <img src={FileUtils.getFileUrl(boardVO.profileSrc ?? "")}
                                     onError={FileUtils.onErrorImg}
                                     alt="프로필 이미지" className="img-profile"/>
                            </div>
                            <div className="text-wrap">
                                <div className="name">
                                    <b>{boardVO.writer}</b>
                                    <span>{MemberUtils.getProStr(boardVO.writerRate ?? "")}</span>
                                </div>
                                <div className="job">{boardVO.coName}</div>
                            </div>
                        </div>
                        {
                            isMe && (
                                <div className="util">
                                    <a href="#" className="btn-modify" onClick={updateBoard}>
                                        <span className="blind">수정하기</span>
                                    </a>
                                    <a href="#" className="btn-delete" onClick={deleteBoard}>
                                        <span className="blind">삭제하기</span>
                                    </a>
                                </div>
                            )
                        }

                    </div>
                    <div className="detail-content">
                        {
                        boardVO.attachList && boardVO.attachList.length > 0 && (
                                <ul className="attach-list">
                                    {
                                        boardVO.attachList.map(value => {
                                            return (
                                                <li key={value.fidx}>
                                                    <a href="#"
                                                       onClick={e => downloadFile(e, value)}
                                                    >
                                                        <span className="path">{value.filename}</span>
                                                    </a>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            )
                        }
                        <div className="text-area">
                            <M_SummernoteIframe mode={"view"} htmlCode={boardVO.contentHtml}/>
                        </div>
                        <div className="like-button">
                            <a href="#" className={isRecommend ? "btn-like active" : "btn-like"}
                               onClick={e => doRecommend(e, !isRecommend)}
                            >
                                <div>
                                    <i className={isRecommend ? "ico-like" : "ico-like deactivate"}></i>
                                    <span className={isRecommend ? "count" : "count deactivate"}>
                                        {boardVO.likeCount}
                                    </span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <M_ProHelpDetailComment/>
        </main>
    )
}

export default M_ProHelpDetail
import React, {useEffect, useRef} from "react";

interface ProFindSearchInputProps {
    updateSearchText: (searchText: string) => void
    placeholderText: string,
    initText?: string
}

const SearchInput = (props:ProFindSearchInputProps) =>{
    const {updateSearchText, placeholderText, initText} = props;
    const searchInput = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (searchInput.current){
            searchInput.current.value = initText ?? ""
        }
    }, [props.initText]);

    return (
        <div className="search">
            <input type="text" className="inp-search"
                   placeholder={placeholderText} ref={searchInput}
                   onKeyDown={(e) => {
                       if (e.key === "Enter" && !e.nativeEvent.isComposing) {
                           updateSearchText(searchInput.current?.value ?? "")
                       }
                   }}/>
            <button type="button" className="btn-search" onClick={() => {
                updateSearchText(searchInput.current?.value ?? "")
            }}>
                <span className="blind">검색</span>
            </button>
        </div>
    )
}

export default SearchInput
import {HWMap} from "../common/common.vo";
import axiosCaller from "../../common/utils/axios.caller";
import APIs from "../../common/apis/APIs";

class AlarmService {

    // 알림 갯수 조회
    static getNotifCount = () => {
        return axiosCaller.get(APIs.ALARM_COUNT)
    }

    // 알림 리스트 조회
    static getNotifList = (_params: HWMap) => {
        return axiosCaller.get(APIs.ALARM_LIST, _params)
    }

    // 알림 상태 변경
    static setIsRead = (_params: HWMap) => {
        return axiosCaller.post(APIs.ALARM_SET_IS_READ, _params)
    }
    /**
     * 각 코드에 해당하는 알림 제목 설정
     * @param code
     */
    static convertAlarmTitleName = (code: string) => {
        switch (code) {
            case "35020101":
            case "35020102":
            case "35020103":
            case "35020104":
            case "35020105":
                return "코스학습"
            case "35020106":
            case "35020107":
                return "코스학습 종료예정안내"
            case "35020301":
            case "35020302":
            case "35020303":
                return "사내고수 지식요청"
            case "35020304":
                return "사내고수"
            case "35020305":
            case "35020306":
                return "사내고수 지식답변"
            case "35020401":
                return "회원정보"
            case "35020308":
                return "직무역량진단"
            case "35020309":
                return "게시판 답글 등록 알람"
            case "35020201":
            case "35020202":
                return "포인트"
            case "35020203":
                return "베스트후기"
            case "35020403":
                return "공지사항"
            case "35020404":
                return "안내"
            case "35020204":
                return "지식등록안내"
            case "35020205":
                return "적용스토리 작성"
        }
    }

    /**
     * 각 코드에 대한 이벤트 처리
     * @param code
     */
    static handleCodeEvent = (code: string, bidx?: number) => {
        switch (code) {
            case "35020101":
            case "35020102":
            case "35020103": // 코스학습 시작 알림
                return location.href = "/education/inprogress"
            case "35020104": // 코스학습 수료 확인 알림
                return location.href = "/education/dashboard"
            case "35020105": // 단기 코스학습 미이수자 중간 알람
            case "35020106": // 코스학습 종료 전 알림 1차(3일전)
            case "35020107": // 코스학습 종료 전 알림 2차(1일전)
                return location.href = "/education/inprogress"
            case "35020301": // 사내고수 신규 요청 등록
            case "35020302": // 사내고수 추가 요청 등록
            case "35020303": // 사내고수 요청 확인 알림
            case "35020304": // 사내고수 후기 확인
                return location.href = "/pro/talk?page=0&size=5&statusCode=&isReceive=true"
            case "35020305": // 요청에 대한 사내고수 답변 등록
            case "35020306": // 사내고수 요청 답변 등록 확인요청
                return location.href = "/pro/talk?page=0&size=5&statusCode=&isReceive=false"
            case "35020401": // 등급 변경
                return location.href = "/mypage"
            case "35020308": // 직무역량진단 미완료 안내
                return location.href = "/mypage/jobskill"
            case "35020309": // 게시판 답변등록 안내
                return location.href = "/pro/help/" + bidx
            case "35020201": // 포인트차감안내
            case "35020202": // 포인트지급안내
                return location.href = "/mypage/point"
            case "35020203": // 베스트후기선정안내(직무, 사내고수)
                return location.href = "/board/story/use_story/" + bidx + "/best"
            case "35020403": // 신규 공지사항 등록
                return location.href = "/mypage/board/announce"
            case "35020404": // 관리자 안내
                return "안내"
            case "35020204": // 알람 희망 키워드 지식 등록 안내
                return location.href = "/mypage/alarm"
            case "35020205": // 학습 후 적용스토리 작성 안내
                return location.href = "/board/story/use_story"
        }
    }
}


export default AlarmService
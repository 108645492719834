import React, {useEffect, useRef, useState} from 'react';

interface ProFindSearchInputProps {
    updateSearchText: (searchText: string) => void;
    placeholderText: string;
    initText?: string;
    cancelHandler: () => void;
}

const M_SearchInput = ({ updateSearchText, placeholderText, initText = "", cancelHandler }:ProFindSearchInputProps) => {
    const [searchText, setSearchText] = useState<string>(initText);
    const [isFocusInput, setIsFocusInput] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setSearchText(initText);
    }, [initText]);


    const handleSearchUpdate = () => {
        if (searchText !== initText) {
            updateSearchText(searchText);
        }
    };


    const handleDeleteClick = () => {
        setSearchText("");
    }

    const handleCancelClick = () => {
        setSearchText("");

        if(cancelHandler) {
            cancelHandler()
        }
    };

    return (
        <div className="board-util">
            <div className="util-group">
                <div className="search-tab">
                    <div className="search-form">
                        <input
                            type="text"
                            className="inp-search"
                            ref={inputRef}
                            placeholder={placeholderText}
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" && !e.nativeEvent.isComposing) {
                                    handleSearchUpdate();
                                }
                            }}
                            onFocus={() => setIsFocusInput(true)}
                            onBlur={() => setIsFocusInput(false)}
                        />
                        <button
                            type="button"
                            className="btn-search"
                            onClick={()=>handleSearchUpdate()}
                            onTouchEnd={()=>handleSearchUpdate()}
                            style={{display: !(isFocusInput && searchText !== "") ? "block" : "none"}}
                            disabled={!(isFocusInput && searchText !== "")}
                        >
                            <span className="blind">검색</span>
                        </button>
                        <button
                            type="button"
                            className="btn-delete"
                            onClick={handleDeleteClick}
                            onTouchEnd={handleDeleteClick}
                            style={{display: (isFocusInput && searchText !== "") ? "inline-block" : "none"}}
                            disabled={isFocusInput && searchText !== ""}
                        >
                            <span className="blind">지우기</span>
                        </button>
                    </div>
                    <button
                        type="button"
                        className="btn-cancel"
                        onClick={handleCancelClick}
                        onTouchEnd={handleCancelClick}
                    >
                        취소
                    </button>
                </div>
            </div>
        </div>
    );
};

export default M_SearchInput;
import React, {useEffect, useState} from "react";
import ProService from "../../../core/pro/pro.service";
import {ProReqVO} from "../../../core/pro/pro.vo";
import {BoardVO} from "../../../core/board/board.vo";
import MainProNewContentsItem from "./MainProNewContentsItem";
import Swiper from "../../../assets/js/swiper.min";
import CardItem from "../board/item/CardItem";

interface MainProNewContentsProps {

}

const MainProNewContents = (props: MainProNewContentsProps) => {
    const [swiperVal, setSwiperVal] = useState<any>(null);
    const [list, setList] = useState<{pro:ProReqVO, board:BoardVO}[]>([])

    useEffect(() => {
        const successHandler = (result:any) => {
            const arr:{pro:ProReqVO, board:BoardVO}[] = result.data
            setList(arr)
        }

        ProService.newProContentGet(successHandler);
    }, []);


    useEffect(() => {
        if (list.length > 0) {
            initSwiper();
        }
    }, [list]);

    const initSwiper = () =>{
        if (swiperVal){
            swiperVal.destroy()
            setSwiperVal(null)
        }

        const newSwiperVal = new Swiper(".main-pro-new-contents", {
            slidesPerView: "auto",
            spaceBetween: 30,
            navigation: {
                nextEl: ".main-pro-new-contents-next",
                prevEl: ".main-pro-new-contents-prev",
            },
        });

        setSwiperVal(newSwiperVal)
    }


    return (
        <section className="list-section">
            <div className="inner">
                <div className="section-title">
                    <h3 className="tit">
                        사내고수 업로드 신규 콘텐츠
                    </h3>
                </div>
                <div className="board-list">
                    <div className="slide-wrap main-pro-new-contents">
                        <ul className="card-list swiper-wrapper type2">
                            {
                                list.map(val => {
                                    return <MainProNewContentsItem key={val.board.idx} pro={val.pro} board={val.board}/>
                                })
                            }
                        </ul>
                    </div>
                    <div className="slide-button main-pro-new-contents-slide-button">
                        <div className="btn-prev main-pro-new-contents-prev" tabIndex={0} role="button"
                             aria-label="Previous slide"></div>
                        <div className="btn-next main-pro-new-contents-next" tabIndex={0} role="button"
                             aria-label="Next slide"></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MainProNewContents;
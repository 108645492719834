import React, {useEffect, useState} from "react";
import MainBanner from "./MainBanner";
import MainService from "../../../core/main/main.service";
import {HWMap} from "../../../core/common/common.vo";
import {BannerVO} from "../../../core/main/main.banner.vo";
import MainEduStatus from "./MainEduStatus";
import MainCategory from "./MainCategory";
import {EduMainVO} from "../../../core/education/education.vo";
import MainProRecommendEmpty from "./MainProRecommendEmpty";
import CodeUtils from "../../../common/utils/code.utils";
import {CodeVO} from "../../../core/common/code.vo";
import MemberUtils from "../../../common/utils/member.utils";
import MainProNewContents from "./MainProNewContents";
import MainUseStory from "./MainUseStory";
import MainBoardContentsItem from "./MainBoardContentsItem";
import MainSystemItem from "./MainSystemItem";
import MainProRecommend from "./MainProRecommend";
import MainJobSkillResult from "./MainJobSkillResult";
import MainContentRecommend from "./MainContentRecommend";
import memberUtils from "../../../common/utils/member.utils";
import MainEditorContents from "./MainEditorContents";
import MainNewContents from "./MainNewContents";
import ElandUtils from "../../../common/utils/eland.utils";
import SwalUtils from "../../../common/utils/swal.utils";


export default function Main () {
	const [params, setParams] = useState<HWMap>();

	/**
	 * 메인 배너 리스트
	 */
	const [bannerList, setBannerList] = useState<Array<BannerVO>>();

	/**
	 * 카테고리 리스트
	 */
	const [menuList, setMenuList] = useState<Array<CodeVO>>();

	/**
	 * 현재 진행중인 필수 교육
	 */
	// 필수교육
	const [majorList, setMajorList] = useState<EduMainVO[]>([]);
	// 지정교육
	const [targetList, setTargetList] = useState<EduMainVO[]>([]);
	// 자율참여
	const [freeList, setFreeList] = useState<EduMainVO[]>([]);

	// Banner 정보 조회
	const getMainBannerList = () => {
		const successHandler = (result: any)=> {
			const bannerList = result.bannerList;
			setBannerList(bannerList);
		}

		MainService.getMainBannerList({}, successHandler)
	}

	// 카테고리 리스트 조회
	const getMenuList = async () => {
		let jobcodeList = await ElandUtils.getJobSkillCodeList(false);
		setMenuList(jobcodeList)
	}

	// 현재 진행중인 필수 교육 조회
	const getEduStatusList = () => {
		const successHandler = (result: any)=>{
			const majorList = result.majorMap.list;
			const targetList = result.targetMap.list;
			const freeList = result.freeMap.list;

			setMajorList(majorList)
			setTargetList(targetList)
			setFreeList(freeList)
		}

		MainService.getEduMainList({}, successHandler);
	}


	const getBoardKeyArr = () => {
		if (!MemberUtils.isLogin()){
			return ["pro_help","announce"]
		}

		const fashion = ["EWF001", "EWJ001",]
		const coCode = MemberUtils.getSessionVO()?.coCode ?? "www";
		for (let i = 0; i <fashion.length; i++) {
			if (fashion[i] === coCode){
				return ["company","company_announce"]
			}
		}

		return ["company","organization"]
	}

	// 직무역량진단 팝업 노출
	const openJobSkillPopup = () => {
		// YYYY-MM-DD
		const today = new Date().toISOString().split('T')[0];
		const savedDate = localStorage.getItem('savedJobSkillDate');

		if ( MemberUtils.isLogin() && !MemberUtils.isCompleteJobSkill() && (today != savedDate) ) {
			SwalUtils.text({
				title : `직무역량진단`,
				message : `
					<i class="ico-eyes"></i>
					<p>
						갓생 직장인을 꿈꾸는 당신!<br>
						무엇을 봐야할 지 고민된다면?<br>
						<br>
						6개월에 한 번 씩 직무역량진단을 통해<br>
						맞춤 학습 가이드를 알아보아요
					</p>
				`,
				isCancel : true,
				confirmOptions : {
					text : "진단 시작하기",
					eventHandler : () => {
						location.href = "/mypage/jobskill"
					}
				},
				cancelOptions : {
					text : "다음에 하기",
					eventHandler : () => {
						localStorage.setItem("savedJobSkillDate", today)
					}
				}
			})
		}
	}

	// init
	useEffect(() => {
		getMainBannerList()
		getEduStatusList()
		getMenuList()
		openJobSkillPopup()
	}, []);

	return (
		<main className="content main">
			{
				/**
				 * 메인 배너
				 */
			}
			<MainBanner bannerList={(bannerList == null) ? [] : bannerList}/>

			{
				/**
				 * 카테고리
				 */
				<MainCategory menuList={(menuList == null) ? [] : menuList}/>
			}

			{
				/**
				 * 현재 진행 중인 필수 교육
				 */
				/*
					진행중인 필수 교육이 없는 경우 영역 자체가 표기되지 않음
					진행중인 필수 교육이 있을 경우 아직 수료하지 않은 과목 노출
				 */
				majorList && majorList.length > 0 && (
					<MainEduStatus majorList={majorList} targetList={targetList} freeList={freeList}/>
				)
			}

			{
				/**
				 * 직무역량진단
				 */
				MemberUtils.isLogin() && ( MemberUtils.isCompleteJobSkill() ? <MainJobSkillResult/> : <MainProRecommendEmpty /> )
			}

			{
				/**
				 * 직무역량진단 true 라면 표출
				 */
				MemberUtils.isCompleteJobSkill() && (
					<section className="recommend-section">
						<div className="inner">
							<div className="section-title">
								<h2 className="tit"><strong>{memberUtils.getSessionVO()?.userName}</strong> 님 역량 향상을 위한 추천!</h2>
							</div>
							{
								/**
								 * 추천 콘텐츠
								 */
								<MainContentRecommend/>
							}
							{
								/**
								 * 추천 사내고수
								 */
								<MainProRecommend/>
							}
						</div>
					</section>
				)
			}

			{
				/**
				 * 에디터 추천 플레이리스트
				 */
			}
			<MainEditorContents type="recommend" />


			{
				/**
				 * 법인 플레이리스트
				 */
				MemberUtils.isLogin() && <MainEditorContents type="company" />
			}

			{
				/**
				 * 에디터's PICK
				 */
			}
			<MainEditorContents type="pick" />

			{
				/**
				 * 사내고수 업로드 신규 콘텐츠
				 */
			}
			<MainProNewContents/>

			{
				/**
				 * 신규 콘텐츠
				 */
			}
			<MainNewContents />

			{
				/**
				 * 적용 스토리
				 */
			}
			{
				MemberUtils.isLogin() ? <MainUseStory /> : <></>
			}

			{
				/**
				 * 리테일 NEWS & 인사공지 게시판
				 */
			}
			{
				MemberUtils.isLogin() &&
				<section className="news-section">
					<div className="inner">
						{
							getBoardKeyArr().map(value => {
								return <MainBoardContentsItem key={value} boardKey={value}/>
							})
						}
					</div>
				</section>
			}

			{
				/**
				 * 포인트 제도 & 사내 고수 제도
				 */
			}
			<div className="system-section">
				<div className="inner">
					<ul className="system-list">
						<MainSystemItem type={"point"}/>
						<MainSystemItem type={"master"} />
					</ul>
				</div>
			</div>
		</main>

	)
}
import M_ProHelpDetailCommentRow from "./M_ProHelpDetailCommentRow";
import React, {useEffect, useRef, useState} from "react";
import {CommentVO} from "../../../../../core/board/board.vo";
import {useParams} from "react-router-dom";
import ProService from "../../../../../core/pro/pro.service";
import M_BottomButton from "../../../common/M_BottomButton";
import M_ProHelpDetailCommentInput from "./M_ProHelpDetailCommentInput";

const M_ProHelpDetailComment = () => {
    const {bidx} = useParams()
    const [commentList, setCommentList] = useState<CommentVO[]>([])
    const commentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        getCommentList()
    }, []);

    const getCommentList = async () => {
        const successHandler = (result:any) => {
            setCommentList(result.data)
        }

        bidx && await ProService.helpCommentListGet(bidx, successHandler);
    }

    const updateEvent = ()=> {
        getCommentList()
    }

    const scrollToCommentInput = () => {
        if (commentRef.current) {
            commentRef.current.scrollIntoView({ block:"center"});
        }
    };


    return (
        <>
            <section className="comment-section">
                <div className="inner">
                    <div className="comment type2">
                        <div className="comment-title">
                            <h3 className="tit">댓글</h3>
                            <span className="num">{commentList.length}</span>
                        </div>
                        <M_ProHelpDetailCommentInput comment={null}
                                                     updateEvent={updateEvent}
                                                     cancelHidden={true}/>
                        <div ref={commentRef}></div>
                        <ul className="comment-list">
                            {
                                // https://github.com/innople-ebot/elandedu/issues/635
                                commentList.length > 0 && (
                                    commentList.map(value => {
                                        return (
                                            <M_ProHelpDetailCommentRow key={value.cidx}
                                                                       comment={value}
                                                                       updateEvent={updateEvent}/>
                                        )
                                    })
                                )
                            }
                        </ul>
                    </div>
                </div>
            </section>

            <M_BottomButton name={"댓글 남기기"}
                            onClick={()=>{scrollToCommentInput()}}/>
        </>


    )
}

export default M_ProHelpDetailComment
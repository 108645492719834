import {ProReqVO} from "../../../core/pro/pro.vo";
import {BoardVO} from "../../../core/board/board.vo";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import FileUtils from "../../../common/utils/file.utils";
import moment from "moment";
import ElandUtils from "../../../common/utils/eland.utils";
import StringUtils from "../../../common/utils/string.utils";

interface MainProNewContentsItemProps {
    pro: ProReqVO,
    board: BoardVO
}

const M_MainProNewContentsItem = (props: MainProNewContentsItemProps) => {
    const [pro, setPro] = useState<ProReqVO>(props.pro)
    const [board, setBoard] = useState<BoardVO>(props.board)
    const [jobCodeName, setJobCodeName] = useState("");

    const getCategory = ()=> {
        if (board.boardKey === "job_data"){
            return "직무자료"
        }

        return board.boardType === 'mov' ? "영상" : "카드뉴스"
    }

    useEffect(() => {
        (async() => {
            if (pro && pro.jobCode) {
                let jobCodeName = await ElandUtils.convertJobSkillCodesToNames(pro.jobCode)
                setJobCodeName(jobCodeName)
            }
        })()
    }, [pro]);

    return (
        <li className="swiper-slide">
            <Link to={"/board/" + board.boardKey + "/" + board.idx + "/" + StringUtils.titleUrlFilter(board.title)}>
                <div className="profile-wrap">
                    <div className="img-wrap">
                        <img src={FileUtils.getFileUrl(board.profileSrc ?? "")} alt=""
                             onError={FileUtils.onErrorImg}
                             className="img-profile"/>
                    </div>
                    <div className="text-wrap">
                        <strong className="name">{pro.companyAccountVO?.userName}</strong>
                        <span className="job">{jobCodeName}</span>
                        <div className="keyword-list">
                            <span className="keyword-span pro-new">
                                {
                                    pro.strengthKeyword.split(",").map((keyword, index) => {
                                        return '#' + keyword
                                    }).join(", ")
                                }
                            </span>
                        </div>
                    </div>
                </div>
                <div className="content-wrap">
                    <span className="cate">{getCategory()}</span>
                    <strong className="tit">{board.title}</strong>
                    <p className="desc">{board.contentText}</p>
                    <span className="date">{moment(board.createDate).format('YYYY. MM. DD')}</span>
                </div>
            </Link>
        </li>
    )
}

export default M_MainProNewContentsItem
import React from "react";
import MypageNav from "../../common/MypageNav";
import JobSkillModal from "./JobSkillModal";

const JobSkillIntro = () => {

    return(
        <main className="content job-competency nav-layout">
            <div className="common-title">
                <div className="inner">
                    <h2 className="tit">직무역량진단</h2>
                </div>
            </div>
            <section className="job-section intro">
                <div className="inner">
                    <h3 className="tit">
                        내가 생각하는 만큼 <span>멋</span><span>지</span><span>게</span> 일하려면<br/>
                        나에겐 무엇이 필요할까?
                    </h3>
                    <p className="desc">진단을 통해 내가 무엇을 잘하는지, 무엇이 부족한지 알고<br/>한 걸음 더 나아간 사내고수 선후배들과
                        지식콘텐츠를 통해 성장해 봐요!</p>
                    <button type="button" className="btn red" data-bs-toggle="modal"
                            data-bs-target="#applyModal">진단하기
                    </button>
                    <p className="guide-text">절대 평가에 반영되지 않으니 솔직하게 임해주세요.</p>
                </div>
            </section>
            <section className="job-section why">
                <div className="inner">
                    <div className="section-title">
                        <h3 className="tit">직무역량진단은 왜 하는 건가요?</h3>
                        <p className="desc">내가 지금 당장 어떤 것을 배워야 성과를 낼 수 있는지 알기 위함이에요.<br/>주변에 멋진 성과를
                            내는 사람을 떠올려 봅시다. 분명 나보다 한 걸음 나아간 부분이 있을 거에요. </p>
                    </div>
                    <div className="section-content">
                        <p>
                            이런 생각을 해본적이 있다면, 지금이 배움이 필요한 시점입니다.<br/>
                            하지만, 배움에도 도움이 필요하죠. 어떤 것부터 학습해야 할지 정하는 것부터가 어려운데요,<br/>
                            내가 부족한 부분부터 체계적으로 채워나가고 싶다면, 직무역량진단을 통해서 나에게 필요한 콘텐츠를 알 수 있어요!
                        </p>
                    </div>
                </div>
            </section>
            <section className="job-section what">
                <div className="inner">
                    <div className="section-title">
                        <h3 className="tit">직무역량진단은 무엇을 알아보는건가요?</h3>
                    </div>
                    <div className="section-content">
                        <p className="desc">
                            직무역량진단은 현재 나의 직무에서 중요한 핵심역량 중<br/>
                            내가 이미 잘 갖추고 있는 “강한 고리”와 더 개발하면 좋을 “약한 고리”를 알아보는 거에요.<br/>
                            우수 역량으로는 사내고수로 활동하며 더 강화해 나가고,<br/>
                            개발 필요 역량은 이랜드에듀에서 관련 콘텐츠와 사내고수 지식을 배우며 성장해 나갈 수 있어요.<br/>
                        </p>
                        <p className="main"><b>쉽게 학습하실 수 있도록 메인화면에서도 보여드릴게요!</b></p>
                    </div>
                </div>
            </section>
            <section className="job-section how">
                <div className="inner">
                    <div className="section-title">
                        <h3 className="tit">내 역량들을 어떻게 발전시킬 수 있나요?</h3>
                    </div>
                    <div className="section-content">
                        <div className="step-list">
                            <div>
                                <strong>강한 고리</strong>
                                <p className="step2">
                                    <span>학습 통해 더욱 강화</span>
                                </p>
                            </div>
                            <div>
                                <strong>약한 고리</strong>
                                <p className="step2">
                                    <span>추천된 지식콘텐츠 학습</span>
                                    <span>사내고수와의 1:1 에듀톡</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="banner-section">
                <div className="inner">
                    <div className="banner">
                        <span className="desc">진단 완료시 <b>1,000P</b> 지급!</span>
                        <strong className="tit">직무역량진단을 통해 나에게 필요한 역량을 찾아보세요</strong>
                        <button type="button" className="btn" data-bs-toggle="modal"
                                data-bs-target="#applyModal">지금 바로 하러가기
                        </button>
                    </div>
                    <div className="guide-text">
                        문항수 : 20~40개 *직무마다 달라요!<br/>
                        소요시간: 3~5분<br/>
                        <br/>
                        *본 진단의 결과는 평가에 반영되지 않으며 이랜드에듀 내에서 학습지원을 위한 용도로만 사용됩니다.<br/>
                        *본 진단은 반기마다 진행됩니다.<br/>
                        *이랜드에듀를 사용하는 직원이라면 필수로 진행해야 합니다.
                    </div>
                </div>
            </section>
            <JobSkillModal/>
            <MypageNav currentMenu={"jobskill"}></MypageNav>
        </main>
    )
}

export default JobSkillIntro
import React from "react";
import {BoardVO} from "../../../../core/board/board.vo";
import moment from "moment";
import FileUtils from "../../../../common/utils/file.utils";

interface BoardCardTypeRowProps {
    board: BoardVO,
    isNotice: boolean,
    isComment: boolean,
    clickEvent: (idx:number)=>void,
}

const M_BoardCardTypeRow = (props:BoardCardTypeRowProps) => {
    const {board,isNotice, clickEvent} = props

    return (
        <li>
            <a href="#" onClick={e => {
                e.preventDefault()
                clickEvent(board.idx)
            }}>
                <div className="image-wrap">
                    <div className="thumb-wrap">
                        <img src={FileUtils.getImageSrc(board.thumbFile)}
                             onError={FileUtils.onErrorBoardImg}
                             alt="" className="img-thumb"/>
                    </div>
                </div>
                <div className="text-wrap">
                    <span className="cate">{board.cateName}</span>
                    <strong className="tit">
                        {isNotice && (<span className="badge type1 red">공지</span>)}
                        {board.title}
                        {
                            board.isFile && (
                                <span className="attach"></span>
                            )
                        }
                    </strong>
                    <div className="info-wrap">
                        <span className="writer">{board.writer}</span>
                        <span className="date">{moment(board.createDate).format("YYYY-MM-DD")}</span>
                    </div>
                    <div className="rating-wrap">
                        <div className="hits">{board.views}</div>
                        {
                            props.isComment && (
                                <span className="comment">{board.commentCount}</span>
                            )
                        }
                        <span className="like">{board.likeCount}</span>
                    </div>
                </div>
            </a>
        </li>
    )
}

export default M_BoardCardTypeRow
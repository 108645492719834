import React, {useEffect, useState} from "react";
import {PageUtils} from "../../../../common/utils/page.utils";
import {BoardVO} from "../../../../core/board/board.vo";
import ProService from "../../../../core/pro/pro.service";
import {Link, useNavigate} from "react-router-dom";

const M_DashboardHelpBoard = () => {
    const navigation = useNavigate();
    const [boardList, setBoardList] = useState<BoardVO[]>([])

    useEffect(() => {
        const getBoardList = async () => {
            const successHandler = async (result:any) => {
                setBoardList(result.data.board.list)
            }

            const params = {...new PageUtils(0, 10, "", 0,"")}
            await ProService.helpListSearch(params, successHandler)
        }

        getBoardList()

    }, []);


    const getListRow = (board:BoardVO) => {
        return (
            <li key={board.idx}>
                <Link to={'/pro/help/' + board.idx} >
                    {getCateBadge(board.cateCode ?? "")}
                    <strong>{board.title}</strong>
                </Link>
            </li>
        )
    }

    const getCateBadge = (cateCode:string) => {
        switch (cateCode) {
            case "040401":
                return (<span className="badge type2 red">도움요청 </span>)
            case "040402":
                return (<span className="badge type2 green">지식나눔 </span>)
            case "040403":
                return (<span className="badge type2 purple">자유주제 </span>)
            default:
                break;
        }
    }

    return (
        <div className="box-item box7">
            <div className="box-title">
                <h3 className="tit">도와줘요 게시판</h3>
                <Link to={"/pro/help"} className="link-more">바로가기</Link>
            </div>
            <div className="box-content">
                {
                    boardList && boardList.length > 0 ? (
                        <ul className="sub-list">
                            {
                                boardList.map((board, index) => {
                                    if (index < 5){
                                        return getListRow(board)
                                    }
                                })
                            }
                        </ul>

                    ) : (
                        <div className="content-empty">
                            <p>새로운 게시글이 없습니다</p>
                        </div>

                    )
                }
            </div>
        </div>
    )
}

export default M_DashboardHelpBoard
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {BoardVO} from "../../../../core/board/board.vo";
import {BoardInfoVO} from "../../../../core/board/BoardInfo.vo";
import M_BoardBasicTypeRow from "./M_BoardBasicTypeRow";
import useHeader from "../../../../common/hook/useHeader";
import {HeaderBtn} from "../../../../core/common/header";
import useFooter from "../../../../common/hook/useFooter";

interface BoardBasicTypeProps {
    boardInfo: BoardInfoVO,
    boardList : BoardVO[],
    noticeList: BoardVO[]

}
const M_BoardBasicType = (props:BoardBasicTypeProps) => {
    const navigation = useNavigate()
    const {boardKey} = useParams()
    const [boardInfo, setBoardInfo] = useState<BoardInfoVO>(props.boardInfo)
    const [boardList, setBoardList] = useState<BoardVO[]>(props.boardList)
    const [noticeList, setNoticeList] = useState<BoardVO[]>(props.noticeList)

    useHeader({
        type:"content",
        title:boardInfo.boardName,
        buttons:[HeaderBtn.Back]
    })
    useFooter({isHidden:true})

    useEffect(() => {
        setBoardList(props.boardList)
        setNoticeList(props.noticeList)
        setBoardInfo(props.boardInfo)

    }, [props]);

    const goDetail = (idx: number) => {
        navigation('/mypage/board/'+ boardKey +'/' + idx)
    }

    return (
        <div className="content notice">
            {
                (boardList.length >= 0 || noticeList.length >= 0) ? (
                    <section className="list-section">
                        <div className="inner">
                            <div className="board-list">
                                <ul className="webzine-list type3">
                                    {
                                        noticeList.length > 0 &&
                                        noticeList.map(value => <M_BoardBasicTypeRow key={value.idx}
                                                                                     board={value}
                                                                                     clickEvent={goDetail}
                                                                                     isCategory={boardInfo.isCategory}
                                                                                     isNotice={true}
                                                                                     isComment={boardInfo.isComment}/>)
                                    }
                                    {
                                        boardList.length > 0 &&
                                        boardList.map(value => <M_BoardBasicTypeRow key={value.idx}
                                                                                    board={value}
                                                                                    clickEvent={goDetail}
                                                                                    isCategory={boardInfo.isCategory}
                                                                                    isNotice={false}
                                                                                    isComment={boardInfo.isComment}/>)
                                    }
                                </ul>
                            </div>
                        </div>
                    </section>
                ) : (
                    <section className="empty-section">
                        <div className="inner">
                            <div className="board-list">
                                <div className="content-empty">
                                    <i className="ico-content"></i>
                                    <p className="txt">
                                        현재는 공지사항이 없습니다.<br/>
                                        새로운 공지가 있으면 알려드리겠습니다.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                )
            }
        </div>
    )
}

export default M_BoardBasicType
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {setHeaderConfig} from "../redux/slices/headerSlice";
import {HeaderConfig} from "../../core/common/header";

// useHeader 훅 정의
const useHeader = (config: HeaderConfig) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const defaultConfig: HeaderConfig = {
            type: 'sub',
            title: '',
            buttons: [],
        };

        const mergedConfig = {
            ...defaultConfig,
            ...config,
            buttons: config.buttons || defaultConfig.buttons,
        };

        dispatch(setHeaderConfig(mergedConfig));
    }, [config, dispatch]);
};

export default useHeader;
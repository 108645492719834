import React, {useEffect, useState} from "react";
import MemberUtils from "../../../../common/utils/member.utils";


interface ProRequestIntroProps {
    proRate: string
}

const ProRequestIntro = (props: ProRequestIntroProps) => {
    const [proRate, setProRate] = useState<string>(props.proRate)
    const rateArr = ["NONE", "REQ", "PROGRESS", "REJECT", "PRO", "PRO_DONE"]

    useEffect(() => {
        setProRate(props.proRate)

    }, [props]);

    const getCurrent = (rate:string) => {
        const index = rateArr.indexOf(proRate);
        const index2 = rateArr.indexOf(rate);
        if (index >= index2){
            return "active"
        }

        return ""
    }

    const getCurrentMsg = () => {
        switch (proRate){
            case "REQ":
                return (
                    <>신청이 접수되었습니다.<br/>결과를 빠르게 알려드릴게요!</>
                )
            case "PROGRESS":
                return (
                    <>신청해주신 내용을 열심히 심사중이에요!<br/>결과가 나오면 안내해 드릴게요!</>
                )
            case "PRO":
                return (
                    <>축하해요! 예비고수로 선발되셨습니다!<br/>부트캠프를 통해 사내고수로 최종 임명되길 응원할게요!</>
                )
            case "PRO_DONE":
                return (
                    <>사내고수로 최종 임명되신 것을 축하드립니다!<br/>명예로운 이랜드의 지식전도사 파이팅!</>
                )
            default:
                return (<></>)
        }

    }

    const isReq= () => {
        return !(proRate === "NONE" || proRate === "REJECT")
    }

    return (
        isReq() ? (
            <section className="master-section complete">
                <div className="inner">
                    <strong className="tit">
                        {getCurrentMsg()}
                    </strong>
                    <div className="step">
                        <span className={getCurrent("REQ")}>접수</span>
                        <span className={getCurrent("PROGRESS")}>심사중</span>
                        <span className={getCurrent("PRO")}>예비고수 선발</span>
                        <span className={getCurrent("PRO_DONE")}>사내고수 최종임명</span>
                    </div>
                </div>
            </section>
        ) : (
            <section className="master-section intro">
                <div className="inner">
                    <h3 className="tit">
                        찾습니다<br/>
                        이랜드의 <span>사</span><span>내</span><span>고</span><span>수</span>
                    </h3>
                    <p className="desc">아직 사내고수가 아니시군요!<br/>지금 바로 신청하고, 사내고수에 합류하여 지식을 나누어주세요!</p>
                    <button type="button" className="btn red" data-bs-toggle="modal"
                            data-bs-target="#applyModal">사내고수 도전하기
                    </button>
                    <p className="desc">잠깐! ‘직무역량진단’을 통해 나의 강점을 확인하셨나요?<br/>아직 하지 않으셨다면, 진단하기를 먼저 한
                        후에 도전해주세요!</p>
                </div>
            </section>
        )
    )
}

export default ProRequestIntro
import React, {useEffect, useState} from "react";
import ProContentSlide from "./ProContentSlide";
import ProReview from "../../review/ProReview";
import ProEduTalkModal from "../../../pc/common/ProEduTalkModal";
import {useParams} from "react-router-dom";
import {DefaultProReqVO, ProReqVO} from "../../../../core/pro/pro.vo";
import CodeUtils from "../../../../common/utils/code.utils";
import ProProfile from "./ProProfile";
import ProService from "../../../../core/pro/pro.service";
import {BoardVO} from "../../../../core/board/board.vo";
import ElandUtils from "../../../../common/utils/eland.utils";

const ProPersonal = () => {
    const {proidx} = useParams()
    const [proReq, setProReq] = useState<ProReqVO>(DefaultProReqVO())
    const [isMe, setIsMe] = useState<boolean>(false)
    const [isFollow, setIsFollow] = useState<boolean>(false)

    const [proContentList, setProContentList] = useState<BoardVO[]>([])
    const [proContentCount, setProContentCount] = useState<number>(0)
    const [proJobDataList, setProJobDataList] = useState<BoardVO[]>([])
    const [proJobDataCount, setProJobDataCount] = useState<number>(0)

    const getProInfo = async () =>{
        const successHandler = (result:any) => {
            renderCodeAndSetPro(result.data.pro)
            setIsMe(result.data.isMe)
            setIsFollow(result.data.isFollow)
        }

        proidx && await ProService.proInfoGet(proidx, successHandler)
    }

    const getProContentAndJob = async () => {
        const successHandler = (result: any) => {
            setProContentList(result.data.know.list)
            setProContentCount(result.data.know.count)
            setProJobDataList(result.data.job.list)
            setProJobDataCount(result.data.job.count)
        }

        proidx && await ProService.proContentAndJobGet(proidx, successHandler)
    }

    const renderCodeAndSetPro = async (pro:ProReqVO) =>{
        const newProReq:ProReqVO = {...pro}
        newProReq.jobCodeName = await ElandUtils.getJobSkillCodeName(pro.jobCode)
        newProReq.skillCodeName = await Promise.all(
            pro.skillCode.split(",").map((code) => ElandUtils.getJobSkillCodeName(code))
        )

        setProReq(newProReq)
    }

    useEffect(() => {
        getProInfo()
        getProContentAndJob()
    }, []);

    return (
        <main className="content master-home">
            {proReq && <ProProfile proReq={proReq} isMe={isMe} isFollow={isFollow}></ProProfile>}
            <ProContentSlide isContent={true} contentList={proContentList} count={proContentCount}/>
            <ProContentSlide isContent={false} contentList={proJobDataList} count={proJobDataCount}></ProContentSlide>
            {
                (proReq && proReq.companyAccountVO && proReq.companyAccountVO.coEmailId !== '')
                    && <ProReview eduTalkVO={proReq.companyAccountVO}></ProReview>
            }

            <ProEduTalkModal receiver={proReq.companyAccountVO?.userName ?? ''}
                             receiverKey={proReq.companyAccountVO?.coEmailId ?? ""}
                             boardVO={null}/>
        </main>
    )
}

export default ProPersonal
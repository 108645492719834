import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import CommonJS from "../../../common/common";

const ScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        CommonJS.all()

    }, [location]);

    return null;
};

export default ScrollToTop

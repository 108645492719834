import React, {useEffect, useState} from "react";
import {BoardVO, CommentVO} from "../../../../../../core/board/board.vo";
import M_ProEduTalkCancel from "../common/M_ProEduTalkCancel";
import M_ProEduTalkBoard from "../common/M_ProEduTalkBoard";
import M_ProEduTalkReceiverReply from "./M_ProEduTalkReceiverReply";
import M_ProEduTalkReceiverInput from "./M_ProEduTalkReceiverInput";

interface ProEduTalkReceiverProps{
    boardVO: BoardVO,
    commentList: CommentVO[],
    updateEvent: () => void
}

const M_ProEduTalkReceiver = (props:ProEduTalkReceiverProps) => {

    const [boardVO, setBoardVO] = useState<BoardVO>(props.boardVO)
    const [commentList, setCommentList] = useState<CommentVO[]>(props.commentList)
    const [editMode, setEditMode] = useState<boolean>(false)

    useEffect(() => {
        setBoardVO(props.boardVO)
        setCommentList(props.commentList)

    }, [props]);

    const clickEditBtn = (isEdit:boolean) => {
        setEditMode(isEdit)
    }


    const updateEvent = () => {
        setEditMode(false)
        props.updateEvent()
    }

    const checkStatus = () => {
        switch (boardVO.statusCode) {
            case "041902":
                return "ing"
            case "041903":
                return "journal"
            case "041904":
                return "completed"
            case "041905":
                return "cancel"
            default:
                return "unresolved"
        }
    }

    const isNew = () => {
        return (checkStatus()==="unresolved") && (commentList.length <= 0)
    }

    const isCancelable = () => {
        return !(checkStatus() === "cancel") && isNew()
    }

    const getReplySection = () => {
        // 취소 화면
        if(checkStatus() === "cancel"){
            return <M_ProEduTalkCancel cancelMsg={boardVO.opt4 ?? ""}/>
        }

        // 신규의 경우 입력창만 표출
        if(isNew()) {
            return <M_ProEduTalkReceiverInput comment={null}
                                              updateEvent={updateEvent}
                                              statusCode={boardVO.statusCode ?? "041901"}/>
        }

        // 댓글 최초 답변과 기타 2개 답변 분기
        const reply = commentList[0]
        const addCommentList = commentList.filter((value, index) => index != 0)
        // 임시저장
        if (editMode || (reply.isTemporary ?? false)) {
            return <M_ProEduTalkReceiverInput comment={reply}
                                            updateEvent={updateEvent}
                                            statusCode={boardVO.statusCode ?? "041901"}/>
        }

        // 댓글 화면
        return <M_ProEduTalkReceiverReply reply={reply}
                                        commentList={addCommentList}
                                        statusCode={boardVO.statusCode ?? "041901"}
                                        updateEvent={updateEvent}
                                        editReplyEvent={clickEditBtn}
                                        journal={boardVO.opt3 ?? ""}
                                        writerKey={boardVO.writerKey}/>
    }


    return (
        <>
            <main className="content edutalk detail">
                <M_ProEduTalkBoard boardVO={boardVO}
                                   isCancelable={isCancelable()}
                                   isEditable={false}
                                   isReceive={true}
                                   updateEvent={updateEvent}/>
                { getReplySection() }
            </main>
        </>
    )
}

export default M_ProEduTalkReceiver
import React, {useEffect, useRef, useState} from "react";
import {BoardVO} from "../../../core/board/board.vo";
import MainService from "../../../core/main/main.service";
import M_CardItem from "../board/item/M_CardItem";
import type { Swiper as SwiperType } from 'swiper';
import SwiperInit from 'swiper';
import ElandUtils from "../../../common/utils/eland.utils";

const M_MainNewContents = () => {
    const [boardList, setBoardList] = useState<BoardVO[]>([]);
    const boardSwiperRef = useRef<SwiperType | null>(null);

    useEffect(() => {
        getNewContents()
    }, []);

    useEffect(() => {
        initSwiper()

    }, [boardList]);

    const initSwiper = () => {
        boardSwiperRef.current?.destroy(true, true);

        if (boardList.length > 0){
            boardSwiperRef.current = new SwiperInit("#main-new-content .board-slide", {
                slidesPerView: "auto",
                spaceBetween: 24,
            })
        }
    }

    const getNewContents = () => {
        const params = {
            size: 8,
            sort: "createDate,DESC"
        };

        console.log("getNewContents")
        const successHandler = async (result: any) => {
            console.log("getNewContents result : ", result)
            if (result.data && result.data.list.length > 0) {
                let newList = await ElandUtils.updateBoardListWithJobNames(result.data.list)
                setBoardList(newList);
            }
        }

        MainService.getNewContents(params, successHandler)
    }

    return (
        (boardList != undefined && boardList.length > 0) ?
            <section id="main-new-content" className="list-section">
                <div className="inner">
                    <div className="section-title">
                        <h3 className="tit">신규 콘텐츠</h3>
                    </div>
                    <div className="board-list board-slide">
                        <ul className="card-list type1 swiper-wrapper">
                            {
                                boardList.map((value, index) => {
                                    return (
                                        <M_CardItem key={value.idx} board={value}/>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </section> : <></>
    )
}

export default M_MainNewContents;

export interface CodeVO {

	code :string,
	codeName :string,

	codeLevel :number
	codeOrder :number

	rows :CodeVO[]

	ext1 :string
	ext2 :string
	ext3 :string
	ext4 :string
	ext5 :string

	ordering: number,

	children: CodeVO[]
}

export function DefaultCodeVO(): CodeVO {
	return {
		code: "", // 빈 문자열
		codeName: "", // 빈 문자열
		codeLevel: 0, // 0으로 초기화
		codeOrder: 0, // 0으로 초기화
		rows: [], // 빈 배열
		ext1: "", // 빈 문자열
		ext2: "", // 빈 문자열
		ext3: "", // 빈 문자열
		ext4: "", // 빈 문자열
		ext5: "", // 빈 문자열
		ordering: 0, // 0으로 초기화
		children: [], // 빈 배열
	};
}

export interface CodeFilter {
	skillCode: string;
	jobCode?: string;
	coCode: string;
}
import moment from "moment";


class DateUtils {
	
	
	/**
	 * 형식에 맞춰서 매년, 매월 형태로 표출
	 * @param _scheduleMode
	 * @param _date
	 */
	static displayEduDate(_scheduleMode:string|undefined, _date:string|undefined) {

		_scheduleMode === `fix` && moment(_date).format("")
		_scheduleMode === `month` && "매년" + moment(_date).month() + "월"
		_scheduleMode === `day` && "매월" + moment(_date).day() + "일"
		return _date;
	}

	static getStartYear(){
		const arr:number[] = [];
		const currentYear = new Date().getFullYear();  // 현재 연도
		const startYear = 1980;  // 시작 연도

		for (let i = currentYear; i >= startYear; i--){
			arr.push(i)
		}
		return arr
	}


	static formatDate(dateString: Date): string {
		return moment(dateString).format('YYYY-MM-DD');
	}


	static formatDateTime(dateString: Date): string {
		return moment(dateString).format('YYYY-MM-DD HH:mm:ss');
	}

	static formatMonthDayTime(date: Date): string {
		return moment(date).format('MM/DD HH:mm');
	}

	static formatTimeForDisplay(dateString: Date): string {
		const now = moment();
		const date = moment(dateString);

		const diffInHours = now.diff(date, 'hours');
		const diffInDays = now.diff(date, 'days');

		if (diffInHours < 24) {
			return date.format('YYYY-MM-DD HH:mm:ss');
		} else {
			return `${diffInDays}일 전`;
		}
	}
	
	/**
	 * 현재 연월일까지 표출
	 * @param _format
	 */
	static getNowDate = (_format:string|null = null) => {
		return moment(new Date()).format(_format || "YYYY.MM.DD")
	}
	
	
	/**
	 * 현재 연월일 시분포 표출
	 * @param _format
	 */
	static getNowDateTimes = (_format:string|null = null) => {
		return moment(new Date()).format(_format || "YYYY.MM.DD HH:mm:ss")
	}


	/**
	 * 1년 전 날짜 반환
	 * @param _format
	 */
	static getOneYearAgoDate = (_format: string | null = null) => {
		return moment().subtract(1, 'year').format(_format || "YYYY-MM-DD HH:mm:ss");
	}

	/**
	 * 주어진 날짜가 올해 이번 달인지 판별
	 * @param date
	 */
	static isCurrentMonth(date: string | Date): boolean {
		// 입력이 문자열일 경우 Date 객체로 변환
		if (typeof date === 'string') {
			date = new Date(date);
		}

		// 오늘 날짜 정보를 가져옴
		const today = new Date();
		const currentYear = today.getFullYear();
		const currentMonth = today.getMonth(); // 월은 0부터 시작 (0 = 1월)

		// 입력 날짜의 연도와 월을 가져옴
		const inputYear = date.getFullYear();
		const inputMonth = date.getMonth();

		// 연도와 월이 동일한지 비교
		return currentYear === inputYear && currentMonth === inputMonth;
	}

	
	static formatTime(seconds: number): string {
		const hours = Math.floor(seconds / 3600);
		const minutes = Math.floor((seconds % 3600) / 60);
		const remainingSeconds = Math.floor(seconds % 60);
		
		const formattedHours = String(hours).padStart(2, '0');
		const formattedMinutes = String(minutes).padStart(2, '0');
		const formattedSeconds = String(remainingSeconds).padStart(2, '0');
		
		if (hours > 0) {
			return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
		} else {
			return `${formattedMinutes}:${formattedSeconds}`;
		}
	}
	

}



export default DateUtils
import React, {ChangeEvent, useEffect, useImperativeHandle, useRef, useState} from "react";
import {HWMap} from "../../../core/common/common.vo";
import stringUtils from "../../../common/utils/string.utils";
import axiosCaller from "../../../common/utils/axios.caller";
import APIs from "../../../common/apis/APIs";
import StringUtils from "../../../common/utils/string.utils";
import webUtils from "../../../common/utils/web.utils";
import DateUtils from "../../../common/utils/date.utils";
import fileService from "../../../core/common/file.service";
import dateUtils from "../../../common/utils/date.utils";
import MemberUtils from "../../../common/utils/member.utils";
import {SessionVO} from "../../../core/common/session.vo";
import FileUtils from "../../../common/utils/file.utils";
import ToastUtils from "../../../common/utils/toast.utils";
import SwalUtils from "../../../common/utils/swal.utils";
import {useIsMobile} from "../../../common/hook/useMediaQuery";


const FileUploader = React.forwardRef(({params}:HWMap, ref) => {
	
	const isMobile = useIsMobile();
	const [sessionVO, setSessionVO] = useState<SessionVO | null>(MemberUtils.getSessionVO());
	
	// * 파일인풋 ID
	const [domId, setDomId] = useState<string>()
	const $filesRef = useRef<HTMLInputElement>(null)

	// 안내문구
	const [domText, setDomText] = useState<string>()
	// * 업로드 타겟 지정
	const [target, setTarget] = useState<string>()
	
	const [fidxs, setFidxs] = useState<string>();
	const [fileList, setFileList] = useState<HWMap[]>([])
	const $fileList = useRef<HWMap[]>()
	
	const [maxFileSize, setMaxFileSize] = useState(0)
	const [maxFileCount, setMaxFileCount] = useState(0)
	
	const [allowList, setAllowList] = useState<string[]>([]);
	const [denyList, setDenyList] = useState<string[]>([]);

	const [duration, setDuration] = useState(0);

	// 업로드 중 여부
	const isUploadingRef = useRef<boolean>(false)

	// 업로드 취소 여부
	const isUploadCancelRef = useRef<boolean>(false)
	
	/**
	 * 초기 params 정의
	 */
	useEffect(() => {
		setDomId(params.domId)
		setTarget(params.target)
		params.domText && setDomText(params.domText)
		params.fidxs && setFidxs(params.fidxs)
		setMaxFileSize(params.maxFileSize * 1000000)
		setMaxFileCount(params.maxFileCount)
		if ( params.allowList ) {
			setAllowList(params.allowList.toLowerCase().split(","))
		}
		if ( params.denyList ) {
			setDenyList(params.denyList.toLowerCase().split(","))
		}
	}, [])


	/**
	 * allowList, denyList 변경시마다 적용
	 */
	useEffect(() => {
		setMaxFileSize(params.maxFileSize * 1000000)
		setMaxFileCount(params.maxFileCount)
		if ( params.allowList ) {
			setAllowList(params.allowList.split(","))
		}
		if ( params.denyList ) {
			setDenyList(params.denyList.split(","))
		}
	}, [params.maxFileSize, params.maxFileCount, params.allowList, params.denyList])

	
	
	/**
	 * fidxs 를 부모 컴포넌트에서 받아왔을때
	 * fidxs 로 파일 목록 조회
	 */
	useEffect(() => {
		
		/**
		 * 파일조회후
		 * 프로그레스와 일자 재정의
		 */
		if ( target && fidxs ) {
			fileService.getFileList(target, fidxs)
				.then((result:HWMap) => {
					
					$fileList.current = result.fileList.map((item:HWMap) => {
						item.progress = 100
						item.createDate = dateUtils.formatDateTime(item.createDate)
						return item
					})
					
					if ( $fileList.current ) {
						setFileList($fileList.current)
					}
				})
		}
	
	}, [fidxs])
	
	
	
	/**
	 * 파일 선택 이벤트
	 * @param event
	 */
	const changeFile = async (event :React.ChangeEvent<HTMLInputElement>) => {
		const uploadList = event.target.files
		
		// 업로드 허용 갯수 확인
		if ( uploadList && maxFileCount > 0 && maxFileCount < (fileList.length + uploadList.length) ) {
			ToastUtils.show(`${maxFileCount} 개까지 업로드 가능합니다.`)
			//alert(`${maxFileCount} 개까지 업로드 가능합니다.`);
			event.target.value = ""
			return
		}
		
		
		// 업로드 허용 크기 확인
		if ( maxFileSize > 0 && uploadList ) {
			const uploadArray = Array.from(uploadList)
			const uploadSize = uploadArray.reduce((prev, current) => prev + current.size, 0)
			const fileSize = fileList.reduce((prev, current) => prev + current.size, 0)
			console.log("⭐uploadSize + fileSize", uploadSize + fileSize);
			if ( maxFileSize < uploadSize + fileSize ) {
				ToastUtils.show(`용량이 초과되었습니다.`)
				//alert("용량 초과");
				event.target.value = "";
				return
			}
		}
		
		
		if ( uploadList ) {
			for (let i = 0; i < uploadList.length; i++) {
				console.log("⭐ext");
				const ext = await FileUtils.getExtention(uploadList[i].name);
				
				/**
				 * allowList 우선 확인
				 */
				if ( allowList.length > 0 ) {
					
					if ( !allowList.includes(ext.toLowerCase()) ) {
						//ToastUtils.show( allowList.join(", ") + ` 확장자만 업로드 가능합니다.`)
						console.log(`⭕ ${allowList.join(", ")}  확장자만 업로드 가능합니다.`)
						SwalUtils.text({
							title : `안내`,
							message : `파일을 업로드할 수 없습니다.<br />포맷을 확인해주세요.`,
							isCancel : false,
							confirmOptions : {
								text : "확인"
							}
						})
						continue
					}
				}
				/**
				 * allowList가 없고 denyList 만 있을 경우 확인
				 */
				else if ( allowList.length <= 0 && denyList.length > 0 ) {
					
					if ( denyList.includes(ext.toLowerCase()) ) {
						//ToastUtils.show(`${uploadList[i].name} 파일은 허용되지 않는 확장자입니다.`)
						console.log(`❌ ${uploadList[i].name} 파일은 허용되지 않는 확장자입니다.`)
						SwalUtils.text({
							title : `안내`,
							message : `파일을 업로드할 수 없습니다.<br />포맷을 확인해주세요.`,
							isCancel : false,
							confirmOptions : {
								text : "확인"
							}
						})
						continue
					}
				}
				
				await doUpload(uploadList[i])
			}
			event.target.value = "";
		}
	}

	
	
	
	/**
	 * 이벤트로 선택된 파일 실제 업로드 로직
	 * @param _file
	 */
	const doUpload = async (_file:File) => {

		const chunkSize = 1024 * (1024 * 2); // 2MB
		
		const UUID = stringUtils.generateUUID()
		const file = _file
		
		// total size 계산
		const totalChunks = Math.ceil(file.size / chunkSize);
		let currentChunk = 0;

		// 업로드 상태로 변경
		isUploadingRef.current = true

		// chunk file 전송
		const sendNextChunk = async () => {
			
			// chunk size 만큼 데이터 분할
			const start = currentChunk * chunkSize;
			const end = Math.min(start + chunkSize, file.size);
			const chunk = file.slice(start, end);
			
			const fileMap:HWMap = {
				UUID : UUID,
				chunk : chunk,
				chunkNumber : currentChunk,
				totalChunks : totalChunks,
				fidx : 0,
				filename : file.name,
				filesize : file.size,
				fileext : FileUtils.getExtention(file.name),
				progress : 0,
				userId : sessionVO?.coEmailId,
				createDate : DateUtils.getNowDateTimes(),
				duration : 0
			}
			let tmpList:HWMap[] = fileList
			
			const findIndex = (_list:HWMap[], _UUID:string) => (
				_list.findIndex((item, index) => item.UUID === _UUID )
			)
			
			let currentIndex = findIndex(tmpList, fileMap.UUID)
			if ( currentIndex <= -1 ) {
				tmpList.push(fileMap)
				currentIndex = findIndex(tmpList, fileMap.UUID)
			}
			
			
			const result = await axiosCaller.postHide(APIs.FILES + `/${target}/upload`, fileMap, {
				"Content-Type" : "multipart/form-data"
			})

			if (isUploadingRef.current) {
				if (isUploadCancelRef.current) {
					isUploadCancelRef.current = false
					return
				}
			}

			if (result.statusCode === 206 || result.statusCode === 200) {
				
				//tmpList[currentIndex].progress = Math.round((result.chunkNumber + 1) / result.totalChunks * 100)
				//setFileList(tmpList)
				console.log($fileList.current)
				tmpList.forEach(tmp => {
					const newFileList = [...tmpList];
					const currentIndex = findIndex(newFileList, fileMap.UUID);
					if (currentIndex !== -1) {
						try {
							if ( newFileList[currentIndex].progress ) {
								newFileList[currentIndex].progress = Math.round((result.chunkNumber + 1) / result.totalChunks * 100);
							} else {
								newFileList[currentIndex].progress = 100
							}
							result.fileMap?.createDate && (newFileList[currentIndex].createDate = result.fileMap.createDate)
							result.fileMap?.fidx && (newFileList[currentIndex].fidx = result.fileMap.fidx)
						} catch (e) {
							console.log(e)
						}
						
					}
					$fileList.current = newFileList
					setFileList($fileList.current)
				})

				if (result.statusCode === 206) {            // 전송 결과가 206이면 다음 파일 조각 전송
					// 진행률 표시
					//resultElement.textContent = Math.round(currentChunk / totalChunks * 100) + "%"
					currentChunk++;
					if (currentChunk < totalChunks) {
						await sendNextChunk();
					}
				} else if (result.statusCode === 200) {     // 마지막 파일까지 전송 되면
					// 완료
					isUploadingRef.current = false
					webUtils.log("업로드 완료");
				}
			}
			
		};
		
		await sendNextChunk();
	}
	
	
	/**
	 * 업로드 후 fileList 갱신 후크
	 */
	useEffect(() => {
		
		if ( fileList && $filesRef.current ) {
			const _oldVal = $filesRef.current.value
			const _newVal = fileList.filter(_fileMap => !isNaN(_fileMap.fidx)).map(_fileMap => _fileMap.fidx).join(",")
			
			if ( _oldVal != _newVal ) {
				$filesRef.current.value = _newVal
				uploadHandler(_newVal);
			}
			console.log(fileList)
		}
	}, [fileList])
	
	
	/**
	 * 갱신된 fidx 값 부모 컴포넌트에 전달
	 * @param _files
	 */
	const uploadHandler = (_files:string) => {
		params.updateHandler(target, _files);
	}
	
	
	
	useImperativeHandle(ref, () => ({
		
		/**
		 * 부모 컴포넌트에서 fidxs 조회
		 */
		getFileList : ():HWMap => {
			return {
				target : target,
				fidxs : fileList.filter(_fileMap => !isNaN(_fileMap.fidx)).map(_fileMap => _fileMap.fidx).join(","),
				fileList : fileList
			}
		},
		
		/**
		 * 부모 컴포넌트에서 fidxs 지정
		 * @param _fidxs
		 */
		setFidxs : (_fidxs:string) => {
			console.log("_fidxs ::::: " + _fidxs)
			setFidxs(_fidxs)
		},

		/**
		 * 현재 업로드 상태 조회
		 */
		getUploadingStatus : () => {
            return isUploadingRef.current
        },

        /**
         * 파일 ��제
         * @param _fidx
         */
        deleteFile : async (_fidx:number) => {

            const deletFile = fileList.find(
                file => file.fidx === _fidx
            )

            if (deletFile) {
                await axiosCaller.delete(APIs.FILES + `/${target}/delete/${_fidx}`)
                const newFileList = fileList.filter(file => file.fidx!== _fidx)
                setFileList(newFileList)
            }
        },

        /**
         * ��제 확인
         * @param _fidx
         */
        deleteFileConfirm : (_fidx:number) => {
            deleteFileConfirm(_fidx)
        },
	}))
	
	
	/**
	 * 삭제 확인 안내메세지
	 * @param _fidx
	 */
	const deleteFileConfirm = (_fidx:number) => {
		
		const deletFile = fileList.find(
			file => file.fidx === _fidx
		)
		console.log(deletFile)
		
		if (deletFile) {
			SwalUtils.text({
				title: "파일삭제 안내",
				message: `<strong>${deletFile.filename}</strong> 파일을 삭제 하시겠습니까? <br/> 파일이 완전히 삭제되며, 복구가 불가능합니다.`,
				confirmOptions: {
					text: "삭제", eventHandler: async (text) => {
						console.log(text)
						await deleteFile(_fidx)
					}
				},
				cancelOptions: {
					text: "취소", eventHandler: async () => {
						console.log("취소 버튼")
					}
				}
			})
			return
		}
	}
	
	
	
	
	/**
	 * 삭제 로직
	 * @param _fidx
	 */
	const deleteFile = async (_fidx:number) => {
		setFileList([])

		if (isUploadingRef.current) {
			isUploadCancelRef.current = true
		}

		const result = await fileService.deleteFiles(target!, _fidx.toString())
		console.log(result.deleteFiles);

		// 비로그인 사용자
		(result.status === "FAIL" && result.msg === "NO_LOGIN") && alert("로그인 후 사용가능.");

		if (result.status === "SUCCESS" && result.deleteFiles) {
			let successList: HWMap[] = [];
			let failList: HWMap[] = [];
			result.deleteFiles.forEach((_item: HWMap) => {
				if (_item.status === "FAIL") {
					_item.msg === "NO_MATCH_USERID" && failList.push(_item);
				} else {
					successList.push(_item)
				}
			})

			// 삭제 실패한 파일 목록 표출
			const failes = failList.map((_item: HWMap) => _item.filename).join(", ")
			failes && console.log(failes + "사용자가 달라서 삭제 불가능")

			// 삭제된 파일 fidxs 에서 지우고 갱신
			const successIdxs = successList.map((_item: HWMap) => _item.fidx.toString())

			const remainIdxs = typeof fidxs === 'string'
				? fidxs.split(",").filter(_fidx => !successIdxs.includes(_fidx)).join(",") : '';
			setFidxs(remainIdxs)

			// fileList 에 지워진 파일의 목록을 삭제
			/*setFileList(prevState =>
				prevState.filter(
					file => !result.deleteFiles.some((deleteFile: HWMap) => deleteFile.fidx === file.fidx)
				)
			)*/

			let filteredFileList = fileList.filter(
				file => !result.deleteFiles.some((deleteFile: HWMap) => deleteFile.fidx === file.fidx)
			)

			setFileList(
				[]
			)
		}
	}

	return (
		<>
			<div className="inp-btn-bind file-upload">
				<input type="file" name="" id="" className="inp-file"/>
				<input type="text" name="" id="" className="inp-text inp-upload" placeholder={domText} readOnly={true} />
				<input type="text" ref={$filesRef} id={`${domId}-files`}
					   style={{display: "none"}}
					   className="inp-text inp-upload"
					   placeholder={`파일번호 목록`} onChange={(e) => uploadHandler(e.target.value)}/>
				<input type={`file`} id={domId} name={domId} style={{display: `none`}} multiple={true}
					   onChange={(e) => changeFile(e)}/>
				<button type="button" className="btn gray btn-upload"
						onClick={() => document.getElementById(`${domId}`)?.click()}>
					{ isMobile || `첨부파일 찾기` }
					{ isMobile && <span className="blind">첨부파일 찾기</span> }
				</button>
			</div>
			{/*<div className="guide-text" dangerouslySetInnerHTML={{__html:domText || ""}}></div>*/}
			{
				fileList && fileList.length > 0 && (
					<ul className="file-list file-uploader">
					{
						fileList?.map((fileMap, f_index) => (
							<li key={f_index}>
								<div className="text-wrap">
									<strong className="tit">{fileMap.filename}</strong>
									<div className="info">
										<span>{fileMap.createDate}</span>
										<span>{StringUtils.formatBytes(fileMap.filesize)}</span>
									</div>
								</div>
								<div
									className={`progress-bar ${fileMap.progress >= 100 && 'complete'}`}>
									<span
										style={{width: `${fileMap.progress}%`}}>{fileMap.progress}%</span>
								</div>
								<button type="button" className="btn-delete"
										onClick={() => deleteFileConfirm(fileMap.fidx)}></button>
							</li>
						))
					}
					</ul>
				)
			}
		</>
	)
})

FileUploader.displayName = `fileUploader`
export default FileUploader
import React, {useEffect, useState, useCallback} from "react";
import {ReviewListItemVO} from "../../../core/review/review.vo";
import {CodeVO} from "../../../core/common/code.vo";
import CommonJS from "../../../common/common";
import ReviewService from "../../../core/review/review.service";
import {HWMap} from "../../../core/common/common.vo";
import {PageUtils} from "../../../common/utils/page.utils";
import M_StarRating from "./M_StarRating";
import {M_ReviewType} from "./M_ReviewType";
import MemberUtils from "../../../common/utils/member.utils";
import StringUtils from "../../../common/utils/string.utils";
import {ReviewVO} from "../../../core/review/ReviewVO";

interface AudienceReviewItemProps {
    itemVO: ReviewListItemVO
    type: M_ReviewType
    onClickUpdate: (review: ReviewVO) => void;
    onLoad: () => void
}

// M_AudienceReview 아이템
const M_AudienceReviewItem = (props: AudienceReviewItemProps)=>{
    const { itemVO, type, onClickUpdate, onLoad } = props;

    const [params, setParams] = useState<HWMap>(new PageUtils())

    // 한줄평 텍스트
    const [reviewText, setReviewText] = useState("");

    // 본인 확인
    function isAuthorize() {
        return MemberUtils.getSessionVO()?.coEmailId == itemVO.review?.writer
    }

    // 코드 목록에서 배지를 렌더링하는 함수
    function renderBadgesFromCodeVOList(codeVOList?:[CodeVO]) {
        if (!codeVOList) {
            return null;
        }

        return codeVOList.map((codeVO, index) => getBadgeTag(codeVO, index));
    }

    // 키워드 가져오기
    function getBadgeTag(codeVO:CodeVO, index:number) {
        // 뒤에서 두 번째 문자 추출
        const secondToLastChar = codeVO.code.charAt(codeVO.code.length - 2);

        // 숫자로 변환
        const number = parseInt(secondToLastChar, 10);
        let className = "badge type1 "

        if (number % 10 === 0 || number % 9 === 0) {
            className += "red"
        } else if (number % 8 === 0 || number % 7 === 0) {
            className += "blue"
        } else if (number % 6 === 0 || number % 5 === 0) {
            className += "orange"
        } else if (number % 4 === 0 || number % 3 === 0) {
            className += "green"
        } else {
            className += "purple"
        }

        return <span key={index} className={className}>{codeVO.codeName}</span>;
    }

    // itemVO가 변경되면,
    useEffect(() => {
        if (itemVO && itemVO.review) {
            setReviewText(itemVO.review.title);
        }
    }, [itemVO]);

    useEffect(() => {
        if (itemVO.review) {
            onLoad();
        }
    }, []);

    return (
        <li>
            <div className="comment-item">
                <span className="name">{itemVO.companyAccount && itemVO.companyAccount.userName}</span>
                <div className="star-rating">
                    <div className="star">
                        {
                            itemVO.review ? (
                                <M_StarRating totalStars={5} rating={ itemVO.review && itemVO.review.rating}/>
                            ) : <></>
                        }
                    </div>
                    <b className="score">{itemVO.review && itemVO.review.rating}</b>
                </div>
                <p className="text-area" dangerouslySetInnerHTML={{__html: StringUtils.nl2br(itemVO.review?.title ?? "")}}></p>
                <span className="date">{itemVO.review && ReviewService.changeDateFormat(itemVO.review.createDate)}</span>
                <div className="util">
                    { isAuthorize() ? (
                        <>
                            <a className="btn-modify" onClick={()=> {
                                if (itemVO.review) {
                                    onClickUpdate(itemVO.review)
                                }
                            }}><span className="blind">수정하기</span></a>
                            {/*<a className="btn-delete" onClick={doDelete}><span className="blind">삭제하기</span></a>*/}
                        </>
                    ) : (
                        <> </>
                    )}
                </div>
            </div>
        </li>
    )
}

export default M_AudienceReviewItem
import React, {MutableRefObject, useEffect, useRef} from 'react';
import { Chart } from "../../../assets/libs/chart.js/chart.min";
import {EduMainVO} from "../../../core/education/education.vo";
import StringUtils from "../../../common/utils/string.utils";
import {Link} from "react-router-dom";
import moment from "moment/moment";
import educationService from "../../../core/education/education.service";
import {HWMap} from "../../../core/common/common.vo";

interface MainEduStatusChartItemProps {
    item?: EduMainVO
}

const MainEduStatusChartItem = (props: MainEduStatusChartItemProps) => {
    const {item} = props;

    // @ts-ignore
    const chartRef: MutableRefObject<Chart | null> = useRef(null);

    useEffect(() => {

    }, []); // 빈 종속성 배열

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.destroy();
        }

        if (item) {
            /* Progress Chart */
            const totalValue1 = item.contentCount;
            const progressValue1 = item.answerCount;
            Chart.getChart(`ProgressCourse-${item.emIdx}`) && Chart.getChart(`ProgressCourse-${item.emIdx}`).destroy()
            chartRef.current = new Chart(`ProgressCourse-${item.emIdx}`, {
                type: 'doughnut',
                data: {
                    labels: ["Course"],
                    datasets: [
                        {
                            data: [totalValue1 - progressValue1, progressValue1],
                            backgroundColor: [
                                "#F0F2F6",
                                "#1847ED"
                            ],
                            cutout: "75%",
                        },
                    ],
                },
                options: {
                    plugins: {
                        legend:{
                            display: false
                        },
                        tooltip: {
                            enabled: false
                        },
                    },
                }
            });
        }
    }, [item]);
    
    
    let scheduleTxt:HWMap = {}
    if ( item ) {
        scheduleTxt = educationService.getScheduleText( item );
    }
    
    
    return (
        <li>
            <div className="chart">
                <canvas className={`charts`} id={`ProgressCourse-${item?.emIdx}`}></canvas>
                <span className="rate">{item?.answerCount ?? 0}/{item?.contentCount ?? 0}</span>
            </div>
            <div className="edu-info">
                <Link to={"/education/" + (item?.emIdx ?? "") + `/0/${StringUtils.titleUrlFilter(item?.eduTitle ?? "") }`}>
                    <span className="badge type1 red">필수교육</span>
                    <strong className="tit">{item?.eduTitle ?? ""}</strong>
                    <span className="date">{scheduleTxt?.startDateTxt} ~ {scheduleTxt?.endDateTxt}</span>
                    <span className="point">{item?.point ?? ""}P</span>
                </Link>
            </div>
        </li>
    )
}

export default MainEduStatusChartItem;
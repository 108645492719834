import React, {useEffect} from "react";
import {BoardVO} from "../../../../core/board/board.vo";
import DateUtils from "../../../../common/utils/date.utils";
import APIs from "../../../../common/apis/APIs";
import CodeUtils from "../../../../common/utils/code.utils";
import {SessionVO} from "../../../../core/common/session.vo";
import M_SummernoteIframe from "../../../mobile/common/M_SummernoteIframe";
import FileUtils from "../../../../common/utils/file.utils";
import {Link} from "react-router-dom";
import ElandUtils from "../../../../common/utils/eland.utils";
import MemberUtils from "../../../../common/utils/member.utils";


const M_BasicDetail: React.FC<{boardVO: BoardVO, sessionVO: SessionVO | null, tags: string[]}> = ({boardVO, sessionVO, tags}) => {

    const [jobName, setJobName] = React.useState<string>("");
    const [coName, setCoName] = React.useState<string>("");

    useEffect(() => {
        if(boardVO.idx > 0) {
            (async() => {
                const jobCodeName = await ElandUtils.convertJobSkillCodesToNames(boardVO.jobCode);
                setJobName(jobCodeName);

                const coNames = await CodeUtils.getCoCodeNames(boardVO.coCode)
                setCoName(coNames);

            })()
        }
    }, [boardVO]);


    return (
        <section className="detail-section border-10">
            <div className="inner">
                <div className="detail-title">
                        <span className="cate">
                            <a href="#">{ coName }</a>
                            <a href="#">{ jobName }</a>
                        </span>
                    <h2 className="tit">{ boardVO.title }</h2>
                    <div className="info">
                        <span className="date">{ DateUtils.formatDate(boardVO.createDate) }</span>
                        <div className="rating-wrap">
                            <span className="hits">{ boardVO.views }</span>
                        </div>
                    </div>
                    {
                        boardVO.writerInfo &&
                        <div className="writer">
                            <div className="image-wrap">
                                <img src={FileUtils.getFileUrl(boardVO?.writerInfo?.profileSrc ?? "")}
                                     onError={FileUtils.onErrorImg}
                                     alt="프로필 이미지"
                                     className="img-profile"/>
                            </div>
                            <div className="text-wrap">
                            <span className="name">
                                <b>{boardVO.writer}</b>
                                {MemberUtils.isProRateCode(boardVO.writerInfo.rateCode) && " 사내고수"}
                            </span>
                                <span className="job">{boardVO.writerInfo.coInfoVO?.coName}</span>
                            </div>
                        </div>
                    }


                    {/* 작성자만 수정 / 삭제 가능 ::: 적용스토리만 삭제가능 */}
                    {/*{*/}
                    {/*    sessionVO?.coEmailId == boardVO.writerKey && */}
                    {/*    <div className="util">*/}
                    {/*        <a href="#" className="btn-modify"><span className="blind">수정하기</span></a>*/}
                    {/*        <a href="#" className="btn-delete"><span className="blind">삭제하기</span></a>*/}
                    {/*    </div>*/}
                    {/*}*/}

                </div>
                <div className="detail-content detail-bottom">
                    <ul className="attach-list">
                        {
                            boardVO.attachList?.map((file, index) => (
                                file.fileMode === 'attach' && file.fidx != boardVO.thumbIdx &&
                                <li key={index} className="attach">
                                    <b className="tit">첨부파일 </b>
                                    <a href={`${APIs.BOARD_FILES_DOWN}${file.fidx}`}>
                                        <span className="path">{file.filename}</span></a>
                                </li>
                            ))
                        }
                    </ul>
                    <div className="text-area">
                        <M_SummernoteIframe mode={"view"} htmlCode={boardVO.contentHtml}/>
                    </div>


                    {
                        tags &&
                        <div className="tag-list">
                            {tags?.map((tag, index) => (
                                <React.Fragment key={index}>
                                    <Link to={`/search/${tag}`}>#{tag}</Link>
                                    {index < tags.length - 1 ? ', ' : ''}
                                </React.Fragment>
                            ))}
                        </div>
                    }

                </div>
            </div>
        </section>
    );
}

export default M_BasicDetail;
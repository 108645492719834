import React, {useEffect, useRef, useState} from "react";
import M_ProRecommendCard from "../pro/find/M_ProRecommendCard";
import {ProReqVO} from "../../../core/pro/pro.vo";
import proService from "../../../core/pro/pro.service";
import type { Swiper as SwiperType } from 'swiper';
import SwiperInit from 'swiper';

const M_MainProRecommend = () => {
    const [recommendList, setRecommendList] = useState<ProReqVO[]>([]);
    const swiperRef = useRef<SwiperType | null>(null);

    useEffect(() => {
        const successHandler = (result: any) => {
            setRecommendList(result.data);
        }

        proService.proRecommendListGet({size: 8}, successHandler)
    }, []);

    useEffect(() => {
        initSwiper()
    }, [recommendList]);

    const initSwiper = () => {
        swiperRef.current?.destroy(true, true);

        if (recommendList.length > 0){
            swiperRef.current = new SwiperInit("#main-pro-recommend .board-slide", {
                slidesPerView: "auto",
                spaceBetween: 24,
            });
        }
    }


    return (
        <section id="main-pro-recommend" className="list-section">
            <div className="inner">
                <div className="section-title">
                    <h3 className="tit">추천 사내고수</h3>
                </div>
                <div className="board-list board-slide">
                    <ul className="card-list type3 swiper-wrapper">
                        {
                            recommendList.map((value, index) => {
                                return <M_ProRecommendCard key={index} {...value}/>
                            })
                        }
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default M_MainProRecommend
import React, {useEffect, useState} from "react";
import {CommentVO} from "../../../../core/board/board.vo";
import moment from "moment";
import MypageService from "../../../../core/mypage/mypage.service";
import StringUtils from "../../../../common/utils/string.utils";
import ToastUtils from "../../../../common/utils/toast.utils";
import SwalUtils from "../../../../common/utils/swal.utils";
import swalUtils from "../../../../common/utils/swal.utils";

interface BoardDetailCommentRowProps {
    comment: CommentVO
    updateEvent: ()=>void
}

const M_BoardDetailCommentRow = (props:BoardDetailCommentRowProps) => {
    const [comment, setComment] = useState<CommentVO>(props.comment)

    useEffect(() => {
        setComment(props.comment)

    }, [props]);


    const showDeletePopup =  () => {
        swalUtils.text({
            title: "삭제하기",
            message: "<p>정말 댓글을 삭제하시겠습니까?</p>",
            isCancel: true,
            confirmOptions:{
                text: "삭제",
                eventHandler: deleteComment
            },
            cancelOptions:{
                text: "취소"
            }
        })
    }

    const deleteComment = async () => {
        const successHandler = () => {
            ToastUtils.show("댓글이 삭제되었습니다")
            props.updateEvent()

        }
        await MypageService.commentOneDelete(comment.cidx, successHandler)
    }

    const showUpdatePopup = () => {
        SwalUtils.textarea({
            title:"댓글",
            message:"",
            isIcon:false,
            inputValue: comment.contentText ?? "",
            inputPlaceholder:"댓글을 입력해주세요",
            isCancel:true,
            confirmOptions:{
                text: "수정",
                eventHandler: updateComment
            },
            cancelOptions:{
                text: "취소"
            }
        })
    }

    const updateComment = (newCommentContent: string) => {
        if (StringUtils.isEmpty(newCommentContent)){
            ToastUtils.show('내용을 입력해주세요.')
            return
        }

        const formData = new FormData();
        formData.append('cidx', comment.cidx.toString())
        formData.append('bidx', comment.bidx?.toString() ?? "0")
        formData.append('pidx', comment.pidx.toString())
        formData.append('contentText', newCommentContent)

        const successHandler = () => {
            ToastUtils.show('수정되었습니다.')
            props.updateEvent()
        }

        MypageService.commentSaveOrUpdate(formData, successHandler)
    }

    return (
        <li>
            <div className="comment-item">
                <span className="name">{comment.writer}</span>
                <p className="text-area"
                   dangerouslySetInnerHTML={{__html: StringUtils.nl2br(comment.contentText ?? "")}}></p>
                <span className="date">{moment(comment.createDate).format("YYYY.MM.DD")}</span>
                {
                    comment.me && (
                        <div className="util">
                            <a href="#" className="btn-delete" onClick={showDeletePopup}>
                                <span className="blind">삭제하기</span>
                            </a>
                            <a href="#" className="btn-modify" onClick={showUpdatePopup}>
                                <span className="blind">수정하기</span>
                            </a>
                        </div>
                    )
                }
            </div>
        </li>
    )
}

export default M_BoardDetailCommentRow
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {HeaderConfig} from "../../../core/common/header";


const initialState: HeaderConfig = {
    type: 'sub',
    title: '',
    buttons: [],
};

const headerSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        // 상태를 설정하는 리듀서
        setHeaderConfig(state, action: PayloadAction<HeaderConfig>) {
            return action.payload; // 전달된 상태로 설정
        },
    },
});

// 액션과 리듀서 내보내기
export const { setHeaderConfig } = headerSlice.actions;

export default headerSlice.reducer;
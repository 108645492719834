import React, {useEffect, useState} from "react";
import PointService from "../../../../core/point/point.service";
import useHeader from "../../../../common/hook/useHeader";
import {HeaderBtn} from "../../../../core/common/header";
import useFooter from "../../../../common/hook/useFooter";
import NumberFormatter from "../../../pc/common/NumberFormatter";
import PointChart from "../../../pc/mypage/point/PointChart";
import M_PointList from "./M_PointList";


const M_MyPoint : React.FC = () => {

    useHeader({
        type: 'content',
        title: '학습포인트',
        buttons: [HeaderBtn.Back, HeaderBtn.Menu],
    });
    useFooter({
        isHidden: false
    });

    const [points, setPoints] = useState({
        coursePoint: 0,
        knowledgePoint: 0,
        proPoint: 0,
        etcPoint: 0,
        eduTotalPoint: 0,
        eventTotalPoint: 0,
        calculateTotalPoints: 0,
    });


    useEffect(() => {

        (async() => {

            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth();
            const quarter = Math.floor(month / 3) + 1
            const months = [
                "1,2,3", // 1분기
                "4,5,6", // 2분기
                "7,8,9", // 3분기
                "10,11,12"  // 4분기
            ];

            const result = await PointService.getAccountPointDetail({searchYear: year, searchMonth: months[quarter - 1]});
            console.log("⭐result", result)  ;
            if(result.status == "SUCCESS" && result.data) {
                const { category, pointType } = result.data;

                const updatedPoints = { ...points };

                category?.forEach((item: any) => {
                    switch (item.cateCode) {
                        case '300101':
                            updatedPoints.coursePoint = item.totalPoints;
                            break;
                        case '300102':
                            updatedPoints.knowledgePoint = item.totalPoints;
                            break;
                        case '300103':
                            updatedPoints.proPoint = item.totalPoints;
                            break;
                        case '300104':
                            updatedPoints.etcPoint = item.totalPoints;
                            break;
                    }
                });

                pointType?.forEach((item: any) => {
                    switch (item.pointType) {
                        case 'EVENT':
                            updatedPoints.eventTotalPoint = item.totalPoints;
                            break;
                        case 'EDU':
                            updatedPoints.eduTotalPoint = item.totalPoints;
                            break;
                        case 'CALCULATE':
                            updatedPoints.calculateTotalPoints = item.totalPoints;
                            break;
                    }
                });

                setPoints(updatedPoints);

            } else {
                console.log("point 호출 살패", result.msg);
            }
        })()
    }, []);


    return (
        <div className="content point">
            <section className="chart-section">
                <div className="inner">
                    <div className="total">
                        <span>현재 포인트</span>
                        <b><NumberFormatter number={points.eduTotalPoint + points.eventTotalPoint + points.calculateTotalPoints}/> P</b>
                    </div>

                    {/* 포인트 차트 */}
                    {
                        points &&
                        <PointChart
                            course={points.coursePoint}
                            knowledge={points.knowledgePoint}
                            pro={points.proPoint}
                            etc={points.etcPoint}
                        />
                    }

                    <ul className="detail-list">
                        <li>
                            <span className="txt">코스학습</span>
                            <b className="num"><NumberFormatter number={points.coursePoint}/></b>
                        </li>
                        <li>
                            <span className="txt">지식학습</span>
                            <b className="num"><NumberFormatter number={points.knowledgePoint}/></b>
                        </li>
                        <li>
                            <span className="txt">사내고수</span>
                            <b className="num"><NumberFormatter number={points.proPoint}/></b>
                        </li>
                        <li>
                            <span className="txt">기타활동</span>
                            <b className="num"><NumberFormatter number={points.etcPoint}/></b>
                        </li>
                    </ul>
                </div>
            </section>

            {/* 포인트 상세 리스트 */}
            <M_PointList />
        </div>
    )
}

export default M_MyPoint;


import React, {useEffect, useState, useCallback} from "react";
import M_Pagination from "../common/M_Pagination";
import {HWMap} from "../../../core/common/common.vo";
import {PageUtils} from "../../../common/utils/page.utils";
import {ReviewListItemVO} from "../../../core/review/review.vo";
import ReviewService from "../../../core/review/review.service";
import M_AudienceReviewItem from "./M_AudienceReviewItem";
import CommonJS from "../../../common/common";
import M_StarRating from "./M_StarRating";
import {M_ReviewType} from "./M_ReviewType";
import PointService from "../../../core/point/point.service";
import MemberUtils from "../../../common/utils/member.utils";
import M_ReviewBottomView from "./M_ReviewBottomView";
import M_ReviewModal from "./M_ReviewModal";
import {ReviewVO} from "../../../core/review/ReviewVO";
import CommonMobileJS from "../../../common/common_m";

interface AudienceReviewProps {
    bidx?: number
    emIdx?: number
    type: M_ReviewType
    isBorder?: boolean
}

/*
    일반 한줄평
    http://khane7.test-site.kr/elandedu_m/html/3_%EC%A7%80%EC%8B%9D%EC%BD%98%ED%85%90%EC%B8%A0/3-3_%EC%A7%81%EB%AC%B4%EC%9E%90%EB%A3%8C%EC%8B%A4_%EC%83%81%EC%84%B8.html
*/
const M_AudienceReview = (props: AudienceReviewProps) => {
    const {emIdx, bidx, type, isBorder = true} = props;
    const [params, setParams] = useState<HWMap>(new PageUtils(0, 5))
    const [totalCount, setTotalCount] = useState<number>(0)
    // 게시판에 해당하는 평점
    const [boardRating, setBoardRating] = useState<number>(0)

    // 한줄평 리스트
    const [reviewList, setReviewList] = useState<ReviewListItemVO[]>([])

    // 개인이 쓴 평점
    const [rating, setRating] = useState<number>(5)

    // 개인이 쓴 한줄평 텍스트
    const [reviewText, setReviewText] = useState("");

    // 한줄평 작성 가능 여부
    const [isDisabled, setIsDisabled] = useState(false);

    // 리뷰 불러오기
    const getReviewList = async () => {
        if (type && ((emIdx && emIdx > 0) || (bidx && bidx > 0))) {
            console.log("getReviewList 요청 완료")
            const result = await ReviewService.getReviewList(params);
            console.log("getReviewList 반환 완료 : ", result)
            if (result.status === 'SUCCESS') {
                setTotalCount(result.count);
                setBoardRating(Math.round(result.rating * 10) / 10)
                setReviewList(result.list);
                setIsDisabled(result.isExistMyReview);
            }
        }
    }

    // 페이지 누를 때 업데이트하는 함수
    const updatePages = (page: number) => {
        setParams((params) => ({
            ...params,
            page: page,
        }));
    };

    const updateParams = () => {
        let _params = params
        switch (type) {
            case M_ReviewType.BOARD:
                _params.bidx = bidx;
                _params.type = M_ReviewType.BOARD;
                break;
            case M_ReviewType.EDUCATION:
                _params.emIdx = emIdx;
                _params.type = M_ReviewType.EDUCATION;
                break;
        }
        setParams(_params)
    }

    const openModal = () => {
        console.log("openModal")
        const texrareaStarAlert = document.getElementById("texrareaStarAlert");

        if (texrareaStarAlert) {
            texrareaStarAlert.style.display = 'block'
        }
    };

    // 한줄평 등록
    const register = async () => {
        let btnSelectClass = document.getElementsByClassName("star-drag");
        let starClass = btnSelectClass[0].getElementsByClassName("check");
        let rating = starClass.length;

        let _params = params
        switch (type) {
            case M_ReviewType.BOARD:
                _params.bidx = bidx;
                _params.type = M_ReviewType.BOARD;
                break;
            case M_ReviewType.EDUCATION:
                _params.emIdx = emIdx;
                _params.type = M_ReviewType.EDUCATION;
                break;
        }
        _params.writer = MemberUtils.getSessionVO()?.coEmailId;
        _params.rating = rating;
        _params.title = reviewText;

        const result = await ReviewService.setReview(_params);
        if (result.status === 'SUCCESS') {
            // 포인트 적립
            switch (type) {
                case M_ReviewType.BOARD:
                    await PointService.savePoint("COMMENT_POST", bidx)
                    break;
                case M_ReviewType.EDUCATION:
                    await PointService.savePoint("COURSE_COMMENT", emIdx)
                    break;
            }

            setRating(5);
            setReviewText("");

            location.reload()
        }
    }

    const onCancel = () => {
        console.log("onCancel")

        setRating(5);
        setReviewText("");
    }

    const onClear = () => {
        setRating(5);
        setReviewText("");
    }

    // 본인 확인
    function isAuthorize(itemVO: ReviewListItemVO) {
        return MemberUtils.getSessionVO()?.coEmailId == itemVO.review?.writer
    }

    // 초기화
    useEffect(() => {
        updateParams()
        CommonMobileJS.starDrag()
    }, []);

    useEffect(() => {
        // 새로 불러올 때마다 한줄평 정보 초기화
        onClear()

        if (type && ((emIdx && emIdx > 0) || (bidx && bidx > 0))) {
            updateParams()
            getReviewList()
        }

    }, [params, emIdx, bidx]);

    return (
        <>
            <section className={`comment-section ${isBorder && "border-10"}`} style={{paddingBottom: '40px'}}>
                <div className="inner">
                    <div className="comment type1">
                        <div className="comment-title">
                            <h3 className="tit">한줄평</h3>
                            <span className="num">총 {totalCount}개</span>
                        </div>
                        <p className="point-guide">한줄평 작성시 <span>100P</span> 가 지급됩니다 </p>
                        <div className="comment-rating">
                            <div className="star-rating">
                                <span className="txt">평점</span>
                                <div className="star">
                                    <M_StarRating totalStars={5} rating={boardRating}/>
                                </div>
                                <b className="score">{boardRating}</b>
                            </div>
                        </div>
                        {/* 리스트 없을 때 */}
                        {reviewList.length === 0 ? (
                            <div className="comment-empty">
                                <p>
                                    아직 작성된 한줄평이 없습니다.<br/>
                                    한줄평 작성하고 100포인트 받아가세요~
                                </p>
                            </div>
                        ) : (
                            <>
                                <ul className="comment-list">
                                    {reviewList.map((review, index) => (
                                        <M_AudienceReviewItem key={index} itemVO={review} type={type}
                                                              onClickUpdate={(review) => {
                                                                  setReviewText(review.title)
                                                                  setRating(review.rating)
                                                                  openModal()
                                                              }}
                                                              onLoad={() => {
                                                                  if (review.review && isAuthorize(review)) {
                                                                      setReviewText(review.review.title)
                                                                      setRating(review.review.rating)
                                                                      console.log("onLoad")
                                                                  }
                                                              }}></M_AudienceReviewItem>
                                    ))}
                                </ul>

                                <M_Pagination page={params.page} totalCount={totalCount} updatePages={updatePages} size={5}/>
                            </>
                        )}
                    </div>
                </div>
            </section>

            <M_ReviewBottomView type={M_ReviewType.BOARD} bidx={bidx}/>
            <M_ReviewModal rating={rating} reviewText={reviewText} setReviewText={setReviewText} onRegister={register} />
        </>
    );
}

export default M_AudienceReview;
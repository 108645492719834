import React, {useState} from "react";
import {useParams} from "react-router-dom";
import KnowledgeList from "./list/KnowledgeList";
import JobDataList from "./list/JobDataList";


const SearchResult: React.FC = () => {
    let {searchText} = useParams();

    const [knowledgeTotalCount, setKnowledgeTotalCount] = useState(0);
    const [jobDataTotalCount, setJobDataTotalCount] = useState(0);

    const totalCount = knowledgeTotalCount + jobDataTotalCount;



    return (
        <main className="content search-result">

            <div className="common-title">
                <div className="inner">
                    <h2 className="tit"><span>{searchText}</span>에 대한 <span>{totalCount}</span>건의 검색결과</h2>
                    <p className="desc">사내고수는 [사내고수-고수찾기] 메뉴를 이용해주세요. 일반게시판 및 공지사항은 마이페이지에서 확인하실 수 있습니다.</p>
                </div>
            </div>

            {/*{knowledgeTotalCount == 0 && jobDataTotalCount == 0 && <M_NullItem />}*/}

            {/* 지식콘텐츠 검색 결과 */}
            <KnowledgeList searchText={searchText || ''}  setTotalCount={setKnowledgeTotalCount} />

            {/* 직무자료실 검색 결과 */}
            <JobDataList searchText={searchText || ''} setTotalCount={setJobDataTotalCount} />

        </main>
    )
}

export default SearchResult;
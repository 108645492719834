import React, {useEffect, useState} from "react";
import StringUtils from "../../../../../common/utils/string.utils";
import JobSkillService from "../../../../../core/jobskill/jobskill.service";

interface JobSkillResultMentoringProps{
    jobName: string,
    strengths: string,
    weaknesses: string
}

const JobSkillResultMentoring = (props:JobSkillResultMentoringProps) => {
    const initMsg = "멘토에게 물어보는 중입니다... 잠시만 기다려 주세요 ☺️"
    const errorMsg = "AI 멘토링을 준비중에 있어요 😊 곧 다시 만나요!"
    const lastMsg = "<br/><br/><span style='color: var(--gray-50)'>✨ ELAND AI가 생성한 직무역량진단 멘토링 입니다</span>"
    const [aiMentoring, setAiMentoring] = useState<string>(initMsg)

    useEffect(() => {
        if (!StringUtils.isEmpty(props.jobName) && !StringUtils.isEmpty(props.strengths) && !StringUtils.isEmpty(props.weaknesses)){
            getMentoring()
        }

    }, [props]);

    const getMentoring = () => {
        let params = {
            jobName: props.jobName,
            strengths: props.strengths,
            weaknesses: props.weaknesses
        }

        // https://platform.openai.com/docs/guides/error-codes/api-errors
        // errorCode는 위에서 확인
        const successHandler = (result:any) => {
            if (result.data.statusCode === 200){
                let mentoringStr = result.data.detail
                let refineStr = mentoringStr.replace("강점강화 : ", "").replace("약점보완 : ", "") + lastMsg
                setAiMentoring(refineStr)
            } else {
                setAiMentoring(errorMsg + "(K)")
            }
        }

        const failureHandler = (result:any) => {
            setAiMentoring(errorMsg + "(E)")
        }

        const errorHandler = (error:any) => {
            setAiMentoring(errorMsg + "(S)")
        }

        JobSkillService.jobSkillAIMentoringGet(params, successHandler, failureHandler, errorHandler)

    }

    return (
        <section className="result-section mentor">
            <div className="inner">
                <div className="section-title">
                    <h3 className="tit">AI 멘토링</h3>
                </div>
                <div className="section-content">
                    <div className="text-area"
                         dangerouslySetInnerHTML={{__html: StringUtils.nl2br(aiMentoring ?? "")}}>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default JobSkillResultMentoring
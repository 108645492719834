import React, {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import {BoardInfoVO} from "../../../../core/board/BoardInfo.vo";
import {DefaultBoardInfoVO} from "../../../../core/board/board.vo";
import BoardService from "../../../../core/board/board.service";
import StringUtils from "../../../../common/utils/string.utils";

const ProHelp = () => {
    const [boardInfo, setBoardInfo] = useState<BoardInfoVO>(DefaultBoardInfoVO());

    useEffect(() => {
        getBoardInfo()

    }, []);

    const getBoardInfo = async () => {
        let params = {boardKey: 'pro_help'}
        let result = await BoardService.getBoardInfoOne(params);
        if (result.status === 'ok'){
            setBoardInfo(result.boardInfoVO);
        }
    }

    return (
        <main className="content help register">
            <div className="common-title">
                <div className="inner">
                    <h2 className="tit">{boardInfo.boardName}</h2>
                    <p className="desc" dangerouslySetInnerHTML={{__html: StringUtils.nl2br(boardInfo?.description ?? "")}}></p>
                </div>
            </div>
            <section className="list-section">
                <div className="inner">
                    <Outlet/>
                </div>
            </section>
        </main>
    )
}

export default ProHelp
import {useDispatch} from "react-redux";
import {FooterConfig} from "../../core/common/footer";
import {useEffect} from "react";
import {setFooterConfig} from "../redux/slices/footerSlice";

// useFooter 훅 정의
const useFooter = (config: FooterConfig) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setFooterConfig({
            ...config
        }))
    }, [config, dispatch]);
};

export default useFooter;

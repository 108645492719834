import React, {useEffect, useRef, useState} from "react";
import M_AccountManagementKeywordInput from "./M_AccountManagementKeywordInput";
import M_AccountManagementInput from "./M_AccountManagementInput";
import MemberUtils from "../../../../common/utils/member.utils";
import {ProReqVO} from "../../../../core/pro/pro.vo";
import {SessionVO} from "../../../../core/common/session.vo";
import {CompanyService} from "../../../../core/company/company.service";
import ProService from "../../../../core/pro/pro.service";
import M_AccountManagementTextarea from "./M_AccountManagementTextarea";
import FileUtils from "../../../../common/utils/file.utils";
import {CompanyAccountVO} from "../../../../core/company/company.vo";
import ToastUtils from "../../../../common/utils/toast.utils";
import ElandUtils from "../../../../common/utils/eland.utils";
import M_BottomButton from "../../common/M_BottomButton";
import useHeader from "../../../../common/hook/useHeader";
import {HeaderBtn} from "../../../../core/common/header";
import useFooter from "../../../../common/hook/useFooter";
import {setSession} from "../../../../common/redux/slices/sessionSlice";
import {useDispatch} from "react-redux";
import CommonMobileJS from "../../../../common/common_m";


const M_AccountManagement = () => {
    
    const dispatch = useDispatch();
    
    useHeader({
        type: "content",
        title: "회원정보 관리",
        buttons: [HeaderBtn.Back]
    })

    useFooter({
        isHidden: true
    })

    // 첫 진입 확인
    const isFirstRender = useRef(true);

    // 편집 상태
    const [isEditing, setIsEditing] = useState<boolean>(false)

    const [isPro, setIsPro] = useState(MemberUtils.isPro())
    const [accountVO, setAccountVO] = useState<SessionVO | null>(null);
    const [proReqVO, setProReqVO] = useState<ProReqVO | null>(null);

    // 프로필사진 : 보여주기 위해 useState 적용
    const [newProfileImg, setNewProfileImg] = useState<File | null>(null)
    // 닉네임 중복검사 수행했는지 확인
    const [isDuplicateCheck, setIsDuplicateCheck] = useState<boolean | null>(null)
    // 닉네임
    const [nickNameVal, setNickNameVal] = useState<string>("")
    // 관심키워드
    const [interestKeyword, setInterestKeyword] = useState<string>("")
    // 강점영역키워드
    const [strengthKeyword, setStrengthKeyword] = useState<string>("")
    // 에듀톡 주제
    const [edutalkKeyword, setEdutalkKeyword] = useState<string>("")
    // 소개글
    const [introduceText, setIntroduceText] = useState<string>("")

    // fileInput ref
    const profileInput = useRef<HTMLInputElement>(null)

    const getAccount = async () => {
        const successHandler = async (result:any) => {
            let data = result.data
            let account;
    
            /**
             * 2024-10-23 : 마이페이지 회원정보관리 에선 DB에서 재조회 요청
             * 사유 : 로그인 연장해서 사용되는 동기화 정보 변경된 직원
             */
            if ( result.coAccountVO ) {
                dispatch(setSession({
                    sessionVO: result.coAccountVO,
                    token : MemberUtils.getAccessToken()
                }))
            }
            
            if (data.isUpdated){
                account = data.account
            } else {
                account = MemberUtils.getSessionVO()
            }

            if (account?.jobCode) {
                account.jobName = await ElandUtils.getJobSkillCodeName(account?.jobCode)
            }

            setAccountVO(account)
            setNickNameVal(account?.nickName ? account?.nickName : "")
            setIsPro(MemberUtils.isPro())
        }

        CompanyService.checkAndReloadAccount(successHandler)
    }

    const getProInfo = () => {
        const successHandler = async (result: any) => {
            const proReq = {...result.data}
            proReq.jobCodeName = await ElandUtils.getJobSkillCodeName(proReq.jobCode)
            setProReqVO(proReq)
        }

        ProService.selfProInfoGet(successHandler)
    }

    const changeTextareaStr = (newVal: string) => {
        setIntroduceText(newVal)
    }

    const changeNicknameStr = (newVal: string) => {
        setNickNameVal(newVal)
        setIsDuplicateCheck(null)
    }

    const handleCheckDuplicate = (nickname: string) => {
        const successHandler = (result: any) => {
            setIsDuplicateCheck(result.data)
        }

        CompanyService.nicknameValidCheck(nickname, successHandler)
    }

    const checkDoNicknameDuplicate = () => {
        if (isDuplicateCheck == null) {
            if (nickNameVal !== accountVO?.nickName && nickNameVal !== "") {
                ToastUtils.show("닉네임 중복확인을 해주세요.")
                return false
            }
        }

        if (isDuplicateCheck == false) {
            ToastUtils.show("사용할 수 없는 닉네임입니다.")
            return false
        }

        return true
    }

    const getPicture = () => {
        if (newProfileImg) {
            return URL.createObjectURL(newProfileImg);
        }

        return FileUtils.getFileUrl(accountVO?.profileSrc ?? "")
    }

    const changeInterestKeywordStr = (newVal: string) => {
        setInterestKeyword(newVal)
    }

    const strengthKeywordStr = (newVal: string) => {
        setStrengthKeyword(newVal)
    }

    const edutalkKeywordStr = (newVal: string) => {
        setEdutalkKeyword(newVal)
    }

    const changePicture = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setNewProfileImg(e.target.files[0])
        }
    }

    const checkAllHasText = () => {
        if (!nickNameVal && MemberUtils.getSessionVO()?.nickName !== nickNameVal) {
            ToastUtils.show("닉네임을 입력해주세요.");
            return false;
        }

        if (proReqVO){
            if (strengthKeyword === "") {
                ToastUtils.show("강점 영역 키워드를 1개 이상 입력해주세요.");
                return false;
            }
            if (edutalkKeyword === "") {
                ToastUtils.show("에듀톡 주제를 1개 이상 입력해주세요.");
                return false;
            }
        }

        return true;
    }

    const doSubmit = () => {
        // 수정하기 상태에서 클릭 했을 때,
        if (!isEditing) {
            setIsEditing(true)
            return
        }

        if (checkDoNicknameDuplicate() && checkAllHasText()){
            if (!FileUtils.isValidSize(profileInput.current, 20) && !FileUtils.isValidNum(profileInput.current, 1, 0)) {
                ToastUtils.show("이미지 형식의 프로필 사진 파일을 1장, 20MB 이하로 등록해주세요")
                return
            }

            const formData = new FormData();

            // 프로필 사진, 파일
            const profile = profileInput.current && profileInput.current.files && profileInput.current.files[0]
            profile && formData.append("profileImg", profile)

            //활동내역, 활동직무, 활동역량, 시작년도, 강점키워드
            formData.append("nickName", nickNameVal)
            formData.append("interestKeyword", interestKeyword)
            formData.append("strengthKeyword", strengthKeyword)
            formData.append("chatTopic", edutalkKeyword)
            formData.append("proInfo", introduceText)

            const successHandler = (result: any) => {
                // 저장하기 -> 수정하기로 변경
                setIsEditing(false)

                const savedAccount: CompanyAccountVO = result.data
                MemberUtils.setSessionVO(savedAccount)
                window.location.reload()
            }
            CompanyService.accountAndProInfoUpdate(formData, successHandler)
        }
    }

    useEffect(() => {
        CommonMobileJS.tooltip()

        getAccount()
    }, []);

    useEffect(() => {
        CommonMobileJS.inputFile()
    }, [isEditing])

    useEffect(() => {
        isPro && getProInfo()

    }, [isPro]);

    useEffect(() => {
        setNickNameVal(accountVO?.nickName ?? "")

        setInterestKeyword(accountVO?.interestKeyword ?? "")

    }, [accountVO]);

    useEffect(() => {
        // 강점영역 키워드
        setStrengthKeyword(proReqVO?.strengthKeyword ?? "")

        // 에듀톡 주제
        setEdutalkKeyword(proReqVO?.chatTopic ?? "")

        // 소개글
        setIntroduceText(proReqVO?.proInfo ?? "")

    }, [proReqVO]);

    return (
        <div className="content myinfo register" style={{paddingBottom: "136px"}}>
            <section className="form-section">
                <div className="inner">
                    <div className="form-list">
                        <div className="form-item">
                            <div className="form-title blind">
                                <strong className="tit">프로필 사진</strong>
                            </div>
                            <div className="profile-upload">
                                <img src={getPicture()}
                                     onError={FileUtils.onErrorImg}
                                     alt="프로필 이미지" className="img-profile"/>
                                {
                                    isEditing &&
                                    <div className="file-upload">
                                        <input type="file" className="inp-file" accept="image/*"
                                               ref={profileInput}
                                               onChange={changePicture}/>
                                        <button type="button" className="btn-upload">
                                            <span className="blind">첨부파일 찾기</span>
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">계정</strong>
                            </div>
                            <div className="txt-bind">
                                <span className="name">{accountVO?.userName} ({accountVO?.coEmailId})</span>
                                <span className="job">{accountVO?.coInfoVO?.coName} I {accountVO?.teamName}</span>
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">사번</strong>
                            </div>
                            <div className="txt-bind">
                                <span className="num">
                                    {accountVO?.coEmpNo ? accountVO?.coEmpNo : '-'}
                                </span>
                            </div>
                        </div>
                        {
                            MemberUtils.isPro() &&
                            <div className="form-item">
                                <div className="form-title">
                                    <strong className="tit">직무</strong>
                                </div>
                                <div className="txt-bind">
                                    {accountVO?.jobName}
                                </div>
                            </div>
                        }
                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">닉네임</strong>
                            </div>

                            {
                                isEditing ?
                                    <M_AccountManagementInput
                                        placeholder="닉네임를 입력하세요"
                                        maxLength={8}
                                        value={accountVO?.nickName ?? ""}
                                        isValid={isDuplicateCheck}
                                        isPossibleEmpty={false}
                                        changeEvent={changeNicknameStr}
                                        buttonClickEvent={handleCheckDuplicate} /> :
                                    <div className="txt-bind">
                                        {accountVO?.nickName}
                                    </div>
                            }
                        </div>
                        <M_AccountManagementKeywordInput
                            isEditing={isEditing}
                            value={accountVO?.interestKeyword ? accountVO?.interestKeyword : ''}
                            title={"관심키워드"}
                            tooltipMsg={"신규 콘텐츠 업로드시 알림을 받고 싶은 키워드를 등록해주세요.<br/> 최대 5개까지 입력하실 수 있어요."}
                            placeholder={"관심 키워드를 입력해주세요"}
                            maxKeyword={5}
                            maxKeywordLength={14}
                            changeKeywordEvent={changeInterestKeywordStr}
                            isTag={true}/>
                    </div>
                </div>
            </section>
            {
                MemberUtils.isPro() && proReqVO &&
                <section className="form-section">
                    <div className="inner">
                        <div className="form-list">
                            <div className="section-title">
                                <h3 className="tit">사내고수 정보</h3>
                            </div>
                            <div className="form-item">
                                <div className="form-title">
                                    <strong className="tit">등급</strong>
                                </div>
                                <div className="txt-bind">
                                    사내고수
                                </div>
                            </div>
                            <div className="form-item">
                                <div className="form-title">
                                    <strong className="tit">지식 업로드</strong>
                                </div>
                                <div className="txt-bind">
                                    {proReqVO.uploadCount ? proReqVO.uploadCount : 0}회
                                </div>
                            </div>
                            <div className="form-item">
                                <div className="form-title">
                                    <strong className="tit">상담지수</strong>
                                </div>
                                <div className="txt-bind">
                                    {proReqVO.chatCount ? proReqVO.chatCount : 0}회
                                </div>
                            </div>
                            <div className="form-item">
                                <div className="form-title">
                                    <strong className="tit">활동직무</strong>
                                </div>
                                <div className="job-list">
                                    {
                                        proReqVO.jobCodeName && (
                                            <span className="badge type1 blue">{proReqVO.jobCodeName}</span>)
                                    }
                                </div>
                            </div>
                            <div className="form-item">
                                <div className="form-title">
                                    <strong className="tit">인증 키워드</strong>
                                </div>
                                <div className="keyword-list">
                                    {
                                        proReqVO.officialKeyword && proReqVO.officialKeyword.split(",").map(value => {
                                            return (<span className="badge type1 blue" key={value}>#{value}</span>)
                                        })
                                    }
                                </div>
                                <div className="guide-text">
                                    직무와 인증역량의 수정이 필요하신 경우, 그룹 인재원에 문의해주세요.
                                </div>
                            </div>
                            <M_AccountManagementKeywordInput
                                isEditing={isEditing}
                                value={proReqVO.strengthKeyword ? proReqVO.strengthKeyword : ""}
                                title={"강점 역량 키워드"}
                                tooltipMsg={"인증역량 외에 본인과 지식을 가장 잘 나타낼 수 있는 키워드를 10개까지 입력하실 수 있어요. 고수찾기시 활용됩니다.<br/>(ex 직무-상품기획 일 때 활동키워드-2일QR, 광저우사입)"}
                                placeholder={"강점 역량 키워드를 입력해주세요"}
                                maxKeyword={10}
                                maxKeywordLength={14}
                                changeKeywordEvent={strengthKeywordStr}/>
                            <M_AccountManagementKeywordInput
                                isEditing={isEditing}
                                value={proReqVO.chatTopic ? proReqVO.chatTopic : ""}
                                title={"에듀톡 주제"}
                                tooltipMsg={"편하게 이야기 나눌 수 있는 주제들을 최대 5개, 30글자까지 입력하실 수 있어요.<br/>(ex 공장과 소통하는 게 막막해요)"}
                                placeholder={"에듀톡 주제를 입력해주세요"}
                                maxKeyword={5}
                                maxKeywordLength={30}
                                changeKeywordEvent={edutalkKeywordStr}/>
                            <M_AccountManagementTextarea value={proReqVO.proInfo ?? ""} isChangeMode={isEditing} changeEvent={changeTextareaStr}/>
                        </div>
                    </div>
                </section>
            }
            <M_BottomButton name={isEditing ? "저장하기" : "수정하기"} onClick={(e) => {
                doSubmit()
            }}/>
        </div>
    )
}
export default M_AccountManagement
import React, {useEffect, useState} from "react";
import ProService from "../../../../core/pro/pro.service";
import {BoardVO} from "../../../../core/board/board.vo";
import {Link, useNavigate} from "react-router-dom";
import MemberUtils from "../../../../common/utils/member.utils";

const DashboardEduTalk = () => {
    const tabMenuList = [
        {name:'전체', code: "", color: "", btn:""},
        {name: '미해결', code: "041901", color: "red"},
        {name:'진행중', code: "041902", color: "orange"},
        {name:'일지작성', code: "041903", color: "purple"},
        {name:'완료', code: "041904", color: "green"},
        {name:'취소', code: "041905", color: "gray"}
    ]
    const navigate = useNavigate();

    const [writerBoardList, setWriterBoardList] = useState<BoardVO[]>([])
    const [receiverBoardList, setReceiverBoardList] = useState<BoardVO[]>([])

    useEffect(() => {
        getList()

    }, []);

    const getList = async () => {
        const successHandler = (result:any) => {
            setWriterBoardList(result.data.writer.list)
            setReceiverBoardList(result.data.receiver.list)
        }

        await ProService.inquiryListForDashboardGet(successHandler)
    }

    const getBadge = (code:string, isReceive:boolean) => {
        const filter = getLabel(code, isReceive)
        return (<span className={`badge type2 ${filter.color}`}>{filter.name}</span>)
    }

    const getLabel = (code:string, isReceive:boolean) => {
        const targetCode = !isReceive && code ==="041903" ? "041904" : code
        const filter = tabMenuList.filter(item => item.code === targetCode);
        return filter.length > 0 ? filter[0] : tabMenuList[0]
    }


    const goDetail = (idx:number, isReceive:boolean) => {
        navigate("/pro/talk/" + idx, {state: isReceive})
    }

    const getRow = (board:BoardVO, isReceive:boolean) => {
        return (
            <li key={board.idx}>
                <a href="#" onClick={e=>{
                    e.preventDefault()
                    goDetail(board.idx, isReceive)
                }}>
                    {getBadge(board.statusCode ?? "", isReceive)}
                    <strong>{board.title}</strong>
                </a>
            </li>
        )
    }

    const getEmptyRow = (index:number) =>{
        return (
            <li key={index}>
                <a href="#" onClick={e=>e.preventDefault()} style={{cursor:"default"}}>
                    <span className="badge type2">empty</span>
                    <strong></strong>
                </a>
            </li>
        )
    }

    const goEdutalk = (e:React.MouseEvent<HTMLAnchorElement>, isReceive:boolean) => {
        e.preventDefault()
        window.location.href="/pro/talk?isReceive=" + isReceive.toString()
    }

    const goRequest = (e:React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        window.location.href="/pro/request"
    }

    return (
        <div className="box-item box4">
            <div className="box-title">
                <h3 className="tit">에듀톡</h3>
            </div>
            <div className="box-content">
                <div>
                    <div className="sub-title">
                        <h3 className="tit">요청한 내역</h3>
                        <a href="#"
                           onClick={e=>goEdutalk(e, false)} className="link-more">바로가기</a>
                    </div>
                    {
                        writerBoardList.length > 0 ? (
                            <ul className="sub-list">
                                {
                                    writerBoardList.map((value, index) => {
                                        return getRow(value, false)
                                    })
                                }
                                {
                                    new Array(5 - writerBoardList.length).fill(1).map((value, index)=>{
                                        return getEmptyRow(index)
                                    })
                                }
                            </ul>
                        ) : (
                            <div className="content-empty">
                                <p>요청한 내역이 없습니다</p>
                            </div>
                        )
                    }
                </div>
                <div>
                    <div className="sub-title">
                        <h3 className="tit">요청받은 내역</h3>
                        <a href="#"
                           onClick={e=>goEdutalk(e, true)} className="link-more">바로가기</a>
                    </div>
                    {
                        (receiverBoardList.length > 0 && MemberUtils.isPro()) ? (
                            <ul className="sub-list">
                                {
                                    receiverBoardList.map((value, index) => {
                                        return getRow(value, true)
                                    })
                                }
                                {
                                    new Array(5 - receiverBoardList.length).fill(1).map((value, index)=>{
                                        return getEmptyRow(index)
                                    })
                                }
                            </ul>
                        ) : (
                            <div className="content-empty">
                                {
                                    MemberUtils.isPro() ? (
                                        <p>요청받은 내역이 없습니다</p>
                                    ) : (
                                        <div>
                                            <p>
                                                사내고수가 되어 지식을 나눔하고<br/>
                                                성장의 경험을 누리세요<br/>
                                                추천도 하실 수 있어요 :)
                                            </p>
                                            <button type="button" className="btn red sm" style={{marginTop:'24px'}}
                                                    onClick={goRequest}
                                            >도전하러가기</button>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default DashboardEduTalk
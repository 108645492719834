import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {HWMap} from "../../../core/common/common.vo";
import {useSelector} from "react-redux";
import {RootState} from "../../../common/redux/store";
import CommonMobileJS from "../../../common/common_m";

interface M_FooterProps {

}

const M_Footer:React.FC<M_FooterProps> = () => {
    const location = useLocation();
    const { isHidden } = useSelector((state: RootState) => state.footer);
    const [firstPath, setFirstPath] = useState<string>("");
    const [secondPath, setSecondPath] = useState<string>("")

    useEffect(() => {
        const pathArr = location.pathname.split('/');
        const firstPath = pathArr[1];
        const secondPath = location.pathname.split('/').length >= 3 ? pathArr[2] : "";
        setFirstPath(firstPath)
        setSecondPath(secondPath)
        CommonMobileJS.floatingArea()

    }, [location.pathname]);

    return (
        (isHidden) ? <></> : <>
            <div className="floating-area">
                <div className="floating-shortcut">
                    <div className="inner">
                        <ul>
                            <li className={firstPath === 'education' ? 'active' : ''}>
                                <Link to={"/education/dashboard"}>
                                    <i></i>
                                    <span>나의 강의실</span>
                                </Link>
                            </li>
                            <li className={firstPath === 'board' ? 'active' : ''}>
                                <Link to={"/board/list/ebg_story/"}>
                                    <i></i>
                                    <span>지식콘텐츠</span>
                                </Link>
                            </li>
                            <li className={firstPath === '' ? 'active' : ''}>
                                <Link to={"/"}>
                                    <i></i>
                                    <span>홈</span>
                                </Link>
                            </li>
                            <li className={firstPath === 'pro' && secondPath === "talk" ? 'active' : ''}>
                                <Link to={"/pro/talk"}>
                                    <i></i>
                                    <span>나의 에듀톡</span>
                                </Link>
                            </li>
                            <li className={firstPath === 'mypage' ? 'active' : ''}>
                                <Link to={"/mypage"}>
                                    <i></i>
                                    <span>마이페이지</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}





const M_FooterClose = ({hwMap}:HWMap) => {
    
    return (
        <></>
    );
}

const M_FooterBack = ({hwMap}:HWMap) => {
    
    return (
        <></>
    );
}



export {M_Footer, M_FooterClose, M_FooterBack}
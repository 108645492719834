import React, {useEffect, useState} from "react";
import {ProReqVO} from "../../../../core/pro/pro.vo";
import APIs from "../../../../common/apis/APIs";
import CodeUtils from "../../../../common/utils/code.utils";
import {Link} from "react-router-dom";
import FileUtils from "../../../../common/utils/file.utils";
import ElandUtils from "../../../../common/utils/eland.utils";

export const ProRecommendCard = (props: ProReqVO) => {
    const [proReq, setPro] = useState(props);

    const renderCode = async () => {
        const newProReq = {...proReq}
        newProReq.jobCodeName = await ElandUtils.getJobSkillCodeName(proReq.jobCode)
        setPro(newProReq)
    }

    useEffect(() => {
        renderCode()
    }, [])

    const onErrorImg = (e: any) => {
        e.target.src = process.env.PUBLIC_URL + '/elandedu/assets/images/common/img_profile.png'
    }

    return (
        <li className="slide-item swiper-slide">
            <Link to={"/pro/" + proReq.proidx}>
                <div className="profile-wrap">
                    <div className="img-wrap">
                        <img src={FileUtils.getFileUrl(proReq.profileImgSrc)} alt="" onError={FileUtils.onErrorImg} className="img-profile"/>
                    </div>
                    <div className="text-wrap">
                        <strong className="name">{proReq.companyAccountVO?.userName}</strong>
                        <div className="job">
                            <span>{proReq.companyAccountVO?.coInfoVO?.coName}</span>
                            <span>{proReq.jobCodeName}</span>
                        </div>
                        <div className="keyword-list">
                            <span className="keyword-span pro-recommend">
                                {
                                    proReq.officialKeyword.split(',').map((value, index) => {
                                        if (value) {
                                            return ('#' + value)
                                        }
                                    }).join(', ')
                                }
                            </span>
                        </div>
                    </div>
                </div>
                <div className="info-wrap">
                    <ul className="info-list">
                        <li>
                            <span className="txt">팔로워 수</span>
                            <span className="num">{proReq.followerCount}</span>
                        </li>
                        <li>
                            <span className="txt">상담지수</span>
                            <span className="num">{proReq.chatCount}</span>
                        </li>
                        <li>
                            <span className="txt">지식업로드</span>
                            <span className="num">{proReq.uploadCount}</span>
                        </li>
                    </ul>
                </div>
            </Link>
        </li>
)
}

export default ProRecommendCard;
import React, {useEffect, useState} from "react";
import {BoardVO} from "../../../../../core/board/board.vo";
import moment from "moment";
import ElandUtils from "../../../../../common/utils/eland.utils";
import MemberUtils from "../../../../../common/utils/member.utils";

interface ProHelpListProps {
    boardVO: BoardVO,
    clickEvent: (idx:number)=>void,
    isBest: boolean,
    index?: number,
}

const ProHelpListRow = (props:ProHelpListProps) => {
    const {clickEvent, isBest} = props
    const [boardVO, setBoardVO] = useState<BoardVO>(props.boardVO)

    useEffect(() => {
        const renderCode = async () =>{
            const board = {...boardVO}
            board.jobName = board.jobCode && await ElandUtils.getJobSkillCodeName(board.jobCode)
            setBoardVO(board)
        }
        renderCode()
    }, []);

    const getCateBadge = (cateCode:string) => {
        switch (cateCode) {
            case "040401":
                return (<span className="badge type2 red">도움요청 </span>)
            case "040402":
                return (<span className="badge type2 green">지식나눔 </span>)
            case "040403":
                return (<span className="badge type2 blue">자유주제 </span>)
            default:
                break;
        }
    }

    return (
        <tr onClick={(e)=>{
            e.preventDefault()
            clickEvent(boardVO.idx)
        }} style={{cursor:"pointer"}}>
            <td>
                {
                    isBest ? (
                        <span className="badge type1 red">BEST</span>
                    ) : (
                        props.index ? props.index : boardVO.idx
                    )
                }

            </td>
            <td>{boardVO.jobName}</td>
            <td>
                {
                    boardVO.cateCode && getCateBadge(boardVO.cateCode)
                }
            </td>
            <td className="tit">
                <div>
                    <strong>{boardVO.title}</strong>
                    {
                        boardVO.commentCount > 0 && (<span className="comment" style={{marginLeft: '4px'}}>({boardVO.commentCount})</span>)
                    }

                    {
                        boardVO.isFile && (<span className="attach"></span>)
                    }
                </div>
            </td>
            <td className="author" style={{textAlign:"end"}}>
                <div className="author-box">
                    {
                        (
                            MemberUtils.isSemiProRateCode(boardVO.writerRate ?? "") ||
                            MemberUtils.isProRateCode(boardVO.writerRate ?? "")
                        ) && (
                            <span className="badge type1 orange" style={{marginRight: '6px'}}>고수</span>
                        )
                    }
                    {boardVO.writer}
                </div>
            </td>
            <td className="date">{moment(boardVO.createDate).format("YYYY-MM-DD")}</td>
            <td className="hits">
                {boardVO.views}
            </td>
            <td className="like">
                <i className="ico-like"></i>
                {boardVO.likeCount}
            </td>
        </tr>
    )
}

export default ProHelpListRow


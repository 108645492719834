import React, {useEffect, useRef, useState} from "react";
import M_ProFindCard from "./M_ProFindCard";
import {ProReqVO} from "../../../../core/pro/pro.vo";
import {PageParams, PageUtils} from "../../../../common/utils/page.utils";
import M_ProRecommendSlide from "./M_ProRecommendSlide";
import {useSearchParams} from "react-router-dom";
import proService from "../../../../core/pro/pro.service";
import M_SearchInputAndFilter from "../../common/M_SearchInputAndFilter";
import useHeader from "../../../../common/hook/useHeader";
import {HeaderBtn} from "../../../../core/common/header";
import useFooter from "../../../../common/hook/useFooter";
import M_InfinityScroll from "../../common/M_InfinityScroll";
import axiosCaller from "../../../../common/utils/axios.caller";
import APIs from "../../../../common/apis/APIs";
import CommonMobileJS from "../../../../common/common_m";
import {HWMap} from "../../../../core/common/common.vo";

const M_ProFind = () => {
    useHeader({
        type: 'sub',
        title: '사내고수 찾기',
        buttons: [HeaderBtn.Menu]
    });
    useFooter({isHidden:false})

    const [query, setQuery] = useSearchParams();
    const [recommendList, setRecommendList] = useState<ProReqVO[]>([]);
    const [searchList, setSearchList] = useState<ProReqVO[]>([]);
    const [pageParams, setPageParams] = useState<PageParams>(
        new PageUtils(
            parseInt(query.get('page') ?? "0"),
            parseInt(query.get('size') ?? "9"),
            query.get('sort') ?? "",
            parseInt(query.get('totalCount') ?? "0"),
            query.get("searchText") ?? "",
        )
    );
    const [totalCount, setTotalCount] = useState<number>(0);
    const [filterParams, setFilterParams] = useState(
        {
            skillCode: query.get("skillCode") ?? "",
            jobCode: query.get("jobCode") ?? "",
            coCode: query.get("coCode") ?? ""
        }
    );
    const scrollRef = useRef<{ reload: () => void, saveScroll:(index:number)=>void}>(null);


    useEffect(() => {
        const successHandler = (result: any) => {
            setRecommendList(result.data);
        }

        proService.proRecommendListGet({size: 9}, successHandler)
    }, []);


    useEffect(() => {
        updateParams()

    }, [pageParams, filterParams]);

    const fetchList = async (makeParamFunc:(params:HWMap, size:number) => HWMap) => {
        let params = makeParamFunc({...pageParams, ...filterParams}, pageParams.size)
        let result = await axiosCaller.get(APIs.PRO_SEARCH, params)

        if (result.status === "SUCCESS"){
            setTotalCount(result.data.totalCount)
            return result.data.list
        }

        return []
    }

    const updateParams = () => {
        query.set("size", pageParams.size.toString())
        query.set("sort", pageParams.sort.toString())
        query.set("searchText", pageParams.searchText)
        query.set("skillCode", filterParams.skillCode)
        query.set("jobCode", filterParams.jobCode)
        query.set("coCode", filterParams.coCode)

        setQuery(query, {replace:true})
    }


    const updateSearchText = (searchText: string) => {
        scrollRef.current && scrollRef.current.reload()
        setPageParams((preParams) => ({
            ...preParams,
            searchText: searchText
        }));
    }

    const updateCode = (coCode: string, jobCode: string, skillCode: string) => {
        scrollRef.current && scrollRef.current.reload()
        setFilterParams((preParams) => ({
            ...preParams,
            jobCode: jobCode,
            skillCode: skillCode,
            coCode: coCode
        }));
    }

    return (
        <main className="content master-find" style={{paddingBottom:"113px"}}>
            {
                recommendList.length > 0 && (
                    <M_ProRecommendSlide recommendList={recommendList}></M_ProRecommendSlide>
                )
            }

            <section className="list-section">
                <div className="inner">
                    <div className="section-title">
                        <h3 className="tit">고수 검색</h3>
                    </div>
                    <div className="board-util">
                        <div className="util-group">
                            <M_SearchInputAndFilter updateSearchText={updateSearchText}
                                                    placeholderText={"검색어를 입력해주세요"}
                                                    initText={pageParams.searchText}
                                                    coCode={filterParams.coCode}
                                                    jobCode={filterParams.jobCode}
                                                    skillCode={filterParams.skillCode}
                                                    updateCode={updateCode}/>
                        </div>
                    </div>
                    <div className="board-list">
                        {
                            searchList.length <= 0 && (
                                <div className="content-empty">
                                    <i className="ico-content"></i>
                                    <p className="txt">해당하는 고수가 없습니다.<br/>검색어를 다시 한 번 확인해주세요.
                                    </p>
                                </div>
                            )
                        }

                        {
                            searchList.length > 0 && (
                                <>
                                    <ul className="card-list type4">
                                        {
                                            searchList.map((value, index) => {
                                                return (
                                                    <M_ProFindCard
                                                        key={value.proidx}
                                                        proReq={value}
                                                        saveEvent={() => {
                                                            scrollRef.current && scrollRef.current.saveScroll(index)
                                                        }}/>
                                                )
                                            })
                                        }
                                    </ul>
                                </>
                            )
                        }
                        <M_InfinityScroll<ProReqVO>
                            queryKey={"ProFindList"}
                            update={fetchList}
                            defaultSize={pageParams.size}
                            totalCount={totalCount}
                            getList={val => {
                                setSearchList(val)
                            }}
                            scrollRef={scrollRef}/>
                    </div>
                </div>
            </section>
        </main>
);
}

export default M_ProFind
import React from 'react'
import SwalUtils from "../../../common/utils/swal.utils";

/**
 * 주소 : http://khane7.test-site.kr/elandedu/html/_common/sweetalert2.html
 * @constructor
 */
const AlertTest: React.FC = () => {
    return (
        <div className="wrap">
            {/* CONTENT */}
            <main className="content dashboard">
                <div className="common-title">
                    <div className="inner">
                        <h2 className="tit">sweetalert2</h2>
                    </div>
                </div>
                <section className="section">
                    <div className="inner" style={{ display : "flex", gap : "20px" }}>
                        <button className="btn gray lg" id="textAlert" onClick={() =>
                            SwalUtils.text({
                                title: "제목",
                                message: "내용",
                                confirmOptions: {
                                    text: "확인", eventHandler: () => {
                                        console.log("확인 버튼")
                                    }
                                },
                                cancelOptions: {
                                    text: "취소", eventHandler: () => {
                                        console.log("취소 버튼")
                                    }
                                },
                                isCancel: false
                            })
                        }>
                            isCancel = false
                        </button>
                        <button className="btn gray lg" id="textAlert" onClick={() =>
                            SwalUtils.text({
                                title: "제목",
                                message: "내용",
                                confirmOptions: {
                                    text: "확인",
                                    eventHandler: () => {
                                        console.log("확인 버튼")
                                    }
                                },
                                cancelOptions: {
                                    text: "취소",
                                    eventHandler: () => {
                                        console.log("취소 버튼")
                                    }
                                },
                                isCancel: true
                            })
                        }>
                            Text
                        </button>
                        <button className="btn gray lg" id="iconAlert" onClick={() =>
                            SwalUtils.text({
                                title: "제목",
                                message: "내용",
                                isIcon: true,
                                confirmOptions: {
                                    text: "확인", eventHandler: () => {
                                        console.log("확인 버튼")
                                    }
                                },
                                cancelOptions: {
                                    text: "취소", eventHandler: () => {
                                        console.log("취소 버튼")
                                    }
                                }
                            })
                        }>
                            Icon + Text
                        </button>
                        <button className="btn gray lg" id="inputAlert" onClick={() =>
                            SwalUtils.input({
                                title: "제목",
                                message: "내용",
                                confirmOptions: {
                                    text: "확인", eventHandler: (text) => {
                                        console.log(text)
                                    }
                                },
                                cancelOptions: {
                                    text: "취소", eventHandler: () => {
                                        console.log("취소 버튼")
                                    }
                                }
                            })
                        }>
                            Input
                        </button>
                        <button className="btn gray lg" id="selectAlert" onClick={() =>
                            SwalUtils.select({
                                title: "제목",
                                message: "내용",
                                options: {
                                    Fruits: {
                                        apples: "Apples",
                                        bananas: "Bananas",
                                        grapes: "Grapes",
                                        oranges: "Oranges"
                                    },
                                    Vegetables: {
                                        potato: "Potato",
                                        broccoli: "Broccoli",
                                        carrot: "Carrot"
                                    },
                                    icecream: "Ice cream"
                                },
                                confirmOptions: {
                                    text: "확인", eventHandler: (text) => {
                                        console.log(text)
                                    }
                                },
                                cancelOptions: {
                                    text: "취소", eventHandler: () => {
                                        console.log("취소 버튼")
                                    }
                                }
                            })
                        }>
                            Select
                        </button>
                    </div>
                </section>
            </main>
            {/* //CONTENT */}
        </div>

    )
}

export default AlertTest;
import React, {useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import BoardListType from "./BoardListType";
import BoardCardType from "./BoardCardType";
import {BoardVO, DefaultBoardInfoVO, DefaultBoardVO} from "../../../../core/board/board.vo";
import {PageParams} from "../../../../common/utils/page.utils";
import MypageService from "../../../../core/mypage/mypage.service";
import SearchInput from "../../common/SearchInput";
import Pagination from "../../common/Pagination";
import MypageNav from "../common/MypageNav";
import {BoardInfoVO} from "../../../../core/board/BoardInfo.vo";
import MemberUtils from "../../../../common/utils/member.utils";
import StringUtils from "../../../../common/utils/string.utils";
import BoardBasicType from "./BoardBasicType";

const MypageBoard = () => {
    const navigate = useNavigate()
    const {boardKey} = useParams()
    const [query, setQuery] = useSearchParams();
    const [boardMode, setBoardMode] = useState<string>("classic")
    const [boardInfo, setBoardInfo] = useState<BoardInfoVO>(DefaultBoardInfoVO())

    const [boardList, setBoardList] = useState<BoardVO[]>([])
    const [noticeList, setNoticeList] = useState<BoardVO[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [pageParams, setPageParams] = useState<PageParams>(
        {
            page: parseInt(query.get('page') ?? "0"),
            size: parseInt(query.get('size') ?? "1"),
            sort: query.get('sort') ?? "createDate,DESC",
            searchText: query.get("searchText") ?? "",
            boardKey: boardKey ?? "",
            cateCode: "",
            statusCode: ""
        }
    );


    useEffect(() => {
        init()

    }, [boardKey]);


    useEffect(() => {
        getBoardList()
        updateParams()

    }, [pageParams]);

    const init = () => {
        setBoardList([])
        setNoticeList([])
        initPageParams()

    }

    const initPageParams = () => {
        setPageParams({
            page: parseInt(query.get('page') ?? "0"),
            size: parseInt(query.get('size') ?? "1"),
            sort: query.get('sort') ?? "createDate,DESC",
            searchText: query.get("searchText") ?? "",
            boardKey: boardKey ?? "",
            cateCode: "",
            statusCode: ""
        })
    }

    const getBoardList = async () => {
        const successHandler = async (result: any) => {
            setBoardMode(result.data.boardInfo.boardMode)
            setBoardInfo(result.data.boardInfo)
            setTotalCount(result.data.boardList.count)
            setBoardList(result.data.boardList.list)

            if (result.data.boardInfo.isNotice){
                setNoticeList(result.data.noticeList.list)
            }
        }

        await MypageService.boardListGet({...pageParams, boardKey: boardKey ?? ""}, successHandler)
    }

    const updateParams = () => {
        query.set("page", pageParams.page.toString())
        query.set("sort", pageParams.sort.toString())
        query.set("searchText", pageParams.searchText)

        setQuery(query)
    }

    const clickSort = (e: React.MouseEvent<HTMLAnchorElement>, sort: string) => {
        e.preventDefault()
        setPageParams(prev => {
            return {...prev, sort: sort}
        })
    }

    const updateSearchText = (searchText: string) => {
        setPageParams((preParams) => ({
            ...preParams,
            page: 0,
            searchText: searchText
        }));
    }

    const updatePages = (newPages: number) => {
        setPageParams((preParams) => ({
            ...preParams,
            page: newPages
        }));
    }

    const isPossibleWrite = () => {
        if (boardInfo.isWrite) {
            if (boardInfo.isWriteRate === "") {
                return true
            }

            const allowRateCodeArr = boardInfo.isWriteRate.split(",");
            for (let i = 0; i < allowRateCodeArr.length; i++) {
                if (MemberUtils.getSessionVO()?.rateCode === allowRateCodeArr[i]) {
                    return true
                }
            }
        }
        return false
    }

    const goCreate = () => {
        navigate('/mypage/board/' + boardKey + "/form", {
            state: {
                cateCode: boardInfo.cateCode,
                isFile:boardInfo.isFile,
                isForm:boardInfo.isForm,
                formText:boardInfo.formText
            }
        })
    }

    const getTypeRow = ()=> {
        if (boardMode === 'classic'){
            // 추천이 있어요
            return (<BoardListType boardInfo={boardInfo}
                                   boardList={boardList}
                                   noticeList={noticeList}
                                   page={pageParams.page}
                                   size={pageParams.size === 1 ? boardInfo.pageCount : pageParams.size}
                                   totalCount={totalCount}/>)
        } else if (boardMode === 'basic'){
            // 추천이 없어요
            return (<BoardBasicType boardInfo={boardInfo}
                                    boardList={boardList}
                                    noticeList={noticeList}
                                    page={pageParams.page}
                                    size={pageParams.size === 1 ? boardInfo.pageCount : pageParams.size}
                                    totalCount={totalCount}/>)
        } else if (boardMode === 'gallery'){
            // 카드형태에요
            return (<BoardCardType boardList={boardList}
                                   boardInfo={boardInfo}
                                   noticeList={noticeList}/>)
        }
    }


    return (
        <main className="content nav-layout">
            <div className="common-title">
                <div className="inner">
                    <h2 className="tit">{boardInfo.boardName}</h2>
                    <p className="desc" dangerouslySetInnerHTML={{__html: StringUtils.nl2br(boardInfo?.description ?? "")}}></p>
                </div>
            </div>
            <section className="list-section">
                <div className="inner">
                    <div className="board-util">
                        {
                            isPossibleWrite() && (
                                <div className="button-area">
                                    <button type="button" className="btn red" onClick={goCreate}>작성하기
                                    </button>
                                </div>
                            )
                        }

                        <div className="util-group">
                            <div className="board-total">
                                총 <span>{totalCount}</span> 개의 게시물이 있습니다.
                            </div>
                            <div className="board-search">
                                <SearchInput updateSearchText={updateSearchText}
                                             placeholderText={'제목 또는 작성자를 입력하세요'}
                                             initText={pageParams.searchText}></SearchInput>
                            </div>
                        </div>
                        <div className="util-group">
                            <div className="board-sort">
                                <div className="sort-list">
                                    <a href="#"
                                       className={pageParams.sort === 'createDate,DESC' ? 'active' : ''}
                                       onClick={(e) => clickSort(e, 'createDate,DESC')}>최신순</a>
                                    <a href="#"
                                       className={pageParams.sort === 'views,DESC' ? 'active' : ''}
                                       onClick={(e) => clickSort(e, 'views,DESC')}>조회순</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="board-list">
                        {
                            getTypeRow()
                        }
                        {
                            (boardList.length > 0 || noticeList.length > 0) && (
                                <Pagination page={pageParams.page}
                                            totalCount={totalCount}
                                            updatePages={updatePages}
                                            size={pageParams.size === 1 ? boardInfo.pageCount : pageParams.size}></Pagination>
                            )
                        }

                    </div>
                </div>
            </section>
            <MypageNav currentMenu={boardKey ?? "board"}/>
        </main>

    )
}

export default MypageBoard
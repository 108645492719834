import {HWMap} from "../common/common.vo";
import axiosCaller from "../../common/utils/axios.caller";
import APIs from "../../common/apis/APIs";

class PointService {

    /**
     * 포인트 적립
     * @param idx
     * @param pointCode
     */
    static async savePoint(pointCode: string, idx?: number | string | null) {
        try {
            console.log("⭐savePoint pointCode:" + pointCode + " , idx:" + idx);
            // 정책에 따라 idx를 emIdx, ecIdx, bIdx로 보내야함
            const emIdxCodes = ["COURSE_COMMENT", "COURSE_COMPLETE"];
            const bIdxCodes = ["CONTENT_VIEW", "COMMENT_POST", "PRO_REG_CONTENT", "PRO_REG_REPOSITORY", "PRO_FEEDBACK", "PRO_WRITE_LOG", "STORY_CREATE", "HELP_POST", "HELP_REPLY"];
            const ecIdxCodes = ["COURSE_VIEW"];

            // 이중에 있으면 해당 변수명으로 파라미터를 만들어서 반환하는 함수
            const determineIdxKey = (pointCode: string) => {
                if (emIdxCodes.includes(pointCode)) {
                    return 'emIdx';
                } else if (bIdxCodes.includes(pointCode)) {
                    return 'bIdx';
                } else if (ecIdxCodes.includes(pointCode)) {
                    return 'ecIdx';
                } else {
                    return null; // 매칭되지 않으면 null 반환
                }
            };

            const key = determineIdxKey(pointCode);
            const params = key ? {
                [key]: typeof idx === 'string' ? parseInt(idx, 10) : idx,
                pointCode: pointCode
            } : {
                pointCode: pointCode
            };

            const response = await axiosCaller.post(APIs.POINT + "/savePoint", params);
            console.log(response.msg);
            return response

        } catch (error) {
            console.error('API 호출 중 오류가 발생했습니다:', error);
            return null
        }
    }




    static async cancelPoint(pointCode: string, idx: number | string | null) {
        try {
            // 정책에 따라 idx를 emIdx, ecIdx, bIdx로 보내야함
            const emIdxCodes = ["COURSE_COMMENT", "COURSE_COMPLETE"];
            const bIdxCodes = ["CONTENT_VIEW", "COMMENT_POST", "PRO_REG_CONTENT", "PRO_REG_REPOSITORY", "PRO_FEEDBACK", "PRO_WRITE_LOG", "STORY_CREATE", "HELP_POST", "HELP_REPLY"];
            const ecIdxCodes = ["COURSE_VIEW"];

            // 이중에 있으면 해당 변수명으로 파라미터를 만들어서 반환하는 함수
            const determineIdxKey = (pointCode: string) => {
                if (emIdxCodes.includes(pointCode)) {
                    return 'emIdx';
                } else if (bIdxCodes.includes(pointCode)) {
                    return 'bIdx';
                } else if (ecIdxCodes.includes(pointCode)) {
                    return 'ecIdx';
                } else {
                    return null; // 매칭되지 않으면 null 반환
                }
            };

            const key = determineIdxKey(pointCode);
            const params = key ? {
                [key]: typeof idx === 'string' ? parseInt(idx, 10) : idx,
                pointCode: pointCode
            } : {
                pointCode: pointCode
            };

            const response = await axiosCaller.post(APIs.POINT + "/cancelPoint", params);

            console.log(response.msg);

        } catch (error) {
            console.error('API 호출 중 오류가 발생했습니다:', error);
        }
    }



    /**
     * 계정의 포인트 통계
     * @param _params
     */
    static getAccountPointDetail = async (_params : HWMap) => {
        return axiosCaller.get(APIs.POINT + "/getAccountPointDetail", _params);
    }


    /**
     * 대시보드 포인트 통계
     * @param _params
     */
    static getPointDetail4DashBoard = async (_params : HWMap) => {
        return axiosCaller.get(APIs.POINT + "/getPointDetail4DashBoard", _params);
    }



    /**
     * 계정의 적립내역 조회
     * @param _params
     */
    static getAccountPointHistory = async (_params : HWMap) => {
        return axiosCaller.get(APIs.POINT + "/getAccountPointHistory", _params);
    }




}


export default PointService;

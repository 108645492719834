import React from "react";
import {Link} from "react-router-dom";

interface M_JobSkillResultRankItem {
    score: number;
    rank: number;
    totalCount: number;
    skillName: string;
    linkValid: boolean;
}

const M_JobSkillResultRankItem = (props:M_JobSkillResultRankItem) => {
    const { rank , totalCount,skillName, linkValid, score} = props;

    const isPreventEmoji = (totalCount <= 10);
    // const isPreventScore = (score >= 80);
    const percentage = ((rank - 1) / totalCount) * 100;
    let rankClass = '';
    let emoji = '';
    let talk = '';

    if (percentage <= 10) {
        rankClass = 'rank1 swiper-slide';
        emoji = 'kissing_with_heart';
        talk = '멋져요!';
    } else if (percentage <= 30) {
        rankClass = 'rank2 swiper-slide';
        emoji = 'kissing_closed_eyes';
        talk = '최고를 향해!';
    } else if (percentage <= 50) {
        rankClass = 'rank3 swiper-slide';
        emoji = 'smiling';
        talk = '함께 성장해봐요~';
    } else {
        rankClass = 'rank4 swiper-slide';
        emoji = isPreventEmoji ? 'smiling' :'downcast';
        talk = '노력해볼까요?';
    }

    function getRandomStr(items: string[]): string {
        if (items.length === 0) {
            return '';
        }
        const randomIndex = Math.floor(Math.random() * items.length);
        return items[randomIndex];
    }

    return (
        <li className={rankClass}>
            <Link to={"/mypage/jobskill"} onClick={e => {
                if(!linkValid){
                    e.preventDefault()
                }
            }}>
                <i className="ico-emoji" style={{
                    backgroundImage:`url("/elandedu/assets/images/imoji/${emoji}.png")`,
                }}></i>
                <strong className="duty">{skillName}</strong>
                <span className="per">상위 {percentage.toFixed(1)}%</span>
                <span className="talk">{talk}</span>
            </Link>
        </li>
    )
}

export default M_JobSkillResultRankItem
import React from "react";
import M_DashboardCourseInfo from "./M_DashboardCourseInfo";
import M_DashboardOldCourseInfo from "./M_DashboardOldCourseInfo";
import M_RecentBoard from "./M_RecentBoard";
import M_DashboardEduTalk from "./M_DashboardEduTalk";
import M_DashboardPoint from "./M_DashboardPoint";
import M_DashboardJobSkillResult from "./M_DashboardJobSkillResult";
import M_DashboardHelpBoard from "./M_DashboardHelpBoard";
import {M_Footer} from "../../layout/M_Footer";
import useHeader from "../../../../common/hook/useHeader";
import {HeaderBtn} from "../../../../core/common/header";
import useFooter from "../../../../common/hook/useFooter";


const M_Dashboard :React.FC = () => {
	useHeader({
		type: 'sub',
		title: "대시보드",
		buttons: [HeaderBtn.Menu]
	});

	useFooter({
		isHidden: false
	})

	return (
		<main id="main" className="content dashboard">
			<section className="dashboard-section">
				<div className="inner">
					<div className="box-list">
						{/* 수강 정보 */}
						<M_DashboardCourseInfo />

						{/* 과거 수강 정보 */}
						<M_DashboardOldCourseInfo />

						{/* 최근 본 지식 */}
						<M_RecentBoard />

						{/* 에듀톡 */}
						<M_DashboardEduTalk/>

						{/* 포인트 */}
						<M_DashboardPoint />

						{/* 직무역량진단 */}
						<M_DashboardJobSkillResult/>

						{/* 도와줘요 게시판 */}
						<M_DashboardHelpBoard/>
					</div>
				</div>
			</section>
		</main>
	);
}


export default M_Dashboard
import axiosCaller from "../../common/utils/axios.caller";
import APIs from "../../common/apis/APIs";
import {HWMap} from "../common/common.vo";
import {ReviewListItemVO} from "./review.vo";
import {ReviewVO} from "./ReviewVO";
import MemberUtils from "../../common/utils/member.utils";


class ReviewService {

    // 리뷰 리스트 조회
    static getReviewList = (_params: HWMap) => {
        return axiosCaller.get(APIs.REVIEW_LIST, _params)
    }

    // 저장 및 수정
    static setReview = (_params: HWMap) => {
        return axiosCaller.post(APIs.REVIEW_SET, _params)
    }

    // 삭제
    static deleteReview = (reviewVO: ReviewVO) => {
        if (reviewVO.writer == MemberUtils.getSessionVO()?.coEmailId) {
            // 리뷰 작성한 사람과 로그인 유저와 일지해야함
            console.log("reviewVO.ridx : ", reviewVO.ridx)
            return axiosCaller.delete(APIs.REVIEW_DELETE + "/" + reviewVO.ridx, [])
        } else {
            return {}
        }
    }

    // 고수의 Pick 등록
    static setReviewPick = (ridx: number, isPin: boolean) => {
        let _params = {"ridx" : ridx, "isPin" : isPin}
        return axiosCaller.post(APIs.REVIEW_SET_PICK, _params)
    }

    // 주어진 날짜 문자열에서 날짜 부분을 추출하는 함수
    static changeDateFormat = (dateString: string) => {
        // 공백을 기준으로 분할
        const parts = dateString.split(' ');

        // 날짜 문자열을 분리
        let numberList = parts[0].split('-');

        // 분리된 부분들을 합쳐서 새로운 형식의 문자열 생성
        let formattedDate = `${numberList[0]}.${numberList[1]}.${numberList[2]}`;

        return formattedDate;
    }
}


export default ReviewService
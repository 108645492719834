import MypageNav from "../common/MypageNav";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {CodeVO} from "../../../../core/common/code.vo";
import SummernoteIframe, {SummernoteIframeHandle} from "../../common/SummernoteIframe";
import AxiosCaller from "../../../../common/utils/axios.caller";
import APIs from "../../../../common/apis/APIs";
import CommonJS from "../../../../common/common";
import MypageService from "../../../../core/mypage/mypage.service";
import MemberUtils from "../../../../common/utils/member.utils";
import FileUploader from "../../common/FileUploader";
import {BoardVO} from "../../../../core/board/board.vo";
import ToastUtils from "../../../../common/utils/toast.utils";

const MypageBoardForm = () => {
    const navigate = useNavigate()
    const {boardKey} = useParams()
    const location = useLocation()
    const [boardVO, setBoardVO] = useState<BoardVO | null>(location.state.board ?? null)
    const [thumbnailFidxs, setThumbnailFidxs] = useState<string>(location.state.thumbFidxs ?? "")
    const [fidxs, setFidxs] = useState<string>(location.state.fidxs ?? "")
    const [title, setTitle] = useState<string>("")
    const [content, setContent] = useState<string>("")
    const [cateCodeList, setCateCodeList] = useState<CodeVO[]>([])

    const [cateCode, setCateCode] = useState<string>('')
    const [isFile, setIsFile] = useState<boolean>(location.state.isFile ?? true)
    const [isForm, setIsForm] = useState<boolean>(location.state.isForm ?? false)
    const [formText, setFormText] = useState<string>(location.state.formText ?? "")

    const cateCodeBtn = useRef<HTMLButtonElement>(null)

    const iframeRef = useRef<SummernoteIframeHandle>(null);

    useEffect(() => {
        location.state.cateCode && getCateCode(location.state.cateCode)
        CommonJS.inputFile('.file-upload-gray')

    }, []);

    const getCateCode = async (code:string) => {
        const result = await AxiosCaller.get(APIs.COMMON_CODE + "/getCodeTree", {code: code})
        if (result.status === 'ok') {
            setCateCodeList(result.codeList);
            readyToUpdate()

        }
    }

    const readyToUpdate = () => {
        if (boardVO) {
            setTitle(boardVO.title)
            setCateCode(boardVO.cateCode ?? '')
            setContent(getContent())
            setFidxs(fidxs)
            if (cateCodeBtn.current && boardVO.cateName) {
                cateCodeBtn.current.innerHTML = boardVO.cateName
            }
        }
        CommonJS.select()
    }


    const updateCateCode = (e: any, code: string) => {
        e.preventDefault()
        setCateCode(code)
    }

    const cancelEvent = () => {
        navigate(-1)
    }

    const handleUploadAttachFile = (idxs:string) => {
        setFidxs(idxs)
    }

    const handleThumbnailFile = (idxs:string) => {
        setThumbnailFidxs(idxs)
    }

    const saveBoard = async () => {
        if (cateCode === "") {
            ToastUtils.show("분류를 선택해주세요.", 2)
            return false;
        }

        if (title.trim() === "") {
            ToastUtils.show("제목을 작성해주세요.", 2)
            return false;
        }

        const htmlContent = iframeRef.current?.getHtmlContent();
        if (!htmlContent || htmlContent.trim() === "" || htmlContent.trim() === "<br>") {
            ToastUtils.show("내용을 작성해주세요.", 2)
            return false;
        }

        let params = {
            boardKey: boardKey ?? "",
            title: title,
            content:iframeRef.current?.getHtmlContent() ?? "",
            coCode: MemberUtils.getSessionVO()?.coCode ?? "",
            cateCode: cateCode,
            fidxs: fidxs,
            thumbFidxs: thumbnailFidxs
        }

        await MypageService.boardOneSave(params, showComplete)
    }

    const updateBoard = async () => {
        const bidx = boardVO?.idx.toString() ?? "0"

        let params = {
            boardKey: boardKey ?? "",
            title: title,
            content: iframeRef.current?.getHtmlContent() ?? "",
            cateCode: cateCode,
            fidxs: fidxs,
            thumbFidxs: thumbnailFidxs
        }

        await MypageService.boardOneUpdate(bidx, params, showComplete);
    }


    const showComplete = (result:any) => {
        ToastUtils.show("저장이 완료되었습니다.")
        navigate(-1);
    }

    const getContent = () => {
        if(boardVO){
            return boardVO?.contentHtml ?? ""
        } else {
            return isForm ? formText : ""
        }

    }

    return (
        <main className="content register nav-layout">
            <div className="common-title">
                <div className="inner">
                    <h2 className="tit">게시글 작성</h2>
                </div>
            </div>
            <section className="form-section">
                <div className="inner">
                    <div className="form-list no-line">
                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">분류</strong>
                            </div>
                            <div className="sel-bind">
                                <div className="select">
                                    <button type="button" className="btn-select"
                                            ref={cateCodeBtn}>분류를 선택해주세요
                                    </button>
                                    <ul className="select-option">
                                        {
                                            cateCodeList.map((code, index) => {
                                                return (
                                                    <li key={index}>
                                                        <a href="/"
                                                           onClick={e => updateCateCode(e, code.code)}>
                                                            <span>{code.codeName}</span>
                                                            <span></span>
                                                        </a>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">제목</strong>
                            </div>
                            <div className="inp-bind fill">
                                <input type="text" name="" id=""
                                       className="inp-text"
                                       onChange={event => {
                                           setTitle(event.target.value)
                                       }}
                                       value={title}
                                       placeholder="제목을 입력해주세요"/>
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">썸네일</strong>
                            </div>
                            <FileUploader params={{
                                domId: `boardVO-thumbnail`,
                                domText: `권장해상도를 확인하시고 썸네일을 등록해주세요`,
                                target: `board`,
                                allowList: "jpg,jpeg,png",
                                fidxs: thumbnailFidxs,
                                maxFileSize: 10,
                                maxFileCount: 1,
                                updateHandler: (_target: string, _files: string) => {
                                    console.log(_target + " ::::: " + _files)
                                    handleThumbnailFile(_files);
                                }
                            }}/>
                            <div className="guide-text">
                                파일 포맷 : JPG, JPEG, PNG / 파일크기 : 10MB이하 / 권장해상도 800*600 (4:3 비율)
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">내용</strong>
                            </div>
                            <div className="inp-bind">
                                <SummernoteIframe ref={iframeRef} mode={"edit"} htmlCode={content}/>
                            </div>
                        </div>
                        {
                            isFile && (
                                <div className="form-item">
                                    <div className="form-title">
                                        <strong className="tit">첨부파일</strong>
                                    </div>
                                    <FileUploader params={{
                                        domId: `boardVO-attach`,
                                        domText: `첨부파일은 1개만 업로드 가능합니다. 파일이 여러개일 경우 zip파일로 압축하여 등록해주세요.`,
                                        target: `board`,
                                        fidxs: fidxs,
                                        maxFileSize: 100,
                                        maxFileCount: 1,
                                        updateHandler: (_target: string, _files: string) => {
                                            console.log(_target + " ::::: " + _files)
                                            handleUploadAttachFile(_files);
                                        }
                                    }}/>
                                    <div className="guide-text">
                                        파일크기 : 100MB이하, 1개의 파일 / 확장자는 제약이 없습니다.
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className="button-area">
                        <button type="button" className="btn gray lg" onClick={cancelEvent}>취소하기
                        </button>
                        <button type="button" className="btn red lg"
                                onClick={boardVO ? updateBoard : saveBoard}>등록하기
                        </button>
                    </div>
                </div>
            </section>
            <MypageNav currentMenu={boardKey ?? ""}/>
        </main>
    )
}

export default MypageBoardForm
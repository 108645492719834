import React from 'react';

const M_NumberFormatter : React.FC<{number: number}> = ({number}) => {
    const formattedNumber = new Intl.NumberFormat('ko-KR').format(number);

    return (
        <> {formattedNumber} </>
    );
};

export default M_NumberFormatter;

import React, {useEffect, useState} from "react";
import ProRequestIntro from "./ProRequestIntro";
import ProRequestModal from "./ProRequestModal";
import MemberUtils from "../../../../common/utils/member.utils";
import ProRequestNotComplete from "./ProRequestNotComplete";
import ProService from "../../../../core/pro/pro.service";

const ProRequest = () => {

    /**
     * 요청 : REQ
     * 진행 : PROGRESS
     * 거절 : REJECT
     * 완료 : PRO
     */
    const [proRate, setProRate] = useState<string>('')
    const [activeList, setActiveList] = useState<string[]>([])

    useEffect(() => {
        getInfo()

    }, []);

    const getInfo = async () => {
        if (MemberUtils.isPro() && MemberUtils.isSemiPro()) {
            setProRate('PRO')

        } else {
            const successHandler = (result:any) =>{
                setProRate(result.data.proRate)
                setActiveList(result.data.activeList)
            }

            await ProService.proRequestStatusGet(successHandler)
        }
    }

    const isReq = () => {
        return !(proRate === "NONE" || proRate === "REJECT")
    }


    return (
        <main className="content master-apply">
            <div className="common-title blind">
                <div className="inner">
                    <h2 className="tit">사내고수 신청</h2>
                </div>
            </div>
            {
                proRate != '' && <ProRequestIntro proRate={proRate}/>
            }
            <ProRequestNotComplete isComplete={isReq()}/>
            <ProRequestModal activeList={activeList}/>
        </main>
    )
}

export default ProRequest
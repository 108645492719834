import React, {useEffect, useState} from "react";
import {AskAnsVO} from "../../../../../core/jobskill/jobskill.vo";

interface JobSkillInputAnswerProps {
    askAns: AskAnsVO,
    selectAnswer: number | null,
    selectEvent: (paaidx: number) => void
}

const M_JobSkillInputAnswer = (props: JobSkillInputAnswerProps) => {
    const {askAns, selectEvent} = props;
    const [selectAnswer, setSelectAnswer] = useState<number>(props.selectAnswer ?? 0)
    useEffect(() => {
        setSelectAnswer(props.selectAnswer ?? 0)
    }, [props]);

    return (
        <div className="radio">
            <input type="radio"
                   id={"ans" + askAns.paaidx}
                   className="inp-radio"
                   checked={selectAnswer === askAns.paaidx}
                   onChange={event => {
                       selectEvent(askAns.paaidx)
                   }}/>
            <label htmlFor={"ans" + askAns.paaidx}
                   className="lab-radio"><span>{askAns.answer}</span></label>
        </div>
    )
}

export default M_JobSkillInputAnswer
import React, {useEffect, useImperativeHandle, useMemo, useState} from "react";
import {useIntersectionObserver} from "../../../common/hook/useIntersectionObserver";
import {InfiniteData, QueryFunctionContext, useInfiniteQuery, useQueryClient} from "react-query";
import {HWMap} from "../../../core/common/common.vo";

interface InfinityScrollProps<T> {
    queryKey: string,
    defaultSize : number,
    totalCount : number,
    update: (makeParamFunc:(params:HWMap, size:number) => HWMap) => Promise<T[]>,
    getList: (list :T[])=> void,
    onSuccess?: (data: InfiniteData<T[]>) => void,
    scrollRef?: React.Ref<{ reload: () => void }>,
    retryCount?: number,
}

const M_InfinityScroll = <T,>(props:InfinityScrollProps<T>) => {
    const {queryKey, update, getList, onSuccess, scrollRef, retryCount = 2} = props
    const sessionKey = queryKey + "_SESSION"
    const queryClient = useQueryClient();
    const [isFetching, setIsFetching] = useState<boolean>(false)
    const [anchorPosition, setAnchorPosition] = useState<number | null>(null);
    const [pageParams, setPageParams] = useState<number|null>(null)

    const getNextPageParam = (lastPage: T[], allPages: Array<T[]>): number | undefined => {
        const getNextPage = () => {
            let preventSize = props.defaultSize >= 0 ? props.defaultSize : 1
            return Math.ceil(allPages.flat().length / preventSize)
        }

        // 마지막 페이지 갯수가 0이라면 에러 횟수 카운트
        if (lastPage.length <= 0){
            let errorCount = allPages.filter(value => value.length <= 0).length;
            if (errorCount >= retryCount){
                return undefined
            }
        }

        // 기본 사이즈가 0보다 크면서
        // 현재 총 갯수가 모든 총 갯수보다 작다면
        if (props.defaultSize > 0 && allPages.flat().length < (props.totalCount ?? 0)) {
            // 미리 불러왔다면 다음 페이지 계산하여 반환
            // 아니라면 다음페이지 return
            return isFetching ? getNextPage() : allPages.length
        }

        return undefined;
    };



    useEffect(() => {
        const prefetch = async () => {
            // 세션 스토리지에서 데이터 복원
            const getStorage = sessionStorage.getItem(sessionKey);
            if (!getStorage) {
                sessionStorage.removeItem(sessionKey);
                setIsFetching(false)
                return
            }
            const { pageParams, anchorPosition, lastPage } = JSON.parse(getStorage);
            const currentPage = window.location.href
            if (lastPage != currentPage){
                sessionStorage.removeItem(sessionKey);
                setIsFetching(false)
                return
            }

            let page = 0
            let size = (pageParams + 1) * props.defaultSize

            // 페이지 데이터를 미리 가져와 캐싱
            await queryClient.prefetchInfiniteQuery(queryKey, (data)=> {
                const makeParamFunc = (params:HWMap, _size:number): HWMap => {
                    return {...params, ...{size:size, page:page}}
                }

                return update(makeParamFunc)
            })

            const getData = queryClient.getQueryData<InfiniteData<T[]>>(queryKey)?.pages[0]
            if (!getData){
                setIsFetching(false)
                return
            }

            setIsFetching(true)
            sessionStorage.removeItem(sessionKey);

            let pageParamsArr:Array<number|undefined> = [undefined]
            for (let i = 0; i < pageParams; i++){
                if (i === 0) continue
                pageParamsArr.push(i)
            }

            queryClient.setQueryData(queryKey, {pages:[...[getData]], pageParams: pageParamsArr})
            setAnchorPosition(anchorPosition)
            setPageParams(pageParams)

        }

        prefetch()


    }, []);


    // 데이터 리로드 함수
    const reload = async () => {
        if (!isLoading){
            // 기존 캐시된 데이터를 삭제
            remove();
        }
    };

    // 데이터 새로고침 함수
    const refetchData = async () => {
        if (!isLoading){
            refetch()
        }
    };

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isLoading,
        status,
        refetch,
        remove
    } = useInfiniteQuery(
        queryKey,
        (context?: QueryFunctionContext)=>{
            const makeParamFunc = (params:HWMap, size:number): HWMap => {
                let pageNum = context?.pageParam ?? 0
                let pageSize = context?.meta?.rowsPerPage ? Number(context?.meta?.rowsPerPage) : size
                return {...params, ...{size:pageSize, page:pageNum}}
            }

            return update(makeParamFunc)
        },
        {
            getNextPageParam: getNextPageParam,
            onSuccess: onSuccess,
            cacheTime: 5,
            retry: 0,
            refetchOnMount: true,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        }
    );

    const { setTarget } = useIntersectionObserver({
        hasNextPage,
        fetchNextPage,
    });

    const dataList = useMemo(()=>{
        if (data){
            return data.pages.flat();
        }

        return []

    }, [data])

    useEffect(() => {
        getList(dataList)

    }, [dataList]);


    useEffect(() => {
        setTimeout(()=>{
            if (anchorPosition && pageParams && pageParams > 0){
                window.scrollTo({
                    top: anchorPosition,
                });
                setAnchorPosition(null)
                setPageParams(null)
            }
        })

    }, [dataList, anchorPosition]);


    // 외부에서 reload 함수를 호출할 수 있게 설정
    useImperativeHandle(scrollRef, () => ({
        reload, refetchData, saveScroll
    }));


    // 스크롤 위치 저장 함수
    const saveScroll = (index:number|null) => {
        const scrollPosition = window.scrollY;
        const currentLoadingItemNum = data?.pages.flat().length ?? 0
        const currentItemNum = index ? Math.min(currentLoadingItemNum, index + 1) : currentLoadingItemNum
        const currentPageLength = Math.ceil(currentItemNum / props.defaultSize)
        const clickedPage = Math.max(currentPageLength - 1, 0)
        sessionStorage.setItem(sessionKey, JSON.stringify({
            anchorPosition: scrollPosition,
            pageParams: clickedPage,
            lastPage: window.location.href
        }));
    };



    return (
        <div>
            {isLoading ? <></> : <div ref={setTarget}/>}
        </div>
    );
};

export default M_InfinityScroll;
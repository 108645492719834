import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import FileUtils from "../../../../../../common/utils/file.utils";
import {CommentFileVO, CommentVO} from "../../../../../../core/board/board.vo";
import CommonJS from "../../../../../../common/common";
import ProService from "../../../../../../core/pro/pro.service";
import ToastUtils from "../../../../../../common/utils/toast.utils";
import StringUtils from "../../../../../../common/utils/string.utils";

interface ProCommentInputProps {
    comment: CommentVO | null,
    updateEvent: () => void,
    enableTemp?: boolean,
    // 추가질문인지 아닌지 여부
    isAddition: boolean
}

const ProEduTalkCommentInput = (props: ProCommentInputProps) => {
    const {comment, updateEvent, isAddition} = props
    const {bidx} = useParams()

    const fileInput = useRef<HTMLInputElement>(null);
    const fileNameInput = useRef<HTMLInputElement>(null);
    const contentTextArea = useRef<HTMLTextAreaElement>(null);

    const [currentFile, setCurrentFile] = useState<File | CommentFileVO | null>(null)
    const [enableTemp, setEnableTemp] = useState<boolean>(props.enableTemp ?? true)

    useEffect(() => {
        CommonJS.inputFile('.file-upload', false)

    }, []);

    useEffect(() => {
        if (comment) {
            if (comment.commentFileList && comment.commentFileList.length > 0){
                setCurrentFile({...comment.commentFileList[0]});
            }

            if (contentTextArea.current) {
                contentTextArea.current.value = comment.contentText ?? ''
            }
        }

    }, [props.comment]);

    const updateFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!FileUtils.isValidSize(fileInput.current, 100)){
            ToastUtils.show("파일 용량이 100MB를 초과했습니다.")
            clearCurrentFile()

        } else {
            setCurrentFile(fileInput.current?.files?.item(0) ?? null);
        }
    }

    const clearAll = () => {
        if (contentTextArea.current) {
            contentTextArea.current.value = '';
        }
        clearCurrentFile()

    }

    const clearCurrentFile = () => {
        if (fileInput.current && fileNameInput.current) {
            fileInput.current.value = ""
            fileNameInput.current.value = ''
        }
        setCurrentFile(null)
    }

    const showSuccessToast = (isTemporary: boolean) => {
        if (isTemporary) {
            ToastUtils.show('임시저장이 완료되었습니다.')
        } else {
            ToastUtils.show('등록이 완료되었습니다.')
        }
    }

    const saveComment = async (e: any, isTemporary:boolean) => {
        e.preventDefault();

        if (StringUtils.isEmpty(contentTextArea.current?.value ?? "")){
            ToastUtils.show('내용을 입력해주세요.')
            return
        }

        FileUtils.validFileAndAlert(fileInput.current, 1, 100, async (validFile) => {
            if (!bidx) {
                return
            }

            const formData = new FormData();
            validFile && formData.append('files', validFile)
            formData.append('cidx', '0')
            formData.append('bidx', bidx)
            formData.append('pidx', '0')
            formData.append('isTemporary', isTemporary.toString())
            formData.append('contentText', contentTextArea.current?.value ?? '')

            const successHandler = () => {
                showSuccessToast(isTemporary);
                clearAll()
                updateEvent()
            }

            await ProService.inquiryCommentSave(formData, successHandler)
        })

    }

    const updateComment = async (e: any, isTemporary:boolean) => {
        e.preventDefault();

        if (!bidx) {
            return
        }

        if (!comment) {
            return
        }

        if (StringUtils.isEmpty(contentTextArea.current?.value ?? "")){
            ToastUtils.show('내용을 입력해주세요.')
            return
        }

        const formData = new FormData()
        let isFileDelete = 'false'

        if (currentFile instanceof File) {
            let validFile = null
            FileUtils.validFileAndAlert(fileInput.current, 1, 100,(file)=>{
                validFile = file
                validFile && formData.append('files', validFile)
                isFileDelete = 'true'
            })
        }

        if (currentFile == null && comment.commentFileList && comment.commentFileList.length > 0) {
            isFileDelete = 'true'
        }

        formData.append('isTemporary', isTemporary.toString())
        formData.append('isDelete', isFileDelete)
        formData.append('contentText', contentTextArea.current?.value ?? '')

        const successHandler = () => {
            showSuccessToast(isTemporary)
            clearAll()
            updateEvent()
        }

        await ProService.inquiryCommentUpdate(comment.cidx, formData, successHandler);
    }



    return (
        <div className="comment-input">
            <div className="input-wrap">
                <div className="comment-text">
                    <textarea name="" id="" cols={30} rows={5} className="inp-text"
                              placeholder={isAddition ? "해당 고수에게 추가로 궁금한 사항이 있다면 남겨주세요! :D" : "답변을 입력해주세요"}
                              ref={contentTextArea}></textarea>
                </div>
                {
                    currentFile && (<div className="comment-file">
                        <strong
                            className="neme">{currentFile instanceof File ? currentFile?.name : currentFile.filename}</strong>
                        <button type="button" className="btn-delete" onClick={clearCurrentFile}><span
                            className="blind">삭제</span></button>
                    </div>)
                }
                <div className="comment-util">
                    <div className="comment-upload">
                        <div className="file-upload">
                            <button type="button" className="btn-upload"><span className="blind">첨부파일 찾기</span>
                            </button>
                            <input type="file" name="" id="" className="inp-file" ref={fileInput}
                                   onChange={updateFile}/>
                            <input type="text" name="" id="" className="inp-text inp-upload"
                                   ref={fileNameInput} readOnly={true}/>
                        </div>
                    </div>
                    <div className="button-area">
                        {
                            enableTemp &&
                            (
                                <button type="button" className="btn gray sm"
                                        onClick={e => {
                                            comment ? updateComment(e, true) : saveComment(e, true)
                                        }}>
                                    임시저장
                                </button>
                            )
                        }
                        <button type="button" className="btn red sm"
                                onClick={e => {
                                    comment ? updateComment(e, false) : saveComment(e, false)
                                }}>
                            {comment && !comment.isTemporary ? "수정하기" : "등록하기"}
                        </button>
                    </div>
                </div>
            </div>
            <div className="guide-text">
                파일크기 : 100MB이하 / 포맷 : 제약이 없습니다. 파일이 여러 개일 경우 ZIP파일로 압축하여 업로드해주세요
            </div>
        </div>
    )
}

export default ProEduTalkCommentInput
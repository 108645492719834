import React, {useCallback, useEffect, useState} from 'react'
import MypageNav from "../common/MypageNav";
import ReviewService from "../../../../core/review/review.service";
import AlarmService from "../../../../core/alarm/alarm.service";
import {HWMap} from "../../../../core/common/common.vo";
import {PageUtils} from "../../../../common/utils/page.utils";
import {ReviewType} from "../../review/ReviewType";
import {ReviewListItemVO} from "../../../../core/review/review.vo";
import {AlarmVO} from "../../../../core/alarm/alarm.vo";
import DateUtils from "../../../../common/utils/date.utils";
import {MessageVO} from "../../../../core/alarm/message.vo";

const AlarmList = () => {
    const [params, setParams] = useState<HWMap>({
        ...new PageUtils(),
        page: 0,
        size: 20,
        sort: "createDate,DESC"
    })
    const [alarmList, setAlarmList] = useState<AlarmVO[]>([])
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    /**
     * 스크롤에 대한 이벤트 처리
     */
    const handleScroll = useCallback(() => {
        if (loading || !hasMore) return;

        const scrollPosition = window.innerHeight + document.documentElement.scrollTop;
        const threshold = document.documentElement.offsetHeight - 200;

        if (scrollPosition >= threshold) {
            setParams(prev => ({...prev, page: prev.page + 1}));
        }
    }, [loading, hasMore]);

    /**
     * 알림 리스트 조회
     */
    const getNotifList = async () => {
        setLoading(true)
        const result = await AlarmService.getNotifList(params);
        if (result.status === 'SUCCESS') {
            const list = alarmList.concat(result.data.notifList)
            setAlarmList(list)
            setHasMore(result.data.notifList.length === params.size);
        }
        setLoading(false)
    }

    /**
     * 알림 선택 했을 떄
     * @param item
     */
    const selectItem = async (item: AlarmVO) => {
        // 알림 읽음 처리
        params.seq = `${item.messageVO?.seq}`
        console.log("params : ", params)
        const result = await AlarmService.setIsRead(params);
        if (result.status === 'SUCCESS') {
            // 알림 읽음 상태 업데이트
            const updatedAlarmList = alarmList.map(alarmItem => {
                if (alarmItem.messageVO?.seq === item.messageVO?.seq) {
                    if (alarmItem.messageVO) {
                        alarmItem.messageVO.isRead = true
                    }
                }
                return alarmItem;
            });

            setAlarmList(updatedAlarmList); // 상태 업데이트

            // 현재 페이지의 history 상태를 업데이트
            history.replaceState({alarmList: updatedAlarmList}, '');

            // 알림에 대한 분기처리
            if (item.codeVO?.code) {
                AlarmService.handleCodeEvent(item.codeVO?.code, item.messageVO?.bidx)
            }
        }
    }

    useEffect(() => {
        getNotifList()
    }, [params]);

    useEffect(() => {
        console.log("alarmList : ", alarmList)
    }, [alarmList]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    return (
        <main className="content notify nav-layout">
            <div className="common-title">
                <div className="inner">
                    <h2 className="tit">알림함</h2>
                    <p className="desc">알림은 <span>최대 30일까지</span> 보관됩니다</p>
                </div>
            </div>
            <section className="list-section">
                <div className="inner">
                    <div className="board-list">
                        {
                            (alarmList && alarmList.length > 0) ?
                                <ul className="notify-list">
                                    {
                                        alarmList.map((item, i) =>
                                            <li key={i} className={item.messageVO?.isRead ? "" : "important"}>
                                                <a className={item.messageVO?.isRead ? "read" : "notRead"} onClick={
                                                    item.codeVO?.code ?
                                                        () => selectItem(item) :
                                                        // TODO: 만약 이동이 있다면 해당 부분 수정
                                                        () => selectItem(item)
                                                }>
                                                    <div className="info">
                                                    <span
                                                        className="cate">{item.codeVO?.code ? AlarmService.convertAlarmTitleName(item.codeVO?.code) : item.messageVO?.title}</span>
                                                        <span
                                                            className="date">{item.messageVO?.createDate && DateUtils.formatTimeForDisplay(item.messageVO?.reserveDT)}</span>
                                                    </div>
                                                    <p className="txt">{item.messageVO?.contentText}</p>
                                                </a>
                                            </li>
                                        )
                                    }
                                </ul>
                                :
                                <div className="content-empty">
                                    <i className="ico-content"></i>
                                    <p className="txt">현재는 알림이 없습니다.<br/>새로운 소식이 있으면 알려드리겠습니다.</p>
                                </div>
                        }
                        {loading && <li>Loading...</li>}
                    </div>
                </div>
            </section>
            <MypageNav currentMenu={"alarm"}/>
        </main>
    )
}

export default AlarmList
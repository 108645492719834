import {Chart} from "chart.js/auto";
import React, {useEffect} from "react";

interface JobSkillResultDoughnutChartProps{
    currentAvg: number;
    lastAvg: number;
}

const JobSkillResultDoughnutChart = (prop:JobSkillResultDoughnutChartProps) => {
    useEffect(() => {
        if (prop.currentAvg > 0){
            setDonutChart()
        }

    }, [prop]);

    const setDonutChart = () => {
        /* Doughnut */
        const percentValue = prop.currentAvg;
        const myChart1 = document.getElementById('myChart1') as HTMLCanvasElement
        const gradientBarCtx = (document.getElementById('myChart1') as HTMLCanvasElement)?.getContext('2d');

        if (Chart.getChart(myChart1)) {
            Chart.getChart(myChart1)?.destroy();
        }

        let colorGradient = gradientBarCtx?.createLinearGradient(80, 80, 0, 240);
        colorGradient?.addColorStop(.1, 'rgba(237,99,60,1)');
        colorGradient?.addColorStop(.2, 'rgba(239,116,60,1)');
        colorGradient?.addColorStop(.7, 'rgba(245,184,12,1)');
        colorGradient?.addColorStop(1, 'rgba(232,40,61,1)');
        new Chart(myChart1, {
            type: 'doughnut',
            data: {
                labels: ["Foo", "Foo2"],
                datasets: [
                    {
                        label: "good",
                        data: [percentValue, 100 - percentValue],
                        backgroundColor: [
                            colorGradient,
                            "rgba(217, 221, 234, 1)"
                        ],
                        circumference: 300,
                        rotation: 210,
                        borderRadius: 0,
                    },
                ],
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    },
                },
                cutout: "70%"
            }
        });
    }


    return (
        <div className="chart-wrap">
            <div className="chart">
                <canvas id="myChart1"></canvas>
                <div className="score">
                    역량진단점수
                    <span>{prop.currentAvg}점</span>
                </div>
            </div>
            {
                prop.lastAvg > 0 && (
                    <div className="state">
                        지난 진단대비 <span
                        className={prop.currentAvg - prop.lastAvg >= 0 ? "up" : "down"}>{Math.abs(prop.currentAvg - prop.lastAvg)} 점</span>
                    </div>
                )
            }
        </div>
    )
}

export default JobSkillResultDoughnutChart
import React, {useEffect, useState} from "react";
import APIs from "../../../common/apis/APIs";
import StringUtils from "../../../common/utils/string.utils";
import {BoardVO} from "../../../core/board/board.vo";
import CardItem from "../board/item/CardItem";
import JobSkillService from "../../../core/jobskill/jobskill.service";
import ElandUtils from "../../../common/utils/eland.utils";
import Swiper from "../../../assets/js/swiper.min";

const MainContentRecommend = () => {
    const [swiperVal, setSwiperVal] = useState<any>(null);
    const [boardList, setBoardList] = useState<BoardVO[]>([])


    useEffect(() => {
        getRelatedContents()

    }, []);

    useEffect(() => {
        if (boardList.length > 0){
            initSwiper()
        }
    }, [boardList]);

    const initSwiper = () =>{
        if (swiperVal){
            swiperVal.destroy()
            setSwiperVal(null)
        }

        const newSwiperVal = new Swiper("#main-content-recommend", {
            slidesPerView: "auto",
            spaceBetween: 66.66666,
            navigation: {
                nextEl: "#main-content-recommend-next",
                prevEl: "#main-content-recommend-prev",
            },
        });

        setSwiperVal(newSwiperVal)
    }

    const getRelatedContents = () => {
        const params = {
            boardKey: APIs.KNOWLEDGE_BOARDKEY,
            size: 8
        };

        const successHandler = async (result: any) => {
            const updatedBoardList = await ElandUtils.updateBoardListWithSkillNames(result.data);
            const newList = await ElandUtils.updateBoardListWithJobNames(updatedBoardList);
            setBoardList(newList);
        }

        JobSkillService.jobSkillRecommendContentGet(params, successHandler)
    }

    return (boardList && boardList.length !== 0) ? (
        <div className="board-list">
            <div className="sub-title">
                <h3 className="tit">추천 콘텐츠</h3>
            </div>
            <div id="main-content-recommend" className="slide-wrap">
                <ul className="slide-list swiper-wrapper card-list type1">
                    {
                        boardList.map(value => {
                            return <CardItem key={value.idx} board={value}/>
                        })
                    }
                </ul>
            </div>
            <div className="slide-button">
                <div id="main-content-recommend-prev" className="btn-prev" tabIndex={0} role="button" aria-label="Previous slide"></div>
                <div id="main-content-recommend-next" className="btn-next" tabIndex={0} role="button" aria-label="Next slide"></div>
            </div>
        </div>
    ) : <></>
}

export default MainContentRecommend
import React from "react";
import {ReviewListItemVO} from "../../../core/review/review.vo";
import {CompanyAccountVO} from "../../../core/company/company.vo";
import {CodeVO} from "../../../core/common/code.vo";
import ReviewService from "../../../core/review/review.service";
import MemberUtils from "../../../common/utils/member.utils";

interface ProReviewItemProps {
    itemVO: ReviewListItemVO
    eduTalkVO: CompanyAccountVO
    updateHandler: () => void
}

// M_ProReview 아이템
const ProReviewItem = (props: ProReviewItemProps) => {
    const {itemVO, eduTalkVO, updateHandler} = props;

    const setReviewPick = async (ridx: number, isPin: boolean) => {
        const result = await ReviewService.setReviewPick(ridx, isPin);

        if (result.status === "SUCCESS") {
            console.log("result.status === \"SUCCESS\"")
            updateHandler()
        }
    }

    // 코드 목록에서 배지를 렌더링하는 함수
    function renderBadgesFromCodeVOList(codeVOList?: [CodeVO]) {
        if (!codeVOList) {
            return null;
        }

        return codeVOList.map((codeVO, index) => getBadgeTag(codeVO, index));
    }

    // 키워드 가져오기
    function getBadgeTag(codeVO: CodeVO, index: number) {
        // 뒤에서문자 추출
        const secondToLastChar = codeVO.code.charAt(5);

        // 숫자로 변환
        const number = parseInt(secondToLastChar, 10);
        let className = "badge type1 "

        if (number % 10 === 0 || number % 9 === 0) {
            className += "red"
        } else if (number % 8 === 0 || number % 7 === 0) {
            className += "blue"
        } else if (number % 6 === 0 || number % 5 === 0) {
            className += "orange"
        } else if (number % 4 === 0 || number % 3 === 0) {
            className += "green"
        } else {
            className += "purple"
        }

        return <span key={index} className={className}>{codeVO.codeName}</span>;
    }

    function isTargetPro(){
        return (MemberUtils.getLoginId() === itemVO.review?.targetId)
    }

    return (
        <li>
            <div className="comment-item">
                <div className="writer">
                    {
                        itemVO.review && itemVO.review.pin ? (
                            <div className="pick">
                                <b>고수 Pick 한줄평</b>
                                <button type="button" className="btn-pick active"
                                        onClick={() => setReviewPick(itemVO.review!.ridx, false)}
                                        disabled={!isTargetPro()}>
                                    <span className="blind">Pick</span></button>
                            </div>
                        ) : (
                            isTargetPro() && (
                                <div className="pick">
                                    <button type="button" className="btn-pick"
                                            onClick={() => setReviewPick(itemVO.review!.ridx, true)}>
                                        <span className="blind">Pick</span></button>
                                </div>
                            )

                        )
                    }
                    <span
                        className="name"><b>{itemVO.companyAccount && itemVO.companyAccount.userName}</b></span>
                    <div className="badge-list">
                        {renderBadgesFromCodeVOList(itemVO.review?.codeVOList)}
                    </div>
                </div>
                <p className="text-area">
                    {itemVO.review && itemVO.review.title}
                </p>
                <span
                    className="date">{itemVO.review && ReviewService.changeDateFormat(itemVO.review.createDate)}</span>
                <div className="util">
                    <a href="#" className="btn-modify"><span className="blind">수정하기</span></a>
                    <a href="#" className="btn-delete"><span className="blind">삭제하기</span></a>
                </div>
            </div>
        </li>
    )
}

export default ProReviewItem
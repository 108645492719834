import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import M_SearchInput from "./M_SearchInput";
import {BoardVO} from "../../../core/board/board.vo";
import {HWMap} from "../../../core/common/common.vo";
import {PageUtils} from "../../../common/utils/page.utils";
import BoardService from "../../../core/board/board.service";
import APIs from "../../../common/apis/APIs";
import ElandUtils from "../../../common/utils/eland.utils";
import Swiper from "swiper";
import useHeader from "../../../common/hook/useHeader";
import {HeaderBtn} from "../../../core/common/header";
import useFooter from "../../../common/hook/useFooter";
import M_WebzineItem from "../board/item/M_WebzineItem";
import M_InfinityScroll from "../common/M_InfinityScroll";
import M_BasicItem from "../board/item/M_BasicItem";


const M_SearchResult: React.FC = () => {
    let buttons: HeaderBtn = HeaderBtn.Back
    useHeader({
        type: 'content',
        title: '검색',
        buttons: [buttons],
    });

    useEffect(() => {
        if (location.pathname.includes('/board/')) {
            buttons = HeaderBtn.Back
        } else {
            buttons = HeaderBtn.GoHomeBackButton
        }
    }, []);

    useFooter({
        isHidden: false
    })

    let {searchText} = useParams();
    let navigate = useNavigate();

    const [boardList, setBoardList] = React.useState<BoardVO[]>([]);
    const [params, setParams] = useState<HWMap>({...new PageUtils()})
    const [sortBoardType, setSortBoardType] = useState<string>('knowledge');
    const boardService = new BoardService();
    const [totalCount, setTotalCount] = useState(0);
    const scrollRef = useRef<{ reload: () => void, saveScroll:(index:number|null)=>void }>(null);

    // @ts-ignore
    const swiperRef = useRef<Swiper | null>(null);

    const initSwiper = () => {
        if (swiperRef.current) {
            swiperRef.current.destroy(true, true);
        }

        swiperRef.current = new Swiper(`.tab_swiper`, {
            slidesPerView: "auto",
            preventClicks: true,
            preventClicksPropagation: false,
            observer: true,
            observeParents: true
        });
    }

    useEffect(() => {
        initSwiper()

    }, []);


    const fetchList = async (makeParamFunc:(params:HWMap, size:number) => HWMap) => {
        let fetchParams = makeParamFunc({...params, searchText : searchText,
            boardKey: findSortBoardKey() ?? ""}, params.size)

        const result = await boardService.getSearchBoards(fetchParams);
        if (result.status == "SUCCESS" && result.data) {
            const list = await ElandUtils.convertJobSkillNames4BoardList(result.data.list);
            setTotalCount(result.data.totalCount)
            return list
        }

        return []
    }

    const findSortBoardKey = () => {
        switch (sortBoardType) {
            case 'knowledge' :
                return removeKeyFromString("job_data,use_story")
            case 'job_data' :
                return 'job_data'
            default :
                return removeKeyFromString("job_data,use_story")
        }
    }

    const handleTabClick = (tabType: string, event: React.MouseEvent<HTMLAnchorElement>) => {
        setSortBoardType(tabType);

        // 모든 .swiper-slide 요소에서 active 클래스를 제거
        const slides = document.querySelectorAll('.swiper-slide');
        slides.forEach(slide => {
            slide.classList.remove('active');
        });

        // 클릭한 요소의 부모 li에 active 클래스 추가
        const target = event.currentTarget.closest('.swiper-slide');
        if (target) {
            target.classList.add('active');
        }
    };

    const removeKeyFromString = (inputString: string) => {
        const knowKeysArray = APIs.KNOWLEDGE_BOARDKEY.split(',');
        const keysArray = inputString.split(',');
        const filteredArray = knowKeysArray.filter(item => !keysArray.includes(item));
        return filteredArray.join(',');
    }

    const reset = () => {
        setSortBoardType("knowledge")
        updateSearchText("")
    }

    const updateSearchText = (searchText: string) => {
        setBoardList([])
        setTotalCount(0)
        scrollRef.current && scrollRef.current.reload()
        navigate("/search/" + searchText, { replace: true });
    }

    return (
        <main id="search-result" className="content search-result">
            <div className="search-area">
                <div className="inner">
                    <M_SearchInput initText={searchText}
                                   updateSearchText={updateSearchText}
                                   placeholderText={"찾고 싶은 자료를 입력해주세요"}
                                   cancelHandler={reset}
                    />
                </div>
            </div>

            {/* Tab */}
            <div className="tab-area">
                <div className="inner">
                    <div className="tab-slide tab_swiper">
                        <ul className="tab-list swiper-wrapper">
                            <li className="tab-item swiper-slide active">
                                <a href="javascript:void(0);"
                                   onClick={(event) => handleTabClick('knowledge', event)}>지식콘텐츠</a>
                            </li>
                            <li className="tab-item swiper-slide ">
                                <a href="javascript:void(0);"
                                   onClick={(event) => handleTabClick('job_data', event)}>사내고수의 자료실</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="list-section">
                <div className="board-list">
                    <div className="inner">
                        {
                            (sortBoardType == "knowledge") && (
                                (boardList.length > 0) ?
                                    <ul className="webzine-list type1">
                                        {
                                            boardList.map((item) => (
                                                <M_WebzineItem key={item.idx} board={item}/>
                                            ))
                                        }
                                    </ul> :
                                    <div className="content-empty">
                                        <i className="ico-content"></i>
                                        <p className="txt">검색 결과가 없습니다.<br/>검색어를 다시 한 번 확인해주세요</p>
                                    </div>
                            )
                        }

                        {
                            (sortBoardType == "job_data") && (
                                (boardList.length > 0) ?
                                    <ul className="webzine-list type2">
                                        {
                                            boardList.map((item) => (
                                                <M_BasicItem key={item.idx} isNeedLogin={false} board={item} />
                                            ))
                                        }
                                    </ul> :
                                    <div className="content-empty">
                                        <i className="ico-content"></i>
                                        <p className="txt">검색 결과가 없습니다.<br/>검색어를 다시 한 번 확인해주세요</p>
                                    </div>
                            )
                        }
                    </div>
                </div>

                {
                    boardList &&
                    <M_InfinityScroll<BoardVO>
                        queryKey={"search" + sortBoardType + "List"}
                        update={fetchList}
                        defaultSize={params.size}
                        totalCount={totalCount}
                        getList={val => {
                            setBoardList(val)
                        }}
                        scrollRef={scrollRef}
                    />
                }

            </div>

        </main>
    )
}

export default M_SearchResult;
import Swal, {SweetAlertResult} from 'sweetalert2'

interface SwalOptions {
	title: string;
	message: string;
	isIcon?: boolean;
	inputValue?: string;
	inputPlaceholder?: string;
	options?: any;
	confirmOptions?: {
		text?: string;
		eventHandler?: (text:string) => void;
	}
	cancelOptions? : {
		text?: string;
		eventHandler?: () => void;
	},
	isCancel?: boolean;
}

class SwalUtils {

	/**
	 * 일반 텍스트
	 * @param title : 제목
	 * @param message : 내용 (html 기반이라 엘리먼트 작성 가능)
	 * @param isIcon : 아이콘 여부(기본: true)
	 * @param confirmOptions : 확인 옵션(오른쪽 버튼)
	 * @param cancelOptions : 취소 옵션(왼쪽 버튼)
	 * @param isCancel : 취소 버튼, dim, esc 클릭 여부
	 */
	static text = ({
		title,
		message,
		isIcon,
		confirmOptions = {},
		cancelOptions = {},
	   	isCancel = false
   	}: SwalOptions) => {
		Swal.fire({
			title: title,
			html: (isIcon == true) ? `<i class="ico-award"></i>` + message : message,
			showCancelButton: (cancelOptions?.text != null),
			showConfirmButton: (confirmOptions?.text != null),
			confirmButtonText: confirmOptions?.text,
			cancelButtonText: cancelOptions?.text,
			allowOutsideClick: isCancel,
		}).then((result) => {
			SwalUtils.handleResult(result, confirmOptions, cancelOptions)
		});
	}

	/**
	 * 텍스트 입력
	 * @param title : 제목
	 * @param message : 내용 (html 기반이라 엘리먼트 작성 가능)
	 * @param isIcon : 아이콘 여부(기본: true)
	 * @param inputPlaceholder : 입력에 대한 힌트값(기본: 내용을 입력해 주세요)
	 * @param confirmOptions : 확인 옵션(오른쪽 버튼) -> return 시 입력한 텍스트 반환
	 * @param cancelOptions : 취소 옵션(왼쪽 버튼)
	 * @param isCancel : 취소 버튼, dim, esc 클릭 여부
	 */
	static input = ({
						title,
					   	message,
						isIcon = true,
						inputPlaceholder = "내용을 입력해 주세요",
					   	confirmOptions = {},
					   	cancelOptions = {},
						isCancel = false
				   }: SwalOptions) => {
		Swal.fire({
			title: title,
			html: (isIcon == true) ? `<i class="ico-award"></i>` + message : message,
			input: "text",
			inputPlaceholder: inputPlaceholder,
			inputAttributes: {
				maxlength: "30",
				autocapitalize: "off",
				autocorrect: "off"
			},
			showCancelButton: (cancelOptions?.text != null),
			showConfirmButton: (confirmOptions?.text != null),
			confirmButtonText: confirmOptions?.text,
			cancelButtonText: cancelOptions?.text,
			allowOutsideClick: isCancel,
		}).then((result) => {
			SwalUtils.handleResult(result, confirmOptions, cancelOptions);
		});
	}

	/**
	 * textarea 입력
	 * @param title : 제목
	 * @param message : 내용 (html 기반이라 엘리먼트 작성 가능)
	 * @param isIcon : 아이콘 여부(기본: true)
	 * @param inputValue : 입력기본값 입력창 기본값
	 * @param inputPlaceholder : 입력에 대한 힌트값(기본: 내용을 입력해 주세요)
	 * @param confirmOptions : 확인 옵션(오른쪽 버튼) -> return 시 입력한 텍스트 반환
	 * @param cancelOptions : 취소 옵션(왼쪽 버튼)
	 * @param isCancel : 취소 버튼, dim, esc 클릭 여부
	 */
	static textarea = ({
						title,
						message,
						isIcon = true,
						inputValue = "",
						inputPlaceholder = "내용을 입력해 주세요",
						confirmOptions = {},
						cancelOptions = {},
						isCancel = false
					}: SwalOptions) => {
		Swal.fire({
			title: title,
			html: (isIcon == true) ? `<i class="ico-award"></i>` + message : message,
			input: "textarea",
			inputValue: inputValue,
			inputPlaceholder: inputPlaceholder,
			inputAttributes: {
				autocapitalize: "off",
				autocorrect: "off"
			},
			showCancelButton: (cancelOptions?.text != null),
			showConfirmButton: (confirmOptions?.text != null),
			confirmButtonText: confirmOptions?.text,
			cancelButtonText: cancelOptions?.text,
			allowOutsideClick: isCancel,
		}).then((result) => {
			SwalUtils.handleResult(result, confirmOptions, cancelOptions);
		});
	}

	/**
	 * 텍스트 선택
	 * @param title : 제목
	 * @param message : 내용 (html 기반이라 엘리먼트 작성 가능)
	 * @param isIcon : 아이콘 여부(기본: true)
	 * @param inputPlaceholder : 입력에 대한 힌트값(기본: 내용을 입력해 주세요)
	 * @param options : 선택 가능한 옵션들 추가
	 * @param confirmOptions : 확인 옵션(오른쪽 버튼) -> return 시 선택한 옵션 반환
	 * @param cancelOptions : 취소 옵션(왼쪽 버튼)
	 * @param isCancel : 취소 버튼, dim, esc 클릭 여부
	 */
	static select = ({
						title,
						message,
						isIcon = true,
						inputPlaceholder = "선택해 주세요",
						options,
						confirmOptions = {},
						cancelOptions = {},
						isCancel = false
					}: SwalOptions) => {
		Swal.fire({
			title: title,
			html: (isIcon == true) ? `<i class="ico-award"></i>` + message : message,
			input: "select",
			inputOptions: options,
			inputPlaceholder: inputPlaceholder,
			showCancelButton: (cancelOptions?.text != null),
			showConfirmButton: (confirmOptions?.text != null),
			confirmButtonText: confirmOptions?.text,
			cancelButtonText: cancelOptions?.text,
			allowOutsideClick: isCancel,
		}).then((result) => {
			SwalUtils.handleResult(result, confirmOptions, cancelOptions);
		});
	}

	// 공통 처리 로직
	static handleResult = (result: SweetAlertResult<any>,
						   confirmOptions: any,
						   cancelOptions: any ) => {
		
		/**
		 * 확인 버튼
		 */
		if (result.isConfirmed && confirmOptions?.eventHandler) {
			
			/**
			 * 함수 여부 확인 후 실행
			 */
			if ( typeof confirmOptions?.eventHandler === "function" )  {
				
				/**
				 * input 값이 있을 경우
				 */
				if ( result.value && result.value.length > 0 ) {
					confirmOptions?.eventHandler(result.value)
				} else {
					confirmOptions?.eventHandler()
				}
			}
			return
		}

		if (!result.isConfirmed && cancelOptions.eventHandler){
			if ( typeof cancelOptions?.eventHandler === "function" )  {
				cancelOptions?.eventHandler()
			}
		}


		/*
		/!**
		 * 취소버튼 or Dim 클릭 or ESC
		 *!/
		if ( typeof cancelOptions?.eventHandler === "function" )  {
			cancelOptions?.eventHandler()
		}
		
		
		// 확인 눌렀을 때
		if (result.isConfirmed && confirmOptions?.eventHandler) {
			if ( typeof confirmOptions?.eventHandler === "function" )  {
				confirmOptions?.eventHandler(result.value)
			}
		}

		// 취소 눌렀을 때
		if (result.dismiss === Swal.DismissReason.cancel) {
			if ( typeof cancelOptions?.eventHandler === "function" )  {
				cancelOptions?.eventHandler()
			}
		}

		// dim 클릭 했을 때
		if (result.dismiss === Swal.DismissReason.backdrop) {
			if ( typeof cancelOptions?.eventHandler === "function" )  {
				cancelOptions?.eventHandler()
			}
		}

		// esc 클릭 했을 때
		if (result.dismiss === Swal.DismissReason.esc) {
			if ( typeof cancelOptions?.eventHandler === "function" )  {
				cancelOptions?.eventHandler()
			}
		}
		*/
	}

	/*************************
	 * async, await 처리
	 *************************/
	static async = {
		/**
		 * 일반 텍스트
		 * @param title : 제목
		 * @param message : 내용 (html 기반이라 엘리먼트 작성 가능)
		 * @param isIcon : 아이콘 여부(기본: true)
		 * @param confirmOptions : 확인 옵션(오른쪽 버튼)
		 * @param cancelOptions : 취소 옵션(왼쪽 버튼)
		 * @param isCancel : 취소 버튼, dim, esc 클릭 여부
		 */
		text: async ({
			title,
			message,
			isIcon = true,
			confirmOptions = {},
			cancelOptions = {},
			isCancel = false,
		}: SwalOptions): Promise<void> => {
			try {
				const result = await Swal.fire({
					title: title,
					html: isIcon ? `<i class="ico-award"></i>${message}` : message,
					showCancelButton: cancelOptions?.text != null,
					showConfirmButton: confirmOptions?.text != null,
					confirmButtonText: confirmOptions?.text,
					cancelButtonText: cancelOptions?.text,
					allowOutsideClick: isCancel,
				});

				SwalUtils.handleResult(result, confirmOptions, cancelOptions);
			} catch (error) {
				console.error('SwalUtils error: ', error);
			}
		},


		/**
		 * 텍스트 입력
		 * @param title : 제목
		 * @param message : 내용 (html 기반이라 엘리먼트 작성 가능)
		 * @param isIcon : 아이콘 여부(기본: true)
		 * @param inputPlaceholder : 입력에 대한 힌트값(기본: 내용을 입력해 주세요)
		 * @param confirmOptions : 확인 옵션(오른쪽 버튼) -> return 시 입력한 텍스트 반환
		 * @param cancelOptions : 취소 옵션(왼쪽 버튼)
		 * @param isCancel : 취소 버튼, dim, esc 클릭 여부
		 */
		input: async ({
			title,
			message,
			isIcon = true,
			inputPlaceholder = "내용을 입력해 주세요",
			confirmOptions = {},
			cancelOptions = {},
			isCancel = false
		}: SwalOptions): Promise<void> => {
			try {
				const result = await Swal.fire({
					title: title,
					html: (isIcon == true) ? `<i class="ico-award"></i>` + message : message,
					input: "text",
					inputPlaceholder: inputPlaceholder,
					inputAttributes: {
						maxlength: "30",
						autocapitalize: "off",
						autocorrect: "off"
					},
					showCancelButton: (cancelOptions?.text != null),
					showConfirmButton: (confirmOptions?.text != null),
					confirmButtonText: confirmOptions?.text,
					cancelButtonText: cancelOptions?.text,
					allowOutsideClick: isCancel,
				})

				SwalUtils.handleResult(result, confirmOptions, cancelOptions);
			} catch (error) {
				console.error('SwalUtils error: ', error);
			}
		},

		/**
		 * 텍스트 선택
		 * @param title : 제목
		 * @param message : 내용 (html 기반이라 엘리먼트 작성 가능)
		 * @param isIcon : 아이콘 여부(기본: true)
		 * @param inputPlaceholder : 입력에 대한 힌트값(기본: 내용을 입력해 주세요)
		 * @param options : 선택 가능한 옵션들 추가
		 * @param confirmOptions : 확인 옵션(오른쪽 버튼) -> return 시 선택한 옵션 반환
		 * @param cancelOptions : 취소 옵션(왼쪽 버튼)
		 * @param isCancel : 취소 버튼, dim, esc 클릭 여부
		 */
		select: async ({
			title,
			message,
			isIcon = true,
			inputPlaceholder = "선택해 주세요",
			options,
			confirmOptions = {},
			cancelOptions = {},
			isCancel = false
		}: SwalOptions) => {
			try {
				const result = await Swal.fire({
					title: title,
					html: (isIcon == true) ? `<i class="ico-award"></i>` + message : message,
					input: "select",
					inputOptions: options,
					inputPlaceholder: inputPlaceholder,
					showCancelButton: (cancelOptions?.text != null),
					showConfirmButton: (confirmOptions?.text != null),
					confirmButtonText: confirmOptions?.text,
					cancelButtonText: cancelOptions?.text,
					allowOutsideClick: isCancel,
				})
				SwalUtils.handleResult(result, confirmOptions, cancelOptions);
			} catch (error) {
				console.error('SwalUtils error: ', error);
			}
		},

		review: async ({
						   title,
						   message,
						   isIcon = true,
						   inputPlaceholder = "선택해 주세요",
						   options,
						   confirmOptions = {},
						   cancelOptions = {},
						   isCancel = false
					   }: SwalOptions) => {
			try {
				const result = await Swal.fire({
					title: title,
					html: (isIcon == true) ? `<i class="ico-award"></i>` + message : message,
					input: "select",
					inputOptions: options,
					inputPlaceholder: inputPlaceholder,
					showCancelButton: (cancelOptions?.text != null),
					showConfirmButton: (confirmOptions?.text != null),
					confirmButtonText: confirmOptions?.text,
					cancelButtonText: cancelOptions?.text,
					allowOutsideClick: isCancel,
				})
				SwalUtils.handleResult(result, confirmOptions, cancelOptions);
			} catch (error) {
				console.error('SwalUtils error: ', error);
			}
		},
	};
}

export default SwalUtils;

import React, {useEffect, useState} from "react";
import CommonJS from "../../../../common/common";

interface AccountManagementKeywordInputProps {
    value: string;
    title: string,
    tooltipMsg: string,
    placeholder: string,
    maxKeyword: number,
    maxKeywordLength:number,
    changeKeywordEvent: (keyword: string) => void;
    zIndex?: number
    isTag?: boolean
}

const AccountManagementKeywordInput = (props:AccountManagementKeywordInputProps) => {

    const {changeKeywordEvent} = props;
    const [keywordArr, setKeywordArr] = useState<string[]>([])
    const [inputValue, setInputValue] = useState<string>('')

    useEffect(() => {
        CommonJS.tooltipV2()

    }, []);

    useEffect(() => {
        if (props.value !== ''){
            setKeywordArr(props.value.split(','))
        }

    }, [props.value]);

    useEffect(() => {
        changeKeywordEvent(keywordArr.join(','))

    }, [keywordArr]);

    const checkInputLength = () => {
        return inputValue.length <= props.maxKeywordLength
    }
    const checkCurrentKeywordNum = () => {
        return keywordArr.length < props.maxKeyword
    }
    const checkIncludeKeyword = () => {
        return keywordArr.includes(inputValue)
    }
    const checkInputIsEmpty = () => {
        return inputValue === ''
    }
    const checkInputIsEmojiOrSpecial = () => {
        /*
        * \p{P}: 구두점(Punctuation)
        * \p{S}: 기호(Symbol)
        * \p{Z}: 구분자(Separator), 주로 공백을 포함
        * \p{C}: 제어 문자(Control character)
        * \p{M}: 결합 문자(Mark), 다이아크리틱(발음 구별 기호) 같은 글자
        * \p{N}: 숫자(Number), 로마 숫자 등
        * \p{So}: 기타 기호(Other Symbol), 이모지나 기호 등을 포함
        * \p{Zs}: 공백 문자(Space separator)
        * */
        const specialOrEmojiPattern = /^[\p{P}\p{S}\p{Z}\p{C}\p{M}\p{So}\p{Zs}]*$/u;
        return specialOrEmojiPattern.test(inputValue)
    }
    const checkKeywordInput = () => {
        return checkInputLength() && checkCurrentKeywordNum()
    }

    const checkValid = () => {
        return checkKeywordInput() && !checkInputIsEmpty() && !checkIncludeKeyword() && !checkInputIsEmojiOrSpecial()
    }

    const btnStyle = () => {
        return checkValid() ? {color: "#1847ed"} : {}

    }

    const getErrorMessage = () => {
        if (!checkInputLength()) {
            return props.maxKeywordLength + '글자 이내로 입력해주세요'
        }

        if (!checkCurrentKeywordNum()) {
            return '키워드가 전부 입력되었습니다. 추가 입력을 원하신다면 기존에 등록한 키워드를 삭제 후 등록해주세요'
        }

        return ''
    }

    const activeEnter = (e: any) => {
        if (e.keyCode === 13) {
            addKeywordAndRemoveInput()
        }
    }

    const addKeyword = (value: string) => {
        setKeywordArr((prev) => {
            const arr = [...prev]
            arr.push(value)

            return arr
        })
    }

    const addKeywordAndRemoveInput = () => {
        if (checkValid()) {
            let str = props.isTag ? inputValue.replace(/[#\s]/g, '') : inputValue
            addKeyword(str)
            setInputValue('')
        }
    }

    const removeKeyword = (value: string) => {
        setKeywordArr((prev) => {
            const arr = [...prev]
            return arr.filter((v) => value != v)
        })
    }

    return (
        <div className="form-item" style={props.zIndex ? {zIndex:props.zIndex} : {}}>
            <div className="form-title type-tooltip">
                <strong className="tit">{props.title}</strong>
                <div className="tooltip">
                    <button type="button" className="btn-tooltip"><span className="blind">툴팁</span></button>
                    <div className="tooltip-content">
                        <div className="txt" dangerouslySetInnerHTML={{__html: props.tooltipMsg}}></div>
                    </div>
                </div>
            </div>
            <div className="inp-btn-bind">
                <input type="text" name="" id="" className="inp-text"
                       placeholder={props.placeholder}
                       value={inputValue}
                       disabled={!checkCurrentKeywordNum()}
                       onChange={e => {
                           if (e.target.value != inputValue) {
                               setInputValue(e.target.value)
                           }
                       }
                       }
                       onKeyDown={activeEnter}/>
                <button type="button"
                        className="btn gray btn-upload"
                        style={btnStyle()}
                        onClick={e=>addKeywordAndRemoveInput()}>등록</button>
            </div>
            <div className="tag-list">
                {
                    keywordArr.map(((value, index) => {
                        return (
                            <span className="tag-item" key={index + value}>
                                {value}
                                <button type="button"
                                        className="btn-remove"
                                        onClick={e => {
                                    removeKeyword(value)
                                }
                                }></button>
                            </span>
                        )
                    }))
                }
            </div>
            <div className="error-text">
                {
                    getErrorMessage()
                }
            </div>
        </div>
    )
}

export default AccountManagementKeywordInput
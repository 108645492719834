import React, {useEffect, useState} from "react";
import {CommentFileVO, CommentVO} from "../../../../../core/board/board.vo";
import moment from "moment";
import ProService from "../../../../../core/pro/pro.service";
import APIs from "../../../../../common/apis/APIs";
import StringUtils from "../../../../../common/utils/string.utils";
import FileUtils from "../../../../../common/utils/file.utils";
import PointService from "../../../../../core/point/point.service";
import ToastUtils from "../../../../../common/utils/toast.utils";
import MemberUtils from "../../../../../common/utils/member.utils";
import SwalUtils from "../../../../../common/utils/swal.utils";
import M_ProHelpDetailCommentInput from "./M_ProHelpDetailCommentInput";

interface ProHelpDetailCommentRowProps {
    comment: CommentVO
    updateEvent: ()=>void
}

const M_ProHelpDetailCommentRow = (props:ProHelpDetailCommentRowProps) => {
    const {updateEvent} = props
    const [comment, setComment] = useState<CommentVO>(props.comment)
    const [updateMode, setUpdateMode] = useState<boolean>(false)

    useEffect(() => {
        setComment(props.comment)
    }, [props]);

    const deleteComment = async (e:any) => {
        e.preventDefault()
        SwalUtils.text({
            title : `삭제하기`,
            message : `
					댓글 삭제 시 동작을 되돌릴 수 없습니다.<br>
						정말 댓글을 삭제하시겠습니까?
				`,
            isCancel : true,
            confirmOptions : {
                text : "삭제",
                eventHandler : async () => {
                    let params = {
                        cidx: comment.cidx,
                    }

                    const successHandler = async () => {
                        await PointService.cancelPoint("HELP_REPLY", comment.bidx ?? "0");
                        ToastUtils.show("삭제되었습니다.")
                        updateEvent()
                    }
                    await ProService.helpCommentDelete(params, successHandler)
                }
            },
            cancelOptions : {
                text : "취소"
            }
        })
    }

    const updateInputEvent = () => {
        updateEvent()
        setUpdateMode(false)
    }

    const updateModeChange = (e:any) => {
        e.preventDefault()
        setUpdateMode(!updateMode)
    }

    const downloadFile = async (e: any, fileVO: CommentFileVO) => {
        e.preventDefault()
        window.location.href = `${APIs.BOARD_COMMENTS_FILES_DOWN}${fileVO.cfidx}`
    }

    return (
        <li>
            {
                updateMode ? (
                    <M_ProHelpDetailCommentInput comment={comment}
                                                 updateEvent={updateInputEvent}
                                                 cancelEvent={()=>{setUpdateMode(!updateMode)}}/>
                ) : (
                    <div className="comment-item">
                        <div className="writer">
                            <div className="image-wrap">
                                <img src={FileUtils.getFileUrl(comment.profileSrc ?? "")}
                                     onError={FileUtils.onErrorImg}
                                     alt="프로필 이미지" className="img-profile"/>
                            </div>
                            <div className="text-wrap">
                                <div className="name">
                                    <b>{comment.writer}</b>
                                    <span>{MemberUtils.getProStr(comment.writerRate ?? "")}</span>
                                </div>
                                <span className="job">{comment.coName}</span>
                            </div>
                        </div>
                        <ul className="attach-list">
                            {
                                comment.commentFileList?.map(value => {
                                    return (
                                        <li key={value.cfidx}>
                                            <a href="#" onClick={e => downloadFile(e, value)}>
                                                <span className="path">{value.filename}</span>
                                            </a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <p className="text-area" dangerouslySetInnerHTML={{__html: StringUtils.nl2br(comment?.contentText)}}></p>
                        <span className="date">{moment(comment.createDate).format('YYYY.MM.DD')}</span>
                        {
                            comment.me && (
                                <div className="util">
                                    <a href="#" className="btn-modify" onClick={updateModeChange}><span className="blind">수정하기</span></a>
                                    <a href="#" className="btn-delete" onClick={deleteComment}><span className="blind">삭제하기</span></a>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </li>
    )
}

export default M_ProHelpDetailCommentRow
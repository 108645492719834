import React, {useEffect, useRef, useState} from "react";
import CommonJS from "../../../../common/common";
import {useNavigate} from "react-router-dom";
import ToastUtils from "../../../../common/utils/toast.utils";

interface ProRequestModalProps {
    activeList: string[]
}

const ProRequestModal = (props: ProRequestModalProps) => {
    const navigate = useNavigate();

    const closeBtn = useRef<HTMLButtonElement>(null);

    const [activeList, setActiveList] = useState<string[]>([]);
    const [selectAct, setSelectAct] = useState<string>('')

    useEffect(() => {
        CommonJS.select()
    }, []);

    useEffect(() => {
        setActiveList(props.activeList)
    }, [props]);

    const goSubmit = () => {
        if (!selectAct || selectAct === '') {
            ToastUtils.show("수행하신 활동을 선택해주세요")
            return
        }
        navigate("/pro/request/submit", {
            state: {
                activityCode: selectAct
            }
        })

        if (closeBtn.current) {
            closeBtn.current.click()
        }
    }


    return (
        <div className="modal fade" id="applyModal" tabIndex={-1} aria-labelledby="modalLabel"
             aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="modalLabel">사내고수 신청</h3>
                    </div>
                    <div className="modal-body">
                        <i className="ico-award"></i>
                        <p className="desc">수행하신 활동을 선택해주세요</p>
                        <div className="select">
                            <button type="button" className="btn-select">활동 전체</button>
                            <ul className="select-option">
                                {/*{
                                    <li className={selectAct == '' ? "selected" : ""} key={'none'}>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault()
                                            setSelectAct('')
                                        }}>
                                            <span>활동 없음</span>
                                        </a>
                                    </li>
                                }*/}
                                {
                                    activeList && activeList.map((active, index) => {
                                        return (
                                            <li className={selectAct == active ? "selected" : ""}
                                                key={index}>
                                                <a href="#" onClick={(e) => {
                                                    e.preventDefault()
                                                    setSelectAct(active)
                                                }}>
                                                    <span>{active}</span>
                                                </a>
                                            </li>
                                        )
                                    })
                                }

                            </ul>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="button-area">
                            <button type="button" className="btn outline sm" data-bs-dismiss="modal"
                                    ref={closeBtn}>취소
                            </button>
                            <button type="button" className="btn sm" onClick={goSubmit}>확인</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProRequestModal
import React, {useEffect, useRef, useState} from "react";
import {BoardVO, DefaultBoardVO} from "../../../../core/board/board.vo";
import MemberUtils from "../../../../common/utils/member.utils";
import M_SummernoteIframe, {
    SummernoteIframeHandle
} from "../../../mobile/common/M_SummernoteIframe";
import APIs from "../../../../common/apis/APIs";
import {HWMap} from "../../../../core/common/common.vo";
import {PageUtils} from "../../../../common/utils/page.utils";
import M_UseStorySelect from "./M_UseStorySelect";
import BoardService from "../../../../core/board/board.service";
import {SessionVO} from "../../../../core/common/session.vo";
import {useNavigate, useParams} from "react-router-dom";
import {ProReqVO} from "../../../../core/pro/pro.vo";
import ToastUtils from "../../../../common/utils/toast.utils";
import PointService from "../../../../core/point/point.service";
import FileUtils from "../../../../common/utils/file.utils";
import ProService from "../../../../core/pro/pro.service"
import ElandUtils from "../../../../common/utils/eland.utils";
import StringUtils from "../../../../common/utils/string.utils";
import useHeader from "../../../../common/hook/useHeader";
import {HeaderBtn} from "../../../../core/common/header";
import useFooter from "../../../../common/hook/useFooter";
import DateUtils from "../../../../common/utils/date.utils";
import SwalUtils from "../../../../common/utils/swal.utils";
import CommonMobileJS from "../../../../common/common_m";
import FileUploader from "../../../pc/common/FileUploader";

const m_UseStoryForm: React.FC = () => {

    useHeader(
        {
            type: 'hidden',
            title: '적용스토리',
            buttons: [HeaderBtn.Back],
        }
    );

    useFooter({
        isHidden: true,
    })


    let {boardKey, bidx} = useParams();
    const navigate = useNavigate()

    const [boardVO, setBoardVO] = useState<BoardVO>(DefaultBoardVO);
    const [selectedBoardVO, setSelectedBoardVO] = useState<BoardVO | null>();
    const [contentList, setContentList] = useState<BoardVO[]>([]);
    const [proList, setProList] = useState<ProReqVO[]>([]);
    const [params, setParams] = useState<HWMap>(new PageUtils())
    const [totalContents, setTotalContents] = useState<number>(0);
    const [sessionVO, setSessionVO] = useState<SessionVO | null>(MemberUtils.getSessionVO());
    const [isSelectActive, setIsSelectActive] = useState(false);
    const [defaultText, setDefaultText] = useState("");

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [keyIndex, setKeyIndex] = useState(0);

    const iframeRef = useRef<SummernoteIframeHandle>(null);
    const $uploadRef = useRef()
    const boardService = new BoardService();

    useEffect(() => {
        if (!bidx) return;
        (async () => {
            const boardNum = parseInt(bidx, 10)

            if (boardNum <= 0) {
                // 임시저장 게시물 있는지 확인
                const result = await boardService.getRecentTempBoardOne({boardKey: boardKey});
                // console.log("⭐getRecentTempBoardOne", result);
                if (result.status == "SUCCESS" && result.data.boardVO != null) {
                    setBoardVO(result.data.boardVO);
                    getSelectBoard(result.data.boardVO);
                } else {
                    // 없으면 새로 생성
                    let result = await BoardService.getBoardInfoOne({boardKey});
                    if (result.status === 'ok' && result.boardInfoVO != null) {
                        const defaultText = result.boardInfoVO.defaultText ? StringUtils.nl2br(result.boardInfoVO.defaultText) : "";
                        setDefaultText(defaultText);
                        setBoardVO({...boardVO, cateCode: "040501", contentHtml: defaultText})
                    }
                }

            } else {
                const result = await boardService.getBoardOne({bidx: bidx, isSaveHis: false});

                if (result.status == "SUCCESS" && result.data) {
                    setBoardVO(result.data.boardVO);
                    getSelectBoard(result.data.boardVO);
                }
            }

            setKeyIndex(keyIndex + 1);
            CommonMobileJS.floatingArea()
        })()
    }, [bidx]);

    // 뒤로가기 : 작성취소
    const cancelWrite = () => {
        SwalUtils.text({
            title: "작성취소",
            message: "작성중인 글을 취소하시겠습니까?<br/>작성 취소 클릭 시 입력한 내용은 저장되지 않습니다.",
            isIcon: false,
            confirmOptions: {
                text: "계속 작성",
            },
            cancelOptions: {
                text: "작성취소",
                eventHandler: () => {
                    navigate('/board/story/use_story');
                }
            },
            isCancel: false
        })
    };

    // 선택한 적용스토리 조회
    const getSelectBoard = (boardVO: BoardVO) => {
        (async () => {
            // 지식콘텐츠
            if ("040501" === boardVO.cateCode) {
                const result = await boardService.getBoardOne({
                    bidx: boardVO.opt5,
                    isSaveHis: false
                });
                if (result.status == "SUCCESS" && result.data) {
                    setSelectedBoardVO(result.data.boardVO);
                }
            }
            // 사내고수라면
            if ("040502" === boardVO.cateCode) {
                const successHandler = async (result: any) => {
                    let selectedBoardVO: BoardVO = DefaultBoardVO();
                    selectedBoardVO.receiverProInfo = result.data.pro;
                    selectedBoardVO.receiverProInfo.jobCodeName = await ElandUtils.convertJobSkillCodesToNames(selectedBoardVO.receiverProInfo.jobCode)
                    setSelectedBoardVO(selectedBoardVO);
                }
                await ProService.proInfoGet(boardVO.opt5 || "0", successHandler)
            }
        })()
    }

    // 등록, 임시저장 버튼 활성화 비활성화
    const isButtonDisabled = (): boolean => {
        console.log("isButtonDisabled 실행")
        const htmlContent = iframeRef.current?.getHtmlContent();
        return (
            !sessionVO ||
            (!htmlContent || htmlContent.trim() === "" || htmlContent.trim() === "<br>") ||
            (!selectedBoardVO && boardVO.cateCode !== "040503") ||
            boardVO.title === ""
        );
    };

    // 목적 변경시
    const handleRadioChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        console.log("event : ", event.target.value)
        let cateCode = event.target.value
        SwalUtils.text({
            title: "목적 수정",
            message: "수정 시, 기존에 작성된 제목과 내용이 사라집니다.<br> 정말 수정하시겠습니까?",
            isIcon: false,
            confirmOptions: {
                text: "수정",
                eventHandler: () => {
                    setSelectedBoardVO(null)
                    setBoardVO({
                        ...boardVO,
                        title: "",
                        cateCode: cateCode
                    });
                    // 에디터 리셋
                    iframeRef.current?.resetEditor();
                }
            },
            cancelOptions: {
                text: "취소",
            },
            isCancel: false
        })
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        if (value.length <= 25) {  // 제목 25글자 제한
            setBoardVO(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleUploadFile = (idx: string) => {
        const parsed = parseInt(idx, 10)
        const thumbIdx = isNaN(parsed) ? null : parsed;
        setBoardVO({...boardVO, thumbIdx: thumbIdx});
    }

    const saveForm = (isTemp: boolean) => {

        if (isSubmitting) return;
        setIsSubmitting(true);

        const htmlContent = iframeRef.current?.getHtmlContent();

        // 지식콘텐츠 경우 : bidx, 사내고수 도움 후기 : proIdx, 활동 후기 : 본인 idx
        let optNum;
        if (boardVO.cateCode == '040501') {
            optNum = selectedBoardVO?.idx
        } else if (boardVO.cateCode == '040502') {
            optNum = selectedBoardVO?.receiverProInfo?.proidx;
        } else {
            optNum = MemberUtils.getSessionVO()?.caidx
        }


        const saveBoard = {
            ...boardVO,
            idx: boardVO.isTemp && !isTemp ? 0 : boardVO.idx,
            contentHtml: htmlContent,
            opt5: optNum,
            writer: sessionVO?.userName,
            writerKey: sessionVO?.coEmailId,
            boardKey: "use_story",
            boardType: "html",
            fileIdxs: boardVO.thumbIdx,
            isTemp: isTemp,
        };

        const {createDate, updateDate, ...filteredSaveBoard} = saveBoard;

        boardService.saveBoardOne(filteredSaveBoard)
        .then(async (res) => {
            if (res.status === "SUCCESS") {
                const resultBoard = res.data;
                if (isTemp) {
                    setBoardVO(resultBoard);
                    ToastUtils.show("임시저장이 완료되었습니다.", 2)
                } else {
                    // 포인트 저장
                    await PointService.savePoint("STORY_CREATE", resultBoard.idx)
                    ToastUtils.show("저장이 완료되었습니다.", 2)
                    navigate("/board/story/use_story")
                }
            } else {
                console.log(":::" + res.status, res.msg);
            }

        });

        setIsSubmitting(false);
    };


    useEffect(() => {
        if (!boardVO.cateCode) return;

        (async () => {
            if (boardVO.cateCode == "040501") {
                const result = await boardService.getRecentViewList(
                    {
                        noPaging: "true",
                        startDate: DateUtils.getOneYearAgoDate(),
                        endDate: DateUtils.formatDateTime(new Date()),
                        boardKeys: APIs.KNOWLEDGE_BOARDKEY
                    });
                setContentList(result.data.content);
                setTotalContents(result.data.totalElements);
            }

            if (boardVO.cateCode == "040502") {
                const successHandler = (result: any) => {
                    setProList(result.data.list);
                    setTotalContents(result.data.totalCount);
                }
                await ProService.getInquiryReceiver({
                    statusCode: "041904,041903",
                    isYear: "true"
                }, successHandler)
            }
            // 사내고수로서 활동한 후기는 조회 안함
            if (boardVO.cateCode == "040503") {
                setContentList([]);
                setProList([]);
                setTotalContents(0);
                setIsSelectActive(false);
            }
        })()

    }, [boardVO?.cateCode]);

    return (

        <>
            <header className="header">
                <h1 className="blind">E-LANDEDU</h1>
                <div className="content-header">
                    <div className="inner">
                        <h2 className="header-title">적용스토리 등록</h2>
                        <div className="header-utils">
                            <div className="left-nav">
                                <button type="button" className="btn-back" onClick={cancelWrite}>
                                    <span className="blind">이전 화면으로</span>
                                </button>
                            </div>
                            <div className="right-nav">
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <main className="content register">
                <section className="form-section">
                    <div className="inner">
                        <div className="form-list">
                            <div className="form-item">
                                <div className="form-title">
                                    <strong className="tit">목적</strong>
                                </div>
                                <div className="sel-bind">
                                    <select
                                        name="sort"
                                        className="sel"
                                        disabled={bidx !== "0"}
                                        value={boardVO.cateCode || ""}
                                        onChange={handleRadioChange}>

                                        <option value="" disabled hidden>
                                            작성 목적을 선택해주세요
                                        </option>
                                        <option value="040501">
                                            지식콘텐츠에 대한 후기를 남길게요
                                        </option>
                                        <option value="040502">
                                            사내고수에게 도움받은 후기를 남길게요
                                        </option>
                                        <option value="040503"
                                                disabled={(!MemberUtils.isPro() && !MemberUtils.isSemiPro())}>
                                            사내고수로서 활동한 후기를 남길게요
                                        </option>
                                    </select>
                                </div>
                            </div>

                            {/* 목적 선택 항목 */}
                            <div className="form-item">
                                <div className="form-title">
                                    <strong className="tit">작성 대상</strong>
                                </div>
                                <div className="sel-bind">
                                    <select
                                        name="sort"
                                        className="sel"
                                        onMouseDown={(e) => {
                                            // cateCode가 040503이면 실행 안함
                                            if (boardVO?.cateCode === '040503') return;

                                            e.preventDefault();
                                            setIsSelectActive(!isSelectActive);
                                        }}
                                        onTouchStart={(e) => {
                                            if (boardVO?.cateCode === '040503') return;

                                            e.preventDefault();
                                            setIsSelectActive(!isSelectActive);
                                        }}
                                        onTouchEnd={(e) => {
                                            if (boardVO?.cateCode === '040503') return;
                                            e.preventDefault();
                                        }}
                                        disabled={bidx !== "0" || boardVO?.cateCode === '040503'}
                                        style={boardVO?.cateCode === '040503' ?
                                            {backgroundColor: "var(--gray-10)", color: "var(--gray-20)"} :
                                            {}}>
                                        <option value="" selected hidden disabled>작성할 대상을 선택해주세요</option>
                                    </select>
                                </div>

                                {isSelectActive && (
                                    <M_UseStorySelect
                                        contentList={contentList || []}
                                        proList={proList || []}
                                        isPro={boardVO?.cateCode === "040502"}
                                        selectBoard={selectedBoardVO || null}
                                        setSelectBoard={(board) => {
                                            setSelectedBoardVO(board);
                                            setIsSelectActive(false); // 'active' 클래스 제거
                                        }}
                                        cancelEvent={() => {
                                            setIsSelectActive(false); // 'active' 클래스 제거
                                        }}
                                    />
                                )}

                                {
                                    selectedBoardVO &&
                                    <>
                                        {   // 지식 컨텐츠
                                            boardVO?.cateCode == '040501' &&
                                            <div className="selected-preview">
                                                <div className="image-wrap">
                                                    <img
                                                        src={selectedBoardVO.boardKey == 'job_data' ? FileUtils.getRandomJobDataImg(selectedBoardVO) : FileUtils.getImageSrc(selectedBoardVO.thumbFile)}
                                                        alt="선택한 적용스토리 썸네일" className="img-thumb"/>
                                                </div>
                                                <div className="text-wrap">
                                                    <strong
                                                        className="tit">{selectedBoardVO.title}</strong>
                                                    <div className="info">
                                                        <span>{selectedBoardVO.writer}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {   // 사내고수
                                            boardVO?.cateCode == '040502' &&
                                            <div className="selected-preview">
                                                <div className="image-wrap">
                                                    <img
                                                        src={selectedBoardVO.receiverProInfo.profileImgSrc}
                                                        alt="사내고수 프로필 사진" className="img-thumb"/>
                                                </div>
                                                <div className="text-wrap">
                                                    <strong
                                                        className="tit">{selectedBoardVO.receiverProInfo.companyAccountVO?.userName}</strong>
                                                    <div className="info">
                                                        <span>{selectedBoardVO.receiverProInfo.jobCodeName}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }

                            </div>
                            <div className="form-item">
                                <div className="form-title">
                                    <strong className="tit">제목</strong>
                                </div>
                                <div className="inp-bind">
                                    <input type="text" name="title" id="" className="inp-text"
                                           value={boardVO?.title}
                                           onChange={handleInputChange}
                                           placeholder="제목을 입력해주세요"/>
                                </div>
                            </div>
                            <div className="form-item">
                                <div className="form-title">
                                    <strong className="tit">내용</strong>
                                </div>
                                <div className="inp-bind">
                                    <M_SummernoteIframe mode={"edit"} ref={iframeRef}
                                                        htmlCode={boardVO?.contentHtml || boardVO?.contentText}
                                                        updateEvent={(text: string)=> {
                                                            setBoardVO(prevState => ({
                                                                ...prevState
                                                            }));
                                                        }}
                                    />
                                </div>
                            </div>
                            <div className="form-item">
                                <div className="form-title type-tooltip">
                                    <strong className="tit">대표 사진</strong>
                                    <div className="tooltip">
                                        <button type="button" className="btn-tooltip"><span
                                            className="blind">툴팁</span>
                                        </button>
                                        <div className="tooltip-content">
                                            <div className="txt">
                                                대표 사진은 베스트 후기 선정 시 섬네일로 설정됩니다
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <FileUploader
                                    key={keyIndex}
                                    ref={$uploadRef} params={{
                                    domId: `boardVO-${boardVO.idx}`,
                                    target: `board`,
                                    fidxs: boardVO.thumbIdx,
                                    maxFileSize: 20000000,
                                    maxFileCount: 1,
                                    allowList: 'jpg,jpeg,png,gif,JPG,JPEG,PNG,GIF',
                                    updateHandler: (_target: string, _files: string) => {
                                        console.log(_target + " ::::: " + _files)
                                        handleUploadFile(_files);
                                    }
                                }}/>

                                <div className="guide-text">
                                    파일 포맷 : JPG, JPEG, PNG / 파일크기 : 20MB이하 | 가로-세로 1:1비율 권장
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <div className="floating-area">
                    <div className="floating-button">
                        <div className="inner">
                            <div className="button-area">
                                {
                                    (boardVO.idx == 0 || boardVO.isTemp) &&
                                    <button className="btn lg gray"
                                            disabled={isButtonDisabled()}
                                            onClick={() => saveForm(true)}
                                    >
                                        임시저장
                                    </button>
                                }
                                <button
                                    className={`btn lg ${(boardVO.idx != 0 && !boardVO.isTemp) && 'full'}`}
                                    disabled={isButtonDisabled()}
                                    onClick={() => saveForm(false)}
                                >
                                    등록하기
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </>
    );
}

export default m_UseStoryForm;
import React, {useEffect} from "react";
import {BoardFileVO, BoardVO} from "../../../core/board/board.vo";
import M_HwsPlayer from "../common/M_HwsPlayer";
import FileUtils from "../../../common/utils/file.utils";

const M_BoardMov : React.FC<{fileList?: BoardFileVO[]; boardVO: BoardVO}> = ({fileList, boardVO}) => {

    const [videoFile, setVideoFile] = React.useState<BoardFileVO>();

    useEffect(() => {
        fileList?.map((item: BoardFileVO, index: number) => {
            if ("mov" === item.fileMode) {
                return setVideoFile(item);
            }
        });
    }, [fileList]);


    return (
        <>
            {
                videoFile?.s3RealLink
                    ? <div className="media-content">
                        <M_HwsPlayer key={FileUtils.getImageSrc(videoFile) || ""} url={FileUtils.getImageSrc(videoFile) || ""} thumbnail={FileUtils.getImageSrc(boardVO.thumbFile)} width={`100%`} height={`100%`} />
                      </div>
                    : <div className="media-content" style={{height:'50px', textAlign: 'center'}}> <h4>영상이 존재하지 않습니다.</h4> </div>
            }
        </>
    );
}

export default M_BoardMov;
import CodeUtils from "./code.utils";
import {BoardVO} from "../../core/board/board.vo";
import DateUtils from "./date.utils";


class StringUtils {

	static isEmpty ($_str? :string) :boolean {
		if ( $_str === undefined || $_str === null || $_str.trim() === '') {
			return true;
		}

		return false;
	}


	static val ($_str :string) :string {
		if (this.isEmpty($_str)) {
			return "";
		} else {
			return $_str;
		}
	}


	static valInt ($_str :string) :number {
		if (this.isEmpty($_str)) {
			return 0;
		}
		return parseInt($_str);
	}


	static nl2br(str: string | undefined) :string {
		if(!str){
			return ''
		}
		return str.replace(/\r\n|\n/g, '<br />')
	}

	static br2nl(str: string | undefined) :string {
		if(!str){
			return ''
		}
		return str.replace(/<br\s*\/?>/gi, '\n');
	}

	static idFilter ( userId :string ) :string {
		if ( userId.length < 4 ){
			return userId
		} else {
			switch (userId.length) {
				case 4:
					return userId.substr(0,3) + '*'
				case 5:
					return userId.substr(0,3) + '**'
				default:
					return userId.substr(0,3) + '**' + userId.substr(5, userId.length)
			}
		}
	}




	static toBase64(string :string) :string { return window.btoa(unescape(encodeURIComponent(string))) }


	static toUTF8(string :string) :string { return decodeURIComponent(escape(window.atob(string))) }


	/**
	 * 공백을 _ 로 치환
	 * @param str
	 */
	static filterEmpty (str :string) :string {
		return str.replaceAll(" ", "_");
	}

	/**
	 * 공백을 _ 로 치환하고, 슬래시를 - 로 치환
	 * @param str
	 * @returns 치환된 문자열
	 */
	static titleUrlFilter(str: string): string {
		str = str.replaceAll(" ", "_")
			.replaceAll("/", "-").replaceAll("!", "%21")
			.replaceAll("#", "%23")
			.replaceAll("\\$", "%24")
			.replaceAll("&", "%26")
			.replaceAll("'", "%27")
			.replaceAll("\\(", "%28")
			.replaceAll("\\)", "%29")
			.replaceAll("\\*", "%2A")
			.replaceAll("\\+", "%2B")
			.replaceAll(",", "%2C")
			.replaceAll(":", "%3A")
			.replaceAll(";", "%3B")
			.replaceAll("=", "%3D")
			.replaceAll("\\?", "%3F")
			.replaceAll("@", "%40")
			.replaceAll("\\[", "%5B")
			.replaceAll("\\]", "%5D");

		str = encodeURIComponent(str);
		return str;
	}

	/**
	 * YYYY.MM.DD 로 형식 변환
	 * @param seconds
	 * 예시 : '2024-06-05T09:49:06.000+09:00' -> 2024.06.05
	 */
	static formatDate(date: string | Date | undefined): string {
		if (!date) {
			return '';
		}

		let _date
		if (date instanceof Date) {
			_date = date;
		} else {
			_date = new Date(date);
		}
		const year = _date.getFullYear().toString().padStart(4, '0');
		const month = (_date.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 +1 필요
		const day = _date.getDate().toString().padStart(2, '0');

		return `${year}.${month}.${day}`;
	}

	/**
	 * seconds -> hours:minutes:seconds
	 * 형식으로 변환
	 * 00:00:00
	 * @param seconds
	 */
	static formatTime(seconds :number) :string {
		// 시간, 분, 초를 계산
		const hours = Math.floor(seconds / 3600);
		const minutes = Math.floor((seconds % 3600) / 60);
		const secs = seconds % 60;

		// 각 값이 10보다 작으면 앞에 '0'을 추가
		const formattedHours = hours < 10 ? `0${hours}` : hours;
		const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
		const formattedSeconds = secs < 10 ? `0${secs}` : secs;

		// 시:분:초 형식의 문자열로 합친 후 반환
		return `${hours > 0 ? formattedHours + ':' : ''}${formattedMinutes}:${formattedSeconds}`;
	}



	static generateUUID() {
		let buf = new Uint16Array(8);
		window.crypto.getRandomValues(buf);
		return ([buf[0], buf[1], buf[2], buf[3], buf[4], buf[5], buf[6], buf[7]]).map(function (n) {
			return n.toString(16);
		}).join('-');
	}



	static formatBytes (bytes :number, decimals:number = 2) {
		if (bytes === 0) return '0 Bytes';

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}



	static jsonToFormData(jsonObject: Record<string, any>): FormData {
		const formData = new FormData();

		for (const key in jsonObject) {
			if (jsonObject.hasOwnProperty(key)) {
				formData.append(key, jsonObject[key]);
			}
		}

		return formData;
	}


	/**
	 * 이름 마스킹 처리 (첫 글자만 보여주고 2글자 마스킹 처리)
	 * @param name
	 * 사용 예시 : maksedName("장효원") -> return 값으로 '장**' 반환
	 */
	static maksedName(name: string | undefined): string {
		// MARK: 만약 첫 글자 외에 나머지 마스킹 처리라면 아래 주석 해제
		// return name ? name.charAt(0) + '*'.repeat(name.length - 1) : '';

		return name ? name.charAt(0) + '*'.repeat(2) : '';
	}


	/**
	 * 특수문자 제거
	 * @param str
	 */
	static deleteSpecial(str: string){
		return str.replace(/[#!@$%^&*()+={}[\]|\\:;"'<>,?\/]/g, '')
	}


}
export default StringUtils;
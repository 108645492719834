import {useNavigate} from "react-router-dom";
import axiosCaller from "../../common/utils/axios.caller";
import APIs from "../../common/apis/APIs";
import {PageParams} from "../../common/utils/page.utils";
import ToastUtils from "../../common/utils/toast.utils";
import {HWMap} from "../common/common.vo";

class MypageService {

    static failHandler = (result:any) =>{
        if (result.status){
            if (result.status === "FAIL"){
                ToastUtils.show(result.msg)

            } else if (result.status === "ERROR"){
                ToastUtils.show(result.msg)
                console.log(result.data)

            } else if (result.status === "UNAUTHORIZATION"){
                ToastUtils.show(result.msg)

                // 로그인 페이지 이동
                const navigate = useNavigate()
                navigate("/login")

            }

        } else {
            ToastUtils.show("네트워크 오류가 발생했습니다. 잠시 후 시도해주세요.")

        }

    }

    static commentSaveOrUpdate = async (comment: FormData,
                                        success: (result: any) => void) => {
        try {
            let result = await axiosCaller.post(APIs.MYPAGE_BOARD + '/comment', comment, {'Content-Type': 'application/x-www-form-urlencoded'})
            if (result.status === 'SUCCESS') {
                success(result)

            } else {
                MypageService.failHandler(result)

            }

        } catch (error) {
            MypageService.failHandler(error)
        }
    }

    static commentOneDelete = async (cidx: number, success: (result: any) => void) => {
        try {
            let params = {cidx: cidx}

            let result = await axiosCaller.delete(APIs.MYPAGE_BOARD + '/comment',params)
            if (result.status === 'SUCCESS') {
                success(result)

            } else {
                MypageService.failHandler(result)

            }

        } catch (error) {
            MypageService.failHandler(error)
        }
    }

    static boardListGet = async (params: PageParams,
                                 success: (result: any) => void) => {
        try {
            let result = await axiosCaller.get(APIs.MYPAGE_BOARD + '/list',params)
            if (result.status === 'SUCCESS') {
                success(result)

            } else {
                MypageService.failHandler(result)

            }

        } catch (error) {
            MypageService.failHandler(error)
        }
    }

    static boardOneGet = async (bidx:string,
                                 success: (result: any) => void) => {
        try {
            let result = await axiosCaller.get(APIs.MYPAGE_BOARD + '/' + bidx)
            if (result.status === 'SUCCESS') {
                success(result)

            } else {
                MypageService.failHandler(result)

            }

        } catch (error) {
            MypageService.failHandler(error)
        }
    }

    static boardOneSave = async (params: HWMap, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.post(APIs.MYPAGE_BOARD, params)
            if (result.status === 'SUCCESS') {
                success(result)
            } else {
                MypageService.failHandler(result)
            }

        } catch (error) {
            MypageService.failHandler(error)
        }
    }

    static boardOneUpdate = async (bidx:string, params: HWMap, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.post(APIs.MYPAGE_BOARD + "/" + bidx, params)
            if (result.status === 'SUCCESS') {
                success(result)
            } else {
                MypageService.failHandler(result)
            }

        } catch (error) {
            MypageService.failHandler(error)
        }
    }


    static boardOneDelete = async (params: {bidx: number}, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.delete(APIs.MYPAGE_BOARD, params);
            if (result.status === 'SUCCESS') {
                success(result)
            } else {
                MypageService.failHandler(result)
            }

        } catch (error) {
            MypageService.failHandler(error)
        }
    }


    static boardOneRecommend = async (params: {bidx: number, isSave:boolean}, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.put(APIs.MYPAGE_BOARD_RECOMMEND, params);
            if (result.status === 'SUCCESS') {
                success(result)
            } else {
                MypageService.failHandler(result)
            }

        } catch (error) {
            MypageService.failHandler(error)
        }
    }

    static privacyGet = async (success: (result: any) => void) => {
        try {
            let result = await axiosCaller.get(APIs.MYPAGE_BOARD + '/privacy')
            if (result.status === 'SUCCESS') {
                success(result)

            } else {
                MypageService.failHandler(result)

            }

        } catch (error) {
            MypageService.failHandler(error)
        }
    }

}

export default MypageService
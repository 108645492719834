import React, {useEffect, useState} from "react";
import {BoardVO} from "../../../core/board/board.vo";
import MainService from "../../../core/main/main.service";
import CardItem from "../board/item/CardItem";
import ElandUtils from "../../../common/utils/eland.utils";
import Swiper from "../../../assets/js/swiper.min";

interface MainNewContentsProps {}

const MainNewContents = (props: MainNewContentsProps) => {
    const [swiperVal, setSwiperVal] = useState<any>(null);
    const [boardList, setBoardList] = useState<BoardVO[]>([]);

    useEffect(() => {
        getNewContents()
    }, []);

    useEffect(() => {
        if (boardList.length > 0){
            initSwiper()
        }
    }, [boardList]);

    const initSwiper = () =>{
        if (swiperVal){
            swiperVal.destroy()
            setSwiperVal(null)
        }

        const newSwiperVal = new Swiper(`.main-new-contents`, {
            slidesPerView: "auto",
            spaceBetween: 66.66666,
            navigation: {
                nextEl: `.main-new-contents-next`,
                prevEl: `.main-new-contents-prev`,
            },
        });

        setSwiperVal(newSwiperVal)
    }

    const getNewContents = () => {
        const params = {
            size: 8,
            sort: "createDate,DESC"
        };

        console.log("getNewContents")
        const successHandler = async (result: any) => {
            console.log("getNewContents result : ", result)
            if (result.data && result.data.list.length > 0) {
                let newList = await ElandUtils.updateBoardListWithJobNames(result.data.list)
                setBoardList(newList);
            }
        }

        MainService.getNewContents(params, successHandler)
    }

    return (
        (boardList != undefined && boardList.length > 0) ?
            <section className="list-section">
                <div className="inner">
                    <div className="section-title">
                        <h3 className="tit">신규 콘텐츠</h3>
                    </div>
                    <div className="board-list">
                        <div className="slide-wrap main-new-contents">
                            <ul className="slide-list swiper-wrapper card-list type1">
                                {
                                    boardList.map(value => {
                                        return <CardItem key={value.idx} board={value}/>
                                    })
                                }
                            </ul>
                        </div>
                        <div className="slide-button">
                            <div className="btn-prev main-new-contents-prev" tabIndex={0} role="button"
                                 aria-label="Previous slide"></div>
                            <div className="btn-next main-new-contents-next" tabIndex={0} role="button"
                                 aria-label="Next slide"></div>
                        </div>
                    </div>
                </div>
            </section> : <></>
    )
}

export default MainNewContents;
import React, {useEffect, useState} from "react";
import MypageService from "../../../core/mypage/mypage.service";
import {BoardInfoVO} from "../../../core/board/BoardInfo.vo";
import {BoardVO, DefaultBoardInfoVO} from "../../../core/board/board.vo";
import {PageParams} from "../../../common/utils/page.utils";
import {Link} from "react-router-dom";

interface MainBoardContentsProps {
    boardKey: string;
}

const MainBoardContentsItem = (props: MainBoardContentsProps) => {
    const [boardInfo, setBoardInfo] = useState<BoardInfoVO>(DefaultBoardInfoVO())
    const [boardList, setBoardList] = useState<BoardVO[]>([])
    const [pageParams, setPageParams] = useState<PageParams>(
        {
            page: 0,
            size: 5,
            sort: "createDate,DESC",
            searchText: "",
            boardKey: props.boardKey ?? "",
            cateCode: "",
            statusCode: ""
        }
    );
    const getBoardList = async () => {
        const successHandler = async (result:any) => {
            setBoardInfo(result.data.boardInfo)
            setBoardList(result.data.boardList.list)
        }

        await MypageService.boardListGet({...pageParams, boardKey: props.boardKey ?? ""}, successHandler)
    }

    useEffect(() => {
        getBoardList()

    }, []);

    return (
        <div>
            <div className="section-title">
                <h2 className="tit">{boardInfo.boardName}</h2>
                <Link to={"/mypage/board/" + props.boardKey} className="link-more">더보기</Link>
            </div>
            <ul className="news-list">
                {
                    boardList.map(value => {
                        return (
                            <li key={value.idx}>
                                <Link to={`/mypage/board/${props.boardKey}/${value.idx}`}>
                                    {value.title}
                                </Link>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default MainBoardContentsItem;
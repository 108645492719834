import React, {useEffect, useState} from "react";
import {CommentVO} from "../../../../core/board/board.vo";
import moment from "moment";
import MypageService from "../../../../core/mypage/mypage.service";
import StringUtils from "../../../../common/utils/string.utils";
import ToastUtils from "../../../../common/utils/toast.utils";
import SwalUtils from "../../../../common/utils/swal.utils";
import ProService from "../../../../core/pro/pro.service";

interface BoardDetailCommentRowProps {
    comment: CommentVO
    updateEvent: (isDelete:boolean)=>void
}

const BoardDetailCommentRow = (props:BoardDetailCommentRowProps) => {
    const [comment, setComment] = useState<CommentVO>(props.comment)
    const [updateMode, setUpdateMode] = useState<boolean>(false)
    const [newCommentContent, setNewCommentContent] = useState<string>(props.comment.contentText ?? "")

    useEffect(() => {
        setComment(props.comment)
        setNewCommentContent(props.comment.contentText ?? "")

    }, [props]);


    const updateComment = () => {
        if (StringUtils.isEmpty(newCommentContent)){
            ToastUtils.show('내용을 입력해주세요.')
            return
        }

        const formData = new FormData();
        formData.append('cidx', comment.cidx.toString())
        formData.append('bidx', comment.bidx?.toString() ?? "0")
        formData.append('pidx', comment.pidx.toString())
        formData.append('contentText', newCommentContent)

        const successHandler = () => {
            ToastUtils.show('저장되었습니다.')
            setUpdateMode(false)
            props.updateEvent(false)
        }

        MypageService.commentSaveOrUpdate(formData, successHandler)
    }

    const deleteComment = async (e:any) => {
        e.preventDefault()
        SwalUtils.text({
            title : `삭제하기`,
            message : `
					댓글 삭제 시 동작을 되돌릴 수 없습니다.<br>
						정말 댓글을 삭제하시겠습니까?
				`,
            isCancel : true,
            confirmOptions : {
                text : "삭제",
                eventHandler : async () => {
                    const successHandler = () => {
                        ToastUtils.show("삭제되었습니다.")
                        props.updateEvent(true)

                    }
                    await MypageService.commentOneDelete(comment.cidx, successHandler)
                }
            },
            cancelOptions : {
                text : "취소"
            }
        })
    }

    const updateModeChange = (e:any) => {
        e.preventDefault()
        setUpdateMode(!updateMode)
    }

    const cancelButtonClick = () => {
        setUpdateMode(false)
        setNewCommentContent(props.comment.contentText ?? "")
    }

    return (
        updateMode ? (
            <li>
                <div className="comment-modify">
                    <div className="comment-util">
                        <span className="name">{comment.writer}</span>
                        <div className="button-area">
                            <button type="button" className="btn gray" onClick={e=>cancelButtonClick()}>취소</button>
                            <button type="button" className="btn blue" onClick={e=>updateComment()}>수정</button>
                        </div>
                    </div>
                    <div className="comment-input">
                                    <textarea name="" id=""
                                              cols={30} rows={3}
                                              className="inp-text"
                                              value={newCommentContent}
                                    onChange={e=> setNewCommentContent(e.target.value)}/>
                    </div>
                    <span className="date">{moment(comment.createDate).format("YYYY-MM-DD")}</span>
                </div>
            </li>
            ) : (
            <li>
                <div className="comment-item">
                    <span className="name">{comment.writer}</span>
                    <p className="text-area" dangerouslySetInnerHTML={{__html: StringUtils.nl2br(comment.contentText ?? "")}}></p>
                    <span className="date">{moment(comment.createDate).format("YYYY-MM-DD")}</span>
                    {
                        comment.me && (
                            <div className="util">
                                <a href="#" className="btn-modify" onClick={updateModeChange}><span className="blind">수정하기</span></a>
                                <a href="#" className="btn-delete" onClick={deleteComment}><span className="blind">삭제하기</span></a>
                            </div>
                        )
                    }

                </div>
            </li>
        )
    )
}

export default BoardDetailCommentRow
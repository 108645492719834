import React, {useEffect, useState} from "react";
import CommonJS from "../../../../../../common/common";
import {useNavigate, useParams} from "react-router-dom";
import ProService from "../../../../../../core/pro/pro.service";
import PointService from "../../../../../../core/point/point.service";
import ToastUtils from "../../../../../../common/utils/toast.utils";
import toastUtils from "../../../../../../common/utils/toast.utils";
import StringUtils from "../../../../../../common/utils/string.utils";

interface ProEduTalkReceiverJournalProps {
    isWrite: boolean,
    journal: string,
    statusCode: string,
    updateEvent: () => void
}

const M_ProEduTalkReceiverJournal = (props: ProEduTalkReceiverJournalProps) => {
    const {statusCode, updateEvent} = props
    const {bidx} = useParams()
    const [isWrite, setIsWrite] = useState(props.isWrite)
    const [journal, setJournal] = useState(props.journal)

    useEffect(() => {
        setIsWrite(props.isWrite)
        setJournal(props.journal)
    }, [props]);

    const updateJournal = async () => {
        if (journal === '') {
            toastUtils.show("입력하지 않은 항목이 존재합니다")
            return
        }
        let params = {journal:journal, bidx: parseInt(bidx ?? "0")}
        const successHandler = async () => {
            ToastUtils.show("나의 일지 등록이 완료되었습니다.")
            if (statusCode !== "041904"){
                await PointService.savePoint("PRO_WRITE_LOG", bidx ?? "0")
            }
            updateEvent()
        }

        await ProService.inquiryJournal(params, successHandler)


    }
    const getAccordionClass = () => {
        // if (isWrite) {
        //     setTimeout(()=>CommonJS.accordion())
        //     return "accordion active"
        // }

        return "accordion-none active"
    }


    return (
        <div className={getAccordionClass()}>
            <div className="inner">
                <h3 className="tit-accordion">나의 일지</h3>
                <div className="accordion-content">
                        {
                            isWrite ? (
                                <div className="comment type2">
                                    <div className="comment-input">
                                        <div className="input-wrap">
                                            <div className="comment-text">
                                            <textarea name="" id="" cols={30} rows={3}
                                                      className="inp-text"
                                                      placeholder="일지를 작성해주세요"
                                                      value={journal}
                                                      onChange={e => {
                                                          setJournal(e.target.value)
                                                      }}
                                            ></textarea>
                                            </div>
                                            <div className="comment-util">
                                                <div className="file-upload"></div>
                                                <div className="button-area">
                                                    <button type="button"
                                                            className="btn-register"
                                                            onClick={e => updateJournal()}>
                                                        등록
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="journal-content">
                                    <p className="text-area"
                                       dangerouslySetInnerHTML={{__html: StringUtils.nl2br(journal)}}></p>
                                    <div className="util">
                                        <a href="#"
                                           className="btn-modify"
                                           onClick={e => {
                                               e.preventDefault()
                                               setIsWrite(true)
                                           }}
                                        ><span className="blind">수정</span></a>
                                    </div>
                                </div>
                            )
                        }
                </div>
            </div>
        </div>
    )
}

export default M_ProEduTalkReceiverJournal
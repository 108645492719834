import React, {useEffect, useState} from "react";
import {HWMap} from "../../../core/common/common.vo";
import {useSelector} from "react-redux";
import {RootState} from "../../../common/redux/store";
import {HeaderBtn} from "../../../core/common/header";
import {Link, useLocation, useNavigate} from "react-router-dom";
import MemberUtils from "../../../common/utils/member.utils";

const M_Header = ({hwMap}:HWMap) => {
    const locations = useLocation();
    const navigate = useNavigate();
    const [notifCount, setNotifCount] = useState<number>(0)

    const fetchNotifCount = async () => {
        const count = await MemberUtils.getNotifCount()
        setNotifCount(count)
    }

    useEffect(() => {
        if (MemberUtils.isLogin()){
            fetchNotifCount()
        }

    }, [locations.pathname]);

    // Redux 상태에서 헤더 설정 가져오기
    const { type, title, buttons } = useSelector((state: RootState) => state.header);

    // 각 버튼의 표시 여부 결정
    const showBackButton = buttons?.includes(HeaderBtn.Back);
    const showGoHomeBackButton = buttons?.includes(HeaderBtn.GoHomeBackButton);
    const showSearchButton = buttons?.includes(HeaderBtn.Search);
    const showNotifyButton = buttons?.includes(HeaderBtn.Notify);
    const showCloseButton = buttons?.includes(HeaderBtn.Close);
    const showLoginCloseButton = buttons?.includes(HeaderBtn.LoginClose);
    const showMenuButton = buttons?.includes(HeaderBtn.Menu);

    // 검색 버튼
    const createSearchButton = () => {
        return (
            <Link to={"/search"} className="btn-search">
                <span className="blind">검색</span>
            </Link>
        )
    }

    // 메뉴 버튼
    const createMenuButton = () => {
        return (
            <Link to={"/menu"}>
                <button type="button" className="btn-menu">
                    <span className="blind">전체메뉴</span>
                </button>
            </Link>
        )
    }

    // 알림 버튼
    const createAlarmButton = () => {
        return (
            <Link to={"/mypage/alarm"}>
                <button type="button" className={`btn-notify ${notifCount > 0 && 'active'}`}>
                    <span className="blind">알림함</span>
                </button>
            </Link>
        )
    }

    if (type === 'hidden'){
        return (<></>)
    }

    return (
        <header className="header">
            <h1 className="blind">{type === 'main' ? 'E-LANDEDU' : '헤더'}</h1>
            {type === 'main' ? (
                <div className="main-header">
                    <h2 className="header-logo">
                        <a href="#">
                            <span className="blind">Home</span>
                        </a>
                    </h2>
                    <div className="header-utils">
                        {showSearchButton && createSearchButton()}
                        {showNotifyButton && createAlarmButton()}
                        {showMenuButton && createMenuButton()}
                    </div>
                </div>
            ) : (
                <div className={`${type}-header`}>
                    <div className="inner">
                        <h2 className="header-title">{title}</h2>
                        <div className="header-utils">
                            {type === 'content' && (
                                <>
                                    <div className="left-nav">
                                        {showBackButton && (
                                        <button type="button" className="btn-back" onClick={() => {
                                            navigate(-1)
                                        }}>
                                            <span className="blind">이전 화면으로</span>
                                        </button>
                                        )}
                                        {showGoHomeBackButton && (
                                            <button type="button" className="btn-back" onClick={() => {
                                                location.href = '/'
                                            }}>
                                                <span className="blind">이전 화면으로</span>
                                            </button>
                                        )}
                                    </div>

                                    <div className="right-nav">
                                        {showSearchButton && createSearchButton()}
                                        {showNotifyButton && createAlarmButton()}
                                        {(showCloseButton || showLoginCloseButton) && (
                                            <button type="button" className="btn-close" onClick={() => navigate(showCloseButton ? -1 : -2)}>
                                                <span className="blind">닫기</span>
                                            </button>
                                        )}
                                        {showMenuButton && createMenuButton()}
                                    </div>
                                </>
                            )}
                            {type === 'sub' && (
                                <>
                                    {showSearchButton && createSearchButton()}
                                    {showNotifyButton && createAlarmButton()}
                                    {showCloseButton && (
                                        <button type="button" className="btn-close">
                                            <span className="blind">닫기</span>
                                        </button>
                                    )}
                                    {showMenuButton && createMenuButton()}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </header>
    )
}

export default M_Header;
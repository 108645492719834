import React, {useEffect, useRef, useState} from "react";
import Swiper from "../../../assets/js/swiper.min"
import {BannerVO} from "../../../core/main/main.banner.vo";
import MainBannerItem from "./MainBannerItem";

interface MainBannerProps {
    bannerList?: Array<BannerVO>
}

/*
    메인 배너 (1000 * 400)
    - 자동 슬라이드(필수로 2개 이상 표출되고 있어야 함)
    - 혹여 노출 배너가 1개일 때 1개가 반복해서 롤링되도록
    http://khane7.test-site.kr/elandedu/html/1_home/1-1_%ED%99%88_%EB%A1%9C%EA%B7%B8%EC%9D%B8_%EC%A0%84.html
*/
const MainBanner = (props: MainBannerProps) => {
    let { bannerList } = props;

    // @ts-ignore
    const swiperRef = useRef<Swiper | null>(null);

    const initSwiper = () => {
        if (swiperRef.current) {
            swiperRef.current.destroy(true, true);
        }

        swiperRef.current = new Swiper(".visual-section .inner", {
            centeredSlides: true,
            loop: true,
            slidesPerView: "auto",
            spaceBetween: 24, // 간격

            autoplay: {
                delay: 5000, // 5초마다 슬라이드 전환
                disableOnInteraction: false, // 사용자 상호작용 후에도 자동 재생 지속
            }
        });
    }

    useEffect(() => {

        initSwiper();
    }, [bannerList])

    return (
        <div className="visual-section">
            <div className="inner">
                <ul className="visual-list swiper-wrapper">
                    {bannerList && bannerList.map((item, index) => (
                        <MainBannerItem key={index} item={item}></MainBannerItem>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default MainBanner;
import {useNavigate} from "react-router-dom";
import axiosCaller from "../../common/utils/axios.caller";
import APIs from "../../common/apis/APIs";
import SwalUtils from "../../common/utils/swal.utils";
import ToastUtils from "../../common/utils/toast.utils";

export class CompanyService {

    static failHandler = (result:any) =>{
        if (result.status){
            if (result.status === "FAIL"){
                ToastUtils.show(result.msg)

            } else if (result.status === "ERROR"){
                ToastUtils.show(result.msg)
                console.log(result.data)

            } else if (result.status === "UNAUTHORIZATION"){
                ToastUtils.show(result.msg)

                // 로그인 페이지 이동
                const navigate = useNavigate()
                navigate("/login")

            }

        } else {
            ToastUtils.show("네트워크 오류가 발생했습니다. 잠시 후 시도해주세요.")
        }

    }

    static nicknameValidCheck = async (nickname: string, success:(result:any)=>void) => {
        try {
            let params = {nickname:nickname}
            const result = await axiosCaller.get(APIs.CO_ACCOUNT + "/nickname",params);

            if (result.status === 'SUCCESS') {
                success(result);

            } else {
                CompanyService.failHandler(result);

            }
        } catch (error) {
            CompanyService.failHandler(error);
        }
    };

    static accountAndProInfoUpdate = async (formData: FormData, success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.post(APIs.ACCOUNT_PRO + "/info",
                formData,
                {'Content-Type': 'application/x-www-form-urlencoded'}
            )

            if (result.status === 'SUCCESS') {
                success(result);

            } else {
                CompanyService.failHandler(result);

            }
        } catch (error) {
            CompanyService.failHandler(error);
        }
    };

    static checkAndReloadAccount = async (success:(result:any)=>void) => {
        try {
            const result = await axiosCaller.post(APIs.SESSION + "/info", {})

            if (result.status === 'SUCCESS') {
                success(result);

            } else {
                CompanyService.failHandler(result);

            }
        } catch (error) {
            CompanyService.failHandler(error);
        }
    };

}
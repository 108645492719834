import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {CodeVO} from "../../../../core/common/code.vo";
import M_SummernoteIframe, {SummernoteIframeHandle} from "../../common/M_SummernoteIframe";
import AxiosCaller from "../../../../common/utils/axios.caller";
import APIs from "../../../../common/apis/APIs";
import CommonJS from "../../../../common/common";
import MypageService from "../../../../core/mypage/mypage.service";
import MemberUtils from "../../../../common/utils/member.utils";
import {BoardVO} from "../../../../core/board/board.vo";
import ToastUtils from "../../../../common/utils/toast.utils";
import M_BottomButton from "../../common/M_BottomButton";
import useHeader from "../../../../common/hook/useHeader";
import {HeaderBtn} from "../../../../core/common/header";
import useFooter from "../../../../common/hook/useFooter";
import FileUploader from "../../../pc/common/FileUploader";

const M_MypageBoardForm = () => {
    useHeader({
        type:"content",
        title:"게시글 작성",
        buttons:[HeaderBtn.Back]
    })
    useFooter({isHidden:true})

    const navigate = useNavigate()
    const {boardKey} = useParams()
    const location = useLocation()
    const [boardVO, setBoardVO] = useState<BoardVO | null>(location.state.board ?? null)
    const [thumbnailFidxs, setThumbnailFidxs] = useState<string>(location.state.thumbFidxs ?? "")
    const [fidxs, setFidxs] = useState<string>(location.state.fidxs ?? "")
    const [title, setTitle] = useState<string>("")
    const [content, setContent] = useState<string>("")
    const [cateCodeList, setCateCodeList] = useState<CodeVO[]>([])

    const [cateCode, setCateCode] = useState<string>('')
    const [isFile, setIsFile] = useState<boolean>(location.state.isFile ?? true)
    const [isForm, setIsForm] = useState<boolean>(location.state.isForm ?? false)
    const [formText, setFormText] = useState<string>(location.state.formText ?? "")

    const cateCodeBtn = useRef<HTMLButtonElement>(null)

    const iframeRef = useRef<SummernoteIframeHandle>(null);

    useEffect(() => {
        location.state.cateCode && getCateCode(location.state.cateCode)

    }, []);

    const getCateCode = async (code:string) => {
        const result = await AxiosCaller.get(APIs.COMMON_CODE + "/getCodeTree", {code: code})
        if (result.status === 'ok') {
            setCateCodeList(result.codeList);
            readyToUpdate()

        }
    }

    const readyToUpdate = () => {
        if (boardVO) {
            setTitle(boardVO.title)
            setCateCode(boardVO.cateCode ?? '')
            setContent(getContent())
            setFidxs(fidxs)
            if (cateCodeBtn.current && boardVO.cateName) {
                cateCodeBtn.current.innerHTML = boardVO.cateName
            }
        }
        CommonJS.select()
    }


    const updateCateCode = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let code = e.target.value
        setCateCode(code)
    }

    const handleUploadAttachFile = (idxs:string) => {
        setFidxs(idxs)
    }
    const handlethumbnailFile = (idxs:string) => {
        setThumbnailFidxs(idxs)
    }


    const saveBoard = async () => {
        if (cateCode === "") {
            ToastUtils.show("분류를 선택해주세요.", 2)
            return false;
        }

        if (title.trim() === "") {
            ToastUtils.show("제목을 작성해주세요.", 2)
            return false;
        }

        const htmlContent = iframeRef.current?.getHtmlContent();
        if (!htmlContent || htmlContent.trim() === "" || htmlContent.trim() === "<br>") {
            ToastUtils.show("내용을 작성해주세요.", 2)
            return false;
        }

        let params = {
            boardKey: boardKey ?? "",
            title: title,
            content:iframeRef.current?.getHtmlContent() ?? "",
            coCode: MemberUtils.getSessionVO()?.coCode ?? "",
            cateCode: cateCode,
            fidxs: fidxs,
            thumbFidxs: thumbnailFidxs
        }

        await MypageService.boardOneSave(params, showComplete)
    }

    const updateBoard = async () => {
        const bidx = boardVO?.idx.toString() ?? "0"

        let params = {
            boardKey: boardKey ?? "",
            title: title,
            content: iframeRef.current?.getHtmlContent() ?? "",
            cateCode: cateCode,
            fidxs: fidxs,
            thumbFidxs: thumbnailFidxs
        }

        await MypageService.boardOneUpdate(bidx, params, showComplete);
    }


    const showComplete = (result:any) => {
        ToastUtils.show("저장이 완료되었습니다.")
        navigate(-1);
    }

    const getContent = () => {
        if(boardVO){
            return boardVO?.contentHtml ?? ""
        } else {
            return isForm ? formText : ""
        }

    }

    return (
        <main className="content register">
            <section className="form-section">
                <div className="inner">
                    <div className="form-list">
                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">분류</strong>
                            </div>
                            <div className="sel-bind">
                                <select name="sort" className="sel" value={cateCode}
                                        onChange={updateCateCode}>
                                    <option value="" hidden disabled>분류를 선택해주세요</option>
                                    {
                                        cateCodeList.map((code, index) => {
                                            return (
                                                <option key={index} value={code.code}>
                                                    {code.codeName}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">제목</strong>
                            </div>
                            <div className="inp-bind">
                                <input type="text" name="" id=""
                                       className="inp-text"
                                       onChange={event => {
                                           setTitle(event.target.value)
                                       }}
                                       value={title}
                                       placeholder="제목을 입력해주세요"/>
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">대표사진</strong>
                            </div>
                            <FileUploader params={{
                                domId: `boardVO-thumbnail`,
                                domText: `썸네일로 등록할 사진을 업로드해주세요`,
                                target: `board`,
                                fidxs: thumbnailFidxs,
                                allowList: "jpg,jpeg,png",
                                maxFileSize: 20,
                                maxFileCount: 1,
                                updateHandler: (_target: string, _files: string) => {
                                    console.log(_target + " ::::: " + _files)
                                    handlethumbnailFile(_files);
                                }
                            }}/>
                            <div className="guide-text">
                                파일 포맷 : JPG, JPEG, PNG / 파일크기 : 20MB이하 | 가로-세로 4:3비율 권장
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-title">
                                <strong className="tit">내용</strong>
                            </div>
                            <div className="inp-bind">
                                <M_SummernoteIframe ref={iframeRef} mode={"edit"}
                                                    htmlCode={content}/>
                            </div>
                        </div>
                        {
                            isFile && (
                                <div className="form-item">
                                    <div className="form-title">
                                        <strong className="tit">첨부파일</strong>
                                    </div>
                                    <FileUploader params={{
                                        domId: `boardVO-attach`,
                                        domText: `첨부파일을 등록해주세요`,
                                        target: `board`,
                                        fidxs: fidxs,
                                        maxFileSize: 100,
                                        maxFileCount: 1,
                                        updateHandler: (_target: string, _files: string) => {
                                            console.log(_target + " ::::: " + _files)
                                            handleUploadAttachFile(_files);
                                        }
                                    }}/>
                                    <div className="guide-text">
                                        파일크기 : 100MB이하 / 1개 파일만 등록 가능합니다. 여러 개일 경우 ZIP파일로 압축하여 업로드해주세요
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </section>
            <M_BottomButton name={"등록하기"}
                            onClick={boardVO ? updateBoard : saveBoard}/>
        </main>
    )
}

export default M_MypageBoardForm
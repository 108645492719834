import React, {useEffect, useState} from "react";
import Swiper from "../../../../assets/js/swiper.min";
import {BoardVO} from "../../../../core/board/board.vo";
import CardItem from "../../board/item/CardItem";
import ElandUtils from "../../../../common/utils/eland.utils";

interface ProContentSlideProps {
    isContent: boolean;
    count: number;
    contentList: BoardVO[]
}

const ProContentSlide = (props: ProContentSlideProps) => {
    const {isContent, count, contentList} = props

    const [proContentList, setProContentList] = useState(contentList)

    useEffect(() => {
        const renderList = async () => {
            let updatedBoardList = await ElandUtils.updateBoardListWithSkillNames(props.contentList);
            updatedBoardList = await ElandUtils.updateBoardListWithJobNames(updatedBoardList);

            setProContentList(updatedBoardList)
        }

        renderList()
    }, [props]);

    const installSwiper = () => {
        if (isContent) {
            new Swiper(".mater-content .slide-wrap", {
                loop: false,
                slidesPerView: "auto",
                spaceBetween: 64,
                navigation: {
                    nextEl: ".btn-next",
                    prevEl: ".btn-prev",
                },
            })
        } else {
            new Swiper(".mater-resources .slide-wrap", {
                loop: false,
                slidesPerView: "auto",
                spaceBetween: 64,
                navigation: {
                    nextEl: ".btn-next",
                    prevEl: ".btn-prev",
                },
            })
        }
    }

    useEffect(() => {
        setTimeout(installSwiper, 100)

    }, [props]);

    const getContentCardList = () => {
        let isExist = proContentList.length > 0;
        return isExist ?
            (
                <div className="slide-wrap card-list type1">
                    <ul className="slide-list swiper-wrapper">
                        {
                            proContentList.map((item) => (
                                <CardItem key={item.idx} board={item}/>
                            ))
                        }
                    </ul>
                    <div className="button-area">
                        <div className="btn-prev"></div>
                        <div className="btn-next"></div>
                    </div>
                </div>
            )
            :
            (
                <div className="content-empty">
                    <p className="txt">아직 업로드된 콘텐츠가 없습니다</p>
                </div>
            )
    }

    const getProDataCardList = () => {
        let isExist = proContentList.length > 0;
        return isExist ?
            (
                <div className="slide-wrap card-list type1">
                    <ul className="slide-list swiper-wrapper">
                        {
                            proContentList.map((item) => (
                                <CardItem key={item.idx} board={item}></CardItem>
                            ))
                        }
                    </ul>
                    <div className="button-area">
                        <div className="btn-prev"></div>
                        <div className="btn-next"></div>
                    </div>
                </div>
            )
            :
            (
                <div className="content-empty">
                    <p className="txt">아직 업로드된 자료가 없습니다</p>
                </div>
            )
    }


    return (
        <section className="list-section">
            <div className="inner">
                <div className="section-title">
                    <h3 className="tit">{isContent ? '고수콘텐츠' : '고수자료실'}</h3>
                    <span className="num"><b>{count}</b>건</span>
                </div>
                <div
                    className={isContent ? "board-list mater-content" : "board-list mater-resources"}>
                    {
                        isContent ? getContentCardList() : getProDataCardList()
                    }
                </div>
            </div>
        </section>

    )


}

export default ProContentSlide
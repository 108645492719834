import React, {useEffect} from "react";
import {BoardVO} from "../../../../core/board/board.vo";
import CommonJS from "../../../../common/common";
import StringUtils from "../../../../common/utils/string.utils";

const QuestionRow = (props:BoardVO) => {

    useEffect(() => {
        CommonJS.accordion()
    }, []);

    return (
        <div className="accordion">
            <div className="tit-accordion">{props.title}</div>
            <div className="accordion-content">
                <p className="txt" dangerouslySetInnerHTML={{__html: StringUtils.nl2br(props.contentHtml ?? "")}}></p>
            </div>
        </div>
    )
}

export default QuestionRow
import React, {useEffect, useState} from "react";
import {CommentFileVO, CommentVO} from "../../../../../../core/board/board.vo";
import ProEduTalkCommentRow from "../common/ProEduTalkCommentRow";
import ProEduTalkCommentInput from "../common/ProEduTalkCommentInput";
import moment from "moment/moment";
import CommonJS from "../../../../../../common/common";
import ProEduTalkReview from "../common/ProEduTalkReview";
import {useParams} from "react-router-dom";
import MemberUtils from "../../../../../../common/utils/member.utils";
import ProEduTalkReceiverJournal from "./ProEduTalkReceiverJournal";
import StringUtils from "../../../../../../common/utils/string.utils";
import FileUtils from "../../../../../../common/utils/file.utils";
import APIs from "../../../../../../common/apis/APIs";

interface ProEduTalkReceiverReplyProps {
    statusCode: string,
    reply: CommentVO
    commentList: CommentVO[],
    updateEvent: () => void,
    editReplyEvent:(isEdit:boolean) => void,
    journal: string,
    writerKey: string
}

const ProEduTalkReceiverReply = (props: ProEduTalkReceiverReplyProps) => {
    const {bidx} = useParams()
    const {reply, commentList, updateEvent, editReplyEvent, journal, writerKey} = props
    const [statusCode, setStatusCode] = useState<string>(props.statusCode)

    useEffect(() => {
        setTimeout(()=>CommonJS.accordion())
    }, []);


    useEffect(() => {
        setStatusCode(props.statusCode)
    }, [props]);


    const isOnly = () =>{
        return commentList.filter(value =>
            value.writerKey === writerKey || value.writerKey || MemberUtils.getLoginId()).length <= 0
    }
    const isAllReply = () => {
        return commentList.filter(value =>
            value.writerKey === writerKey || value.writerKey || MemberUtils.getLoginId()).length >= 2
    }
    const isComplete = () => statusCode === "041904" || statusCode === "041903"
    const isShowUnderBar = () => isOnly() ? {borderBottom: '0px'} : {}


    const getUpdateBtn = () => {
        return isOnly() && !isComplete() && (
            <a href="#" className="btn-modify"
               onClick={e => {
                   e.preventDefault()
                   editReplyEvent(true)
               }}><span
                className="blind">수정하기</span></a>
        )
    }

    const getReplyAndAddCommentArea = () => {
        return !isOnly() &&
            (
                <div className="comment type2">
                    <ul className="comment-list">
                        {
                            // 추가 답변 영역
                            commentList.map((value, index) => (
                                <ProEduTalkCommentRow key={index} comment={value} updateEvent={updateEvent} isEditable={!isComplete()}/>
                            ))
                        }
                    </ul>
                    {
                        // 답변을 아직 하지 않은 경우 입력창 추가
                        (!isAllReply() && !isComplete()) &&
                        <ProEduTalkCommentInput comment={null} updateEvent={updateEvent} enableTemp={false} isAddition={false}/>
                    }
                </div>
            )
    }

    const getReviewArea = () => {
        return isComplete() && <ProEduTalkReview bidx={bidx ?? "0"} targetId={MemberUtils.getLoginId()} isReceiver={true}/>
    }
    
    const getJournalArea = () => {
        return isComplete() && <ProEduTalkReceiverJournal isWrite={journal === ""}
                                                          journal={journal}
                                                          updateEvent={updateEvent}
                                                          statusCode={statusCode}/>
    }

    const getAccordionClass = () => {
        if (isComplete()){
            CommonJS.accordion()
            return "accordion active"
        }

        return "accordion-none active"
    }

    const downloadFile = async (e: any, fileVO: CommentFileVO) => {
        e.preventDefault()
        window.location.href = `${APIs.BOARD_COMMENTS_FILES_DOWN}${fileVO.cfidx}`
    }


    return (
        <>
            <section className="reply-section">
                <div className="inner">
                    <div className={getAccordionClass()}>
                        <h3 className="tit-accordion">답변</h3>
                        <div className="accordion-content">
                            <div className="reply">
                                <div className="reply-util">
                                    <div className="writer">
                                        <div className="image-wrap">
                                            <img
                                                src={FileUtils.getFileUrl(reply.profileSrc ?? "")}
                                                onError={FileUtils.onErrorImg}
                                                alt="프로필 이미지" className="img-profile"/>
                                        </div>
                                        <div className="text-wrap">
                                            <span className="name"><b>{reply.writer}</b>{MemberUtils.getProStr(reply.writerRate ?? "")}</span>
                                            <span className="job">{reply.coName}</span>
                                        </div>
                                    </div>
                                    <div className="util">
                                        {
                                            getUpdateBtn()
                                        }
                                    </div>
                                </div>
                                <div className="reply-content" style={isShowUnderBar()}>
                                    <ul className="attach-list">
                                        {
                                            reply.commentFileList && reply.commentFileList?.map((value, index) => {
                                                return (
                                                    <li key={index}>
                                                        <b className="tit">첨부파일 </b>
                                                        <a href="#"
                                                           onClick={e=>downloadFile(e,value)}><span className="path">{value.filename}</span></a>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    <div className="text-area" dangerouslySetInnerHTML={{__html: StringUtils.nl2br(reply.contentText)}}>
                                    </div>
                                    <span className="date">{moment(reply.updateDate).format("YYYY.MM.DD")}</span>
                                </div>
                            </div>
                            {getReplyAndAddCommentArea()}
                        </div>
                    </div>

                    {getReviewArea()}
                    {getJournalArea()}
                </div>
            </section>
        </>
    )
}

export default ProEduTalkReceiverReply
import React, {useEffect, useState} from "react";
import {HWMap} from "../../../../core/common/common.vo";
import {AnsTstVO, EduAnswerVO, EduContentVO, EduMainVO, QstTstOptionVO, QstTstVO, TstAnsVO} from "../../../../core/education/education.vo";
import WebUtils from "../../../../common/utils/web.utils";
import DateUtils from "../../../../common/utils/date.utils";
import {M_EduContentIndex, M_EduTitleDom} from "../M_EduMainDetail";
import educationService from "../../../../core/education/education.service";
import {useNavigate, useParams} from "react-router-dom";
import SwalUtils from "../../../../common/utils/swal.utils";
import PointService from "../../../../core/point/point.service";
import ToastUtils from "../../../../common/utils/toast.utils";
import EducationService from "../../../../core/education/education.service";


const M_QstTstResult:React.FC<{paramMap:HWMap}> = ({paramMap}) => {
	
	const navigate = useNavigate();
	let { emIdxText, stepText, eduTitle } = useParams();
	
	const [requestStep, setRequestStep] = useState()
	const [eduMainVO, setEduMainVO] = useState<EduMainVO|null>(null)
	const [contentList, setContentList] = useState<EduContentVO[]|null>(null)
	const [eduContentVO, setContentVO] = useState<EduContentVO|null>(null)
	const [qstTstList, setQstTstList] = useState<QstTstVO[]|null>(null)
	const [qstTstOption, setQstTstOption] = useState<QstTstOptionVO>()
	const [eduAnswerVO, setEduAnswerVO] = useState<EduAnswerVO|null>(null)
	const [ansTstList, setAnsTstList] = useState<AnsTstVO[]|null>(null)
	
	const [isPass, setIsPass] = useState<boolean>(false);
	const [score, setScore] = useState<number>(0);
	const [scoreText, setScoreText] = useState<string>();
	const [resultText, setResultText] = useState<string>();
	const [scoreCutoff, setScoreCutoff] = useState<number>(0);
	
	const [created, setCreated] = useState<boolean>(false)
	const [mounted, setMounted] = useState<boolean>(false)

	const [isLoading, setIsLoading] = useState(false);
	
	/**
	 * 받아온 데이터 정의
	 */
	useEffect(() => {
		WebUtils.log(paramMap)
		if (paramMap.eduMainVO) {
			setRequestStep(paramMap.requestStep)
			setEduMainVO(paramMap.eduMainVO)
			setContentList(paramMap.contentList)
			setContentVO(paramMap.eduContentVO);
			
			(async () => {
				const resultDetail = await educationService.getContentDetail({
					ecIdx: paramMap.eduContentVO.ecIdx
				})
				
				/**
				 * 정답번호 저장 처리
				 */
				resultDetail.qstTstList?.map((qstTstVO:QstTstVO, qt_index:number) => {
					let j = 1;
					qstTstVO.correctNumber = []
					
					qstTstVO.answerList.map((tstAnsVO:TstAnsVO, ta_index:number) => {
						if ( tstAnsVO.isCorrect ) {
							qstTstVO.correctNumber.push(j)
						}
						j++;
					})
				})
				
				setQstTstList(resultDetail.qstTstList)
				setQstTstOption(resultDetail.qstTstOption)
				
				WebUtils.goTop()
			})()
			
			setEduAnswerVO( paramMap.answerList[paramMap.requestStep] )
			setAnsTstList( paramMap.answerList[paramMap.requestStep]?.ansTstList )
			//console.log(paramMap.answerList[paramMap.requestStep]?.ansTstList)
		}
	}, [paramMap]);
	
	
	/**
	 * 답변 세팅시
	 * 점수 설정
	 */
	useEffect(() => {
		
		if ( qstTstList && qstTstOption && ansTstList ) {
			
			const scoreTmp = (ansTstList?.filter(ansTst => ansTst.isCorrect).length / qstTstList?.length) * 100
			setScore( Math.floor( scoreTmp ) )
			
			qstTstOption && setScoreCutoff(qstTstOption.cutoffScore)
			
			if (Math.floor( scoreTmp ) >= qstTstOption.cutoffScore) {
				setIsPass(true)
				setScoreText("합격")
				setResultText(`잘하셨어요! 합격입니다!<br />같이 정답을 확인해 볼까요?`)
			} else {
				setIsPass(false)
				setScoreText("불합격")
				setResultText(`아쉽네요! 불합격입니다!<br />같이 정답을 확인해 볼까요?`)
			}
			
			setMounted(true)
		}
		
	}, [eduAnswerVO, qstTstOption])
	
	
	
	
	/**
	 * 테스트 결과 화면일 경우
	 * 안내메세지 표출
	 */
	useEffect(() => {
		
		if ( mounted ) {
			
			/**
			 * 학습가능상태 확인
			 */
			if ( paramMap.isPossible ) {
				
				/**
				 * 결과페이지 표출 후
				 * 완료 버튼 클릭 안내 메세지
				 * 완료처리 전 + 통과가능 상태 or 완료처리 전 + 컷오프 사용안함 상태
				 */
				if ( !eduAnswerVO?.isComplete ) {
					if ( (!eduAnswerVO?.isComplete && isPass) || ( !eduAnswerVO?.isComplete && !qstTstOption?.isCutoff ) ) {
						SwalUtils.text({
							title : `안내`,
							message : `학습을 완료하려면 <br />화면 맨 아래의 '완료' 버튼을 클릭하세요. `,
							isCancel : true,
							confirmOptions : {
								text : "확인"
							}
						})
					}
				}
			}
		}
		
	}, [mounted])
	
	
	
	
	// 제출하기
	const btnClickComplete = async () => {
		if (isLoading) return
		setIsLoading(true)

		try {
			EducationService.setAnsRecord(eduContentVO!.ecIdx, `END`, "tst")
			const result = await educationService.setAnswerStatus({
				eaIdx : eduAnswerVO!.eaIdx,
				isResult : true,
				isComplete : true,
			})
			if ( result.status === "SUCCESS" ) {

				ToastUtils.show(`${eduContentVO?.qstTstOptionVO.testTitle} 완료되었습니다.`)

				// 포인트적립 : 학습 컨텐츠 완료
				await PointService.savePoint("COURSE_VIEW", eduContentVO!.ecIdx)

				paramMap._retrievePage(result.allComplete)
			}
		} catch (error) {
			WebUtils.log(error)
		} finally {
			setTimeout(() => {
				setIsLoading(false)
			}, 3000)
		}
		//paramMap._goStep(requestStep! + 1)
	}
	
	
	// 재도전
	const btnCLickRetry = async () => {
		
		EducationService.setAnsRecord(eduContentVO!.ecIdx, `RETRY`, "tst")
		const result = await educationService.resetAnsTst( eduAnswerVO!.eaIdx )
		if ( result.status === "SUCCESS" ) {
			paramMap._retrievePage()
		}
		
		/*educationService.setAnswerStatus({
			eaIdx : eduAnswerVO!.eaIdx,
			isResult : false,
			isComplete : false,
		})*/
	}
	
	
	return (
		<>
			<section className="detail-section type-test">
				{/*title*/}
				<div className="detail-title">
					<div className="inner">
						<M_EduTitleDom paramMap={{
							mainTitle: eduMainVO?.eduTitle,
							contentTitle: qstTstOption?.testTitle,
							startDate : DateUtils.displayEduDate(eduMainVO?.scheduleMode, eduMainVO?.startDate),
							endDate : DateUtils.displayEduDate(eduMainVO?.scheduleMode, eduMainVO?.endDate),
							introduce : eduMainVO?.introduce
						}} />
						<div className={`test-content ${(isPass) ? "type-pass" : "type-fail"}`}>
							<div className="text-area">
								<i className="ico-award"></i>
								<div className="text-wrap">
									<strong className="tit">테스트 결과는 <b>{score}점 {scoreText}</b>입니다.</strong>
								</div>
								<div className="score-wrap">
									<div className="num">
										<span>정답</span>
										<b>{ansTstList?.filter(ansTst => ansTst.isCorrect).length}/{qstTstList?.length} 개</b>
									</div>
									<span className="txt" dangerouslySetInnerHTML={{__html:resultText || ""}}></span>
								</div>
							</div>
						</div>
						<h2 className="tit">{qstTstOption?.testTitle}</h2>
						
						<M_EduContentIndex paramMap={paramMap} ></M_EduContentIndex>
						
					</div>
				</div>
				<div className="detail-content">
					<div className="inner">
						<div className="content-title">
							<h3 className="tit">테스트</h3>
						</div>
						<div className="form-list">
							
							{qstTstList?.map( (qstTst, qt_index) => (
									<div className={`form-item ${ansTstList && ansTstList[qt_index].isCorrect ? "type-right" : "type-wrong"}`} key={qt_index}>
										
										
										{/* 객관식 싱글 */}
										{(ansTstList && qstTst.tstType === `single`) && (
											<>
												<div className="form-title">
													<strong className="tit">Q{qt_index + 1}. {qstTst.tstTitle} {/*{qstTst.qtIdx}*/}</strong>
													<p className="desc">{qstTst.tstDesc}</p>
												</div>
												<div className="rdo-bind">
													{ qstTst.answerList.map((tstAns:TstAnsVO, ta_index:number) => (
														
														<div className={((ta_index + 1) == qstTst.correctNumber) ? "radio active" :
															(ansTstList[qt_index]?.selTaIdxs == tstAns.taIdx.toString()) ? "radio wrong" : "radio"
														} key={ta_index}>
															<input type="radio" name={`qstTst-${qt_index}`} id={`tstAns-${tstAns.taIdx}`} value={tstAns.taIdx} className="inp-radio"
															       checked={ ((ta_index + 1) == qstTst.correctNumber) }
															       readOnly={true}
															/>
															<label htmlFor={`tstAns-${tstAns.taIdx}`} className="lab-radio">
																{tstAns.answer} {/*{tstAns.taIdx}*/}
																{ tstAns.answerImgSrc &&  <img src={tstAns.answerImgSrc} alt={tstAns.answer} />}
																{ tstAns.answerAudioSrc && <audio src={tstAns.tstAudioSrc} controls={true}></audio>}
															</label>
														</div>
													
													))}
												</div>
												<div className="answer-text">
													<strong className="answer">정답은 {qstTst.correctNumber}번 입니다</strong>
													<p className="desc">{qstTst.incorrectTxt}</p>
												</div>
											</>
										)}
										
										
										{/* 객관식 멀티 */}
										{(ansTstList && qstTst.tstType === `multi`) && (
											<>
												<div className="form-title">
													<strong className="tit">Q{qt_index + 1}. {qstTst.tstTitle} {/*{qstTst.qtIdx}*/}</strong>
													<p className="desc">{qstTst.tstDesc}</p>
												</div>
												<div className="rdo-bind">
													{ qstTst.answerList.map((tstAns:TstAnsVO, ta_index:number) => (
														
														<div className={( qstTst.correctNumber.includes(ta_index + 1)) ? "radio active" :
															(ansTstList[qt_index]?.selTaIdxs == tstAns.taIdx.toString()) ? "radio wrong" : "radio"
														} key={ta_index}>
															<input type="checkbox" name={`qstTst-${qt_index}`} id={`tstAns-${tstAns.taIdx}`}
															       value={tstAns.taIdx} className="inp-radio"
															       checked={ (ansTstList[qt_index]?.selTaIdxs.includes(tstAns.taIdx.toString()) ) || qstTst.correctNumber.includes(ta_index + 1) }
															       readOnly={true}
															/>
															<label htmlFor={`tstAns-${tstAns.taIdx}`} className="lab-radio">
																{tstAns.answer} {/*{tstAns.taIdx}*/}
																{ tstAns.answerImgSrc &&  <img src={tstAns.answerImgSrc} alt={tstAns.answer} />}
																{ tstAns.answerAudioSrc && <audio src={tstAns.tstAudioSrc} controls={true}></audio>}
															</label>
														</div>
													
													))}
												</div>
												<div className="answer-text">
													<strong className="answer">정답은 {qstTst.correctNumber.join(", ")}번 입니다</strong>
													<p className="desc">{qstTst.incorrectTxt}</p>
												</div>
											</>
										)}
										
										
										{/* 주관식 */}
										{(ansTstList && qstTst.tstType === `short`) && (
											<>
												<div className="form-title">
													<strong className="tit">Q{qt_index + 1}. {qstTst.tstTitle} {/*{qstTst.qtIdx}*/}</strong>
												</div>
												<div className="inp-bind">
													<textarea name={`qstTst-${qt_index}`} id={`qstTst-${qt_index}`}
													          cols={30} rows={1} className={`inp-text ${(!ansTstList[qt_index].isCorrect) ? 'wrong' : ''}`} placeholder="답변을 이곳에 작성해주세요"
													          value={ansTstList[qt_index]?.shortTxt ?? ""}
													          readOnly={true}/>
												</div>
												<div className="answer-text">
													<strong className="answer">정답은 ({qstTst.answerList.map(value => value.answer).join(", ")}) 입니다</strong>
													<p className="desc">{qstTst.incorrectTxt}</p>
												</div>
											</>
										)}
									
									
									</div>
								)
							)}
						</div>
					</div>
				</div>
			</section>
			
						
			{
				(!eduAnswerVO?.isComplete && paramMap.isPossible ) && (
					<div className="floating-area">
						<div className="floating-button">
							<div className="inner">
						{
							// 합격
							isPass &&
							<div className="button-area">
								<button type="button" className="btn red lg full" onClick={() => btnClickComplete()}>완료</button>
							</div>
						}
						
						{
							// 불합격
							!isPass &&
							<>
								{
									// 컷오프 사용시
									qstTstOption?.isCutoff &&
									<div className="button-area">
										<button type="button" className="btn blue lg full" onClick={() => btnCLickRetry()}>재도전하기</button>
									</div>
								}
								
								{
									// 컷오프 미사용
									!qstTstOption?.isCutoff &&
									<div className="button-area">
										<button type="button" className="btn blue lg" onClick={() => btnCLickRetry()}>재도전하기</button>
										<button type="button" className="btn lg blue full" onClick={() => btnClickComplete()}>완료</button>
									</div>
								}
							</>
						}
							</div>
						</div>
					</div>
				)
			}
		</>
	)
}
export default M_QstTstResult
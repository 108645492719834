import React, {useEffect, useRef, useState} from "react";
import {BoardVO, DefaultBoardVO} from "../../../../core/board/board.vo";
import ElandUtils from "../../../../common/utils/eland.utils";
import {ProReqVO} from "../../../../core/pro/pro.vo";
import UseStoryThumbImage from "../../../pc/board/useStory/UseStoryThumbImage";
import SwalUtils from "../../../../common/utils/swal.utils";

interface UseStorySelectProps {
    contentList: BoardVO[],
    proList: ProReqVO[],
    isPro: boolean,
    selectBoard: BoardVO | null,
    setSelectBoard: (board: BoardVO) => void;
    cancelEvent:()=>void
}

const M_UseStorySelect: React.FC<UseStorySelectProps> = ({
                                                             contentList,
                                                             isPro,
                                                             selectBoard,
                                                             proList,
                                                             setSelectBoard,
                                                             cancelEvent
                                                         }) => {

    const [updatedContentList, setUpdatedContentList] = useState<BoardVO[]>([]);
    const [updatedProList, setUpdatedProList] = useState<ProReqVO[]>([]);
    const [activeItem, setActiveItem] = useState<BoardVO | ProReqVO | null>();

    const proSelect = (pro: ProReqVO) => {
        let board: BoardVO = selectBoard ? selectBoard : DefaultBoardVO()
        board.receiverProInfo = pro;
        setSelectBoard(board);
    }

    const updateBoardContentList = async () => {
        if (contentList.length > 0) {
            const updatedList = [];
            for (const item of contentList) {
                const jobCodeName = await ElandUtils.convertJobSkillCodesToNames(item?.receiverProInfo?.jobCode);
                updatedList.push({
                    ...item,
                    receiverProInfo: {...item.receiverProInfo, jobCodeName}
                });
            }
            setUpdatedContentList(updatedList);
        } else {
            setUpdatedContentList([]);
        }
    };

    const updateProList = async () => {
        if (proList.length > 0) {
            const updatedList = [];
            for (const item of proList) {
                const jobCodeName = await ElandUtils.convertJobSkillCodesToNames(item.jobCode);
                updatedList.push({...item, jobCodeName});
            }
            setUpdatedProList(updatedList);
        } else {
            setUpdatedProList([]);
        }
    };

    function isProReqVO(item: any): item is ProReqVO {
        return item && typeof item.proidx === 'number';
    }

    function isBoardVO(item: any): item is BoardVO {
        return item && typeof item.idx === 'number';
    }


    const viewAlert = () => {
        SwalUtils.text({
            title: "적용스토리",
            message: `대상을 선택해주세요.`
        })
    }


    const selectItem = () => {
        if (activeItem) {
            if (isPro) {
                if (isProReqVO(activeItem)) {
                    setTranslateY(0)
                    proSelect(activeItem);
                } else {
                    viewAlert()
                }
            } else {
                if (isBoardVO(activeItem)) {
                    setTranslateY(0)
                    setSelectBoard(activeItem);
                } else {
                    viewAlert()
                }
            }
        }
    }


    useEffect(() => {
        setActiveItem(null)
        if (isPro) {
            updateProList();
        } else {
            updateBoardContentList();
        }

        if (selectBoard) {
            if (isPro) {
                setActiveItem(selectBoard.receiverProInfo)
            } else {
                setActiveItem(selectBoard)
            }
        }
    }, [contentList, proList]);


    // 상단 바 끌어 내려 취소하는 기능 추가
    const [translateY, setTranslateY] = useState(0);
    const startYRef = useRef<number | null>(null);

    useEffect(() => {
        const prevScrollY = preventScroll();
        return () => {
            allowScroll(prevScrollY);
        };
    }, []);

    const preventScroll = () => {
        const currentScrollY = window.scrollY;
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
        document.body.style.top = `-${currentScrollY}px`; // 현재 스크롤 위치
        document.body.style.overflowY = 'scroll';
        document.body.style.overflow = "hidden";
        document.body.style.overscrollBehaviorY = 'contain'
        return currentScrollY;
    };

    const allowScroll = (prevScrollY: number) => {
        document.body.style.position = '';
        document.body.style.width = '';
        document.body.style.top = '';
        document.body.style.overflowY = '';
        document.body.style.overflow = "";
        document.body.style.overscrollBehaviorY = ''
        window.scrollTo(0, prevScrollY);
    };


    const checkDragEleClick = (target: HTMLDivElement, x: number, y: number) => {
        const rect = target.getBoundingClientRect();

        // const pseudoElementLeft = rect.left + rect.width / 2 - 20;
        // const pseudoElementWidth = 40;
        // const xCheck = x >= pseudoElementLeft && x <= pseudoElementLeft + pseudoElementWidth

        const pseudoElementTop = rect.top + 16;
        const pseudoElementHeight = 20;
        const yCheck = y >= pseudoElementTop - pseudoElementHeight && y <= pseudoElementTop + pseudoElementHeight

        return yCheck

    };

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        startYRef.current = event.clientY - translateY;
    };

    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
        if (startYRef.current !== null) {
            const newTranslateY = event.clientY - startYRef.current;
            if (newTranslateY >= 0) {
                setTranslateY(newTranslateY);
            }
        }
    };

    const handleMouseUp = () => {
        if (translateY > 100) {
            setTranslateY(0);
            cancelEvent();
        } else {
            setTranslateY(0);
        }
        startYRef.current = null;
    };

    const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
        event.stopPropagation()
        const touch = event.touches[0];
        if (checkDragEleClick(event.currentTarget, touch.clientX, touch.clientY)) {
            startYRef.current = touch.clientY - translateY
        }
    };

    const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
        event.stopPropagation()
        if (startYRef.current !== null) {
            const touch = event.touches[0];
            const newTranslateY = touch.clientY - startYRef.current;

            if (newTranslateY >= 0) {
                setTranslateY(newTranslateY)
            }
        }
    }

    const handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
        event.stopPropagation()
        if (translateY > 100) {
            setTranslateY(0)
            cancelEvent()

        } else {
            setTranslateY(0)
        }
        startYRef.current = null;
    };


    return (
        <div className={`layer type-half content-select`}
             style={{
                 display: 'block',
                 transform: `translateY(${translateY}px)`,
                 transition: translateY === 0 ? 'transform 0.3s ease' : 'none',
             }}>
            {!isPro && (
                <div className="layer-content"
                     onMouseDown={handleMouseDown}
                     onMouseMove={handleMouseMove}
                     onMouseUp={handleMouseUp}
                     onTouchStart={handleTouchStart}
                     onTouchMove={handleTouchMove}
                     onTouchEnd={handleTouchEnd}>
                    <div className="inner">
                        <div className="layer-title">
                            <h3 className="tit">지식콘텐츠 선택</h3>
                        </div>
                        <div className="scroll-content">
                            {contentList.length === 0 ? (
                                <div className="content-empty">
                                    <p className="txt">
                                        최근 본 지식콘텐츠가 없습니다.
                                        <br/>
                                        지식콘텐츠를 시청한 후, 적용스토리를 작성해주세요
                                    </p>
                                </div>
                            ) : (
                                <ul className="content-list">
                                    {updatedContentList.map((item, index) => (
                                        <li
                                            className={`${isBoardVO(activeItem) && activeItem.idx === item.idx && 'selected'}`}
                                            key={index}
                                            onClick={() => setActiveItem(item)}
                                        >
                                            <a href="#">
                                                <div className="image-wrap">
                                                    <UseStoryThumbImage item={item}/>
                                                </div>
                                                <div className="text-wrap">
                                                    <strong className="tit">{item.title}</strong>
                                                    <div className="info">
                                                        <span>
                                                            {
                                                                (item.writerInfo?.isAdmin)
                                                                    ? `${item.writer}`
                                                                    : `${item.writerInfo?.coInfoVO?.coName} ${item.writer} 고수`
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {
                isPro && (
                    <div className="layer-content"
                         onMouseDown={handleMouseDown}
                         onMouseMove={handleMouseMove}
                         onMouseUp={handleMouseUp}
                         onTouchStart={handleTouchStart}
                         onTouchMove={handleTouchMove}
                         onTouchEnd={handleTouchEnd}>
                        <div className="inner">
                            <div className="layer-title">
                                <h3 className="tit">사내고수 선택</h3>
                            </div>
                            <div className="scroll-content">
                                {proList.length === 0 ? (
                                    <div className="content-empty">
                                        <p className="txt">
                                            도움받은 사내고수가 없습니다.
                                            <br/>
                                            에듀톡 문의하기를 통해 도움을 받아보세요
                                        </p>
                                    </div>
                                ) : (
                                    <ul className="content-list">
                                        {updatedProList.map((item, index) => (
                                            <li
                                                className={`${isProReqVO(activeItem) && activeItem.proidx === item.proidx && 'selected'}`}
                                                key={index}
                                                onClick={() => setActiveItem(item)}
                                            >
                                                <a href="#">
                                                    <div className="image-wrap">
                                                        <img
                                                            src={item?.profileImgSrc}
                                                            alt="profileImg"
                                                            className="img-thumb"
                                                        />
                                                    </div>
                                                    <div className="text-wrap">
                                                        <strong className="tit">
                                                            {item.companyAccountVO?.userName}
                                                        </strong>
                                                        <div className="info">
                                                            <span>{item.jobCodeName}</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                )}

            <div className="layer-button">
                <div className="inner">
                    <div className="button-area">
                        <button type="button" className="btn lg full" onClick={selectItem}
                                disabled={!activeItem}>
                            등록하기
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default M_UseStorySelect;
import React, {useEffect, useRef, useState} from "react";

interface AccountManagementTextareaProps {
    value: string;
    changeEvent: (newValue: string) => void;
}

const AccountManagementTextarea = (props: AccountManagementTextareaProps) => {
    const [text, setText] = useState('');
    const [charCount, setCharCount] = useState(0);
    const [lineCount, setLineCount] = useState(1);
    const textAredRef = useRef<HTMLTextAreaElement>(null);
    const maxLength = 300;
    const maxLines = 7;
    const maxCharsPerLine = 55;

    useEffect(() => {
        const lines = props.value.split('\n');
        setText(props.value);
        setCharCount(props.value.length);
        setLineCount(lines.length);
    }, [props.value]);

    const getLineHeight = (ta:HTMLTextAreaElement) => {
        const cs = window.getComputedStyle(ta)
        const lh = parseFloat(cs.lineHeight)
        const value = ta.value

        const cloneTa = ta.cloneNode() as HTMLTextAreaElement
        if (value === '') {
            cloneTa.value = ' '
        } else {
            cloneTa.value = ta.value
        }
        cloneTa.style.minHeight = 'unset'
        cloneTa.style.maxHeight = 'unset'
        cloneTa.style.position = 'absolute'
        cloneTa.style.top = '0'
        cloneTa.style.left = '0'
        cloneTa.style.width = cs.width
        cloneTa.style.font = cs.font
        cloneTa.style.wordSpacing = cs.wordSpacing
        cloneTa.style.lineHeight = `${lh}px`
        cloneTa.style.height = '0'
        cloneTa.style.overflow = 'scroll'

        document.body.append(cloneTa)
        const result = Math.ceil(cloneTa.scrollHeight / lh)
        cloneTa.remove()

        return result
    }

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value;

        const lines = getLineHeight(event.target);

        if (value.length <= maxLength && lines <= maxLines) {
            setText(value);
            setCharCount(value.length);
            setLineCount(lines);
            props.changeEvent(value);
        }
    };

    return (
        <div className="form-item">
            <div className="form-title">
                <strong className="tit">소개글</strong>
            </div>
            <div className="inp-bind type-limit">
                <textarea
                    name=""
                    id=""
                    cols={30}
                    rows={10}
                    className="inp-text"
                    placeholder="나를 소개하기 위한 문구를 입력해주세요"
                    ref={textAredRef}
                    value={text}
                    onChange={handleChange}
                />
                <span
                    className="txt-limit"
                    style={{color:charCount === maxLength || lineCount === maxLines ? "" : "var(--gray-40)"}}
                >
                    {charCount}/{maxLength} (Lines: {lineCount}/{maxLines})
                </span>
            </div>
        </div>
    );
};

export default AccountManagementTextarea;
import React, {useEffect, useRef} from "react"
import CommonMobileJS from "../../../common/common_m";

interface M_ReviewModalProps {
    rating: number;
    reviewText: string;
    setReviewText: React.Dispatch<React.SetStateAction<string>>;
    onRegister: () => void;
};

const M_ReviewModal = ({ rating, reviewText, setReviewText, onRegister }: M_ReviewModalProps) => {

    useEffect(() => {
        console.log("rating : ", rating)
        CommonMobileJS.sweetModal()
    }, []);

    const onCancel = () => {
        console.log("onCancel")
    }

    return (
        <div id="texrareaStarAlert" className="sweet-modal">
            <div className="modal-inner">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title">한줄평</h2>
                    </div>
                    <div className="modal-body">
                        <p className="desc">지식콘텐츠에 한줄평과 별점을 남겨주세요</p>
                        <div className="star-drag">
                            {[1, 2, 3, 4, 5].map((star) => (
                                <button
                                    key={star}
                                    type="button"
                                    className={rating >= star ? "check" : ""}>
                                    <span className="blind">{star}</span>
                                </button>
                            ))}
                        </div>
                        <textarea name="" cols={30} rows={2} className="inp-text"
                                  placeholder="내용을 입력해주세요" value={reviewText}
                                  onChange={(e) => setReviewText(e.target.value)}></textarea>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn lg btn-cancel" onClick={onCancel}>취소</button>
                        <button type="button" className="btn lg btn-confirm" onClick={onRegister}>등록</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default M_ReviewModal;
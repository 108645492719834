import React, {useEffect, useRef, useState} from "react";
import CommonJS from "../../../../../common/common";
import CodeUtils from "../../../../../common/utils/code.utils";
import {CodeVO} from "../../../../../core/common/code.vo";
import {useLocation, useNavigate} from "react-router-dom";
import SummernoteIframe, {SummernoteIframeHandle} from "../../../../pc/common/SummernoteIframe";
import MemberUtils from "../../../../../common/utils/member.utils";
import MypageService from "../../../../../core/mypage/mypage.service";
import FileUploader from "../../../../pc/common/FileUploader";
import PointService from "../../../../../core/point/point.service";
import ToastUtils from "../../../../../common/utils/toast.utils";
import ElandUtils from "../../../../../common/utils/eland.utils";
import StringUtils from "../../../../../common/utils/string.utils";

const ProHelpForm = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [boardVO, setBoardVO] = useState((location.state && location.state.board) ? {...location.state.board} : null)
    const [fidxs, setFidxs] = useState<string>((location.state && location.state.fidxs) ? location.state.fidxs : "")
    const [jobCodeList, setJobCodeList] = useState<CodeVO[]>([])

    const boardKey = "pro_help"
    const [title, setTitle] = useState<string>("")
    const [jobCode, setJobCode] = useState<string>('')
    const [cateCode, setCateCode] = useState<string>('')

    const jobCodeBtn = useRef<HTMLButtonElement>(null)
    const cateCodeBtn = useRef<HTMLButtonElement>(null)

    const iframeRef = useRef<SummernoteIframeHandle>(null);


    useEffect(() => {
        getJobCodeList().then(() => {
                readyToUpdate()
                CommonJS.inputFile('.file-upload-gray')
                CommonJS.select()
            }
        )

    }, []);

    const getJobCodeList = async () => {
        let jobSkillCodeList = await ElandUtils.getJobSkillCodeList(false)
        setJobCodeList(jobSkillCodeList)
    }

    const readyToUpdate = () => {
        if (boardVO) {
            setTitle(boardVO.title)
            setCateCode(boardVO.cateCode ?? '')
            setJobCode(boardVO.jobCode)
            setFidxs(fidxs)
            if (cateCodeBtn.current && boardVO.cateName) {
                cateCodeBtn.current.innerHTML = boardVO.cateName
            }

            if (jobCodeBtn.current && boardVO.jobName) {
                jobCodeBtn.current.innerHTML = boardVO.jobName
            }

        }
        CommonJS.select()
    }

    const updateJobCode = (e: any, code: string) => {
        e.preventDefault()
        setJobCode(code)
    }

    const updateCateCode = (e: any, code: string) => {
        e.preventDefault()
        setCateCode(code)
    }

    const cancelEvent = () => {
        navigate(-1)
    }

    const handleUploadAttachFile = (idxs:string) => {
        setFidxs(idxs)
    }

    const checkInputFill = () => {
        return !StringUtils.isEmpty(title) &&
            !StringUtils.isEmpty(iframeRef.current?.getHtmlContent() ?? "") &&
            !StringUtils.isEmpty(jobCode) &&
            !StringUtils.isEmpty(cateCode)
    }

    const saveHelpBoard = async () => {
        if (!checkInputFill()){
            ToastUtils.show("제목, 내용을 입력해주세요.")
            return
        }

        let params = {
            boardKey: boardKey ?? "",
            title: title,
            content:iframeRef.current?.getHtmlContent() ?? "",
            coCode: MemberUtils.getSessionVO()?.coCode ?? "",
            jobCode: jobCode,
            cateCode: cateCode,
            fidxs: fidxs
        }

        let successHandler = async (result:any) => {
            await PointService.savePoint("HELP_POST", result.data ?? "0");
            showComplete()
        }

        await MypageService.boardOneSave(params, successHandler)

    }

    const updateHelpBoard = async () => {
        if (!checkInputFill()){
            ToastUtils.show("내용을 입력해주세요.")
            return
        }

        const bidx = boardVO.idx.toString()
        let params = {
            boardKey: boardKey ?? "",
            title: title,
            content:iframeRef.current?.getHtmlContent() ?? "",
            jobCode: jobCode,
            cateCode: cateCode,
            fidxs: fidxs
        }

        await MypageService.boardOneUpdate(bidx, params, showComplete)

    }


    const showComplete = () => {
        ToastUtils.show("저장이 완료되었습니다.")
        navigate('/pro/help/', {replace: true});
    }

    const titleOnChangeEvent = (event:React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        if (input.length <= 50) {
            setTitle(input);
        }
    }

    return (
        <>
            <div className="form-list no-line">
                <div className="form-item">
                    <div className="form-title">
                        <strong className="tit">직무</strong>
                    </div>
                    <div className="sel-bind">
                        <div className="select">
                            <button type="button" className="btn-select" ref={jobCodeBtn}>직무를
                                선택해주세요
                            </button>
                            <ul className="select-option">
                                {
                                    jobCodeList.map((code, index) => {
                                        return (
                                            <li key={index}>
                                                <a href="/"
                                                   onClick={e => updateJobCode(e, code.code)}>
                                                    <span>{code.codeName}</span>
                                                    <span></span>
                                                </a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="form-item">
                    <div className="form-title">
                        <strong className="tit">분류</strong>
                    </div>
                    <div className="sel-bind">
                        <div className="select">
                            <button type="button" className="btn-select" ref={cateCodeBtn}>게시글의 분류를
                                선택해주세요
                            </button>
                            <ul className="select-option">
                                <li>
                                    <a href="#" onClick={e => updateCateCode(e, '040401')}>도움요청</a>
                                </li>
                                <li>
                                    <a href="#" onClick={e => updateCateCode(e, '040402')}>지식나눔</a>
                                </li>
                                <li>
                                    <a href="#" onClick={e => updateCateCode(e, '040403')}>자유주제</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="form-item">
                    <div className="form-title">
                        <strong className="tit">제목</strong>
                    </div>
                    <div className="inp-bind fill">
                        <input type="text" name="" id=""
                               className="inp-text"
                               onChange={titleOnChangeEvent}
                               value={title}
                               placeholder="제목을 입력해주세요"/>
                    </div>
                </div>
                <div className="form-item">
                    <div className="form-title">
                        <strong className="tit">내용</strong>
                    </div>
                    <div className="inp-bind">
                        <SummernoteIframe ref={iframeRef} mode={"edit"} htmlCode={boardVO?.contentHtml ?? ""}/>
                    </div>
                </div>
                <div className="form-item">
                    <div className="form-title">
                        <strong className="tit">첨부파일</strong>
                    </div>
                    <FileUploader params={{
                        domId: `boardVO-attach`,
                        domText: `첨부파일은 1개만 업로드 가능합니다. 파일이 여러개일 경우 zip파일로 압축하여 등록해주세요.`,
                        target: `board`,
                        fidxs: fidxs,
                        maxFileSize: 1024 * 1024 * 100,
                        maxFileCount: 1,
                        updateHandler: (_target: string, _files: string) => {
                            console.log(_target + " ::::: " + _files)
                            handleUploadAttachFile(_files);
                        }
                    }}/>
                    <div className="guide-text">
                        파일크기 : 100MB이하, 1개의 파일 / 확장자는 제약이 없습니다.
                    </div>
                </div>
            </div>
            <div className="button-area">
                <button type="button" className="btn gray lg" onClick={cancelEvent}>취소하기</button>
                <button type="button" className="btn red lg"
                        onClick={boardVO ? updateHelpBoard : saveHelpBoard}>등록하기
                </button>
            </div>
        </>
    )
}

export default ProHelpForm


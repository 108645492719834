import React from "react";
import {BoardVO} from "../../../../core/board/board.vo";
import moment from "moment/moment";

interface BoardBasicTypeRowProps {
    board: BoardVO,
    isCategory: boolean,
    isNotice: boolean,
    clickEvent: (idx:number)=>void,
    index?: number
}

const BoardBasicTypeRow = (props:BoardBasicTypeRowProps) => {
    const {board, isCategory,isNotice, clickEvent} = props

    return (
        <tr onClick={(e)=>{
            e.preventDefault()
            clickEvent(board.idx)
        }} style={{cursor:"pointer"}}>
            {
                isNotice ? (
                    <td>
                        <div>
                            <strong>{isNotice && (<span className="badge type1 red">공지</span>)}</strong>
                        </div>
                    </td>
                ) : (
                    <td>{props.index ? props.index : board.idx}</td>
                )
            }
            {isCategory && (<td>{board.cateName}</td>)}
            <td className="tit">
                <div>
                    <strong> {board.title} </strong>
                    {
                        board.commentCount > 0 && (<span className="comment" style={{marginLeft: '4px'}}>({board.commentCount})</span>)
                    }
                    {
                        board.isFile && (<span className="attach"></span>)
                    }
                </div>
            </td>
            <td>{board.writer}</td>
            <td className="date">{moment(board.createDate).format("YYYY-MM-DD")}</td>
            <td className="hits">{board.views}</td>
        </tr>
    )
}

export default BoardBasicTypeRow
class DeviceUtils {


    static getDeviceToken = (): Promise<string> => {
        return new Promise((resolve, reject) => {
            // 플랫폼 감지
            const isAndroid = /Android/i.test(navigator.userAgent);
            const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
            const isWeb = !isAndroid && !isIOS;

            if (isAndroid && (window as any).HWAPP && (window as any).HWAPP.getDeviceToken) {
                // Android 네이티브 코드 호출
                (window as any).HWAPP.getDeviceToken("deviceToken");

                // Android에서 디바이스 토큰을 받아올 전역 함수 정의
                (window as any).updateDeviceToken = (token: string) => {
                    resolve(token);  // 토큰을 받아오면 Promise를 resolve
                };
            } else if (isWeb) {
              // ....
            } else if (isIOS) {
                (window as any).receiveDeviceToken = (token: string) => {
                    resolve(token);
                }

                DeviceUtils.getIOSDeviceToken()
               // .....
            } else {
                reject("Unsupported platform.");
            }
        });
    }

    static isAndroidDevice = () => {
        return /Android/i.test(navigator.userAgent);
    };

    // PiP 재생
    static onPlayInPip = (videoUrl: string, currentTime: number, isPlaying: boolean) => {
        if (DeviceUtils.isAndroidDevice() && (window as any).HWAPP && typeof (window as any).HWAPP.playVideoInPip === 'function') {
            // 안드로이드 네이티브 코드 호출 (재생 여부도 함께 전달)
            (window as any).HWAPP.playVideoInPip(videoUrl, currentTime, isPlaying);

        } else {
            console.log('안드로이드 기기가 아니거나, 네이티브 코드와 연결되지 않았습니다.');
        }
    };

    static getIOSDeviceToken = () => {
        let webkit = (window as any).webkit
        if (webkit && webkit.messageHandlers) {
            webkit.messageHandlers.getDeviceToken.postMessage({});
        }
    }

}

export default DeviceUtils;
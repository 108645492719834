import React, {useEffect, useState} from "react";
import Pagination from "../common/Pagination";
import {HWMap} from "../../../core/common/common.vo";
import BoardService from "../../../core/board/board.service";
import {BoardVO} from "../../../core/board/board.vo";
import CardItem from "../board/item/CardItem";
import APIs from "../../../common/apis/APIs";
import NullItem from "../board/item/NullItem";
import StringUtils from "../../../common/utils/string.utils";
import ElandUtils from "../../../common/utils/eland.utils";
import {useLocation} from "react-router-dom";

interface StorageboxProps {
	type:string
}

const Storagebox: React.FC<StorageboxProps> = ({ type }) => {

	const location = useLocation()
	let initMenu = (location.state?.activeMenu === "recent" || location.state?.activeMenu === "wish") ? location.state.activeMenu : type;
	const [totalCount, setTotalCount] = useState(0);
	const [activeMenu, setActiveMenu] = useState(initMenu);
	const [boardList, setBoardList] = useState<BoardVO[]>([]);
	const [params, setParams] = useState<HWMap>({
		page: 0,
		size: 12,
		sort: "createDate,DESC",
	})

	const boardService = new BoardService();

	// 페이지 누를 때 업데이트하는 함수
	const updatePages = (page:number) => {
		setParams((params) => ({
			...params,
			page: page
		}));
	};



	const getRecentViewList = async () => {
		const result = await boardService.getRecentViewList({ ...params, boardKeys: APIs.KNOWLEDGE_BOARDKEY });
		if (result.status === 'SUCCESS' && result.data) {
			const updatedBoardList = await ElandUtils.convertJobSkillNames4BoardList(result.data.content);
			setBoardList(updatedBoardList);
			setTotalCount(result.data.totalElements);
		}
	};

	const getWishList = async () => {
		const result = await boardService.getSearchBoards({ ...params, WISH_LIKE: 'wish', boardKey: APIs.KNOWLEDGE_BOARDKEY });
		if (result.status === 'SUCCESS' && result.data) {
			const updatedBoardList = await ElandUtils.convertJobSkillNames4BoardList(result.data.list);
			setBoardList(updatedBoardList);
			setTotalCount(result.data.totalCount);
		}
	};

	const onRefresh = () => {
		if (activeMenu === 'recent') {
			getRecentViewList()
		} else {
			getWishList()
		}
	}

	useEffect(() => {
		(async () => {
			if (activeMenu === 'recent') {
				await getRecentViewList();
			} else if (activeMenu === 'wish') {
				await getWishList();
			}
		})();
	}, [params]);


	useEffect(() => {
		if(activeMenu != "") {
			setParams({
				page: 0,
				size: 12,
				sort: "createDate,DESC",
			})
		}
	}, [activeMenu]);

	useEffect(() => {
		let page = params.page

		if (boardList.length === 0 && page > 0) {
			page -= 1
			updatePages(page)
		}
	}, [boardList]);

	return (
		<main className="content storage">
			<div className="common-title">
				<div className="inner">
					<h2 className="tit">보관함</h2>
				</div>
			</div>
			<section className="list-section">
				<div className="inner">
					<div className="board-util">
						<div className="util-group">
							<div className="board-tabs">
								<ul className="tab-list">
									<li className={`tab-item ${activeMenu === "recent" ? "active" : ""}`}>
										<a href="#" onClick={(event) => {
											event.preventDefault();
											setActiveMenu("recent");
										}}>최근 본 지식</a>
									</li>
									<li className={`tab-item ${activeMenu === "wish" ? "active" : ""}`}>
										<a href="#" onClick={(event) => {
											event.preventDefault();
											setActiveMenu("wish");
										}}>관심 지식</a>
									</li>
								</ul>
							</div>
						</div>

						{totalCount > 0 && (
							<div className="util-group">
								<div className="board-total">
									총 <span>{totalCount}</span> 개의 게시물이 있습니다.
								</div>
							</div>
						)}

					</div>
					<div className="board-list">
						<ul className="card-list type1">
							{
								boardList.map(value => {
									return <CardItem key={value.idx} board={value} onRefresh={onRefresh}/>
								})
							}
						</ul>

						{/* 내용없는 리스트 */}
						{boardList.length < 1 && <NullItem text={activeMenu == 'recent' ? '최근 본 지식이 없습니다\n이랜드에듀에서 다양한 교육을 만나보세요.' : '관심 지식이 없습니다\n이랜드에듀에서 다양한 교육을 만나보세요'}/>}

						{/* 페이지네이션 */}
						{boardList.length > 0 && <Pagination page={params.page} totalCount={totalCount} updatePages={updatePages} size={params.size}/>}

					</div>
				</div>
			</section>
		</main>
	)
}

export default Storagebox
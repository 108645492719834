import React, {useEffect, useState} from "react";
import {BoardVO} from "../../../../core/board/board.vo";
import BasicItem from "../item/BasicItem";
import {useNavigate, useParams} from "react-router-dom";
import {HWMap} from "../../../../core/common/common.vo";
import WebUtils from "../../../../common/utils/web.utils";
import BasicHeader from "../item/BasicHeader";
import {BoardInfoVO} from "../../../../core/board/BoardInfo.vo";

interface PageParamsProps{
    totalCount:number,
    page: number,
    size: number
}

const Basic: React.FC<{paramMap:HWMap}> = ({paramMap = undefined}) => {
    const navigate = useNavigate();

    const [boardList, setBoardlist] = useState<BoardVO[]>([])
    const [boardInfo, setBoardinfo] = useState<BoardInfoVO>()
    const [headerList, setHeaderList] = useState<String[]>()
    const [isNeedLogin, setIsNeedLogin] = useState<boolean>(false)

    const [pageParams, setPageParams] = useState<PageParamsProps>({totalCount:0, size: 0, page:0})

    /**
     * 받아온 데이터 정의
     */
    useEffect(() => {
        WebUtils.log(paramMap)
        if (paramMap?.boardList !== undefined) {
            setBoardlist( paramMap.boardList )
            setHeaderList( paramMap.headerList )
            setBoardinfo( paramMap.boardInfo )
        }

        if (paramMap?.pageParams){
            setPageParams(paramMap.pageParams)
        }

        setIsNeedLogin(paramMap && paramMap.isNeedLogin);
    }, [paramMap]);

    return (
        <table className={`table-list ${headerList ? 'type2' : 'type1'}`}>
            <colgroup>
                <col/>
                <col/>
                <col style={{width: '50%'}}/>
                <col/>
                <col/>
                <col/>
            </colgroup>

            {/* 헤더는 커스텀 or 기본 */}
            <BasicHeader customHeaders={headerList} boardInfo={boardInfo}/>

            <tbody>
            {boardList.map((item, index) => (
                <BasicItem key={index} board={item} isNeedLogin={isNeedLogin} boardInfo={boardInfo}
                           index={pageParams ? pageParams.totalCount - ((pageParams.page * pageParams.size) + index) : undefined}/>
            ))}
            </tbody>
        </table>
    )
}

export default Basic;
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import SearchInput from "../search/SearchInput";
import MemberUtils from "../../../common/utils/member.utils";
import FileUtils from "../../../common/utils/file.utils";
import NumberFormatter from "../common/NumberFormatter";


export default function Header () {

	const [myPoint, setMyPoint] = useState<number>(0);
	const [notifCount, setNotifCount] = useState<number>(0)

	useEffect(() => {
		const fetchMyPoint = async () => {
			const point = await MemberUtils.getMyPoint();
			setMyPoint(point);
		};

		const fetchNotifCount = async () => {
			const count = await MemberUtils.getNotifCount()
			setNotifCount(count)
		}

		if (MemberUtils.isLogin()){
			fetchMyPoint();
			fetchNotifCount()
		}

	}, []);


	// @ts-ignore
	return (
		<header className="header">
			<div className="inner">
				<h1 className="header-logo">
					<Link to="/">
						<span className="blind">E-LANDEDU</span>
					</Link>
				</h1>
				<nav className="gnb">
					<ul className="gnb-list">
						<li>
							<Link to={`/education/dashboard`}>나의 강의실</Link>
							<div className="depth2-wrap">
								<ul className="depth2-list">
									<li>
										<Link to={`/education/dashboard`}>대시보드</Link>
									</li>
									<li>
										<Link to={`/education/inprogress`}>코스학습</Link>
									</li>
									<li>
										<Link to={`/education/storagebox`}>보관함</Link>
									</li>
								</ul>
							</div>
						</li>
						<li>
							<Link to={"/board/list/ebg_story/"}>지식콘텐츠</Link>
						</li>
						<li>
							<Link to={"/pro"}>사내고수</Link>
							<div className="depth2-wrap">
								<ul className="depth2-list">
									<li>
										<Link to={"/pro"}>사내고수찾기</Link>
									</li>
									<li>
										<Link to={"/pro/help"}>도와줘요 게시판</Link>
									</li>
									<li>
										<Link to={"/pro/request"}>사내고수 신청</Link>
									</li>
									<li>
										<Link to={"/pro/talk"}>나의 에듀톡</Link>
									</li>
								</ul>
							</div>
						</li>
						<li>
							<Link to={"/pro/request"}>고수 신청</Link>
						</li>
					</ul>
				</nav>
				<div className="header-utils">

					{/* 검색 */}
					<SearchInput/>

					{/*<!-- <a href="#" className="btn-login">로그인</a> -->*/}

					{MemberUtils.isLogin() ?
						<Link to={"/mypage/point"} className="btn-point"><NumberFormatter number={myPoint}/>P</Link> :
						<Link to={"/login"} className="btn-point">
							로그인
						</Link>
					}

					<Link to={"/mypage/management"} className="btn-user">
						<span className="blind">회원정보관리</span>
						<img src={FileUtils.getFileUrl(MemberUtils.getProfileImgSrc())}
							 onError={FileUtils.onErrorImg}
							 alt="프로필 이미지"/>
					</Link>
					<Link to={"/mypage/alarm"} className={`btn-notify ${notifCount > 0 && 'active'}`}>
						<span className="blind">알림함</span>
					</Link>
				</div>
			</div>
			<div className="gnb-bg"></div>
		</header>
	);
}
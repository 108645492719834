import React, { useEffect, useState } from "react";
import CommonJS from "../../../common/common";

interface SelectOption {
    value: string | number;
    name: string;
}

interface SelectProps {
    options: SelectOption[];
    updateOption: (option: string | number) => void;
}

const M_CommonSelect: React.FC<SelectProps> = ({ options, updateOption }) => {
    const [selectedValue, setSelectedValue] = useState<string | number>(options[0].value);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        setSelectedValue(value);
        updateOption(value);
    };

    useEffect(() => {
        CommonJS.select(); // 외부 스크립트 초기화
    }, []);

    return (
        <select
            name="sort"
            className="sel"
            value={selectedValue}
            onChange={handleChange}
        >
            {options.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.name}
                </option>
            ))}
        </select>
    );
};

export default M_CommonSelect;

import CodeUtils from "./code.utils";
import {BoardVO} from "../../core/board/board.vo";
import AxiosCaller from "./axios.caller";
import APIs from "../apis/APIs";
import {CodeVO} from "../../core/common/code.vo";
import axiosCaller from "./axios.caller";
import {HWMap} from "../../core/common/common.vo";

class ElandUtils {
    // 진행 중인 요청을 저장할 정적 변수
    private static jobSkillCodeTreePromise: Promise<HWMap> | null = null;

    private static async getJobSkillCodeTree() {
        if (sessionStorage.getItem('jobSkillCodeList') == null) {
            const result = await this.getAxiosJobSkillCode()
            if (result.status === 'ok') {
                sessionStorage.setItem('jobSkillCodeList', JSON.stringify(result.codeList));
            }
        }

        let codeList: CodeVO[] = JSON.parse(sessionStorage.getItem('jobSkillCodeList') ?? '[]');
        return codeList;
    }

    private static async getAxiosJobSkillCode(){
        if (!this.jobSkillCodeTreePromise){
            this.jobSkillCodeTreePromise = new Promise<HWMap>(resolve => {
                resolve(AxiosCaller.get(APIs.COMMON_CODE + "/getCodeTree", {code: '02'}))
            }).finally(()=>{
                this.jobSkillCodeTreePromise = null
            })
        }

        return this.jobSkillCodeTreePromise
    }

    static getBoardNameList = async () => {
        const sessionName = "boardNameList"
        const boardKeyList = [
            'company','organization','company_announce','announce','frequently',
            'ebg_story', 'ebg_common', 'pro_knowledge', 'job_data', 'leadership', 'es','use_story'
        ];

        if (!sessionStorage.getItem(sessionName)) {
            let result = await axiosCaller.get(APIs.MYPAGE_BOARD + '/name/list',{boardKeys: boardKeyList.join(",")});
            if (result.status === 'SUCCESS' && result.data) {
                sessionStorage.setItem(sessionName, JSON.stringify(result.data));
                return result.data
            } else {
                return []
            }

        } else {
            let list = sessionStorage.getItem(sessionName) ?? '[]'
            return JSON.parse(list)
        }
    }

    static async getPopularIdxs() {
        if (sessionStorage.getItem('topBidxs') == null) {
            const result = await axiosCaller.get(APIs.BOARD_FIND_POPULAR, {boardKey: APIs.KNOWLEDGE_BOARDKEY, page: 0, size: 100})

            if (result.status === 'SUCCESS') {
                sessionStorage.setItem('topBidxs', JSON.stringify(result.data));
            }
        }

        let bidxs: number[] = JSON.parse(sessionStorage.getItem('topBidxs') ?? '[]');
        return bidxs;
    }


    static async isInPopularIdxs(idxToCheck: number): Promise<boolean> {
        const popularIdxs = await this.getPopularIdxs();
        return popularIdxs.includes(idxToCheck);
    }


    static async getJobSkillCodeName(code: string) {
        let codeList = await this.getJobSkillCodeTree();
        let findCode = CodeUtils.findCode(codeList, code);

        return findCode && findCode.codeName ? findCode.codeName : "";
    }

    static async getJobCodeListForPoll() {
        let codeList = await this.getJobSkillCodeTree()
        let jobCode = CodeUtils.findCode(codeList, '0201')
        if (!jobCode){
            return []
        }

        return CodeUtils.extractCodeListByLevel(jobCode, 2)
    }

    static async getJobSkillCodeList(isSkill:boolean) {
        let codeList = await this.getJobSkillCodeTree()
        let jobCode = CodeUtils.findCode(codeList, !isSkill ? '0201' : '0202')
        if (!jobCode){
            return []
        }

        return CodeUtils.extractCodeListByLevel(jobCode, 1)
    }



    // 역량 이름 필터 (코드 -> 이름 으로 보이게)
    static async updateBoardListWithSkillNames(boardList: BoardVO[]): Promise<BoardVO[]> {
        const updatedBoardList = await Promise.all(
            boardList.map(async (board) => {
                let skillNames: string[] = [];

                if (board.skillCode) {
                    const codes = board.skillCode.split(",");
                    skillNames = await Promise.all(codes.map(code => this.getJobSkillCodeName(code)));
                }


                return { ...board, skillName: skillNames.join(", ") };
            })
        );

        return updatedBoardList;
    }


    /**
     * 직무 코드를 직무 이름으로 변환
     * @param boardList
     */
    static async updateBoardListWithJobNames (boardList: BoardVO[]) {

        const updatedBoardList = await Promise.all(
            boardList.map(async (board) => {

                if (!board.jobCode) { return board; }

                const codes = board.jobCode.split(",");
                const jobNames = await Promise.all(codes.map(code => this.getJobSkillCodeName(code)));

                return { ...board, jobName: jobNames.join(", ") };
            })
        );

        return updatedBoardList;
    }

    static async updateSkillNames(boardList: BoardVO[]): Promise<BoardVO[]> {
        const updatedBoardList = await Promise.all(
            boardList.map(async (board) => {
                let skillNames: string[] = [];

                if (board.skillCode) {
                    const codes = board.skillCode.split(",");
                    skillNames = await Promise.all(codes.map(code => this.getJobSkillCodeName(code)));
                }

                return { ...board, skillName: skillNames.join(", ") };
            })
        );

        return updatedBoardList;
    }



    // 24.07.11 ===============================================

    /**
     * boardList 를 받아
     * 직무 명, 역량 명 세팅 후 반환
     * @param boardList
     */
    static async convertJobSkillNames4BoardList(boardList: BoardVO[] | null | undefined): Promise<BoardVO[]> {
        if (!boardList) {
            return [];
        }

        const updatedBoardList: BoardVO[] = [];

        for (const board of boardList) {
            const updatedBoard = await this.convertJobSkillNames4Board(board);
            updatedBoardList.push(updatedBoard);
        }

        return updatedBoardList;
    }

    /**
     * board 를 받아
     * 직무 명, 역량 명 세팅 후 반환
     * @param board
     */
    static async convertJobSkillNames4Board(board: BoardVO | null | undefined): Promise<BoardVO> {
        if (!board) {
            return {} as BoardVO;
        }

        const updatedBoard: BoardVO = { ...board };

        if (board.skillCode) {
            updatedBoard.skillName = await this.convertJobSkillCodesToNames(board.skillCode);
        }
        if (board.jobCode) {
            updatedBoard.jobName = await this.convertJobSkillCodesToNames(board.jobCode);
        }
        return updatedBoard;
    }

    /**
     * (직무/역량) 코드를 받아서
     * 코드명 반환
     * @param jobSkillCodes
     */
    static async convertJobSkillCodesToNames(jobSkillCodes: string): Promise<string> {
        if (!jobSkillCodes) {
            return "";
        }

        const codes = jobSkillCodes.split(",");
        const skillNames = await this.getJobSkillNames(codes);

        return skillNames.join(",");
    }


    private static async getJobSkillNames(codes: string[]): Promise<string[]> {
        const codeList = await this.getJobSkillCodeTree();
        return codes.map(code => {
            const findCode = CodeUtils.findCode(codeList, code);
            return findCode && findCode.codeName ? findCode.codeName : "";
        });
    }


    /**
     * 지식 컨텐츠 인지?
     * @param boardKey
     */
    static isKnowledgeBoard = (boardKey : string) => {
        const boardKeyList = APIs.KNOWLEDGE_BOARDKEY.split(',');
        // boardKey가 리스트에 포함되어 있는지 확인
        if (!boardKeyList.includes(boardKey)) {
            return false;
        } else {
            return true;
        }
    }














}

export default ElandUtils;
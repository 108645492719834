import React, {useEffect, useState} from "react";
import moment from "moment/moment";
import {ProReqVO} from "../../../../core/pro/pro.vo";
import ProService from "../../../../core/pro/pro.service";
import {Link} from "react-router-dom";
import FileUtils from "../../../../common/utils/file.utils";
import StringUtils from "../../../../common/utils/string.utils";

interface ProProfileProps {
    proReq: ProReqVO
    isMe: boolean
    isFollow: boolean
}

const ProProfile = (props: ProProfileProps) => {
    const {proReq, isMe} = props;
    const [isFollow, setIsFollow] = useState<boolean>(props.isFollow)

    useEffect(() => {
        setIsFollow(props.isFollow)
    }, [props]);


    const calWorkingYear = (workingYear: number) => {

        if (workingYear < 1980) {
            return ""
        }
        let currentYear = new Date().getFullYear()
        let year: number = currentYear - workingYear + 1
        if (year >= 9) {
            return "시니어(9~)"
        } else if (year >= 5) {
            return "미들(5~8)"
        } else if (year >= 1) {
            return "주니어(1~4)"
        } else {
            return ""
        }
    }

    const onErrorImg = (e: any) => {
        e.target.src = process.env.PUBLIC_URL + '/elandedu/assets/images/common/img_profile.png'
    }

    const getButton = () => {
        if (isMe) {
            return (<Link to="/mypage/management" className="btn gray outline">페이지 관리</Link>)
        }
        return (
            <>
                {getFollowButton()}
                <a href="#" className="btn red" data-bs-toggle="modal" data-bs-target="#qnaModal">에듀톡 문의하기</a>
            </>
        )

    }

    const getFollowButton = () => {
        return <a
            onClick={
                (e) => {
                    e.preventDefault()
                    updateFollow(!isFollow)
                }}
            className={isFollow ? "btn outline gray" : "btn outline blue"}
        >{isFollow ? "팔로우 해제" : "팔로우"}</a>
    }

    const updateFollow = async (isSave: boolean) => {
        const successHandler = (result: any) => {
            let plus = isFollow ? -1 : 1
            proReq.followerCount = proReq.followerCount + plus
            setIsFollow(isSave)
        }

        let params = {
            proidx: proReq.proidx,
            isSave: isSave,
        }

        await ProService.proFollowUpdate(params, successHandler)
    }


    return (
        <section className="profile-section">
            <div className="inner">
                <div className="master-image">
                    <img src={FileUtils.getFileUrl(proReq.profileImgSrc)} alt=""
                         onError={onErrorImg}
                         className="img-profile"/>
                </div>
                <div className="master-info">
                    <div className="name">
                        <strong>{proReq.companyAccountVO?.userName}</strong>
                        <span>
                            사내고수
                        </span>
                    </div>
                    <p className="job">{proReq.companyAccountVO?.coInfoVO?.coName} I {calWorkingYear(proReq.workingYear)}</p>
                    <p className="desc" dangerouslySetInnerHTML={{__html: StringUtils.nl2br(proReq?.proInfo ?? "")}}></p>
                    <ul className="info-list">
                        <li>팔로워 {proReq.followerCount}</li>
                        <li>상담지수 {proReq.chatCount}</li>
                        <li>지식업로드 {proReq.uploadCount}</li>
                    </ul>
                </div>
                <div className="master-keyword">
                    <div className="keyword">
                        <div className="keyword-title">
                            <strong className="tit">활동 직무</strong>
                        </div>
                        <ul className="keyword-list">
                            <li>{proReq.jobCodeName}</li>
                        </ul>
                    </div>
                    <div className="keyword">
                        <div className="keyword-title">
                            <strong className="tit">사내고수 인증 강점 역량</strong>
                            <span className="desc">그룹에서 인증한 고수의 강점 역량입니다.</span>
                        </div>
                        <ul className="keyword-list">
                        {
                                proReq.officialKeyword.split(',').map((value, index) => {
                                    return (<li key={index}>#{value}</li>)
                                })
                            }
                        </ul>
                    </div>
                    <div className="keyword">
                        <div className="keyword-title">
                            <strong className="tit">강점 역량 키워드</strong>
                            <span className="desc">강점 역량과 관련하여 고수에게 배울 수 있는 구체적인 스킬입니다.</span>
                        </div>
                        <ul className="keyword-list">
                        {
                                proReq.strengthKeyword.split(',').map((value, index) => {
                                    return (<li key={index}>#{value}</li>)
                                })
                            }
                        </ul>
                    </div>
                    <div className="keyword">
                        <div className="keyword-title">
                            <strong className="tit">고수가 제안하는 에듀톡 주제</strong>
                            <span className="desc">이외에 궁금한 내용도 자유롭게 질문할 수 있어요</span>
                        </div>
                        <ul className="keyword-list blue">
                            {
                                proReq.chatTopic.split(',').map((value, index) => {
                                    return (<li key={index}>#{value}</li>)
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className="util">
                    <div className="button-area justify-content-end">
                        {
                            getButton()
                        }
                    </div>
                    <p className="active-text">
                        <span>최근 활동일자</span>
                        <span>{moment(proReq.recentActivityDate).format('YYYY.MM.DD')}</span>
                    </p>
                </div>
            </div>
        </section>
    )
}

export default ProProfile
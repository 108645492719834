import React, {useEffect, useState} from "react";
import {BoardVO} from "../../../../core/board/board.vo";
import M_BasicItem from "../item/M_BasicItem";
import {useNavigate, useParams} from "react-router-dom";
import {HWMap} from "../../../../core/common/common.vo";
import WebUtils from "../../../../common/utils/web.utils";
import {BoardInfoVO} from "../../../../core/board/BoardInfo.vo";


const M_Basic: React.FC<{paramMap:HWMap}> = ({paramMap = undefined}) => {
    const navigate = useNavigate();

    const [boardList, setBoardlist] = useState<BoardVO[]>([])
    const [boardInfo, setBoardinfo] = useState<BoardInfoVO>()
    const [headerList, setHeaderList] = useState<String[]>()
    const [isNeedLogin, setIsNeedLogin] = useState<boolean>(false)

    /**
     * 받아온 데이터 정의
     */
    useEffect(() => {
        WebUtils.log(paramMap)
        if (paramMap?.boardList !== undefined) {
            setBoardlist( paramMap.boardList )
            setHeaderList( paramMap.headerList )
            setBoardinfo( paramMap.boardInfo )
        }

        setIsNeedLogin(paramMap && paramMap.isNeedLogin);
    }, [paramMap]);

    return (
        <ul className="webzine-list type2">
            {boardList.map((item, index) => (
                <M_BasicItem key={item.idx} isNeedLogin={isNeedLogin} board={item} />
            ))}
        </ul>
    )
}

export default M_Basic;
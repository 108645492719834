import React, {useEffect, useState} from "react";
import MemberUtils from "../../../../../common/utils/member.utils";
import memberUtils from "../../../../../common/utils/member.utils";
import Pagination from "../../../../pc/common/Pagination";
import {BoardVO} from "../../../../../core/board/board.vo";
import ProEduTalkRow from "./ProEduTalkRow";
import {useSearchParams} from "react-router-dom";
import ProService from "../../../../../core/pro/pro.service";


const ProEduTalk = () => {
    let [query, setQuery] = useSearchParams();

    const [pageParams, setPageParams] = useState({
            page : parseInt(query.get('page') ?? "0"),
            size : parseInt(query.get('size') ?? "5"),
            totalCount: parseInt(query.get('totalCount') ?? "0"),
        }
    )
    const [totalCount, setTotalCount] = useState<number>(0)
    const [statusCode, setStatusCode] = useState<string>(query.get('statusCode') ?? "")
    const [isReceive, setIsReceive] = useState<boolean>(
        (!query.get('isReceive') ? MemberUtils.isPro() : query.get('isReceive')==="true" && MemberUtils.isPro())
    )
    const [boardList, setBoardList] = useState<BoardVO[]>([])
    const [tabMenuList, setTabMenuList] =
        useState<{ name: string, code: string, count: number }[]>([
            {name: '전체', code: "", count: 0},
            {name: '미해결', code: "041901", count: 0},
            {name: '진행중', code: "041902", count: 0},
            {name: '일지작성', code: "041903", count: 0},
            {name: '완료', code: "041904", count: 0},
            {name: '취소', code: "041905", count: 0}
        ])

    const [isNewArr, setIsNewArr]
        = useState<{receiver:boolean, writer:boolean}>({receiver: false, writer: false})

    useEffect(() => {
        getIsNewBadge()

    }, []);

    useEffect(() => {
        getList()
        updateParams()

    }, [pageParams, statusCode, isReceive]);

    const updateParams = () => {
        query.set("page", pageParams.page.toString())
        query.set("size", pageParams.size.toString())
        query.set("statusCode",statusCode)
        query.set("isReceive", isReceive.toString())

        setQuery(query)
    }

    const getIsNewBadge = async () => {
        let successHandler = (result: any) =>{
            setIsNewArr({receiver: result.data.receiver, writer: result.data.writer})
        }
        await ProService.inquiryNewGet(successHandler)
    }

    const getList = async () => {
        let paramStatus = statusCode
        if (statusCode === '041904' && !isReceive){
            paramStatus = '041903,041904'
        }

        let params = {
            ...pageParams,
            statusCode: paramStatus,
            isReceive: isReceive
        }
        const successHandler = (result:any) => {
            setBoardList(result.data.list)
            setTotalCount(result.data.count)
            result.data.statusCount && updateStatusCount(result.data.statusCount)
        }

        await ProService.inquiryListGet(params, successHandler)
    }

    const updateStatusCount = (arr: { code: string, count: number }[]) => {
        let totalCount = 0
        for (let i = 0; i < arr.length; i++) {
            totalCount += arr[i].count
        }

        let completeCount = 0
        if (!isReceive){
            completeCount = arr.filter(val => val.code ==="041903" ||val.code ==="041904")
                .map(value => value.count)
                .reduce((sum, currValue)=>{return sum + currValue}, 0)

        }

        const newTabMenuList = [...tabMenuList];
        newTabMenuList.map(value => {
            if (value.code === '') {
                value.count = totalCount
                return
            }
            if(value.code === '041904' && !isReceive) {
                value.count = completeCount
                return
            }

            value.count = arr.find(value2 => value2.code === value.code)?.count ?? 0
        })

        setTabMenuList(newTabMenuList)

    }

    const updateReceiveTab = (is: boolean) => {
        setPageParams(prev=>{
            return {...prev, page: 0}
        })
        setStatusCode("")
        setIsReceive(is)
    }

    const updateTab = (statusCode: string) => {
        updatePages(0)
        setStatusCode(statusCode)
    }

    const updatePages = (newPages: number) => {
        setPageParams((preParams) => ({
            ...preParams,
            page: newPages
        }));
    }

    const getTab = () => {

        return (
            <ul className={memberUtils.isPro() ? "tab-list" : "tab-list single"} style={{marginBottom:'8px'}}>
                {
                    memberUtils.isPro() && (
                        <li className={isReceive ? "tab-item active" : "tab-item"}>
                            <a href="#" onClick={e => {
                                e.preventDefault()
                                updateReceiveTab(true)
                            }}>요청받은 내역{isNewArr.receiver && <i className="ico-new"/>}</a>
                        </li>
                    )
                }
                <li className={!isReceive ? "tab-item active" : "tab-item"}>
                    <a href="#" onClick={e => {
                        e.preventDefault()
                        updateReceiveTab(false)
                    }}>요청한 내역{isNewArr.writer && <i className="ico-new"/>}</a>
                </li>
            </ul>
        )
    }

    return (
        <main className="content edutalk">
            <div className="common-title">
                <div className="inner">
                    <h2 className="tit">나의 에듀톡</h2>
                </div>
            </div>
            <section className="list-section">
                <div className="inner">
                    <div className="section-title">
                        {
                            getTab()
                        }
                        <p className="desc">
                            도움요청에 대해 너무 걱정할 것없이 티타임 한 번으로도 요청하신 분께는 큰 도움이 될 수 있습니다.<br/>
                            새로 만드시거나 정리해주시는 것도 좋지만 가지고 계신 지식을 편하게 공유해보세요. 요청이 해결되면 문의해주신 분과 답변해주신 분
                            모두에게 포인트를 지급해드립니다.
                        </p>
                    </div>
                    <div className="board-util">
                        <div className="util-group">
                            <div className="board-tabs">
                                <ul className="tab-list">
                                    {
                                        tabMenuList.map((value, index) => {
                                            if (value.code === "041903" && !isReceive) {
                                                return
                                            }
                                            let status = statusCode
                                            if (!isReceive && (statusCode === "041903" || statusCode === "041903,041904")) {
                                                status = "041904"
                                            }

                                            return (
                                                (
                                                    <li className={status === value.code ? "tab-item active" : "tab-item"}
                                                        key={value.code}>
                                                        <a href="#" onClick={e => {
                                                            e.preventDefault()
                                                            updateTab(value.code)
                                                        }}>{value.name} ({value.count})</a>
                                                    </li>
                                                )
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="board-list">
                        {
                            boardList.length > 0 ?
                                (   <>
                                        <ul className="webzine-list type1">
                                            {
                                                boardList.map((value, index) => {
                                                    return (
                                                        <ProEduTalkRow boardVO={value} isReceive={isReceive}
                                                                       key={value.idx}/>
                                                    )
                                                })
                                            }
                                        </ul>
                                        <Pagination page={pageParams.page} totalCount={totalCount} updatePages={updatePages} size={pageParams.size} />
                                    </>
                                )
                                :
                                (
                                    <div className="content-empty">
                                        <i className="ico-content"></i>
                                        <p className="txt">
                                            {
                                                isReceive ? (
                                                    <>
                                                        요청받은 내역이 없습니다.<br/>에듀톡 문의가 들어오면 알림으로 안내해드릴게요
                                                    </>
                                                ) : (
                                                    <>
                                                        요청한 내역이 없습니다.<br/>에듀톡 문의를 통해 사내고수 지식을 공유받아보세요
                                                    </>
                                                )
                                            }
                                        </p>
                                    </div>
                                )
                        }

                    </div>
                </div>
            </section>
        </main>
    )
}

export default ProEduTalk
import { useEffect, useState } from 'react';

interface IuseIntersectionObserverProps {
    threshold?: number;
    hasNextPage: boolean | undefined;
    fetchNextPage: () => any;
}

export const useIntersectionObserver = ({
                                            threshold = 0.1,
                                            hasNextPage,
                                            fetchNextPage,
                                        }: IuseIntersectionObserverProps) => {

    const [target, setTarget] = useState<HTMLDivElement | null | undefined>(null);
    const [isFetching, setIsFetching] = useState(false);

    const observerCallback: IntersectionObserverCallback = (entries) => {
        entries.forEach((entry) => {

            if (entry.isIntersecting && hasNextPage && !isFetching) {
                setIsFetching(true)
                fetchNextPage().finally(()=>setIsFetching(false))
            }
        });
    };

    useEffect(() => {
        if (!target) return;

        //ointersection observer 인스턴스 생성
        const observer = new IntersectionObserver(observerCallback, {
            threshold,
        });

        // 타겟 관찰 시작
        observer.observe(target);

        // 관찰 멈춤
        return () => observer.unobserve(target);
    }, [observerCallback, threshold, target]);

    return { setTarget };
};
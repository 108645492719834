import React, {useEffect, useRef, useState} from "react";
import {BoardVO} from "../../../../../core/board/board.vo";
import M_CardItem from "../../../board/item/M_CardItem";
import APIs from "../../../../../common/apis/APIs";
import JobSkillService from "../../../../../core/jobskill/jobskill.service";
import ElandUtils from "../../../../../common/utils/eland.utils";
import Swiper from "../../../../../assets/js/swiper.min";

interface JobSkillContentRecommendProps {
    weakList: string;
}

const M_JobSkillContentRecommend = (props:JobSkillContentRecommendProps) => {
    let swiperVal:any = null
    const [boardList, setBoardList] = useState<BoardVO[]>([])

    useEffect(() => {
        setTimeout(initSwiper, 200)

    }, [boardList]);

    useEffect(() => {
        getRelatedContents()


    }, [props]);

    const initSwiper = () =>{
        if (swiperVal){
            swiperVal.destroy()
            swiperVal = null
        }

        swiperVal = new Swiper(".board-slide", {
            slidesPerView: "auto",
            spaceBetween: 24,
        });
    }

    const getRelatedContents = () => {
        const params = {
            boardKey: APIs.KNOWLEDGE_BOARDKEY,
            size: 6
        };

        const successHandler = async (result: any) => {
            const updatedBoardList = await ElandUtils.updateBoardListWithSkillNames(result.data);
            const newList = await ElandUtils.updateBoardListWithJobNames(updatedBoardList);
            setBoardList(newList);
        }

        JobSkillService.jobSkillRecommendContentGet(params, successHandler)
    }


    if ((boardList.length <= 0)){
        return (<></>)
    }

    return (
        <section className="recommend-section">
            <div className="inner">
                <div className="section-title">
                    <h3 className="tit">이 콘텐츠를 추천해요!</h3>
                </div>
                <div className="board-list board-slide">
                    <ul className="card-list type1 swiper-wrapper">
                        {
                            boardList.map(value => {
                                return <M_CardItem key={value.idx} board={value} isRateShow={true}/>
                            })
                        }
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default M_JobSkillContentRecommend
import React from "react";
import M_ProEduTalkCommentInput from "../common/M_ProEduTalkCommentInput";
import {CommentVO} from "../../../../../../core/board/board.vo";

interface ProCommentInputProps{
    comment: CommentVO | null,
    statusCode: string,
    updateEvent : () => void
}


const M_ProEduTalkReceiverInput = (props:ProCommentInputProps) => {
    const {comment, updateEvent} = props

    return (
        <section className="reply-section">
            <div className="accordion active">
                <div className="inner">
                    <h3 className="tit-accordion">답변</h3>
                    <div className="accordion-content">
                        <div className="comment type2">
                            <M_ProEduTalkCommentInput comment={comment}
                                                      showCheckEvent={true}
                                                      updateEvent={updateEvent}
                                                      enableTemp={props.statusCode === "041901"}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default M_ProEduTalkReceiverInput
import React from "react";
import ProEduTalkCommentInput from "./ProEduTalkCommentInput";


const ProEduTalkNone = () => {

    return (
        <section className="reply-section empty">
            <div className="inner">
                <div className="empty-text">
                    고수의 답변을 기다리고 있습니다
                </div>
            </div>
        </section>
    )


}

export default ProEduTalkNone
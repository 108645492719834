import React, {useEffect, useState} from "react";
import BoardService from "../../../core/board/board.service";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {BoardFileVO, BoardVO, DefaultBoardVO} from "../../../core/board/board.vo";
import APIs from "../../../common/apis/APIs";
import RelatedItem from "./item/RelatedItem";
import MovCardDetail from "./detail/MovCardDetail";
import BasicDetail from "./detail/BasicDetail";
import {BoardInfoVO} from "../../../core/board/BoardInfo.vo";
import Snb from "./Snb";
import {SessionVO} from "../../../core/common/session.vo";
import MemberUtils from "../../../common/utils/member.utils";
import ProEduTalkModal from "../../pc/common/ProEduTalkModal";
import {CompanyAccountVO} from "../../../core/company/company.vo";
import {ReviewType} from "../review/ReviewType";
import AudienceReview from "../review/AudienceReview";
import PointService from "../../../core/point/point.service";
import FileUtils from "../../../common/utils/file.utils";
import ElandUtils from "../../../common/utils/eland.utils";
import SwalUtils from "../../../common/utils/swal.utils";
import StringUtils from "../../../common/utils/string.utils";

const BoardDetail : React.FC = () => {
    const boardService = new BoardService();
    let {boardKey, bidx, boardTitle} = useParams();

    const [sessionVO, setSessionVO] = useState<SessionVO | null>(MemberUtils.getSessionVO());
    const [boardVO, setBoardVO] = useState<BoardVO>(DefaultBoardVO);
    const [boardInfoVO, setBoardInfoVO] = useState<BoardInfoVO>();
    const [tags, setTags] = useState<string[]>([]);
    const [relatedList, setRelatedList] = useState<BoardVO[]>([]);
    const [fileList, setFileList] = useState<BoardFileVO[]>([] );
    const [mode, setMode] = useState("cardMov");
    const [writerInfo, setWriterInfo] = useState<CompanyAccountVO>();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if(!bidx) return;

        (async () => {
            const result = await boardService.getBoardOne({bidx: bidx});

            if(result.status == "SUCCESS" && result.data) {
                const board = result.data.boardVO;

                // 삭제되었다면 에러화면으로 이동
                if(!board.isUse) {
                    navigate('/notFound', { state: { alertMessage: '삭제되었습니다.' } });
                }

                board.isWish = result.data.isBookMark;
                board.writerInfo = result.data.writerInfo;

                // skillCode, JobCode name 받아오기
                const updatedBoard = await ElandUtils.convertJobSkillNames4Board(board);

                setBoardVO(updatedBoard);
                setFileList(board.attachList);
                setBoardInfoVO(result.data.boardInfoVO);
                setTags(board.tags?.split(","));

                const writerInfo = result.data.writerInfo;
                setWriterInfo(writerInfo);


                // 포인트 적립
                await PointService.savePoint("CONTENT_VIEW", bidx)
            }

        })()
    }, [bidx]);

    useEffect(() => {
        if(boardVO != null && boardVO.idx > 0) {
            console.log("⭐boardVO",boardVO);
            getRelatedContents();
        }

    }, [boardVO]);

    useEffect(() => {
        if(boardInfoVO?.boardMode == "classic" || boardInfoVO?.boardMode == "basic") {
            setMode("basic")
        } else {
            setMode("cardMov")
        }
    }, [boardInfoVO]);


    const deleteOne = (e: any) => {
        e.preventDefault()
        SwalUtils.text({
            title : `안내`,
            message : `지식 콘텐츠 삭제가 불가능합니다.<br />삭제가 필요하신 경우 그룹 인재원에게 소통해주세요.`,
            isCancel : false,
            confirmOptions : {
                text : "확인"
            },
        })
    }

    const getRelatedContents = () => {

        const params = {
            boardKey: APIs.KNOWLEDGE_BOARDKEY,
            coCode: boardVO.coCode,
            tags: boardVO.tags,
            skillCode: boardVO.skillCode
        };

        (async() => {
           const result = await boardService.getRelatedContents(params);

            if(result.status == "SUCCESS" && result.data) {
                // todo 태그 가져와야 함
                const updatedBoardList = await ElandUtils.convertJobSkillNames4BoardList(result.data);
                setRelatedList(updatedBoardList);
            }

        })()
    }

    const goList = () => {
        let from = location.state?.from ?? ""
        if (from.startsWith("/board/list/")) {
            navigate(-1);
        } else {
            navigate(`/board/list/${boardKey}`);
        }
    }



    return (
        <main className={
           mode === 'basic' ?
            "content resources detail nav-layout" :
            "content knowledge detail"
        }>

            {/* 일반 글 상세 화면 */}
            {mode === 'basic' && <BasicDetail boardVO={boardVO} sessionVO={sessionVO} tags={tags}/>}

            {/* 카드뉴스 / 영상 상세 화면 */}
            {
                boardInfoVO && mode === 'cardMov' &&
                <MovCardDetail
                    boardInfoVO={boardInfoVO}
                    boardVO={boardVO}
                    fileList={fileList}
                    tags={tags}
                />
            }

            {
                bidx && boardVO.idx > 0 &&
                <AudienceReview emIdx={0} bidx={parseInt(bidx,10)} type={ReviewType.BOARD} />
            }


            {/* 지식 제작자 */}
            {mode == 'cardMov' && writerInfo &&
                <section className="writer-section">
                    <div className="inner">
                        <div className="section-title">
                            <h3 className="tit">지식 제작자</h3>
                        </div>
                        <div className="knowledge-writer">
                            <img src={FileUtils.getFileUrl(writerInfo?.profileSrc ?? "")}
                                 onError={FileUtils.onErrorImg}
                                 alt="프로필 이미지"
                                 className="img-profile"/>
                            {
                                // 고수나 예비고수
                                MemberUtils.isProRateCode(writerInfo.rateCode) || MemberUtils.isSemiProRateCode(writerInfo.rateCode)
                                ? <>
                                        <div className="text-wrap">
                                            <strong className="name">{MemberUtils.isProRateCode(writerInfo.rateCode) ? '사내고수' : '예비고수'} {writerInfo.userName}</strong>
                                            <span className="job">{writerInfo.teamName}</span>
                                        </div>
                                        {
                                            // 사내고수이고 작성자가 아니면
                                            (MemberUtils.isProRateCode(writerInfo.rateCode) && writerInfo.coEmailId !== MemberUtils.getLoginId()) && (
                                                <a href="#" className="btn red" data-bs-toggle="modal" data-bs-target="#qnaModal">에듀톡
                                                    문의하기</a>
                                            )
                                        }

                                        {
                                            //  작성자라면 표출
                                            writerInfo.coEmailId == MemberUtils.getLoginId() && (
                                                <div className="util">
                                                    <a href="#" className="btn-delete" onClick={(e)=>deleteOne(e)}><span className="blind">삭제하기</span></a>
                                                    <Link to={`/board/${boardKey}/${bidx}/${StringUtils.titleUrlFilter(boardTitle || "")}}/form`} className="btn-modify">
                                                        <span className="blind">수정하기</span>
                                                    </Link>
                                                </div>
                                            )
                                        }
                                    </>
                                    : <>
                                        <div className="text-wrap">
                                            <strong
                                                className="name">{boardVO.writer}</strong>
                                            <span className="job">이랜드 에듀는 이랜드 임직원분들의 성장을 돕기 위해 시작한 교육 플랫폼입니다.</span>
                                            {/*<span className="job">{writerInfo.teamName}</span>*/}
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </section>
            }


            <ProEduTalkModal receiver={writerInfo?.userName ?? ''}
                             receiverKey={writerInfo?.coEmailId ?? ""} boardVO={null}/>

            {/* 목록으로 버튼 */}
            {mode == 'basic' &&
                <div className="button-area">
                    <div className="inner">
                        <a onClick={e=>{
                            e.preventDefault()
                            goList()
                        }} type="button" className="btn outline gray">목록으로</a>
                    </div>
                </div>
            }

            {/* 연관 지식 */}
            {mode == 'cardMov' && <RelatedItem boardList={relatedList}/>}

            {/* 사이드 메뉴 바 */}
            {mode == 'basic' && <Snb/>}
        </main>
    );
}

export default BoardDetail;
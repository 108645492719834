import React, {useEffect, useState} from "react";
import {BoardVO, DefaultBoardInfoVO} from "../../../../core/board/board.vo";
import {HWMap} from "../../../../core/common/common.vo";
import {PageUtils} from "../../../../common/utils/page.utils";
import BoardService from "../../../../core/board/board.service";
import StringUtils from "../../../../common/utils/string.utils";
import Pagination from "../../common/Pagination";
import Basic from "../../board/listType/Basic";
import NullItem from "../../board/item/NullItem";
import {BoardInfoVO} from "../../../../core/board/BoardInfo.vo";
import ElandUtils from "../../../../common/utils/eland.utils";


interface JobDataListProps {
    searchText: string;
    setTotalCount: (count: number) => void;
}
const JobDataList : React.FC<JobDataListProps> = ({searchText, setTotalCount}) => {

    const [jobList, setJobList] = React.useState<BoardVO[]>([]);
    const [count, setCount] = React.useState(0);
    const [params, setParams] = useState<HWMap>(new PageUtils())
    const boardService = new BoardService();

    useEffect(() => {
        console.log("⭐searchText");
        if(searchText) {
            setParams({
                page: 0,
                size: 10,
                sort: "createDate,DESC",
                boardKey: 'job_data',
                searchText: searchText,
            });
        }
    }, [searchText]);


    useEffect(() => {
        if (params && params.size && params.boardKey != '') {

            console.log("⭐getSearchBoards");
            (async () => {
                const result = await boardService.getSearchBoards(params);
                if(result.status == "SUCCESS" && result.data) {
                    const updatedBoardList = await ElandUtils.convertJobSkillNames4BoardList(result.data.list);
                    setJobList(updatedBoardList)
                    setCount(result.data.totalCount)
                    setTotalCount(result.data.totalCount)
                }
            })()
        }
    }, [params])

    // 페이지 업데이트
    const updatePages = (newPage: number) => {
        setParams(prevParams => ({
            ...prevParams,
            page: newPage
        }));
    }

    return (
        <section className="resources-section">
            <div className="inner">
                <div className="section-title">
                    <h3 className="tit">사내고수의 자료실</h3>
                </div>
                <div className="board-list">

                    {/* 직무자료실 리스트 */}
                    { jobList.length > 0 ? <Basic paramMap={{boardList: jobList}}/> : <NullItem /> }

                    {/* 페이지네이션 */}
                    { jobList.length > 0 && <Pagination page={params.page} totalCount={count} updatePages={updatePages} size={params.size}/>}

                </div>
            </div>
        </section>
    )
}

export default JobDataList;
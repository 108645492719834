import axiosCaller from "../../common/utils/axios.caller";
import APIs from "../../common/apis/APIs";
import {HWMap} from "../common/common.vo";
import {useNavigate} from "react-router-dom";
import BoardService from "../board/board.service";
import SwalUtils from "../../common/utils/swal.utils";
import ToastUtils from "../../common/utils/toast.utils";

class MainService {
    // 메인 리스트 조회

    static failHandler = (result: any) => {
        if (result.status){
            if (result.status === "FAIL"){
                // ToastUtils.show(result.msg)

            } else if (result.status === "ERROR"){
                ToastUtils.show(result.msg)
                console.log(result.data)

            } else if (result.status === "UNAUTHORIZATION"){
                ToastUtils.show(result.msg)

                // 로그인 페이지 이동
                const navigate = useNavigate()
                navigate("/login")
            }
        } else {
            ToastUtils.show("네트워크 오류가 발생했습니다. 잠시 후 시도해주세요.")
        }

    }

    // 배너 리스트
    static getMainBannerList = async (params: HWMap,
                                success: (result: any) => void) => {
        try {
            const result = await axiosCaller.get(APIs.MAIN_LIST + "/", params);
            if (result.status === 'SUCCESS') {
                console.log("SUCCESS")
                success(result)
            } else {
                MainService.failHandler(result)
            }

        } catch (error) {
            MainService.failHandler(error)
        }
    }

    // 현재 진행중인 교육
    static getEduMainList = async (params: HWMap,
                                      success: (result: any) => void) => {
        try {
            const result = await axiosCaller.get(APIs.EDU_MAIN + "/getEduMainList.v2", params);
            if (result.status === 'SUCCESS') {
                console.log("SUCCESS")
                success(result)
            } else {
                MainService.failHandler(result)
            }

        } catch (error) {
            MainService.failHandler(error)
        }
    }

    // 에디터 추천 플레이리스트
    static getRecommendPlayList = async (params: HWMap,
                                         success: (result: any) => void) => {
        try {
            const result = await axiosCaller.get(APIs.BOARDS + "/getRecommendPlayList", params);
            if (result.status === 'SUCCESS') {
                console.log("SUCCESS")
                success(result)
            } else {
                MainService.failHandler(result)
            }

        } catch (error) {
            MainService.failHandler(error)
        }
    }

    // 법인 플레이 리스트
    static getCompanyPlayList = async (params: HWMap,
                                         success: (result: any) => void) => {
        try {
            const result = await axiosCaller.get(APIs.BOARDS + "/getRecommendPlayList", params);
            if (result.status === 'SUCCESS') {
                console.log("SUCCESS")
                success(result)
            } else {
                MainService.failHandler(result)
            }

        } catch (error) {
            MainService.failHandler(error)
        }
    }

    // 에디터's PICK
    static getEditorPickList = async (params: HWMap,
                                         success: (result: any) => void) => {
        try {
            const result = await axiosCaller.get(APIs.BOARDS + "/getEditorPickList", params);
            if (result.status === 'SUCCESS') {
                console.log("SUCCESS")
                success(result)
            } else {
                MainService.failHandler(result)
            }

        } catch (error) {
            MainService.failHandler(error)
        }
    }

    // 신규 콘텐츠
    static getNewContents = async ( params: HWMap,
                                    success: (result: any) => void) => {
        try {
            const result = await axiosCaller.get(APIs.MAIN_NEW_CONTENS, params);
            if (result.status === 'SUCCESS') {
                console.log("SUCCESS")
                success(result)
            } else {
                MainService.failHandler(result)
            }

        } catch (error) {
            MainService.failHandler(error)
        }
    }

    // 적용 스토리
    static getMainUseStory = async (params: HWMap,
                                    success: (result: any) => void) => {
        try {
            const result = await axiosCaller.get(APIs.BOARDS + "/getMainUseStory", params);
            if (result.status === 'SUCCESS') {
                console.log("SUCCESS")
                success(result)
            } else {
                MainService.failHandler(result)
            }

        } catch (error) {
            MainService.failHandler(error)
        }
    }
}

export default MainService;
import React, { useState, useEffect } from 'react';
import { CodeVO } from "../../../core/common/code.vo";
import CommonJS from "../../../common/common";
import CodeUtils from "../../../common/utils/code.utils";
import ElandUtils from "../../../common/utils/eland.utils";

interface SkillFilterSelectButtonProps {
    updateSkillCode: (newSkillCodes: string) => void;
    initCode?: string;
}

const SkillFilterSelectButton = ({ updateSkillCode, initCode }: SkillFilterSelectButtonProps) => {
    const [selectedCodes, setSelectedCodes] = useState<string[]>(initCode ? initCode.split(',') : []);
    const [skillCodeList, setSkillCodeList] = useState<CodeVO[]>([]);

    useEffect(() => {
        getSkillCodeList();
        CommonJS.select();
    }, []);

    useEffect(() => {
        if (initCode) {
            setSelectedCodes(initCode.split(','));
        }
    }, [initCode]);

    const getSkillCodeList = async () => {
        let jobSkillCodeList = await ElandUtils.getJobSkillCodeList(true);
        setSkillCodeList(jobSkillCodeList);
    };

    const handleCheckboxChange = (code: string) => {
        let newSelectedCodes;
        if (selectedCodes.includes(code)) {
            newSelectedCodes = selectedCodes.filter(selectedCode => selectedCode !== code);
        } else {
            newSelectedCodes = [...selectedCodes, code];
        }
        setSelectedCodes(newSelectedCodes);
        updateSkillCode(newSelectedCodes.join(','));
    };

    /**
     * 선택한 직무 코드 이름 반환
     */
    const selectedCodeNames = () =>{
        let selectArr = skillCodeList.filter((code) => selectedCodes.includes(code.code))
        let str = selectArr.filter((code, index) => index < 1)
            .map((code) => code.codeName)
            .join(', ');

        let nStr = selectArr.length > 1 ? ` 외 ${selectArr.length - 1}개` : ''

        return str + nStr
    }



    return (
        <div className="select">
            <button type="button" className="btn-select">
                {selectedCodes.length > 0 ? selectedCodeNames() : `직무 역량 전체 (${skillCodeList.length})`}
            </button>
            <ul className="select-multi-option">
                {skillCodeList.map((code, index) => {
                    return (
                        <li key={index} className={selectedCodes.includes(code.code) ? "selected" : ""}>
                            <div className="checkbox">
                                <input
                                    type="checkbox"
                                    id={`checkId${index + 1}`}
                                    className="inp-checkbox"
                                    checked={selectedCodes.includes(code.code)}
                                    onChange={() => handleCheckboxChange(code.code)}
                                />
                                <label htmlFor={`checkId${index + 1}`} className="lab-checkbox">
                                    {code.codeName}
                                </label>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default SkillFilterSelectButton;

import React, {useEffect, useState} from "react";
import {BoardVO, DefaultBoardVO} from "../../../../core/board/board.vo";
import APIs from "../../../../common/apis/APIs";
import CodeUtils from "../../../../common/utils/code.utils";
import ElandUtils from "../../../../common/utils/eland.utils";
import {ProReqVO} from "../../../../core/pro/pro.vo";
import FileUtils from "../../../../common/utils/file.utils";
import UseStoryThumbImage from "./UseStoryThumbImage";

interface UseStorySelectProps {
    contentList: BoardVO[],
    proList: ProReqVO[],
    isPro: boolean,
    selectBoard: BoardVO | null,
    setSelectBoard:(board: BoardVO) => void;
}

const UseStorySelect: React.FC<UseStorySelectProps> = ({contentList, isPro, selectBoard, proList, setSelectBoard}) => {

    const [updatedContentList, setUpdatedContentList] = useState<BoardVO[]>([]);
    const [updatedProList, setUpdatedProList] = useState<ProReqVO[]>([]);

    const proSelect = (pro: ProReqVO) => {
        let board: BoardVO = selectBoard ? selectBoard : DefaultBoardVO()
        board.receiverProInfo = pro;
        setSelectBoard(board);
    }

    const updateBoardContentList = async () => {
        if (contentList.length > 0) {
            const updatedList = [];
            for (const item of contentList) {
                const jobCodeName = await ElandUtils.convertJobSkillCodesToNames(item?.receiverProInfo?.jobCode);
                updatedList.push({ ...item, receiverProInfo: { ...item.receiverProInfo, jobCodeName } });
            }
            setUpdatedContentList(updatedList);
        } else {
            setUpdatedContentList([]);
        }
    };

    const updateProList = async () => {
        if (proList.length > 0) {
            const updatedList = [];
            for (const item of proList) {
                const jobCodeName = await ElandUtils.convertJobSkillCodesToNames(item.jobCode);
                updatedList.push({ ...item, jobCodeName });
            }
            setUpdatedProList(updatedList);
        } else {
            setUpdatedProList([]);
        }
    };


    useEffect(() => {

        if(isPro) {
            updateProList();
        } else {
            updateBoardContentList();
        }

    }, [contentList, proList]);


    return (
        <>
            { isPro && proList.length == 0 && <div className="content-empty">도움받은 사내고수가 없습니다.<br/>에듀톡 문의하기를 통해 도움을 받아보세요.</div> }
            { !isPro && updatedContentList.length == 0 && <div className="content-empty">최근 본 지식콘텐츠가 없습니다.</div> }


            {!isPro && contentList.length > 0 &&
                <ul className="option-list pro-list">
                    {updatedContentList.map((item, index) => (
                        <li key={index} onClick={() => setSelectBoard(item)}>
                            <div className="radio">
                                <input
                                    type="radio"
                                    name="radios2"
                                    id={`radioOptPro${index}`}
                                    className="inp-radio"
                                    checked={selectBoard?.idx === item.idx}
                                />
                                <label htmlFor={`radioOptPro${index}`} className="lab-radio">
                                    <div className="image-wrap">
                                        <UseStoryThumbImage item={item && item} key={index} />
                                    </div>
                                    <div className="text-wrap">
                                        <strong className="tit">{item.title}</strong>
                                        <div className="info">
                                            <span>
                                                {
                                                    (item.writerInfo?.isAdmin)
                                                        ? `${item.writer}`
                                                        : `${item.writerInfo?.coInfoVO?.coName} ${item.writer} 고수`
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </li>
                    ))}
                </ul>
            }

            {
                isPro && updatedProList.length > 0 &&
                <ul className="option-list non-pro-list">
                    {updatedProList.map((item, index) => (
                        <li key={index} onClick={() => proSelect(item)}>
                            <div className="radio">
                                <input
                                    type="radio"
                                    name="radios2"
                                    id={`radioOpt${index}`}
                                    className="inp-radio"
                                    checked={selectBoard?.receiverProInfo?.proidx === item.proidx}
                                />
                                <label htmlFor={`radioOpt${index}`} className="lab-radio">
                                    <div className="image-wrap">
                                        <img
                                            src={item?.profileImgSrc}
                                            alt="profileImg"
                                            className="img-thumb"
                                        />
                                    </div>
                                    <div className="text-wrap">
                                        <strong className="tit">{item.companyAccountVO?.userName}</strong>
                                        <div className="info">
                                            <span>{item.jobCodeName}</span>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </li>
                    ))}
                </ul>
            }

        </>
    )
}

export default UseStorySelect;
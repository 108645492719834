import React, {useEffect, useState} from "react";
import M_ProContentSlide from "./M_ProContentSlide";
import M_ProReview from "../../review/M_ProReview";
import M_ProEduTalkModal from "../../../mobile/common/M_ProEduTalkModal";
import {useParams} from "react-router-dom";
import {DefaultProReqVO, ProReqVO} from "../../../../core/pro/pro.vo";
import M_ProProfile from "./M_ProProfile";
import ProService from "../../../../core/pro/pro.service";
import {BoardVO} from "../../../../core/board/board.vo";
import ElandUtils from "../../../../common/utils/eland.utils";
import useHeader from "../../../../common/hook/useHeader";
import {HeaderBtn} from "../../../../core/common/header";
import useFooter from "../../../../common/hook/useFooter";
import CommonMobileJS from "../../../../common/common_m";
import M_BottomButton from "../../common/M_BottomButton";

const M_ProPersonal = () => {

    useFooter({
        isHidden: true
    })

    useHeader({
        type: 'content',
        title: '사내고수',
        buttons: [HeaderBtn.Back]
    });

    const {proidx} = useParams()
    const [proReq, setProReq] = useState<ProReqVO>(DefaultProReqVO())
    const [isMe, setIsMe] = useState<boolean>(false)
    const [isFollow, setIsFollow] = useState<boolean>(false)
    const [isShowEduTalk, setIsShowEduTalk] = useState<boolean>(false)

    const [proContentList, setProContentList] = useState<BoardVO[]>([])
    const [proContentCount, setProContentCount] = useState<number>(0)
    const [proJobDataList, setProJobDataList] = useState<BoardVO[]>([])
    const [proJobDataCount, setProJobDataCount] = useState<number>(0)

    useEffect(() => {
        getProInfo()
        getProContentAndJob()
        CommonMobileJS.floatingArea()

    }, []);


    const getProInfo = async () => {
        const successHandler = (result: any) => {
            renderCodeAndSetPro(result.data.pro)
            setIsMe(result.data.isMe)
            setIsFollow(result.data.isFollow)
        }

        proidx && await ProService.proInfoGet(proidx, successHandler)
    }

    const getProContentAndJob = async () => {
        const successHandler = (result: any) => {
            setProContentList(result.data.know.list)
            setProContentCount(result.data.know.count)
            setProJobDataList(result.data.job.list)
            setProJobDataCount(result.data.job.count)
        }

        proidx && await ProService.proContentAndJobGet(proidx, successHandler)
    }

    const renderCodeAndSetPro = async (pro: ProReqVO) => {
        const newProReq: ProReqVO = {...pro}
        newProReq.jobCodeName = await ElandUtils.getJobSkillCodeName(pro.jobCode)
        newProReq.skillCodeName = await Promise.all(
            pro.skillCode.split(",").map((code) => ElandUtils.getJobSkillCodeName(code))
        )

        setProReq(newProReq)
    }

    return (
        <>
            {
                !isShowEduTalk ? (
                    <div className="content master-home">
                        {proReq &&
                            <M_ProProfile proReq={proReq} isMe={isMe}
                                          isFollow={isFollow}></M_ProProfile>}
                        <M_ProContentSlide isContent={true} contentList={proContentList}
                                           count={proContentCount}/>
                        <M_ProContentSlide isContent={false} contentList={proJobDataList}
                                           count={proJobDataCount}/>
                        {
                            (proReq && proReq.companyAccountVO && proReq.companyAccountVO.coEmailId !== '')
                            && <M_ProReview eduTalkVO={proReq.companyAccountVO}></M_ProReview>
                        }

                        {
                            !isMe && (
                                <M_BottomButton name={"에듀톡 문의하기"} onClick={e => {
                                    e.preventDefault()
                                    setIsShowEduTalk(true)
                                }}/>
                            )
                        }

                    </div>
                ) : (
                    <M_ProEduTalkModal receiver={proReq.companyAccountVO?.userName ?? ''}
                                       receiverKey={proReq.companyAccountVO?.coEmailId ?? ""}
                                       boardVO={null}
                                       updateEvent={() => {
                                           setIsShowEduTalk(false)
                                       }}/>
                )
            }
        </>
    )
}

export default M_ProPersonal

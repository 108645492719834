import {BannerVO} from "../../../core/main/main.banner.vo";
import React from "react";

interface MainBannerItemProps {
    item: BannerVO
}

const M_MainBannerItem = (props: MainBannerItemProps) => {
    const { item} = props;

    return (
        <li className="swiper-slide">
            <a href={item.link} target={item.linkType}>
                <img src={item.bannerSrc} alt={item.title} style={{width: '100%'}} className="img-visual"/>
            </a>
        </li>
    )
}

export default M_MainBannerItem
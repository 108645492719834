import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import CodeUtils from "../../../../../common/utils/code.utils";
import {CodeVO} from "../../../../../core/common/code.vo";
import CommonJS from "../../../../../common/common";
import MemberUtils from "../../../../../common/utils/member.utils";
import ElandUtils from "../../../../../common/utils/eland.utils";
import ToastUtils from "../../../../../common/utils/toast.utils";

const JobSkillModal = () => {
    const navigate = useNavigate();
    const [jobCodeList, setJobCodeList] = useState<CodeVO[]>([])
    const [selectJobCode, setSelectJobCode] = useState<string>('')
    const closeBtn = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        getJobCodeList();
        CommonJS.select();
    }, []);
    const goSubmit = () => {
        if (!selectJobCode || selectJobCode === '') {
            ToastUtils.show("본인에게 가장 가까운 직무를 선택해주세요")
            return
        }
        navigate("/mypage/jobskill/input", {
            state: {
                jobCode: selectJobCode
            }
        })

        closeBtn.current && closeBtn.current.click()
    }

    const getJobCodeList = async () => {
        const jobSkillCodeList = await ElandUtils.getJobCodeListForPoll();
        const jobCodeList = MemberUtils.getCoInfoVO()?.jobCodeList ?? "";
        const jobCodeArr = jobCodeList.split(",");
        const filter = jobSkillCodeList.filter(code=>jobCodeArr.includes(code.code));
        setJobCodeList(filter)
    }

    return (
        <div className="modal fade" id="applyModal" tabIndex={-1} aria-labelledby="modalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="modalLabel">직무역량진단</h3>
                    </div>
                    <div className="modal-body">
                        <p className="desc">
                            본인에게 가장 가까운 직무를 선택해주세요.<br/>
                            본인의 직무가 선택지에 없을 경우, &apos;이랜드인(공통)&apos;을 선택해주세요.
                        </p>

                        <div className="select">
                            <button type="button" className="btn-select">직무를 선택해주세요</button>
                            <ul className="select-option">
                                {
                                    jobCodeList.map(code=>{
                                        return (
                                            <li className={code.code === selectJobCode ? "selected" : ""} key={code.code}>
                                                <a href="#" onClick={e => {
                                                    e.preventDefault()
                                                    setSelectJobCode(code.code)
                                                }}>
                                                    <span>{code.codeName}</span>
                                                </a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="button-area">
                            <button type="button" className="btn outline sm" data-bs-dismiss="modal" ref={closeBtn}>취소</button>
                            <button type="button"
                                    className={!selectJobCode || selectJobCode === '' ? "btn sm disabled" : "btn sm"}
                                    onClick={goSubmit}>확인</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JobSkillModal
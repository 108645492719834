import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import M_BoardListType from "./M_BoardListType";
import M_BoardCardType from "./M_BoardCardType";
import {BoardVO, DefaultBoardInfoVO} from "../../../../core/board/board.vo";
import {PageParams} from "../../../../common/utils/page.utils";
import {BoardInfoVO} from "../../../../core/board/BoardInfo.vo";
import MemberUtils from "../../../../common/utils/member.utils";
import M_BoardBasicType from "./M_BoardBasicType";
import M_SearchInput from "../../common/M_SearchInput";
import M_InfinityScroll from "../../common/M_InfinityScroll";
import {HWMap} from "../../../../core/common/common.vo";
import axiosCaller from "../../../../common/utils/axios.caller";
import APIs from "../../../../common/apis/APIs";
import M_BottomButton from "../../common/M_BottomButton";

const M_MypageBoard = () => {
    const scrollRef = useRef<{ reload: () => void, saveScroll:(index:number|null)=>void }>(null);

    const navigate = useNavigate()
    const {boardKey} = useParams()
    const [query, setQuery] = useSearchParams();
    const [boardMode, setBoardMode] = useState<string>("classic")
    const [boardInfo, setBoardInfo] = useState<BoardInfoVO>(DefaultBoardInfoVO())

    const [boardList, setBoardList] = useState<BoardVO[]>([])
    const [noticeList, setNoticeList] = useState<BoardVO[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [pageParams, setPageParams] = useState<PageParams>(
        {
            page: parseInt(query.get('page') ?? "0"),
            size: parseInt(query.get('size') ?? "1"),
            sort: query.get('sort') ?? "createDate,DESC",
            searchText: query.get("searchText") ?? "",
            boardKey: boardKey ?? "",
            cateCode: "",
            statusCode: ""
        }
    );


    useEffect(() => {
        init()

    }, [boardKey]);


    useEffect(() => {
        updateParams()

    }, [pageParams]);

    const init = () => {
        setBoardList([])
        setNoticeList([])
        initPageParams()

    }

    const initPageParams = () => {
        setPageParams({
            page: parseInt(query.get('page') ?? "0"),
            size: parseInt(query.get('size') ?? "1"),
            sort: query.get('sort') ?? "createDate,DESC",
            searchText: query.get("searchText") ?? "",
            boardKey: boardKey ?? "",
            cateCode: "",
            statusCode: ""
        })
    }

    const fetchList = async (makeParamFunc:(params:HWMap, size:number) => HWMap) => {
        let params = makeParamFunc({...pageParams, boardKey: boardKey ?? ""}, pageParams.size)
        let result = await axiosCaller.get(APIs.MYPAGE_BOARD + '/list',params)
        if (result.status === "SUCCESS"){
            setBoardMode(result.data.boardInfo.boardMode)
            setBoardInfo(result.data.boardInfo)
            setTotalCount(result.data.boardList.count)

            if (result.data.boardInfo.isNotice){
                setNoticeList(result.data.noticeList.list)
            }

            return result.data.boardList.list
        }

        return []
    }


    const updateParams = () => {
        query.set("sort", pageParams.sort.toString())
        query.set("searchText", pageParams.searchText)

        setQuery(query, {replace:true})
    }

    const clickSort = (e: React.MouseEvent<HTMLAnchorElement>, sort: string) => {
        e.preventDefault()
        scrollRef.current && scrollRef.current.reload()
        setPageParams(prev => {
            return {...prev, sort: sort}
        })
    }

    const updateSearchText = (searchText: string) => {
        scrollRef.current && scrollRef.current.reload()
        setPageParams((preParams) => ({
            ...preParams,
            searchText: searchText
        }));
    }

    const isPossibleWrite = () => {
        if (boardInfo.isWrite) {
            if (boardInfo.isWriteRate === "") {
                return true
            }

            const allowRateCodeArr = boardInfo.isWriteRate.split(",");
            for (let i = 0; i < allowRateCodeArr.length; i++) {
                if (MemberUtils.getSessionVO()?.rateCode === allowRateCodeArr[i]) {
                    return true
                }
            }
        }
        return false
    }

    const goCreate = () => {
        navigate('/mypage/board/' + boardKey + "/form", {
            state: {
                cateCode: boardInfo.cateCode,
                isFile:boardInfo.isFile,
                isForm:boardInfo.isForm,
                formText:boardInfo.formText,
            }
        })
    }

    const reset = () => {
        init()
        scrollRef.current && scrollRef.current.reload()

    }

    const getTypeRow = ()=> {
        if (boardMode === 'basic'){
            return (<M_BoardBasicType boardInfo={boardInfo}
                                      boardList={boardList}
                                      noticeList={noticeList}/>)
        } else {
            if (boardMode === 'classic' || boardMode === 'gallery') {
                return (
                    <main id="mypage-board" className="content">
                        <div className="search-area border-10">
                            <div className="inner">
                                <M_SearchInput initText={pageParams.searchText}
                                               updateSearchText={updateSearchText}
                                               placeholderText={"검색어를 입력해주세요"}
                                               cancelHandler={reset}
                                />
                            </div>
                        </div>
                        <section className="list-section">
                            <div className="inner">
                                <div className="board-util">
                                    <div className="util-group">
                                        <div className="board-total">
                                            총 <span>{totalCount}</span> 개
                                        </div>
                                        <div className="board-sort">
                                            <div className="sort-list">
                                                <a href="#"
                                                   className={pageParams.sort === 'createDate,DESC' ? 'active' : ''}
                                                   onClick={(e) => clickSort(e, 'createDate,DESC')}>최신순</a>
                                                <a href="#"
                                                   className={pageParams.sort === 'views,DESC' ? 'active' : ''}
                                                   onClick={(e) => clickSort(e, 'views,DESC')}>조회순</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="board-list">
                                    {
                                        boardMode === 'classic' ? (
                                            <M_BoardListType boardInfo={boardInfo}
                                                              boardList={boardList}
                                                              noticeList={noticeList}/>
                                        ) : (
                                            <M_BoardCardType boardList={boardList}
                                                             boardInfo={boardInfo}
                                                             noticeList={noticeList}/>
                                        )
                                    }
                                </div>
                            </div>
                        </section>
                    </main>
                )
            }
        }
        return <></>
    }


    return (
        <>
            {getTypeRow()}
            <M_InfinityScroll<BoardVO>
                queryKey={"helpBoadList"}
                update={fetchList}
                defaultSize={pageParams.size}
                totalCount={totalCount}
                getList={val=>{setBoardList(val)}}
                scrollRef={scrollRef} />
            {
                isPossibleWrite() && (
                    <M_BottomButton name={"작성하기"}
                                    onClick={goCreate}/>
                )
            }
        </>
    )
}

export default M_MypageBoard
import React from "react";

const M_NullItem: React.FC<{ text?: string }> = ({ text }) => {
    // 줄바꿈 문자를 <br /> 태그로 대체하는 함수
    const formatText = (input: string) => {
        return input.split('\n').map((str, index) => (
            <React.Fragment key={index}>
                {str}
                <br />
            </React.Fragment>
        ));
    };

    return (
        <div className="board-list">
            <div className="content-empty">
                <i className="ico-content"></i>
                <p className="txt">
                    {text ? formatText(text) : (
                        <>
                            검색 결과가 없습니다<br />
                            입력된 내용을 다시 한 번 확인해주세요
                        </>
                    )}
                </p>
            </div>
        </div>
    );
};


export default M_NullItem;
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {
    BoardFileVO,
    BoardVO,
    DefaultBoardInfoVO,
    DefaultBoardVO
} from "../../../../core/board/board.vo";
import APIs from "../../../../common/apis/APIs";
import moment from "moment";
import FileUtils from "../../../../common/utils/file.utils";
import M_SummernoteIframe from "../../common/M_SummernoteIframe";
import MemberUtils from "../../../../common/utils/member.utils";
import MypageService from "../../../../core/mypage/mypage.service";
import ElandUtils from "../../../../common/utils/eland.utils";
import ToastUtils from "../../../../common/utils/toast.utils";
import {BoardInfoVO} from "../../../../core/board/BoardInfo.vo";
import M_BoardDetailComment from "./M_BoardDetailComment";
import useHeader from "../../../../common/hook/useHeader";
import {HeaderBtn} from "../../../../core/common/header";
import useFooter from "../../../../common/hook/useFooter";
import SwalUtils from "../../../../common/utils/swal.utils";

const M_MypageBoardDetail = () => {


    useFooter({isHidden:true})

    const navigate = useNavigate()
    const {bidx,boardKey} = useParams()
    const [boardVO, setBoardVO] = useState<BoardVO>(DefaultBoardVO)
    const [isRecommend, setIsRecommend] = useState<boolean>(false)
    const [isMe, setIsMe] = useState<boolean>(false)
    const [boardInfo, setBoardInfo] = useState<BoardInfoVO>(DefaultBoardInfoVO)
    const [title, setTitle] = useState<string>("")
    const [buttons, setButtons] = useState<[HeaderBtn]>()

    useHeader({
        type:"content",
        title: title,
        buttons:buttons
    })

    useEffect(() => {
        getDetail()

        switch (boardKey) {
            case "announce":
                setTitle("공지사항")
                setButtons([HeaderBtn.Close])
                break
            case "organization":
                setTitle("인사 게시판")
                setButtons([HeaderBtn.Back])
                break
            case "company":
                setTitle("법인 게시판")
                setButtons([HeaderBtn.Back])
        }

    }, []);

    const getDetail = async () => {
        const successHandler = async (result: any) => {
            const board = result.data.board;
            board.skillName = await ElandUtils.convertJobSkillCodesToNames(board.skillCode);
            setBoardVO(board);
            setIsRecommend(result.data.isRecommend);
            setIsMe(board.writerKey === MemberUtils.getLoginId())
            setBoardInfo(board.boardInfoVO)
        }

        MypageService.boardOneGet(bidx ?? "0", successHandler)
    }


    const deleteBoard = async (e:any) => {
        e.preventDefault()
        if (isMe){
            SwalUtils.text({
                title:"삭제하기",
                message:"삭제 클릭 시 동작을 되돌릴 수 없습니다.<br/>정말로 삭제하시겠습니까?",
                isIcon:false,
                confirmOptions:{
                    text:"삭제",
                    eventHandler:async ()=>{
                        let params = {
                            bidx: boardVO.idx,
                        }

                        const successHandler = () => {
                            ToastUtils.show("삭제되었습니다.")
                            navigate(-1)
                        }

                        await MypageService.boardOneDelete(params, successHandler)
                    }
                },
                cancelOptions:{
                    text:"취소",
                    eventHandler:()=>{}
                },
                isCancel:true
            })
        }
    }

    const updateBoard = (e:any) =>  {
        e.preventDefault()

        if (isMe){
            navigate('/mypage/board/' + boardKey + "/" + bidx + '/form', {
                state : {
                    board: boardVO,
                    fidxs: boardVO.attachList?.map(value => value.fidx).join(",") ?? "",
                    thumbFidxs: boardVO.thumbIdx ? boardVO.thumbIdx.toString() : "",
                    cateCode: boardVO.boardInfoVO.cateCode,
                    isFile:boardInfo.isFile,
                    isForm:boardInfo.isForm,
                    formText:boardInfo.formText,
                }
            })
        }
    }

    const doRecommend = async (e:any, isSave:boolean) => {
        e.preventDefault()

        let params = {
            bidx: boardVO.idx,
            isSave: isSave,
        }

        const successHandler = () => {
            setIsRecommend(isSave)
            const newBoard = {...boardVO}
            newBoard.likeCount = isSave ? ++newBoard.likeCount : --newBoard.likeCount
            setBoardVO(newBoard)
        }

        await MypageService.boardOneRecommend(params, successHandler)
    }

    const downloadFile = async (e: any, fileVO: BoardFileVO) => {
        e.preventDefault()
        window.location.href = `${APIs.BOARD_FILES_DOWN}${fileVO.fidx}`
    }

    const isGalleryOrClassic = () => {
        return (boardInfo.boardMode === 'gallery' || boardInfo.boardMode === 'classic')
    }


    return (
        <main className="content detail">
            <section className={`detail-section ${boardInfo.isComment ? "border-10" : ""}`}>
                <div className="inner">
                    <div className="detail-title border-2">
                        <div className="cate">
                            <a href="#" onClick={e => e.preventDefault()}>{boardVO.cateName}</a>
                        </div>
                        <h2 className="tit">{boardVO.title}</h2>
                        <div className="info">
                            <span
                                className="date">{moment(boardVO.createDate).format("YYYY-MM-DD")}</span>
                            <div className="rating-wrap">
                                {
                                    isGalleryOrClassic() && (
                                        <span className="like">{boardVO.likeCount}</span>)
                                }
                                <span className="hits">{boardVO.views}</span>
                            </div>
                        </div>
                        <div className="writer">
                            <div className="image-wrap">
                                <img
                                    src={FileUtils.getFileUrl(boardVO.writerInfo?.profileSrc ?? "")}
                                    onError={FileUtils.onErrorImg}
                                    alt="프로필 이미지" className="img-profile"/>
                            </div>
                            <div className="text-wrap">
                                <div className="name">
                                    <b>{boardVO.writer}</b>
                                </div>
                                <span className="job">
                                    {boardVO.writerInfo?.coInfoVO?.coName} {boardVO.writerInfo?.teamName}
                                </span>
                            </div>
                        </div>
                        {
                            isMe && (
                                <div className="util">
                                    <a href="#" className="btn-modify" onClick={updateBoard}>
                                        <span className="blind">수정하기</span>
                                    </a>
                                    <a href="#" className="btn-delete" onClick={deleteBoard}>
                                        <span className="blind">삭제하기</span>
                                    </a>
                                </div>
                            )
                        }
                    </div>
                    <div className="detail-content">
                        {
                            boardVO.attachList && boardVO.attachList.length > 0 && (
                                <ul className="attach-list">
                                    {
                                        boardVO.attachList.map(value => {
                                            return (
                                                <li key={value.fidx}>
                                                    <a href="#" onClick={e => downloadFile(e, value)}>
                                                    <span className="path">{value.filename}</span>
                                                    </a>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            )
                        }

                        <div className="text-area">
                            <M_SummernoteIframe mode={"view"} htmlCode={boardVO.contentHtml}/>
                        </div>
                        {
                            isGalleryOrClassic() && (
                                <div className="like-button">
                                    <a href="#" className={isRecommend ? "btn-like active" : "btn-like"}
                                       onClick={e => doRecommend(e, !isRecommend)}>
                                        <div>
                                            <i className="ico-like"></i>
                                            <span
                                                className={isRecommend ? "count" : "count deactivate"}>{boardVO.likeCount}</span>
                                        </div>
                                    </a>
                                </div>
                            )
                        }
                    </div>
                </div>
            </section>
            {
                boardInfo.isComment && (<M_BoardDetailComment/>)
            }
        </main>
    )
}

export default M_MypageBoardDetail
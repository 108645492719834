import React, {useEffect, useState} from "react";
import JobSkillResultRadarChart from "../../mypage/jobskill/result/JobSkillResultRadarChart";
import JobSkillResultRankItem from "../../mypage/jobskill/result/JobSkillResultRankItem";
import {CodeVO, DefaultCodeVO} from "../../../../core/common/code.vo";
import {JobSkillReport} from "../../../../core/jobskill/jobskill.vo";
import JobSkillService from "../../../../core/jobskill/jobskill.service";
import {Link, useNavigate} from "react-router-dom";
import MemberUtils from "../../../../common/utils/member.utils";
import ElandUtils from "../../../../common/utils/eland.utils";

const DashboardJobSkillResult = () => {
    const navigate = useNavigate();

    const [skillCodeList, setSkillCodeList] = useState<CodeVO[]>([])
    const [resultReport, setResultReport] = useState<JobSkillReport>({
        currentAvg: 0,
        jobCode: DefaultCodeVO(),
        parentCode: DefaultCodeVO(),
        lastAvg: 0,
        list: [],
        totalCount: 0,
    })

    useEffect(() => {
        const getSkillCodeList = async () => {
            setSkillCodeList(await ElandUtils.getJobSkillCodeList(true));
        }

        const successHandler = (result: any) => {
            setResultReport(result.data)
        }

        if (MemberUtils.isCompleteJobSkill()){
            getSkillCodeList()
            JobSkillService.jobSkillResultGet(successHandler)
        }
    }, []);


    const goJobSkill = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        window.location.href = "/mypage/jobskill"
    }

    return (
        <div className="box-item box6">
            <div className="box-title">
                <h3 className="tit">직무역량진단</h3>
                <p className="desc">
                    {
                        MemberUtils.isCompleteJobSkill() && (
                            <>
                                나의 직무 : <span>{resultReport.parentCode.codeName} - {resultReport.jobCode.codeName}</span>
                            </>
                        )
                    }
                </p>
            </div>
            <div className="box-content">

                {
                    MemberUtils.isCompleteJobSkill() ? (
                        <div className="chart-wrap">
                            <JobSkillResultRadarChart resultReport={resultReport.list} skillCodeList={skillCodeList}/>
                            <div className="rank-wrap">
                                <ul className="rank-list">
                                    {
                                        resultReport.list
                                        .filter((value, index) => index < 4)
                                        .map(val => {
                                            const codeName = skillCodeList.find(code=>code.code === val.skillCode)?.codeName ?? "";
                                            return <JobSkillResultRankItem key={val.skillCode}
                                                                           rank={val.rank}
                                                                           totalCount={val.totalCount}
                                                                           skillName={codeName}
                                                                           linkValid={true}
                                                                           score={val.score}/>
                                        })
                                    }
                                </ul>
                                <a href='#' onClick={goJobSkill} className="link-result">직무역량진단결과 보러가기</a>
                            </div>
                        </div>

                    ) : (

                        <div className="content-empty">
                            <div>
                                <p>
                                    직무역량진단을 통해 나에게 필요한 역량을 확인해보세요<br/>
                                    진단 완료 시 1,000P가 지급됩니다!
                                </p>
                                <button type="button" className="btn red sm"
                                        onClick={goJobSkill}>
                                    진단하러가기
                                </button>
                            </div>
                        </div>
                    )
                }


            </div>
        </div>
    )
}

export default DashboardJobSkillResult
import React, {useEffect, useState} from "react";
import {BoardInfoVO} from "../../../../core/board/BoardInfo.vo";

const BasicHeader: React.FC<{ customHeaders?: String[], boardInfo?: BoardInfoVO }> = ({ customHeaders, boardInfo }) => {

    // 기본 헤더를 state로 관리
    const [defaultHeaders, setDefaultHeaders] = useState(["법인", "직무", "제목", "작성자", "작성일", "조회수"]);

    useEffect(() => {
        if (boardInfo && !boardInfo.isTarget) {
            setDefaultHeaders(["법인", "제목", "작성자", "작성일", "조회수"]);
        }
    }, [boardInfo]);

    // 만약 customHeaders가 있으면 사용, 아니면 기본 헤더 사용
    const headers = customHeaders || defaultHeaders;

    return (
        <thead>
            <tr>
                {headers.map((header, index) => (
                        <th key={index}>{header}</th>
                    ))}
            </tr>
        </thead>
    );
};


export default BasicHeader;
import React, {useEffect, useState} from "react";
import {CodeVO} from "../../../../../../core/common/code.vo";
import moment from "moment";
import {DefaultReviewVO, ReviewVO} from "../../../../../../core/review/ReviewVO";
import {HWMap} from "../../../../../../core/common/common.vo";
import {PageUtils} from "../../../../../../common/utils/page.utils";
import {M_ReviewType} from "../../../../review/M_ReviewType";
import ReviewService from "../../../../../../core/review/review.service";
import CommonJS from "../../../../../../common/common";
import M_EduTalkReviewUpdate from "../../../../review/M_EduTalkReviewUpdate";
import StringUtils from "../../../../../../common/utils/string.utils";
import M_BottomButton from "../../../../common/M_BottomButton";

interface ProEduTalkReviewProps {
    bidx: string,
    targetId : string,
    isReceiver: boolean
}

const M_ProEduTalkReview = (props:ProEduTalkReviewProps) => {
    const {bidx, targetId, isReceiver} = props;
    const [reviewVO, setReviewVO] = useState<ReviewVO>(DefaultReviewVO())
    const [isUpdate, setIsUpdate] = useState<boolean>(false)

    useEffect(() => {
        getReviewList()
        setTimeout(()=>CommonJS.accordion())
    }, []);

    const getReviewList = async () => {
        if (bidx && targetId) {
            let params: HWMap = {...new PageUtils()}
            params.bidx = bidx
            params.targetId = targetId;
            params.type = M_ReviewType.EDU_TALK;

            const result = await ReviewService.getReviewList(params);
            if (result.status === 'SUCCESS') {
                if (result.list.length > 0){
                    setReviewVO(result.list[0].review)
                }
            }
        }
    }

    const updateReview = () => {
        getReviewList()
        setIsUpdate(false)
    }


    const getAccordionClass = () => {
        if (isReceiver) {
            setTimeout(()=>CommonJS.accordion())
            return "accordion border-10 active"
        }

        return "accordion-none active"
    }

    function renderBadgesFromCodeVOList(codeVOList:[CodeVO]) {
        if (codeVOList == null) {
            return null;
        }

        return codeVOList.map((codeVO, index) => getBadgeTag(codeVO, index));
    }

    // 키워드 가져오기
    function getBadgeTag(codeVO: CodeVO, index: number) {
        // 뒤에서문자 추출
        const secondToLastChar = codeVO.code.charAt(5);

        // 숫자로 변환
        const number = parseInt(secondToLastChar, 10);
        let className = "badge type1 "

        if (number % 10 === 0 || number % 9 === 0) {
            className += "red"
        } else if (number % 8 === 0 || number % 7 === 0) {
            className += "blue"
        } else if (number % 6 === 0 || number % 5 === 0) {
            className += "orange"
        } else if (number % 4 === 0 || number % 3 === 0) {
            className += "green"
        } else {
            className += "purple"
        }

        return <span key={index} className={className}>{codeVO.codeName}</span>;
    }

    if (reviewVO.ridx === 0 && isReceiver){
        return (
            <div className={getAccordionClass()}>
                <div className="inner">
                    <h3 className="tit-accordion">한줄평</h3>
                    <div className="accordion-content">
                        <div className="comment type2">
                            <div className="empty-text" style={{padding: "52px 0"}}>
                                한줄평을 작성하지 않았습니다
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return !(isUpdate || (reviewVO.ridx === 0 && !isReceiver)) ?
        (
            <div className={getAccordionClass()}>
                <div className="inner">
                    <h3 className="tit-accordion">한줄평</h3>
                    <div className="accordion-content">
                        <div className="comment type2">
                            <div className="oneline-comment">
                                <div className="cate">
                                    {reviewVO && renderBadgesFromCodeVOList(reviewVO?.codeVOList)}
                                </div>
                                <p className="text-area"
                                   dangerouslySetInnerHTML={{__html: StringUtils.nl2br(reviewVO.title)}}></p>
                                <span className="date">
                                    {moment(reviewVO.createDate).format("YYYY.MM.DD")}
                                </span>
                                {
                                    !isReceiver && (
                                        <div className="util">
                                            <a href="#"
                                               className="btn-modify" onClick={e => {
                                                e.preventDefault()
                                                setIsUpdate(true)
                                            }}><span className="blind">수정</span></a>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
        :
        <></>

        // (<M_EduTalkReviewUpdate bidx={parseInt(bidx)} targetId={targetId} reviewVO={reviewVO} updateHandler={updateReview}/>)
}

export default M_ProEduTalkReview
import axiosCaller from "../../common/utils/axios.caller";
import APIs from "../../common/apis/APIs";


class FileService {

	
	static getFileList = ( target:string, _fidxs:string ) => {
		return axiosCaller.get(`${APIs.FILES}/${target}/getFileList`, {
			fidxs : _fidxs
		})
	}

	
	static deleteFiles = ( target:string, _fidxs:string ) => {
		return axiosCaller.delete(`${APIs.FILES}/${target}/deleteFiles`, {
			fidxs: _fidxs
		});
	}
	
	
	/**
	 * 파일명으로 해상도 목록 조회
	 * @param _filename
	 */
	static getVideoList = async ( _filename :string ) => {
		const result = await axiosCaller.getHide(`${APIs.FILES}/video/getVideoList4Name/${_filename}`)
		if ( result.status === "SUCCESS" ) {
			return result.data
		}
	}


}

export default FileService
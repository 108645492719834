class CommonMobileJS {

    static all() {
        this.floatSearch();
        this.floatingArea();
        this.accordion();
        this.tabmenu();
        this.inputReset();
        this.searchFilter();
        this.textarea();
        this.inputFile();
        this.tooltip();
        this.hpPlayer();
        this.starDrag();
        this.sweetModal();
        this.mediaModal();
    }

    static floatSearch() {
        try {
            var searchArea = $(".search-area");
            var lastScroll = 0;

            $(window).on("scroll", function () {
                var scrollTop = $(this).scrollTop();

                if (scrollTop > lastScroll) {
                    searchArea.addClass("fixed");
                } else {
                    searchArea.removeClass("fixed");
                }

                lastScroll = scrollTop;
            });
        } catch (e) {
            console.error('floatSearch error:', e);
        }
    }

    static inputReset() {
        try {
            var searchForm = $(".search-form");
            var deleteInp = searchForm.find(".inp-search");
            var deleteBtn = searchForm.find(".btn-delete");

            deleteInp.on("focus", function () {
                deleteBtn.show();
            });
            deleteBtn.on("click", function () {
                deleteInp.val("");
                $(this).hide();
            });
        } catch (e) {
            console.error('inputReset error:', e);
        }
    }



    static floatingArea() {
        try {
            var floatArea = $(".floating-area");
            var height = $(floatArea).outerHeight(true);

            if ($(".content").children().is(floatArea)) {
                $(".wrap").css("padding-bottom", height);
            }
        } catch (e) {}
    }

    static accordion() {
        try {
            var accordion = $(".accordion");
            var accordionTit = accordion.find(".tit-accordion");
            var accordionCont = accordion.find(".accordion-content");

            accordionTit.on("click", function () {
                if ($(this).parent().hasClass("active")) {
                    $(this).parent().toggleClass("active").find(accordionCont).slideToggle("fast");
                } else {
                    $(this).parent().toggleClass("active").find(accordionCont).slideToggle("fast");
                }
            });
        } catch (e) {}
    }

    static tabmenu() {
        try {
            var tabs = $(".tabs");

            tabs.each(function () {
                var tabMenu = $(this).find(".tab-item");
                var tabCont = $(this).find(".tab-panel");

                tabMenu.on("click", function () {
                    var idx = tabMenu.index(this);
                    tabCont.hide().eq(idx).show();
                    tabMenu.removeClass("active").eq(idx).addClass("active");
                });
                tabMenu.eq(0).click();
            });
        } catch (e) {}
    }

    static knowledgeTabMenu() {
        $(".tab-area .tab-slide").each(function (index) {
            const $container = $(this);

            $container.addClass(`tab_swiper_${index}`);

            const swiper = new Swiper(`.tab_swiper_${index}`, {
                slidesPerView: "auto",
                preventClicks: false,
                preventClicksPropagation: false,
                observer: true,
                observeParents: true
            });

            $container.on('click', '.swiper-slide a', function (e) {
                const $item = $(this).parent();

                $container.find('.swiper-slide').removeClass('active');
                $item.addClass('active');

                centerTabItem($item);

                // 기본 링크 이동 동작이 실행되도록 e.preventDefault() 제거
                // e.preventDefault();
            });

            const $activeItem = $container.find('.swiper-slide.active');
            if ($activeItem.length > 0) {
                centerTabItem($activeItem);
            }

            function centerTabItem($target) {
                const $wrapper = $container.find('.swiper-wrapper');
                const targetPos = $target.position();
                const containerWidth = $container.width();
                let newPosition = 0;
                let listWidth = 0;

                $wrapper.find('.swiper-slide').each(function () {
                    listWidth += $(this).outerWidth();
                });

                const selectTargetPos = targetPos.left + $target.outerWidth() / 2;
                if (containerWidth < listWidth) {
                    if (selectTargetPos <= containerWidth / 2) {
                        newPosition = 0;
                    } else if ((listWidth - selectTargetPos) <= containerWidth / 2) {
                        newPosition = listWidth - containerWidth;
                    } else {
                        newPosition = selectTargetPos - containerWidth / 2;
                    }
                }

                setTimeout(function () {
                    $wrapper.css({
                        "transform": `translate3d(${-newPosition}px, 0, 0)`,
                        "transition-duration": "500ms"
                    });
                }, 200);
            }
        });
    }




    static searchFilter() {
        try {
            var searchFilter = $(".search-filter");
            var filterOpen = $(".search-area .btn-filter");
            var filterClose = $(".search-filter .btn-back");

            filterOpen.on("click", function () {
                searchFilter.stop().fadeIn(200);
            });
            filterClose.on("click", function () {
                searchFilter.stop().fadeOut(200);
            });
        } catch (e) {}
    }

    static openLayer() {
        try {
            var searchArea = $(".search-area");
            var filterOpen = searchArea.find(".layer-open");
            var filterClose = searchArea.find(".layer-close");
            var filterLayer = searchArea.find(".layer");

            filterOpen.on("click", function () {
                filterLayer.stop().fadeIn(200);
            });
            filterClose.on("click", function () {
                filterLayer.stop().fadeOut(200);
            });
        } catch (e) {}
    }

    static textarea() {
        try {
            var textarea = $(".inp-bind textarea");
            textarea.on("click keyup", function () {
                $(this).height(this.scrollHeight - 15);
            });
        } catch (e) {}
    }

    static inputFile() {
        try {
            var uploadeWrap = $(".file-upload");
            var uploadBtn = uploadeWrap.find(".btn-upload");
            var uploadPath = uploadeWrap.find(".inp-upload");
            var fileInp = uploadeWrap.find(".inp-file");

            uploadBtn.on("click", function () {
                $(this).siblings(fileInp).click();

                fileInp.on("change", function () {
                    var filePath = $(this).val();
                    $(this).siblings(uploadPath).val(filePath);
                });
            });
        } catch (e) {}
    }

    static tooltip() {
        try {
            var tooltipWrap = $(".tooltip");
            var tooltipBtn = tooltipWrap.find(".btn-tooltip");
            var tooltipClose = tooltipWrap.find(".btn-close");

            tooltipBtn.on("click", function () {
                $(this).parent().toggleClass("active");
            });
            tooltipClose.on("click", function () {
                tooltipWrap.removeClass("active");
            });
        } catch (e) {}
    }

    static accordion() {
        try {
            var accordion = $(".accordion");
            var accordionTit = accordion.find(".tit-accordion");
            var accordionCont = accordion.find(".accordion-content");

            accordionTit.on("click", function () {
                if ($(this).parent().hasClass("active")) {
                    $(this).parent().toggleClass("active").find(accordionCont).slideToggle("fast");
                } else {
                    $(this).parent().toggleClass("active").find(accordionCont).slideToggle("fast");
                }
            });
        } catch (e) {}
    }

    static hpPlayer() {
        try {
            var hpPlayer = $(".hp-player");
            var controlBar = hpPlayer.find(".hp-control-bar");
            var progressBar = hpPlayer.find(".hp-progress-bar");
            var progressInp = progressBar.find(".inp-range");

            var dropdownWrap = $(".hp-dropdown");
            var dropdownBtn = dropdownWrap.find(".btn-dropdown");

            // 타이머 ID를 저장할 변수
            var fadeOutTimer;

            hpPlayer.on("touchstart", function () {
                // 컨트롤바가 보이지 않는 상태라면 fadeIn 후 4초 뒤 fadeOut
                if (controlBar.css("display") === "none") {
                    controlBar.stop().fadeIn(100);
                }

                // 이전 타이머가 설정되어 있다면 클리어
                if (fadeOutTimer) {
                    clearTimeout(fadeOutTimer);
                }
                // 새로운 fadeOut 타이머 설정
                fadeOutTimer = setTimeout(function () {
                    controlBar.stop().fadeOut();
                }, 3000);
            });

            dropdownBtn.click(function () {
                if (!$(this).parent(dropdownWrap).hasClass("active")) {
                    dropdownWrap.removeClass("active");
                    $(this).parent(dropdownWrap).toggleClass("active");
                } else {
                    $(this).parent(dropdownWrap).toggleClass("active");
                }

                $(document).mouseup(function (e) {
                    if (dropdownWrap.has(e.target).length === 0) {
                        dropdownWrap.removeClass("active");
                    }
                });
            });

            progressInp.bind('input touchmove', function() {
                var val = $(this).val();
                var maxVal = parseInt($(this).attr('max')); // max 속성 값을 정수로 파싱

                // 버퍼값 계산
                var bufferPercent = val / maxVal * 100;

                // linear-gradient 스타일 적용
                $(this).css(
                    'background',
                    'linear-gradient(to right, #e30019 0%, #e30019 ' + bufferPercent + '%, #888 ' + bufferPercent + '%, #888 ' + bufferPercent + '%, #444 ' + bufferPercent + '%, #444 100%)'
                );
            });

        } catch (e) {}
    }

    static starDrag() {
        try {
            var star = $(".star-drag > button");

            star.off("click");

            star.on("click", function() {
                $(this).parent().children("button").removeClass("check");
                $(this).addClass("check").prevAll("button").addClass("check");
            });
        } catch (e) {}
    }

    static sweetModal() {
        try {
            var modal = $(".sweet-modal");
            var modalInner = modal.find(".modal-inner");
            var modalBtn = $(".btn-modal");
            var modalCancelBtn = $(".btn-cancel");

            modalBtn.off("click")
            modalInner.off("click")
            modalCancelBtn.off("click")

            modalBtn.on("click", function () {
                var modal = $(this).data("id");
                $("#" + modal).show();
            });

            modalInner.on("click", function (e) {
                if (modalInner.has(e.target).length === 0) {
                    modal.hide();
                }
            });

            modalCancelBtn.on("click", function (e) {
                if (modalCancelBtn.has(e.target).length === 0) {
                    modal.hide();
                }
            });
        } catch (e) {}
    }

    static mediaModal() {
        try {
            var modalOpen = $(".media-content .btn-media");
            var modal = $(".media-modal");
            var modalClose = modal.find(".btn-close");

            modal.hide();

            // 기존 이벤트를 삭제하는 부분
            modalOpen.off("click");
            modalClose.off("click");

            modalOpen.on("click", function () {
                modal.fadeIn();
            });

            modalClose.on("click", function () {
                modal.fadeOut();
            });
        } catch (e) {}
    }

    static typeChange() {
        try {
            var btnChange = $(".btn-change");
            var inpPassword = $(".inp-password");

            btnChange.on("click", function () {
                if ($(this).parent().is(".show")) {
                    inpPassword
                        .attr("type", "password")
                        .parent()
                        .removeClass("show");
                } else {
                    inpPassword.attr("type", "text").parent().addClass("show");
                }
            });
        } catch (e) {}
    }
}

export default CommonMobileJS
import React, {useEffect, useRef, useState} from "react";
import {BannerVO} from "../../../core/main/main.banner.vo";
import MainService from "../../../core/main/main.service";
import M_MainBannerItem from "./M_MainBannerItem";
import type { Swiper as SwiperType } from 'swiper';
import SwiperInit from 'swiper';
import {Autoplay, Pagination} from 'swiper/modules'

const M_MainBanner = () => {
    /**
     * 메인 배너 리스트
     */
    const [bannerList, setBannerList] = useState<Array<BannerVO>>();

    const swiperRef = useRef<SwiperType | null>(null);

    const initSwiper = () => {
        swiperRef.current?.destroy(true, true);

        if (bannerList && bannerList?.length > 0){
            SwiperInit.use([Autoplay, Pagination])
            swiperRef.current = new SwiperInit(".visual-section .inner", {
                loop: true,
                slidesPerView: 1,
                spaceBetween: 24, // 간격

                autoplay: {
                    delay: 5000, // 5초마다 슬라이드 전환
                    disableOnInteraction: false, // 사용자 상호작용 후에도 자동 재생 지속
                },
                pagination: {
                    el: ".swiper-pagination",
                    type: 'bullets',
                    clickable: true,
                },
            });
        }
    }

    // Banner 정보 조회
    const getMainBannerList = () => {
        const successHandler = (result: any)=> {
            const bannerList = result.bannerList;
            setBannerList(bannerList);
        }

        MainService.getMainBannerList({}, successHandler)
    }

    useEffect(() => {
        getMainBannerList()
    }, []);

    useEffect(() => {
        initSwiper()
    }, [bannerList])

    return (
        <div className="visual-section">
            <div className="inner">
                <ul className="visual-list swiper-wrapper">
                    {bannerList && bannerList.map((item, index) => (
                        <M_MainBannerItem key={index} item={item}></M_MainBannerItem>
                    ))}
                </ul>
                <div className="swiper-pagination"></div>
            </div>
        </div>
    );
}

export default M_MainBanner;
import React from "react";
import ProRecommendCard from "../pro/find/ProRecommendCard";
import {useEffect, useState} from "react";
import {ProReqVO} from "../../../core/pro/pro.vo";
import proService from "../../../core/pro/pro.service";
import Swiper from "../../../assets/js/swiper.min";

const MainProRecommend = () => {
    const [swiperVal, setSwiperVal] = useState<any>(null);
    const [recommendList, setRecommendList] = useState<ProReqVO[]>([]);

    useEffect(() => {
        const successHandler = (result: any) => {
            setRecommendList(result.data);
        }

        proService.proRecommendListGet({size:8},successHandler)
    }, []);

    const initSwiper = () =>{
        if (swiperVal){
            swiperVal.destroy()
            setSwiperVal(null)
        }

        const newSwiperVal = new Swiper("#main-pro-recommend", {
            slidesPerView: "auto",
            spaceBetween: 30,
            navigation: {
                nextEl: "#main-pro-recommend-next",
                prevEl: "#main-pro-recommend-prev",
            },
        });

        setSwiperVal(newSwiperVal)
    }

    useEffect(() => {
        if (recommendList.length > 0) {
            initSwiper();
        }
    }, [recommendList]);

    return(
        <div className="board-list">
            <div className="sub-title">
                <h3 className="tit">추천 사내고수</h3>
            </div>
            <div id="main-pro-recommend" className="slide-wrap">
                <ul className="slide-list swiper-wrapper card-list type3">
                    {
                        recommendList.map((value, index) => {
                            return (
                                <ProRecommendCard key={index} {...value}></ProRecommendCard>
                            )
                        })
                    }

                </ul>
            </div>
            <div className="slide-button" style={{ top: 5 }}>
                <div id="main-pro-recommend-prev" className="btn-prev" tabIndex={0} role="button" aria-label="Previous slide"></div>
                <div id="main-pro-recommend-next" className="btn-next" tabIndex={0} role="button" aria-label="Next slide"></div>
            </div>
        </div>
    )
}

export default MainProRecommend

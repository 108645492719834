import React, {useEffect, useRef, useState} from "react";
import M_Pagination from "../../common/M_Pagination";
import {useParams} from "react-router-dom";
import {BoardVO, CommentVO} from "../../../../core/board/board.vo";
import CommentService from "../../../../core/board/comment.service";
import M_BoardDetailCommentRow from "./M_BoardDetailCommentRow";
import MypageService from "../../../../core/mypage/mypage.service";
import ToastUtils from "../../../../common/utils/toast.utils";
import StringUtils from "../../../../common/utils/string.utils";
import M_BottomButton from "../../common/M_BottomButton";
import SwalUtils from "../../../../common/utils/swal.utils";
import M_InfinityScroll from "../../common/M_InfinityScroll";
import {HWMap} from "../../../../core/common/common.vo";
import axiosCaller from "../../../../common/utils/axios.caller";
import APIs from "../../../../common/apis/APIs";

const M_BoardDetailComment = () => {
    const commentSize = 10
    const {bidx} = useParams()
    const [commentList, setCommentList] = useState<CommentVO[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<number>(0)
    const scrollRef = useRef<{ reload: () => void,refetchData: () => void, saveScroll:(index:number|null)=>void }>(null);


    const updateEvent = () => {
        scrollRef.current && scrollRef.current.refetchData()
    }

    const fetchList = async (makeParamFunc:(params:HWMap, size:number) => HWMap) => {
        if(bidx){
            let params = makeParamFunc({page:currentPage}, commentSize)
            let result = await axiosCaller.get(APIs.BOARD_COMMENTS + '/page/' + bidx, params)
            if (result.status === "SUCCESS"){
                setTotalCount(result.data.count)
                return result.data.list
            }
        }

        return []
    }

    const showNewPopup = () => {
        SwalUtils.textarea({
            title:"댓글",
            message:"",
            isIcon:false,
            inputPlaceholder:"댓글을 입력해주세요",
            isCancel:true,
            confirmOptions:{
                text: "등록",
                eventHandler: saveNewComment
            },
            cancelOptions:{
                text: "취소"
            }
        })
    }

    const saveNewComment = (newComment:string) => {
        if (!bidx) {
            return
        }

        if (StringUtils.isEmpty(newComment)){
            ToastUtils.show('내용을 입력해주세요.')
            return
        }

        const formData = new FormData();
        formData.append('cidx', '0')
        formData.append('bidx', bidx)
        formData.append('pidx', '0')
        formData.append('contentText', newComment)

        const successHandler = () => {
            ToastUtils.show('저장되었습니다.')
            updateEvent()
        }

        MypageService.commentSaveOrUpdate(formData, successHandler)
    }

    return (
        <>
            <section className="comment-section">
                <div className="inner">
                    <div className="comment type1">
                        <div className="comment-title">
                            <h3 className="tit">댓글</h3>
                            <span className="num">{totalCount}개</span>
                        </div>

                        {
                            commentList.length <= 0 ? (
                                <div className="comment-empty">
                                    <p className="txt">아직 작성된 댓글이 없습니다.<br/>제일 먼저 댓글을 작성해주시겠어요?</p>
                                </div>
                            ) : (
                                <ul className="comment-list">
                                    {
                                        commentList.map(val => <M_BoardDetailCommentRow key={val.cidx}
                                                                                        comment={val}
                                                                                        updateEvent={updateEvent}/>)
                                    }
                                </ul>
                            )
                        }
                        <M_InfinityScroll<CommentVO>
                            queryKey={"helpBoadList"}
                            update={fetchList}
                            defaultSize={commentSize}
                            totalCount={totalCount}
                            getList={val=>{setCommentList(val)}}
                            scrollRef={scrollRef} />
                    </div>
                </div>
            </section>
            <M_BottomButton name={"댓글 남기기"} onClick={showNewPopup}/>
        </>
    )
}

export default M_BoardDetailComment
import axiosCaller from "../../common/utils/axios.caller";
import APIs from "../../common/apis/APIs";
import {HWMap} from "../common/common.vo";
import {AnsMovVO, AnsPollVO, AnsTstVO, EduMainVO} from "./education.vo";
import {useNavigate, useParams} from "react-router-dom";
import MemberUtils from "../../common/utils/member.utils";
import moment from "moment/moment";


class EducationService {
	
	
	static goNextStep = () => {

		let { emIdxText, stepText, eduTitle } = useParams();
		const navigate = useNavigate()
		navigate(`/education/${emIdxText}/${parseInt(stepText!) + 1}/${eduTitle}`)
	}

	static goPrevStep = () => {

		let { emIdxText, stepText, eduTitle } = useParams();
		const navigate = useNavigate()
		navigate(`/education/${emIdxText}/${parseInt(stepText!) + 1}/${eduTitle}`)
	}
	
	
	
	
	
	/**
	 * 교육일정 텍스트 변환
	 * @param _eduMain
	 */
	static getScheduleText = ( _eduMain : EduMainVO ) => {
		
		const result:HWMap = {
			startDateTxt: "",
			endDateTxt: "",
		}
		
		switch (_eduMain.scheduleMode) {
			case "fix":
				result.startDateTxt = _eduMain.startDate.replace(/-/gi, ".");
				result.endDateTxt = _eduMain.endDate.replace(/-/gi, ".");
				break;
			case "month":
				result.startDateTxt = "매년 " + (moment(_eduMain.startDate).month() + 1) + "월";
				result.endDateTxt = "매년 " + (moment(_eduMain.endDate).month() + 1) + "월";
				break;
			case "day":
				result.startDateTxt = "매월 " + moment(_eduMain.startDate).day() + "일";
				result.endDateTxt = "매월 " + moment(_eduMain.endDate).day() + "일";
				break;
		}
		
		return result;
	}
	
	
	
	
	

	/**
	 * 교육 목록 조회
	 * 이랜드 특화 : 필수, 지정, 자율
	 * @param _params
	 */
	static getEduMainList = (_params:HWMap) => (
		axiosCaller.get(APIs.EDU_MAIN + "/getEduMainList.v2", _params)
	)


	/**
	 * 교육 상세 조회
	 * @param _emIdx
	 */
	static getEduMainOne = async ( _emIdx:number ) => (
		await axiosCaller.get(APIs.EDU_MAIN + "/getEduMain", {
			emIdx : _emIdx
		})
	)


	/**
	 * 영상 옵션 조회
	 * @param _ecIdx
	 */
	static getMovOption = async (_ecIdx:number) => {

		const result = await axiosCaller.getHide(APIs.EDU_CONTENT + "/getMovOption", {
			ecIdx: _ecIdx
		})

		return result.data
	}


	/**
	 * 카드뉴스 파일 조회
	 * @param _ecIdx
	 */
	static getCardFiles = async (_ecIdx:number) => {

		const result = await axiosCaller.get(APIs.EDU_CONTENT + "/getCardFiles", {
			ecIdx: _ecIdx
		})
		if ( result.cardFileList != null ) {
			return result.cardFileList
		}

		return null
	}


	/**
	 * 테스트 답변 저장
	 * @param _ecIdx
	 * @param _ansTstList
	 */
	static setAnsTstList = async ( _ecIdx:number, _ansTstList:AnsTstVO[], _isSubmission:boolean ) => {
		const result = await axiosCaller.put(APIs.EDU_ANSWER + "/tstAns.v2", {
			ecIdx : _ecIdx,
			answerList : JSON.stringify(_ansTstList),
			isSubmission : _isSubmission
		})
		return result;
	}


	/**
	 * 테스트 답변데이터 초기화
	 * @param _eaIdx
	 */
	static resetAnsTst = async ( _eaIdx:number ) => {
		return await axiosCaller.delete(APIs.EDU_ANSWER + "/tstAns/reset", {
			eaIdx: _eaIdx
		});
	}





	/**
	 * 답변 데이터의 상태 변경
	 * @param params
	 */
	static setAnswerStatus = async (params:HWMap) => {
		return await axiosCaller.patch(APIs.EDU_ANSWER + "/", params)
	}





	/**
	 * 답변 데이터의 상태 변경
	 * @param _emIdx
	 * @param params
	 */
	static setStudentStatus = async (_mode:string, _emIdx:number, params:HWMap) => {
		return axiosCaller.patch(`${APIs.EDU_STUDENT}/${_mode}/${_emIdx}`, params);
	}





	/**
	 * 교육 컨텐츠 상세 조회
	 * ecIdx 로 상세 조회
	 * 추후 이걸로 통일
	 * @param params
	 */
	static getContentDetail = (params:HWMap) => {
		return axiosCaller.get(APIs.EDU_CONTENT + "/getContentDetails", params)
	}
	
	
	
	
	
	
	/**
	 * 설문과제 저장
	 * @param _ecIdx
	 * @param _ansPollList
	 * @param _isSubmission
	 */
	static setAnsPollList = async ( _ecIdx:number, _ansPollList:AnsPollVO[], _isSubmission:boolean ) => {
		const result = await axiosCaller.putHide(APIs.EDU_ANSWER + "/pollAns", {
			ecIdx : _ecIdx,
			answerList : JSON.stringify(_ansPollList),
			isSubmission : _isSubmission
		})
		return result;
	}
	
	
	
	
	/**
	 * 영상 답변 저장
	 * 현재 플레이 시간 저장
	 * 플레이 완료시 초기화
	 * @param ansMov
	 */
	static setAnsMov = ( ansMov:AnsMovVO ) => {
		return axiosCaller.patchHide(APIs.EDU_ANSWER + "/mov", ansMov);
	}
	
	
	
	
	/**
	 * 답변 기록 저장
	 * @param _ecIdx
	 * @param _recordMode
	 * @param _contentType : poll tst file mov
	 */
	static setAnsRecord = (_ecIdx:number, _recordMode:string, _contentType:string ) => {
		void axiosCaller.putHide(APIs.EDU_ANSWER + "/record", {
			ecIdx: _ecIdx,
			userId: MemberUtils.getSessionVO()?.coEmailId,
			recordMode: _recordMode,
			contentType: _contentType
		})
	}


	/**
	 * 대시보드 수강정보 조회
	 * @param params
	 */
	static getMyCourseInfo4DashBoard = (params:HWMap) => {
		return axiosCaller.get(APIs.EDU_MINE + "/getMyCourseInfo4DashBoard", params)
	}

	/**
	 * 대시보드 과거 수강정보 조회
	 * @param params
	 */
	static getMyCourseOldInfo4DashBoard = (params:HWMap) => {
		return axiosCaller.get(APIs.EDU_MINE + "/getMyCourseOldInfo4DashBoard", params)
	}
	
	


}


export default EducationService
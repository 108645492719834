import React from "react";
import MemberUtils from "../../../common/utils/member.utils";

interface MainProRecommendEmptyProps { }

const MainProRecommendEmpty = (props: MainProRecommendEmptyProps) => {
    return (
        <section className="recommend-section">
            <div className="inner">
                <div className="section-title">
                    <h2 className="tit"><strong>{ MemberUtils.getSessionVO()?.userName }</strong> 님 역량 향상을 위한 추천!</h2>
                </div>
                <div className="board-list">
                    <div className="sub-title">
                        <h3 className="tit">추천 콘텐츠</h3>
                    </div>
                    <div className="content-empty">
                        <p className="txt">아직 직무역량진단을 하지 않아 콘텐츠를 추천할 수 없어요.</p>
                    </div>
                </div>
                <div className="banner">
                    <span className="desc">진단 완료시 <b>1,000P</b> 지급!</span>
                    <strong className="tit">직무역량진단을 통해 나에게 필요한 역량을 찾아보세요</strong>
                    <a href="/mypage/jobskill" className="btn">지금 바로 하러가기</a>
                </div>
            </div>
        </section>
    )
}

export default MainProRecommendEmpty;
import React, {useEffect, useState} from "react";
import ProFindCard from "./ProFindCard";
import {ProReqVO} from "../../../../core/pro/pro.vo";
import {PageParams, PageUtils} from "../../../../common/utils/page.utils";
import JobSkillFilterSelectButton from "../../../pc/common/JobSkillFilterSelectButton";
import Pagination from "../../../pc/common/Pagination";
import CoSelectButton from "../../../pc/common/CoSelectButton";
import SearchInput from "../../../pc/common/SearchInput";
import ProRecommendSlide from "./ProRecommendSlide";
import {useSearchParams} from "react-router-dom";
import proService from "../../../../core/pro/pro.service";
import ProService from "../../../../core/pro/pro.service";

const ProFind = () => {
    const [query, setQuery] = useSearchParams();

    const [recommendList, setRecommendList] = useState<ProReqVO[]>([]);
    const [searchList, setSearchList] = useState<ProReqVO[]>([]);
    const [pageParams, setPageParams] = useState<PageParams>(
        new PageUtils(
            parseInt(query.get('page') ?? "0"),
            parseInt(query.get('size') ?? "9"),
            query.get('sort') ?? "",
            parseInt(query.get('totalCount') ?? "0"),
            query.get("searchText") ?? "",
        )
    );
    const [totalCount, setTotalCount] = useState<number>(0);
    const [filterParams, setFilterParams] = useState(
        {skillCode: query.get("skillCode") ?? "", jobCode: query.get("jobCode") ?? "", coCode: query.get("coCode") ?? ""}
    );


    useEffect(() => {
        const successHandler = (result: any) => {
            setRecommendList(result.data);
        }

        proService.proRecommendListGet({size:9},successHandler)
    }, []);


    useEffect(() => {
        const successHandler = (result:any)=>{
            setSearchList(result.data.list);
            setTotalCount(result.data.totalCount);
        }

        ProService.proListSearch({...pageParams, ...filterParams}, successHandler)
        updateParams()
    }, [pageParams, filterParams]);

    const updateParams = () => {
        query.set("page", pageParams.page.toString())
        query.set("size", pageParams.size.toString())
        query.set("sort", pageParams.sort.toString())
        query.set("searchText", pageParams.searchText)
        query.set("skillCode", filterParams.skillCode)
        query.set("jobCode", filterParams.jobCode)
        query.set("coCode", filterParams.coCode)

        setQuery(query)
    }


    const updateSearchText = (searchText: string) => {
        updatePages(0)
        setPageParams((preParams) => ({
            ...preParams,
            searchText: searchText
        }));
    }

    const updateSort = (sort: string) => {
        setPageParams((preParams) => ({
            ...preParams,
            sort: sort
        }));
    }

    const updatePages = (newPages: number) => {
        setPageParams((preParams) => ({
            ...preParams,
            page: newPages
        }));
    }

    const updateJobCode = (newJobCode: string) => {
        setFilterParams((preParams) => ({
            ...preParams,
            jobCode: newJobCode
        }));
    }

    const updateSkillCode = (newSkillCode: string) => {
        setFilterParams((preParams) => ({
            ...preParams,
            skillCode: newSkillCode
        }));
    }

    const updateCoCode = (newCode: string) => {
        setFilterParams((preParams) => ({
            ...preParams,
            coCode: newCode
        }));
    }


    const doSort = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, sort: string) => {
        e.preventDefault()
        updateSort(sort)
    }

    return (
        <main className="content master-find">
            <div className="common-title">
                <div className="inner">
                    <h2 className="tit">사내고수 찾기</h2>
                </div>
            </div>

            {
                recommendList.length > 0 &&
                (<ProRecommendSlide recommendList={recommendList}></ProRecommendSlide>)
            }

            <section className="list-section">
                <div className="inner">
                    <div className="section-title">
                        <h2 className="tit">고수 검색 </h2>
                        <p className="desc">사내고수를 직접 찾아보고 도움을 요청해보세요</p>
                    </div>
                    <div className="board-util">
                        <div className="util-group">
                            <div className="board-filter">
                                <CoSelectButton updateCoCode={updateCoCode} initCode={filterParams.coCode}></CoSelectButton>
                                <JobSkillFilterSelectButton isSkill={false}
                                                            updateJobCode={updateJobCode}
                                                            updateSkillCode={updateSkillCode}
                                                            initCode={filterParams.jobCode}
                                                            isFilter={false}/>

                                <JobSkillFilterSelectButton isSkill={true}
                                                            updateJobCode={updateJobCode}
                                                            updateSkillCode={updateSkillCode}
                                                            initCode={filterParams.skillCode}
                                                            isFilter={false}/>

                            </div>
                            <div className="board-search">
                                <SearchInput updateSearchText={updateSearchText}
                                             placeholderText={"찾으시는 고수의 이름 혹은 태그를 검색해주세요"}
                                             initText={pageParams.searchText}/>
                            </div>
                        </div>
                        <div className="util-group">
                            <div className="board-total">
                                총 <span>{totalCount}</span> 명의 고수가 있습니다.
                            </div>
                            <div className="board-sort">
                                <div className="sort-list">
                                    <a href="#" className={pageParams.sort === '' ? 'active' : ''}
                                       onClick={(e)=>doSort(e,'')}>최신순</a>
                                    <a href="#"
                                       className={pageParams.sort === 'ca.userName,ASC' ? 'active' : ''}
                                       onClick={(e) => doSort(e,'ca.userName,ASC')}>이름순</a>
                                    <a href="#"
                                       className={pageParams.sort === 'chatCount,DESC' ? 'active' : ''}
                                       onClick={(e) => doSort(e,'chatCount,DESC')}>상담지수순</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="board-list">
                        {
                            searchList.length <= 0 && (
                                <div className="content-empty">
                                    <i className="ico-content"></i>
                                    <p className="txt">검색 결과가 없습니다.<br/>입력된 내용을 다시 한 번 확인해주세요</p>
                                </div>
                            )
                        }

                        {
                            searchList.length > 0 && (
                                <>
                                    <ul className="card-list type4">
                                        {
                                            searchList.map((value, index) => {
                                                return (
                                                    <ProFindCard key={value.proidx} {...value}></ProFindCard>
                                                )
                                            })
                                        }
                                    </ul>
                                    <Pagination page={pageParams.page}
                                                totalCount={totalCount}
                                                updatePages={updatePages}
                                                size={9} />
                                </>
                            )
                        }
                    </div>
                </div>
            </section>
        </main>
    );
}

export default ProFind
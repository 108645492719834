import React, {useState} from "react";
import {BoardVO} from "../../../../../core/board/board.vo";
import moment from "moment/moment";
import {useNavigate} from "react-router-dom";

interface ProEduTalkRowProps {
    boardVO: BoardVO,
    isReceive : boolean
}

const ProEduTalkRow = (props:ProEduTalkRowProps) => {
    const navigate = useNavigate();

    const tabMenuList = [
        {name:'전체', code: "", color: "", btn:""},
        {name: '미해결', code: "041901", color: "red"},
        {name:'진행중', code: "041902", color: "orange"},
        {name:'일지작성', code: "041903", color: "purple"},
        {name:'완료', code: "041904", color: "green"},
        {name:'취소', code: "041905", color: "gray"}
    ]
    const {isReceive} = props
    const [boardVO, setBoardVO] = useState<BoardVO>(props.boardVO)

    const getBadge = (code:string) => {
        const filter = getLabel(code)
        return (<span className={`badge type2 ${filter.color}`}>{filter.name}</span>)
    }

    const getBtn = (code:string) => {
        if (!isReceive) {
            return "확인하기"
        }
        const filter = getLabel(code)

        switch (filter.name){
            case "미해결" :
                return "답변하기"
            case  "일지작성" :
                return  "일지작성"
            default :
                return "확인하기"
        }
    }

    const getLabel = (code:string) => {
        const targetCode = !isReceive && code ==="041903" ? "041904" : code
        const filter = tabMenuList.filter(item => item.code === targetCode);
        return filter.length > 0 ? filter[0] : tabMenuList[0]
    }

    const goDetail = (idx:number) => {
        navigate("/pro/talk/" + idx, {state: {isReceive : isReceive}})
    }

    return (
        <li>
            <div className="text-wrap">
                {
                    boardVO.statusCode && getBadge(boardVO.statusCode)
                }
                <strong className="tit">{boardVO.title}</strong>
                <p className="desc">
                    {boardVO.contentText}
                </p>
                <div className="info-wrap">
                    <span className="author">{isReceive ? boardVO.writer : boardVO.receiver}&nbsp;</span>
                    <span className="date">{moment(boardVO.updateDate).format('YYYY.MM.DD')}</span>
                </div>
            </div>
            <div className="button-wrap">
                <button type="button" className="btn red" onClick={e => {
                    e.preventDefault()
                    goDetail(boardVO.idx)
                }
                }>{boardVO.statusCode && getBtn(boardVO.statusCode)}</button>
            </div>
        </li>
    )
}

export default ProEduTalkRow
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {SessionVO} from "../../../core/common/session.vo";


interface SessionData {
	sessionVO :SessionVO,
	token :string
}


const initialState :SessionData = {
	sessionVO : {} as SessionVO,
	token :""
}

// @ts-ignore
export const sessionSlice = createSlice({
	name :'session',
	initialState,
	reducers :{

		// 세션 정보 저장
		setSession: (state, action: PayloadAction<SessionData>) => {
			state.sessionVO = action.payload.sessionVO;
			state.token = action.payload.token;

			localStorage.setItem("sessionVO", JSON.stringify(state.sessionVO))
			localStorage.setItem("token", state.token)
		},

		// 세션 정보 조회
		getSession: (state: SessionData) => {
			return state;
		},

	}

})


export const { getSession, setSession } = sessionSlice.actions
export default sessionSlice.reducer
import React, {useCallback, useEffect, useRef, useState} from 'react'
import useHeader from "../../../../common/hook/useHeader";
import {HeaderBtn} from "../../../../core/common/header";
import useFooter from "../../../../common/hook/useFooter";
import {HWMap} from "../../../../core/common/common.vo";
import {PageUtils} from "../../../../common/utils/page.utils";
import {AlarmVO} from "../../../../core/alarm/alarm.vo";
import AlarmService from "../../../../core/alarm/alarm.service";
import DateUtils from "../../../../common/utils/date.utils";
import MypageNav from "../../../pc/mypage/common/MypageNav";
import M_InfinityScroll from "../../common/M_InfinityScroll";
import {BoardVO} from "../../../../core/board/board.vo";
import axiosCaller from "../../../../common/utils/axios.caller";
import APIs from "../../../../common/apis/APIs";
import ElandUtils from "../../../../common/utils/eland.utils";

const M_Alarm = () => {
    useHeader({
        type: "content",
        title: "알림함",
        buttons: [HeaderBtn.Back]
    })

    useFooter({
        isHidden: true
    })

    const [params, setParams] = useState<HWMap>({
        ...new PageUtils(),
        page: 0,
        size: 20,
        sort: "createDate,DESC"
    })
    const [alarmList, setAlarmList] = useState<AlarmVO[]>([])
    const [totalCount, setTotalCount] = useState(0);
    const scrollRef = useRef<{ reload: () => void, saveScroll:(index:number|null)=>void }>(null);

    /**
     * 알림 선택 했을 떄
     * @param item
     */
    const  selectItem = async (item: AlarmVO) => {
        // 알림 읽음 처리
        params.seq = `${item.messageVO?.seq}`
        console.log("params : ", params)
        const result = await AlarmService.setIsRead(params);
        if (result.status === 'SUCCESS') {
            // 알림 읽음 상태 업데이트
            const updatedAlarmList = alarmList.map(alarmItem => {
                if (alarmItem.messageVO?.seq === item.messageVO?.seq) {
                    if (alarmItem.messageVO) {
                        alarmItem.messageVO.isRead = true
                    }
                }
                return alarmItem;
            });

            setAlarmList(updatedAlarmList); // 상태 업데이트

            // 현재 페이지의 history 상태를 업데이트
            history.replaceState({alarmList: updatedAlarmList}, '');

            // 알림에 대한 분기처리
            if (item.codeVO?.code) {
                AlarmService.handleCodeEvent(item.codeVO?.code, item.messageVO?.bidx)
            }
        }
    }

    const fetchList = async (makeParamFunc:(params:HWMap, size:number) => HWMap) => {
        let fetchParams = makeParamFunc({...params}, params.size)
        console.log("⭐fetchParams", fetchParams);
        let result = await AlarmService.getNotifList(fetchParams)
        if (result.status === "SUCCESS"){
            console.log("alarmList : ", alarmList)
            console.log("result.data.notifList : ", result.data.notifList)
            const list = result.data.notifList
            setTotalCount(result.data.totalCount)
            return list
        }

        return []
    }

    useEffect(() => {
        console.log("alarmList : ", alarmList)
    }, [alarmList]);

    return (
        <main className="content notify">
            <section className="list-section">
                <div className="inner">
                    <div className="board-list">
                        {
                            (alarmList && alarmList.length > 0) ?
                                <ul className="notify-list">
                                    {
                                        alarmList.map((item, i) =>
                                            <li key={i} className={item.messageVO?.isRead ? "read" : ""}>
                                                <a onClick={
                                                    item.codeVO?.code ?
                                                        () => selectItem(item) :
                                                        // TODO: 만약 이동이 있다면 해당 부분 수정
                                                        () => selectItem(item)
                                                }>
                                                    <div className="info">
                                                    <span
                                                        className="cate">{item.codeVO?.code ? AlarmService.convertAlarmTitleName(item.codeVO?.code) : item.messageVO?.title}</span>
                                                        <span
                                                            className="date">{item.messageVO?.createDate && DateUtils.formatTimeForDisplay(item.messageVO?.reserveDT)}</span>
                                                    </div>
                                                    <p className="txt">{item.messageVO?.contentText}</p>
                                                </a>
                                            </li>
                                        )
                                    }
                                </ul>
                                :
                                <div className="content-empty">
                                    <i className="ico-content"></i>
                                    <p className="txt">현재는 알림이 없습니다.<br/>새로운 소식이 있으면 알려드리겠습니다.</p>
                                </div>
                        }
                    </div>

                    {
                        alarmList &&
                        <M_InfinityScroll<AlarmVO>
                            queryKey={"alarmList"}
                            update={fetchList}
                            defaultSize={params.size}
                            totalCount={totalCount}
                            getList={val=>{setAlarmList(val)}}
                            scrollRef={scrollRef}
                        />
                    }
                </div>
            </section>
        </main>
    )
}


export default M_Alarm
import React from "react";
import {BoardVO} from "../../../../core/board/board.vo";
import CardItem from "./CardItem";

const RelatedItem: React.FC<{boardList: BoardVO[]}> = ({boardList}) => {
    return (
        <section className="list-section" style={{marginBottom: "168px"}}>
            <div className="inner">
                <div className="section-title">
                    <h3 className="tit">연관 지식</h3>
                </div>
                <div className="board-list">
                    {
                        boardList.length > 0 ?
                            <ul className="card-list type1">
                                {
                                    boardList.map((item, index) => (
                                        <CardItem key={index} board={item}></CardItem>
                                    ))
                                }
                            </ul>
                            : <div className="board-list type1">
                                <div className="content-empty">
                                    <p className="txt">연관지식을 모두 시청하셨습니다 😀</p>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </section>
    );
}

export default RelatedItem;
import React, {useEffect, useState} from "react";
import moment from "moment/moment";
import {ProReqVO} from "../../../../core/pro/pro.vo";
import ProService from "../../../../core/pro/pro.service";
import {useNavigate} from "react-router-dom";
import FileUtils from "../../../../common/utils/file.utils";
import StringUtils from "../../../../common/utils/string.utils";

interface ProProfileProps {
    proReq: ProReqVO
    isMe: boolean
    isFollow: boolean
}

const M_ProProfile = (props: ProProfileProps) => {
    let navigate = useNavigate();
    const {proReq, isMe} = props;
    const [isFollow, setIsFollow] = useState<boolean>(props.isFollow)

    useEffect(() => {
        setIsFollow(props.isFollow)
    }, [props]);


    const calWorkingYear = (workingYear: number) => {

        if (workingYear < 1980) {
            return ""
        }
        let currentYear = new Date().getFullYear()
        let year: number = currentYear - workingYear + 1
        if (year >= 9) {
            return "시니어(9~)"
        } else if (year >= 5) {
            return "미들(5~8)"
        } else if (year >= 1) {
            return "주니어(1~4)"
        } else {
            return ""
        }
    }

    const onErrorImg = (e: any) => {
        e.target.src = process.env.PUBLIC_URL + '/elandedu/assets/images/common/img_profile.png'
    }

    const getButton = () => {
        const toManagement = () => {
            navigate('/mypage/management')
        }
        return (
            <button type="button"
                    className={isMe || isFollow ? "btn sm gray" : "btn sm blue"}
                    onClick={
                        (e) => {
                            e.preventDefault()
                            isMe ? toManagement() : updateFollow(!isFollow)
                        }}>
                {isMe ? "페이지 관리" : isFollow ? "팔로우 취소" : "팔로우"}
            </button>
        )
    }


    const updateFollow = async (isSave: boolean) => {
        const successHandler = (result: any) => {
            let plus = isFollow ? -1 : 1
            proReq.followerCount = proReq.followerCount + plus
            setIsFollow(isSave)
        }

        let params = {
            proidx: proReq.proidx,
            isSave: isSave,
        }

        await ProService.proFollowUpdate(params, successHandler)
    }

    return (
        <>
            <section className="profile-section border-10">
                <div className="inner">
                    <div className="master-image">
                        <img alt="프로필 이미지"
                             src={FileUtils.getFileUrl(proReq.profileImgSrc)}
                             onError={onErrorImg}/>
                    </div>
                    <div className="master-info">
                        <div className="name-wrap">
                            <div className="name">
                                <strong>{proReq.companyAccountVO?.userName}</strong>
                                <span>사내고수<i className="ico-master"></i></span>
                            </div>
                            <div className="active-text">
                                <span>최근 활동일자</span>
                                <span>{moment(proReq.recentActivityDate).format('YYYY.MM.DD')}</span>
                            </div>
                        </div>
                        <p className="job">{proReq.companyAccountVO?.coInfoVO?.coName} I {calWorkingYear(proReq.workingYear)}</p>
                        <p className="intro" dangerouslySetInnerHTML={{__html: StringUtils.nl2br(proReq?.proInfo ?? "")}}></p>
                        <ul className="count-list">
                            <li>팔로워 <b>{proReq.followerCount}</b></li>
                            <li>상담지수 <b>{proReq.chatCount}</b></li>
                            <li>지식업로드 <b>{proReq.uploadCount}</b></li>
                        </ul>
                        <div className="util">
                            {getButton()}
                        </div>
                    </div>
                </div>
            </section>
            <section className="keyword-section border-10">
                <div className="inner">
                    <div className="master-keyword">
                        <div className="keyword">
                            <div className="keyword-title">
                                <strong className="tit">활동 직무</strong>
                            </div>
                            <div className="keyword-list">
                                <span className="badge type1 gray">{proReq.jobCodeName}</span>
                            </div>
                        </div>
                        <div className="keyword">
                            <div className="keyword-title">
                                <strong className="tit">사내고수 인증 강점역량</strong>
                                <span className="desc">그룹에서 인증한 고수의 강점역량입니다.</span>
                            </div>
                            <div className="keyword-list">
                                {
                                    proReq.officialKeyword.split(',').map((value, index) => {
                                        return (<span key={index} className="badge type1 gray">#{value}</span>)
                                    })
                                }
                            </div>
                        </div>
                        <div className="keyword">
                            <div className="keyword-title">
                                <strong className="tit">강점 역량 키워드</strong>
                                <span className="desc">강점역량 관련하여 고수에게 배울 수 있는 구체적인 스킬입니다.</span>
                            </div>
                            <div className="keyword-list">
                                {
                                    proReq.strengthKeyword.split(',').map((value, index) => {
                                        return (<span key={index} className="badge type1 gray">#{value}</span>)
                                    })
                                }
                            </div>
                        </div>
                        <div className="keyword">
                            <div className="keyword-title">
                                <strong className="tit">고수가 제안하는 에듀톡 주제</strong>
                                <span className="desc">이외에 궁금한 내용도 자유롭게 질문할 수 있어요</span>
                            </div>
                            <div className="keyword-list">
                                {
                                    proReq.chatTopic.split(',').map((value, index) => {
                                        return (<span className="badge type1 blue" key={index}>#{value}</span>)
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default M_ProProfile
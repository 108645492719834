import React, {useEffect, useState} from "react";
import Pagination from "../../pc/common/Pagination";
import BoardService from "../../../core/board/board.service";
import {Link, useLocation, useParams, useSearchParams} from "react-router-dom";
import {HWMap} from "../../../core/common/common.vo";
import {PageUtils} from "../../../common/utils/page.utils";
import {BoardVO, CodeCountDto, DefaultBoardVO} from "../../../core/board/board.vo";
import JobSkillFilterSelectButton from "../../pc/common/JobSkillFilterSelectButton";
import SearchInput from "../../pc/common/SearchInput";
import CoSelectButton from "../../pc/common/CoSelectButton";
import Snb from "./Snb";
import NullItem from "./item/NullItem";
import StringUtils from "../../../common/utils/string.utils";
import Basic from "./listType/Basic";
import Gallery from "./listType/Gallery";
import MemberUtils from "../../../common/utils/member.utils";
import {CodeFilter} from "../../../core/common/code.vo";
import {BoardInfoVO} from "../../../core/board/BoardInfo.vo";
import ElandUtils from "../../../common/utils/eland.utils";
import SwalUtils from "../../../common/utils/swal.utils";

const BoardList: React.FC<HWMap> = (hwMap) => {
    const boardService = new BoardService();
    const location = useLocation();

    let {boardKey} = useParams();
    const [query, setQuery] = useSearchParams();

    const [boardCount, setBoardCount] = useState(0);
    const [boardList, setBoardList] = useState<BoardVO[]>([]);
    const [boardInfoVO, setBoardInfoVO] = useState<BoardInfoVO>();
    const [boardMode, setBoardMode] = useState("gallery");
    const [params, setParams] = useState<HWMap>(new PageUtils())
    const [filterParams, setFilterParams] = useState<CodeFilter>({skillCode: '', jobCode: '', coCode: ''});
    const [sortState, setSortState] = useState("new");
    const [codeCountMap, setCodeCountMap] = useState<{
        coCount:CodeCountDto[],
        jobCount:CodeCountDto[],
        skillCount:CodeCountDto[]
    }>({coCount: [], jobCount: [], skillCount:[]})

    // 페이지 업데이트
    const updatePages = (newPage: number) => {
        setParams(prevParams => ({
            ...prevParams,
            page: newPage
        }));
    }

    // 직무 선택 필터
    const updateJobCode = (newJobCode: string) => {
        setFilterParams((preParams) => ({
            ...preParams,
            jobCode: newJobCode
        }));
        updatePages(0)
    }

    // 역량 선택 필터
    const updateSkillCode = (newSkillCode: string) => {
        setFilterParams((preParams) => ({
            ...preParams,
            skillCode: newSkillCode
        }));
        updatePages(0)
    }

    // 검색창
    const updateSearchText = (searchText: string) => {
        setParams((preParams) => ({
            ...preParams,
            page: 0,
            searchText: searchText
        }));
    }

    // 법인코드 필터
    const updateCoCode = (newCode: string) => {
        setFilterParams((preParams) => ({
            ...preParams,
            coCode: newCode
        }));
        updatePages(0)
    }


    // 최신순 / 조회순
    const handleSortClick = (e: any, sort: string) => {
        e.preventDefault();
        setSortState(sort)
        setParams(prevParams => ({
            ...prevParams,
            page: 0,
            sort: `${sort == 'new' ? 'createDate,DESC' : 'views,DESC'}`
        }));
    }

    const updateParams = () => {
        query.set("page", params.page.toString())
        // query.set("sort", params.sort.toString())
        query.set("searchText", params.searchText)

        isFilterBoard('jobCode') && query.set('jobCode', filterParams.jobCode ?? "")
        isFilterBoard('skillCode') && query.set('skillCode', filterParams.skillCode ?? "")
        isFilterBoard('coCode') && query.set('coCode', filterParams.coCode ?? "")

        setQuery(query, {replace: true})
    }

    const isFilterBoard = (codeMenu:string) => {
        if (codeMenu === 'jobCode' || codeMenu === 'coCode'){
           return boardKey == 'ebg_story' || boardKey == 'pro_knowledge' || boardKey == 'job_data'
        }
        if (codeMenu === 'skillCode'){
           return boardKey == 'ebg_story' || boardKey == 'pro_knowledge'
        }

        return false
    }

    const getPageSize = () => {
        if (boardMode === "gallery") {
            return 9;
        } else if (boardMode === "basic" || boardMode === "classic") {
            return 10;
        } else {
            return parseInt(query.get('size') ?? "9");
        }
    };

    const initPageParams = () => {
        setSortState("new")
        setParams({
            page: parseInt(query.get('page') ?? "0"),
            size: getPageSize(),
            sort: 'createDate,DESC',
            searchText: query.get("searchText") ?? "",
            boardKey: boardKey ?? "",
            cateCode: "",
            statusCode: ""
        })
        setFilterParams({
            jobCode: query.get('jobCode') ?? "",
            skillCode: query.get('skillCode') ?? "",
            coCode: query.get('coCode') ?? "",
        })
    }

    const updateFilterCount = async () => {
        if (!StringUtils.isEmpty(boardKey) && isFilterBoard("coCode")) {
            let params = {boardKey: boardKey}
            let result = await boardService.getBoardFilterCount(params);
            if (result.status == "SUCCESS"){
                setCodeCountMap(result.data);
            }
        }

    }


    const getBoardInfo = async () => {
        let result = await BoardService.getBoardInfoOne({boardKey});
        if (result.status === 'ok'){
            setBoardInfoVO(result.boardInfoVO);
            setBoardMode(result.boardInfoVO.boardMode)
        }
    }


    // 메인 페이지에서 jobcode를 통해 진입 했을 때
    useEffect(() => {
        updateJobCode(location.state?.jobcode)

    }, [location.state?.jobcode]);

    useEffect(() => {
        if(boardKey) {
            if (ElandUtils.isKnowledgeBoard(boardKey)) {
                getBoardInfo();
            } else {
                SwalUtils.text({
                    title : "안내",
                    message : "잘못된 접근입니다.",
                    isCancel : false,
                    confirmOptions : {
                        text : "확인",
                        eventHandler : () => window.location.href = ('/board/list/ebg_story')
                    },
                })
            }
        }
    }, [boardKey]);

    useEffect(() => {
        if(boardInfoVO) {
            initPageParams();
            updateFilterCount();
        } else {
            initPageParams();
        }
    }, [boardInfoVO]);


    useEffect(() => {
        if (params && params.size && params.boardKey != '') {
            setBoardList([]);
            setBoardCount(0);

            (async () => {
                const result = await boardService.getSearchBoards({...params, ...filterParams, ...{isContentSearch: true}});

                if (result.status == "SUCCESS" && result.data) {
                    const updatedBoardList = await ElandUtils.convertJobSkillNames4BoardList(result.data.list);
                    setBoardList(updatedBoardList);
                    setBoardCount(result.data.totalCount)
                }
            })()
        }

        updateParams()

    }, [params, filterParams])



    return (
        <main className={`content nav-layout success`}>

            {/* 리스트 본문 */}
            <div className="common-title">
                <div className="inner">
                    <h2 className="tit">{boardInfoVO?.boardName}</h2>
                    <p className="desc" dangerouslySetInnerHTML={{__html: StringUtils.nl2br(boardInfoVO?.description ?? "")}}></p>
                </div>
            </div>

            <section className="list-section">
                <div className="inner">
                    <div className="board-util">


                        {/* [사내고수 지식] 지식 작성 버튼 : 사내고수만 */}
                        {boardKey == 'pro_knowledge' && (MemberUtils.isPro() || MemberUtils.isSemiPro()) &&
                            <div className="button-area"><Link to={'/board/_/0/_/form?type=mov'} type="button"
                                                               className="btn red">작성하기</Link></div>}
                        {/* [직무자료실] 지식 작성 버튼 : 사내고수만 */}
                        {boardKey == 'job_data' && (MemberUtils.isPro() || MemberUtils.isSemiPro()) &&
                            <div className="button-area"><Link to={'/board/_/0/_/form?type=html'} type="button"
                                                               className="btn red">작성하기</Link></div>}

                        <div className="util-group">
                            {/* 필터 <EBG 성공스토리, 사내고수 지식, 직무자료실 에만 적용>*/}
                            <div className="board-filter">
                                {(boardKey == 'ebg_story' || boardKey == 'pro_knowledge' || boardKey == 'job_data') &&
                                    <>
                                        <CoSelectButton updateCoCode={updateCoCode}
                                                        initCode={filterParams.coCode}
                                                        countList={codeCountMap.coCount}></CoSelectButton>

                                        <JobSkillFilterSelectButton isSkill={false}
                                                                    updateJobCode={updateJobCode}
                                                                    updateSkillCode={updateSkillCode}
                                                                    initCode={filterParams.jobCode}
                                                                    isFilter={true}
                                                                    countList={codeCountMap.jobCount}/>

                                        {boardKey != 'job_data' &&
                                            <JobSkillFilterSelectButton isSkill={true}
                                                                        updateJobCode={updateJobCode}
                                                                        updateSkillCode={updateSkillCode}
                                                                        initCode={filterParams.skillCode}
                                                                        isFilter={true}
                                                                        countList={codeCountMap.skillCount}/>
                                        }
                                    </>
                                }
                            </div>


                            <div className="board-search">
                                <SearchInput updateSearchText={updateSearchText}
                                             placeholderText={"제목 또는 작성자를 입력해주세요."}
                                             initText={params.searchText}/>
                            </div>
                        </div>

                        <div className="util-group">
                            <div className="board-total">
                                총 <span>{boardCount}</span> 개의 게시물이 있습니다.
                            </div>
                            {/* 정렬 */}
                            <div className="board-sort">
                                <div className="sort-list">
                                    <a href="#"
                                       className={`${sortState == 'new' ? 'active' : ''}`}
                                       onClick={(e) => handleSortClick(e, 'new')}
                                    >최신순</a>
                                    <a href="#"
                                       className={`${sortState == 'view' ? 'active' : ''}`}
                                       onClick={(e) => handleSortClick(e, 'view')}
                                    >조회순</a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="board-list">
                        {
                            boardList.length > 0 ?
                                <>
                                    {boardMode === "gallery" && <Gallery paramMap={{boardList}}/>}
                                    {boardMode === "basic" && <Basic paramMap={{boardList: boardList, boardInfo: boardInfoVO}}/>}
                                    {boardMode === "classic" && <Basic paramMap={{boardList: boardList, boardInfo: boardInfoVO}}/>}
                                </>
                                : <NullItem/>
                        }


                        {/* 페이징 */}
                        {
                            boardList.length > 0 &&
                            <Pagination page={params.page}
                                        totalCount={boardCount}
                                        updatePages={updatePages}
                                        size={params.size}></Pagination>
                        }

                    </div>
                </div>
            </section>

            {/* 사이드 메뉴 바 */}
            <Snb/>

        </main>


    )
}

export default BoardList;
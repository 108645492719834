import React, {useEffect, useState} from 'react';
import M_ReviewModal from "./M_ReviewModal";
import CommonMobileJS from "../../../common/common_m";
import {M_ReviewType} from "./M_ReviewType";
import {HWMap} from "../../../core/common/common.vo";
import {PageUtils} from "../../../common/utils/page.utils";
import MemberUtils from "../../../common/utils/member.utils";
import ReviewService from "../../../core/review/review.service";
import PointService from "../../../core/point/point.service";

interface M_ReviewBottomViewProps {
    bidx?: number
    emIdx?: number
    type: M_ReviewType
}

const M_ReviewBottomView = (props: M_ReviewBottomViewProps) => {
    return (
        <>
            <div className="floating-area">
                <div className="floating-button">
                    <div className="inner">
                        <div className="button-area">
                            <button className="btn lg full btn-modal" data-id="texrareaStarAlert">한줄평
                                남기기
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default M_ReviewBottomView;
import React from "react";
import {Link} from "react-router-dom";


export default function Footer () {


	return (
		/*<!-- FOOTER -->*/
		<footer className="footer">
			<div className="inner">
				<div className="footer-logo">
					<a href="#">
						<span className="blind">E-LANDEDU</span>
					</a>
				</div>
				<div className="footer-info">
					<p>법인명 : ㈜이랜드인재원  대표이사 : 최형욱, 안영훈  개인정보책임관리자 : 안영훈</p>
					<p>주소 : 서울특별시 금천구 범안로 1155, 4층(독산동, 동남빌딩)</p>
					<p>사업자등록번호 : 597-87-00771</p>
					<p>고객센터 : eland_hrd@eland.co.kr /
						<span className="footer-nav ms-1">
						<Link to={"/privacy"} className="link-policy">개인정보처리방침</Link>
						</span>
					</p>

					<p>Copyright © 이랜드에듀 All Rights Reserved.</p>
				</div>
				<div className="footer-app">
					<img src="/elandedu/assets/images/common/img_app_down_240821.png" alt="앱 다운로드 QR코드" />
						<span>앱 다운로드</span>
				</div>
			</div>
		</footer>
		/*<!-- //FOOTER -->*/
	)
}
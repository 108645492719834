import React, {useEffect, useState} from "react";
import {JobSkillReport} from "../../../../../core/jobskill/jobskill.vo";
import {DefaultCodeVO} from "../../../../../core/common/code.vo";
import JobSkillService from "../../../../../core/jobskill/jobskill.service";
import JobSkillResult from "../result/JobSkillResult";
import JobSkillIntro from "./JobSkillIntro";
import MemberUtils from "../../../../../common/utils/member.utils";

const JobSkillMain = () => {
    const [isComplete, setIsComplete] = useState<boolean>(MemberUtils.isCompleteJobSkill())
    const [resultReport, setResultReport] = useState<JobSkillReport>({
        currentAvg: 0,
        jobCode: DefaultCodeVO(),
        parentCode: DefaultCodeVO(),
        lastAvg: 0,
        list: [],
        totalCount: 0,
    })

    useEffect(() => {
        const successHandler = (result: any) => {
            setIsComplete(true)
            MemberUtils.setIsCompleteJobSkill(true)
            setResultReport(result.data)
        }

        const failureHandler = (result: any) => {
            if (result.status ==="FAIL"){
                setIsComplete(false)
                MemberUtils.setIsCompleteJobSkill(false)
            }
        }

        JobSkillService.jobSkillResultGet(successHandler, failureHandler)

    }, []);

    return (
        isComplete ? <JobSkillResult resultReport={resultReport}/> : <JobSkillIntro/>
    )
}

export default JobSkillMain
import React, {useState} from "react";
import {BoardVO} from "../../../../../core/board/board.vo";
import moment from "moment/moment";
import {useNavigate} from "react-router-dom";

interface ProEduTalkRowProps {
    boardVO: BoardVO,
    isReceive : boolean
    onclickEvent: () => void
}

const M_ProEduTalkRow = (props:ProEduTalkRowProps) => {
    const navigate = useNavigate();

    const tabMenuList = [
        {name:'전체', code: "", color: "", btn:""},
        {name: '미해결', code: "041901", color: "red"},
        {name:'진행중', code: "041902", color: "orange"},
        {name:'일지작성', code: "041903", color: "purple"},
        {name:'완료', code: "041904", color: "green"},
        {name:'취소', code: "041905", color: "gray"}
    ]
    const {isReceive} = props
    const [boardVO, setBoardVO] = useState<BoardVO>(props.boardVO)

    const getBadge = (code:string) => {
        const filter = getLabel(code)
        return (<span className={`badge type1 sm ${filter.color}`}>{filter.name}</span>)
    }

    const getLabel = (code:string) => {
        const targetCode = !isReceive && code ==="041903" ? "041904" : code
        const filter = tabMenuList.filter(item => item.code === targetCode);
        return filter.length > 0 ? filter[0] : tabMenuList[0]
    }

    const goDetail = (idx:number) => {
        props.onclickEvent()
        navigate("/pro/talk/" + idx, {state: {isReceive : isReceive}})
    }

    return (

    <li>
        <a href="#" onClick={e => {
            e.preventDefault()
            goDetail(boardVO.idx)
        }}>
            <div className="cate">
                {boardVO.statusCode && getBadge(boardVO.statusCode)}
            </div>
            <strong className="tit">{boardVO.title}</strong>
            <p className="desc">{boardVO.contentText}</p>
            <div className="info-wrap">
                <div>
                    <div className="writer">
                        {isReceive ? boardVO.writer : (<>{boardVO.receiver} <span>고수</span></>)}&nbsp;
                    </div>
                    <span className="date">{moment(boardVO.updateDate).format('YYYY-MM-DD')}</span>
                </div>
            </div>
        </a>
    </li>
    )
}

export default M_ProEduTalkRow
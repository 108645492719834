import React, {useEffect, useRef, useState} from "react";
import {BoardVO} from "../../../core/board/board.vo";
import MainService from "../../../core/main/main.service";
import MemberUtils from "../../../common/utils/member.utils";
import {HWMap} from "../../../core/common/common.vo";
import M_CardItem from "../board/item/M_CardItem";
import ElandUtils from "../../../common/utils/eland.utils";
import type { Swiper as SwiperType } from 'swiper';
import SwiperInit from 'swiper';

/**
 * 타입에 따라 "에디터 추천 플레이리스트" 와 "에디터's PICK" 로 나뉘어 집니다.
 *
 * [2024.07.12 (금)]
 * (제목 변경) 에디터 추천 플레이 리스트 -> 전체공개 추천 콘텐츠
 * (항목 추가, 법인만 노출) 이노플 에디터 픽!!
 * (유지) 에디터’s PICK
 *
 * 말씀주신 것 중 (유지) 항목 제외한 나머지는 b/o에서 생성한 플레이리스트대로 출력되어야하는데요!
 * b/o 플레이리스트 중 전체공개인 플리 -> 전체공개 추천콘텐츠 (b/o에서 이름을 바꾸거나 콘텐츠를 바꾸면 연동되어야함)
 * b/o 플레이리스트 중 법인에 맞는 플리 -> 이노플 에디터 픽!!(b/o에서 이름을 바꾸거나 콘텐츠 바꾸면 연동되어야함)
 * 전체공개인 플리엔 콘텐츠가 무조건 들어가있을거라 고정 출력이고,
 * 법인별 플리엔 콘텐츠가 없는 경우는 해당 영역 제거 / 콘텐츠가 하나라도 추가되어있는 경우는 해당 영역 출력되어야 합니다!
 *
 * @param props
 * @constructor
 */
const M_MainEditorContents = (props: MainEditorContentsProps) => {
    const { type } = props;

    const [title, setTitle] = useState("");
    const [boardList, setBoardList] = useState<BoardVO[]>([])
    const boardSwiperRef = useRef<SwiperType | null>(null);

    useEffect(() => {
        switch (type) {
            case "recommend":
                getRecommendPlayList()
                break
            case "company":
                getCompanyPlayList()
                break
            case "pick":
                // 에디터's PICK 제목은 고정
                setTitle("추천 Pick!")
                getEditorPickList()
                break
        }
    }, [type]);

    useEffect(() => {
        initSwiper()

    }, [boardList]);

    const initSwiper = () => {
        boardSwiperRef.current?.destroy(true, true);

        if (boardList.length > 0){
            boardSwiperRef.current = new SwiperInit("#main-editor .board-slide", {
                slidesPerView: "auto",
                spaceBetween: 24,
            })
        }
    }

    /**
     * 에디터 추천 플레이리스트 조회
     */
    const getRecommendPlayList = () => {
        const params: HWMap = {
            size: 8
        };

        // 전체 공개라 주석 처리
        // if (MemberUtils.isLogin()) {
        //     params.coCode = MemberUtils.getSessionVO()?.coCode ?? ""
        // }

        const successHandler = async (result: any) => {
            console.log("⭐getRecommendPlayList", result);
            if(!result.data.boardList) return
            let newList = await ElandUtils.convertJobSkillNames4BoardList(result.data.boardList)
            setTitle(result.data.title)
            setBoardList(newList);
        }

        MainService.getRecommendPlayList(params, successHandler)
    }

    /**
     * 법인 플레이리스트 조회
     */
    const getCompanyPlayList = () => {
        const params: HWMap = {
            size: 8
        };

        if (MemberUtils.isLogin()) {
            params.coCode = MemberUtils.getSessionVO()?.coCode ?? ""
        }

        const successHandler = async (result: any) => {
            console.log("⭐getCompanyPlayList", result);
            if(!result.data.boardList) return
            let newList = await ElandUtils.convertJobSkillNames4BoardList(result.data.boardList)
            setTitle(result.data.title)
            setBoardList(newList);
        }

        MainService.getCompanyPlayList(params, successHandler)
    }

    /**
     * 에디터's Pick 조회
     */
    const getEditorPickList= () => {
        const params = {
            size: 8,
            sort: "createDate,DESC"
        };

        const successHandler = async (result: any) => {
            let newList = await ElandUtils.updateBoardListWithJobNames(result.data.list)
            setBoardList(newList);
        }

        MainService.getEditorPickList(params, successHandler)
    }

    return (
        (boardList && boardList.length !== 0) ? (
            <section id="main-editor" className="list-section">
                <div className="inner">
                    <div className="section-title">
                        <h2 className="tit">
                            {title}
                        </h2>
                    </div>
                    <div className="board-list board-slide">
                        <ul className="card-list type1 swiper-wrapper">
                            {
                                boardList.map((value, index) => {
                                    return (
                                        <M_CardItem key={value.idx} board={value}/>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </div>
            </section>
        ) : <></>
    );
}

interface MainEditorContentsProps {
    /**
     * recommend : 에디터 추천 플레이리스트
     * pick : 에디터's PICK
     */
    type: string
}

export default M_MainEditorContents
import React, {useEffect, useState} from "react";
import {JobSkillReport} from "../../../../../core/jobskill/jobskill.vo";
import {DefaultCodeVO} from "../../../../../core/common/code.vo";
import JobSkillService from "../../../../../core/jobskill/jobskill.service";
import M_JobSkillResult from "../result/M_JobSkillResult";
import M_JobSkillIntro from "./M_JobSkillIntro";
import MemberUtils from "../../../../../common/utils/member.utils";
import useHeader from "../../../../../common/hook/useHeader";
import {HeaderBtn} from "../../../../../core/common/header";

const M_JobSkillMain = () => {
    useHeader({
        buttons: [HeaderBtn.Menu],
        title: "직무역량진단",
        type: 'sub'})


    const [isComplete, setIsComplete] = useState<boolean>(MemberUtils.isCompleteJobSkill())
    const [resultReport, setResultReport] = useState<JobSkillReport>({
        currentAvg: 0,
        jobCode: DefaultCodeVO(),
        parentCode: DefaultCodeVO(),
        lastAvg: 0,
        list: [],
        totalCount: 0,
    })

    useEffect(() => {
        const successHandler = (result: any) => {
            setIsComplete(true)
            MemberUtils.setIsCompleteJobSkill(true)
            setResultReport(result.data)
        }

        const failureHandler = (result: any) => {
            if (result.status ==="FAIL"){
                setIsComplete(false)
                MemberUtils.setIsCompleteJobSkill(false)
            }
        }

        JobSkillService.jobSkillResultGet(successHandler, failureHandler)

    }, []);

    return (
        isComplete ? <M_JobSkillResult resultReport={resultReport}/> : <M_JobSkillIntro/>
    )
}

export default M_JobSkillMain
import React, {useEffect, useRef, useState} from "react";
import {CodeVO} from "../../../../../core/common/code.vo";

interface TypeFilterProps {
    menuList:CodeVO[],
    currentCode: string,
    updateEvent: (code:string, isCancel:boolean)=> void
}


const M_TypeFilter = (props:TypeFilterProps) => {
    const {menuList, updateEvent} = props
    const [tempJobCode, setTempJobCode] = useState<string>(props.currentCode)

    const [translateY, setTranslateY] = useState(0);
    const startYRef = useRef<number | null>(null);

    useEffect(() => {
        const prevScrollY = preventScroll();
        return () => {
            allowScroll(prevScrollY);
        };
    }, []);

    const preventScroll = () => {
        const currentScrollY = window.scrollY;
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
        document.body.style.top = `-${currentScrollY}px`; // 현재 스크롤 위치
        document.body.style.overflowY = 'scroll';
        document.body.style.overflow = "hidden";
        document.body.style.overscrollBehaviorY = 'contain'
        return currentScrollY;
    };

    const allowScroll = (prevScrollY: number) => {
        document.body.style.position = '';
        document.body.style.width = '';
        document.body.style.top = '';
        document.body.style.overflowY = '';
        document.body.style.overflow = "";
        document.body.style.overscrollBehaviorY = ''
        window.scrollTo(0, prevScrollY);
    };




    const checkDragEleClick = (target: HTMLDivElement, x: number, y: number) => {
        const rect = target.getBoundingClientRect();

        // const pseudoElementLeft = rect.left + rect.width / 2 - 20;
        // const pseudoElementWidth = 40;
        // const xCheck = x >= pseudoElementLeft && x <= pseudoElementLeft + pseudoElementWidth

        const pseudoElementTop = rect.top + 16;
        const pseudoElementHeight = 20;
        const yCheck = y >= pseudoElementTop - pseudoElementHeight && y <= pseudoElementTop + pseudoElementHeight

        return yCheck

    };


    const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
        event.stopPropagation()
        const touch = event.touches[0];
        if (checkDragEleClick(event.currentTarget, touch.clientX, touch.clientY)) {
            startYRef.current = touch.clientY - translateY
        }
    };

    const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
        event.stopPropagation()
        if (startYRef.current !== null) {
            const touch = event.touches[0];
            const newTranslateY = touch.clientY - startYRef.current;

            if (newTranslateY >= 0) {
                setTranslateY(newTranslateY)
            }
        }
    }

    const handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
        event.stopPropagation()
        if (translateY > 100) {
            updateEvent(tempJobCode, true)
            setTranslateY(0)
        } else {
            setTranslateY(0)
        }
        startYRef.current = null;
    };

    const filterClose = () => {
        updateEvent(tempJobCode, false)
        setTranslateY(0)
    };


    return (
        <div className="layer type-half job-filter"
             style={{
                 display:'block',
                 transform: `translateY(${translateY}px)`,
                 transition: translateY === 0 ? 'transform 0.3s ease' : 'none',
             }}
        >
            <div className="layer-content"
                 onTouchStart={handleTouchStart}
                 onTouchMove={handleTouchMove}
                 onTouchEnd={handleTouchEnd}>
                <div className="inner">
                    <div className="layer-title">
                        <h3 className="tit">직무 필터</h3>
                    </div>
                    <div className="scroll-content">
                        <ul className="filter-list">
                            <li className={tempJobCode === '' ? 'active' : ''}>
                                <a href="#" onClick={e => {
                                    e.preventDefault()
                                    setTempJobCode('')
                                }}>전체</a>
                            </li>
                            {
                                menuList.map(menu => {
                                    return (
                                        <li key={'f' + menu.code}
                                            className={tempJobCode === menu.code ? 'active' : ''}>
                                            <a href="#" onClick={e => {
                                                e.preventDefault()
                                                setTempJobCode(menu.code)
                                            }}>{menu.codeName}</a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <div className="layer-button">
                <div className="inner">
                    <div className="button-area">
                        <button type="button"
                                className="btn lg full"
                                onClick={e => {
                                    e.preventDefault()
                                    filterClose()
                                }}
                        >
                            조회하기
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default M_TypeFilter;
import React, {useEffect, useRef, useState} from 'react';
// @ts-ignore
import WebViewer from '@pdftron/pdfjs-express-viewer';
import APIs from "../../../common/apis/APIs";

interface PdfViewerProps {
    fileUrl: string;
}

const PdfViewer: React.FC<PdfViewerProps> = ({fileUrl}) => {
    const viewer = useRef(null);
    useEffect(() => {
        WebViewer(
            {
                path: process.env.PUBLIC_URL + '/elandedu/assets/libs/webviewer/',
                initialDoc: fileUrl,
                licenseKey: APIs.PDF_KEY,
                disabledElements: [
                    'eraserToolButton',
                    'freeHandToolGroupButton',
                    'textToolGroupButton',
                    'shapeToolGroupButton',
                    'signatureToolButton',
                    'freeTextToolButton',
                    'stickyToolButton',
                    'miscToolGroupButton',
                    'outlinesPanelButton',
                    'toolsHeader',
                    'notesPanelButton',
                    'printButton',
                    'toggleNotesButton',
                    'toolbarGroup-FillAndSign',
                    'toolbarGroup-Insert',
                    'toolbarGroup-Shapes',
                    'toolbarGroup-Annotate',
                    'toolbarGroup-View',
                    'selectToolButton',
                    'downloadButton',
                    'highlightToolButton',
                    'freeHandToolButton',
                    'freeHandHighlightToolButton'
                ]
            },
            viewer.current,
        ).then((instance: any) => {
            instance.UI.disableElements(['menuButton'])
            instance.UI.disableElements(['moreButton'])
            instance.UI.disableElements(['fullscreenButton'])

            if(!isIos()) {
                instance.UI.setHeaderItems((header: any) => {
                    header.getHeader('default').push({
                        img: "icon-header-full-screen",
                        index: -1,
                        type: "actionButton",
                        element: 'fullScreenButton',
                        onClick: () => {
                            instance.UI.toggleFullScreen()

                            const iframe = document.querySelector('iframe'); // 또는 정확한 선택자를 사용
                            const iframeDocument = iframe?.contentWindow?.document;

                            const footer = iframeDocument?.querySelector('.footer') as HTMLElement;
                            if (footer) {
                                if (document.fullscreenElement) {
                                    footer.style.paddingBottom = '0';
                                } else {
                                    footer.style.paddingBottom = '30px';
                                }
                            }
                        }
                    });
                });
            }
        });
    }, []);

    // iOS 감지 함수
    function isIos() {
        return (
            ['iPhone'].includes(navigator.platform)
        );
    }

    return (
        <div className="pdfApp">
            <div className="webviewer" ref={viewer}></div>
        </div>
    );

};

export default PdfViewer;

import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import axiosCaller from "../../../../common/utils/axios.caller";
import APIs from "../../../../common/apis/APIs";
import ProService from "../../../../core/pro/pro.service";
import MemberUtils from "../../../../common/utils/member.utils";
import SwalUtils from "../../../../common/utils/swal.utils";
import ElandUtils from "../../../../common/utils/eland.utils";


const MypageNav = (props:{currentMenu : string}) => {
    const BOARD_KEY_LIST = ['company','organization','company_announce','announce','frequently'];
    const [boardNameList, setBoardNameList] = useState<{boardKey:string, boardName:string}[]>([])
    useEffect(() => {
        getBoardNameList()
    }, []);

    useEffect(() => {
        setCurrentMenu(props.currentMenu)
    }, [props]);

    const [currentMenu, setCurrentMenu] = useState<string>(props.currentMenu)

    const getBoardNameList = async () => {
        let result = await ElandUtils.getBoardNameList();
        setBoardNameList(result)
    }

    const getBoardName = (boardKey:string)=>{
        let find = boardNameList.find(board=> board.boardKey === boardKey);
        return find ? find.boardName : boardKey
    }

    const isActiveMenu = (menu:string) => {
        return menu === currentMenu ? "active" : ""

    }

    const doLogout = async (e:React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()

        SwalUtils.text({
            title : "로그아웃",
            message : "<p style='line-height: 150%'>정말로 로그아웃 하시겠습니까?</p>",
            isIcon : false,
            confirmOptions : {
                text : "확인",
                eventHandler : async () => {
                    const result = await axiosCaller.post(APIs.SESSION + "/logout", {})

                    if (result.status === "SUCCESS"){
                        MemberUtils.clearLocalStorageExcept(['isRemember', 'rememberUserId', 'savedJobSkillDate'])
                        sessionStorage.clear()
                        window.location.href = "/"
                    } else {
                        ProService.failHandler(result)

                    }
                }
            },
            cancelOptions : {
                text : "취소",
                eventHandler : () => {}
            },
            isCancel: true
        })
    }




    return (
        <nav className="snb">
            <strong className="tit">마이페이지</strong>
            <ul className="snb-list">
                <li className={isActiveMenu("alarm")}>
                    <Link to={"/mypage/alarm"}>알림함</Link>
                </li>
                <li className={isActiveMenu("point")}>
                    <Link to={"/mypage/point"}>학습포인트</Link>
                </li>
                <li className={isActiveMenu("jobskill")}>
                    <Link to={"/mypage/jobskill"}>직무역량진단</Link>
                </li>
                <li className={isActiveMenu("management")}>
                    <Link to={"/mypage/management"}>회원정보관리</Link>
                </li>
                {
                    BOARD_KEY_LIST.map(key => {
                        let link = !(key ==="frequently") ? `/board/${key}` : "/frequently"
                        return (
                            <li key={key} className={isActiveMenu(key)}>
                                <Link to={`/mypage${link}`}>{getBoardName(key)}</Link>
                            </li>
                        )
                    })
                }
                <li>
                    <a href="#" onClick={doLogout}>로그아웃</a>
                </li>
            </ul>
        </nav>
    )
}

export default MypageNav
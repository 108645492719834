import React, {useEffect, useState} from "react";
import CommonJS from "../../../common/common";
import StarRating from "./StarRating";
import {HWMap} from "../../../core/common/common.vo";
import {PageUtils} from "../../../common/utils/page.utils";
import {ReviewType} from "./ReviewType";
import ProService from "../../../core/pro/pro.service";
import PointService from "../../../core/point/point.service";

/**
 * 에듀톡
 */
interface EduTalkReviewWriteProps {
    bidx: number
    targetId: string
    updateHandler: () => void
}

const EduTalkReviewWrite = (props: EduTalkReviewWriteProps) => {
    const { bidx, targetId, updateHandler} = props;

    // 선택한 키워드 리스트
    const [selectedKeywords, setSelectedKeyword] = useState<string[]>([]);

    // 한줄평 텍스트
    const [reviewText, setReviewText] = useState("");

    // 평점
    const [rating, setRating] = useState<number>(0)

    // 피드백
    const [feedback, setFeedback] = useState<number>(0)

    // 완료 버튼 활성/비활성
    const [isDisabled, setIsDisabled] = useState(true);

    const [params, setParams] = useState<HWMap>(new PageUtils())

    // 리뷰 저장
    const setReview = async () => {
        if (bidx && targetId) {
            await ProService.inquiryReviewSave(params, updateHandler)
            await PointService.savePoint("PRO_FEEDBACK", bidx)
        }
    }

    // 초기화
    useEffect(() => {
        let _params = params
        _params.bidx = bidx;
        _params.targetId = targetId;
        _params.type = ReviewType.EDU_TALK;
        setParams(_params)

        CommonJS.select();
    }, []);

    // 각 상태값 체크
    useEffect(() => {
        updateCompleStatus()
    }, [selectedKeywords, reviewText, rating, feedback]);

    // 완료 버튼 클릭 했을 때
    const complete = () => {
        let _params = params
        // 키워드 리스트
        console.log(selectedKeywords, reviewText)

        // 한줄평
        console.log("reviewText : ", reviewText)

        // 별점
        console.log("rating : ", rating)

        // 피드백
        console.log("feedback : ", feedback)

        console.log("완료")

        const keywordCodeList = selectedKeywords.map((keyword, index) => keyword).join(', ').replaceAll(" ", "");

        _params.keywordCodeList = keywordCodeList
        _params.title = reviewText
        _params.rating = rating
        _params.feedback = feedback

        setReview()
    }

    // 키워드 컴포넌트
    const keywordComponent = (keywordCode: string) => {
        const descriptions: { [key: string]: string } = {
            "350101": "내 일에 진짜 활용할 수 있는 직무 꿀팁을 받았어요",
            "350102": "전문성이 느껴졌어요",
            "350103": "필요할 때 도움을 빠르게 받을 수 있었어요",
            "350104": "다양한 경험을 공유해 주었어요",
            "350105": "참고하고 활용할 수 있는 자료를 공유해 주셨어요",
            "350106": "나의 질문을 끝까지 고민하고 해결해 주었어요",
            "350107": "문제를 해결할 수 있는 방향을 잘 제시해 주었어요",
            "350108": "현실적인 조언을 주었어요",
            "350109": "따뜻한 응원과 격려를 받아 해낼 용기가 생겼어요",
            "350110": "편안한 분위기를 만들어주고, 질문에 친절하게 답변해 주었어요"
        };

        return (
            <div className="checkbox" >
                <input
                    type="checkbox"
                    id={keywordCode}
                    className="inp-checkbox"
                    onChange={onChangeKeyword}
                    checked={selectedKeywords.includes(keywordCode)}
                />
                <label htmlFor={keywordCode} className="lab-checkbox">
                    { descriptions[keywordCode] }
                </label>
            </div>
        );
    }

    // 키워드 리스트 10개 합친 것
    const keywordListView = () => {
        const codes = Array.from({ length: 10 }, (_, i) => (350101 + i).toString());

        return (
            <div>
                {codes.map(code =>
                    <React.Fragment key={code}>
                        {keywordComponent(code)}
                    </React.Fragment>
                )}
            </div>
        );
    };

    // 키워드 변경
    const onChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = e.target;

        // 최대 5개
        if (selectedKeywords.length >= 5) {
            // 선택되어 있으면 해제
            setSelectedKeyword(prev => prev.filter(item => item !== id));
            updateCompleStatus();
            return
        }

        if (checked) {
            setSelectedKeyword(prev => [...prev, id]);
        } else {
            setSelectedKeyword(prev => prev.filter(item => item !== id));
        }
    };

    // 한줄평 변경
    const onChangeReviewText = (e:React.ChangeEvent<HTMLTextAreaElement>) => {
        setReviewText(e.target.value)
    }

    // 피드백 변경
    const onChangeFeedback = (e:React.ChangeEvent<HTMLInputElement>) => {
        let feedback: number = Number(e.target.id.replace("radioId", ""));
        setFeedback(feedback)
    }

    // 별점 변경
    const onClickRating = () => {
        let btnSelectClass = document.getElementsByClassName("btn-select");
        let starClass = btnSelectClass[0].getElementsByClassName("star");
        let rating = starClass[0].getElementsByClassName("check").length;
        setRating(rating);
    }

    // 완료 버튼 활성/비활성화
    const updateCompleStatus = () => {
        let disabled = selectedKeywords.length == 0 || reviewText == "" || rating == 0 || feedback == 0
        setIsDisabled(disabled)
    }

    return (
        <div className="evaluation">
            <div className="form-list">
                <div className="form-item">
                    <div className="form-title">
                        <strong className="tit">키워드</strong>
                        <p className="desc">키워드는 최대 5개 선택할 수 있습니다</p>
                    </div>
                    <div className="chk-bind">
                        { keywordListView() }
                    </div>
                </div>
                <div className="form-item">
                    <div className="form-title">
                        <strong className="tit">한줄평</strong>
                    </div>
                    <div className="inp-bind">
                    <textarea
                        name=""
                        id=""
                        cols={30}
                        rows={4}
                        className="inp-text"
                        placeholder="고수에게 남길 후기를 작성해주세요"
                        value={reviewText}
                        onChange={onChangeReviewText}
                        maxLength={1000}
                    />
                    </div>
                </div>
                <div className="form-item">
                    <div className="form-title">
                        <strong className="tit">피드백</strong>
                        <p className="desc">
                            남기신 피드백은 고수에게 노출되지 않습니다
                        </p>
                    </div>
                    <div className="star-wrap">
                        <p className="ques">
                            더 나은 사내고수를 위해 별점을 남겨주세요.
                        </p>
                        <div className="select">
                            <button type="button" className="btn-select">
                                <div className="star">
                                    <StarRating totalStars={5} rating={rating} />
                                </div>
                            </button>
                            <ul className="select-option" onClick={onClickRating}>
                                <li className="selected">
                                    <a>
                                        <div className="star">
                                            <StarRating totalStars={5} rating={5}/>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a>
                                        <div className="star">
                                            <StarRating totalStars={5} rating={4}/>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a>
                                        <div className="star">
                                            <StarRating totalStars={5} rating={3}/>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a>
                                        <div className="star">
                                            <StarRating totalStars={5} rating={2}/>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a>
                                        <div className="star">
                                            <StarRating totalStars={5} rating={1}/>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="recommend-wrap">
                        <p className="ques">
                            고수를 다른 사람들에게 추천하고 싶습니다.
                        </p>
                        <div className="rad-bind">
                            <div className="radio">
                                <input
                                    type="radio"
                                    name="radios"
                                    id="radioId1"
                                    className="inp-radio"
                                    onChange={onChangeFeedback}
                                />
                                <label htmlFor="radioId1" className="lab-radio">
                                    전혀아니다
                                </label>
                            </div>
                            <div className="radio">
                                <input
                                    type="radio"
                                    name="radios"
                                    id="radioId2"
                                    className="inp-radio"
                                    onChange={onChangeFeedback}
                                />
                                <label htmlFor="radioId2" className="lab-radio">
                                    아니다
                                </label>
                            </div>
                            <div className="radio">
                                <input
                                    type="radio"
                                    name="radios"
                                    id="radioId3"
                                    className="inp-radio"
                                    onChange={onChangeFeedback}
                                />
                                <label htmlFor="radioId3" className="lab-radio">
                                    보통이다
                                </label>
                            </div>
                            <div className="radio">
                                <input
                                    type="radio"
                                    name="radios"
                                    id="radioId4"
                                    className="inp-radio"
                                    onChange={onChangeFeedback}
                                />
                                <label htmlFor="radioId4" className="lab-radio">
                                    그렇다
                                </label>
                            </div>
                            <div className="radio">
                                <input
                                    type="radio"
                                    name="radios"
                                    id="radioId5"
                                    className="inp-radio"
                                    onChange={onChangeFeedback}
                                />
                                <label htmlFor="radioId5" className="lab-radio">
                                    매우 그렇다
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="button-area">
                        <button type="button" className="btn red" id="completeButton" onClick={complete} disabled={isDisabled}>
                            완료
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EduTalkReviewWrite;
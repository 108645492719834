import React from "react";
import FileUtils from "../../../../common/utils/file.utils";
import HwsPlayer from "../../common/HwsPlayer";
import APIs from "../../../../common/apis/APIs";

interface ProRequestNotCompleteProps{
    isComplete: boolean
}

const ProRequestNotComplete = (props: ProRequestNotCompleteProps) => {
    return (
        <>
            <section className="master-section define">
                <div className="inner">
                    <div className="section-title">
                        <h3 className="tit">사내고수란?</h3>
                    </div>
                    <div className="section-content">
                        <div className="master-define">
                            <div>
                                <strong>누구나 할 수 있어요!</strong>
                                <p>
                                    직무 영역에서 가진 지식과 노하우를 동료에게 전파하고<br/>
                                    나눠주는 사람, 우리는 그들을 “사내고수”라고 부릅니다.<br/>
                                    그리고 이랜드의 직원이라면 누구나 도전 가능합니다.
                                </p>
                            </div>
                            <div>
                                <strong>이건 꼭 필요해요!</strong>
                                <p>
                                    단, ‘법인에서 인정받은 활동’ 을 수행한 적이 있어야 합니다.<br/>
                                    사내고수 도전하기를 클릭해서 내가 수행한 활동이 있는지 확인해보세요!
                                </p>
                            </div>
                        </div>
                        <p className="master-qna">
                            <b>Q : 지금 해당되는 활동이 없으면 앞으로도 못하나요?</b>
                            고수님들의 원활하고 유의미한 활동을 위해서 지원조건이 있을 뿐, 아직 수행한 활동이 없다고 포기하지 마세요!<br/>
                            언제든지 활동을 하나라도 수행했다면, 에듀는 예비고수님들의 도전을 기다리고 있습니다.
                        </p>
                    </div>
                </div>
            </section>
            <section className="master-section act">
                <div className="inner">
                    <div className="section-title">
                        <h3 className="tit">사내고수로 함께한다면<br/>어떤 활동을 할 수 있나요?</h3>
                    </div>
                    <div className="section-content">
                        <div className="desc">
                            <p>
                                사내고수는 크게 ①지식업로드(영상 /지식노트/자료) ②에듀톡 ③오픈강좌 ④사내 교육 공식 강사<br/>
                                4가지 활동을 할 수 있습니다.<br/>
                                고수님 각자의 성향에 맞게 더 잘 할 수 있는 영역 위주로 활동하실 수도 있습니다.<br/>
                                사내고수는 지식을 에듀 플랫폼에 직접 업로드 할 수 있으며, 올려 주신 지식은 직원들에게 맞춤 큐레이션 되어 추천 됩니다.<br/>
                                또한 나에게 도움을 받은 직원의 후기도 확인하실 수 있습니다.
                            </p>
                        </div>
                        <ul className="icon-list">
                            <li>
                                <i className="ico-act"></i>
                                <span>지식업로드 활동</span>
                            </li>
                            <li>
                                <i className="ico-act"></i>
                                <span>에듀톡 활동</span>
                            </li>
                            <li>
                                <i className="ico-act"></i>
                                <span>오픈강좌</span>
                            </li>
                            <li>
                                <i className="ico-act"></i>
                                <span>사내 교육 공식 강사</span>
                            </li>
                        </ul>
                        <ul className="act-list">
                            <li>
                                <strong className="tit">
                                    <span>01</span>
                                    지식업로드 활동
                                </strong>
                                <p className="desc-1">
                                    고수는 자신의 성공 경험, 가지고 있는 노하우나 지식을 정리하는 과정을 거쳐 콘텐츠화 합니다.<br/>
                                    고수는 에듀v2 플랫폼에 자신의 콘텐츠를 자유롭게 업로드할 수 있습니다.<br/>
                                    해당 지식은 직원들에게 추천되어 직원들이 학습을 하고 성장을 할 수 있는 발판이 됩니다.<br/>
                                </p>
                                <p className="desc-2">
                                    1. 영상콘텐츠 <br/>
                                    2. 고수의 지식노트<br/>
                                    3. PPT,엑셀 등의 자료 <br/>
                                    등 여러 형태로 지식 공유가 가능합니다.<br/>
                                </p>
                            </li>
                            <li>
                                <strong className="tit">
                                    <span>02</span>
                                    에듀톡 활동
                                </strong>
                                <p className="desc-1">
                                    나에게 무언가 궁금해서 문의를 준(=에듀톡 문의) 동료에게 직접적으로 지식을 전달해주는 활동을 말합니다.<br/>
                                    문의에 대해 지식 전달(=답변)하는 방법은 문의의 내용에 따라, 혹은 나의 상황에 따라 맞는 방법으로 진행할 수
                                    있습니다.
                                </p>
                                <p className="desc-2">
                                    1. 에듀톡 문의 창에서 답변<br/>
                                    2. 직접 만나서 답변<br/>
                                    3. 온라인으로 만나서 답변 (줌미팅 등)<br/>
                                    4. 전화통화를 통해 답변<br/>
                                    등의 형태로 답변 가능합니다
                                </p>
                            </li>
                            <li>
                                <strong className="tit">
                                    <span>03</span>
                                    오픈강좌
                                </strong>
                                <p className="desc-1">
                                    고수가 <b>자발적으로 신청 혹은 직원들의 요청에 의하여</b> 나의 강좌를 오픈하고 강의하는 활동입니다.<br/>
                                    플랫폼을 통해 강좌를 열고 수강인원을 받아 진행하게 됩니다.
                                </p>
                            </li>
                            <li>
                                <strong className="tit">
                                    <span>04</span>
                                    사내 교육 공식 강사
                                </strong>
                                <p className="desc-1">
                                    <b>법인의 오프라인 교육에서의 강사 활동</b>을 의미합니다.<br/>
                                    우수 사내강사 기준으로 강사료가 지급됩니다.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="master-section benefit">
                <div className="inner">
                    <div className="section-title">
                        <h3 className="tit">어떤 혜택이 있나요?</h3>
                    </div>
                    <div className="section-content">
                        <div className="master-benefit">
                            <div>
                                <strong>명예</strong>
                                <p>HR링크에 등재 및 대표님의 공식임명 / 이넷 소개 / EM전용 명함, 사원증 지급 / 전문 프로필사진
                                    촬영(헤어&메이크업) </p>
                            </div>
                            <div>
                                <strong>평가</strong>
                                <p>
                                    사내고수 임명된<br/>
                                    당해 역량평가 E 보장
                                </p>
                            </div>
                            <div>
                                <strong>성장</strong>
                                <p>
                                    우수인재 네트워킹 / 다양한<br/> 스킬업
                                    교육 제공 / 강사 우선섭외<br/>
                                    콘텐츠 제작 플랫폼 비용 지원
                                </p>
                            </div>
                            <div>
                                <strong>포상</strong>
                                <p>
                                    인센티브, 활동비 등 활동에 따른<br/>
                                    에듀포인트 적립 / 그룹페스티벌<br/>
                                    초청 및 포상
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="master-section video">
                <div className="inner">
                    <div className="section-title">
                        <h3 className="tit">고수 선배들의 실제 후기</h3>
                    </div>
                    <div className="section-content">
                        <HwsPlayer
                            key={1}
                            url={`https://cdn-edu.eland.co.kr/attach_files/cms/2024/10/14/buypn1728864216987.mp4`}
                            thumbnail={`https://cdn-edu.eland.co.kr/attach_files/cms/2024/10/14/lqddb1728864281505.png`}
                            width={`100%`}
                            height={`100%`}
                        />
                    </div>
                </div>
            </section>
            <section className="master-section process">
                <div className="inner">
                    <div className="section-title">
                        <h3 className="tit">어떤 과정이 필요한가요?</h3>
                    </div>
                    <div className="section-content">
                        <ul className="master-process">
                            <li>
                                <strong><span>1</span> 지원서 접수</strong>
                                <p className="txt">하단 “사내고수 도전하기” 버튼을 클릭해서 지원서를 접수해주세요</p>
                                <p className="txt sm">*사내고수 지원 자격: 법인에서 인증한 활동을 1가지 이상 수행한 사람</p>
                            </li>
                            <li>
                                <strong><span>2</span> 심사 및 선별</strong>
                                <p className="txt">지원서에 대한 심사가 마무리 되면 메일로 선발 안내가 나가요.</p>
                            </li>
                            <li>
                                <strong><span>3</span> 부트캠프</strong>
                                <p className="txt">온라인 사전과제와 오프라인<br/>교육과정을 병행하고 총 3개월간<br/>진행돼요.
                                </p>
                                <p className="txt sm">*부트캠프는 온라인과 오프라인 과정을 병행하고 총 8시간 소요돼요.</p>
                            </li>
                            <li>
                                <strong><span>4</span> 사내고수 임명</strong>
                                <p className="txt red">멋진 사내고수가 되어 활동해주세요!</p>
                            </li>
                        </ul>
                    </div>

                    {
                        !props.isComplete && (
                            <div className="button-area">
                                <button type="button" className="btn red lg" data-bs-toggle="modal"
                                        data-bs-target="#applyModal">사내고수 도전하기
                                </button>
                            </div>
                        )
                    }

                </div>
            </section>
        </>
    )
}

export default ProRequestNotComplete

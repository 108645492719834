import ProHelpDetailCommentInput from "./ProHelpDetailCommentInput";
import ProHelpDetailCommentRow from "./ProHelpDetailCommentRow";
import React, {useEffect, useState} from "react";
import {CommentVO} from "../../../../../core/board/board.vo";
import {useParams} from "react-router-dom";
import ProService from "../../../../../core/pro/pro.service";

const ProHelpDetailComment = () => {
    const {bidx} = useParams()
    const [commentList, setCommentList] = useState<CommentVO[]>([])

    useEffect(() => {
        getCommentList()
    }, []);

    const getCommentList = async () => {
        const successHandler = (result:any) => {
            setCommentList(result.data)
        }

        bidx && await ProService.helpCommentListGet(bidx, successHandler);
    }

    const updateComment = ()=> {
        getCommentList()
    }


    return (
        <section className="comment-section">
            <div className="inner">
                <div className="comment type2">
                    <div className="comment-title">
                        <h3 className="tit">댓글</h3>
                        <span className="num">{commentList.length}</span>
                    </div>
                    <ProHelpDetailCommentInput comment={null} updateEvent={updateComment}/>

                    <ul className="comment-list">
                        {
                            commentList.map(value => {
                                return (<ProHelpDetailCommentRow key={value.cidx} comment={value} updateEvent={updateComment}/>)
                            })
                        }

                    </ul>
                </div>
            </div>
        </section>
    )
}

export default ProHelpDetailComment
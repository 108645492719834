import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {BoardVO} from "../../../../core/board/board.vo";
import {BoardInfoVO} from "../../../../core/board/BoardInfo.vo";
import BoardListTypeRow from "./BoardListTypeRow";
import BoardBasicTypeRow from "./BoardBasicTypeRow";

interface BoardBasicTypeProps {
    boardInfo: BoardInfoVO,
    boardList : BoardVO[],
    noticeList: BoardVO[],
    size: number,
    page: number,
    totalCount:number

}
const BoardBasicType = (props:BoardBasicTypeProps) => {
    const navigation = useNavigate()
    const {boardKey} = useParams()
    const [boardInfo, setBoardInfo] = useState<BoardInfoVO>(props.boardInfo)
    const [boardList, setBoardList] = useState<BoardVO[]>(props.boardList)
    const [noticeList, setNoticeList] = useState<BoardVO[]>(props.noticeList)
    const [page, setPage] = useState<number>(props.page)
    const [size, setSize] = useState<number>(props.size)
    const [totalCount, setTotalCount] = useState<number>(props.totalCount)

    useEffect(() => {
        setBoardList(props.boardList)
        setNoticeList(props.noticeList)
        setBoardInfo(props.boardInfo)
        setPage(props.page)
        setSize(props.size)
        setTotalCount(props.totalCount)

    }, [props]);

    const goDetail = (idx: number) => {
        navigation('/mypage/board/'+ boardKey +'/' + idx)
    }

    return (
        <table className="table-list type1">
            <colgroup>
                <col/>
                {boardInfo.isCategory && (<col/>)}
                <col style={{width: "50%"}}/>
                <col/>
                <col/>
                <col/>
            </colgroup>
            <thead>
                <tr>
                    <th>순번</th>
                    {boardInfo.isCategory && (<th>분류</th>)}
                    <th>제목</th>
                    <th>작성자</th>
                    <th>작성일</th>
                    <th>조회수</th>
                </tr>
            </thead>
            <tbody>
                {
                    noticeList.length > 0 &&
                    noticeList.map(value => <BoardBasicTypeRow key={value.idx}
                                                               board={value}
                                                               clickEvent={goDetail}
                                                               isCategory={boardInfo.isCategory}
                                                               isNotice={true}/>)
                }
                {
                    boardList.length > 0 &&
                    boardList.map((value, index) => <BoardBasicTypeRow key={value.idx}
                                                              board={value}
                                                              clickEvent={goDetail}
                                                              isCategory={boardInfo.isCategory}
                                                              isNotice={false}
                                                              index={totalCount - ((page * size) + index)}/>)
                }
                {
                    boardList.length <= 0 && noticeList.length <= 0 &&
                    (
                        <tr>
                            <td colSpan={7}>
                                <div className="content-empty">
                                    <i className="ico-content"></i>
                                    <p className="txt">검색 결과가 없습니다.<br/>검색어를 다시 한 번 확인해주세요</p>
                                </div>
                            </td>
                        </tr>
                    )
                }
            </tbody>
        </table>
    )
}

export default BoardBasicType
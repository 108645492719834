import {EduMainVO} from "../../../core/education/education.vo";
import React, {MutableRefObject, useEffect, useRef} from "react";
import { Chart } from "../../../assets/libs/chart.js/chart.min";

interface MainEduStatusChartItemProps {
    item?: EduMainVO
}

const M_MainEduStatusChartItem = (props: MainEduStatusChartItemProps) => {
    const {item} = props;

    // @ts-ignore
    const chartRef: MutableRefObject<Chart | null> = useRef(null);

    useEffect(() => {

    }, []); // 빈 종속성 배열

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.destroy();
        }

        if (item) {
            /* Progress Chart */
            const totalValue1 = item.contentCount;
            const progressValue1 = item.answerCount;
            Chart.getChart(`ProgressCourse-${item.emIdx}`) && Chart.getChart(`ProgressCourse-${item.emIdx}`).destroy()
            chartRef.current = new Chart(`ProgressCourse-${item.emIdx}`, {
                type: 'doughnut',
                data: {
                    labels: ["Course"],
                    datasets: [
                        {
                            data: [totalValue1 - progressValue1, progressValue1],
                            backgroundColor: [
                                "#F0F2F6",
                                "#1847ED"
                            ],
                            cutout: "75%",
                        },
                    ],
                },
                options: {
                    plugins: {
                        legend:{
                            display: false
                        },
                        tooltip: {
                            enabled: false
                        },
                    },
                }
            });
        }
    }, [item]);

    return (
        <div className="chart">
            <canvas className={`charts`} id={`ProgressCourse-${item?.emIdx}`}></canvas>
            <span className="rate">{item?.answerCount ?? 0}/{item?.contentCount ?? 0}</span>
        </div>
    )
}

export default M_MainEduStatusChartItem;
import React, {useEffect, useRef, useState} from "react";

interface ProRequestSubmitKeywordInputProps {
    changeKeywordEvent: (keyword: string) => void;
}

const ProRequestSubmitKeywordInput = (props: ProRequestSubmitKeywordInputProps) => {
    const {changeKeywordEvent} = props;
    const [keywordArr, setKeywordArr] = useState<string[]>([])
    const [inputValue, setInputValue] = useState<string>('')


    useEffect(() => {
        changeKeywordEvent(keywordArr.join(','))

    }, [keywordArr]);

    const checkInputLength = () => {
        return inputValue.length <= 14
    }
    const checkCurrentKeywordNum = () => {
        return keywordArr.length < 10
    }
    const checkIncludeKeyword = () => {
        return keywordArr.includes(inputValue)
    }
    const checkInputIsEmpty = () => {
        return inputValue === ''
    }

    const checkKeywordInput = () => {
        return checkInputLength() && checkCurrentKeywordNum()
    }

    const checkValid = () => {
        return checkKeywordInput() && !checkInputIsEmpty() && !checkIncludeKeyword()
    }

    const btnStyle = () => {
        return checkValid() ? {color: "#1847ed"} : {}

    }

    const getErrorMessage = () => {
        if (!checkInputLength()) {
            return '14글자 이내로 입력해주세요'
        }

        if (!checkCurrentKeywordNum()) {
            return '기존에 등록한 키워드를 삭제 후 등록해주세요'
        }

        return ''
    }

    const activeEnter = (e: any) => {
        if (e.keyCode === 13) {
            addKeywordAndRemoveInput()
        }
    }

    const addKeyword = (value: string) => {
        setKeywordArr((prev) => {
            const arr = [...prev]
            arr.push(value)

            return arr
        })
    }

    const addKeywordAndRemoveInput = () => {
        if (checkValid()) {
            addKeyword(inputValue)
            setInputValue('')
        }
    }

    const removeKeyword = (value: string) => {
        setKeywordArr((prev) => {
            const arr = [...prev]
            return arr.filter((v) => value != v)
        })
    }

    return (
        <div className="form-item">
            <div className="form-title">
                <strong className="tit">강점 역량 키워드 *</strong>
            </div>
            <div
                className={checkKeywordInput() ? "inp-btn-bind type-keyword" : "inp-btn-bind type-keyword error"}>
                <input type="text" name="" id="" className="inp-text"
                       placeholder="키워드를 입력한 후, 엔터 혹은 우측의 등록 버튼을 클릭해 주세요"
                       value={inputValue}
                       onChange={e => {
                           if (e.target.value != inputValue) {
                               setInputValue(e.target.value)
                           }
                       }
                       }
                       onKeyDown={activeEnter}/>
                <button type="button" className="btn gray"
                        style={btnStyle()}
                        onClick={e => {
                            addKeywordAndRemoveInput()
                        }}>
                    등록
                </button>
            </div>

            <div className="error-text">
                {
                    getErrorMessage()
                }

            </div>
            {
                keywordArr.length > 0 && (
                    <div className="keyword-list">
                        {
                            keywordArr.map(((value, index) => {
                                return (
                                    <span className="badge type1 blue" key={index + value}>
                                        {'#' + value}
                                        <button type="button" className="btn-remove" onClick={e => {
                                            removeKeyword(value)
                                        }}></button>
                                    </span>
                                )
                            }))
                        }
                    </div>
                )
            }
            <div className="guide-text">
                * 강점역량 관련 직원들에게 알려줄 수 있는 구체적인 스킬을 한 단어의 형태로 적어주시면 됩니다.<br/>
                (ex. 2일5일 / 캐리오버 / 분양매출 / 원팀소통 / 고객조사왕 / 업체소통 / 수량설계 / 재고관리 / 폐기량관리 등등)
            </div>
        </div>
    )

}

export default ProRequestSubmitKeywordInput
import React, {useEffect, useRef, useState} from "react";
import Swal from "sweetalert2";
import {BoardVO} from "../../../core/board/board.vo";
import {useNavigate} from "react-router-dom";
import MemberUtils from "../../../common/utils/member.utils";
import MypageService from "../../../core/mypage/mypage.service";
import FileUploader from "./FileUploader";
import StringUtils from "../../../common/utils/string.utils";

interface ProProfileProps {
    receiver: string,
    receiverKey: string,
    boardVO: BoardVO | null,
    fidxs?: string,
    updateEvent? : () =>void
}

const ProEduTalkModal = (props: ProProfileProps) => {
    const navigate = useNavigate();
    const [boardVO, setBoardVO] = useState(props.boardVO);
    const [fidxs, setFidxs] = useState<string>(props.fidxs ?? "")
    const [title, setTitle] = useState<string>("")
    const [content, setContent] = useState<string>("")
    const boardKey = "one_to_one_inquiry";

    const closeBtn = useRef<HTMLButtonElement>(null);
    const fileInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
        // CommonJS.inputFile()
    }, []);

    useEffect(() => {
        setBoardVO(props.boardVO)
        setTitle(props.boardVO?.title ?? "")
        setContent(StringUtils.br2nl(props.boardVO?.contentHtml ?? ""))
        setFidxs(props.fidxs ?? "")

    }, [props]);



    const submitEduTalk = async () => {
        const bidx = boardVO?.idx.toString() ?? "0"
        let params = {
            boardKey: boardKey,
            title: title,
            content: StringUtils.nl2br(content) ,
            coCode: MemberUtils.getSessionVO()?.coCode ?? "",
            receiver: props.receiver,
            receiverKey: props.receiverKey,
            fidxs: fidxs
        }

        if (!boardVO){
            await MypageService.boardOneSave(params, showComplete)
        } else {
            await MypageService.boardOneUpdate(bidx, params, showComplete)
        }

    }

    const handleUploadAttachFile = (idxs:string) => {
        setFidxs(idxs)
    }

    const checkTextFill = () => {
        return title.length > 0 && content.length > 0

    }



    const showComplete = () => {
        fileInput.current && (fileInput.current.value = '')
        setTitle("")
        setContent("")
        setFidxs("")
        closeBtn.current && closeBtn.current.click()
        props.updateEvent && props.updateEvent()
        Swal.fire({
            title: "에듀톡 문의하기",
            html: `
            문의가 완료되었습니다!<br>
            답변이 등록되면 메일로 알려드릴게요.<br><br>
            진행상황은 [사내고수 > 나의 에듀톡] 에서 확인해보세요
        `,
            showCancelButton: true,
            confirmButtonText: "에듀톡 바로가기",
            cancelButtonText: "닫기",
        }).then(result =>{
            if (result.isConfirmed){
                navigate("/pro/talk?isReceive=false")
            }
        })

    }

    return (
        <div className="modal fade" id="qnaModal" tabIndex={-1} aria-labelledby="modalLabel"
             aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="modalLabel">에듀톡 문의하기</h3>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close" ref={closeBtn}></button>
                    </div>
                    <div className="modal-body">
                        <p className="desc">
                            고수에게 추가로 문의하거나 요청하고싶은 내용이 있으신가요?<br/>
                            내용을 작성해주시면 사내고수가 확인 후 도움을 드릴게요. 부담갖지 마시고 물어보세요.
                        </p>
                        <div className="form-list">
                            <div className="form-item">
                                <label className="lab">제목</label>
                                <div className="inp-bind fill">
                                    <input type="text" name="" id="" className="inp-text"
                                           value={title}
                                           onChange={e=>{
                                               if (e.target.value.length <= 25){
                                                   setTitle(e.target.value)
                                               }
                                           }}
                                           placeholder="제목을 25자 이내로 입력해주세요"/>
                                </div>
                            </div>
                            <div className="form-item">
                                <label className="lab">내용</label>
                                <div className="inp-bind">
                                    <textarea name="" id="" cols={30} rows={5} className="inp-text"
                                              placeholder="내용을 300자 이내로 입력해주세요"
                                              value={content}
                                              onChange={e=>{
                                                  if (e.target.value.length <= 300) {
                                                      setContent(e.target.value)
                                                  }
                                              }}
                                              ></textarea>
                                </div>
                            </div>
                            <div className="form-item">
                                <label className="lab">첨부파일</label>
                                <div className="register modal-file">
                                <FileUploader key={fidxs} params={{
                                    domId: `boardVO-attach`,
                                    domText: '첨부파일을 업로드해주세요',
                                    target: `board`,
                                    fidxs: fidxs,
                                    maxFileSize: 100,
                                    maxFileCount: 1,
                                    updateHandler: (_target: string, _files: string) => {
                                        console.log(_target + " ::::: " + _files)
                                        handleUploadAttachFile(_files);
                                    }
                                }}/>
                                </div>
                                <div className="guide-text">
                                    파일크기 : 100MB이하 / 1개 파일만 등록 가능합니다. 여러개일 경우 ZIP파일로 압축하여 업로드해주세요
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="button-area">
                            <button type="button" className={`btn lg ${checkTextFill() ? '' : 'disabled'}`}
                                    onClick={e=>{
                                        if(checkTextFill()) {
                                            submitEduTalk()
                                        }
                                    }}>제출하기</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProEduTalkModal
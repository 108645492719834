import React, {useEffect, useState} from "react";
import M_ProEduTalkReceiver from "./receiver/M_ProEduTalkReceiver";
import {useNavigate, useParams} from "react-router-dom";
import M_ProEduTalkWriter from "./writer/M_ProEduTalkWriter";
import ToastUtils from "../../../../../common/utils/toast.utils";
import ProService from "../../../../../core/pro/pro.service";
import {BoardVO, CommentVO, DefaultBoardVO} from "../../../../../core/board/board.vo";
import MemberUtils from "../../../../../common/utils/member.utils";

const M_ProEduTalkDetail = () => {
    const navigate = useNavigate();

    const {bidx} = useParams()
    const [boardVO, setBoardVO] = useState<BoardVO>(DefaultBoardVO)
    const [commentList, setCommentList] = useState<CommentVO[]>([])
    const [isReceiver, setIsReceiver] = useState<boolean>(false)
    useEffect(() => {
        getDetail()

    }, []);

    const getDetail = async () => {
        const successHandler = (result: any) => {
            let newBoard = result.data.board;
            setBoardVO(newBoard)
            setCommentList(result.data.comment)
            setIsReceiver(newBoard.receiverKey === MemberUtils.getLoginId())

        }

        const failHandler = (result: any) => {
            ToastUtils.show("잘못된 접근입니다.")
            navigate(-1)
        }

        bidx && await ProService.inquiryOneGet(bidx, successHandler, failHandler)
    }


    return (
        isReceiver
            ? <M_ProEduTalkReceiver boardVO={boardVO}
                                    commentList={commentList}
                                    updateEvent={getDetail}/>
            : <M_ProEduTalkWriter boardVO={boardVO}
                                  commentList={commentList}
                                  updateEvent={getDetail}/>
    )
}

export default M_ProEduTalkDetail
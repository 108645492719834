import React from 'react';
import useHeader from "../../../common/hook/useHeader";
import {HeaderBtn} from "../../../core/common/header";
import useFooter from "../../../common/hook/useFooter";
import SearchInput from "../../pc/search/SearchInput";
import M_SearchInput from "./M_SearchInput";
import {useNavigate, useParams} from "react-router-dom";

const M_Search: React.FC = () => {
    useHeader({
        type: "content",
        title: "검색",
        buttons: [HeaderBtn.Back]
    })

    useFooter({
        isHidden: false
    })

    const navigate = useNavigate()

    const updateSearchText = (searchText:string) => {
        navigate("/search/" + searchText, { replace: true });
    }


    return (
        <main id="main" className="content search-result">
            <div className="search-area">
                <div className="inner">
                    <M_SearchInput initText={""}
                                   updateSearchText={updateSearchText}
                                   placeholderText={"찾고 싶은 자료를 입력해주세요"}
                                   cancelHandler={()=>{}}
                    />
                </div>
            </div>
            <section className="tip-section">
                <div className="inner">
                    <strong>Tip!</strong>
                    <p>
                        직무, 역량, 혹은 관심있는 키워드를 입력해주세요<br/>
                        ex) EBG, 기네스, 승진
                    </p>
                </div>
            </section>
        </main>
    )
}

export default M_Search
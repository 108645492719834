import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import MemberUtils from "../../../common/utils/member.utils";
import ElandUtils from "../../../common/utils/eland.utils";

const Snb : React.FC = () => {
    const links = [
        {middle: '/board/list/', key: 'ebg_story', label: 'EBG 성공스토리' },
        {middle: '/board/list/', key: 'ebg_common', label: 'EBG 지식콘텐츠' },
        {middle: '/board/list/', key: 'pro_knowledge', label: '사내고수의 지식' },
        {middle: '/board/list/', key: 'job_data', label: '사내고수의 자료실' },
        // { key: 'brand_story', label: '브랜드스토리' },
        {middle: '/board/list/', key: 'leadership', label: '리더십' },
        {middle: '/board/list/', key: 'es', label: 'ES' },
        {middle: '/board/story/', key: 'use_story', label: '적용스토리' },
    ];

    let {boardKey} = useParams();
    const [boardNameList, setBoardNameList] = useState<{boardKey:string, boardName:string}[]>([])

    useEffect(() => {
        getBoardNameList()
    }, []);

    const getBoardNameList = async () => {
        let list = await ElandUtils.getBoardNameList();
        setBoardNameList(list)
    }

    const getBoardName = (boardKey:string)=>{
        let find = boardNameList.find(board=> board.boardKey === boardKey);
        return find ? find.boardName : boardKey
    }

    return (
        <nav className="snb">
            <strong className="tit">지식콘텐츠</strong>
            <ul className="snb-list">
                {
                    links.map((link, index) => (
                        <li key={index} className={boardKey === `${link.key}` ? "active" : ""}>
                            <Link to={link.middle + link.key}>{getBoardName(link.key)}</Link>
                        </li>
                    ))
                }
                {
                    (MemberUtils.isPro() || MemberUtils.isSemiPro()) &&
                    <li className={location.pathname === '/board/_/0/_/form' ? "active" : ""}>
                        <Link to={`/board/_/0/_/form`}>지식등록</Link>
                    </li>
                }
            </ul>
        </nav>
    );
}

export default Snb;
import React from 'react';

interface StarRatingProps {
    totalStars: number;
    rating: number;
}

const StarRating: React.FC<StarRatingProps> = ({ totalStars, rating }) => {
    return (
        <>
            {Array.from({ length: totalStars }, (_, index) => (
                <span key={index} className={index < rating ? "check" : ""}>
          ⭐
        </span>
            ))}
        </>
    )
}

export default StarRating;
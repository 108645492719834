import MypageNav from "../common/MypageNav";
import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
    BoardFileVO,
    BoardVO,
    DefaultBoardInfoVO,
    DefaultBoardVO
} from "../../../../core/board/board.vo";
import APIs from "../../../../common/apis/APIs";
import moment from "moment";
import FileUtils from "../../../../common/utils/file.utils";
import SummernoteIframe from "../../common/SummernoteIframe";
import MemberUtils from "../../../../common/utils/member.utils";
import MypageService from "../../../../core/mypage/mypage.service";
import ElandUtils from "../../../../common/utils/eland.utils";
import ToastUtils from "../../../../common/utils/toast.utils";
import {BoardInfoVO} from "../../../../core/board/BoardInfo.vo";
import BoardDetailComment from "./BoardDetailComment";

const MypageBoardDetail = () => {
    const navigate = useNavigate()
    const {bidx,boardKey} = useParams()
    const [boardVO, setBoardVO] = useState<BoardVO>(DefaultBoardVO)
    const [isRecommend, setIsRecommend] = useState<boolean>(false)
    const [isMe, setIsMe] = useState<boolean>(false)
    const [boardInfo, setBoardInfo] = useState<BoardInfoVO>(DefaultBoardInfoVO)

    useEffect(() => {
        getDetail()

    }, []);

    const getDetail = async () => {
        const successHandler = async (result: any) => {
            const board = result.data.board;
            board.skillName = await ElandUtils.convertJobSkillCodesToNames(board.skillCode);
            setBoardVO(board);
            setIsRecommend(result.data.isRecommend);
            setIsMe(board.writerKey === MemberUtils.getLoginId())
            setBoardInfo(board.boardInfoVO)
        }

        MypageService.boardOneGet(bidx ?? "0", successHandler)
    }


    const deleteBoard = async (e:any) => {
        e.preventDefault()
        if (isMe && confirm("정말로 삭제하시겠습니까?")){
            let params = {
                bidx: boardVO.idx,
            }

            const successHandler = () => {
                ToastUtils.show("삭제되었습니다.")
                navigate(-1)
            }

            await MypageService.boardOneDelete(params, successHandler)
        }
    }

    const updateBoard = (e:any) =>  {
        e.preventDefault()

        if (isMe){
            navigate('/mypage/board/' + boardKey + "/" + bidx + '/form', {
                state : {
                    board: boardVO,
                    fidxs: boardVO.attachList?.map(value => value.fidx).join(",") ?? "",
                    thumbFidxs: boardVO.thumbIdx ? boardVO.thumbIdx.toString() : "",
                    cateCode: boardVO.boardInfoVO.cateCode,
                    isFile:boardInfo.isFile,
                    isForm:boardInfo.isForm,
                    formText:boardInfo.formText
                }
            })
        }
    }

    const doRecommend = async (e:any, isSave:boolean) => {
        e.preventDefault()

        let params = {
            bidx: boardVO.idx,
            isSave: isSave,
        }

        const successHandler = () => {
            setIsRecommend(isSave)
            const newBoard = {...boardVO}
            newBoard.likeCount = isSave ? ++newBoard.likeCount : --newBoard.likeCount
            setBoardVO(newBoard)
        }

        await MypageService.boardOneRecommend(params, successHandler)
    }

    const downloadFile = async (e: any, fileVO: BoardFileVO) => {
        e.preventDefault()
        window.location.href = `${APIs.BOARD_FILES_DOWN}${fileVO.fidx}`
    }

    const isGalleryOrClassic = () => {
        return (boardInfo.boardMode === 'gallery' || boardInfo.boardMode === 'classic')
    }


    return (
        <main className="content detail nav-layout">
            <section className="detail-section">
                <div className="inner">
                    <div className="detail-title">
                        {
                            isGalleryOrClassic()
                            && (
                                <div className="breadcrumb">
                                    <Link to={"/mypage/management"}>마이페이지</Link>
                                    <Link to={"/mypage/board/" + boardKey}>{boardVO.boardInfoVO?.boardName ?? ""}</Link>
                                </div>
                            )
                        }
                        <div className="cate">
                            <a href="#" onClick={e=>e.preventDefault()}>{boardVO.cateName}</a>
                        </div>
                        <h2 className="tit">{boardVO.title}</h2>
                        <div className="info">
                            <span className="date">{moment(boardVO.createDate).format("YYYY-MM-DD")}</span>
                            <div className="rating-wrap">
                                {
                                    isGalleryOrClassic() && (<span className="like">{boardVO.likeCount}</span>)
                                }
                                <span className="hits">{boardVO.views}</span>
                            </div>
                        </div>
                        <div className="writer">
                            <div className="image-wrap">
                                <img src={FileUtils.getFileUrl(boardVO.writerInfo?.profileSrc ?? "")}
                                     onError={FileUtils.onErrorImg}
                                     alt="프로필 이미지" className="img-profile"/>
                            </div>
                            <div className="text-wrap">
                                <span className="name"><b>{boardVO.writer}</b></span>
                                <span className="job">{boardVO.writerInfo?.teamName}</span>
                            </div>
                        </div>
                        {
                            isMe && (
                                <div className="util">
                                    <a href="#" className="btn-modify" onClick={updateBoard}><span className="blind">수정하기</span></a>
                                    <a href="#" className="btn-delete" onClick={deleteBoard}><span className="blind">삭제하기</span></a>
                                </div>
                            )
                        }
                    </div>
                    <div className="detail-content">
                        {
                            boardVO.attachList && boardVO.attachList.length > 0 && (
                                <ul className="attach-list">
                                    {
                                        boardVO.attachList.map(value => {
                                            return (
                                                <li key={value.fidx}>
                                                    <b className="tit">첨부파일 </b>
                                                    <a href="#" onClick={e=>downloadFile(e, value)}>
                                                        <span className="path">{value.filename}</span>
                                                    </a>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            )
                        }

                        <div className="text-area">
                            <SummernoteIframe mode={"view"} htmlCode={boardVO.contentHtml}/>
                        </div>
                        {
                            isGalleryOrClassic() && (
                                <div className="like-button">
                                    <a href="#" className="btn-like" onClick={e=> doRecommend(e, !isRecommend)}>
                                        <div>
                                            <i className={isRecommend ? "ico-like" : "ico-like deactivate"}></i>
                                            <span className={isRecommend ? "count" : "count deactivate"}>{boardVO.likeCount}</span>
                                        </div>
                                    </a>
                                </div>
                            )
                        }
                    </div>
                </div>
            </section>
            {
                boardInfo.isComment && (<BoardDetailComment/>)
            }
            <MypageNav currentMenu={boardVO.boardKey}/>
        </main>
    )
}

export default MypageBoardDetail
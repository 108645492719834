import React, {useEffect, useRef, useState} from "react";
import BoardService from "../../../core/board/board.service";
import {useLocation, useParams, useSearchParams} from "react-router-dom";
import {BoardVO} from "../../../core/board/board.vo";
import {BoardInfoVO} from "../../../core/board/BoardInfo.vo";
import {HWMap} from "../../../core/common/common.vo";
import {PageUtils} from "../../../common/utils/page.utils";
import ElandUtils from "../../../common/utils/eland.utils";
import StringUtils from "../../../common/utils/string.utils";
import M_TabMenu from "./M_TabMenu";
import {HeaderBtn} from "../../../core/common/header";
import useHeader from "../../../common/hook/useHeader";
import M_Gallery from "./listType/M_Gallery";
import M_Basic from "./listType/M_Basic";
import M_NullItem from "./item/M_NullItem";
import M_SearchInputAndFilter from "../common/M_SearchInputAndFilter";
import useFooter from "../../../common/hook/useFooter";
import M_InfinityScroll from "../common/M_InfinityScroll";
import axiosCaller from "../../../common/utils/axios.caller";
import APIs from "../../../common/apis/APIs";
import SwalUtils from "../../../common/utils/swal.utils";

export const M_BoardList : React.FC = () => {

    const boardService = new BoardService();
    const location = useLocation();

    let {boardKey} = useParams();
    const [query, setQuery] = useSearchParams();

    const [boardCount, setBoardCount] = useState(0);
    const [boardList, setBoardList] = useState<BoardVO[]>([]);
    const [boardInfoVO, setBoardInfoVO] = useState<BoardInfoVO>();
    const [boardMode, setBoardMode] = useState("gallery");
    const [params, setParams] = useState<HWMap>(new PageUtils())
    const [sortState, setSortState] = useState("new");
    const [filterParams, setFilterParams] = useState(
        {
            skillCode: query.get("skillCode") ?? "",
            jobCode: query.get("jobCode") ?? "",
            coCode: query.get("coCode") ?? ""
        }
    );
    const scrollRef = useRef<{ reload: () => void, saveScroll:(index:number|null)=>void }>(null);

    useHeader({
        type: 'sub',
        title: '지식콘텐츠',
        buttons: [HeaderBtn.Menu],
    });
    useFooter({
        isHidden: false
    });

    const fetchList = async (makeParamFunc:(params:HWMap, size:number) => HWMap) => {
        let fetchParams = makeParamFunc({...params, ...filterParams}, params.size)
        console.log("⭐fetchParams", fetchParams);
        let result = await axiosCaller.get(APIs.BOARDS + "/searchBoards", fetchParams)
        if (result.status === "SUCCESS"){
            const updatedBoardList = await ElandUtils.convertJobSkillNames4BoardList(result.data.list);
            setBoardCount(result.data.totalCount)
            return updatedBoardList
        }

        return []
    }

    // 페이지 업데이트
    const updatePages = (newPage: number) => {
        setParams(prevParams => ({
            ...prevParams,
            page: newPage
        }));
    }

    // 직무 선택 필터
    const updateJobCode = (newJobCode: string) => {
        scrollRef.current && scrollRef.current.reload()
        setFilterParams((preParams) => ({
            ...preParams,
            jobCode: newJobCode
        }));
    }

    const updateCode = (coCode: string, jobCode: string, skillCode: string) => {
        scrollRef.current && scrollRef.current.reload()
        setFilterParams((preParams) => ({
            ...preParams,
            jobCode: jobCode,
            skillCode: skillCode,
            coCode: coCode
        }));
    }

    // 검색창
    const updateSearchText = (searchText: string) => {
        scrollRef.current && scrollRef.current.reload()
        setParams((preParams) => ({
            ...preParams,
            searchText: searchText
        }));
    }

    // 최신순 / 조회순
    const handleSortClick = (e: any, sort: string) => {
        e.preventDefault()
        scrollRef.current && scrollRef.current.reload();
        setSortState(sort)
        setParams(prevParams => ({
            ...prevParams,
            sort: `${sort == 'new' ? 'createDate,DESC' : 'views,DESC'}`
        }));
    }

    const updateParams = () => {
        query.set("page", params.page.toString())
        // query.set("sort", params.sort.toString())
        query.set("searchText", params.searchText)

        isFilterBoard('jobCode') && query.set('jobCode', filterParams.jobCode ?? "")
        isFilterBoard('skillCode') && query.set('skillCode', filterParams.skillCode ?? "")
        isFilterBoard('coCode') && query.set('coCode', filterParams.coCode ?? "")

        setQuery(query)
    }

    const isFilterBoard = (codeMenu:string) => {
        if (codeMenu === 'jobCode' || codeMenu === 'coCode'){
            return boardKey == 'ebg_story' || boardKey == 'pro_knowledge' || boardKey == 'job_data'
        }
        if (codeMenu === 'skillCode'){
            return boardKey == 'ebg_story' || boardKey == 'pro_knowledge'
        }

        return false
    }

    const getPageSize = () => {
        if (boardMode === "gallery") {
            return 9;
        } else if (boardMode === "basic" || boardMode === "classic") {
            return 10;
        } else {
            return parseInt(query.get('size') ?? "9");
        }
    };

    const initPageParams = () => {
        setSortState("new")
        setParams({
            page: parseInt(query.get('page') ?? "0"),
            size: getPageSize(),
            sort: 'createDate,DESC',
            searchText: query.get("searchText") ?? "",
            boardKey: boardKey ?? "",
            cateCode: "",
            statusCode: ""
        })
        setFilterParams({
            jobCode: query.get('jobCode') ?? "",
            skillCode: query.get('skillCode') ?? "",
            coCode: query.get('coCode') ?? "",
        })

        if(scrollRef.current) {
            scrollRef.current.reload();
        }
    }

    const updateFilterCount = async () => {
        if (!StringUtils.isEmpty(boardKey) && isFilterBoard("coCode")) {
            let params = {boardKey: boardKey}
            let result = await boardService.getBoardFilterCount(params);
            if (result.status == "SUCCESS"){
                // setCodeCountMap(result.data);
            }
        }

    }


    const getBoardInfo = async () => {
        let result = await BoardService.getBoardInfoOne({boardKey});
        if (result.status === 'ok'){
            setBoardInfoVO(result.boardInfoVO);
            setBoardMode(result.boardInfoVO.boardMode)
            initPageParams();
        }
    }


    // 메인 페이지에서 jobcode를 통해 진입 했을 때
    useEffect(() => {
        updateJobCode(location.state?.jobcode)

    }, [location.state?.jobcode]);

    useEffect(() => {
        if(boardKey) {
            if (ElandUtils.isKnowledgeBoard(boardKey)) {
                getBoardInfo();
            } else {
                SwalUtils.text({
                    title : "안내",
                    message : "잘못된 접근입니다.",
                    isCancel : false,
                    confirmOptions : {
                        text : "확인",
                        eventHandler : () => window.location.href = ('/board/list/ebg_story')
                    },
                })
            }
        }
    }, [boardKey]);


    useEffect(() => {
        updateParams()

    }, [params, filterParams])

    return (
        <main id="main" className="content tab-layout">

            {/* 상단 탭 메뉴 */}
            <M_TabMenu />

            <div className="search-area border-10">
                <div className="inner">
                    <M_SearchInputAndFilter updateSearchText={updateSearchText}
                                            placeholderText={"검색어를 입력해주세요"}
                                            initText={params.searchText}
                                            coCode={filterParams.coCode}
                                            jobCode={filterParams.jobCode}
                                            skillCode={filterParams.skillCode}
                                            updateCode={updateCode}
                                            isCancelButton={!(
                                                isFilterBoard("coCode") ||
                                                isFilterBoard("jobCode") ||
                                                isFilterBoard("skillCode")
                                            )}/>
                </div>
            </div>
            <section className="list-section">
                <div className="inner">
                    <div className="board-util">
                        <div className="util-group">
                            <div className="board-total">
                                총 <span>{boardCount}</span> 개
                            </div>
                            <div className="board-sort">
                                <div className="sort-list">
                                    <a href="#"
                                       className={`${sortState == 'new' ? 'active' : ''}`}
                                       onClick={(e) => handleSortClick(e, 'new')}
                                    >최신순</a>
                                    <a href="#"
                                       className={`${sortState == 'view' ? 'active' : ''}`}
                                       onClick={(e) => handleSortClick(e, 'view')}
                                    >조회순</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="board-list">

                        {
                            boardList.length > 0 ?
                                <>
                                    {boardMode === "gallery" && <M_Gallery paramMap={{boardList}}/>}
                                    {boardMode === "basic" && <M_Basic paramMap={{boardList: boardList, boardInfo: boardInfoVO}}/>}
                                    {boardMode === "classic" && <M_Basic paramMap={{boardList: boardList, boardInfo: boardInfoVO}}/>}
                                </>
                                : <M_NullItem/>
                        }

                    </div>

                    {
                        boardInfoVO &&
                        <M_InfinityScroll<BoardVO>
                            queryKey={"boardList"}
                            update={fetchList}
                            defaultSize={params.size}
                            totalCount={boardCount}
                            getList={val=>{setBoardList(val)}}
                            scrollRef={scrollRef}
                        />
                    }

                </div>
            </section>
        </main>
    );
};

export default M_BoardList;
import React from 'react';

interface MainSystemItemProps {
    // point : 포인트 제도
    // master : 사내 고수 제도
    type: string
}

const MainSystemItem = (props: MainSystemItemProps) => {
    const { type } = props;

    return (
        <li className={type}>
            <strong className="tit">{ type == "point" ? "포인트 제도" : "사내 고수 제도"}</strong>
            <p className="desc" dangerouslySetInnerHTML={
                {__html: type == "point" ?
                    "학습 정도와 성취율에 따라서 주어지는 포인트!<br/>상세한 내용을 안내해 드릴까요?" :
                    "사내고수를 통해 얻는 더욱 깊은 지식<br/>이랜드에서의 성취를 함께 나누어 주시겠어요?"
                }
            }></p>
            <a href={ type == "point" ? "/mypage/point" : "/pro"} className="btn red lg">상세히 보기</a>
        </li>
    )
}

export default MainSystemItem;
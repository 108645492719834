import React, {useEffect, useRef} from "react";
import {BoardVO} from "../../../../core/board/board.vo";
import DateUtils from "../../../../common/utils/date.utils";
import APIs from "../../../../common/apis/APIs";
import CodeUtils from "../../../../common/utils/code.utils";
import {SessionVO} from "../../../../core/common/session.vo";
import SummernoteIframe, {SummernoteIframeHandle} from "../../../pc/common/SummernoteIframe";
import FileUtils from "../../../../common/utils/file.utils";
import {Link, useParams} from "react-router-dom";
import ElandUtils from "../../../../common/utils/eland.utils";
import StringUtils from "../../../../common/utils/string.utils";
import SwalUtils from "../../../../common/utils/swal.utils";
import MemberUtils from "../../../../common/utils/member.utils";


const BasicDetail: React.FC<{boardVO: BoardVO, sessionVO: SessionVO | null, tags: string[]}> = ({boardVO, sessionVO, tags}) => {

    let {boardKey, bidx, boardTitle} = useParams();

    const [jobName, setJobName] = React.useState<string>("");
    const [coName, setCoName] = React.useState<string>("");
    const iframeRef = useRef<SummernoteIframeHandle>(null);

    // img 태그에 오른쪽 클릭 방지 이벤트를 추가하는 함수
    const applyRightClickProtection = (doc: Document) => {
        const preventRightClick = (img: HTMLImageElement) => {
            img.addEventListener('contextmenu', (event: MouseEvent) => {
                event.preventDefault();
            });
        };

        // 이미 존재하는 img 태그에 오른쪽 클릭 방지 이벤트 추가
        const existingImages = doc.querySelectorAll<HTMLImageElement>('img');
        existingImages.forEach(preventRightClick);

        // MutationObserver를 사용하여 새로운 img 태그 감지 및 이벤트 추가
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'childList') {
                    mutation.addedNodes.forEach((node) => {
                        if (node instanceof HTMLImageElement) {
                            preventRightClick(node);
                        } else if (node instanceof HTMLElement) {
                            // 하위 노드들 중 img 태그가 있는 경우도 처리
                            node.querySelectorAll<HTMLImageElement>('img').forEach(preventRightClick);
                        }
                    });
                }
            });
        });

        observer.observe(doc.body, {
            childList: true,
            subtree: true,
        });

        return observer;
    };

    const deleteOne = (e: any) => {
        e.preventDefault()
        SwalUtils.text({
            title : `안내`,
            message : `지식 콘텐츠 삭제가 불가능합니다.<br />삭제가 필요하신 경우 그룹 인재원에게 소통해주세요.`,
            isCancel : false,
            confirmOptions : {
                text : "확인"
            },
        })
    }

    useEffect(() => {
        // iframe이 로드된 후 처리
        const iframe = iframeRef.current?.getIframe() as HTMLIFrameElement;
        if (iframe) {
            iframe.onload = () => {
                const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
                if (iframeDoc) {
                    applyRightClickProtection(iframeDoc);
                }
            };
        }

        return () => {
            if (iframe) {
                const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
                if (iframeDoc) {
                    iframeDoc.querySelectorAll('img').forEach((img) => {
                        img.removeEventListener('contextmenu', (event: MouseEvent) => event.preventDefault());
                    });
                }
            }
        };
    }, []);

    useEffect(() => {
        if(boardVO.idx > 0) {
            (async() => {
                const jobCodeName = await ElandUtils.convertJobSkillCodesToNames(boardVO.jobCode);
                setJobName(jobCodeName);

                const coNames = await CodeUtils.getCoCodeNames(boardVO.coCode)
                setCoName(coNames);

                setTimeout(()=>{
                    const images = document.querySelectorAll('img');

                    images.forEach((img) => {
                        img.removeEventListener('contextmenu', (event) => {
                            event.preventDefault();
                        });
                    });

                    images.forEach((img) => {
                        img.addEventListener('contextmenu', (event) => {
                            event.preventDefault();
                        });
                    });
                }, 0)
            })()
        }
    }, [boardVO]);

    useEffect(() => {

    }, [boardVO.contentHtml]);


    return (
        <section className="detail-section">
            <div className="inner">
                <div className="detail-title">
                        <span className="cate">
                            <a href="#">{ coName }</a>
                            <a href="#">{ jobName }</a>
                        </span>
                    <h2 className="tit">{ boardVO.title }</h2>
                    <div className="info">
                        <span className="date">{ DateUtils.formatDate(boardVO.createDate) }</span>
                        <div className="rating-wrap">
                            {/*<span className="like">{ boardVO.likeCount }</span>*/}
                            <span className="hits">{ boardVO.views }</span>
                        </div>
                    </div>
                    {
                        boardVO.writerInfo &&
                        <div className="writer">
                            <div className="image-wrap">
                                <img src={FileUtils.getFileUrl(boardVO?.writerInfo?.profileSrc ?? "")}
                                     onError={FileUtils.onErrorImg}
                                     alt="프로필 이미지"
                                     className="img-profile"/>
                            </div>
                            <div className="text-wrap">
                            <span className="name">
                                <b>{boardVO.writer}</b>
                                {MemberUtils.isProRateCode(boardVO.writerInfo.rateCode) && " 사내고수"}
                            </span>
                                <span className="job">{boardVO.writerInfo.coInfoVO?.coName}</span>
                            </div>
                        </div>
                    }


                    {/*작성자만 수정 / 삭제 가능*/}
                    {
                        sessionVO?.coEmailId == boardVO.writerKey &&
                        <div className="util">
                            <Link to={`/board/${boardKey}/${bidx}/${StringUtils.titleUrlFilter(boardTitle ?? "")}/form`} className="btn-modify">
                                <span className="blind">수정하기</span>
                            </Link>
                            <a href="#" className="btn-delete" onClick={(e) => deleteOne(e)}>
                                <span className="blind">삭제하기</span>
                            </a>
                        </div>
                    }

                </div>
                <div className="detail-content detail-bottom">
                    <ul className="attach-list">
                        {
                            boardVO.attachList?.map((file, index) => (
                                file.fileMode === 'attach' && file.fidx != boardVO.thumbIdx &&
                                <li key={index} className="attach">
                                    <b className="tit">첨부파일 </b>
                                    <a href={`${APIs.BOARD_FILES_DOWN}${file.fidx}`}>
                                        <span className="path">{file.filename}</span></a>
                                </li>
                            ))
                        }
                    </ul>
                    <div className="text-area">
                        <SummernoteIframe mode={"view"} ref={iframeRef} htmlCode={boardVO.contentHtml}/>
                    </div>


                    {
                        tags &&
                        <div className="tag-list">
                            {tags?.map((tag, index) => (
                                <React.Fragment key={index}>
                                    <Link to={`/search/${StringUtils.deleteSpecial(tag)}`}>#{tag}</Link>
                                    {index < tags.length - 1 ? ', ' : ''}
                                </React.Fragment>
                            ))}
                        </div>
                    }

                </div>
            </div>
        </section>
    );
}

export default BasicDetail;
import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import CommonJS from "../../../common/common";
import CommonMobileJS from "../../../common/common_m";

const M_ScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        CommonMobileJS.all()

    }, [location]);

    return null;
};

export default M_ScrollToTop

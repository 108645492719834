import WebUtils from "./web.utils";


const webUtils:WebUtils = new WebUtils()
const PAGE_SIZE = 10;




export interface PageParams {
	page: number;
	size: number;
	sort: string;
	searchText: string;
	statusCode: string;
	cateCode: string;
	boardKey: string;
}


export class PageUtils implements PageParams{

	constructor(
		public page: number = 0,
		public size: number = 10,
		public sort: string = "createDate,DESC",
		public totalCount: number = 0,
		public searchText: string = "",
		public statusCode: string = "",
		public cateCode: string = "",
		public boardKey: string = ""
	) {}
}
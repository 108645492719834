import {CodeVO, DefaultCodeVO} from "../common/code.vo";

export interface CompanyAccountVO {
    caidx: number;
    coCode: string;
    coEmpNo?: string;
    coEmailId?: string;
    passwd?: string;
    passchk?: string;
    userName?: string;
    nickName?: string;
    isUse: boolean;
    teamCode?: string;
    teamName?: string;
    rateCode: string;
    rateName?: string;
    phone?: string;
    birthday?: string;
    gender?: string;
    zipcode?: string;
    address1?: string;
    address2?: string;
    profileSrc?: string;
    companyName?: string;
    authString?: string;
    authEmail: boolean;
    deviceToken?: string;
    isUseTerms: boolean;
    isPrivacyPolicy: boolean;
    isMarketing: boolean;
    isSnsLogin: boolean;
    snsService?: string;
    snsId?: string;
    isParent: boolean;
    parentId?: string | null;
    jobCode?: string;
    jobName?: string;
    skillCode?: string;
    skillName?: string;
    interestKeyword?: string;
    outDate?: Date;
    lastLoginDate?: Date;

    coInfoVO?: CompanyInfoVO;
    rateCodeVO?: CodeVO;
    teamCodeVO?: CodeVO;
    coEmailIdDec?: string;
    userIdDec?: string;
    userNameDec?: string;
    point?: number;
    sessionId?: string;
    
    positionName?: string;

    isAdmin: boolean; // 관리자 여부
}

export interface CompanyInfoVO {
    cidx: number;
    bizNumber?: string;
    onlineSaleReportNo?: string;
    coCode: string;
    coName?: string;
    ceoName?: string;
    csName?: string;
    csTel?: string;
    csEmail?: string;
    csFax?: string;
    zipcode?: string;
    address1?: string;
    address2?: string;
    privacyOfficer?: string;
    coAccountList?: CompanyAccountVO[];
    logo?: string;
    bgColor1?: string;
    areaCode?: string;
    areaCodeVO?: CodeVO;
    teamCode?: string;
    teamCodeVO?: CodeVO;
    isIosShop: boolean;
    isIosSns: boolean;
    privateKeepYear: number;
    jobCodeList?: string;
}

export function DefaultCompanyAccountVO(): CompanyAccountVO {
    return {
        caidx: 0,
        coCode: "",
        coEmpNo: "",
        coEmailId: "",
        passwd: "",
        passchk: "",
        userName: "",
        nickName: "",
        isUse: false,
        teamCode: "",
        teamName: "",
        rateCode: "",
        rateName: "",
        phone: "",
        birthday: "",
        gender: "",
        zipcode: "",
        address1: "",
        address2: "",
        profileSrc: "",
        companyName: "",
        authString: "",
        authEmail: false,
        deviceToken: "",
        isUseTerms: false,
        isPrivacyPolicy: false,
        isMarketing: false,
        isSnsLogin: false,
        snsService: "",
        snsId: "",
        isParent: false,
        parentId: null,
        outDate: new Date(0),
        lastLoginDate: new Date(0),
        coInfoVO: DefaultCompanyInfoVO(),
        rateCodeVO: DefaultCodeVO(),
        teamCodeVO: DefaultCodeVO(),
        coEmailIdDec: "",
        userIdDec: "",
        userNameDec: "",
        point: 0,
        sessionId: "",

        isAdmin: false,
    };
}

export function DefaultCompanyInfoVO(): CompanyInfoVO {
    return {
        cidx: 0,
        bizNumber: "",
        onlineSaleReportNo: "",
        coCode: "",
        coName: "",
        ceoName: "",
        csName: "",
        csTel: "",
        csEmail: "",
        csFax: "",
        zipcode: "",
        address1: "",
        address2: "",
        privacyOfficer: "",
        coAccountList: [],
        logo: "",
        bgColor1: "",
        areaCode: "",
        areaCodeVO: DefaultCodeVO(),
        teamCode: "",
        teamCodeVO: DefaultCodeVO(),
        isIosShop: false,
        isIosSns: false,
        privateKeepYear: 0,
    };
}
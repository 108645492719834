import React, {useEffect, useState} from "react";
import BoardService from "../../../../../core/board/board.service";
import StringUtils from "../../../../../common/utils/string.utils";
import {BoardVO} from "../../../../../core/board/board.vo";
import CardItem from "../../../board/item/CardItem";
import APIs from "../../../../../common/apis/APIs";
import boardService from "../../../../../core/board/board.service";
import JobSkillService from "../../../../../core/jobskill/jobskill.service";
import ElandUtils from "../../../../../common/utils/eland.utils";

interface JobSkillContentRecommendProps {
    weakList: string;
}

const JobSkillContentRecommend = (props:JobSkillContentRecommendProps) => {
    const [boardList, setBoardList] = useState<BoardVO[]>([])

    useEffect(() => {
        getRelatedContents()

    }, [props]);


    const getRelatedContents = () => {
        const params = {
            boardKey: APIs.KNOWLEDGE_BOARDKEY,
            size: 6
        };

        const successHandler = async (result: any) => {
            const updatedBoardList = await ElandUtils.updateBoardListWithSkillNames(result.data);
            const newList = await ElandUtils.updateBoardListWithJobNames(updatedBoardList);
            setBoardList(newList);
        }

        JobSkillService.jobSkillRecommendContentGet(params, successHandler)
    }


    return (
        <section className="recommend-section">
            <div className="inner">
                <div className="section-title">
                    <h3 className="tit">추천 콘텐츠를 통해 역량을 강화해보세요!</h3>
                </div>
                <div className="board-list">
                    {
                        boardList.length > 0 ?
                            (
                                <ul className="card-list type1">
                                    {
                                        boardList.map(value => {
                                            return <CardItem key={value.idx} board={value}/>
                                        })
                                    }
                                </ul>
                            ) : (
                                <div className="board-list type1 recommend-content">
                                    <div className="content-empty">
                                        <p className="txt">추천 콘텐츠를 모두 시청하셨습니다!</p>
                                    </div>
                                </div>
                            )
                    }

                </div>
            </div>
        </section>
    )
}

export default JobSkillContentRecommend
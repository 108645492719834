import React, {useEffect, useState} from "react";
import CommonJS from "../../../../common/common";
import CommonMobileJS from "../../../../common/common_m";

interface AccountManagementKeywordInputProps {
    isEditing: boolean,
    value: string,
    title: string,
    tooltipMsg: string,
    placeholder: string,
    maxKeyword: number,
    maxKeywordLength:number,
    changeKeywordEvent: (keyword: string) => void;
    isTag?:boolean
}

const M_AccountManagementKeywordInput = (props:AccountManagementKeywordInputProps) => {

    const {changeKeywordEvent} = props;
    const [keywordArr, setKeywordArr] = useState<string[]>([])
    const [inputValue, setInputValue] = useState<string>('')
    const [isErrorMessage, setIsErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        CommonMobileJS.tooltip()

    }, []);

    useEffect(() => {
        if (props.value !== ''){
            setKeywordArr(props.value.split(','))
        }

    }, [props.value]);

    useEffect(() => {
        changeKeywordEvent(keywordArr.join(','))

        if (keywordArr.length == 0) {
            setIsErrorMessage(false)
        } else if(keywordArr.length == props.maxKeyword) {
            setIsErrorMessage(!checkValid())
            setErrorMessage(getErrorMessage())
        } else {
            setIsErrorMessage(false)
        }

    }, [keywordArr]);

    useEffect(() => {
        // maxKeywordLength가 넘었으면 메시지 표시
        if (inputValue.length > props.maxKeywordLength) {
            // setIsErrorMessage(!checkValid())
            setIsErrorMessage(true)
            setErrorMessage(getErrorMessage())
        } else {
            setIsErrorMessage(false)
        }
    }, [inputValue]);

    /**
     * 글자수 체크
     */
    const checkInputLength = () => {
        return inputValue.length <= props.maxKeywordLength
    }

    /**
     * 추가된 키워드 개수 체크
     */
    const checkCurrentKeywordNum = () => {
        return (keywordArr.length < props.maxKeyword)
    }

    /**
     * 빈값 확인
     */
    const checkInputIsEmpty = () => {
        return inputValue === ''
    }

    /**
     * 추가된 키워드에 대한 중복여부
     */
    const checkIncludeKeyword = () => {
        return keywordArr.includes(inputValue)
    }

    const checkValid = () => {
        return  checkInputLength() &&
                checkCurrentKeywordNum() &&
                !checkInputIsEmpty() &&
                !checkIncludeKeyword()
    }

    const getErrorMessage = () => {
        if (!checkInputLength()) {
            return props.maxKeywordLength + '글자 이내로 입력해주세요'
        }

        // 입력된 텍스트가 없거나 [특수문자, 이모티콘, 공백]만 입력되는 경우 등록되지 않음
        if (inputValue == '') {
            return '키워드가 전부 입력되었습니다. 추가 입력을 원하신다면 기존에 등록한 키워드를 삭제 후 등록해주세요'
        }

        return ''
    }

    const activeEnter = (e: any) => {
        if (e.keyCode === 13) {
            addKeywordAndRemoveInput()
        }
    }

    const addKeyword = (value: string) => {
        setKeywordArr((prev) => {
            const arr = [...prev]
            arr.push(value)

            return arr
        })
    }

    const addKeywordAndRemoveInput = () => {
        if (checkValid()) {
            let str = props.isTag ? inputValue.replace(/[#\s]/g, '') : inputValue
            addKeyword(str)
            setInputValue('')
        }
    }

    const removeKeyword = (value: string) => {
        setKeywordArr((prev) => {
            const arr = [...prev]
            return arr.filter((v) => value != v)
        })
    }

    return (
        <>
            <div className="form-item">
                <div className="form-title type-tooltip">
                    <strong className="tit">{props.title}</strong>
                    <div className="tooltip">
                        <button type="button" className="btn-tooltip"><span className="blind">툴팁</span></button>
                        <div className="tooltip-content">
                            <div className="txt" dangerouslySetInnerHTML={{__html: props.tooltipMsg}}></div>
                        </div>
                    </div>
                </div>

                {
                    props.isEditing ?
                        <>
                            <div className={isErrorMessage ? "inp-btn-bind error" : "inp-btn-bind"}>
                                <input type="text" name="" id="" className="inp-text"
                                       placeholder={props.placeholder}
                                       value={inputValue}
                                       disabled={!checkCurrentKeywordNum()}
                                       onChange={e => {
                                           let filteredValue;

                                           if (e.target.value.length === 1) {
                                               // 입력값이 첫 글자인 경우만 정규식을 적용합니다.
                                               filteredValue = e.target.value.replace(/[^ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9]/g, '');
                                           } else {
                                               // 첫 글자가 아닌 경우에는 입력값 그대로 사용합니다.
                                               filteredValue = e.target.value;
                                           }

                                           setInputValue(filteredValue);

                                       }
                                       }
                                       onKeyDown={activeEnter}/>
                                <button type="button"
                                        className="btn gray btn-upload"
                                        onClick={e => addKeywordAndRemoveInput()}>등록
                                </button>
                            </div>
                            <div className="tag-list">
                            {
                                    keywordArr.map(((value, index) => {
                                        return (
                                            <span className="tag-item" key={index + value}>
                                {value}
                                                <button type="button"
                                                        className="btn-remove"
                                                        onClick={e => {
                                                            removeKeyword(value)
                                                        }
                                                        }></button>
                            </span>
                                        )
                                    }))
                                }
                            </div>
                            {
                                isErrorMessage &&
                                <div className="error-text">
                                    {
                                        errorMessage
                                    }
                                </div>
                            }
                        </> :
                        <div className="tag-list">
                            {
                                keywordArr.map(((value, index) => {
                                    return (
                                        <span className="tag-item" key={index + value}>
                                            {value}
                                            {
                                                props.isEditing &&
                                                <button type="button"
                                                        className="btn-remove"
                                                        onClick={e => {
                                                            removeKeyword(value)
                                                        }
                                                        }></button>
                                            }
                                        </span>
                                    )
                                }))
                            }
                        </div>
                }
            </div>
        </>
    )
}

export default M_AccountManagementKeywordInput
import React, {useEffect, useState} from "react";
import {EduMainVO} from "../../../core/education/education.vo";
import M_MainEduStatusChartItem from "./M_MainEduStatusChartItem";
import MainService from "../../../core/main/main.service";
import {Link} from "react-router-dom";
import StringUtils from "../../../common/utils/string.utils";
import educationService from "../../../core/education/education.service";
import {HWMap} from "../../../core/common/common.vo";

const M_MainEduStatus = () => {
    const [majorItem, setMajorItem] = useState<EduMainVO>()
    const [scheduleTxt, setScheduleTxt] = useState<HWMap>({})
    
    // 현재 진행중인 필수 교육 조회
    const getEduStatusList = () => {
        const successHandler = (result: any)=>{
            const majorList = result.majorMap.list;

            const majorItem = majorList.length > 0 ? majorList[0] : null;
            setMajorItem(majorItem);
            
            if (majorItem) {
                setScheduleTxt(
                    educationService.getScheduleText( majorItem )
                )
                console.log(scheduleTxt)
            }
        }

        MainService.getEduMainList({}, successHandler);
    }

    // init
    useEffect(() => {
        getEduStatusList()
    }, []);

    return (
        majorItem == null ? <></> : (
            <>
                <section className="progress-section">
                    <div className="inner">
                        <div className="section-title">
                            <h2 className="tit">진행중인 필수교육</h2>
                        </div>
                        <div className="progress-course">
                            <M_MainEduStatusChartItem item={majorItem}/>
                            <div className="edu-info">
                                <Link to={"/education/" + (majorItem?.emIdx ?? "") + "/0/" + StringUtils.titleUrlFilter(majorItem?.eduTitle ?? "")}>
                                    <span className="badge type1 red">필수교육</span>
                                    <strong className="tit">{majorItem?.eduTitle ?? ""}</strong>
                                    <span className="date">{scheduleTxt.startDateTxt} ~ {scheduleTxt.endDateTxt}</span>
                                    <span className="point">{majorItem?.point ?? ""}P</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    )
}

export default M_MainEduStatus;
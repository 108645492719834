import React, {useEffect, useRef, useState} from "react";
import {Link, useParams} from "react-router-dom";
import CommonMobileJS from "../../../common/common_m";
import ElandUtils from "../../../common/utils/eland.utils";

const M_TabMenu : React.FC = () => {
    const containerRef = useRef<HTMLDivElement>(null);
    let {boardKey} = useParams();

    /*
    https://github.com/innople-ebot/elandedu/issues/607
     */
    const links = [
        {middle: '/board/list/', key: 'ebg_story', label: 'EBG 성공스토리' },
        {middle: '/board/story/', key: 'use_story', label: '적용스토리' },
        {middle: '/board/list/', key: 'ebg_common', label: 'EBG 지식콘텐츠' },
        {middle: '/board/list/', key: 'pro_knowledge', label: '사내고수의 지식' },
        {middle: '/board/list/', key: 'job_data', label: '사내고수의 자료실' },
        // { key: 'brand_story', label: '브랜드스토리' },
        {middle: '/board/list/', key: 'leadership', label: '리더십' },
        {middle: '/board/list/', key: 'es', label: 'ES' },
    ];

    const [boardNameList, setBoardNameList] = useState<{boardKey:string, boardName:string}[]>([])

    useEffect(() => {
        getBoardNameList()
    }, []);

    useEffect(() => {
        CommonMobileJS.knowledgeTabMenu()
    }, [boardKey]);

    const getBoardNameList = async () => {
        let result = await ElandUtils.getBoardNameList();
        setBoardNameList(result)
    }

    const getBoardName = (boardKey:string)=>{
        let find = boardNameList.find(board=> board.boardKey === boardKey);
        return find ? find.boardName : boardKey
    }



    return (
        <div className="tab-area" ref={containerRef}>
            <div className="inner">
                <div className="tab-slide">
                    <ul className="tab-list swiper-wrapper">

                        {links.map((link, index) => (
                            <li key={index}
                                className={`tab-item swiper-slide ${boardKey === `${link.key}` ? "active" : ""}`}>
                                <Link to={link.middle + link.key}>{getBoardName(link.key)}</Link>
                            </li>
                        ))}

                    </ul>
                </div>
            </div>
        </div>
    )
}

export default M_TabMenu;
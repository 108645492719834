import React, {useEffect} from "react";
import {BoardVO} from "../../../../core/board/board.vo";
import {ProReqVO} from "../../../../core/pro/pro.vo";
import {Link} from "react-router-dom";
import StringUtils from "../../../../common/utils/string.utils";
import FileUtils from "../../../../common/utils/file.utils";
import APIs from "../../../../common/apis/APIs";

const M_UseStoryCard: React.FC<{cardContent: BoardVO | ProReqVO}> = ({cardContent}) => {
    const isBoardVO = (content: BoardVO | ProReqVO): content is BoardVO => {
        return (content as BoardVO).title !== undefined;
    };

    return (
        <>
            {/* 지식콘텐츠 카드 */}
            {
                cardContent && 'title' in cardContent &&
                <div className="selected-content">
                    <strong className="content-tit">지식콘텐츠</strong>
                    <div className="content-wrap">
                        <Link to={`/board/${cardContent.boardKey}/${cardContent.idx}/${StringUtils.titleUrlFilter(cardContent.title)}`}>
                            <div className="image-wrap">
                                <img src={cardContent.boardKey =='job_data' ? FileUtils.getRandomJobDataImg(cardContent) : FileUtils.getImageSrc(cardContent.thumbFile)}
                                alt="참조 컨텐츠 썸네일" className="img-thumb"/>
                            </div>
                            <div className="text-wrap">
                                <strong className="tit">{cardContent.title}</strong>
                                <span className="job">
                                {
                                    (cardContent.writerInfo?.isAdmin)
                                        ? `${cardContent.writer}`
                                        : `${cardContent.coInfoVO?.coName} ${cardContent.writer} 고수`
                                }
                                </span>
                            </div>
                        </Link>
                    </div>
                </div>
            }

            {/* 사내고수 카드 */}
            {
                (cardContent && 'chatTopic' in cardContent) &&
                <div className="selected-content">
                    <strong className="content-tit">사내고수</strong>
                    <div className="content-wrap">
                        <Link to={`/pro/${cardContent.proidx}`}>
                            <div className="image-wrap">
                                <img src={cardContent.profileImgSrc || APIs.BOARD_ERROR_IMG} alt=""
                                     className="img-thumb"/>
                            </div>
                            <div className="text-wrap">
                                <strong className="name">{cardContent.companyAccountVO?.companyName} {cardContent.companyAccountVO?.userName}</strong>
                                <span className="job">{cardContent.jobCodeName}</span>
                            </div>
                        </Link>
                    </div>
                </div>
            }
        </>
    );
}

export default M_UseStoryCard;
import React, {useEffect, useState} from 'react'
import CommonMobileJS from "../../../common/common_m";

interface M_BottomButtonProps {
    name: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
    disabled?: boolean
}

const M_BottomButton = (props: M_BottomButtonProps) => {
    const {name, onClick} = props
    const [isDisabled, setIsDisabled] = useState<boolean>(props.disabled === true);


    useEffect(() => {
        setIsDisabled(props.disabled === true)
        setTimeout(()=>{CommonMobileJS.floatingArea()})

    }, [props.disabled]);

    return (
        <>
            <div className="floating-area">
                <div className="floating-button">
                    <div className="inner">
                        <div className="button-area">
                            <button className="btn lg full"
                                    disabled={isDisabled}
                                    onClick={onClick}>
                                {name}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default M_BottomButton
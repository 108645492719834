import React, {useEffect, useState} from "react";
import Snb from "../Snb";
import BestItems from "./BestItems";
import CommonSelect from "../../../pc/common/CommonSelect";
import Pagination from "../../../pc/common/Pagination";
import {HWMap} from "../../../../core/common/common.vo";
import {BoardVO, DefaultBoardInfoVO} from "../../../../core/board/board.vo";
import BoardService from "../../../../core/board/board.service";
import Basic from "../listType/Basic";
import {useParams} from "react-router-dom";
import UseStoryDetail from "./UseStoryDetail";
import NullItem from "../item/NullItem";
import SwalUtils from "../../../../common/utils/swal.utils";
import {BoardInfoVO} from "../../../../core/board/BoardInfo.vo";
import StringUtils from "../../../../common/utils/string.utils";


const UseStoryList: React.FC = () => {

    const boardService = new BoardService();
    let {boardKey, bidx} = useParams();

    const [boardInfo, setBoardInfo] = useState<BoardInfoVO>(DefaultBoardInfoVO())
    const [isLoad, setIsLoad] = useState<boolean>(false);
    const [totalCount, setTotalCount] = useState(0);
    const [boardList, setBoardList] = useState<BoardVO[]>([]);
    const [bestList, setBestList] = useState<BoardVO[]>([]);
    const [filter, setFilter] = useState<string | number>();

    const [params, setParams] = useState<HWMap>({})

    const options = [
        { value: '', name: '전체' },
        { value: 'best', name: 'BEST' },
        { value: 'knowledge', name: '지식콘텐츠' },
        { value: 'pro', name: '사내고수' }
    ];

    const headerList = ["순번", "구분", "제목", "작성자", "작성일"]
    const isNeedLogin = true
    
    // 페이지 업데이트
    const updatePages = (newPage: number) => {
        setParams(prevParams => ({
            ...prevParams,
            page: newPage
        }));
    }

    useEffect(() => {
        console.log("⭐filter", filter);
        setParams(prevParams => ({
            page: 0,
            size: 10,
            sort: "createDate,DESC",
            boardKey: boardKey,
            filterMode: filter
        }));
    }, [filter]);
    
    
    useEffect(() => {

        if(boardKey) {
            if(boardKey != "use_story") {
                SwalUtils.text({
                    title : "안내",
                    message : "잘못된 접근입니다.",
                    isCancel : false,
                    confirmOptions : {
                        text : "확인",
                        eventHandler : () => window.location.href = "/board/story/use_story"
                    },
                })
            }
        }

        if (params && params.size && params.boardKey != '') {

            (async () => {
                const res = await boardService.getUseStoryBoardList(params);
                const result = res.data

                result.boardInfoVO && setBoardInfo(result.boardInfoVO)

                if (result.boardMap) {
                    setBoardList(result.boardMap.list);

                    // 첫 로드때 한번만 세팅 (필터 변경 방지)
                    if(!isLoad) {
                        setBestList(result.noticeMap?.list);
                        setIsLoad(true);
                    }

                    setTotalCount(result.boardMap?.count)
                }

                console.log("⭐getBoardList", result);
            })()
        }
    }, [params, boardKey])

    return (

        <main className="content apply nav-layout">

            { bidx && bidx != "0"
                ? <UseStoryDetail />
                : <>
                    <div className="common-title">
                        <div className="inner">
                            <h2 className="tit">{boardInfo?.boardName}</h2>
                            <p className="desc"
                               dangerouslySetInnerHTML={{__html: StringUtils.nl2br(boardInfo?.description ?? "")}}></p>
                        </div>
                    </div>

                    {/* 베스트 선정 후기 */}
                    <section className="best-section">
                        <div className="inner">
                        <div className="section-title">
                                <h3 className="tit"><i className="ico-best"></i>베스트 선정후기</h3>
                            </div>
                            {
                                bestList.length > 0
                                ?  <BestItems bestList={bestList}/>
                                :  <NullItem />
                            }

                        </div>
                    </section>
                </>
            }


            <section className="list-section">
                <div className="inner">
                    <div className="section-title">
                        <h3 className="tit">적용스토리</h3>
                    </div>

                    <div className="board-util">
                        <div className="button-area">
                            <button className="btn red" onClick={() => {
                                location.href = '/board/story/use_story/0/_/form'
                            }}>
                                작성하기
                            </button>
                        </div>
                        <div className="util-group">
                            <div className="board-total">
                                총 <span>{totalCount}</span> 개의 후기가 있습니다.
                            </div>
                            <div className="board-filter">
                                {/* 커스텀 필터 옵션 제작 가능 (사내고수, 지식콘텐츠....) */}
                                <CommonSelect options={options} updateOption={setFilter} />
                            </div>
                        </div>
                    </div>


                    <div className="board-list">

                        {/* 게시판 리스트 */}
                        {/*
                        if (MemberUtils.getSessionVO() == null) {
                            if (window.confirm('로그인이 필요합니다. 로그인 페이지로 넘어가시겠습니까?')) {
                                navigate('/login');
                            } else {
                                navigate(-1); // 이전 페이지로 이동
                            }
                        }
                        */}
                        {
                            boardList.length > 0
                            ?  <Basic paramMap={{boardList, headerList, isNeedLogin, pageParams:{
                                totalCount : totalCount, page:params.page, size:params.size
                            }}} />
                            :  <NullItem />
                        }


                        {/* 페이지네이션 */}
                        <Pagination page={params.page}
                                    totalCount={totalCount}
                                    updatePages={updatePages}
                                    size={params.size}/>

                    </div>

                </div>
            </section>


            <Snb/>
        </main>
    );
}

export default UseStoryList;
import React, {useEffect, useState} from "react";
import {EduMainVO} from "../../../core/education/education.vo";
import MainEduStatusChartItem from "./MainEduStatusChartItem";
import MainEduStatusListItem from "./MainEduStatusListItem";

interface MainEduStatusProps {
    majorList: EduMainVO[]
    targetList: EduMainVO[]
    freeList: EduMainVO[]
}

const MainEduStatus = (props: MainEduStatusProps) => {
    const { majorList, targetList, freeList } = props;

    const [majorItem, setMajorItem] = useState<EduMainVO>()
    const [targetItem, setTargetItem] = useState<EduMainVO>()
    const [freeItem, setFreeItem] = useState<EduMainVO>()

    useEffect(() => {
        const firstItem = majorList.length > 0 ? majorList[0] : null;
        if (firstItem) {
            setMajorItem(firstItem);
        }
    }, [majorList]);

    useEffect(() => {
        const firstItem = targetList.length > 0 ? targetList[0] : null;
        if (firstItem) {
            setTargetItem(firstItem);
        }
    }, [targetItem]);

    useEffect(() => {
        const firstItem = freeList.length > 0 ? freeList[0] : null;
        if (firstItem) {
            setFreeItem(firstItem);
        }
    }, [freeItem]);

    return (
        <section className="progress-section">
            <div className="inner">
                <div className="section-title">
                    <h2 className="tit">현재 진행 중인 필수 교육</h2>
                </div>
                <div className="progress-list">
                    <MainEduStatusChartItem item={majorItem} />
                    <li>
                        <ul className="edu-list">
                            {majorItem && <MainEduStatusListItem type={"major"} item={majorItem} />}
                            {targetItem && <MainEduStatusListItem type={"target"} item={targetItem} />}
                            {freeItem && <MainEduStatusListItem type={"free"} item={freeItem} />}
                        </ul>
                    </li>
                </div>
            </div>
        </section>
    )
}

export default MainEduStatus;
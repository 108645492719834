import React, {useEffect, useRef, useState} from "react";
import APIs from "../../../common/apis/APIs";
import {BoardVO} from "../../../core/board/board.vo";
import M_CardItem from "../board/item/M_CardItem";
import JobSkillService from "../../../core/jobskill/jobskill.service";
import ElandUtils from "../../../common/utils/eland.utils";
import memberUtils from "../../../common/utils/member.utils";
import type { Swiper as SwiperType } from 'swiper';
import SwiperInit from 'swiper';


const M_MainContentRecommend = () => {
    const [boardList, setBoardList] = useState<BoardVO[]>([])
    const boardSwiperRef = useRef<SwiperType | null>(null);

    useEffect(() => {
        getRelatedContents()

    }, []);

    useEffect(() => {
        initSwiper()

    }, [boardList]);

    const initSwiper = () => {
        boardSwiperRef.current?.destroy(true, true);

        if (boardList.length > 0) {
            boardSwiperRef.current = new SwiperInit("#main-content-recommend .board-slide", {
                slidesPerView: "auto",
                spaceBetween: 24,
            });
        }

    };

    const getRelatedContents = () => {
        const params = {
            boardKey: APIs.KNOWLEDGE_BOARDKEY,
            size: 8
        };

        const successHandler = async (result: any) => {
            const updatedBoardList = await ElandUtils.updateBoardListWithSkillNames(result.data);
            const newList = await ElandUtils.updateBoardListWithJobNames(updatedBoardList);
            setBoardList(newList);
        }

        JobSkillService.jobSkillRecommendContentGet(params, successHandler)
    }

    return (boardList && boardList.length !== 0) ? (
        <section id="main-content-recommend" className="list-section">
            <div className="inner">
                <div className="section-title">
                    <h3 className="tit">
                        <b>{memberUtils.getSessionVO()?.userName}</b> 님의 역량 향상을 위한
                        <br/>
                        추천 콘텐츠
                    </h3>
                </div>
                <div id="main-content" className="board-list board-slide">
                    <ul className="card-list type1 swiper-wrapper">
                        {
                            boardList.map((value, index) => {
                                return <M_CardItem key={index} board={value}/>
                            })
                        }
                    </ul>
                </div>
            </div>
        </section>
    ) : <></>
}

export default M_MainContentRecommend
import {useIsMobile} from "../hook/useMediaQuery";
import webUtils from "./web.utils";

const style = document.createElement('style');
style.innerHTML = `
.toast-message {
    transition: opacity 0.5s ease-out;
    z-index:1060;
}

.toast-message.fade-out {
    opacity: 0;
}
`;
document.head.appendChild(style);



class ToastUtils {


	static template = `<div id="toast-msg" class="toast-message active" style="[TOP_POSITION]">
			                        <div class="txt">
			                            [MSG]
			                        </div>
			                        <button type="button" class="btn-close" id="toast-close"><span class="blind">닫기</span></button>
			                    </div>`;


	static template_mobile = `<div id="toast-msg" class="toast-message">
						            <div class="inner">
						                <p>[MSG]</p>
						            </div>
						        </div>`;
	
	
	/**
	 * 토스트 메세지 표출
	 * @param _msg : 메세지
	 * @param _closeSeconds : 사라질 초수 지정. 기본 3초
	 * @param _topPosition : top position 지정. 기본 104
	 */
	static show = ( _msg:string, _closeSeconds:number = 3, _topPosition:number = 104 ) => {
		
		const isMobile = webUtils.isMobile()
		/**
		 * 기존 토스트 메세지가 있을 경우 삭제하고 표출
		 */
		const oldDiv = document.getElementById(`toast-msg`) as HTMLElement;
		if ( oldDiv ) {
			oldDiv.remove()
		}
		
		
		let toastHtml = ToastUtils.template.replace("[MSG]", _msg)
			.replace("[TOP_POSITION]", `top: ${_topPosition}px`)
		
		// 모바일 일 경우 html 변경
		if ( isMobile ) {
			toastHtml = ToastUtils.template_mobile.replace("[MSG]", _msg);
		}
		
		const tempDiv = document.createElement('div');
		tempDiv.innerHTML = toastHtml;
		
		// 실제로 페이지에 추가
		const toastElement = tempDiv.firstElementChild as HTMLElement;
		document.body.appendChild(toastElement);
		
		// 닫기 버튼에 이벤트 리스너 추가
		const closeButton = toastElement.querySelector('#toast-close');
		closeButton?.addEventListener('click', () => {
			ToastUtils.close()
		});
		
		
		setTimeout(() => {
			ToastUtils.close()
		}, _closeSeconds * 1000)
		
	}
	
	
	
	/**
	 * 토스트 메세지 삭제
	 */
	static close = async () => {
		const tempDiv = document.getElementById(`toast-msg`) as HTMLElement;
		if ( tempDiv ) {
			await tempDiv.classList.add("fade-out")
			await new Promise(resolve => setTimeout(resolve, 500));
			tempDiv.remove();
		}
	}

}

export default ToastUtils
import React, {useEffect, useState, useCallback} from "react";
import M_ProReviewItem from "./M_ProReviewItem";
import M_Pagination from "../common/M_Pagination";
import {CompanyAccountVO} from "../../../core/company/company.vo";
import {HWMap} from "../../../core/common/common.vo";
import {PageUtils} from "../../../common/utils/page.utils";
import ReviewService from "../../../core/review/review.service";
import {ReviewListItemVO} from "../../../core/review/review.vo";
import {M_ReviewType} from "./M_ReviewType";
import CommonMobileJS from "../../../common/common";
import MemberUtils from "../../../common/utils/member.utils";

interface ProReviewProps {
    eduTalkVO: CompanyAccountVO
}


const M_ProReview = (props: ProReviewProps) =>{
    const [params, setParams] = useState<HWMap>({
        ...new PageUtils(),
        size:10,
        type:M_ReviewType.EDU_TALK,
        targetId:props.eduTalkVO.coEmailId
    })
    const [totalCount, setTotalCount] = useState<number>(0)
    const [reviewList, setReviewList] = useState<ReviewListItemVO[]>([])

    const getReviewList = async () =>{
        if (params.targetId !== ''){
            const result = await ReviewService.getReviewList(params);
            if (result.status === 'SUCCESS') {
                setTotalCount(result.count);
                setReviewList(result.list);
            }
        }
    }

    // 페이지 누를 때 업데이트하는 함수
    const updatePages = (page:number) => {
        setParams((params) => ({
            ...params,
            page: page
        }));
    };

    useEffect(() => {
        getReviewList()

    }, [params]);

    useEffect(() => {
        CommonMobileJS.tooltipV2()

    }, []);

    const isMaxPage = () => {
        const totalPages = Math.ceil(totalCount / params.size);
        return params.page >= totalPages - 1;
    }

    const isMinPage = () => {
        return params.page <= 0
    }

    const incrementPage = (n: number) => {
        const totalPages = Math.ceil(totalCount / params.size);
        const newPage = params.page + n;
        return Math.max(0, Math.min(newPage, totalPages - 1));
    }

    function isTargetPro(){
        return (MemberUtils.getLoginId() === params.targetId)
    }

    return (
        <section className="comment-section">
            <div className="inner">
                <div className="section-title">
                    <h3 className="tit">
                        고수 한줄평

                        {
                            isTargetPro() && (
                                <div className="tooltip">
                                    <button type="button" className="btn-tooltip">
                                        <span className="blind">툴팁</span>
                                    </button>
                                    <div className="tooltip-content">
                                        <div className="txt">
                                            고수 Pick 한줄평은 하나만 설정할 수 있습니다.
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                    </h3>
                    <span className="num"><b>{totalCount}</b>건</span>
                </div>
                <div className="comment type2">

                    {
                        reviewList.length === 0 ? (
                            <div className="content-empty">
                                <p className="txt">아직 작성된 한줄평이 없습니다.</p>
                            </div>
                        ) : (
                                <>
                                    <ul className="comment-list">
                                        {/* M_ProReviewItem 컴포넌트를 배열의 각 요소로 렌더링 */}
                                        {reviewList.map((review, index) => (
                                            <M_ProReviewItem key={index} itemVO={review}
                                                             eduTalkVO={props.eduTalkVO}
                                                             updateHandler={getReviewList}/>
                                        ))}
                                    </ul>
                                    <div className="button-area">
                                        <button type="button"
                                                className={"btn-prev" + (isMinPage() ? " disabled" : "")}
                                                onClick={e=>{
                                                        e.preventDefault()
                                                        !isMinPage() && updatePages(incrementPage(-1))
                                                    }
                                                }
                                        >
                                            <span className="blind">이전 페이지</span>
                                        </button>
                                        <button type="button"
                                                className={"btn-next" + (isMaxPage() ? " disabled" : "")}
                                                onClick={e=>{
                                                        e.preventDefault()
                                                        !isMaxPage() && updatePages(incrementPage(+1))
                                                    }
                                                }
                                        >
                                            <span className="blind">다음 페이지</span>
                                        </button>
                                    </div>
                                </>
                            )
                        }

                </div>
            </div>
        </section>
    )
}

export default M_ProReview
import React, {useEffect, useState} from "react";
import {PageParams, PageUtils} from "../../../common/utils/page.utils";
import {EduMainVO} from "../../../core/education/education.vo";
import {HWMap} from "../../../core/common/common.vo";
import moment from "moment";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Chart} from "../../../assets/libs/chart.js/chart.min";
import EducationService from "../../../core/education/education.service";
import SwalUtils from "../../../common/utils/swal.utils";
import StringUtils from "../../../common/utils/string.utils";
import useHeader from "../../../common/hook/useHeader";
import {HeaderBtn} from "../../../core/common/header";
import useFooter from "../../../common/hook/useFooter";


/**
 * 교육 목록 페이지
 * @constructor
 */
const M_EduMainList: React.FC<HWMap> = (hwMap) => {
	
	useHeader({
		type: `sub`,
		title: `코스학습`,
		buttons: [
			HeaderBtn.Menu
		]
	});
	
	useFooter({
		isHidden: false
	});

	const navigate = useNavigate();
	let {openStatus} = useParams();
	const [openStatusInfo, setOpenStatusInfo] = useState<string>();
	const [params, setParams] = useState<HWMap>(new PageUtils())
	const [majorList, setMajorList] = useState<EduMainVO[]>([]);
	const [targetList, setTargetList] = useState<EduMainVO[]>([]);
	const [freeList, setFreeList] = useState<EduMainVO[]>([]);
	
	useEffect(() => {
		setParams({
			page: 0,
			size: 20,
			sort: "createDate,DESC",
			openStatus: openStatus // 변수가 정의되어 있어야 합니다.
		});
		
		openStatus === "inprogress" && setOpenStatusInfo(`진행중인`);
		openStatus === "termination" && setOpenStatusInfo(`종료된`);
		openStatus === "scheduled" && setOpenStatusInfo(`예정된`);
		
	}, [openStatus])
	
	
	useEffect(() => {
		if ( params && params.size && params.size > 10 ) {
			(async () => {
				const result = await EducationService.getEduMainList(params);
				if ( result.status === `SUCCESS` ) {
					setMajorList(result.majorMap.list)
					setTargetList(result.targetMap.list)
					setFreeList(result.freeMap.list)
				} else {
					if ( result.msg === `NO_LOGIN` ) {
						SwalUtils.text({
							title : "코스학습",
							message : "로그인 사용자만 접근 가능합니다.",
							confirmOptions : {
								text : "확인",
								eventHandler : () => navigate("/login/")
							},
						})
						//navigate(-1)
					}
				}
			})()
		}
	}, [params])


	return (
		<main id="edu-main-list" className="content course tab-layout">
			{/*<!-- Tab -->*/}
			<div className="tab-area">
				<div className="inner">
					<div className="tab-slide">
						<ul className="tab-list swiper-wrapper">
							<li className={`tab-item ${openStatus === 'inprogress' && 'active'}`}>
								<Link to={`/education/inprogress`}>진행중</Link>
							</li>
							<li className={`tab-item ${openStatus === 'termination' && 'active'}`}>
								<Link to={`/education/termination`}>종료</Link>
							</li>
							<li className={`tab-item ${openStatus === 'scheduled' && 'active'}`}>
								<Link to={`/education/scheduled`}>예정</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<section className="list-section">
				<div className="inner">
					<div className="board-list">
						<div className="accordion active">
							<button type="button" className="tit-accordion">{openStatusInfo} 필수교육</button>
							<div className="accordion-content">
								{
									majorList.length > 0 ? (
										<ul className="course-list">
											{
												majorList?.map((item, index) => (
													<M_EduMainItem key={`${index}-${item.emIdx}`} eduMain={item} />
												))
											}
										</ul>
									) : (
										<M_EduNullItem status={openStatusInfo || ""}/>
									)
								}
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="list-section">
				<div className="inner">
					<div className="board-list">
						<div className="accordion active">
							<button type="button" className="tit-accordion">{openStatusInfo} 지정교육</button>
							<div className="accordion-content">
								{
									targetList.length > 0 ? (
										<ul className="course-list">
											{
												targetList?.map((item, index) => (
													<M_EduMainItem key={`${index}-${item.emIdx}`} eduMain={item} />
												))
											}
										</ul>
									) : (
										<M_EduNullItem status={openStatusInfo || ""}/>
									)
								}
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="list-section">
				<div className="inner">
					<div className="board-list">
						<div className="accordion active">
							<button type="button" className="tit-accordion">{openStatusInfo} 자율참여 교육</button>
							<div className="accordion-content">
								{
									freeList.length > 0 ? (
										<ul className="course-list">
											{
												freeList?.map((item, index) => (
													<M_EduMainItem key={`${index}-${item.emIdx}`} eduMain={item} />
												))
											}
										</ul>
									) : (
										<M_EduNullItem status={openStatusInfo || ""}/>
									)
								}
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
	)
};








const M_EduMainItem :React.FC<{eduMain:EduMainVO}> = ({eduMain} ) => {
	
	//const [eduMain, setEduMain] = useState(_eduMain)
	const [student, setStudent] = useState(eduMain.studentVO)
	const schduleMode = eduMain.scheduleMode
	const startDate = eduMain.startDate
	const endDate = eduMain.endDate
	
	const [created, setCreated] = useState<boolean>(false)
	const [mounted, setMounted] = useState<boolean>(false)


	let startDateTxt = "";
	let endDateTxt = "";
	
	switch (schduleMode) {
		case "fix":
			startDateTxt = startDate.replace(/-/gi, ".");
			endDateTxt = endDate.replace(/-/gi, ".");
			break;
		case "month":
			startDateTxt = "매년 " + (moment(startDate).month() + 1) + "월";
			endDateTxt = "매년 " + (moment(endDate).month() + 1) + "월";
			break;
		case "day":
			startDateTxt = "매월 " + moment(startDate).day() + "일";
			endDateTxt = "매월 " + moment(endDate).day() + "일";
			break;
	}
	
	
	
	useEffect(() => {
		
		/* 기존 차트 삭제 */
		/*const oldCharts = document.querySelectorAll('.charts');
		oldCharts.forEach(chartElement => {
			// 차트의 ID를 가져옵니다.
			const chartId = chartElement.id;
			Chart.getChart(chartId) && Chart.getChart(chartId).destroy();
		});*/
		
		if ( eduMain ) {
			
			/* Progress Chart */
			const totalValue1 = eduMain.contentCount;
			const progressValue1 = eduMain.answerCount;
			Chart.getChart(`ProgressCourse-${eduMain.emIdx}`) && Chart.getChart(`ProgressCourse-${eduMain.emIdx}`).destroy()
			const ProgressCourse = new Chart(`ProgressCourse-${eduMain.emIdx}`, {
				type: 'doughnut',
				data: {
					labels: ["Course"],
					datasets: [
						{
							data: [totalValue1 - progressValue1, progressValue1],
							backgroundColor: [
								"#F0F2F6",
								"#1847ED"
							],
							cutout: "75%",
						},
					],
				},
				options: {
					plugins: {
						legend:{
							display: false
						},
						tooltip: {
							enabled: false
						},
					},
				}
			});
		}
		
	}, [eduMain])
	
	
	
	return (
		<li>
			<Link to={`/education/${eduMain.emIdx}/0/${StringUtils.titleUrlFilter(eduMain.eduTitle) }`}>
				<div className="chart-wrap">
					<canvas className={`charts`} id={`ProgressCourse-${eduMain.emIdx}`}></canvas>
					<span className="rate">{eduMain.answerCount}/{eduMain.contentCount}</span>
				</div>
				<div className="course-info">
					
					{ eduMain.openCode === `20010201` && <span className="badge type1 red">필수교육</span>}
					{ eduMain.openCode === `20010202` && <span className="badge type1 orange">지정교육</span>}
					{ eduMain.openCode === `20010203` && <span className="badge type1 green">자율참여</span>}
					
					<strong className="tit">{eduMain.eduTitle}</strong>
					<span className="date">{startDateTxt} ~ {endDateTxt}</span>
					<span className="point">{eduMain.point}P</span>
				</div>
			</Link>
		</li>
	)
}


const M_EduNullItem: React.FC<{status : string}> = ({status}) => {
	return (
		<div className="content-empty">
			<p className="txt">{status} 교육이 없습니다.</p>
		</div>
	)
}


export default M_EduMainList;
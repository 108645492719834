import axiosCaller from "../../common/utils/axios.caller";
import APIs from "../../common/apis/APIs";
import {useNavigate} from "react-router-dom";
import SwalUtils from "../../common/utils/swal.utils";
import ToastUtils from "../../common/utils/toast.utils";

class CommentService {

    static failHandler = (result: any) => {
        if (result.status) {
            if (result.status === "FAIL") {
                ToastUtils.show(result.msg)

            } else if (result.status === "ERROR") {
                ToastUtils.show(result.msg)
                console.log(result.data)

            } else if (result.status === "UNAUTHORIZATION") {
                ToastUtils.show(result.msg)

                // 로그인 페이지 이동
                const navigate = useNavigate()
                navigate("/login")

            }

        } else {
            ToastUtils.show("네트워크 오류가 발생했습니다. 잠시 후 시도해주세요.")
        }

    }


    static commentListGet = async (bidx: string,
                                   params: { page: number, size: number },
                                   success: (result: any) => void) => {
        try {
            let result = await axiosCaller.get(APIs.BOARD_COMMENTS + '/page/' + bidx, params)
            if (result.status === 'SUCCESS') {
                success(result)
            } else {
                CommentService.failHandler(result)

            }

        } catch (error) {
            CommentService.failHandler(error)
        }
    }


}

export default CommentService
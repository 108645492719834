import React, {useEffect, useRef, useState} from "react";
import PointService from "../../../../core/point/point.service";
import {PointAccountVO} from "../../../../core/point/point.vo";
import {HWMap} from "../../../../core/common/common.vo";
import {PageUtils} from "../../../../common/utils/page.utils";
import M_Pagination from "../../common/M_Pagination";
import DateUtils from "../../../../common/utils/date.utils";
import M_NumberFormatter from "../../common/M_NumberFormatter";
import NumberFormatter from "../../../pc/common/NumberFormatter";
import {BoardVO} from "../../../../core/board/board.vo";
import M_InfinityScroll from "../../common/M_InfinityScroll";
import axiosCaller from "../../../../common/utils/axios.caller";
import APIs from "../../../../common/apis/APIs";

const M_PointList: React.FC = () => {

    const [pointList, setPointList] = React.useState<PointAccountVO[]>([]);
    const [count, setCount] = React.useState(0);
    const [params, setParams] = useState<HWMap>(new PageUtils())
    const [activeSort, setActiveSort] = React.useState("");

    const scrollRef = useRef<{ reload: () => void, saveScroll:(index:number|null)=>void }>(null);

    const fetchList = async (makeParamFunc:(params:HWMap, size:number) => HWMap) => {
        let cpParams = {...params};
        cpParams.cateCode = activeSort;

        let pageParams = makeParamFunc({...cpParams}, cpParams.size)
        let result = await axiosCaller.get(APIs.POINT + "/getAccountPointHistory", pageParams)
        if (result.status === "SUCCESS"){
            setCount(result.data.totalElements);
            return result.data.content
        }

        return []
    }


    useEffect(() => {
        scrollRef.current && scrollRef.current.reload()
        setParams(new PageUtils())
    }, [activeSort]);

    return (
        <section className="list-section">
            <div className="tab-area">
                <div className="inner">
                    <div className="tab-slide">
                        <ul className="tab-list swiper-wrapper">
                            <li className={`tab-item swiper-slide ${activeSort == '' && 'active'}`}>
                                <a href="javascript:void(0);"
                                   onClick={() => setActiveSort('')}>전체</a>
                            </li>
                            <li className={`tab-item swiper-slide ${activeSort == '300101' && 'active'}`}>
                                <a href="javascript:void(0);"
                                   onClick={() => setActiveSort('300101')}>코스학습</a>
                            </li>
                            <li className={`tab-item swiper-slide ${activeSort == '300102' && 'active'}`}>
                                <a href="javascript:void(0);"
                                   onClick={() => setActiveSort('300102')}>지식학습</a>
                            </li>
                            <li className={`tab-item swiper-slide ${activeSort == '300103' && 'active'}`}>
                                <a href="javascript:void(0);"
                                   onClick={() => setActiveSort('300103')}>사내고수</a>
                            </li>
                            <li className={`tab-item swiper-slide ${activeSort == '300104' && 'active'}`}>
                                <a href="javascript:void(0);"
                                   onClick={() => setActiveSort('300104')}>기타활동</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="tab-content">
                <div className="inner">
                    <ul className="save-list">
                        {
                            pointList.length > 0 ?
                                pointList.map((point: PointAccountVO, index) => (
                                    <li key={index}>
                                        <span className="cate">{point.cateCodeVO?.codeName}</span>
                                        <div className="point-wrap">
                                            <strong className="tit">{point.title}</strong>
                                            {
                                                point.actionCode === "S"
                                                    ? <span className="num type-plus">+<NumberFormatter
                                                        number={point.point}/>P</span>
                                                    : <span className="num type-minus"><NumberFormatter
                                                        number={point.point}/>P</span>
                                            }
                                        </div>
                                        <div className="info">
                                            <p className="desc">{point.detailDesc}</p>
                                            <span
                                                className="date">{DateUtils.formatMonthDayTime(point.createDate)}</span>
                                        </div>
                                    </li>
                                ))
                                :
                                <div className="content-empty">
                                    <p className="txt">아직 적립된 포인트가 없어요.<br/>활동을 해서 포인트를 쌓아보세요</p>
                                </div>
                        }
                    </ul>

                    <M_InfinityScroll<PointAccountVO>
                        queryKey={"myPointList"}
                        update={fetchList}
                        defaultSize={params.size}
                        totalCount={count}
                        getList={val => {
                            setPointList(val)
                        }}
                        scrollRef={scrollRef}/>
                </div>
            </div>

        </section>
    )
}


export default M_PointList;
import React, {useEffect} from "react";
import APIs from "../../../../common/apis/APIs";
import EducationService from "../../../../core/education/education.service";
import {Link} from "react-router-dom";

const DashboardCourseInfo : React.FC = () => {

    const [inprogressCount, setInprogressCount] = React.useState(0);
    const [scheduleCount, setScheduleCount] = React.useState(0);
    const [wishCount, setWishCount] = React.useState(0);

    useEffect(() => {
        (async () => {
            const result = await EducationService.getMyCourseInfo4DashBoard({page: 0, size: 1, sort: "createDate,DESC", boardKeys: APIs.KNOWLEDGE_BOARDKEY});
            if(result.status == "SUCCESS" && result.data) {
                setInprogressCount(result.data.inprogress);
                setScheduleCount(result.data.scheduled);
                setWishCount(result.data.wish);
            }
        })()
    }, []);


    return (
        <>
            <div className="box-item box1">
                <div className="box-title">
                    <h3 className="tit">수강정보</h3>
                </div>
                <div className="box-content">
                    <ul className="stats-list">
                        <li>
                            <strong>코스학습</strong>
                            <ul className="count-list">
                                <li>
                                    <Link to={`/education/inprogress`}>
                                        <span className="num">{inprogressCount}</span>
                                        <span className="txt">진행중</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/education/scheduled`}>
                                        <span className="num">{scheduleCount}</span>
                                        <span className="txt">예정</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <strong>보관함</strong>
                            <ul className="count-list">
                                <li>
                                    <Link to={"/education/storagebox"} state={{activeMenu:"wish"}}>
                                        <span className="num">{wishCount}</span>
                                        <span className="txt">관심 지식</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>

        </>
    )
}

export default DashboardCourseInfo;
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {FooterConfig} from "../../../core/common/footer";

const initialState: FooterConfig = {
    isHidden: false
};

const footerSlice = createSlice({
    name: 'footer',
    initialState,
    reducers: {
        // 상태를 설정하는 리듀서
        setFooterConfig(state, action: PayloadAction<FooterConfig>) {
            return action.payload; // 전달된 상태로 설정
        },
    },
});

// 액션과 리듀서 내보내기
export const { setFooterConfig } = footerSlice.actions;

export default footerSlice.reducer;
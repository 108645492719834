import React from "react";

const M_ProEduTalkCancel = (props:{cancelMsg:string}) => {

    return (
        <section className="reply-section">
            <div className="empty-content">
                <div className="inner">
                    <div className="empty-text">
                        고수가 취소한 문의입니다<br/>
                        취소 사유 : “{props.cancelMsg}”
                    </div>
                </div>
            </div>
        </section>
    )
}

export default M_ProEduTalkCancel
import React, {useEffect, useState} from "react";
import {BoardFileVO, BoardVO} from "../../../../core/board/board.vo";
import Swiper from "../../../../assets/js/swiper.min";
import WebUtils from "../../../../common/utils/web.utils";
import APIs from "../../../../common/apis/APIs";
import {Link} from "react-router-dom";
import StringUtils from "../../../../common/utils/string.utils";

interface BestItemsProps{
    bestList: BoardVO[]
}

const M_BestItems = (props:BestItemsProps) => {
    const [bestList, setBestList] = useState<BoardVO[]>([])

    useEffect(() => {
        setTimeout(initSwiper, 100);
        WebUtils.goTop()

    }, []);

    useEffect(() => {
        setBestList( props.bestList )
        console.log("⭐props.bestList", props.bestList);
    }, [props.bestList]);

    const initSwiper = () => {
        new Swiper(".best-section .slide-wrap", {
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            loop: true,
            slidesPerView: "auto",
            spaceBetween: 30,
            centeredSlides: true,
            navigation: {
                nextEl: ".btn-next",
                prevEl: ".btn-prev",
            },
        });
    }


    return (
        <div className="board-list">
            <div className="slide-wrap">
                <ul className="slide-list swiper-wrapper">
                    {
                        bestList.map((item, index) => (
                        <li className="slide-item swiper-slide" key={index}>
                            <Link to={`/board/story/${item.boardKey}/${item.idx}/${StringUtils.titleUrlFilter(item.title)}`}>
                                <div className="image-wrap">
                                    <img src={item.thumbFile ? `${APIs.BOARD_FILES_SHOW + item.thumbFile.fidx}` : `${APIs.BOARD_FILES_SHOW }0`}
                                         style={{height: '180px'}}
                                         alt="" className="img-thumb"/>
                                </div>
                                <div className="text-wrap">
                                    <strong className="tit">{item.title}</strong>
                                    <p className="desc">{item.contentText}</p>
                                    <span className="writer">{item.writer}</span>
                                    <span className="job">{item.writerInfo?.coInfoVO?.coName}</span>
                                </div>
                            </Link>
                        </li>
                    ))}
                </ul>
                <div className="button-area">
                    <div className="btn-prev"></div>
                    <div className="btn-next"></div>
                </div>
            </div>
        </div>
    )
}


export default M_BestItems;
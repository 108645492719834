import React from "react";
import DashboardJobSkillResult from "./DashboardJobSkillResult";
import DashboardHelpBoard from "./DashboardHelpBoard";
import DashboardEduTalk from "./DashboardEduTalk";
import RecentBoard from "./RecentBoard";
import DashboardPoint from "./DashboardPoint";
import DashboardCourseInfo from "./DashboardCourseInfo";
import DashboardOldCourseInfo from "./DashboardOldCourseInfo";


const Dashboard :React.FC = () => {


	return (
		<main className="content dashboard">
			<div className="common-title">
				<div className="inner">
					<h2 className="tit">대시보드</h2>
				</div>
			</div>
			<section className="dashboard-section">
				<div className="inner">
					<div className="box-list">

						{/* 수강 정보 */}
						<DashboardCourseInfo />

						{/* 과거 수강 정보 */}
						<DashboardOldCourseInfo />

						{/* 최근 본 지식 */}
						<RecentBoard />

						{/* 에듀톡 */}
						<DashboardEduTalk/>

						{/* 포인트 */}
						<DashboardPoint />

						{/* 직무역량진단 */}
						<DashboardJobSkillResult/>

						{/* 도와줘요 게시판 */}
						<DashboardHelpBoard/>

					</div>
				</div>
			</section>
		</main>
	);
}


export default  Dashboard
import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {BoardVO, CommentVO} from "../../../../../../core/board/board.vo";
import ProEduTalkCancel from "../common/ProEduTalkCancel";
import ProEduTalkNone from "../common/ProEduTalkNone";
import ProEduTalkBoard from "../common/ProEduTalkBoard";
import ProEduTalkWriterReply from "./ProEduTalkWriterReply";

interface ProEduTalkWriterProps{
    boardVO: BoardVO,
    commentList: CommentVO[]
    updateEvent: ()=>void
}

const ProEduTalkWriter = (props:ProEduTalkWriterProps) => {
    const {updateEvent} = props
    const {bidx} = useParams()
    const [boardVO, setBoardVO] = useState<BoardVO>(props.boardVO)
    const [commentList, setCommentList] = useState<CommentVO[]>(props.commentList)

    useEffect(() => {
        setBoardVO(props.boardVO)
        setCommentList(props.commentList)
    }, [props]);

    const checkStatus = () => {
        switch (boardVO.statusCode) {
            case "041902":
                return "ing"
            case "041903":
                return "journal"
            case "041904":
                return "completed"
            case "041905":
                return "cancel"
            default:
                return "unresolved"
        }
    }

    const isCancel = () => boardVO.statusCode === '041905'
    const isNew = () => (checkStatus()==="unresolved") && (commentList.length <= 0)
    const isEditable = () => !isCancel() && isNew()


    const getReplySection = () => {
        if(isCancel()){
            return <ProEduTalkCancel cancelMsg={boardVO.opt4 ?? ""}/>
        }
        if(isNew()) {
            return <ProEduTalkNone/>
        }

        const reply = commentList[0]
        const addCommentList = commentList.filter((value, index) => index != 0)
        return <ProEduTalkWriterReply reply={reply}
                                      commentList={addCommentList}
                                      receiverKey={boardVO.receiverKey}
                                      statusCode={boardVO.statusCode ?? "041901"}
                                      updateEvent={updateEvent}/>
    }

    return (
        <>
            <main className="content edutalk detail">
                <ProEduTalkBoard boardVO={boardVO} isCancelable={false} isEditable={isEditable()} updateEvent={updateEvent}/>
                { getReplySection() }
            </main>
        </>
    )
}

export default ProEduTalkWriter


import {BoardVO} from "../../../../core/board/board.vo";
import React from "react";
import FileUtils from "../../../../common/utils/file.utils";
import APIs from "../../../../common/apis/APIs";
interface UseStoryThumbImageProps {
    item: BoardVO;
}

const UseStoryThumbImage: React.FC<UseStoryThumbImageProps> = ({ item }) => {
    const getSrc = () => {
        if (item.boardKey === 'job_data') {
            return FileUtils.getRandomJobDataImg(item);
        }

        return FileUtils.getImageSrc(item.thumbFile)
    };

    return (
        <img
            src={getSrc()}
            alt="thumbnail file"
            className="img-thumb"
        />
    );
};

export default UseStoryThumbImage;
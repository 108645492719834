import React, {useEffect, useRef, useState} from "react";
import {CommentFileVO, CommentVO} from "../../../../../core/board/board.vo";
import {useParams} from "react-router-dom";
import CommonJS from "../../../../../common/common";
import FileUtils from "../../../../../common/utils/file.utils";
import ProService from "../../../../../core/pro/pro.service";
import PointService from "../../../../../core/point/point.service";
import ToastUtils from "../../../../../common/utils/toast.utils";
import StringUtils from "../../../../../common/utils/string.utils";

interface ProHelpDetailCommentInputProps {
    comment: CommentVO | null,
    updateEvent: () => void
}

const ProHelpDetailCommentInput = (props: ProHelpDetailCommentInputProps) => {
    const {bidx} = useParams()
    const {comment, updateEvent} = props
    const fileInput = useRef<HTMLInputElement>(null);
    const fileNameInput = useRef<HTMLInputElement>(null);
    const contentTextArea = useRef<HTMLTextAreaElement>(null);
    const [currentFile, setCurrentFile] = useState<File | CommentFileVO | null>(null)


    useEffect(() => {
        if (comment) {
            if (comment.commentFileList && comment.commentFileList.length > 0){
                setCurrentFile({...comment.commentFileList[0]});
            }

            if (contentTextArea.current) {
                contentTextArea.current.value = comment.contentText ?? ''
            }
        }

        CommonJS.inputFile('.file-upload')
    }, []);

    const updateFile = () => {
        if (!FileUtils.isValidSize(fileInput.current, 100)){
            ToastUtils.show("파일 용량이 100MB를 초과했습니다.")
            clearCurrentFile()

        } else {
            setCurrentFile(fileInput.current?.files?.item(0) ?? null);
        }
    }

    const clearAll = () => {
        if (contentTextArea.current) {
            contentTextArea.current.value = '';
        }
        clearCurrentFile()

    }

    const clearCurrentFile = () => {
        if (fileInput.current && fileNameInput.current) {
            fileInput.current.value = ""
            fileNameInput.current.value = ''
        }
        setCurrentFile(null)
    }

    const saveComment = async (e: any) => {
        e.preventDefault();

        FileUtils.validFileAndAlert(fileInput.current, 1, 100, async (validFile) => {
            if (!bidx) {
                return
            }

            if (StringUtils.isEmpty(contentTextArea.current?.value ?? '')){
                ToastUtils.show('내용을 입력해주세요.')
                return
            }

            const formData = new FormData();
            validFile && formData.append('files', validFile)
            formData.append('cidx', '0')
            formData.append('bidx', bidx)
            formData.append('pidx', '0')
            formData.append('contentText', contentTextArea.current?.value ?? '')

            const successHandler = async () => {
                await PointService.savePoint("HELP_REPLY", bidx ?? "0");
                ToastUtils.show('저장되었습니다.')
                clearAll()
                updateEvent()
            }

            await ProService.helpCommentSave(formData, successHandler);
        })

    }

    const updateComment = async (e: any) => {
        e.preventDefault();

        if (!bidx) {
            return
        }

        if (!comment) {
            return
        }

        if (StringUtils.isEmpty(contentTextArea.current?.value ?? '')){
            ToastUtils.show('내용을 입력해주세요.')
            return
        }

        const formData = new FormData()
        let isFileDelete = 'false'

        if (currentFile instanceof File) {
            let validFile = null
            FileUtils.validFileAndAlert(fileInput.current, 1, 100,(file)=>{
                validFile = file
                validFile && formData.append('files', validFile)
                isFileDelete = 'true'
            })

        }

        if (currentFile == null && comment.commentFileList && comment.commentFileList.length > 0) {
            isFileDelete = 'true'
        }

        formData.append('isDelete', isFileDelete)
        formData.append('contentText', contentTextArea.current?.value ?? '')
        const successHandler = () => {
            ToastUtils.show('수정되었습니다.')
            clearAll()
            updateEvent()
        }

        await ProService.helpCommentUpdate(comment.cidx, formData, successHandler);
    }



    return (
        <div className="comment-input">
            <div className="input-wrap">
                <div className="comment-text">
                    <textarea name="" id="" cols={30} rows={5} className="inp-text"
                              placeholder="꼭 정답이 아니어도 괜찮아요! 댓글을 작성해보세요 :)"
                              ref={contentTextArea}></textarea>
                </div>
                {
                    currentFile && (<div className="comment-file">
                        <strong
                            className="neme">{currentFile instanceof File ? currentFile?.name : currentFile.filename}</strong>
                        <button type="button" className="btn-delete" onClick={clearCurrentFile}><span
                            className="blind">삭제</span></button>
                    </div>)
                }
                <div className="comment-util">
                    <div className="comment-upload">
                        <div className="file-upload">
                            <button type="button" className="btn-upload"><span className="blind">첨부파일 찾기</span>
                            </button>
                            <input type="file" name="" id="" className="inp-file" ref={fileInput}
                                   onChange={updateFile}/>
                            <input type="text" name="" id="" className="inp-text inp-upload"
                                   ref={fileNameInput}/>
                        </div>
                    </div>
                    <div className="button-area">
                        <button type="button" className="btn red sm" onClick={comment ? updateComment : saveComment}>{comment ? "수정하기" : "등록하기"}
                        </button>
                    </div>
                </div>
            </div>
            <div className="guide-text">
                파일크기 : 100MB이하, 1개의 파일 / 확장자는 제약이 없습니다.
            </div>
        </div>
    )
}

export default ProHelpDetailCommentInput
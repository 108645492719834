import React from "react";
import {Link} from "react-router-dom";


const M_MainFooter = () => {

    return (
        <div className="footer">
            <div className="inner">
                <div className="footer-logo">
                    <Link to="/">
                        <span className="blind">E-LANDEDU</span>
                    </Link>
                </div>
                <div className="footer-nav">
                    <Link to="/privacy" className="link-policy">개인정보처리방침</Link>
                </div>
                <div className="footer-info">
                    <p>법인명 : ㈜이랜드인재원  대표이사 : 최형욱, 안영훈  개인정보책임관리자 : 안영훈</p>
                    <p>주소 : 서울특별시 금천구 범안로 1155, 4층(독산동, 동남빌딩)</p>
                    <p>사업자등록번호 : 597-87-00771</p>
                    <p>고객센터 : eland_hrd@eland.co.kr</p>
                    <p>Copyright © 2024. All Rights Reserved to ELAND</p>
                </div>
            </div>
        </div>
    )
}

export default M_MainFooter
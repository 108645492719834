export enum HeaderBtn {
    Back = 'showBackButton',
    GoHomeBackButton = 'showGoHomeBackButton',
    Search = 'showSearchButton',
    Notify = 'showNotifyButton',
    Close = 'showCloseButton',
    LoginClose = 'showLoginCloseButton',
    Menu = 'showMenuButton',
}

export interface HeaderConfig {
    type: 'main' | 'content' | 'sub' | 'hidden';
    title?: string;
    buttons?: HeaderBtn[];
}

export const defaultHeaderConfig: HeaderConfig = {
    type: 'sub',
    title: '',
    buttons: [],
};
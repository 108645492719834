import React, {useEffect, useState} from "react";
import Pagination from "../../common/Pagination";
import {useParams} from "react-router-dom";
import {CommentVO} from "../../../../core/board/board.vo";
import CommentService from "../../../../core/board/comment.service";
import BoardDetailCommentRow from "./BoardDetailCommentRow";
import MypageService from "../../../../core/mypage/mypage.service";
import ToastUtils from "../../../../common/utils/toast.utils";
import StringUtils from "../../../../common/utils/string.utils";

const BoardDetailComment = () => {
    const commentSize = 10
    const {bidx} = useParams()
    const [commentList, setCommentList] = useState<CommentVO[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<number>(0)

    const [newComment, setNewComment] = useState<string>("")

    useEffect(() => {
        getCommentList()

    }, [currentPage]);
    const getCommentList = async () => {
        const successHandler = (result:any) => {
            setCommentList(result.data.list)
            setTotalCount(result.data.count)
        }

        bidx && await CommentService.commentListGet(bidx, {page:currentPage, size:10}, successHandler);
    }


    const updatePages = (newPages: number) => {
        setCurrentPage(newPages)
    }

    const updateEvent = (isDelete: boolean) => {
        if (isDelete && (totalCount - 1) <= (currentPage * commentSize)){
            setCurrentPage(prevState => prevState - 1)

        } else {
            getCommentList()
        }

    }

    const saveNewComment = () => {
        if (!bidx) {
            return
        }

        if (StringUtils.isEmpty(newComment)){
            ToastUtils.show('내용을 입력해주세요.')
            return
        }

        const formData = new FormData();
        formData.append('cidx', '0')
        formData.append('bidx', bidx)
        formData.append('pidx', '0')
        formData.append('contentText', newComment)

        const successHandler = () => {
            ToastUtils.show('저장되었습니다.')
            updateEvent(false)
            setNewComment("")
        }

        MypageService.commentSaveOrUpdate(formData, successHandler)
    }

    return (
        <section className="comment-section">
            <div className="inner">
                <div className="comment type1">
                    <div className="comment-title">
                        <h3 className="tit">댓글</h3>
                        <span className="num">총 {totalCount}개</span>
                    </div>
                    <div className="comment-input">
                        <textarea name="" id="" className="inp-text inp-upload"
                                  rows={1}
                                  style={{resize:'none'}}
                                  value={newComment}
                                  onChange={e=>setNewComment(e.target.value)}
                                  onKeyDown={(e) => {
                                      if (e.key === "Enter" && !e.nativeEvent.isComposing) {
                                          if (e.shiftKey) {

                                          } else {
                                              e.preventDefault()
                                              saveNewComment()
                                          }

                                      }
                                  }}
                               placeholder="댓글을 입력해주세요"/>
                        <button type="button" className="btn red" onClick={e=> {saveNewComment()}}>등록</button>
                    </div>
                    <ul className="comment-list">
                        {
                            commentList.map(val=><BoardDetailCommentRow key={val.cidx} comment={val} updateEvent={updateEvent}/>)
                        }
                    </ul>
                    {
                        commentList.length > 0 &&(
                            <Pagination page={currentPage} totalCount={totalCount} updatePages={updatePages} size={commentSize}/>
                        )
                    }

                </div>
            </div>
        </section>
    )
}

export default BoardDetailComment
import React, {useEffect, useRef, useState} from "react";
import M_BestItems from "./M_BestItems";
import M_CommonSelect from "../../../mobile/common/M_CommonSelect";
import {HWMap} from "../../../../core/common/common.vo";
import {BoardVO} from "../../../../core/board/board.vo";
import BoardService from "../../../../core/board/board.service";
import M_Basic from "../listType/M_Basic";
import {Link, useNavigate, useParams} from "react-router-dom";
import UseStoryDetail from "./M_UseStoryDetail";
import M_NullItem from "../item/M_NullItem";
import useHeader from "../../../../common/hook/useHeader";
import {HeaderBtn} from "../../../../core/common/header";
import useFooter from "../../../../common/hook/useFooter";
import M_TabMenu from "../M_TabMenu";
import M_InfinityScroll from "../../common/M_InfinityScroll";
import axiosCaller from "../../../../common/utils/axios.caller";
import APIs from "../../../../common/apis/APIs";
import SwalUtils from "../../../../common/utils/swal.utils";


const M_UseStoryList: React.FC = () => {

    const boardService = new BoardService();
    let {boardKey, bidx} = useParams();

    const [isLoad, setIsLoad] = useState<boolean>(false);
    const [totalCount, setTotalCount] = useState(0);
    const [boardList, setBoardList] = useState<BoardVO[]>([]);
    const [bestList, setBestList] = useState<BoardVO[]>([]);
    const [filter, setFilter] = useState<string | number>();

    const [params, setParams] = useState<HWMap>({
        page: 0,
        size: 10,
        sort: "createDate,DESC",
        boardKey: boardKey,
        filterMode: filter
    })

    const scrollRef = useRef<{ reload: () => void, saveScroll:(index:number|null)=>void }>(null);

    const options = [
        { value: '', name: '전체' },
        { value: 'best', name: 'BEST' },
        { value: 'knowledge', name: '지식콘텐츠' },
        { value: 'pro', name: '사내고수' }
    ];

    const headerList = ["순번", "구분", "제목", "작성자", "작성일"]
    const isNeedLogin = true



    useHeader({
        type: (bidx && bidx != "0") ? 'content' : 'sub',
        title: '지식콘텐츠',
        buttons: (bidx && bidx != "0") ? [HeaderBtn.Back] : [HeaderBtn.Menu],
    });

    useFooter({
        isHidden: (bidx && bidx != "0") ? true : false,
    })


    // 페이지 업데이트
    const updatePages = (newPage: number) => {
        setParams(prevParams => ({
            ...prevParams,
            page: newPage
        }));
    }

    const fetchList = async (makeParamFunc:(params:HWMap, size:number) => HWMap) => {
        let fetchParams = makeParamFunc({...params}, params.size)
        let result = await axiosCaller.get(APIs.BOARDS + "/getUseStoryBoardList", fetchParams)
        if (result.status === "SUCCESS"){
            if(!isLoad) {
                setBestList(result.data.noticeMap?.list);
                setIsLoad(true);
            }
            setTotalCount(result.data.boardMap?.count)
            return result.data.boardMap.list
        }

        return []
    }

    useEffect(() => {
        if(boardKey) {
            if(boardKey != "use_story") {
                SwalUtils.text({
                    title : "안내",
                    message : "잘못된 접근입니다.",
                    isCancel : false,
                    confirmOptions : {
                        text : "확인",
                        eventHandler : () => window.location.href = "/board/story/use_story"
                    },
                })
            }
        }

        scrollRef.current && scrollRef.current.reload()
        setParams(prevParams => ({
            page: 0,
            size: 10,
            sort: "createDate,DESC",
            boardKey: boardKey,
            filterMode: filter
        }));
    }, [filter, boardKey]);


    return (

        <main className="content apply nav-layout">

            {bidx && bidx != "0"
                ? <UseStoryDetail/>
                : <>
                    {/* 상단 탭 메뉴 */}
                    <M_TabMenu/>

                    {/* 베스트 선정 후기 */}
                    <section className="best-section">
                        <div className="inner">
                            <div className="section-title">
                                <h3 className="tit"><i className="ico-best"></i>베스트 선정후기</h3>
                            </div>
                            {
                                bestList?.length > 0
                                    ? <M_BestItems bestList={bestList}/>
                                    : <M_NullItem/>
                            }

                        </div>
                    </section>

                    <section className="list-section">
                        <div className="inner">
                            <div className="section-title">
                                <h3 className="tit">적용스토리</h3>

                                <div className="board-filter">
                                    {/* 커스텀 필터 옵션 제작 가능 (사내고수, 지식콘텐츠....) */}
                                    <M_CommonSelect options={options} updateOption={setFilter}/>
                                </div>

                            </div>
                            <div className="board-list">
                                {
                                    boardList.length > 0
                                        ? <M_Basic paramMap={{boardList, headerList, isNeedLogin}}/>
                                        : <M_NullItem/>
                                }
                            </div>
                        </div>

                        <M_InfinityScroll<BoardVO>
                            queryKey={"useBoardList"}
                            update={fetchList}
                            defaultSize={params.size}
                            totalCount={totalCount}
                            getList={val=>{setBoardList(val)}}
                            scrollRef={scrollRef}
                        />
                    </section>

                    <div className="floating-icon">
                        <button className="btn-add"
                                onClick={() => {
                                    location.href = '/board/story/use_story/0/_/form'
                                }}>
                            <span className="blind">등록 화면으로</span>
                        </button>
                    </div>
                </>
            }
        </main>
    );
}

export default M_UseStoryList;
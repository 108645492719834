import React, {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import {BoardInfoVO} from "../../../../core/board/BoardInfo.vo";
import {DefaultBoardInfoVO} from "../../../../core/board/board.vo";
import BoardService from "../../../../core/board/board.service";

const M_ProHelp = () => {
    const [boardInfo, setBoardInfo] = useState<BoardInfoVO>(DefaultBoardInfoVO());

    useEffect(() => {
        getBoardInfo()

    }, []);

    const getBoardInfo = async () => {
        let params = {boardKey: 'pro_help'}
        let result = await BoardService.getBoardInfoOne(params);
        if (result.status === 'ok'){
            setBoardInfo(result.boardInfoVO);
        }
    }

    return (
        <>
            <Outlet/>
        </>
    )
}

export default M_ProHelp
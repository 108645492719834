import React, {useEffect, useState} from "react";
import {CommentFileVO, CommentVO} from "../../../../../../core/board/board.vo";
import ProEduTalkCommentRow from "../common/ProEduTalkCommentRow";
import ProEduTalkCommentInput from "../common/ProEduTalkCommentInput";
import moment from "moment/moment";
import CommonJS from "../../../../../../common/common";
import EduTalkReviewWrite from "../../../../review/EduTalkReviewWrite";
import {useParams} from "react-router-dom";
import ProEduTalkReview from "../common/ProEduTalkReview";
import MemberUtils from "../../../../../../common/utils/member.utils";
import FileUtils from "../../../../../../common/utils/file.utils";
import APIs from "../../../../../../common/apis/APIs";
import StringUtils from "../../../../../../common/utils/string.utils";

interface ProEduTalkWriterReplyProps {
    statusCode: string,
    reply: CommentVO,
    commentList: CommentVO[],
    updateEvent: () => void,
    receiverKey: string,
}

const ProEduTalkWriterReply = (props: ProEduTalkWriterReplyProps) => {
    const {bidx} = useParams()
    const {receiverKey, reply, commentList,updateEvent} = props
    const [statusCode, setStatusCode] = useState<string>(props.statusCode)

    // 버튼 클릭시 모드
    const [isAddMode, setIsAddMode] = useState<boolean>(false)

    useEffect(() => {
        setTimeout(()=>CommonJS.accordion())
    }, []);


    useEffect(() => {
        setStatusCode(props.statusCode)
    }, [props]);


    const isOnly = () => {
        return commentList.filter(value =>
            value.writerKey === MemberUtils.getLoginId() || value.writerKey === receiverKey).length <= 0
    }
    const isAllReply = () => {
        return commentList.filter(value =>
            value.writerKey === MemberUtils.getLoginId() || value.writerKey === receiverKey).length >= 2
    }
    const isComplete = () => ["041904", "041903"].includes(statusCode);

    const onChangeMode = (e:any, isAdd: boolean) => {
        e.preventDefault()
        setIsAddMode(isAdd)
    }

    const getReviewAndAddBtn=() => {
        return !isComplete() &&
            (
                <>
                    <div className="button-area">
                        <a href="#" className={isAddMode ? "btn-complete" : "btn-complete active"}
                           onClick={e => onChangeMode(e, false)}>문의가 해결되었어요</a>
                        <a href="#" className={isAddMode ? "btn-add active" : "btn-add"}
                           onClick={e => onChangeMode(e, true)}>추가로 질문할래요</a>
                    </div>
                    {getReviewWrite()}
                </>
            )
    }

    const getReviewWrite = () => {
        return !isAddMode &&
            (
                <EduTalkReviewWrite bidx={parseInt(bidx ?? "0")} targetId={props.receiverKey} updateHandler={()=>{
                    setIsAddMode(true)
                    updateEvent()
                }}/>
            )
    }


    const getReplyAndAddCommentArea = () => {
        return (isAddMode || isComplete()) &&
            (
                <>
                    {
                        (isOnly() && !isComplete())
                        && (
                            <div className="comment type2">
                                <ProEduTalkCommentInput comment={null} updateEvent={updateEvent} enableTemp={false} isAddition={true}/>
                            </div>
                        )
                    }
                    {
                        !isOnly() && (
                            <div className="comment type2">
                                <ul className="comment-list">
                                    {
                                        commentList.map((value, index) => {
                                            return <ProEduTalkCommentRow key={index}
                                                                         comment={value}
                                                                         updateEvent={updateEvent}
                                                                         isEditable={!isAllReply() && !isComplete()}/>
                                        })
                                    }
                                </ul>
                            </div>
                        )
                    }
                </>
            )
    }

    const getReviewArea = () => {
        return isComplete() &&
            <ProEduTalkReview bidx={bidx ?? "0"} targetId={receiverKey} isReceiver={false}/>
    }

    const getAccordionClass = () => {
        if (isComplete()) {
            CommonJS.accordion()
            return "accordion active"
        }

        return "accordion-none active"
    }

    const downloadFile = async (e: any, fileVO: CommentFileVO) => {
        e.preventDefault()
        window.location.href = `${APIs.BOARD_COMMENTS_FILES_DOWN}${fileVO.cfidx}`
    }


    return (
        <section className="reply-section">
            <div className="inner">
                <div className={getAccordionClass()}>
                    <h3 className="tit-accordion">답변</h3>
                    <div className="accordion-content">
                        <div className="reply">
                            <div className="reply-util">
                                <div className="writer">
                                    <div className="image-wrap">
                                        <img
                                            src={FileUtils.getFileUrl(reply?.profileSrc ?? "")}
                                            onError={FileUtils.onErrorImg}
                                            alt="프로필 이미지" className="img-profile"/>
                                    </div>
                                    <div className="text-wrap">
                                        <span
                                            className="name"><b>{reply.writer}</b>{MemberUtils.getProStr(reply.writerRate ?? "")}</span>
                                        <span className="job">{reply.coName}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="reply-content">
                                <ul className="attach-list">
                                    {
                                        reply.commentFileList && reply.commentFileList?.map((value, index) => {
                                            return (
                                                <li key={index}>
                                                    <b className="tit">첨부파일 </b>
                                                    <a href="#"
                                                       onClick={e=>downloadFile(e,value)}>
                                                        <span className="path">{value.filename}</span>
                                                    </a>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                <div className="text-area" dangerouslySetInnerHTML={{__html:StringUtils.nl2br(reply.contentText ?? "")}}></div>
                                <span className="date">{moment(reply.updateDate).format("YYYY.MM.DD")}</span>
                            </div>
                            {getReviewAndAddBtn()}
                        </div>
                        {getReplyAndAddCommentArea()}
                    </div>
                </div>
                {getReviewArea()}
            </div>
        </section>
    )
}

export default ProEduTalkWriterReply
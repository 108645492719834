import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';

interface SummernoteIframeProps {
    mode: string;
    htmlCode?: string | null;
}

export interface SummernoteIframeHandle {
    getHtmlContent: () => string | null;
    getIframe: () => HTMLIFrameElement | null;
}

// eslint-disable-next-line react/display-name
const SummernoteIframe = forwardRef<SummernoteIframeHandle, SummernoteIframeProps>(({ mode, htmlCode }, ref) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    let observer: MutationObserver
    let imgObservers: HTMLImageElement[] = []

    useImperativeHandle(ref, () => ({
        getHtmlContent: () => {
            if (iframeRef.current && iframeRef.current.contentWindow) {
                const iframeDocument = iframeRef.current.contentWindow.document;
                const summernoteElement = iframeDocument.getElementById('summernote');

                if (summernoteElement) {
                    return (iframeRef.current.contentWindow as any).$('#summernote').summernote('code');
                }

            }
            return null;
        },

        getIframe: () => {
            if (iframeRef.current) {
                return iframeRef.current;
            }
            return null;
        }
    }));

    /**
     * 옵저버 설정
     */
    const setupObserver = () => {
        observer = new MutationObserver(() => {

            adjustIframeHeight()
        });

        if (iframeRef.current && iframeRef.current.contentWindow) {
            const iframeDocument = iframeRef.current.contentWindow.document;

            observer.observe(iframeDocument.body, {
                childList: true,
                subtree: true,
                attributes: true,
            })
        }
    };

    /**
     * 높이 조절
     */
    const adjustIframeHeight = () => {
        if (iframeRef.current && iframeRef.current.contentWindow) {
            const iframeWindow = iframeRef.current.contentWindow;
            const iframeDocument = iframeWindow.document;

            const body = iframeDocument.body
            if (body) {
                const computedStyle = iframeRef.current.contentWindow.getComputedStyle(body);
                const marginTop = parseInt(computedStyle.marginTop, 10);
                const marginBottom = parseInt(computedStyle.marginBottom, 10);

                const contentHeight = body.offsetHeight + marginTop + marginBottom;
                iframeRef.current.style.height = `${contentHeight}px`;
            }
        }
    };

    /**
     * HTML 코드 삽입
     * @param html
     */
    const handleDom = () => {
        if (iframeRef.current &&  iframeRef.current.contentWindow) {
            const iframeWindow = iframeRef.current.contentWindow;
            const iframeDocument = iframeWindow.document;
            let summernoteElement = null;
            if (mode === "edit") {
                summernoteElement = iframeDocument.querySelector('.note-editable');
            } else if (mode === "view") {
                summernoteElement = iframeDocument.getElementById('summernote');
            }

            if (summernoteElement) {
                summernoteElement.innerHTML = htmlCode || '';
            }

            // 옵저버 설정
            if (summernoteElement) {
                const imgElements = summernoteElement.getElementsByTagName('img');
                if (imgElements && imgElements.length > 0) {
                    Array.from(imgElements).forEach((img) => {
                        imgObservers.push(img)
                        img.addEventListener('load', adjustIframeHeight);
                    });
                } else {
                    adjustIframeHeight();
                }
            }

            adjustIframeHeight()
        }
    }

    // init
    useEffect(() => {
        setupObserver()
        const iframe = iframeRef.current;

        const handleLoad = () => {
            if (iframeRef.current && iframeRef.current.contentWindow) {
                const iframeDocument = iframeRef.current.contentWindow.document;
                iframeDocument.body.style.overflow = 'hidden'
                iframeDocument.body.style.margin = "0"
            }
        };

        iframe?.addEventListener("load", handleLoad);

        return () => {
            iframe?.removeEventListener("load", handleLoad);

            if (observer) {
                observer.disconnect();
            }

            imgObservers.forEach((img) => {
                    img.removeEventListener('load', adjustIframeHeight);
                }
            )
        };
    }, []);

    /**
     * 모드(mode) 변경 시
     */
    useEffect(() => {
        const baseUrl = "/elandedu/assets/summernote/";
        const html = mode === "edit" ? "summernote_form.html" : "summernote_view.html";
        const url = `${baseUrl}${html}`;

        if (iframeRef.current) {
            iframeRef.current.src = url;
        }
    }, [mode])

    /**
     * htmlCode 변경 시
     */
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (iframeRef.current && iframeRef.current.contentWindow) {
                const iframeWindow = iframeRef.current.contentWindow;
                const iframeDocument = iframeWindow.document;
                let summernoteElement = null;
                if (mode === "edit") {
                    summernoteElement = iframeDocument.querySelector('.note-editable');
                } else if (mode === "view") {
                    summernoteElement = iframeDocument.getElementById('summernote');
                }

                // 'summernote' 요소가 존재하는지 확인 후 handleDom 호출
                if (summernoteElement) {
                    clearInterval(intervalId); // 확인 후 반복 종료
                    handleDom();
                }
            }
        }, 200);

        // Clean up
        return () => {
            clearInterval(intervalId);
        };
    }, [htmlCode]);

    return (
        <div style={{width: '100%', height: mode === 'edit' ? '640px' : 'auto', overflow: 'hidden'}}>
            <iframe
                ref={iframeRef}
                style={{width: '100%', height: '100%', border: 'none', overflow: 'hidden'}}
                title="Summernote Editor"
            />
        </div>
    );
});

export default SummernoteIframe;

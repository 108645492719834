import React from "react";


interface ProPaginationProps {
    page: number;
    totalCount: number;
    updatePages:(page: number) => void;
    size?: number
}

const M_Pagination = (props: ProPaginationProps) => {
    const {page, totalCount, updatePages} = props;
    const size:number = props.size ?? 10
    const totalPages = Math.ceil(totalCount / size)
    const maxDisplay = 10;

    let startPage = Math.max(0, page - Math.floor(maxDisplay / 2));
    let endPage = Math.min(totalPages - 1, startPage + maxDisplay - 1);

    const setPageLinks = () => {
        const pageLinks = []

        if (startPage == endPage){
            return (
                <a href="#"
                   className={'page-item active'}>1</a>
            )
        }
        for (let i = startPage; i <= endPage; i++) {
            pageLinks.push(
                <a href="#"
                   key={i}
                   onClick={(e) => {
                       e.preventDefault()
                       updatePages(i)
                   }}
                   className={page === i ? 'page-item active' : 'page-item'}>{i + 1}</a>
            )
        }
        return pageLinks
    }

    return (
        <div className="pagination">
            <a href="#"
               className={page != 0 ? 'page-prev' : 'page-prev disabled'}
               onClick={(e) => {
                   e.preventDefault()
                   if (page > 0) {
                       updatePages(page - 1)
                   }
               }}>
                <span className="blind">이전 페이지</span>
            </a>
            <div className="page-list">
                {
                    setPageLinks()
                }
            </div>
            <a href="#"
               className={page != totalPages - 1 ? 'page-next' : 'page-next disabled'}
               onClick={(e) => {
                   e.preventDefault()
                   if (page != totalPages - 1) {
                       updatePages(page + 1)
                   }
               }}>
                <span className="blind">다음 페이지</span>
            </a>
        </div>
    )
}

export default M_Pagination;
import React, {useEffect, useState} from 'react'
import useFooter from "../../../common/hook/useFooter";
import useHeader from "../../../common/hook/useHeader";
import {HeaderBtn} from "../../../core/common/header";
import FileUtils from "../../../common/utils/file.utils";
import {SessionVO} from "../../../core/common/session.vo";
import MemberUtils from "../../../common/utils/member.utils";
import ElandUtils from "../../../common/utils/eland.utils";
import NumberFormatter from "../../pc/common/NumberFormatter";
import ProService from "../../../core/pro/pro.service";
import axiosCaller from "../../../common/utils/axios.caller";
import APIs from "../../../common/apis/APIs";
import SwalUtils from "../../../common/utils/swal.utils";
import {CompanyService} from "../../../core/company/company.service";

/*
    http://khane7.test-site.kr/elandedu_m/html/5_%EB%A7%88%EC%9D%B4%ED%8E%98%EC%9D%B4%EC%A7%80/5-1_%EB%A7%88%EC%9D%B4%ED%8E%98%EC%9D%B4%EC%A7%80.html
 */
const M_Mypage = () => {

    useHeader({
        type: 'sub',
        title: "마이페이지",
        buttons: [HeaderBtn.Notify, HeaderBtn.Menu]
    });

    useFooter({
        isHidden: false
    })

    const [accountVO, setAccountVO] = useState<SessionVO | null>(null);

    // 학습포인트
    const [myPoint, setMyPoint] = useState<number>(0);

    const getPicture = () =>{
        return FileUtils.getFileUrl(accountVO?.profileSrc ?? "")
    }

    // 계정정보 조회
    const getAccount = async () => {
        const account = MemberUtils.getSessionVO()
        if (account?.jobCode) {
            let jobCode = account?.jobCode.length > 6 ? account?.jobCode.slice(0, -2) : account?.jobCode
            account.jobName = await ElandUtils.getJobSkillCodeName(jobCode)
        }

        setAccountVO(account)
    }

    // 로그아웃
    const doLogout = async (e:React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()

        SwalUtils.text({
            title : "로그아웃",
            message : "로그아웃 하시겠습니까?",
            isIcon : false,
            confirmOptions : {
                text : "로그아웃",
                eventHandler : async () => {
                    const result = await axiosCaller.post(APIs.SESSION + "/logout", {})

                    if (result.status === "SUCCESS") {
                        clearLocalStorageExcept(['isRemember', 'rememberUserId', 'savedJobSkillDate'])
                        sessionStorage.clear()
                        window.location.href = "/"
                    } else {
                        ProService.failHandler(result)

                    }
                }
            },
            cancelOptions : {
                text : "취소",
                eventHandler : () => {
                    console.log("취소")
                }
            },
            isCancel: true
        })
    }

    function clearLocalStorageExcept(keysToKeep: string[]): void {
        const savedValues: { [key: string]: string | null } = keysToKeep.reduce((acc, key) => {
            acc[key] = localStorage.getItem(key);
            return acc;
        }, {} as { [key: string]: string | null });

        localStorage.clear();

        keysToKeep.forEach(key => {
            if (savedValues[key] !== null) {
                localStorage.setItem(key, savedValues[key]!);
            }
        });
    }

    // init
    useEffect(() => {
        getAccount()

        const fetchMyPoint = async () => {
            const point = await MemberUtils.getMyPoint();
            setMyPoint(point);
        };

        fetchMyPoint()
    }, []);

    return (
        <main id="mypage-main" className="content mypage">
            <section className="list-section">
                <div className="inner">
                    <div className="profile-area">
                        <img src={getPicture()}
                             onError={FileUtils.onErrorImg}
                             alt="프로필 이미지" className="img-profile"/>
                        <div className="text-wrap">
                            <strong className="name">{accountVO?.userName}</strong>
                            <a href="/mypage/management" className="link-info">내 정보 관리</a>
                        </div>
                    </div>
                    <div className="info-list">
                        <ul>
                            <li>
                                <strong>학습포인트</strong>
                                <a href="/mypage/point"><NumberFormatter number={myPoint}/> P</a>
                            </li>
                            <li>
                                <strong>직무역량진단</strong>
                                {
                                    MemberUtils.isCompleteJobSkill() ?
                                        // [6-3.직무역량진단 결과]화면으로 이동
                                        <a href="/mypage/jobskill">
                                            { accountVO?.jobName }
                                        </a> :
                                        // [6-1 . 직무역량진단 소개] 화면으로 이동
                                        <a href="/mypage/jobskill" className="link-test">진단하기</a>
                                }
                            </li>
                        </ul>
                    </div>
                    <div className="menu-list">
                        <div>
                            <strong>나의 강의실</strong>
                            <ul>
                                <li>
                                    <a href="/education/dashboard">대시보드</a>
                                </li>
                                <li>
                                    <a href="/education/inprogress">코스학습</a>
                                </li>
                                <li>
                                    <a href="/education/storagebox">보관함</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <strong>고객센터</strong>
                            <ul>
                                <li>
                                    <a href="/mypage/board/announce">공지사항</a>
                                </li>
                                <li>
                                    <a href="/mypage/frequently">자주 묻는 질문</a>
                                </li>
                            </ul>
                        </div>
                        <a href="#" className="btn gray" onClick={doLogout}>로그아웃</a>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default M_Mypage
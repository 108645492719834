import React, {useEffect, useState, useCallback} from "react";
import ProReviewItem from "./ProReviewItem";
import Pagination from "../common/Pagination";
import {CompanyAccountVO} from "../../../core/company/company.vo";
import {HWMap} from "../../../core/common/common.vo";
import {PageUtils} from "../../../common/utils/page.utils";
import ReviewService from "../../../core/review/review.service";
import {ReviewListItemVO} from "../../../core/review/review.vo";
import {ReviewType} from "./ReviewType";

interface ProReviewProps {
    eduTalkVO: CompanyAccountVO
}


const ProReview = (props: ProReviewProps) =>{
    const [params, setParams] = useState<HWMap>({
        ...new PageUtils(),
        size:5,
        type:ReviewType.EDU_TALK,
        targetId:props.eduTalkVO.coEmailId
    })
    const [totalCount, setTotalCount] = useState<number>(0)
    const [reviewList, setReviewList] = useState<ReviewListItemVO[]>([])

    const getReviewList = async () =>{
        if (params.targetId !== ''){
            const result = await ReviewService.getReviewList(params);
            if (result.status === 'SUCCESS') {
                setTotalCount(result.count);
                setReviewList(result.list);
            }
        }
    }

    // 페이지 누를 때 업데이트하는 함수
    const updatePages = (page:number) => {
        setParams((params) => ({
            ...params,
            page: page
        }));
    };

    useEffect(() => {
        getReviewList();

    }, [params]);


    return (
        <section>
            <div className="inner">
                <div className="section-title">
                    <h3 className="tit">고수 한줄평</h3>
                    <span className="num"><b>{totalCount}</b>건</span>
                </div>
                <div className="comment type3">
                    <div>
                        { reviewList.length === 0 ? (
                            <div className="content-empty">
                                <p className="txt">아직 작성된 한줄평이 없습니다.</p>
                            </div>
                        ) : (
                            <>
                                <ul className="comment-list">
                                    {/* M_ProReviewItem 컴포넌트를 배열의 각 요소로 렌더링 */}
                                    {reviewList.map((review, index) => (
                                        <ProReviewItem key={index} itemVO={review} eduTalkVO={props.eduTalkVO} updateHandler={getReviewList}></ProReviewItem>
                                    ))}
                                </ul>

                                {/* 페이지네이션 컴포넌트 */}
                                <Pagination page={params.page} totalCount={totalCount} updatePages={updatePages} size={params.size}/>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProReview
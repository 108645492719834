import React, {useEffect, useState} from "react";
import {BoardVO} from "../../../../core/board/board.vo";
import {HWMap} from "../../../../core/common/common.vo";
import {PageUtils} from "../../../../common/utils/page.utils";
import StringUtils from "../../../../common/utils/string.utils";
import BoardService from "../../../../core/board/board.service";
import APIs from "../../../../common/apis/APIs";
import Gallery from "../../board/listType/Gallery";
import NullItem from "../../board/item/NullItem";
import Pagination from "../../common/Pagination";
import ElandUtils from "../../../../common/utils/eland.utils";


interface KnowledgeListProps {
    searchText: string;
    setTotalCount: (count: number) => void;
}

const KnowledgeList : React.FC<KnowledgeListProps> = ({searchText, setTotalCount}) => {

    const [knowList, setKnowList] = React.useState<BoardVO[]>([]);
    const [count, setCount] = React.useState(0);
    const [params, setParams] = useState<HWMap>(new PageUtils())
    const [sortBoardType, setSortBoardType] = useState<string>('all');
    const boardService = new BoardService();


    useEffect(() => {
        if(searchText) {
            setParams({
                page: 0,
                size: 12,
                sort: "createDate,DESC",
                searchText: searchText,
                boardKey: findSortBoardKey()
            });
        }
    }, [sortBoardType, searchText]);

    // getSearchBoards

    useEffect(() => {
        if (params && params.size && params.boardKey != '') {

            (async () => {
                const result = await boardService.getSearchBoards(params);
                if(result.status == "SUCCESS" && result.data) {
                    const updatedBoardList = await ElandUtils.convertJobSkillNames4BoardList(result.data.list);
                    setKnowList(updatedBoardList)
                    setCount(result.data.totalCount)
                    setTotalCount(result.data.totalCount)
                }
            })()
        }
    }, [params])

    useEffect(() => {

    }, [sortBoardType]);

    // 페이지 업데이트
    const updatePages = (newPage: number) => {
        setParams(prevParams => ({
            ...prevParams,
            page: newPage
        }));
    }

    const removeKeyFromString = (inputString: string) => {
        const knowKeysArray = APIs.KNOWLEDGE_BOARDKEY.split(',');
        const keysArray = inputString.split(',');
        const filteredArray = knowKeysArray.filter(item => !keysArray.includes(item));
        return filteredArray.join(',');
    }

    const findSortBoardKey = () => {
        switch (sortBoardType) {
            case 'all' :
                return removeKeyFromString("job_data,use_story")
            case 'ebg' :
                return "ebg_story";
            case 'pro' :
                return "pro_knowledge";
            case 'etc' :
                return removeKeyFromString("ebg_story,pro_knowledge,use_story,job_data")
            default :
                return removeKeyFromString("job_data,use_story")
        }
    }

    return (
        <section className="list-section">

            <div className="inner">
                <div className="section-title">
                    <h3 className="tit">지식콘텐츠</h3>
                    <div className="board-sort">
                        <div className="sort-list">
                            <a href="#"
                               onClick={(e) => {
                                   e.preventDefault();
                                   setSortBoardType('all');
                               }}
                               className={sortBoardType == 'all' ? 'active' : ''}>
                                전체
                            </a>
                            <a href="#"
                               onClick={(e) => {
                                   e.preventDefault();
                                   setSortBoardType('ebg');
                               }}
                               className={sortBoardType == 'ebg' ? 'active' : ''}>
                                EBG성공스토리
                            </a>
                            <a href="#"
                               onClick={(e) => {
                                   e.preventDefault();
                                   setSortBoardType('pro');
                               }}
                               className={sortBoardType == 'pro' ? 'active' : ''}>
                                사내고수의 지식
                            </a>
                            <a href="#"
                               onClick={(e) => {
                                   e.preventDefault();
                                   setSortBoardType('etc');
                               }}
                               className={sortBoardType == 'etc' ? 'active' : ''}>
                                기타
                            </a>
                        </div>
                    </div>
                </div>
                <div className="board-list">

                    {knowList?.length > 0 ? <Gallery paramMap={{boardList: knowList}}/> : <NullItem/>}

                    {/* 페이지네이션 */}
                    {knowList?.length > 0 &&
                    <Pagination page={params.page} totalCount={count} updatePages={updatePages} size={params.size}/>}

                </div>
            </div>

        </section>
    )
}

export default KnowledgeList;
import React, { useEffect, useRef, useState } from "react";
import SwiperInit from "swiper";
import {Navigation, Pagination} from "swiper/modules";
import { Swiper as SwiperType } from "swiper";
import {BoardFileVO} from "../../../core/board/board.vo";
import FileUtils from "../../../common/utils/file.utils";
import {CardFileVO} from "../../../core/education/education.vo";

interface ModalCardSlideProps {
    cardList: BoardFileVO[] | CardFileVO[];
    selectedSlideIndex: number;
    closeModal: () => void;
}

const ModalCardSlide: React.FC<ModalCardSlideProps> = ({ cardList, selectedSlideIndex, closeModal }) => {
    const swiperRef = useRef<SwiperType | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const initSwiper = () => {
        if (swiperRef.current) {
            swiperRef.current.destroy(true, true);
        }

        SwiperInit.use([Navigation]);

        swiperRef.current = new SwiperInit(".media-modal .slide-wrap", {
            navigation: {
                nextEl: ".btn-next",
                prevEl: ".btn-prev",
            },
            loop: true,
            initialSlide: selectedSlideIndex, // 인덱스로 시작
        });
    };

    useEffect(() => {
        if (selectedSlideIndex !== null) {
            setIsModalOpen(true);
            setTimeout(initSwiper, 20);
        }
    }, [selectedSlideIndex]);

    return (
        <>
            {isModalOpen && (
                <div className="media-modal" style={{ display: isModalOpen ? "block" : "none" }}>
                    <div className="inner">
                        <div className="slide-wrap">
                            <ul className="slide-list swiper-wrapper">
                                {
                                    cardList.map((item: BoardFileVO | CardFileVO, index: number) => (
                                        <li key={index} className="slide-item swiper-slide" style={{height: "100vh"}}>
                                            <a style={{width: '100%'}}>
                                                <img
                                                    onContextMenu={(e)=> {e.preventDefault()}}
                                                    src={FileUtils.getImageSrc(item)}
                                                     alt=""
                                                     className="img-cardnews" style={{
                                                    objectFit: "contain",
                                                    width: "100%"
                                                }}/>
                                            </a>
                                        </li>
                                    ))
                                }
                            </ul>
                            <div className="slide-page"></div>
                            <div className="btn-prev"></div>
                            <div className="btn-next"></div>
                        </div>
                        <button type="button" className="btn-close" style={{zIndex:'1'}} onClick={()=>closeModal()}></button>
                    </div>
                </div>
            )}
        </>
    );
}

export default ModalCardSlide;

import React, {useEffect, useState} from "react";
import BoardService from "../../../../core/board/board.service";
import {BoardVO, DefaultBoardInfoVO} from "../../../../core/board/board.vo";
import M_QuestionRow from "./M_QuestionRow";
import {BoardInfoVO} from "../../../../core/board/BoardInfo.vo";
import useHeader from "../../../../common/hook/useHeader";
import {HeaderBtn} from "../../../../core/common/header";
import useFooter from "../../../../common/hook/useFooter";

const M_FrequentlyQuestion = () => {
    useHeader({
        type: 'content',
        title: '자주묻는질문',
        buttons: [HeaderBtn.Close],
    })
    useFooter({isHidden:true})

    const [noticeMap, setNoticeMap] = useState<BoardVO[]>([])
    const [boardMap, setBoardMap] = useState<BoardVO[]>([])
    const [boardInfo, setBoardInfo] = useState<BoardInfoVO>(DefaultBoardInfoVO())

    useEffect(() => {
        getQuestion()

    }, []);

    const getQuestion = async () => {
        const bs = new BoardService()
        const boardList = await bs.getBoardList({boardKey: "frequently"});

        if (boardList.status === "ok"){
            setNoticeMap(boardList.noticeMap.list)
            setBoardMap(boardList.boardMap.list)
            setBoardInfo(boardList.boardInfoVO)
        } else {
            console.log("오류발생")
            console.log(boardList.msg)
        }

    }

    return (
        <div className="content faq">
            <section className="list-section">
                <div className="inner">
                    <ul className="faq-list">
                        {
                            noticeMap.map((value, index) => {
                                return (<M_QuestionRow key={value.idx} {...value}/>)
                            })
                        }
                        {
                            boardMap.map((value, index) => {
                                return (<M_QuestionRow key={value.idx} {...value}/>)
                            })
                        }
                    </ul>
                </div>
            </section>
        </div>
    )
}

export default M_FrequentlyQuestion
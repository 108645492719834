import React, {useEffect} from "react";
import {Chart} from "chart.js/auto";

interface Dataset {
    label: string;
    data: number[];
    backgroundColor: string;
    borderRadius: any;
    borderSkipped: boolean;
    barPercentage: number;
}

interface M_PointChartProps {
    course: number,
    knowledge: number,
    pro: number,
    etc: number
}

const M_PointChart: React.FC<M_PointChartProps> = ({ course, knowledge, pro, etc }) => {

    useEffect(() => {
        setChart();
    }, [course, knowledge, pro, etc]);

    const setChart = () => {
        const myChart1 = document.getElementById('myChart1') as HTMLCanvasElement;

        if (Chart.getChart(myChart1)) {
            Chart.getChart(myChart1)?.destroy();
        }

        // 코스학습
        let courseData = course > 0 ? course : 0
        // 지식학습
        let knowledgeData = knowledge > 0 ? knowledge : 0
        // 사내고수
        let proData = pro > 0 ? pro : 0
        // 기타
        let etcData = etc > 0 ? etc : 0

        // 총합 계산
        const totalData = courseData + knowledgeData + proData + etcData;

        const coursePercent = totalData > 0 ? (courseData / totalData) * 100 : 0;
        const knowledgePercent = totalData > 0 ? (knowledgeData / totalData) * 100 : 0;
        const proPercent = totalData > 0 ? (proData / totalData) * 100 : 0;
        const etcPercent = totalData > 0 ? (etcData / totalData) * 100 : 0;

        const minData = 5
        // 최소 값 설정
        const normalizedData = {
            course: coursePercent < minData ? minData : coursePercent,
            knowledge: knowledgePercent < minData ? minData : knowledgePercent,
            pro: proPercent < minData ? minData : proPercent,
            etc: etcPercent < minData ? minData : etcPercent,
        };

        // 100% 기준으로 재조정
        const normalizedTotal = normalizedData.course + normalizedData.knowledge + normalizedData.pro + normalizedData.etc;
        const finalData = {
            course: (normalizedData.course / normalizedTotal) * 100,
            knowledge: (normalizedData.knowledge / normalizedTotal) * 100,
            pro: (normalizedData.pro / normalizedTotal) * 100,
            etc: (normalizedData.etc / normalizedTotal) * 100,
        };

        const datasets: Dataset[] = [
            {
                label: "코스학습",
                data: [finalData.course],
                backgroundColor: "#EF743C",
                borderRadius: 0,
                borderSkipped: false,
                barPercentage: 1
            },
            {
                label: "지식학습",
                data: [finalData.knowledge],
                backgroundColor: "#4D74FF",
                borderRadius: 0,
                borderSkipped: false,
                barPercentage: 1
            },
            {
                label: "사내고수",
                data: [finalData.pro],
                backgroundColor: "#43C478",
                borderRadius: 0,
                borderSkipped: false,
                barPercentage: 1
            },
            {
                label: "기타활동",
                data: [finalData.etc],
                backgroundColor: "#FFCC12",
                borderRadius: 0,
                borderSkipped: false,
                barPercentage: 1
            },
        ];

        // 첫 값 처리
        for (let i = 0; i < datasets.length; i++) {
            if (datasets[i].data[0] > 0) {
                datasets[i].borderRadius = {
                    topLeft: 14,
                    bottomLeft: 14
                };
                break;
            }
        }

        // 마지막 값 처리
        for (let i = datasets.length - 1; i >= 0; i--) {
            if (datasets[i].data[0] > 0) {
                datasets[i].borderRadius = {
                    ...datasets[i].borderRadius,
                    topRight: 14,
                    bottomRight: 14
                };
                break;
            }
        }


        new Chart(myChart1, {
            type: 'bar',
            data: {
                labels: ["포인트"],
                datasets: datasets,
            },
            options: {
                indexAxis: "y",
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    },
                    // stacked100: {
                    //     enable: true
                    // }
                },
                layout: {
                    padding: {
                        left: -20,
                        bottom: -8
                    },
                },
                maintainAspectRatio: false,
                scales: {
                    x: {
                        bounds: "data",
                        stacked: true,
                        border: {
                            display: false,
                        },
                        grid: {
                            display: false,
                            drawOnChartArea: false,
                        },
                        ticks: {
                            display: false
                        },
                    },
                    y: {
                        bounds: "data",
                        stacked: true,
                        border: {
                            display: false,
                        },
                        grid: {
                            display: false,
                            // drawOnChartArea: false,
                            // drawBorder: false,
                        },
                        ticks: {
                            backdropPadding: 0,
                            display: false
                        },
                    },
                }
            }
        });
    }

    return (
        <div className="chart" style={{ width : "100%" }}>
            {
                course == 0 && knowledge == 0 && pro == 0 && etc == 0 && etc == 0 &&
                <div className="chart-empty"></div>
            }
            <canvas id="myChart1"></canvas>
        </div>
    );
}

export default M_PointChart;
import React, {useEffect, useRef, useState} from "react";
import {CodeVO, DefaultCodeVO} from "../../../core/common/code.vo";
import {JobSkillReport} from "../../../core/jobskill/jobskill.vo";
import JobSkillService from "../../../core/jobskill/jobskill.service";
import {Link} from "react-router-dom";
import ElandUtils from "../../../common/utils/eland.utils";
import M_JobSkillResultRankItem from "../mypage/jobskill/result/M_JobSkillResultRankItem";
import M_JobSkillResultRadarChart from "../mypage/jobskill/result/M_JobSkillResultRadarChart";
import type { Swiper as SwiperType } from 'swiper';
import SwiperInit from 'swiper';


const M_MainJobSkillResult = () => {
    const [skillCodeList, setSkillCodeList] = useState<CodeVO[]>([])
    const [resultReport, setResultReport] = useState<JobSkillReport>({
        currentAvg: 0,
        jobCode: DefaultCodeVO(),
        parentCode: DefaultCodeVO(),
        lastAvg: 0,
        list: [],
        totalCount: 0,
    })

    const resultsSwiperRef = useRef<SwiperType | null>(null);

    useEffect(() => {
        const getSkillCodeList = async () => {
            setSkillCodeList(await ElandUtils.getJobSkillCodeList(true));
        }

        const successHandler = (result: any) => {
            setResultReport(result.data)
        }

        getSkillCodeList()
        JobSkillService.jobSkillResultGet(successHandler)


    }, []);

    useEffect(() => {
        resultsSwiperRef.current?.destroy(true,true)

        resultsSwiperRef.current = new SwiperInit(".results-section .rank-slide", {
            slidesPerView: "auto",
            spaceBetween: 16,
        })

    }, [resultReport]);

    return (
        <div className="results-section">
            <div className="inner">
                <div className="section-title">
                    <h2 className="tit">직무역량진단</h2>
                    <Link to={"/mypage/jobskill"} className="link-more">더보기</Link>
                </div>
                <div className="results-wrap">
                    <M_JobSkillResultRadarChart resultReport={resultReport.list} skillCodeList={skillCodeList}/>
                    <div className="rank-slide">
                        <ul className="rank-list swiper-wrapper">
                            {
                                resultReport.list
                                    .filter((value, index) => index < 4)
                                    .map(val => {
                                        const codeName = skillCodeList.find(code => code.code === val.skillCode)?.codeName ?? "";
                                        return <M_JobSkillResultRankItem key={val.skillCode}
                                                                         rank={val.rank}
                                                                         totalCount={val.totalCount}
                                                                         skillName={codeName}
                                                                         linkValid={true}
                                                                         score={val.score}/>
                                    })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default M_MainJobSkillResult
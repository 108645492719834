import React, {useEffect, useState} from "react";
import {ProReqVO} from "../../../../core/pro/pro.vo";
import APIs from "../../../../common/apis/APIs";
import CodeUtils from "../../../../common/utils/code.utils";
import {Link} from "react-router-dom";
import {CodeVO} from "../../../../core/common/code.vo";
import FileUtils from "../../../../common/utils/file.utils";
import ElandUtils from "../../../../common/utils/eland.utils";

export const M_ProFindCard = (props:{proReq:ProReqVO, saveEvent:()=>void}) => {
    const [proReq, setPro] = useState<ProReqVO>(props.proReq);
    const [reviewCodeList, setReviewCodeList] = useState<CodeVO[]>([])

    useEffect(()=>{
        const renderCode = async () =>{
            const newProReq = {...proReq}
            newProReq.jobCodeName = await ElandUtils.getJobSkillCodeName(proReq.jobCode)
            newProReq.reviewCode && getCodeListFromCodeStr(newProReq.reviewCode)
            setPro(newProReq)
        }

        renderCode()

    },[])


    const getCodeListFromCodeStr = async (codes: string) => {
        if (codes === '') {
            return
        }
        const reviewCodeList = await CodeUtils.getReviewCodeList();
        const codeVOList:CodeVO[] = codes.split(',')
            .map(codeStr => {
                return reviewCodeList.find(codeVO => codeVO.code === codeStr);
            })
            .filter((codeVO): codeVO is CodeVO => codeVO !== undefined)

        setReviewCodeList(codeVOList)
    }

    // 키워드 가져오기
    function getBadgeTag(codeVO:CodeVO, index:number) {
        // 뒤에서문자 추출
        const secondToLastChar = codeVO.code.charAt(5);

        // 숫자로 변환
        const number = parseInt(secondToLastChar, 10);
        let className = "badge type1 "

        if (number % 10 === 0 || number % 9 === 0) {
            className += "red"
        } else if (number % 8 === 0 || number % 7 === 0) {
            className += "blue"
        } else if (number % 6 === 0 || number % 5 === 0) {
            className += "orange"
        } else if (number % 4 === 0 || number % 3 === 0) {
            className += "green"
        } else {
            className += "purple"
        }

        return <span key={index} className={className}>{codeVO.codeName}</span>
    }


    return (
        <li>
            <Link onClick={e=>{props.saveEvent()}} to={"/pro/" + proReq.proidx}>
                <div className="profile-wrap">
                    <div className="img-wrap">
                        <img src={FileUtils.getFileUrl(proReq.profileImgSrc)} alt=""
                             onError={FileUtils.onErrorImg}
                             className="img-profile"/>
                    </div>
                    <div className="text-wrap">
                        <strong className="name">{proReq.companyAccountVO?.userName}</strong>
                        <div className="job">
                            <span>{proReq.companyAccountVO?.coInfoVO?.coName}</span>
                            <span>{proReq.jobCodeName}</span>
                        </div>
                    </div>
                </div>
                <div className="info-wrap">
                    <ul className="info-list">
                        <li>
                            <span className="txt">팔로워 수</span>
                            <span className="num">{proReq.followerCount}</span>
                        </li>
                        <li>
                            <span className="txt">상담지수</span>
                            <span className="num">{proReq.chatCount}</span>
                        </li>
                        <li>
                            <span className="txt">지식업로드</span>
                            <span className="num">{proReq.uploadCount}</span>
                        </li>
                    </ul>
                </div>
                <div className="keyword-wrap">

                    <div className="keyword-list">
                        <span className="keyword-span pro-find">
                            {
                                proReq.strengthKeyword.split(',').map((value, index)=>{
                                    if (value){
                                        return ('#' + value)
                                    }
                                }).join(", ")
                            }
                        </span>
                    </div>
                    <div className="keyword-list">
                        <span className="keyword-span pro-find">
                            {
                                proReq.officialKeyword.split(',').map((value, index)=>{
                                    if (value){
                                        return ('#' + value)
                                    }
                                }).join(", ")
                            }
                        </span>
                    </div>
                    <div className="badge-list">
                        {
                            reviewCodeList.map((codeVO, index) => {
                                if (!codeVO) {
                                    return
                                }
                                return getBadgeTag(codeVO, index)
                            })
                        }
                    </div>
                </div>
            </Link>
        </li>
    );
}

export default M_ProFindCard;
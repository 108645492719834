import React, {useEffect, useState} from "react";
import {HWMap} from "../../../../core/common/common.vo";
import {M_EduContentIndex, M_EduTitleDom} from "../M_EduMainDetail";
import {
	AnsFileVO,
	EduAnswerVO,
	EduContentVO,
	EduFileVO,
	EduMainVO,
	EduStudentVO,
	QstFileVO
} from "../../../../core/education/education.vo";
import educationService from "../../../../core/education/education.service";
import EducationService from "../../../../core/education/education.service";
import DateUtils from "../../../../common/utils/date.utils";
import StringUtils from "../../../../common/utils/string.utils";
import FileUtils from "../../../../common/utils/file.utils";
import APIs from "../../../../common/apis/APIs";
import ToastUtils from "../../../../common/utils/toast.utils";
import PointService from "../../../../core/point/point.service";
import WebUtils from "../../../../common/utils/web.utils";


const M_QstFile:React.FC<{paramMap:HWMap}> = ({paramMap}) => {
	
	const [requestStep, setRequestStep] = useState()
	const [eduMainVO, setEduMainVO] = useState<EduMainVO | null>(null)
	const [contentList, setContentList] = useState<EduContentVO[] | null>(null)
	const [eduContentVO, setContentVO] = useState<EduContentVO | null>(null)
	
	const [qstFileVO, setQstFile] = useState<QstFileVO | null>(null)
	const [eduFileList, setEduFileList] = useState<EduFileVO[] | null>(null)
	
	const [studentVO, setStudentVO] = useState<EduStudentVO | null>(null)
	const [eduAnswerVO, setEduAnswerVO] = useState<EduAnswerVO | null>(null)
	const [ansFileList, setAnsFileList] = useState<AnsFileVO[] | null>(null)

	const [isLoading, setIsLoading] = useState(false);


	useEffect(() => {
		
		if ( paramMap.eduMainVO ) {
			
			setRequestStep(paramMap.requestStep)
			setEduMainVO(paramMap.eduMainVO)
			setContentList(paramMap.contentList)
			setContentVO(paramMap.eduContentVO);
			
			setStudentVO( paramMap.studentVO )
			setEduAnswerVO( paramMap.answerList[paramMap.requestStep] )
			setAnsFileList( paramMap.answerList[paramMap.requestStep]?.ansFileList )
			
			getQstFile();
		}
		
	}, [paramMap])
	
	
	/**
	 * 파일 컨텐츠의 전체내용 조회
	 */
	const getQstFile = async () => {
		const result = await educationService.getContentDetail({
			ecIdx: paramMap.eduContentVO.ecIdx
		})
		setQstFile(result.qstFileVO)
		setEduFileList(result.eduFileList)
	}
	
	
	
	const doDownload = async (_fidx:number, _afIdx:number) => {
		if (isLoading) return
		setIsLoading(true)

		try {
			const eduFile = eduFileList?.find(_file => _file.fidx === _fidx)
			EducationService.setAnsRecord(eduContentVO!.ecIdx, `DOWNLOAD ${eduFile!.filename}`, "file")
			await FileUtils.doDownload(`${APIs.API_URL}/api/edu/file/download/${_fidx}?afIdx=${_afIdx}`, eduFile!.filename)
			//location.href = `${APIs.API_URL}/api/edu/file/download/${_fidx}?afIdx=${_afIdx}`

			/**
			 * 다운받지 않은 파일 존재 확인
			 */
			ansFileList?.forEach(ansFile => {
				if (ansFile.fidx === _fidx) {
					ansFile.isDownload = true;
				}
			});
			const remainingFiles = ansFileList?.filter(ansFile => ansFile.isDownload === false);

			/**
			 * 학습가능상태 확인
			 */
			if ( paramMap.isPossible ) {

				/**
				 * 전부 다운로드 했을 경우 답변상태 변경 및 화면 갱신
				 */
				if ( !remainingFiles || remainingFiles.length <= 0 ) {
					const result = await educationService.setAnswerStatus({
						eaIdx : eduAnswerVO!.eaIdx,
						isResult : false,
						isComplete : true,
					})
					if ( result.status === "SUCCESS" ) {
						ToastUtils.show(`${eduContentVO?.qstFileVO.fileTitle} 완료되었습니다.`)

						// 포인트적립 : 학습 컨텐츠 완료
						await PointService.savePoint("COURSE_VIEW", eduContentVO!.ecIdx)

						paramMap._retrievePage(result.allComplete)
					}
				} else {
					await paramMap._retrievePage()
				}
				// await getQstFile()
			}
		} catch (error) {
			WebUtils.log(error)
		} finally {
			setTimeout(() => {
				setIsLoading(false)
			}, 3000)
		}
	}
	
	
	
	
	return (
		<>
			<section className="detail-section type-survey">
				{/*title*/}
				<div className="detail-title">
					<div className="inner">
						<M_EduTitleDom paramMap={{
							mainTitle: eduMainVO?.eduTitle,
							contentTitle: qstFileVO?.fileTitle,
							startDate : DateUtils.displayEduDate(eduMainVO?.scheduleMode, eduMainVO?.startDate),
							endDate : DateUtils.displayEduDate(eduMainVO?.scheduleMode, eduMainVO?.endDate),
							introduce : eduMainVO?.introduce
						}} />
						<div className="survey-content">
							<div className="attach-content">
								<ul className="file-list">
									{
										eduFileList?.map((file, index) => {
											
											const ansFile = ansFileList?.find(ansFile => ansFile.fidx === file.fidx)
											
											return (
												<li key={index}>
													<a href="#" onClick={(e) => {
														e.preventDefault()
														doDownload(file.fidx, ansFile!.afIdx)
													}}>
														<strong className="tit">{file.filename}</strong>
													</a>
													<div className="info">
														<span>{DateUtils.formatDate(file.createDate)}</span>
														<span>{StringUtils.formatBytes(file.filesize)}</span>
													</div>
													{
														ansFile?.isDownload && <span className="state">다운로드 완료</span>
													}
												</li>
											)
										})
									}
								</ul>
							</div>
						</div>
						<h2 className="tit">{qstFileVO?.fileTitle}</h2>
						
						<M_EduContentIndex paramMap={paramMap} ></M_EduContentIndex>
						
					</div>
				</div>
				<div className="detail-content">
					<div className="inner">
						<div className="tabs">
							<ul className="tab-menu">
								<li className="tab-item explain active">
									<a href="#" onClick={e => e.preventDefault()}>파일 설명</a>
								</li>
							</ul>
							<div className="tab-panel tab-explain">
								<div className="text-area" dangerouslySetInnerHTML={{__html:StringUtils.nl2br(qstFileVO?.fileDesc) || ""}}></div>
							</div>
						</div>
					</div>
				</div>
			</section>
			
		</>
	)
}
export default M_QstFile
import React, {useEffect, useRef, useState} from "react";
import {CodeVO} from "../../../../../core/common/code.vo";
import {PageParams, PageUtils} from "../../../../../common/utils/page.utils";
import M_ProHelpListRow from "./M_ProHelpListRow";
import {useNavigate, useSearchParams} from "react-router-dom";
import {BoardVO} from "../../../../../core/board/board.vo";
import ElandUtils from "../../../../../common/utils/eland.utils";
import Swiper from "../../../../../assets/js/swiper.min";
import M_TypeFilter from "./M_TypeFilter";
import M_BottomButton from "../../../common/M_BottomButton";
import axiosCaller from "../../../../../common/utils/axios.caller";
import APIs from "../../../../../common/apis/APIs";
import M_InfinityScroll from "../../../common/M_InfinityScroll";
import {HWMap} from "../../../../../core/common/common.vo";
import WebUtils from "../../../../../common/utils/web.utils";
import M_SearchInput from "../../../common/M_SearchInput";
import useHeader from "../../../../../common/hook/useHeader";
import {HeaderBtn} from "../../../../../core/common/header";
import useFooter from "../../../../../common/hook/useFooter";


interface ProHelpListFilterParam {
    jobCode: string,
    cateCode: string
}

const M_ProHelpList = () => {
    useHeader({buttons: [HeaderBtn.Back], title: "도와줘요 게시판", type: 'content'})
    useFooter({isHidden:true})
    const navigation = useNavigate()
    const [query, setQuery] = useSearchParams();
    const [isFilterShow, setIsFilterShow] = useState<boolean>(false)

    const [bestBoardList, setBestBoardList] = useState<BoardVO[]>([])
    const [boardList, setBoardList] = useState<BoardVO[]>([])

    const [menuList, setMenuList] = useState<CodeVO[]>([])
    const [pageParams, setPageParams] = useState<PageParams>(
        new PageUtils(
            parseInt(query.get('page') ?? "0"),
            parseInt(query.get('size') ?? "10"),
            query.get('sort') ?? "",
            parseInt(query.get('totalCount') ?? "0"),
            query.get("searchText") ?? "",
        )
    );
    const [filterParams, setFilterParams] = useState<ProHelpListFilterParam>(
        {jobCode: query.get("jobCode") ?? "", cateCode: query.get("cateCode") ?? ""}
    );
    const [totalCount, setTotalCount] = useState<number>(0)
    const scrollRef = useRef<{ reload: () => void, saveScroll:(index:number|null)=>void }>(null);


    useEffect(() => {
        getMenuList()
        setTimeout(initSwiper, 200)

    }, []);

    useEffect(() => {
        updateParams()

    }, [pageParams, filterParams]);

    useEffect(()=>{
        setTimeout(()=>{
            WebUtils.findCenter(true, filterParams.jobCode)
        }, 300)

    },[filterParams.jobCode])

    useEffect(()=>{
        setTimeout(()=>{
            WebUtils.findCenter(false, filterParams.cateCode)
        }, 300)


    },[filterParams.cateCode])

    const initSwiper = () => {
        new Swiper(`.tab_swiper`, {
            slidesPerView: "auto",
            preventClicks: true,
            preventClicksPropagation: false,
            observer: true,
            observeParents: true
        });

        new Swiper(`.filter_swiper`, {
            slidesPerView: "auto",
            preventClicks: true,
            preventClicksPropagation: false,
            observer: true,
            observeParents: true
        });
    }

    const getMenuList = async () => {
        let jobcodeList = await ElandUtils.getJobSkillCodeList(false);
        setMenuList(jobcodeList)
    }

    const clickCategory = (e: React.MouseEvent<HTMLAnchorElement>, cate: string) => {
        e.preventDefault()
        updateCategory(cate)
    }

    const clickMenu = (e: React.MouseEvent<HTMLAnchorElement>, code: string) => {
        e.preventDefault()
        updateMenu(code)
    }

    const updateParams = () => {
        query.set("sort", pageParams.sort.toString())
        query.set("searchText", pageParams.searchText)
        query.set("jobCode", filterParams.jobCode)
        query.set("cateCode", filterParams.cateCode)

        setQuery(query, {replace:true})
    }


    const updateSearchText = (searchText: string) => {
        scrollRef.current && scrollRef.current.reload()
        setPageParams((preParams) => ({
            ...preParams,
            searchText: searchText
        }));
    }

    const updateMenu = (newJobCode: string) => {
        let searchText = pageParams.searchText
        scrollRef.current && scrollRef.current.reload()
        setFilterParams((preParams) => ({
            ...preParams,
            jobCode: newJobCode
        }));

        setPageParams(prev => ({
            ...prev,
            page: 0,
            searchText: searchText
        }))
    }

    const updateCategory = (newCate: string) => {
        scrollRef.current && scrollRef.current.reload()
        setFilterParams((preParams) => ({
            ...preParams,
            cateCode: newCate
        }));
    }

    const goForm = () => {
        scrollRef.current && scrollRef.current.saveScroll(null)
        navigation('/pro/help/form')
    }

    const goDetail = (idx: number, index:number|null) => {
        scrollRef.current && scrollRef.current.saveScroll(index)
        navigation('/pro/help/' + idx)
    }

    const isBoardListEmpty = () => {
        return bestBoardList.length <= 0 && boardList.length <= 0
    }

    const filterUpdate = (jobCode:string, isCancel:boolean) => {
        if (!isCancel){
            updateMenu(jobCode)
        }
        setIsFilterShow(false)
    }

    const fetchList = async (makeParamFunc:(params:HWMap, size:number) => HWMap) => {
        let params = makeParamFunc({...pageParams, ...filterParams}, pageParams.size)
        let result = await axiosCaller.get(APIs.PRO_HELP_SEARCH, params)
        if (result.status === "SUCCESS"){
            setTotalCount(result.data.board.count)
            setBestBoardList(result.data.best.list)
            return result.data.board.list
        }

        return []
    }

    const reset = () => {
        setBoardList([])
        setTotalCount(0)
        setFilterParams((preParams) => ({
            ...preParams,
            jobCode: ''
        }));

        setPageParams(prev => ({
            ...prev,
            page: 0,
            searchText: ''
        }))
        scrollRef.current && scrollRef.current.reload()
    }


    // TODO: 각 탭 메뉴 눌렀을 때, 검색창 사라지는 부분 방지
    return (
        <main className="content help">
            <div className="search-area">
                <div className="inner">
                    <M_SearchInput updateSearchText={updateSearchText}
                                   placeholderText={'제목 또는 작성자를 입력하세요'}
                                   initText={pageParams.searchText}
                                   cancelHandler={reset}
                    ></M_SearchInput>
                </div>
            </div>
            <div className="tab-area">
                <div className="inner">
                    <div className="tab-slide tab_swiper">
                        <ul className="tab-list swiper-wrapper">
                            <li id={"tab-"}
                                className={filterParams.cateCode === '' ? 'tab-item swiper-slide active' : 'tab-item swiper-slide'}>
                                <a href="#"
                                   onClick={(e) => clickCategory(e, '')}>
                                    전체
                                </a>
                            </li>
                            <li id={"tab-040401"}
                                className={filterParams.cateCode === '040401' ? 'tab-item swiper-slide active' : 'tab-item swiper-slide'}>
                                <a href="#"
                                   onClick={(e) => clickCategory(e, '040401')}>
                                    도움요청
                                </a>
                            </li>
                            <li id={"tab-040402"}
                                className={filterParams.cateCode === '040402' ? 'tab-item swiper-slide active' : 'tab-item swiper-slide'}>
                                <a href="#"
                                   onClick={(e) => clickCategory(e, '040402')}>
                                    지식나눔
                                </a>
                            </li>
                            <li id={"tab-040403"}
                                className={filterParams.cateCode === '040403' ? 'tab-item swiper-slide active' : 'tab-item swiper-slide'}>
                                <a href="#"
                                   onClick={(e) => clickCategory(e, '040403')}>
                                    자유주제
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="filter-area border-10">
                <div className="inner">
                    <div className="filter-slide filter_swiper">
                        <ul className="filter-list swiper-wrapper">
                            <li className={isFilterShow ? "filter-item swiper-slide active" : "filter-item swiper-slide"}>
                                <a href="#"
                                   onClick={(e) => {
                                       e.preventDefault()
                                       setIsFilterShow(prev=>!prev)
                                   }}>
                                    필터
                                    <i className="ico-all"></i>
                                </a>
                            </li>
                            <li className={filterParams.jobCode === '' ? "filter-item swiper-slide active" : "filter-item swiper-slide"}
                                id={"filter-"}>
                                <a href="#"
                                   onClick={(e) => clickMenu(e, '')}>
                                    전체
                                </a>
                            </li>
                            {
                                menuList.map((value, index) => {
                                    return (
                                        <li key={index}
                                            id={"filter-" + value.code}
                                            className={filterParams.jobCode === value.code ? 'filter-item swiper-slide active' : 'filter-item swiper-slide'}>
                                            <a href="#"
                                               onClick={(e) => clickMenu(e, value.code)}>{value.codeName}</a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <section className="list-section">
                <div className="inner">
                    <div className="board-list">
                        {
                            !isBoardListEmpty() ? (
                                <>
                                    <ul className="webzine-list type2">
                                        {
                                            bestBoardList.map((value, index) => {
                                                return (
                                                    <M_ProHelpListRow key={value.idx + " " + index}
                                                                      boardVO={value}
                                                                      clickEvent={e=>{
                                                                          goDetail(e, null)
                                                                      }}
                                                                      isBest={true}/>
                                                )
                                            })
                                        }
                                        {
                                            boardList.map((value, index)=>{
                                                return (
                                                    <M_ProHelpListRow key={value.idx + " " + index}
                                                                      boardVO={value}
                                                                      clickEvent={e=>{
                                                                          goDetail(e, index)
                                                                      }}
                                                                      isBest={false}/>
                                                )
                                            })
                                        }
                                    </ul>
                                </>
                            ) : (
                                <div className="content-empty">
                                    <i className="ico-content"></i>
                                    <p className="txt">현재는 게시글이 없습니다.</p>
                                </div>
                            )
                        }
                    </div>
                </div>
                <M_InfinityScroll<BoardVO>
                    queryKey={"helpBoadList"}
                    update={fetchList}
                    defaultSize={pageParams.size}
                    totalCount={totalCount}
                    getList={val=>{setBoardList(val)}}
                    scrollRef={scrollRef} />
            </section>
            <M_BottomButton name={"작성하기"} onClick={e => {
                e.preventDefault()
                goForm()
            }} />

            {
                isFilterShow && <M_TypeFilter menuList={menuList}
                                              updateEvent={filterUpdate}
                                              currentCode={filterParams.jobCode}/>
            }
        </main>
    )
}

export default M_ProHelpList
import React, {FunctionComponent, useEffect, useState} from "react";
import useHeader from "../../../common/hook/useHeader";
import {HeaderBtn} from "../../../core/common/header";
import {Link} from "react-router-dom";
import useFooter from "../../../common/hook/useFooter";
import SwalUtils from "../../../common/utils/swal.utils";
import ElandUtils from "../../../common/utils/eland.utils";

const M_Menu: React.FC = () => {
    useHeader({
        type: 'content',
        title: '메뉴',
        buttons: [HeaderBtn.Back],
    });
    useFooter({
        isHidden:true
    })

    const [boardNameList, setBoardNameList] = useState<{boardKey:string, boardName:string}[]>([])

    useEffect(() => {
        getBoardNameList()
    }, []);

    const getBoardNameList = async () => {
        let result = await ElandUtils.getBoardNameList();
        setBoardNameList(result)
    }

    const getBoardName = (boardKey:string)=>{
        let find = boardNameList.find(board=> board.boardKey === boardKey);
        return find ? find.boardName : boardKey
    }

    const proRequestInfo = () => {
        SwalUtils.text({
            title : `사내고수 신청`,
            message : `사내고수 신청은 웹사이트에서만 가능합니다.<br/> 웹사이트를 통해 사내고수에 도전해주세요`,
            isCancel : false,
            confirmOptions : {
                text : "확인",
                eventHandler : () => {}
            }
        })
    }

    const links = [
        {middle: '/board/list/', key: 'ebg_story', label: 'EBG 성공스토리' },
        {middle: '/board/list/', key: 'ebg_common', label: 'EBG 지식콘텐츠' },
        {middle: '/board/list/', key: 'pro_knowledge', label: '사내고수의 지식' },
        {middle: '/board/list/', key: 'job_data', label: '사내고수의 자료실' },
        // { key: 'brand_story', label: '브랜드스토리' },
        {middle: '/board/list/', key: 'leadership', label: '리더십' },
        {middle: '/board/list/', key: 'es', label: 'ES' },
        {middle: '/board/story/', key: 'use_story', label: '적용스토리' },
    ];

    const mypageBoardLinks = [
        {middle: '/mypage/board/', key: 'company'},
        {middle: '/mypage/board/', key: 'organization'},
        {middle: '/mypage/board/', key: 'company_announce'},
    ]

    return (
        <main className="content sitemap">
            <section className="sitemap-section">
                <div className="inner">
                    <ul className="depth1-list">
                        <li>
                            <strong>지식콘텐츠</strong>
                            <ul className="depth2-list">
                                {
                                    links.map((link, index) => (
                                            <li key={index}>
                                                <Link to={link.middle + link.key}>{getBoardName(link.key)}</Link>
                                            </li>
                                        ))
                                }
                            </ul>
                        </li>
                        <li>
                            <strong>사내고수</strong>
                            <ul className="depth2-list">
                                <li>
                                    <Link to="/pro">사내고수찾기</Link>
                                </li>
                                <li>
                                    <a href="/pro/help">도와줘요 게시판</a>
                                </li>
                                <li>
                                    <a href="#" onClick={e=>{
                                        e.preventDefault()
                                        proRequestInfo()
                                    }}>사내고수 신청</a>
                                </li>
                                <li>
                                    <a href="/pro/talk">나의 에듀톡</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <strong>게시판</strong>
                            <ul className="depth2-list">
                                {
                                    mypageBoardLinks.map((link, index) => (
                                        <li key={index}>
                                            <Link to={link.middle + link.key}>{getBoardName(link.key)}</Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        </li>
                    </ul>
                </div>
            </section>
        </main>
    )
}

export default M_Menu;
import React, {useEffect, useState} from "react";
import CodeUtils, {CoCode} from "../../../common/utils/code.utils";
import {CodeCountDto} from "../../../core/board/board.vo";

interface CoSelectButtonProps {
    updateCoCode:(newCoCode: string) => void;
    initCode?:string
    countList?: CodeCountDto[]
}


const CoSelectButton = (props:CoSelectButtonProps) => {

    const {updateCoCode, initCode} = props;
    const [coCodeList, setCoCodeList] = useState<CoCode[]>([])
    const [countList, setCountList] = useState<CodeCountDto[]>([])

    useEffect(() => {
        const getCodeList = async () => {
            let coCodeList = await CodeUtils.getCoCodeList()
            setCoCodeList(coCodeList)
        }
        getCodeList()

    }, []);

    useEffect(() => {

    }, [initCode]);

    useEffect(() => {
        if (props.countList && props.countList.length > 0){
            setCountList(props.countList)

        }

    }, [props.countList])

    const buttonClick = (e:any, val:string)=>{
        e.preventDefault()
        updateCoCode(val)
    }

    const getCodeCount = (code: string) => {
        if (countList.length <= 0){
            return ''
        }

        let codeVO = countList.find((vo)=>vo.code === code)
        return codeVO ? `(${codeVO.count})` : '(0)'
    }

    const getTotalCount = () => {
        return countList.find(vo=> vo.code === 'totalCount')?.count
    }


    return(
        <div className="select">
            <button type="button" className="btn-select">
                {
                    !initCode ?
                        <span>법인 전체</span> :
                        <span>
                            {
                                (coCodeList.find((codeVO) => codeVO.coCode === initCode))?.coName
                            }
                        </span>
                }

            </button>
            <ul className="select-option" style={{zIndex:20}}>
                <li className={!initCode ? "selected" : ""}>
                    <a onClick={(e)=>buttonClick(e,"")}>
                        <span>법인 전체</span>
                        <span>{countList && countList.length > 0 ? `(${getTotalCount()})` : ''}</span>
                    </a>
                </li>

                {
                    coCodeList.map((value,index)=>{
                        return(
                            <li key={index} className={initCode===value.coCode ? "selected" : ""}>
                                <a onClick={(e)=>buttonClick(e,value.coCode)}>
                                    <span>{value.coName}</span>
                                    <span>{getCodeCount(value.coCode)}</span>
                                </a>
                            </li>
                        )
                    })
                }

            </ul>
        </div>
        );
}

export default CoSelectButton
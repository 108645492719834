import React, {useEffect, useRef, useState} from "react";
import {ProReqVO} from "../../../core/pro/pro.vo";
import {BoardVO} from "../../../core/board/board.vo";
import ProService from "../../../core/pro/pro.service";
import M_MainProNewContentsItem from "./M_MainProNewContentsItem";
import type { Swiper as SwiperType } from 'swiper';
import SwiperInit from 'swiper';

const M_MainProNewContents = () => {
    const [list, setList] = useState<{pro:ProReqVO, board:BoardVO}[]>([])
    const boardSwiperRef = useRef<SwiperType | null>(null);

    useEffect(() => {
        const successHandler = (result:any) => {
            const arr:{pro:ProReqVO, board:BoardVO}[] = result.data
            setList(arr)
        }

        ProService.newProContentGet(successHandler);

    }, []);

    useEffect(() => {
        boardSwiperRef.current?.destroy(true, true)

        boardSwiperRef.current = new SwiperInit("#main-pro-new-content .board-slide", {
            slidesPerView: "auto",
            spaceBetween: 24,
        })

    }, [list]);


    return (
        <section id="main-pro-new-content" className="list-section cont-name">
            <div className="inner">
                <div className="section-title">
                    <h3 className="tit">사내고수 업로드 신규 콘텐츠</h3>
                </div>
                <div className="board-list board-slide">
                    <ul className="card-list type2 swiper-wrapper">
                        {
                            list.map(val=>{
                                return <M_MainProNewContentsItem key={val.board.idx} pro={val.pro} board={val.board}/>
                            })
                        }
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default M_MainProNewContents;
import React, {useEffect, useState} from "react";
import {DefaultProReqVO, ProReqVO} from "../../../../../core/pro/pro.vo";
import ProService from "../../../../../core/pro/pro.service";
import CodeUtils from "../../../../../common/utils/code.utils";
import {CodeVO} from "../../../../../core/common/code.vo";
import {Link} from "react-router-dom";
import FileUtils from "../../../../../common/utils/file.utils";
import ElandUtils from "../../../../../common/utils/eland.utils";
import StringUtils from "../../../../../common/utils/string.utils";

interface JobSkillResultProRecommendProps {
    weakList: string;
}

const JobSkillResultProRecommend = (props:JobSkillResultProRecommendProps) => {
    const [proReq, setProReq] = useState<ProReqVO>(DefaultProReqVO)
    const [reviewCodeList, setReviewCodeList] = useState<CodeVO[]>([])

    useEffect(() => {
        !StringUtils.isEmpty(props.weakList) && getRecommendPro()

    }, [props]);

    const getRecommendPro = () => {
        const successHandler = async (result: any) => {
            const newProReq = {...result.data}
            newProReq.jobCodeName = await ElandUtils.getJobSkillCodeName(newProReq.jobCode)
            newProReq.reviewCode && getCodeListFromCodeStr(newProReq.reviewCode)

            setProReq(newProReq)
        }
        let params = { weakList: props.weakList }
        ProService.proRecommendOneGet(params, successHandler, ()=>{})
    }

    const getCodeListFromCodeStr = async (codes: string) => {
        if (codes === '') {
            return
        }
        const reviewCodeList = await CodeUtils.getReviewCodeList();
        const codeVOList:CodeVO[] = codes.split(',')
        .map(codeStr => {
            return reviewCodeList.find(codeVO => codeVO.code === codeStr);
        })
        .filter((codeVO): codeVO is CodeVO => codeVO !== undefined)

        setReviewCodeList(codeVOList)
    }

    // 키워드 가져오기
    function getBadgeTag(codeVO:CodeVO, index:number) {
        // 뒤에서문자 추출
        const secondToLastChar = codeVO.code.charAt(5);

        // 숫자로 변환
        const number = parseInt(secondToLastChar, 10);
        let className = "badge type1 "

        if (number % 10 === 0 || number % 9 === 0) {
            className += "red"
        } else if (number % 8 === 0 || number % 7 === 0) {
            className += "blue"
        } else if (number % 6 === 0 || number % 5 === 0) {
            className += "orange"
        } else if (number % 4 === 0 || number % 3 === 0) {
            className += "green"
        } else {
            className += "purple"
        }

        return <span key={index} className={className}>{codeVO.codeName}</span>;
    }



    return proReq.proidx !== 0 ?
        (
        <section className="recommend-section">
            <div className="inner">
                <Link to={"/pro/" + proReq.proidx}>
                <div className="section-title">
                    <h3 className="tit">추천 고수를 만나보세요!</h3>
                </div>
                <div className="section-content">
                    <div className="master-info">
                        <div>
                            <div className="profile-wrap">
                                <div className="img-wrap">
                                    <img src={FileUtils.getFileUrl(proReq.profileImgSrc)}
                                         onError={FileUtils.onErrorImg}
                                         alt="" className="img-profile"/>
                                </div>
                                <div className="text-wrap">
                                    <strong className="name">{proReq.companyAccountVO?.userName}</strong>
                                    <div className="job">
                                        <span>{proReq.companyAccountVO?.coInfoVO?.coName} </span>
                                        <span>{proReq.jobCodeName}</span>
                                    </div>
                                    <div className="keyword-list">
                                        {
                                            reviewCodeList.map((value,index) => {
                                                return getBadgeTag(value, index)
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="info-wrap">
                                <ul className="info-list">
                                    <li>
                                        <span className="txt">팔로워 수</span>
                                        <span className="num">{proReq.followerCount}</span>
                                    </li>
                                    <li>
                                        <span className="txt">상담지수</span>
                                        <span className="num">{proReq.chatCount}</span>
                                    </li>
                                    <li>
                                        <span className="txt">지식업로드</span>
                                        <span className="num">{proReq.uploadCount}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div style={{ width: "340px" }}>
                            <div className="keyword-wrap">
                                <strong>고수 키워드</strong>
                                <div className="keyword-list">
                                    <span style={{ width : "100%" }} className={"keyword-span pro-recommend"}>
                                        {
                                            proReq.strengthKeyword.split(',').map((value, index) => {
                                                if (value) {
                                                    return ('#' + value)
                                                }
                                            }).join(', ')
                                        }
                                    </span>
                                </div>
                                <div className="keyword-list">
                                    <span style={{ width : "100%" }} className={"keyword-span pro-recommend"}>
                                        {
                                            proReq.officialKeyword.split(',').map((value, index) => {
                                                if (value) {
                                                    return (
                                                        <span key={index}>#{value}</span>
                                                    )
                                                }
                                            })
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="edutalk-wrap">
                                <strong>에듀톡 주제</strong>
                                <div className="badge-list">
                                    {
                                        proReq.chatTopic.split(',').map((value, index) => {
                                            return (<span key={index}>{value}</span>)
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </Link>
            </div>
        </section>
    ) : (
        <></>
    )
}

export default JobSkillResultProRecommend
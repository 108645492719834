import React from "react";
import M_ProEduTalkCommentInput from "./M_ProEduTalkCommentInput";


const M_ProEduTalkNone = () => {

    return (
        <section className="reply-section empty">
            <div className="empty-content">
                <div className="inner">
                    <h3 className="tit-accordion">답변</h3>
                    <div className="accordion-content">
                        <div className="empty-text" style={{padding: "52px 0"}}>
                            고수의 답변을 기다리고 있습니다
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )


}

export default M_ProEduTalkNone
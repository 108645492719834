import React, {useEffect, useState} from "react";
import {BoardVO} from "../../../../core/board/board.vo";
import BoardService from "../../../../core/board/board.service";
import APIs from "../../../../common/apis/APIs";
import {Link, useNavigate} from "react-router-dom";
import FileUtils from "../../../../common/utils/file.utils";
import M_Badge from "../../board/item/M_Badge";
import StringUtils from "../../../../common/utils/string.utils";
import ToastUtils from "../../../../common/utils/toast.utils";
import ElandUtils from "../../../../common/utils/eland.utils";

const M_RecentBoard: React.FC = () => {

    const boardService = new BoardService();
    const [board, setBoard] = useState<BoardVO>();
    const [isWish, setIsWish] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const result = await boardService.getRecentViewList({page: 0, size: 1, sort: "createDate,DESC", boardKeys: APIs.KNOWLEDGE_BOARDKEY});
            if(result.status == "SUCCESS" && result.data && result.data.content) {
                const updatedBoardList = await ElandUtils.convertJobSkillNames4BoardList(result.data.content);
                if ( updatedBoardList && updatedBoardList.length >= 1 ) {
                    setBoard(updatedBoardList[0])
                    setIsWish(updatedBoardList[0].isWish)
                }
            }

        })()
    }, []);

    const setBookMark = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

        event.stopPropagation(); // 이벤트 전파 방지
        event.preventDefault(); // 기본 동작 방지

        let result;

        if (!board){
            return
        }

        if (isWish) {
            result = await boardService.deleteWishBoard({ bidx: board.idx });
        } else {
            result = await boardService.putWishBoard({ bidx: board.idx });
        }

        if (result.status === "SUCCESS" && result.data) {
            setIsWish(result.data.isWished);

            if (result.data.isWished) {
                ToastUtils.show("관심 지식에 저장되었습니다.", 2);
            } else {
                ToastUtils.show("관심 지식에서 해제되었습니다.", 2);
            }

        }
    };

    return (
        <div className="box-item box3">
            <div className="box-title">
                <h3 className="tit">최근 본 지식</h3>
                <a href="#" className="link-more" onClick={()=>navigate('/education/storagebox')}>전체보기</a>
            </div>

            {
                board
                    ?
                    <div className="box-content">
                        <div className="board-list">
                            <ul className="webzine-list type1">
                                <li>
                                    <Link to={`/board/${board.boardKey + '/' + board.idx + '/'}` + StringUtils.titleUrlFilter(board.title)}>
                                        <div className="image-wrap" style={{height:"120px"}}>
                                            {
                                                board.boardKey == 'job_data'
                                                    ? <img src={FileUtils.getRandomJobDataImg(board) || APIs.BOARD_ERROR_IMG} alt="" className="img-thumb" />
                                                    : <img
                                                        src={ FileUtils.getImageSrc(board.thumbFile) }
                                                        onError={ FileUtils.onErrorBoardImg }
                                                        alt="" className="img-thumb"/>
                                            }
                                            {
                                                board.boardKey != 'job_data' &&
                                                <button type="button"
                                                        onClick={setBookMark}
                                                        className={`btn-bookmark ${isWish ? 'active' : ''}`}>
                                                    <span className="blind">북마크</span>
                                                </button>
                                            }
                                            <span className="data">
                                                {
                                                    board.boardType === 'mov' ? StringUtils.formatTime(board.duration) : "카드뉴스"
                                                }
                                            </span>
                                        </div>
                                        <div className="text-wrap">
                                            <div className="cate-wrap">
                                                {/* 추천 / 신규 / 인기 뱃지 */}
                                                <M_Badge type={board.opt1 ? board.opt1 : ''} />
                                                <span className="cate">{ board.jobName }</span>
                                            </div>
                                            <strong className="tit">{ board.title }</strong>
                                            {
                                                board.writerInfo &&
                                                <span className="writer">
                                                    {
                                                        board.writerInfo.isAdmin
                                                            ? board.writer
                                                            : `${board.writerInfo.coInfoVO?.coName} ${board.writerInfo.userName} 고수`
                                                    }
                                                </span>
                                            }
                                            <div className="rating-wrap">
                                                <span className="star">{board.rateAvg.toFixed(1)} ({board.reviewCount})</span>
                                                <span className="hits">{ board.views }</span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    :
                    <div className="box-content">
                        <div className="content-empty">
                            <p>최근 본 지식이 없습니다</p>
                        </div>
                    </div>
            }

        </div>
    )
}

export default M_RecentBoard;
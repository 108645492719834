import React, {useEffect, useState} from "react";
import {CodeVO, DefaultCodeVO} from "../../../core/common/code.vo";
import {JobSkillReport} from "../../../core/jobskill/jobskill.vo";
import JobSkillService from "../../../core/jobskill/jobskill.service";
import {Link} from "react-router-dom";
import {getModifierAndSmallTalk} from "../../../core/jobskill/jobskill.text";
import ArrayUtils from "../../../common/utils/array.utils";
import ElandUtils from "../../../common/utils/eland.utils";
import JobSkillResultRadarChart from "../mypage/jobskill/result/JobSkillResultRadarChart";
import JobSkillResultRankItem from "../mypage/jobskill/result/JobSkillResultRankItem";


const MainJobSkillResult = () => {
    const [skillCodeList, setSkillCodeList] = useState<CodeVO[]>([])
    const [resultReport, setResultReport] = useState<JobSkillReport>({
        currentAvg: 0,
        jobCode: DefaultCodeVO(),
        parentCode: DefaultCodeVO(),
        lastAvg: 0,
        list: [],
        totalCount: 0,
    })

    useEffect(() => {
        const getSkillCodeList = async () => {
            setSkillCodeList(await ElandUtils.getJobSkillCodeList(true));
        }

        const successHandler = (result: any) => {
            setResultReport(result.data)
        }

        getSkillCodeList()
        JobSkillService.jobSkillResultGet(successHandler)
    }, []);

    return (
        <div className="results-section">
            <div className="inner">
                <div className="section-title">
                    <h2 className="tit">직무역량진단 결과</h2>
                    <Link to={"/mypage/jobskill"} className="link-more">더보기</Link>
                </div>
                <p className="results-text">당신은 <em>{getModifierAndSmallTalk(resultReport.currentAvg).modifier}{resultReport.jobCode.codeName}</em></p>
                <div className="results-wrap">
                    <ul className="rank-list">
                        {
                            resultReport.list
                            .filter((value, index) => index < 4)
                            .map(val => {
                                const codeName = skillCodeList.find(code=>code.code === val.skillCode)?.codeName ?? "";
                                return <JobSkillResultRankItem key={val.skillCode}
                                                               rank={val.rank}
                                                               totalCount={val.totalCount}
                                                               skillName={codeName}
                                                               linkValid={true}
                                                               score={val.score}/>
                            })
                        }
                    </ul>
                    <JobSkillResultRadarChart resultReport={resultReport.list} skillCodeList={skillCodeList}/>
                </div>
            </div>
        </div>
    )
}

export default MainJobSkillResult
import React, {useEffect, useState} from "react";
import M_MypageNav from "../../common/M_MypageNav";
import {useLocation, useNavigate} from "react-router-dom";
import M_JobSkillInputAsk from "./M_JobSkillInputAsk";
import jobskillService from "../../../../../core/jobskill/jobskill.service";
import JobSkillService from "../../../../../core/jobskill/jobskill.service";
import {PollAskVO} from "../../../../../core/jobskill/jobskill.vo";
import MemberUtils from "../../../../../common/utils/member.utils";
import ToastUtils from "../../../../../common/utils/toast.utils";
import PointService from "../../../../../core/point/point.service";
import useFooter from "../../../../../common/hook/useFooter";
import useHeader from "../../../../../common/hook/useHeader";
import {HeaderBtn} from "../../../../../core/common/header";

const M_JobSkillInput = () => {
    useHeader({
        buttons: [HeaderBtn.Menu],
        title: "직무역량진단",
        type: 'sub'})
    useFooter({isHidden:true})

    const NumberPerPage = 5
    const navigate = useNavigate();
    const location = useLocation()

    const [pidx, setPidx] = useState<number>(0)
    const [selectJobCode, setSelectJobCode] = useState<string>(location.state?.jobCode ?? "")

    const [page, setPage] = useState<number>(0)
    const [askList, setAskList] = useState<PollAskVO[]>([])


    useEffect(() => {
        if (!selectJobCode || selectJobCode === "") {
            ToastUtils.show("잘못된 접근입니다.")
            navigate(-1);

        } else {
            getPoll(selectJobCode)

        }

    }, []);

    const getPoll = (jobCode: string) => {
        const successHandler = (result: any) => {
            setPidx(result.data.pidx)
            setAskList(result.data.askList)
        }

        const failHandler = (result: any) => {
            ToastUtils.show(result.msg)
            navigate(-1)
        }

        jobskillService.jobSkillPollGet(jobCode, successHandler, failHandler)
    }

    const paginationAskList = (page: number, index: number) => {
        let n = NumberPerPage
        const totalCount = askList.length

        let startIndex = totalCount < page * n ? totalCount : page * n
        let endIndex = totalCount < startIndex + n ? totalCount : startIndex + n
        return startIndex <= index && index < endIndex

    }

    const movePage = (isNext: boolean) => {
        if (isPossiblePageMove(isNext)) {
            setPage(prev => isNext ? prev + 1 : prev - 1)
            setTimeout(()=>{window.scrollTo(0, 0)})
        }
    }

    const isPossiblePageMove = (isNext: boolean) => {
        const totalCount = askList.length;
        const maxPage = Math.ceil(totalCount / 5) - 1; // 올림을 사용하여 최대 페이지 계산
        const minPage = 0;

        return isNext ? page < maxPage : page > minPage;

    }

    const calProgressPercent = () => {
        const total = askList.length > 0 ? askList.length : 1
        const current = askList.filter(ask => ask.selectAnswer).length

        const progress = (current / total) * 100

        if (progress < 10) {
            return 10
        }

        if (progress > 100) {
            return 100
        }

        return progress
    }

    const isCompleteCurrentPage = () => {
        let currentPageAnswerNum = askList.filter((value, index) => paginationAskList(page, index))
        .filter((value, index) => value.selectAnswer)
        .length

        return currentPageAnswerNum == NumberPerPage
    }

    const selectAnswerEvent = (ask: PollAskVO) => {
        setAskList(prevAskList => {
                return prevAskList.map(prevAsk =>
                    prevAsk.paidx === ask.paidx ? ask : prevAsk
                )
            }
        );
    }

    const changeJobCode = (code: string) => {
        let sessionVO = MemberUtils.getSessionVO();
        if (sessionVO){
            sessionVO.jobCode = code
            MemberUtils.setSessionVO(sessionVO)
        }
    }

    const doSubmit = () => {
        if (calProgressPercent() == 100){
            const selectAnswerList = askList.filter(ask => ask.selectAnswer)
            .map(ask => ask.selectAnswer);

            if (selectAnswerList.length === askList.length) {
                const successHandler = (result: any) => {
                    changeJobCode(result.data.jobCode)
                    savePoint()
                    MemberUtils.setIsCompleteJobSkill(true)
                    navigate("/mypage/jobskill")
                }

                // 전송
                let params = {
                    selectAnsList: selectAnswerList,
                    pidx: pidx,
                    jobCode: selectJobCode,
                }

                JobSkillService.jobSkillSubmit(params, successHandler)

            } else {
                ToastUtils.show("선택하지 않은 설문이 존재합니다.")

            }
        }
    }

    const savePoint = async () => {
        const response = await PointService.savePoint("JOB_TEST_INIT", null);
        if (response && response.status === "SUCCESS"){
            ToastUtils.show(`진단을 완료해서 ${response.data.point}P 가 지급되었어요 (기간중 1회만 적립)`)
        }
    }


    return (
        <div className="content job-competency">
            <section className="test-section">
                <div className="inner">
                    <div className="progress-bar">
                        <span style={{width: calProgressPercent() + "%"}}></span>
                    </div>
                    <div className="form-list">
                        {
                            askList.filter((value, index) => paginationAskList(page, index)).map(ask => {

                                return (
                                    <M_JobSkillInputAsk key={ask.paidx} pollAsk={ask}
                                                        selectAnswerEvent={selectAnswerEvent}/>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            <div className="floating-area">
                <div className="floating-button">
                    <div className="inner">
                        <div className="button-area">
                            <>
                                <button type="button"
                                        className={isPossiblePageMove(false) ? "btn gray lg" : "btn gray lg disabled"}
                                        onClick={e => movePage(false)}>이전
                                </button>
                                {
                                    isPossiblePageMove(true) ? (
                                        <button type="button"
                                                className={isPossiblePageMove(true) && isCompleteCurrentPage() ? "btn red lg" : "btn red lg disabled"}
                                                onClick={e => movePage(true)}>다음
                                        </button>
                                    ) : (
                                        <button type="button"
                                                className={calProgressPercent() == 100 ? "btn red lg" : "btn red lg disabled"}
                                                onClick={doSubmit}>제출하기
                                        </button>
                                    )
                                }
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default M_JobSkillInput
import React, {useEffect, useState} from "react";
import MemberUtils from "../../../../common/utils/member.utils";

interface AccountManagementInputProps {
    title: string;
    value: string;
    placeholder: string;
    maxLength: number;
    isValid: boolean|null;
    isPossibleEmpty: boolean;
    changeEvent: (value: string) => void;
    buttonClickEvent: (value: string) => void;
}

const AccountManagementInput = (props: AccountManagementInputProps) => {
    const [text, setText] = useState(props.value)
    const [isValid, setIsValid] = useState(props.isValid)

    useEffect(() => {
        setText(props.value)

    }, [props.value]);

    useEffect(() => {
        setIsValid(props.isValid)

    }, [props.isValid]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setText(value)
        props.changeEvent(value)
    };

    const handleButtonClick = () => {
        if (checkBtnVaild()){
            props.buttonClickEvent(text)
        }

    }

    const checkInputLength = (value: string) => {
        return value.length <= props.maxLength;
    };

    const checkInputSpace = (value: string) => {
        if(!props.isPossibleEmpty){
            return value.search(/\s/) == -1
        }

        return true
    }

    const checkInputIsEmpty = () => {
        return text === '';
    };

    const checkIsError = (value:string) => {
        return !checkInputLength(value) || (!(isValid === null) && !isValid) || !checkInputSpace(value)

    }

    const checkBtnVaild = () => {
        return checkInputLength(text)
            && !checkInputIsEmpty()
            && MemberUtils.getSessionVO()?.nickName !== text
            && isValid === null
            && checkInputSpace(text)
    };

    const btnStyle = () => {
        return checkBtnVaild() ? { color: "#1847ed" } : {};
    };

    const getErrorMessage = () => {
        if (!checkInputSpace(text) || !checkInputLength(text)){
            return (
                <div className="error-text">
                    닉네임은 공백없이 8글자 이내로 입력해주세요
                </div>
            )
        }

        if (isValid === null){
            return ("")
        }

        if (isValid){
            return (
                <div className="valid-text">
                    사용가능한 닉네임입니다
                </div>
            )
        }


        return (
            <div className="error-text">
                이미 사용중인 닉네임입니다
            </div>
        )

    };

    return (
        <div className="form-item">
            <div className="form-title">
                <strong className="tit">{props.title}</strong>
            </div>
            <div className={checkIsError(text) ? "inp-btn-bind error" : "inp-btn-bind"}>
                <input type="text"
                       name=""
                       id=""
                       className="inp-text"
                       placeholder={props.placeholder}
                       value={text}
                       onChange={e=> {handleChange(e)}}/>
                <button type="button"
                        className="btn gray btn-upload"
                        style={btnStyle()}
                        onClick={handleButtonClick}>
                    중복 확인
                </button>
            </div>
            {
                getErrorMessage()
            }
        </div>
    );
};

export default AccountManagementInput;

import React, {useEffect, useRef} from "react";
import {ProReqVO} from "../../../../core/pro/pro.vo";
import M_ProRecommendCard from "./M_ProRecommendCard";
import type { Swiper as SwiperType } from 'swiper';
import SwiperInit from 'swiper';

interface ProRecommendSlideProps {
    recommendList: ProReqVO[]
}

const M_ProRecommendSlide = (props:ProRecommendSlideProps) => {
    const swiperRef = useRef<SwiperType | null>(null);
    const {recommendList} = props

    const installSwiper = () => {
        swiperRef.current?.destroy(true, true);

        if (recommendList.length > 0){
            swiperRef.current = new SwiperInit(".recommend-section .slide-wrap", {
                loop: true,
                slidesPerView: "auto",
                spaceBetween: 30,
                centeredSlides: true,
                navigation: {
                    nextEl: ".btn-next",
                    prevEl: ".btn-prev",
                },
            });
        }
    }

    useEffect(() => {
        installSwiper()

    }, [recommendList]);


    return (
        <section className="recommend-section">
            <div className="inner">
                <div className="section-title">
                    <h3 className="tit">추천 고수</h3>
                </div>
                <div className="board-list">
                    <div className="slide-wrap">
                        <ul className="slide-list swiper-wrapper card-list type3">
                            {
                                recommendList.map((value, index) => {
                                    return (
                                        <M_ProRecommendCard
                                            key={index} {...value}></M_ProRecommendCard>
                                    )
                                })
                            }

                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default M_ProRecommendSlide
import React from "react";

function M_Badge({type}: { type: string }) {
    switch (type) {
        case 'commend':
            return <span className="badge type1 sm green">추천</span>;
        case 'popular':
            return <span className="badge type1 sm red">인기</span>;
        case 'new':
            return <span className="badge type1 sm orange">신규</span>;
        default:
            return <></>;
    }
}

export default M_Badge
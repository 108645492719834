import React, {useEffect, useRef, useState} from "react";
import {HWMap} from "../../../../core/common/common.vo";
import {
	AnsMovVO,
	EduAnswerVO,
	EduContentVO,
	EduMainVO,
	QstMovOptionVO,
	QstMovVO, VideoVO
} from "../../../../core/education/education.vo";
import DateUtils from "../../../../common/utils/date.utils";
import WebUtils from "../../../../common/utils/web.utils";
import EducationService from "../../../../core/education/education.service";
import educationService from "../../../../core/education/education.service";
import StringUtils from "../../../../common/utils/string.utils";
import {EduContentIndex, EduTitleDom} from "../EduMainDetail";
import ReactPlayer from "react-player";
import {OnProgressProps} from "react-player/types/base";
import ToastUtils from "../../../../common/utils/toast.utils";
import PointService from "../../../../core/point/point.service";
import HwsPlayer from "../../common/HwsPlayer";
import {current} from "@reduxjs/toolkit";


const QstMov:React.FC<{paramMap:HWMap}> = ({paramMap}) => {

	const $hwsPlayer = useRef<any>(null);
	
	const [requestStep, setRequestStep] = useState()
	const [eduMainVO, setEduMainVO] = useState<EduMainVO|null>(null)
	const [contentList, setContentList] = useState<EduContentVO[]|null>(null)
	const [eduContentVO, setContentVO] = useState<EduContentVO|null>(null)
	const [qstMovVO, setQstMovVO] = useState<QstMovVO|null>(null)
	const [qstMovOptionVO, setQstMovOption] = useState<QstMovOptionVO|null>(null)
	const [videoList, setVideoList] = useState<VideoVO[]|null>(null)
	const [eduAnswerVO, setEduAnswerVO] = useState<EduAnswerVO|null>(null)
	
	const [created, setCreated] = useState<boolean>(false)
	const [mounted, setMounted] = useState<boolean>(false)

	// 탭 순서) 강의 설명 : 0, AI 요약 beta : 1, 스크립트 : 2
	const [currentTab, setCurrentTab] = useState(0);

	const [isLoading, setIsLoading] = useState(false);
	
	/**
	 * 받아온 데이터 정의
	 */
	useEffect(() => {
		WebUtils.log(paramMap)
		if (paramMap.eduMainVO) {
			setRequestStep(paramMap.requestStep)
			setEduMainVO(paramMap.eduMainVO)
			setContentList(paramMap.contentList)
			setContentVO(paramMap.eduContentVO)
			setQstMovVO(paramMap.eduContentVO.qstMovVO)
			setEduAnswerVO( paramMap.answerList[paramMap.requestStep] )
			
			setCreated(true)
		}
	}, [paramMap]);


	/**
	 * 이어보기 로직
	 */
	useEffect(() => {

		if ( created ) {
			
			/**
			 * 영상 옵션 정보 조회
			 */
			(async () => {
				if ( eduContentVO?.ecIdx ) {
					const optionData = await EducationService.getMovOption(eduContentVO.ecIdx)
					await setQstMovOption(optionData.qstMovOptionVO)
					await setVideoList(optionData.videoList)
				}
			})()
			
			setMounted(true)
		}

	}, [created, eduAnswerVO])
	
	
	
	
	
	/**
	 * 모든 데이터 준비
	 */
	useEffect(() => {
		
		if (mounted) {
			
			/**
			 * 영상준비까지 끝난 경우 이어보기 실행
			 */
			if ( eduAnswerVO?.ansMovVO && eduAnswerVO?.ansMovVO.currentTimes > 0 ) {
				const doContinuePlay = ( _seconds:number ) => {
					//$hwsPlayer.current?.seekTo(_seconds)
					if ( $hwsPlayer.current && ($hwsPlayer.current as any).getReady() ) {
						$hwsPlayer.current?.seekTo(_seconds)
					} else {
						setTimeout(() => doContinuePlay(_seconds), 500)
					}
				}
				doContinuePlay(eduAnswerVO?.ansMovVO.currentTimes)
			}
		}
		
	}, [mounted])
	
	

	/**
	 * 1초마다 실행. 현재 플레이 시간 저장
	 * 10초마다 현재시간 ansMov 에 저장
	 * @param _props
	 */
	const recordOldTimes = useRef<number>(0);
	const fnProgress = async (_playseconds:number) => {

		if ( eduAnswerVO && _playseconds - recordOldTimes.current >= 10 ) {
			recordOldTimes.current = _playseconds
			const ansMov:AnsMovVO = eduAnswerVO?.ansMovVO;
			ansMov.currentTimes = _playseconds
			EducationService.setAnsRecord(eduContentVO!.ecIdx, "PLAYING", "mov")
			EducationService.setAnsMov( ansMov )
		}
	}

	
	
	/**
	 * 앞으로 이동시 이동시간 저장
	 * 뒤로 이동시 isSkip 확인 후 허용 및 불가처리
	 * @param _seconds
	 */
	const fnSeek = async (_seconds:number) => {

		if ( eduAnswerVO?.ansMovVO ) {

			const ansMov:AnsMovVO = eduAnswerVO?.ansMovVO;

			/**
			 * 뒤로 이동시
			 * isSkip false 면 10초 이상 차이날 경우 롤백
			 */
			if ( ansMov.currentTimes < _seconds ) {
				
				/**
				 * 학습가능상태 확인
				 */
				if ( paramMap.isPossible ) {
					
					/**
					 * 건너뛰기 가능여부 확인
					 */
					if (!qstMovVO?.isSkip) {
						if (_seconds - ansMov.currentTimes  >= 10) {
							//console(ansMov.currentTimes + " >>>> " + _seconds);
							//console("뒤로 불가")
							await ToastUtils.show("뒤로 넘기기가 불가능한 영상입니다.")
							await $hwsPlayer.current?.seekTo(ansMov.currentTimes)
							return
						}
					}
				}

			}

			/**
			 * 앞으로 이동시 이동한 시간 저장
			 */
			if ( ansMov && ansMov.currentTimes >= _seconds ) {
				//console("앞으로")
				ansMov.currentTimes = _seconds
				EducationService.setAnsRecord(eduContentVO!.ecIdx, "PREV", "mov")
				EducationService.setAnsMov( ansMov )
			}

			//setCurrentTimes(_seconds)
			ansMov.currentTimes = _seconds
		}
	}


	const onEnded = async () => {
		if (isLoading) return
		setIsLoading(true)

		try {
			/**
			 * 학습가능상태 확인
			 */
			if ( paramMap.isPossible ) {

				const result = await educationService.setAnswerStatus({
					eaIdx : eduAnswerVO!.eaIdx,
					isResult : false,
					isComplete : true,
				})
				if ( result.status === `SUCCESS` ) {

					//alert("영상 재생이 끝났습니다. 컨텐츠 완료됩니다.")
					ToastUtils.show(`${eduContentVO?.qstMovVO.movTitle} 영상재생이 완료되었습니다.`)

					/**
					 * 재생위치 초기화
					 */
					if ( eduAnswerVO?.ansMovVO ) {
						const ansMov:AnsMovVO = eduAnswerVO.ansMovVO;
						ansMov.currentTimes = 0
						EducationService.setAnsRecord(eduContentVO!.ecIdx, "END", "mov")
						EducationService.setAnsMov( ansMov )
					}

					// 포인트적립 : 학습 컨텐츠 완료
					await PointService.savePoint("COURSE_VIEW", eduContentVO!.ecIdx)

					// 현재페이지 갱신만
					paramMap._retrievePage(result.allComplete)

					// 다음페이지로 이동
					//paramMap._goStep(requestStep! + 1)
				}
			}
		} catch (error) {
			WebUtils.log(error)
		} finally {
			setTimeout(() => {
				setIsLoading(false)
			}, 3000)
		}

	}


	return (
		<section className="detail-section">
			<div className="inner">
				<EduTitleDom paramMap={{
					mainTitle: eduMainVO?.eduTitle,
					contentTitle: qstMovVO?.movTitle,
					startDate : DateUtils.displayEduDate(eduMainVO?.scheduleMode, eduMainVO?.startDate),
					endDate : DateUtils.displayEduDate(eduMainVO?.scheduleMode, eduMainVO?.endDate),
					introduce : eduMainVO?.introduce
				}} />

				<div className="detail-content">
					<div className="detail-top" onContextMenu={(e) => e.preventDefault()}>
						<div id={`playerDom`} className="media-content">
							
							
							{/*<ReactPlayer ref={$player} url={qstMovVO?.movUrl} width={`888px`} height={`500px`}
							             //controls={true}
							             onPlay={() => setIsPlaying(true)}
							             onPause={() => setIsPlaying(false)}
							             onProgress={(props) => onProgress(props)}
							             onSeek={(_seconds) => onSeek(_seconds)}
							             onPlaybackRateChange={(_speed:number) => onPlaybackRateChange(_speed)}
							             onEnded={onEnded}
							             pip={pipMode}
							/>*/}
							
							
							{ qstMovVO &&
								<HwsPlayer ref={$hwsPlayer}
								           key={qstMovVO!.movUrl}
								           url={qstMovVO!.movUrl}
								           width={`100%`}
								           height={`100%`}
								           thumbnail={qstMovVO!.movThumb}
								           isSpeed={qstMovVO.isSpeed}
								           fnSeek={fnSeek}
								           fnProgress={fnProgress}
								           fnEnded={onEnded}
								           videoList={videoList}/>
							}
							
							
						</div>
					</div>
					<div className="detail-bottom">
						<div className="tabs">
							<ul className="tab-menu">
								<li className={`tab-item explain ${currentTab == 0 ? 'active' : ''}`} style={{ cursor : 'pointer' }} onClick={() => setCurrentTab(0)}>
									<a href="#" onClick={e=>e.preventDefault()}>강의 설명 {/*<button id={`btnPip`} type={`button`} onClick={() => setPipMode(!pipMode)}>PIP</button>*/}</a>
								</li>
								{
									!StringUtils.isEmpty(qstMovOptionVO?.aiSummary) && (
										<li className={`tab-item ai ${currentTab == 1 ? 'active' : ''}`} style={{ cursor : 'pointer' }} onClick={() => setCurrentTab(1)}>
											<a href="#" onClick={e=>e.preventDefault()}>AI 요약 beta</a>
										</li>
									)
								}
								{
									!StringUtils.isEmpty(qstMovOptionVO?.scriptText) && (
										<li className={`tab-item script ${currentTab == 2 ? 'active' : ''}`} style={{ cursor : 'pointer' }} onClick={() => setCurrentTab(2)}>
											<a href="#" onClick={e=>e.preventDefault()}>스크립트</a>
										</li>
									)
								}

							</ul>
							{
								currentTab == 0 &&
								<div className="tab-panel tab-explain">
									<div className="text-area" style={{
										marginTop: '0px'
									}}
										 dangerouslySetInnerHTML={{__html: StringUtils.nl2br(qstMovVO?.note) || ""}}></div>
								</div>
							}
							{
								currentTab == 1 &&
								<div className="tab-panel tab-ai">
									<div className="text-area"
										 dangerouslySetInnerHTML={{__html: StringUtils.nl2br(qstMovOptionVO?.aiSummary) || ""}}></div>
								</div>
							}
							{
								currentTab == 2 &&
								<div className="tab-panel tab-script">
									<div className="text-area"
										 dangerouslySetInnerHTML={{__html: StringUtils.nl2br(qstMovOptionVO?.scriptText) || ""}}></div>
								</div>
							}
						</div>
					</div>

					<EduContentIndex paramMap={paramMap}/>

				</div>
			</div>
		</section>
	)
}
export default QstMov;
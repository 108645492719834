import React, {useEffect, useState} from "react";
import {BoardVO} from "../../../core/board/board.vo";
import APIs from "../../../common/apis/APIs";
import StringUtils from "../../../common/utils/string.utils";
import JobSkillService from "../../../core/jobskill/jobskill.service";
import MainService from "../../../core/main/main.service";
import MemberUtils from "../../../common/utils/member.utils";
import {HWMap} from "../../../core/common/common.vo";
import CardItem from "../board/item/CardItem";
import ElandUtils from "../../../common/utils/eland.utils";
import Swiper from "../../../assets/js/swiper.min";

/**
 * 타입에 따라 "에디터 추천 플레이리스트" 와 "에디터's PICK" 로 나뉘어 집니다.
 *
 * [2024.07.12 (금)]
 * (제목 변경) 에디터 추천 플레이 리스트 -> 전체공개 추천 콘텐츠
 * (항목 추가, 법인만 노출) 이노플 에디터 픽!!
 * (유지) 에디터’s PICK
 *
 * 말씀주신 것 중 (유지) 항목 제외한 나머지는 b/o에서 생성한 플레이리스트대로 출력되어야하는데요!
 * b/o 플레이리스트 중 전체공개인 플리 -> 전체공개 추천콘텐츠 (b/o에서 이름을 바꾸거나 콘텐츠를 바꾸면 연동되어야함)
 * b/o 플레이리스트 중 법인에 맞는 플리 -> 이노플 에디터 픽!!(b/o에서 이름을 바꾸거나 콘텐츠 바꾸면 연동되어야함)
 * 전체공개인 플리엔 콘텐츠가 무조건 들어가있을거라 고정 출력이고,
 * 법인별 플리엔 콘텐츠가 없는 경우는 해당 영역 제거 / 콘텐츠가 하나라도 추가되어있는 경우는 해당 영역 출력되어야 합니다!
 *
 * @param props
 * @constructor
 */
const MainEditorContents = (props: MainEditorContentsProps) => {
    const { type } = props;
    const uniqueId = `swiper-${type}`;

    const [swiperVal, setSwiperVal] = useState<any>(null);
    const [title, setTitle] = useState("");
    const [boardList, setBoardList] = useState<BoardVO[]>([])

    useEffect(() => {
        switch (type) {
            case "recommend":
                getRecommendPlayList()
                break
            case "company":
                getCompanyPlayList()
                break
            case "pick":
                // 에디터's PICK 제목은 고정
                setTitle("에디터's PICK")
                getEditorPickList()
                break
        }
    }, [type]);

    useEffect(() => {
        if (boardList.length > 0){
            initSwiper()
        }
    }, [boardList]);

    const initSwiper = () =>{
        if (swiperVal){
            swiperVal.destroy()
            setSwiperVal(null)
        }

        const newSwiperVal = new Swiper(`.${uniqueId} .main-editor-contents`, {
            slidesPerView: "auto",
            spaceBetween: 66.66666,
            navigation: {
                nextEl: `.${uniqueId} .main-editor-contents-next`,
                prevEl: `.${uniqueId} .main-editor-contents-prev`,
            },
        });

        setSwiperVal(newSwiperVal)
    }

    /**
     * 에디터 추천 플레이리스트 조회
     */
    const getRecommendPlayList = () => {
        const params: HWMap = {
            size: 8
        };

        // 전체 공개라 주석 처리
        // if (MemberUtils.isLogin()) {
        //     params.coCode = MemberUtils.getSessionVO()?.coCode ?? ""
        // }

        const successHandler = async (result: any) => {
            console.log("⭐getRecommendPlayList", result);
            if(!result.data.boardList) return
            let newList = await ElandUtils.convertJobSkillNames4BoardList(result.data.boardList)
            setTitle(result.data.title)
            setBoardList(newList);
        }

        MainService.getRecommendPlayList(params, successHandler)
    }

    /**
     * 법인 플레이리스트 조회
     */
    const getCompanyPlayList = () => {
        const params: HWMap = {
            size: 8
        };

        if (MemberUtils.isLogin()) {
            params.coCode = MemberUtils.getSessionVO()?.coCode ?? ""
        }

        const successHandler = async (result: any) => {
            console.log("⭐getCompanyPlayList", result);
            if(!result.data.boardList) return
            let newList = await ElandUtils.convertJobSkillNames4BoardList(result.data.boardList)
            setTitle(result.data.title)
            setBoardList(newList);
        }

        MainService.getCompanyPlayList(params, successHandler)
    }

    /**
     * 에디터's Pick 조회
     */
    const getEditorPickList= () => {
        const params = {
            size: 8,
            sort: "createDate,DESC"
        };

        const successHandler = async (result: any) => {
            let newList = await ElandUtils.updateBoardListWithJobNames(result.data.list)
            setBoardList(newList);
        }

        MainService.getEditorPickList(params, successHandler)
    }

    return (
        (boardList && boardList.length !== 0) ? (
            <section className={`list-section ${uniqueId}`}>
                <div className="inner">
                    <div className="section-title">
                        <h2 className="tit">
                            {title}
                        </h2>
                    </div>
                    <div className="board-list">
                        <div className="slide-wrap main-editor-contents">
                            <ul className="slide-list swiper-wrapper card-list type1">
                                {
                                    boardList.map(value => {
                                        return <CardItem key={value.idx} board={value}/>
                                    })
                                }
                            </ul>
                        </div>
                        <div className="slide-button">
                            <div className="btn-prev main-editor-contents-prev" tabIndex={0} role="button" aria-label="Previous slide"></div>
                            <div className="btn-next main-editor-contents-next" tabIndex={0} role="button" aria-label="Next slide"></div>
                        </div>
                    </div>
                </div>
            </section>
        ) : <></>
    );
}

interface MainEditorContentsProps {
    /**
     * recommend : 에디터 추천 플레이리스트
     * pick : 에디터's PICK
     */
    type: string
}

export default MainEditorContents